import React,{useContext}  from 'react';
import { Component, useEffect, useState, useRef} from 'react';
import { Link, useParams, useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { Grid,Paper, Button, Select, MenuItem, FormControl, InputLabel, TextField, Tooltip} from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';

import UndoIcon from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import randomIcon from '../../assets/img/random.svg';
import useToken from '../../hooks/useToken';
import LoadingButton from "../../components/LoadingButton";
import { ApplicationContext } from "../../context/applicationContext";
import inventoryService from '../../services/inventoryService';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import organizationService from "../../services/organizationService";
import Alert from '@material-ui/lab/Alert';
import accountingService from "../../services/accountingService";
import downloadFileUtils from '../../utils/downloadFile';
import { QrReader } from 'react-qr-reader';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
        display: 'flex',
        flexDirection: "row",
        maxWidth: '1200px',
        width: '100%',
    },
    box: {
        paddingLeft:'30px',
        paddingTop:'40px',
        width:'100%',
        [theme.breakpoints.down('md')]:{
            padding:'8px'
        }
    },
    undoIcon: {
        marginRight: "23px",
        color:"#3f51b5",
        cursor:'pointer'
    },
    pageTitle: {
        padding: "21px 0 21px 27px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[3],
    },
    pageContent:{
        width: "100%",
        margin: "0",
        padding: "13px 0 21px 55px",
        [theme.breakpoints.down('md')]:{
            padding:'8px'
        }
    },
    firstPart: {
        margin: "8px 0 8px 0"
    },
    secondPart: {
        margin: "8px 29px 8px 0"
    },
    thirdPart: {
        margin: "8px 0 8px 0"
    },
    forthPart: {
        margin: "8px 0 8px 0"
    },
    dashedLine: {
        borderTop: "1px solid #dbdbdb",
        width: "100%",
    },
    selectField: {
        padding: "11px 14px",
    },
    required:{
        color:'red'
    },
    itemTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginBottom: "4px",
        color: "#818181",
    },
    editTitle:{
        color: "#818181",
    },
    itemContent: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin:"4px 0 0 0"
    },
    required: {
        color: "#fd4747",
        display: "inline",
    },
    itemSpacing: {
        marginBottom: "13px",
    },
    thirdItemSpacing: {
        margin: "auto 7px auto 0",
    },
    secondItemSpacing: {
        marginBottom: "20px",
    },
    textField: {
        margin: "0",
    },
    randomImage: {

    },
    buttonSpacing: {
        width: "150px",
        marginLeft: "10px",
        [theme.breakpoints.down('md')]:{
            width: "auto",
        }

    },
    checkBtn: {
        width: "150px",
        marginLeft: "10px",
        background: "grey",
    },
    btnBottom: {
        margin:"42px 0 53px 0"
    },
    thTitle: {
        letterSpacing: "0.05px",
        color: "#5d5d5d",
    },
    thBorder: {
        borderBottom: "none ",
        fontSize: "14px",
        padding: "6px",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
    margin: 2,
    },
    formControl: {
        minWidth: 250,
        maxWidth: 300,
    },

    customMargin: {
        margin: "0 67px 14px 0",
        [theme.breakpoints.down('md')]: {
            margin: "0 10px 14px 0",
        }
    },
    customMargin2: {
        margin: "0 0 18px 0",
        [theme.breakpoints.down('md')]: {
            margin: 0,
            justifyContent: 'center',
        }
    }
}));

export default function CheckOutPage(props) {
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const { token } = useToken();

    const theme = useTheme();

    const history = useHistory();

    const defaultlotItemData = {
        lotNo: {value: "", error: false}, 
        checkInCost:{value: "", error: false}, 
        qtyForCost: {value: "", error: false}, 
        qtyForStorage:{value: "", error: false}, 
        certification: {value: "", error: false}, 
        location: {value: "", error: false}, 
    };

    const defaultRefFileItemData = {
        fileType: {value: "", error: false},
        fileNo: {value: "", error: false}
    };
    const [submited, setSubmited] = useState(false);
    const [type, setType] = useState("checkout");
    const [isloading, setIsloading] = useState(false);
    const [companyList,setCompanyList] = useState([]);

    const [billNumber, setBillNumber] = useState("");
    const [refBillNumber, setRefBillNumber] = useState("");

    const [staffArray,setStaffArray] = useState([])
    const [friends,setFriends] = useState([]);
    const [pageType, setPageType] = useState("new");
    const [currentCheckOutNo, setCurrentCheckOutNo] = useState(null);
    const [isMaterial, setIsMaterial] = useState(false);

    const [goodsData, setGoodsData] = useState("");
    useEffect(()=> {
        if(history.location.state.operationType&&history.location.state.operationType==="view"){
            setSubmited(true);
        }
        if(history.location.state.type&&history.location.state.type==="reserve"){
            setType("reserve");
            console.log("reserve");
        }
        if(history.location.state.warehouseData.isMaterial){
            setIsMaterial(history.location.state.warehouseData.isMaterial);
        }
        else{
            inventoryService.getWarehouse(history.location.state.warehouseData.warehouseId).then(result=>{
                setIsMaterial(result.data.isMaterial);
            })
        }
        accountingService.addName(history.location.state.warehouseData.orgId).then((result) => {
            setCompanyList(result.data)
        })
        inventoryService.searchGoodByNameOrId(history.location.state.warehouseData.orgId,"").then(result=>{
            if(result.status===200){
                setGoodsData(result.data.datas);
            }
        })
        organizationService.getUsersByOrgId(history.location.state.warehouseData.orgId).then((result) => {
            if (result.data && result.data.users) {
              setFriends(result.data.users);
              let tempFriends = result.data.users;
              inventoryService.getWarehouseStaff(history.location.state.warehouseData.warehouseId,{}).then(result=>{
                if(result.status===200){
                    let tempStaffArray = [];
                    result.data.map(item=>{
                        let tempItem = {};
                        tempItem.email = item;
                        let findResult = tempFriends.find(item=>{
                            return (item.email === tempItem.email);
                        })
                        if(findResult){
                            tempItem.username = findResult.username;
                        }
                        else{
                            tempItem.username = "";
                        }
                        tempStaffArray.push(tempItem);
                    })
                    console.log(tempStaffArray);
                    setStaffArray(tempStaffArray);
                    if(history.location.state.checkOutId&&history.location.state.checkOutId!==""){
                        if(history.location.state.operationType&&history.location.state.operationType==="view"){
                            setPageType("view");
                        }
                        else{
                            setPageType("edit");
                        }
                        inventoryService.getCheckOutById(history.location.state.checkOutId).then(result=>{
                            if(result.status===200){
                                // console.log(result.data);
                                if(result.data.outBillNumber){
                                   setBillNumber(result.data.outBillNumber);
                                }
                                setRefBillNumber(result.data.refOutBillNumber);
                                setSelGood(result.data.goods[0].goods_id);
                                inventoryService.getGoodDetailById(history.location.state.warehouseData.orgId,result.data.goods[0].goods_id,history.location.state.warehouseData.warehouseId).then(result=>{
                                    if(result.status===200){
                                        setSelGoodInfo(result.data);
                                    }
                                })
                                setCheckOutTotalCost(result.data.totalConst);
                                if(result.data.filterType==="FIFO"){
                                    setFifoChecked(true);
                                }
                                else{
                                    setFifoChecked(false);
                                }
                                let tempLotData=[];
                                let tempTotalCost = 0;
                                let tempCheckOutQty = 0;
                                result.data.checkOutDetails.map(item=>{
                                    let tempItem ={};
                                    tempItem.certification=item.certification;
                                    tempItem.checkInDetailsId=item.check_in_details_id;
                                    tempItem.checkOutCost=item.check_out_cost;
                                    tempItem.goodsId = item.goods_id;
                                    tempItem.location=item.location;
                                    tempItem.lotNumber=item.lot_number;
                                    tempItem.qtyCost=item.qty_cost;
                                    tempItem.qtyStorage=item.qty_storage;
                                    tempLotData.push(tempItem);
                                    tempTotalCost=tempTotalCost+item.check_out_cost;
                                    tempCheckOutQty =tempCheckOutQty+ tempItem.qtyStorage;
                                })
                                setLotData(tempLotData);
                                setCheckOutTotalCost(tempTotalCost);
                                setCheckOutQtyForStorage(tempCheckOutQty);
                                let refFileItemDataTemp = [];
                                if(result.data.referenceInfo&&result.data.referenceInfo.length>0){
                                    result.data.referenceInfo.map(item=>{
                                        let tempItem = defaultRefFileItemData;
                                        tempItem.fileType.value = item.referenceType;
                                        tempItem.fileNo.value = item.referenceNumber;
                                        refFileItemDataTemp.push(tempItem);
                                    })
                                }
                                else{
                                    refFileItemDataTemp.push({
                                        fileType: {value: "", error: false},
                                        fileNo: {value: "", error: false}
                                    });
                                }
                                setRefFileItemData(refFileItemDataTemp);
                                setSupplier(result.data.supplier)
                                setCustomer(result.data.customer)
                                setInspector(result.data.inspector)
                                setInspectionDate(result.data.inspectorDate);
                                setRequestor(result.data.requestor);
                                let staffTemp = {};
                                let findtemp = friends.find(item=>{
                                    return(item.email === result.data.staff)
                                });
                                if(result.data.staff){
                                    staffTemp.email =result.data.staff;
                                    if(findtemp){
                                        staffTemp.username = findtemp.username;
                                    }
                                    else{
                                        staffTemp.username = "";
                                    }
                                    setWarehouseStaff(staffTemp);
                                }
                                setNotes(result.data.notes)
                            }
                        })
                    }
                }
            })
            }
        })
    },[])

    

    const [selGood, setSelGood] = useState("");
    const [selDays, setSelDays] = useState(7);
    const [selGoodInfo, setSelGoodInfo] = useState("");

    const [lotItemData, setLotItemData] = useState([defaultlotItemData]);

    const [checkOutQtyForStorage, setCheckOutQtyForStorage] = useState("");
    const [checkOutQtyForStorageError, setCheckOutQtyForStorageError] = useState(false);

    const [checkOutTotalCost, setCheckOutTotalCost] = useState("");

    const [lotData, setLotData] = useState([]);

    const [fifoChecked,setFifoChecked] =useState(true);

    const [refFileItemData, setRefFileItemData] = useState([defaultRefFileItemData]);

    const [fileTypeData, setFileTypeData] = useState([]);
    useEffect(()=>{
        setFileTypeData([t("checkIn.po"),t("checkIn.so"),t("checkIn.wo"),t("checkIn.sncr"),t("checkIn.rma"),t("checkIn.other")]);
    },[])

    const [reserveDays] = useState([7,14]);

    const [supplier, setSupplier] = useState(null);

    const [customer, setCustomer] = useState(null);

    const [inspector, setInspector] = useState("");

    const [inspectionDate, setInspectionDate] = useState(null);

    const [requestor, setRequestor] = useState("");
    const [checkOutFilterError, setCheckOutFilterError] = useState("");
    const [submitError, setSubmitError] = useState("");

    const [warehouseStaff, setWarehouseStaff] = useState(null);

    const [notes, setNotes] = useState("");
    const [selLotNo, setSelLotNo] = useState([]);
    const [selLotNoError, setSelLotNoError] = useState("");
    const [lotNoData, setLotNoData] = useState(null);
    const [billNumberError, setBillNumberError] = useState(false);
    const [selDaysError, setSelDaysError] = useState(false);

    const [selGoodError, setSelGoodError] = useState(false);
    const [categoryData, setCategoryData] = useState([t('materialGoods.shapelistBar'),t('materialGoods.shapelistSeamless'),t('materialGoods.shapelistWelding'),t('materialGoods.shapelistSheet'),t('materialGoods.shapelistStrip'),t('materialGoods.shapelsitWire'),t('materialGoods.shapelistGrain'),t('materialGoods.shapelistPowder'),t('materialGoods.shapelistSpecial'),t('materialGoods.shapelistOthers')]);
    const [goodsType,setGoodsType] = useState("");
    const [goodsCategory,setGoodsCategory] = useState(null);
    const [goodsBrand,setGoodsBrand] = useState("");
    const [goodsESpec1,setGoodsESpec1] = useState(null);
    const [goodsESpec2,setGoodsESpec2] = useState(null);
    const [goodsESpec3,setGoodsESpec3] = useState(null);
    const [requestorError, setRequestorError] = useState(false);
    const [qrCodeOpen, setQrCodeOpen] = useState(false);

    const [warehouseStaffError, setWarehouseStaffError] = useState(false);
    const filter = createFilterOptions();

    const [customerError, setCustomerError] = useState(false);
        useEffect(()=>{
            timer = setTimeout(() => {
                if(pageType!=="view"&&!history.location.state.from){
                    let fifotype = "FIFO"
                    if(!fifoChecked){
                        fifotype = "manual"
                    }
                let tempSelNo="";
                selLotNo.map(item=>{
                    if(tempSelNo===""){
                        tempSelNo=item.detailsId;
                    }
                    else{
                        tempSelNo = tempSelNo + "," +item.detailsId;
                    }
                })
                if(fifotype==="FIFO"||(fifotype==="manual"&&selLotNo.length>0)){
                    if(selGood!==""&&checkOutQtyForStorage!==""&&checkNumber()){
                        inventoryService.getCheckOutFilter(history.location.state.warehouseData.orgId,history.location.state.warehouseData.warehouseId,selGood,checkOutQtyForStorage,fifotype,tempSelNo).then(result=>{
                            if(result.status===200){
                                setCheckOutTotalCost(result.data.totalConst);
                                setLotData(result.data.datas);
                                setCheckOutFilterError("");
                            }
                        }).catch((e)=>{
                            console.log(e.response.data.message);
                            console.log(e.response.data.message.indexOf("Please check the checkOutStorage value"));
                            if(e.response.data.message.indexOf("Please check the checkOutStorage value, out the total storage")>-1){
                                setCheckOutFilterError(t("checkOut.checkOutQtyExceeds"));
                                setCheckOutTotalCost("");
                                setLotData([]);
                            }
                            
                            // console.log();
                        })
                    }
                }
            } 
        },500);
        return() => {clearTimeout(timer);}
    },[fifoChecked,selLotNo,checkOutQtyForStorage,selGood])

    useEffect(() => {
        if(isMaterial){
            let eSpec = {};
            if(goodsESpec1&&goodsESpec1.value!==''){
                eSpec[goodsESpec1.label]=goodsESpec1.value;
            }
            if(goodsESpec2&&goodsESpec2.value!==''){
                eSpec[goodsESpec2.label]=goodsESpec2.value;
            }
            if(goodsESpec3&&goodsESpec3.value!==''){
                eSpec[goodsESpec3.label]=goodsESpec3.value;
            }
            console.log(JSON.stringify(eSpec));
            inventoryService.searchGoodBySpec(history.location.state.warehouseData.orgId,goodsType,goodsBrand,goodsCategory,JSON.stringify(eSpec)).then(result=>{
                if(result.status===200){
                    setGoodsData(result.data.datas);
                }
            })
        }   
    },[goodsType,goodsCategory,goodsBrand,goodsESpec1,goodsESpec2,goodsESpec3,isMaterial])
    // const getFilerResult = (fifoChecked,selLotNo,checkOutQtyForStorage,selGood)=>{
    //     console.log(timer);
    //     if(timer){
    //         console.log("clear timeout");
    //         clearTimeout(timer);
    //     }
    //     timer = setTimeout(() => {
    //         let fifotype = "FIFO"
    //         if(!fifoChecked){
    //             fifotype = "manual"
    //         }
    //         let tempSelNo="";
    //         selLotNo.map(item=>{
    //             if(tempSelNo===""){
    //                 tempSelNo=item.detailsId;
    //             }
    //             else{
    //                 tempSelNo = tempSelNo + "," +item.detailsId;
    //             }
    //         })
    //         if(fifotype==="FIFO"||(fifotype==="manual"&&selLotNo.length>0)){
    //             if(selGood!==""&&checkOutQtyForStorage!==""&&checkNumber()){
    //                 inventoryService.getCheckOutFilter(history.location.state.warehouseData.orgId,history.location.state.warehouseData.warehouseId,selGood,checkOutQtyForStorage,fifotype,tempSelNo).then(result=>{
    //                     if(result.status===200){
    //                         setCheckOutTotalCost(result.data.totalConst);
    //                         setLotData(result.data.datas);
    //                         setCheckOutFilterError("");
    //                     }
    //                 }).catch((e)=>{
    //                     console.log(e.response.data.message);
    //                     console.log(e.response.data.message.indexOf("Please check the checkOutStorage value"));
    //                     if(e.response.data.message.indexOf("Please check the checkOutStorage value, out the total storage")>-1){
    //                         setCheckOutFilterError(t("checkOut.checkOutQtyExceeds"));
    //                         setCheckOutTotalCost("");
    //                         setLotData([]);
    //                     }
                        
    //                     // console.log();
    //                 })
    //             }
    //         }
    //     },500);
    // }

    const checkNumber = (values) => {
        let reg = /^\d*(\.\d{1,4})?$/;
        if (values && !reg.test(values)) {
            return false;
        }
        return true;
    }

    const billNumberChangeHandler = (e) => {
        if(e.target.value === ""){
            setBillNumberError(true);
        }else{
            setBillNumberError(false);
        }
        setBillNumber(e.target.value);
    };

    const refBillNumberChangeHandler = (e) => {
        setRefBillNumber(e.target.value);
    };

    const selDaysChangeHandler = (e) =>{
        if(e.target.value === ""){
            setSelDaysError(true);
        }else{
            setSelDaysError(false);
        }
        setSelDays(e.target.value);
    };
    
    const goodsChangeHandler = (e) => {
        if(e.target.value === ""){
            setSelGoodError(true);
        }else{
            setSelGoodError(false);
        }
        setSelGood(e.target.value);
        goodsData.map( (item)=> {
            if(item.goodsId === e.target.value){
                inventoryService.getGoodDetailById(history.location.state.warehouseData.orgId,e.target.value,history.location.state.warehouseData.warehouseId).then(result=>{
                    if(result.status===200){
                        item.availableStorage = result.data.availableStorage;
                        setLotNoData(result.data.checkInDatas);
                    }
                })
                setSelGoodInfo(item);
            }
        })
        
        // getFilerResult(fifoChecked,selLotNo,checkOutQtyForStorage,e.target.value);
    };

    const lotNumberChangeHandler = (event) => {
        setSelLotNo(event.target.value);
        // getFilerResult(fifoChecked,event.target.value,checkOutQtyForStorage,selGood);
        console.log(event.target.value);
        // let tempLotData = [];
        // lotNoData.map(item=>{
        //     let itemTemp = item;
        //     event.target.value.map(item=>{
        //         if(itemTemp.lotNo===item.lotNumber){
        //             tempLotData.push(itemTemp);
        //         }
        //     })
        // })
        // console.log(tempLotData);
        // setLotData(tempLotData);
    };
    let timer;

    const checkOutQtyForStorageChangeHandler = (e) => {
        if(e.target.value===""||!checkNumber(e.target.value)){
            setCheckOutQtyForStorageError(true);
        }
        else{
            setCheckOutQtyForStorageError(false);
        }
        setCheckOutQtyForStorage(e.target.value);
        // getFilerResult(fifoChecked,selLotNo,e.target.value,selGood);

    };

    const setRefFileTypeError = (index) => {
        const copy_refFileItemData = [...refFileItemData];
        copy_refFileItemData[index].fileType.error = true;
        setRefFileItemData(copy_refFileItemData);
    };

    const setRefFileNoError = (index) => {
        const copy_refFileItemData = [...refFileItemData];
        copy_refFileItemData[index].fileNo.error = true;
        setRefFileItemData(copy_refFileItemData);
    };

    const fileTypeChangeHandler = (index, event) => {
        const copy_refFileItemData = [...refFileItemData];
        if(event.target.value === ""){
            copy_refFileItemData[index].fileType.error = true;
        }else{
            copy_refFileItemData[index].fileType.error = false;
        }
        copy_refFileItemData[index].fileType.value = event.target.value;
        setRefFileItemData(copy_refFileItemData);
    };

    const fileNoChangeHandler = (index, event) => {
        const copy_refFileItemData = [...refFileItemData];
        if(event.target.value === ""){
            copy_refFileItemData[index].fileNo.error = true;
        }else{
            copy_refFileItemData[index].fileNo.error = false;
        }
        copy_refFileItemData[index].fileNo.value = event.target.value;
        setRefFileItemData(copy_refFileItemData);
    };

    const refFileItemDataAddHandler = () => {
        const copy_refFileItemData = [...refFileItemData];
        copy_refFileItemData.push(defaultRefFileItemData);
        setRefFileItemData(copy_refFileItemData);
    };

    const removeRefFileItemDataHandler = (index) => {
        const copy_refFileItemData = [...refFileItemData];
        copy_refFileItemData.splice(index, 1);

        console.log("copy_refFileItemData", copy_refFileItemData)

        setRefFileItemData(copy_refFileItemData);
    };

    const supplierChangeHandler = (e) => {
        setSupplier(e.target.value);
    };

    const customerChangeHandler = (e) => {
        if(e.target.value === ""){
            setCustomerError(true);
        }else{
            setCustomerError(false);
        }
        setCustomer(e.target.value);
    };

    const inspectorChangeHandler = (e) => {
        setInspector(e.target.value);
    };

    const inspectionDateChangeHandler = (date) => {
        setInspectionDate(date);
    };

    const requestorChangeHandler = (e) => {
        if(e.target.value === ""){
            setRequestorError(true);
        }else{
            setRequestorError(false);
        }
        setRequestor(e.target.value);
    };

    const warehouseStaffChangeHandler = (e,newValue) => {
        if(!newValue){
            setWarehouseStaffError(true);
        }else{
            setWarehouseStaffError(false);
        }
        setWarehouseStaff(newValue);
    };

    const notesChangeHandler = (e) => {
        setNotes(e.target.value);
    };

    const submitCheckOutFormHandler = (e) => {
        inventoryService.verifyCheckOut(currentCheckOutNo).then(result=>{
            if(result.status===200){
                history.go(-1);
            }
        });
    };

    const generateOrderHandler =(e) => {
        setSubmited(true);
        let valid = true;
        if(type==="checkout"&&billNumber===""){
            setBillNumberError(true);
            valid = false;
        }
        if(selGood===""){
            setSelGoodError(true);
            valid = false;
        }
        refFileItemData.map((item,index)=>{
            if(type==="checkout"&&item.fileType.value===''){
                setRefFileTypeError(index);
                valid = false;
            }
            if(type==="checkout"&&item.fileNo.value===''){
                setRefFileNoError(index);
                valid = false;
            }
        })
        if(checkOutQtyForStorage===""||lotData.length===0){
            setCheckOutQtyForStorageError(true);
            valid = false;
        }
        if(type==="checkout"&&!requestor){
            setRequestorError(true);
            valid = false;
        }

        if(type==="checkout"&&!customer){
            setCustomerError(true);
            valid = false;
        }

        if(type==="checkout"&&!warehouseStaff){
            setWarehouseStaffError(true);
            valid = false;
        }
        if(!valid){
            setSubmited(false);
            return;
        }
        let submitValue = {};
        if(type==="checkout"){
            submitValue.outBillNumber = billNumber;
            submitValue.refOutBillNumber = refBillNumber;
            submitValue.inspector = inspector;

            if(inspectionDate){
                submitValue.inspectorDate = moment(inspectionDate).format('yyyy-MM-DD');
            }
            submitValue.staff = warehouseStaff.email;
            submitValue.supplier = supplier;
            submitValue.customer = customer;
            let tempRfData = [];
            refFileItemData.map(item=>{
                tempRfData.push({
                    referenceType: item.fileType.value,
                    referenceNumber: item.fileNo.value
                });
            })
            submitValue.referenceInfo = tempRfData;
            submitValue.isReserved = false;
            submitValue.requestor = requestor;
        }
        else{
            submitValue.refRFQNumber = refBillNumber;
            submitValue.reservedDays = selDays;
            submitValue.isReserved = true;
        }
        submitValue.warehouseId = history.location.state.warehouseData.warehouseId;
        submitValue.orgId = history.location.state.warehouseData.orgId;

        submitValue.notes = notes;
        if(fifoChecked){
            submitValue.filterType = "FIFO";
        }
        else{
            submitValue.filterType = "manual";
        }
        
        submitValue.checkOutDetails = lotData;
        console.log(submitValue);
        console.log(pageType);
        if(pageType==="new"){
            inventoryService.addCheckOut(submitValue).then(result=>{
                if(result.status===200){
                    setCurrentCheckOutNo(result.data.checkOutId);
                    setSubmited(true);
                    if(type==="checkout"){
                        inventoryService.downloadCheckOutById(result.data.checkOutId,-(((new Date().getTimezoneOffset())/60))).then(result=>{
                            let types = 'application/vnd.ms-excel';
                            let fileName = `${t('checkOut.title')} ${billNumber}.xlsx`;
                            let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
                            ; // 保存的文件名
                            downloadFileUtils.downLoadFile(blob, fileName);   
                        })
                    }
                    else{
                        const settingURL = '/Inventory/ReservationMgt';
                        history.push({
                            pathname: settingURL,
                            state: { "orgId": history.location.state.warehouseData.orgId},
                        });
                    }
                }
            }).catch(e=>{
                setSubmited(false);
                console.log(e.response.data.message);
                console.log(e.response.data.message.indexOf("The inBillNumber"));
                if(e.response.data.message.indexOf("The outBillNumber")>-1 && e.response.data.message.indexOf("exist in this org")>-1){
                    setSubmitError(t("checkOut.checkOutBillNumerExist"));
                    setBillNumberError(true);
                }
                else{
                    setSubmitError(e.response.data.message)
                }
            })
        }
        else{
            submitValue.status="saved";
            inventoryService.reserveToCheckOutById(history.location.state.checkOutId,submitValue).then(result=>{
                if(result.status===200){
                    setCurrentCheckOutNo(result.data.checkOutId);
                    setSubmited(true);
                    inventoryService.downloadCheckOutById(result.data.checkOutId,-(((new Date().getTimezoneOffset())/60))).then(result=>{
                        let types = 'application/vnd.ms-excel';
                        let fileName = `${t('checkOut.title')} ${billNumber}.xlsx`;
                        let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
                        ; // 保存的文件名
                        downloadFileUtils.downLoadFile(blob, fileName);   
                    })
                }
            })
        }
    };

    const goBackHandler = () => {
        history.go(-1);
    };

    const getBillNumberHandler =()=>{
        inventoryService.getBillNumber(history.location.state.warehouseData.orgId,"check_out").then(result=>{
            if(result.status===200){
                let tempNumber = "CO" + moment().format("YYYYMMDD") + ("0000" + parseInt(result.data.maxNumber+1)).slice(-4);
                setBillNumber(tempNumber);
                setBillNumberError(false);
                console.log(tempNumber);
            }
        })
    }

    const setGoodsESpec1Handler = (e)=>{
        {
            let copySepc = JSON.parse(JSON.stringify(goodsESpec1));
            console.log(copySepc);
            copySepc.value=e.target.value;
            if(e.target.value===''&&copySepc.required===true){
                copySepc.error=true;
            }
            else{
                copySepc.error=false;
            }
            console.log(copySepc);
            setGoodsESpec1(copySepc);
        }
    }

    const setGoodsESpec2Handler = (e)=>{
        {
            let copySepc = JSON.parse(JSON.stringify(goodsESpec2));
            console.log(copySepc);
            copySepc.value=e.target.value;
            if(e.target.value===''&&copySepc.required===true){
                copySepc.error=true;
            }
            else{
                copySepc.error=false;
            }
            console.log(copySepc);
            setGoodsESpec2(copySepc);
        }
    }

    const setGoodsESpec3Handler = (e)=>{
        {
            let copySepc = JSON.parse(JSON.stringify(goodsESpec3));
            console.log(copySepc);
            copySepc.value=e.target.value;
            if(e.target.value===''&&copySepc.required===true){
                copySepc.error=true;
            }
            else{
                copySepc.error=false;
            }
            console.log(copySepc);
            setGoodsESpec3(copySepc);
        }
    }

    const showExtraSpec = (lineData)=>{
        console.log(lineData)
        let tempName = '';
        let arr=[];
        if(lineData.goodsType&&lineData.goodsType!==''){
            arr.push(lineData.goodsType);
        }
        
        if(lineData.category&&lineData.category!==''){
            arr.push(lineData.category);
        }
        if(lineData.brand!==''){
            arr.push(lineData.brand);
        }
        if(lineData.extralSpec&&lineData.extralSpec!==''){
            // arr.push(goodsESpec3.value);
            arr = arr.concat(Object.values(lineData.extralSpec));
        }
        tempName = arr.join('/');
        console.log(tempName);
        return tempName;
    }

    const scanQRCodeHandler = () =>{
        setQrCodeOpen(true);
    }
    
    return (
        <div className={classes.root}> 
            <div className={classes.box}>
                <Grid container justifyContent="flex-start" alignItems="center" className={classes.pageTitle}>
                    <div onClick={goBackHandler}>
                        <UndoIcon color="primary" className={classes.undoIcon} ></UndoIcon>
                    </div>
                    <Typography variant="h3" gutterBottom style={{"fontWeight":"bold"}}>
                        {type==="checkout"?t('checkOut.title'):t('reserve.title')} -  {history.location.state.warehouseData.name}
                    </Typography>
                </Grid>

                <Grid container justifyContent="flex-start" alignItems="center" direction="row" className={classes.pageContent}>
                    <Grid item xs={12} container className={classes.firstPart}>
                        <Grid item xs={12} className={classes.itemSpacing}>
                            <Grid container xs={12}>
                                <Grid item xs={12} >
                                    <Grid container xs={12}>
                                        {type==="checkout"&&<Grid item md={3} xs={5}>
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkOut.billNo")}<span className={classes.required}>*</span>
                                            </Typography>
                                        </Grid>}
                                        {type==="checkout"&&<Grid xs={1}>

                                        </Grid>}
                                        <Grid item md={3} xs={5}>
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {type==="checkout"?t("checkOut.refBillNo"):t('reserve.refBillNo')}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={1}>

                                        </Grid>
                                        {type==="reserve"&&<Grid item xs={3}>
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t('reserve.reserveDate')}
                                            </Typography>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container xs={12}>
                                        {type==="checkout"&&<Grid item md={3} xs={5} style={{ margin: "auto 0" }}>
                                            <TextField
                                                id="invoice_number_text"
                                                error={billNumberError}
                                                value={billNumber}
                                                required={true}
                                                disabled = {true}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                                onChange={billNumberChangeHandler}
                                            />
                                        </Grid>}
                                        {type==="checkout"&&<Grid item md={1} xs={2} >
                                            <Tooltip title="">
                                                <IconButton onClick={getBillNumberHandler} disabled = {submited||pageType==="view"}>
                                                    <img src={randomIcon} className={classes.randomImage}></img>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>}
                                        <Grid item md={3} xs={5} style={{ margin: "auto 0" }}>
                                            <TextField
                                                id="invoice_number_text"
                                                disabled = {submited}
                                                value={refBillNumber}
                                                required={true}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                                onChange={refBillNumberChangeHandler}
                                            />
                                        </Grid>
                                        <Grid xs={1}>

                                        </Grid>
                                        {type==="reserve"&&<Grid item md={3} xs={5}>
                                            <Select
                                                fullWidth
                                                required={true}
                                                error={selDaysError}
                                                variant="outlined"
                                                disabled = {submited}
                                                value={selDays}
                                                onChange={selDaysChangeHandler}
                                                classes={{ select: classes.selectField }}
                                            >
                                                {reserveDays && reserveDays.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {history.location.state.warehouseData.isMaterial&&<Grid container xs={12} spacing={1} className={classes.itemSpacing}>
                        <Grid item md={3} xs={5}>
                                    <Grid className={classes.editTitle}>
                                        {t('mGoodsManagement.goodsType')}
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            // value={item.title}
                                            value={goodsType}
                                            freeSolo
                                            fullWidth
                                            size='small'
                                            options={[t('materialGoods.typelistSteel'),t('materialGoods.typelistStainless'),t('materialGoods.typelistAl'),t('materialGoods.typelistTi'),t('materialGoods.typelistNi'),t('materialGoods.typelistLi'),t('materialGoods.typelistZr'),t('materialGoods.typelistCu')].map(option=>option)}
                                            // getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                {option}
                                                </React.Fragment>
                                            )}
                                            
                                            // onChange={(e, newValue) => {
                                            //     setUnitforCost(newValue);
                                            //     console.log(unitforCost);
                                            // }}
                                            onInputChange={(e,newInputValue)=>{
                                                setGoodsType(newInputValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('mGoodsManagement.goodsType')}/>}
                                            style={{marginTop:'8px'}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item md={3} xs={5}>
                                    <Grid className={classes.editTitle}>
                                        {t('mGoodsManagement.brand')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="description"
                                            variant="outlined"
                                            value={goodsBrand}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>{
                                                setGoodsBrand(e.target.value);
                                            }}
                                            placeholder={t('mGoodsManagement.brand')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item md={3} xs={5}>
                                    <Grid className={classes.editTitle}>
                                        {t('mGoodsManagement.shape')}
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            id="goodsCategory"
                                            value={goodsCategory}
                                            fullWidth
                                            required={true}
                                            variant="outlined"
                                            size="small"
                                            onChange={e=>{
                                                setGoodsCategory(e.target.value);
                                                if(e.target.value==='棒'||e.target.value==='Bar'){
                                                    setGoodsESpec1({label:t('materialGoods.dimDiameter'),required:false,value:'',error:false});
                                                    setGoodsESpec2({label:t('materialGoods.dimLength'),required:false,value:'',error:false});
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='无缝管'||e.target.value==='焊接管'||e.target.value==='Seamless tube'||e.target.value==='Welding tube'){
                                                    setGoodsESpec1({label:t('materialGoods.dimDiameter'),required:false,value:'',error:false});
                                                    setGoodsESpec2({label:t('materialGoods.dimWall'),required:false,value:'',error:false});
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='板'||e.target.value==='条-块-方棒'||e.target.value==='Sheet'||e.target.value==='Strip-Ingot-Square bar'){
                                                    setGoodsESpec1({label:t('materialGoods.dimStickness'),required:false,value:'',error:false});
                                                    setGoodsESpec2({label:t('materialGoods.dimWidth'),required:false,value:'',error:false});
                                                    setGoodsESpec3({label:t('materialGoods.dimLength'),required:false,value:'',error:false});
                                                }
                                                if(e.target.value==='丝'||e.target.value==='Wire'){
                                                    setGoodsESpec1({label:t('materialGoods.dimDiameter'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='颗粒'||e.target.value==='Grain'){
                                                    setGoodsESpec1({label:t('materialGoods.dimSize1'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='粉'||e.target.value==='Powder'){
                                                    setGoodsESpec1({label:t('materialGoods.dimSize2'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='异形'||e.target.value==='Special shape'){
                                                    setGoodsESpec1({label:t('mGoodsManagement.dimension'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='其他'||e.target.value==='Others'){
                                                    setGoodsESpec1({label:t('mGoodsManagement.dimension'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                            }}
                                            classes={{ select: classes.selectField }}
                                            style={{marginTop:'7px'}}
                                        >
                                            {categoryData && categoryData.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                {goodsESpec1&&<Grid item xs={1}>
                                    <Grid className={classes.editTitle}>
                                        {goodsESpec1.label}{goodsESpec1.required&&<span className={classes.required}>*</span>}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="spec"
                                            variant="outlined"
                                            value={goodsESpec1.value}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={setGoodsESpec1Handler}
                                            placeholder={t('goodsManagement.spec')}
                                        />
                                    </Grid>
                                </Grid>}
                                {goodsESpec2&&<Grid item xs={1}>
                                    <Grid className={classes.editTitle}>
                                        {goodsESpec2.label}{goodsESpec2.required&&<span className={classes.required}>*</span>}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="spec"
                                            variant="outlined"
                                            value={goodsESpec2.value}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={setGoodsESpec2Handler}
                                            placeholder={t('goodsManagement.spec')}
                                        />
                                    </Grid>
                                </Grid>}
                                {goodsESpec3&&<Grid item xs={1}>
                                    <Grid className={classes.editTitle}>
                                        {goodsESpec3.label}{goodsESpec3.required&&<span className={classes.required}>*</span>}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="spec"
                                            variant="outlined"
                                            value={goodsESpec3.value}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={setGoodsESpec3Handler}
                                            placeholder={t('goodsManagement.spec')}
                                        />
                                    </Grid>
                                </Grid>}
                        </Grid>
                        }
                        <Grid item xs={12} className={classes.itemSpacing}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkOut.goodsIdOrName")}<span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={5} >
                                    <Select
                                        fullWidth
                                        required={true}
                                        error={selGoodError}
                                        variant="outlined"
                                        disabled = {submited||history.location.state.from==='reserve'}
                                        value={selGood}
                                        onChange={goodsChangeHandler}
                                        classes={{ select: classes.selectField }}
                                    >
                                        {goodsData && goodsData.map((item, index) => (
                                            <MenuItem key={index} value={item.goodsId}>
                                                {item.id}/{item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={2} md={1} alignItems="flex-start">
                                    <IconButton id="scanQRCode" disabled={submited} onClick={scanQRCodeHandler}>
                                        <CropFreeIcon >
                                        </CropFreeIcon>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.secondItemSpacing}>
                            <Grid container>
                                {!history.location.state.warehouseData.isMaterial&&<Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkOut.category")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.category}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>}

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkOut.description")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3} >
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkOut.rev")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.rev}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {selGoodInfo&&history.location.state.warehouseData.isMaterial?t("goodsManagement.eSpec"):t("checkIn.spec")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo&&history.location.state.warehouseData.isMaterial?showExtraSpec(selGoodInfo):selGoodInfo.spec}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.secondItemSpacing}>
                            <Grid container>
                                

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkOut.standard")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.standard}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3} >
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkOut.unitforCost")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.unitCost}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkOut.unitforStorage")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.unitStorage}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkOut.currency")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.currency}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.secondItemSpacing}>
                            <Grid container>
                                

                                <Grid item xs={3} >
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkOut.avaliableQty")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {!selGoodInfo.availableStorage?"0":selGoodInfo.availableStorage}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.dashedLine}></Grid>

                    <Grid item xs={12} className={classes.secondPart}>
                        <Grid container>
                            <Grid item xs={12} >
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t("checkOut.lotNo")}<span className={classes.required}>*</span>
                                </Typography>
                            </Grid>
                            <Grid item md={3} xs={5} >
                                <Grid container>
                                    {/* <Grid item xs={12} style={{ margin: "auto 0" }}>
                                        <TextField
                                            id="lot_number_select"
                                            required={true}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            className={classes.textField}
                                            onChange={(event) => lotNumberChangeHandler( event)}
                                        />
                                    </Grid> */}
                                    <Typography style={{marginTop:'7px'}}>FIFO:</Typography><Switch
                                        checked={fifoChecked}
                                        disabled = {submited||history.location.state.from==='reserve'}
                                        onChange={event=>{
                                            setFifoChecked(event.target.checked);
                                            // getFilerResult(event.target.checked,selLotNo,checkOutQtyForStorage,selGood);
                                        }}
                                        color="secondary"
                                        name="checkedB"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    {!fifoChecked&&<FormControl variant="outlined" className={classes.formControl}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                                        <Select
                                        id="lot_number_select"
                                        fullWidth
                                        multiple
                                        disabled = {submited||history.location.state.from==='reserve'}
                                        renderValue={(selected) => <div className={classes.chips}>
                                        {selected.map((value) => (
                                          <Chip key={value} label={value.lotNumber+"("+moment(value.checkInDate).format('YYYY-MM-DD')+")"} className={classes.chip} />
                                        ))}
                                      </div>}
                                        // input={<Input />}
                                        error={selLotNoError}
                                        value={selLotNo}
                                        onChange={lotNumberChangeHandler}
                                        classes={{ select: classes.selectField }}
                                    >
                                        {lotNoData && lotNoData.map((item, index) => (
                                            <MenuItem key={index+1} value={item}>
                                                {item.lotNumber}({moment(item.checkInDate).format('YYYY-MM-DD')})
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container style={{margin:"15px 0 0 0"}}>
                            <Grid item xs={4}>
                                <Grid item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {type==="checkout"?t("checkOut.checkOutQtyForStorage"):t("reserve.checkOutQtyForStorage")}
                                    </Typography>
                                </Grid>
                                <Grid item md={10} xs={12}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item xs={10} style={{ margin: "auto 0"}}>
                                            <TextField
                                                id="checkOutQtyForStorager_text"
                                                required={true}
                                                value={checkOutQtyForStorage}
                                                fullWidth
                                                disabled = {submited||history.location.state.from==='reserve'}
                                                error={checkOutQtyForStorageError}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                                onChange={(event) => checkOutQtyForStorageChangeHandler(event)}
                                            />
                                        </Grid>
                                        <Grid item xs={1} style={{margin:"auto"}}>
                                            <Typography variant="body1" >
                                                {selGoodInfo.unitforStorage}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {type==="checkout"?t("checkOut.checkOutTotalCost"):t("reserve.checkOutTotalCost")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant="body1" className={classes.itemContent}>
                                        {checkOutTotalCost}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    { checkOutFilterError!=="" && <Alert severity="error" style={{marginBottom:'6px'}}><Typography>{checkOutFilterError}</Typography></Alert>}

                    <Grid item xs={12}>
                        {lotData && lotData.length > 0 ? (
                            <Grid key={lotData.lotNo} >
                            <TableContainer component={Paper} className={classes.invoiceLinePanel}>
                                <Table aria-label="simple table"  >
                                <TableHead>
                                    <TableRow className={classes.thTitle}>
                                    <TableCell className={classes.thBorder}>{t("checkOut.lotNo")}</TableCell>
                                    <TableCell className={classes.thBorder} align="left">{type==="checkout"?t("checkOut.checkOutCost"):t("reserve.checkOutCost")}</TableCell>
                                    <TableCell className={classes.thBorder} align="left">{t("checkOut.qtyForCost")}</TableCell>
                                    <TableCell className={classes.thBorder} align="left">{t("checkOut.qtyForStorage")}</TableCell>
                                    <TableCell className={classes.thBorder} align="left">{t("checkOut.certification")}</TableCell>
                                    <TableCell className={classes.thBorder} align="left">{t("checkOut.location")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lotData.map((item) => (
                                        <TableRow className={classes.thBorder}>
                                            <TableCell component="th" scope="row" className={classes.thBorder}>{item.lotNumber}</TableCell>
                                            <TableCell className={classes.thBorder}>{item.checkOutCost}</TableCell>
                                            <TableCell className={classes.thBorder}>{item.qtyCost}</TableCell>
                                            <TableCell className={classes.thBorder}>{item.qtyStorage}</TableCell>
                                            <TableCell className={classes.thBorder}>{item.certification}</TableCell>
                                            <TableCell className={classes.thBorder}>{item.location}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                </Table>
                            </TableContainer>
                            </Grid>
                        )
                            :
                            (<span></span>)
                        }
                    </Grid>

                    {type==="checkout"&&<Grid container className={classes.dashedLine}></Grid>}

                    {type==="checkout"&&<Grid item xs={12} className={classes.thirdPart}>
                        <Grid container>
                            <Grid item xs={12} >
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t("checkOut.refNo")}<span className={classes.required}>*</span>
                                </Typography>
                            </Grid>
                            {refFileItemData.map((item, index) => {
                                return (
                                    <Grid container>
                                        <Grid item md={2} xs={3} className={classes.thirdItemSpacing}>
                                            <Select
                                                fullWidth
                                                required={true}
                                                disabled = {submited}
                                                error={item.fileType.error}
                                                variant="outlined"
                                                value={item.fileType.value}
                                                onChange={(event) => fileTypeChangeHandler(index, event)}
                                                classes={{ select: classes.selectField }}
                                            >
                                                {fileTypeData && fileTypeData.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item md={2} xs={4} className={classes.thirdItemSpacing}>
                                            <TextField
                                                id="location_text"
                                                error={item.fileNo.error}
                                                value={item.fileNo.value}
                                                required={true}
                                                disabled = {submited}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                                onChange={(event) => fileNoChangeHandler(index, event)}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title="">
                                                <IconButton disabled = {submited} style={{ visibility: refFileItemData.length > 1 ? "visible" : "hidden" }} onClick={() => removeRefFileItemDataHandler(index)}>
                                                    <DeleteIcon size="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            <IconButton onClick={refFileItemDataAddHandler} disabled={submited} style={{ margin: '0 20px' }}>
                                <AddCircleIcon >
                                </AddCircleIcon>
                            </IconButton>

                            <Grid container>
                                <Grid item md={3} xs={5} className={classes.customMargin}>
                                    <Grid container item xs={12} >
                                        <Typography variant="body1" className={classes.itemTitle}>
                                            {t("checkOut.supplier")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Autocomplete
                                        id="supplier"
                                        freeSolo
                                        fullWidth
                                        disabled = {submited}
                                        value={supplier}
                                        options={[]}
                                        getOptionLabel={(option) => option?option.name:""}
                                        onChange={(event, newValue) => {
                                            setSupplier(newValue);
                                        }}
                                        // onInputChange={(event, newValue) => {
                                        //     setSupplier(newValue);
                                        // }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} margin="dense" onKeyDown={e=>{
                                                if(e.keyCode === 13){
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }
                                            }} variant="outlined" size="small" />
                                        )}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            let keyword = params.inputValue;
                                            if(keyword!==""&&keyword.length>0){
                                                companyList.map((item) => {
                                                    if (item.counterpartyName.toLowerCase().indexOf(keyword.toLowerCase())>=0) {
                                                        filtered.push({
                                                            id: item.orgId,
                                                            name: item.counterpartyName
                                                        })
                                                    }
                                                })
                                                if(filtered.length===0){
                                                    filtered.push({
                                                        id: '',
                                                        name: keyword
                                                    });
                                                }
                                            }
                                            return filtered;
                                        }}
                                    />
                                    </Grid>
                                </Grid>

                                <Grid item md={3} xs={6}>
                                    <Grid container item xs={12} >
                                        <Typography variant="body1" className={classes.itemTitle}>
                                            {t("checkOut.customer")}<span className={classes.required}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} >
                                        <Autocomplete
                                            id="customer"
                                            freeSolo
                                            fullWidth
                                            disabled = {submited}
                                            value={customer}
                                            options={[]}
                                            getOptionLabel={(option) => option?option.name:""}
                                            onChange={(event, newValue) => {
                                                setCustomer(newValue);
                                                if(newValue){
                                                    setCustomerError(false);
                                                }
                                                else{
                                                    setCustomerError(true);
                                                }
                                            }}
                                            // onInputChange={(event, newValue) => {
                                            //     setSupplier(newValue);
                                            // }}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    {option.name}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} margin="dense" error={customerError} onKeyDown={e=>{
                                                    if(e.keyCode === 13){
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }
                                                }} variant="outlined" size="small" />
                                            )}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                let keyword = params.inputValue;
                                                if(keyword!==""&&keyword.length>0){
                                                    companyList.map((item) => {
                                                        if (item.counterpartyName.toLowerCase().indexOf(keyword.toLowerCase())>=0) {
                                                            filtered.push({
                                                                id: item.orgId,
                                                                name: item.counterpartyName
                                                            })
                                                        }
                                                    })
                                                    if(filtered.length===0){
                                                        filtered.push({
                                                            id: '',
                                                            name: keyword
                                                        });
                                                    }
                                                }
                                                return filtered;
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {type==="checkout"&&<Grid container className={classes.dashedLine}></Grid>}

                    <Grid item xs={12} className={classes.forthPart}>
                        {type==="checkout"&&<Grid container>
                            <Grid item md={3} xs={5} className={classes.customMargin}>
                                <Grid container item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkOut.inspector")}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} >
                                    <TextField
                                        id="inspector_text"
                                        value={inspector}
                                        fullWidth
                                        disabled = {submited}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={classes.textField}
                                        onChange={inspectorChangeHandler}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item md={3} xs={6}>
                                <Grid container item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkOut.inspectionDate")}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} >
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled={submited}
                                            autoOk
                                            // disableToolbar
                                            disabled = {submited}
                                            id="date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            size="small"
                                            format="yyyy/MM/DD"
                                            InputAdornmentProps
                                            value={inspectionDate}
                                            onChange={date => inspectionDateChangeHandler(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>}

                        <Grid container>
                            {type==="checkout"&&<Grid item md={3} xs={5} className={classes.customMargin}>
                                <Grid container item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {type==="checkout"?t("checkOut.requestor"):t("reserve.requestor")}<span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} >
                                    <TextField
                                        id="requestor_text"
                                        error={requestorError}
                                        value={requestor}
                                        fullWidth
                                        disabled = {submited}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={classes.textField}
                                        onChange={requestorChangeHandler}
                                    />
                                </Grid>
                            </Grid>}

                            {type==="checkout"&&<Grid item md={3} xs={6}>
                                <Grid container item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkOut.warehouseStaff")}<span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} >
                                <Autocomplete
                                        freeSolo
                                        disabled={submited}
                                        fullWidth
                                        selectOnFocus
                                        size='small'
                                        className={classes.input}
                                        value={warehouseStaff}
                                        options={staffArray}
                                        onChange={warehouseStaffChangeHandler}
                                        getOptionLabel={(option) => option?option.username+"("+ option.email +")":""}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                            {option.username+"("+ option.email +")"}
                                            </React.Fragment>
                                        )}

                                        // onInputChange={handleManagerChange}
                                        
                                        renderInput={(params) => <TextField {...params} onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            }
                                        }} variant="outlined" error={warehouseStaffError} />}
                                        />
                                </Grid>
                            </Grid>}
                        </Grid>
  
                        <Grid container>
                            <Grid item xs={12} >
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t("checkOut.notes")}
                                </Typography>
                            </Grid>
                            <Grid container item md={10} xs={12}>
                                <Grid container>
                                    <TextField
                                        id="notes_text"
                                        value={notes}
                                        fullWidth
                                        margin="normal"
                                        disabled = {submited}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        rows={4}
                                        className={classes.textField}
                                        onChange={notesChangeHandler}
                                    />
                                </Grid>
                                {submitError!=="" && <Grid container alignItems="center" justifyContent="center" direction="row" style={{marginTop:'8px'}}>
                                    <Alert severity="error" style={{marginBottom:'6px'}}><Typography>{submitError}</Typography></Alert>
                                </Grid>}
                                <Grid container alignItems="center" justifyContent="center" direction="row" className={classes.btnBottom}>
                                    <Grid xs={4}>
                                        <Button variant="contained" className={classes.buttonSpacing} onClick={goBackHandler}>
                                            <Typography variant="subtitle2">
                                                {t("common.cancel")}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid xs={4}>
                                        {type==="checkout"&&<Button variant="contained" color="primary" className={classes.buttonSpacing}  disabled={!submited||history.location.state.operationType==='view'}  onClick={submitCheckOutFormHandler}>
                                            <Typography variant="subtitle2">
                                                {t("checkOut.title")}
                                            </Typography>
                                        </Button>}
                                    </Grid>
                                    <Grid xs={4}>
                                        <Button variant="contained" color="primary" className={classes.buttonSpacing} disabled={submited} onClick={generateOrderHandler}>
                                            <Typography variant="subtitle2">
                                                {type==="checkout"?t("checkOut.generate"):t('reserve.confirm')}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    
                                </Grid>
                                
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
                <Dialog open={qrCodeOpen} scroll="body" maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogContent classes={{ root: classes.qrCode, }}>
                          <QrReader
                            onResult={(result, error) => {
                              if (!!result) {
                                setSelGood(result?.text);
                                goodsData.map( (item)=> {
                                    if(item.goodsId === result?.text){
                                        inventoryService.getGoodDetailById(history.location.state.warehouseData.orgId,result?.text,history.location.state.warehouseData.warehouseId).then(result=>{
                                            if(result.status===200){
                                                item.availableStorage = result.data.availableStorage;
                                                setLotNoData(result.data.checkInDatas);
                                            }
                                        })
                                        setSelGoodInfo(item);
                                    }
                                })
                                setQrCodeOpen(false);
                              }
                              if (!!error) {
                                console.info(error);
                              }
                            }}
                            style={{ width: '100%' }}
                            constraints={{facingMode:'environment'}}
                          />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={e=>setQrCodeOpen(false)} color="primary">
                            {t('common.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            
        </div>
    )
}