import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        display: 'flex',
        flexFlow: 'column',
        color: theme.currentTheme.colors[1],
        backgroundColor:'#000',
    },
}))


function Footer(props){
    const classes = useStyles();
    return (
        <Grid container style={{maxWidth:'1200px',width:'100%'}}>
            <Grid item xs={6}>
                <Typography variant="body1" gutterBottom className={classes.text2}>@2023 openuse.co</Typography>
            </Grid>
            <Grid item xs={6}>
                <Grid container justifyContent="flex-end">
                    <Grid item xs={3}>
                        <Typography variant="body1" gutterBottom className={classes.footerText} style={{textAlign:'right'}}>Terms</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" gutterBottom className={classes.footerText} style={{textAlign:'right'}}>Privacy</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Footer;
