import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import useToken from "../hooks/useToken"
import Avatar from '@material-ui/core/Avatar';
import { useEffect } from 'react';
import { useState } from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      padding:'8px'
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],

    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    avatar: {
        width: '24px',
        height: '24px',
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        cursor: "pointer",
        fontSize: "1rem",
    }
  }));
export default function HelloUser() {
    const classes = useStyles();
    let {token} = useToken();
    const [shortName, setShortName] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');
    useEffect(()=>{
        if(token && token.user_info && token.user_info.username){
            const username = token.user_info.username;
            const names = username.split(' ');
            let abbr = "";
            if(names.length <= 1){
                abbr = username.charAt(0);
            }else{
                abbr = names[0].charAt(0) + names[1].charAt(0)
            }
            setShortName(abbr.toUpperCase());
        }
    },[token.user_info.username]);
    useEffect(()=>{
      if(token && token.user_info && token.user_info.avatar){
        const url = process.env.REACT_APP_CORE_API_BASE + '/public/avatar/'+ token.user_info.avatar;
        setAvatarUrl(url);
      }
    },[token, token.user_info.avatar])
    return (
        <div className={classes.root}>
            {/* <span className="title_small">{helloMessage()}</span> */}
            <Avatar className={classes.avatar} src={avatarUrl}>{shortName}</Avatar>
        </div>
    );
}