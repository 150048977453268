const langEN = {
  lang: '中文',
  changeLang: "Translate to 中文(简体)",
  common: {
    ok: 'Ok',
    cancel: 'Cancel',
    save: 'Save',
    confirm:'Confirm',
    actions:'Actions',
    delete: 'Delete',
    edit: 'Edit',
    noRecord: 'No records to display',
    download:'Download',
    check:'Check',
    add:'Add',
    home:'Home',
    export:'Export',
    exportAsCsv:'Export as CSV',
    duplucate:'Dupilcate',
    sharePO: 'Share PO',
    qrCode:'Genrate QRCode',
    print:'Print',
    settings: 'Settings',
    all_selected:'select all',
    all_not_selected:'deselect all',
    add_new_note: 'A new note has been added by $username.',
    review_notifications: 'Review Notifications',
    no_notifications: 'No notifications',
    mark_read: 'Mark Read',
    attach: 'Attachment',
    downloadAttachment: 'Download Attachment',
    noAttach: 'No attachments',
    shareBook: 'Share book',
    bookDistribute: 'Book distribute to',
    supplier: 'supplier',
    purchaser: 'purchaser',
    email: 'Email Address',
  },
  shareBook: {
    noUser: 'The email address you filled in is not a registered user of openuse platform and cannot share the book.',
    noTransactionOrg: 'This email user has no company organization and cannot share the book. Please tell the him/her to set the company organization in "Your organization".',
    companyOrgDesc: 'Book distribute to company organization',
    confirmEmail: 'Confirm email',
    unShare: 'Stop sharing',
    unShareDesc: 'After un-sharing, your shared data will not be recovered.',
    sharedToUser: 'Users who have shared',
    sharedToCompanyOrg: 'Company organizations who have shared',
  },
  header:{
    openOffice:'Open Office',
    liveOfficiating:'Live Officiating',
    liveSourcing:'Live Sourcing',
    liveAudit:'Live Auditing',
    liveParing:'Live Pairing',
    openBook:'Open Book',
    poTransactions:'PO Transactions',
    inventory:'Inventory',
    openTrace:'Open Trace',
    openComm:'Open Comm'
  },
  login: {
    signIn: 'SIGN IN',
    signUp: 'SIGN UP'
  },
  select: {
    selectOrg: 'Select Organization'
  },
  platformHomepage:{
    content1:'An open platform for enterprises in supply chain',
    content2:'We created this platform for the enterprises in supply chain to share using software as a service (SaaS). Today, the enterprises in supply chain are experiencing difficulties collaborating purchasers and suppliers through software. openuse.io empowers the enterprises to connect the purchasers and suppliers working together seamlessly. The purchasers and suppliers enjoy interacting each other through the SaaS such as Open Book, Open Office, Open Trace, and Open Comm hosted on the platform. For instance, Open Book logs the activities between a purchaser and a supplier so that both of the up-stream and down-stream keep clear outstanding purchase orders, account balances, inventories and shippings in a book. The platform continuously adds more SaaS developed not only by openuse.io but also the 3rd parties to provide best customer experience.',
    content2_1: 'We created this platform for the enterprises in supply chain to share using software as a service (SaaS). openuse.io empowers the enterprises to connect the purchasers and suppliers working together seamlessly. The purchasers and suppliers enjoy interacting each other through the SaaS such as',
    content2_2: ' Open Book,',
    content2_3: ' Open Office,',
    content2_4: ' Open Trace',
    content2_5: ' and ',
    content2_6: 'Open Comm',
    content2_7: ' hosted on the platform.',
    content3:'Trade fair is canceled？Travel is restricted?',
    content4:'What brands can you approach?',
    content5:'What covid-19 impact us?',
    content6:'What brands can you engage with?',
    content7:'Where to find manufacturers?',
    content8Header:'Live Sourcing',
    content8:'Live Sourcing is a solution for both of purchaser and supplier. Purchaser wants to vet supplier and supplier demonstrates its capability. Purchaser and supplier work together in the virtual rooms such as reception room, factory room, signing room, and meeting room on 24/7 base. There is no travel needed.',
    content9Header:'OPEN BOOK',
    po_transactions: 'PO TRANSACTIONS',
    inventory_control: 'INVENTORY CONTROL',
    live_sourcing: 'LIVE SOURCING',
    live_office: 'LIVE OFFICE',
    content9:'Open Book lets you create inventory control in detailed visibility into stock order, stock trend, and stock reservation. You can create an organization and invite any stakeholders to join using Inventory, for instance, sales team can generate the sales order through inventory control in real-time and so on.',
    content9_1:'Open Book lets you create the books which record the purchase order transactions. The book provides real time visibility at outstanding PO, account balance, and shipping status to both purchaser and supplier.',
    content9_2:'Open Book lets you create Inventory Control in detailed visibility into stock order, stock trend, and stock reservation. You can create an organization and invite any stakeholders to join using Inventory Control.',
    content10Header:'OPEN OFFICE',
    content10_1:'Live Sourcing is a solution for both of purchaser and supplier. Purchaser wants to vet supplier and supplier demonstrates its capability. Purchaser and supplier work together in the virtual rooms such as reception room, factory room, signing room, and sample room on 24/7 base. There is no travel needed.',
    content10_2:'Live Office facilitates a meta office for remote workers to work together in the virtual rooms such as cubicles, meeting room, coffee room, and entertaining room. The remote workers manage themselves through the \'activities\' they created in the meta office. Both productivity and inspiration increase significantly.',
    button:'Request Demo',
    button1:'SIGN UP for openuse.io',
    button2:'LEARN MORE',
    terms:'Terms',
    privacy:'Privacy'
  },
  homePageDemo: {
    title: 'I\'m interested in an openuse.io demo. Please contact me.',
    firstName: 'First Name',
    lastName: 'Last Name',
  },
  homePage: {
    selOrg: 'Select an organization',
    newOrg: 'New organization',
    warehouse: 'Warehouse list',
    management: 'Management',
    checkInOutMgt: 'Check-in/Check-out Mgt',
    resvationMgt: 'Resvation Mgt',
    goodsMgt: 'Goods Mgt',
    materialGoodsMgt: 'Material Goods Mgt',
    supplierMgt: 'Supplier Mgt',
    customerMgt: 'Customer Mgt',
    finishedGoods: 'Finished Goods',
    material: 'Material',
    transfer: 'Transfer',
    scraped: 'Scraped',
    id: 'ID',
    manager: 'Manager',
    phone: 'Phone',
    address: 'Address',
    notes: 'Notes',
    checkIn:'Check-in',
    checkOut:'Check-out',
    reserve:'Reservation',
    description1:'Inventory',
    description2:'is a service that manages the stock of products, components, materials, and scraps. It tracks the product movement, storage, and status in real time. It empowers the sales team to fulfill sales order and prioritize the most urgent orders. It provides an easy-to-use operation of check-in and check-out. It solves the problem of relying on Excel sheets controlled by one individual who struggles to communicate with sales, shipment, and accounting in emails, messages, or phone conversation.',
    createOrgTips:'Create your own organization, and you will find that you immediately have the right of admin to use Inventory.',
    addWarehouse:'Add warehouse'

  },
  poLearnMore: {
    title: 'PO Transactions',
    benefitsTitle: 'Benefits',
    benefits1: 'do you need a book keeping purchase orders instead of searching the PO attachments in email?',
    benefits2: 'do you need to understand the outstanding PO and account balance?',
    benefits3: 'do you need a vendor or customer reconciliation?',
    benefits4: 'are you worried about the disputes caused by lost PO transaction records?',
    experienceTitle: 'Experience',
    experience1: 'sign up on openuse•io platform',
    experience2: 'choose Open Book on the homepage, then select PO Transactions',
    experience3: 'create an organization',
    experience4: 'invite the stake holders to join your organization',
    experience5: 'enjoy working on Book with your stake holders',
    price: 'price',
    demo: 'request demo',
    video: 'https://www.youtube.com/embed/WtK4maGNVYc'
  },
  inventoryControlLearnMore: {
    title: 'Inventory Control',
    benefitsTitle: 'Benefits',
    benefits1: 'do you want your sales team to lock inventory in time to respond RFQ?',
    benefits2: 'do you want your inventory on tip of your hand and share with your stake holders?',
    benefits3: 'are you worried about data latency using excel sheets to manage inventory?',
    experienceTitle: 'Experience',
    experience1: 'sign up on openuse•io platform',
    experience2: 'choose Open Book on the homepage, then select Inventory',
    experience3: 'create an organization',
    experience4: 'invite the stake holders to join your organization',
    experience5: 'enjoy working on Book with your stake holders',
    price: 'price',
    demo: 'request demo',
    video: 'https://www.youtube.com/embed/KVQVifunyJw'
  },
  liveSourcingLearnMore: {
    title: 'Live Sourcing',
    benefitsTitle: 'Benefits',
    benefits1: 'as a purchaser, are you looking for a best supplier?',
    benefits2: 'as a supplier, do you want to add more purchasers or brands?',
    benefits3: 'are you restricted on international traveling or you want to reduce business travel?',
    benefits4: 'are you eager to visit the factories?',
    benefits5: 'are you expecting to see and touch the samples?',
    benefits6: 'do you want RFQ and other agreements to be signed in realtime?',
    benefits7: 'do you like to touch base with suppliers or purchasers on 24/7 base?',
    experienceTitle: 'Experience',
    experience1: 'sign up on openuse•io platform',
    experience2: 'choose Open Office on the homepage, then select Live Sourcing',
    experience3: 'create an organization',
    experience4: 'invite the stake holders to join your organization',
    experience5: 'enjoy interacting with the stake holders',
    price: 'price',
    demo: 'request demo',
    video: 'https://www.youtube.com/embed/MgD3oGqYVNA'
  },
  liveOfficeLearnMore: {
    title: 'Live Office',
    benefitsTitle: 'Benefits',
    benefits1: 'as a manager, do you want your remote workers collaborative, productive, and inspirational?',
    benefits2: 'as a manager, do you want your remote workers to be transparent?',
    benefits3: 'as a CEO, do you want your remote employees mutually trusted?',
    benefits4: 'as an employee, are you fatigue on video meeting and want a flexible workplace?',
    benefits5: 'as an employee, do you want your effort to be rewarded when work remotely?',
    experienceTitle: 'Experience',
    experience1: 'sign up on openuse•io platform',
    experience2: 'choose Open Office on the homepage, then select Live Office',
    experience3: 'create an organization',
    experience4: 'invite the stake holders to join your organization',
    experience5: 'enjoy working with your colleagues',
    price: 'price',
    demo: 'request demo',
    video: 'https://www.youtube.com/embed/MgD3oGqYVNA'
  },
  itemTotalStock: {
    title: 'Item Total Stock',
    inputHint: 'Input Goods ID/Goods Name',
    goodsId: 'Goods ID',
    name: 'Name',
    description: 'Description',
    spec: 'Spec',
    currency: 'Currency',
    rev: 'Rev',
    unitforStorage: 'UnitforStorage',
    unitforCost: 'UnitforCost',
    totalQty: 'Total QTY',
    totalCost: 'Total Cost',
    quantity: 'Quantity',
    lotCost: 'LotCost',
    lotNum: 'Lot Num',
    checkInDate: 'CheckInDate',
    location: 'Location',
    notes: 'Notes',
  },
  itemBatchStock: {
    title: 'Item Batch Stock',
    inputHint: 'Input Lot Number',
    goodsId: 'Goods ID',
    name: 'Name',
    description: 'Description',
    spec: 'Spec',
    currency: 'Currency',
    lotNum: 'Lot Num',
    checkInDate: 'CheckInDate',
    location: 'Location',
    totalLotQty: 'Total Lot QTY',
    totalLotCost: 'Total Lot Cost',
  },
  warehouse: {
    addHouse: 'Add Warehouse',
    name: 'Name',
    id: 'ID',
    manager: 'Manager',
    phone: 'Phone',
    address: 'Address',
    notes: 'Notes',
    editHouse:'Edit Warehouse',
    delHouse: 'Delete Warehouse',
    staff: 'Staff',
    deleteConfirmation: 'Are you sure you want to delete the Warehouse?',
    deleteTitle: 'Delete warehouse confirm',
    noRecord: 'You do not have any stock line.',
    viewHouse:'View warehouse',
    isMaterial:'Material?'
  },
  goodsManagement:{
    title:'Goods Management',
    searchHint:'Search by ID/Name',
    addFormTitle:'Add Goods',
    editFormTitle:'Edit Goods',
    goodsId:'Goods ID',
    goodsName:'Goods Name',
    category:'Category',
    description:'Description',
    rev:'Rev',
    spec:'Spec',
    eSpec:'Type/Shape/Grade/Dimension',
    standard:'Standard',
    unitforStorage:'UnitforStorage',
    unitforCost:'UnitforCost',
    currency:'Currency',
    notes:'Notes',
    deleteConfirm:'Are you sure delete this goods?',
    addButton:'Add goods',
    uploadButton:'Batch upload',
    batchUploadResultTitle:'Batch upload result',
    unSupportCsv:'Ooops! This is not the supported CSV format. Please try again for another one.',
    errorTips1:'OutStorage greater than zero，cannot do this change！'
  },
  mGoodsManagement:{
    title:'Material Goods Management',
    goodsType:'Type',
    brand:'Grade',
    shape:'Shape',
    dimension:'Dimension'
  },
  materialGoods:{
    goodsType:'Type*',
    goodsGrade:'Grade',
    goodsShape:'Shape',
    goodsDimension1:'Dimonsion1',
    goodsDimension2:'Dimonsion2',
    goodsDimension3:'Dimonsion3',
    dimDiameter:'Diameter',
    dimStickness:'Stickness',
    dimWall:'Wall thickness',
    dimLength:'Length',
    dimWidth:'Width',
    dimSize1:'Size',
    dimSize2:'Size',
    dimWantonly:'Arbitrary',
    typelistSteel:'Steel',
    typelistStainless:'Stainless Steel',
    typelistAl:'AI (AL, aluminum)',
    typelistTi:'Ti (titanium)',
    typelistNi:'Ni (nickel)',
    typelistLi:'Li (lithium)',
    typelistZr:'Zr (zirconium)',
    typelistCu:'Cu (Copper, Cuprum)',
    shapelistBar:'Bar',
    shapelistSeamless:'Seamless tube',
    shapelistWelding:'Welding tube',
    shapelistSheet:'Sheet',
    shapelistStrip:'Strip-Ingot-Square bar',
    shapelsitWire:'Wire',
    shapelistGrain:'Grain',
    shapelistPowder:'Powder',
    shapelistSpecial:'Special shape',
    shapelistOthers:'Others',
  },

  customerManagement:{
    title:'Customer Management',
    searchHint:'Search by ID/Name',
    addFormTitle:'Add Customer',
    id:'ID',
    name:'Name',
    contact:'Contact',
    phone:'Phone',
    address:'Address',
    notes:'Notes',
    deleteConfirm:'Are you sure delete this company?'
  },
  supplierManagement:{
    title:'Supplier Management',
    searchHint:'Search by ID/Name',
    addFormTitle:'Add Supplier',
    id:'ID',
    name:'Name',
    contact:'Contact',
    phone:'Phone',
    address:'Address',
    notes:'Notes',
    deleteConfirm:'Are you sure delete this company?'
  },
  checkInOutManagement:{
    title:'Check-in/Check-out Management',
    searchHint:'Search by ID/Name',
    searchHint1:'Search by Lot Num',
    addFormTitle:'Add Goods',
    type:'Type',
    warehouse:'Warehouse',
    goodsIdName:'Goods ID/Name',
    checkDate:'CheckDate',
    lotNum:'Lot Num',
    warehouseStaff:'Warehouse Staff',
    requestor:'Requestor',
    billNumber:'Bill Number',
    status:'Status',
    supplier:'Supplier',
    customer:'Customer',
    createTime:'Create Date',
    optionCheckIn:'Check In',
    optionCheckOut:'Check Out',
    refBillNumber: 'Reference Bill Number',
    deleteButton:'Cancel check-in/Check-out'

  },
  checkIn: {
    title: 'Check In',
    billNo: 'Bill Number',
    refBillNo: 'Reference Bill Number',
    goodsIdOrName: 'Goods ID/Name',
    category: 'Category',
    description: 'Description',
    rev: 'Rev',
    spec: 'Spec',
    standard: 'Standard',
    unitforCost: 'UnitForCost',
    unitforStorage: 'UnitForStorage',
    currency: 'Currency',
    lotNo: 'Lot Number',
    refLotNo: 'Reference Lot Number',
    checkInCost: 'Check-in Cost',
    qtyForCost: 'QTYforCost',
    qtyForStorage: 'QTYforStorage',
    certification: 'Certification',
    location: 'Location',
    refNo: 'Reference Number',
    supplier: 'Supplier',
    customer: 'Customer',
    inspector: 'Inspector',
    inspectionDate: 'Inspection Date',
    requestor: 'Requestor',
    warehouseStaff: 'Warehouse Staff',
    notes: 'Notes',
    generate: 'Generate Order',
    billOrder: 'Bill Number：',
    generated: 'has been generated',
    checkInBillNumerExist: 'The inBillNumber existed, please re-genrate a new number',
    LotNumberAlreadyExists:'Lot number already exists, please select the correct goods.',
    calculatedQTYforCostInconsistent:'The calculated (QTYforCost : QTYforStorage) is inconsistent with the same Lot number check-in last time.',
    calculatedUnitpriceInconsistent:'The calculated unit price is inconsistent with the same Lot number check-in last time.',
    po:'PO',
    so:'SO',
    wo:'WO',
    sncr:'SNCR',
    rma:'RMA',
    other:'Other'

  },
  checkOut: {
    title: 'Check Out',
    billNo: 'Bill Number',
    refBillNo: 'Reference Bill Number',
    goodsIdOrName: 'Goods ID/Name',
    category: 'Category',
    description: 'Description',
    rev: 'Rev',
    spec: 'Spec',
    standard: 'Standard',
    unitforCost: 'UnitForCost',
    unitforStorage: 'UnitForStorage',
    currency: 'Currency',
    lotNo: 'Lot Number',
    refLotNo: 'Reference Lot Number',
    checkOutQtyForStorage: 'Check-out QTYforStorage',
    checkOutTotalCost: 'Check-out Total Cost',
    checkOutCost: 'Check-out Cost',
    qtyForCost: 'QTYforCost',
    qtyForStorage: 'QTYforStorage',
    certification: 'Certification',
    location: 'Location',
    refNo: 'Reference Number',
    supplier: 'Supplier',
    customer: 'Customer',
    inspector: 'Inspector',
    inspectionDate: 'Inspection Date',
    requestor: 'Requestor',
    warehouseStaff: 'Warehouse Staff',
    notes: 'Notes',
    generate: 'Generate Order',
    billOrder: 'Bill Number：',
    generated: 'has been generated',
    checkOutQtyExceeds: 'Check-out QTY exceeds the total QTY',
    avaliableQty:'Avaliable QTY',
    checkOutBillNumerExist: 'The outBillNumber existed, please re-genrate a new number'

  },
  reserve: {
    title: 'Reservation',
    refBillNo:'Reference Number',
    reserveDate:'Reserve days',
    requestor:'Requestor',
    confirm:'Reserve',
    number:'QTY',
    cancel:'Cancel reservation',
    checkOutQtyForStorage: 'Reservation QTYforStorage',
    checkOutTotalCost: 'Reservation Total Cost',
    checkOutCost: 'Reservation Cost',
    checkOut: 'Check Out',
  },
  poManagementHomepage:{
    outstandingBook:'Outstanding Book',
    closedBook:'Closed book',
    searchByPONumber:'Search by PO number',
    balance:'Balance',
    addBook:'Add Book',
    bookTitle:'Book Title',
    poDate:'PO Date',
    purchaserName:'Purchaser name',
    purchaserMember:'Purchaser member',
    supplierName:'Supplier name',
    supplierMember:'Supplier member',
    bookOwner:'Book owner',
    closedDate:'Closed Date',
    poTotal:'PO Total',
    outstandingPO:'Outstanding PO',
    invoice:'Invoice',
    payment:'Payment',
    delivery:'Shipping',
    issuePO:'Issue a PO',
    closeBook:'close Book',
    deleteBook:'delete book',
    checkInventory:'Check inventory',
    addAlarm:'Alarm management',
    addLineRef:'Ref management',
    closeLine:'Close libe',
    description1:'PO Transaction',
    description2:'is an accountant book open (transparent) between the purchasers and suppliers. It logs the original purchase order, revisions, confirmation, invoices, and all the activities. It solves the problem of losing PO, revision, confirmation, invoice and etc in emails, attachments, messages, or phone confirmations. PO Transaction is built using a new technology, Event Sourcing, which makes each transaction immutable, and thus it provides the source of truth for looking up if needed.',
    createOrgTips:'Create your own organization, and you will find that you immediately have the right of admin to use PO Transaction.',
    downloadPo:'Download PO',
    bookMenu:'Subledger',
    totalBookMenu:' General ledger',
    invoiceCheck:'Invoice Check',
    bookingDate:'Booking date',
    bookingDateWidth: '110px',
    invoiceDate: 'Invoice date',
    requestDate: 'Request date',
    invoiceDateWidth: '110px',
    all: 'All',
    notInvoiced: 'Not invoiced',
    unpaid: 'Unpaid',
    paid: 'Paid',
    qtyAlarm: 'The modified qty is less than the quantity shipped in this line',
  },
  newBookPage:{
    newBook:'New book',
    purchaserName:'Purchaser name',
    purchaserMember:'Purchaser member',
    supplierName:'Supplier name',
    supplierMember:'Supplier member',
    title:'Title',
    sales:'Sales',
    accounting:'Accounting',
    buyer:'Buyer',
    warehouse:'Warehouse',
    orgMemberError:'Only organization members can be added to the book',
    purchaserTips:'This usually referes to the purchaser company name',
    supplierTips:'This usually referes to the supplier company name',
    searchTips:'search by email',
    confirmButton:'Confirm and issue a PO',
    titleTips:'select title',
    editBook:'Edit book'

  },
  viewBookPage:{
    viewBook:'View Book',
    creator:'Creator',
    createTime:'Create time',
    lastUpdate:'Last update',
    status:'Status'
  },
  closeBookPage:{
    closeBook:'Close book',
    closeBookContent:'Are you sure you want to close the book?',
  },
  deleteBookPage:{
    deleteBook:'Delete book',
    deleteBookContent:'Are you sure you want to delete the book?',
  },
  poFormPage:{
    importExcel: 'Import excel',
    poForm:'PO form',
    poNumber:'PO number',
    customer:'Customer',
    refNumber:'Reference number',
    rev:'Rev',
    deliveryTerm:'Delivery term',
    paymentTerm:'Payment term',
    poDate:'PO date',
    supplierCode:'Supplier code',
    supplierPhoneNumber:'Supplier phone number',
    currency:'Currency',
    supplierAddress:'Supplier address',
    shippingAddress:'Shipping address',
    line:'Line',
    item:'Item',
    product:'Product',
    QTY:'Qty',
    unit:'Unit',
    storageUnit:'Storage',
    price:'Price',
    tax:'Tax',
    discount:'Discount',
    lineTotal:'Line total',
    date:'Date',
    alarmDate:'Alarm date',
    alarmNotes:'Alarm notes',
    alarmOn:'Alarm on',
    alarmTrue:'Yes',
    alarmFalse:'No',
    lineRefNO:'Ref NO.',
    actions:'Actions',
    notes:'Notes',
    scan:'Scan',
    scanTitle:'Scan to help you pre-fill the PO form.',
    warningTitle: 'Tips for importing excel',
    warningTips1: 'There are incomplete, duplicate lines and date format incorrect, these lines will not be imported!',
    warningTips2: 'There are incomplete and duplicate lines, these lines will not be imported!',
    warningTips3: 'There are incomplete and date format incorrect, these lines will not be imported!',
    warningTips4: 'There are some duplicate lines and date format incorrect, these lines will not be imported!',
    warningTips5: 'There are incomplete, these lines will not be imported!',
    warningTips6: 'There are some lines which date format are incorrect, these lines will not be imported!',
    warningTips7: 'There are some duplicate lines, these lines will not be imported!',
    fileOverwrite:'file overwrite',
    fileOverwriteTips:'are you sure to overwrite the existing data?',
    poNumberExistTips:'The PO Number already exists.',
    outstandingPO:'outstanding PO',
    checkInventory:'check inventory',
    unSupportPDFTitle:'Scan File',
    unSupportPDF:'Ooops! This is not the supported PDF format. Please try again for another one.',
    supplierName:'Supplier name',
    purchaserName:'Purchaser name',
    refLines:'Ref Lines',
    noLines:'No Ref Lines',
    addRefLine:'Add Ref',
    refAmount:'Ref Amount',
    display_column: 'Select the columns to display',
    po_note: 'PO line Note',
  },
  invoicePage:{
    addInvoice:'Add invoice',
    poNumber:'PO number',
    paymentTerm:'Payment term',
    currency:'Currenty',
    invoiceNumber:'Invoice number',
    invoiceDate:'Invoice date',
    invoiceTotalAmount:'Invoice total amount',
    purchaserName:'Purchaser name',
    purchaserTINEINVAT:'Purchaser TIN/EIN/VAT',
    purchaserID:'purchaser ID',
    supplierName:'Supplier name',
    supplierTINEINVAT:'Supplier TIN/EIN/VAT',
    TINTips:'The TIN/EIN/VAT you fill in will be used as the reference value of all subsequent invoices of this PO, please double check.',
    errorTips1:'Please note that the invoiced line total is inconsistent with PO line total.',
    errorTips2:'Please select at least one line item',
    notes:'Notes',
    status:'Status',
    checked:'Checked',
    billed:'Billed',
    paid:'Paid',
    line:'Line',
    noInvoiceLine: 'You do not have any invoice line.',
    existedInvoiceNumber:'Existed Invoice Number',
    statementNumber:'Statement Number',
    statementDate:'Statement Date',
    statementAmount:'Statement Amount',
    statementButton:'Statement',
    invoiceButton:'Invoice',
    totalAmount:'Total Amount',
    searchByStatementOrInvoice:'Statement or Invoice number',
    cancelStatementTips:'Are you sure you want to delete the statement?',
    generateNumber:'Genrate Statement Number',
    invoiceNumberExistTips:'invoice number already exist, please input another invoice number',
  },
  paymentPage:{
    addPayment:'Add payment',
    poNumber:'PO number',
    poDate:'PO date',
    currency:'Currency',
    paymentActions:'Payment actions',
    deposit:'deposit',
    invoice:'Invoice',
    paymentAmount:'Payment amount',
    errorTips1:'Please note that the payment amount is inconsistent with the invoice total.',
    errorTips2:'PO balance cleared. You can close this book by clicking the upper right button.',
    errorTips3:'Please select at least one line',
    notes:'Notes',
    user:'User',
    invoiceNumber:'Invoice number',
    noPaymentLine: 'You do not have any payment line.',
    batch:'Pay by Invoice',

  },
  shippingPage:{
    addShipping:'Add shipping',
    getShipping:'Received',
    noShippingLine:'You do not have any shipping line.',
    invoiceNumber:'Invoice number',
    shippingNumber:'Shipping number',
    shippingDate :'Shipping date',
    shippingQTY:'Total QTY',
    shippingAddressFrom:'Shipping from',
    shippingAddressTo:'Shipping to',
    errorTips2:'Please select at least one line item',
    errorTips1:'Please note that the shipping line number is inconsistent with PO line total number.',
    status:'Status',
    shipped:'Shipped',
    received:'Received',
    exportContractNo:'export Contract No.',
    baoguanNo:'Request No.',
    baoguanDate:'Request Date',
    waimaoxinxi:'Export declaration ',
    btnShipping: 'Shipping',
    btnShippingAndDeclare: 'Shipping and Declare',
    btnConfirmRequest: 'Confirm',
    btnCancelRequest: 'Cancel',
  },
  outstandingBalancePage:{
    outstandingBalance:'Outstanding Balance',
    outstandingLineBalance:'outstanding line balance',
    actions:'Actions',
    line:'Line',
    receivable:'Receivable',
    payable:'payable',
    received:'received',
    paid:'paid',
    balance:'Balance',
    notes:'notes',
    timestamp:'timestamp',
    ClosePOLineBySystem:'Close PO line by system',
    action1:'New PO Line',
    action2:'Cancel PO Line',
    action3:'Revise PO Line Qty',
    action4:'Revise PO Line Date',
    action5:'Close PO Line',
    action6:'New Invoice Line',
    action7:'Invoice Payment',
    action8:'Deposit Payment'
  },
  poLineManagement:{
    poLineManagement:'PO line management',
    activeLine:'active line',
    closedLine:'closed line',
    issueInvoice:'Issue invoice',
    issuePayment:'Deposit Payment',
    taxReconciliation:'Tax reconciliation',
    issueShipping:'Shipping',
    date:'Date',
    actualDate:'Invoiced Date',
    shipDate:'Shipping Date',
    shipped:'Shipped',
    invoiced:'Invoiced',
    notShipped:'Not shipped',
    notInvoiced:'Not invoiced',
    all:'All',
    balance:'Balance',
    expand: 'Expand',
    collapse: 'Collapse',
    errorTipsNoLineSelected:'请先选择您要操作的行号！',
    errorTipsAlreadyShpped:'您选择的行号里包含已发货的行，请重新选择后再试',
    errorTipsAlreadyInvoiced:'您选择的行号里包含已开票的行，请重新选择后再试',
    errorTipsNoPermission:'您选择的行号里包含无权限操作的行，请重新选择后再试。',
    errorTipsAlreadyClosed:'您选择的行号里包含已关闭的行，请重新选择后再试。',
    errorTipsNotSameCustomer:'您选择的行号里包含不同的采购商，请重新选择后再试。',
    errorTipsNotSameCurrency:'您选择的行号里包含不同的货币，请重新选择后再试。',
    errorTipsNotSameSupplier:'您选择的行号里包含不同的供应商名称，请重新选择后再试。',
    errorTipsNotSameSupplierTax:'您选择的行号里包含不同的供应商税号，请重新选择后再试。',
    errorTipsNotSamePurchaserTax:'您选择的行号里包含不同的采购商税号，请重新选择后再试。',
    errorTipsAlreadyChecked:'您选择的行号里包含已完全对账/开票的行，请重新选择后再试'
  },
  myLogoMenu:{
    yourProfile:'Your profile',
    yourOrg:'Your organizations',
    technicalSupport: 'Help',
    signOut:'Sign out',
  },
  profileMenu:{
    Profile:'Profile',
    accountSecurity:'Account security',
    Signature:'Signature',
    Avatar:'Avatar',
  },
  myProfile:{
    personalProfile:'Personal profile',
    Name:'Name',
    Email:'Email',
    updateButton:'Update',
    infoMsg:'Your name may appear around openuse.io where you contribute or are mentioned. You can change it at any time.',
  },
  accountSecurity:{
    changePsd:'Change password',
    oldPsd:'Old password',
    newPsd:'New password',
    infoMsg1:'Strong passwords or passphrases are important for security. Your password must use:',
    infoMsg2:'Minimum 8 characters',
    infoMsg3:'1 Letter',
    infoMsg4:'1 Number',
    confirmPsd:'Confirm new password',
    updatePsdButton:'Update password',
    errorMsg1:'This field is required.',
    errorMsg2:'Sorry, the password is not qualified!',
    errorMsg3:'Sorry, the two passwords that you entered do not match!',
    errorMsg4:'Change password failed.',
    errorMsg5:'The original password is incorrect.',
    errorMsg6:'The new password is incorrect.',
    errorMsg7:'The old password is incorrect.'
  },
  mySignature:{
    Signature:'Signature',
    yourSignature:'Your signature',
    updateSigButton:'Update signature',
  },
  myAvatar:{
    Avatar:'Avatar',
    changeAvaButton:'Change avatar',
    avaMsg:'File type accepted : JPG , JPEG, PNG, 2MB at max , reject if over size.',
    Zoom:'Zoom',
    cancelButton:'Cancel',
    previewButton:'Preview',
    updateButton:'Update',
  },
  myOrgtab: {
    Org: 'Organizations',
    orgInvitation: 'Org invitations',
    newOrg: 'New organization',
    orgPayment: 'Payment',
    companyOrg: 'Company Organization'
  },
  myOrg:{
    editOrgHint:'Edit',
    deleteOrgHint:'Delete',
    searchEmail:'Search by display name or email address',
    inviteMsg:'Invite (“{email}”) to join openuse.io',
    Title:'Title',
    Role:'Role',
    Email:'Email',
    Status:'Status',
    roleValueadmin:'Org admin',
    roleValuemanager:'Org manager',
    roleValuemember:'Org member',
    addMemberHint:'Add',
    deleteMemberHint:'Delete',
    status1:'Invited',
    status2:'Joined',
    expireDate:'Trail expired',
    paySuccessTitle:'Success',
    paySuccessContent:'Change package success.',
    payConfirmTitle:'Upgrade payment confirmation',
    payConfirmContent1:'We will upgrade the service through the credit card with the mantissa of',
    payConfirmContent2:'for which you have signed the monthly payment service. The upgrade payment will be paid for the difference in the current month. The new service will take effect immediately after the payment is successful.',
    payConfirmContent3:'Jumping to third-party payment',
  },
  myInvite:{
    noInviteMsg:'You have no new invitation!',
    createTime:'Create Time',
    orgName:'Org name',
    serviceName:'Service name',
    servicePackage:'Service package',
    Inviter:'Inviter',
    Title:'Title',
    Role:'Role',
    joinButton:'Join',
  },
  userReg:{
    signUp:'Sign up',
    startWork:'to start working',
    Email:'Email*',
    emailMsg1:'Please input an email',
    emailMsg2:'Please input an valid email',
    emailError:'User email is already existed',
    newPsd:'Password*',
    infoMsg1:'Strong passwords or passphrases are important for security. Your password must use:',
    infoMsg2:'Minimum 8 characters',
    infoMsg3:'1 Letter',
    infoMsg4:'1 Number',
    passMsg1:'Please input a password',
    passMsg2:'Password is not qualified',
    rePsd:'Re-Password*',
    repsdMsg:'Please confirm your password',
    errorMsg3:'Sorry, the two passwords that you entered do not match!',
    company:'Company',
    name:'User name*',
    nameMsg: 'Please input the user name',
    location:'Location*',
    locationMsg: 'Please select your location',
    userExist: 'This user is already existed',
    pendingTitle:'Welcome!',
    pendingContent:'Your account is pending for approval, please wait for notification sent to your email.',
    successContent1:'We are so happy to have you on board.',
    successContent2:'You can start using by login.',
    successContent3:'Back to login',
    termsPrivacy:'By creating an account, you agree to the Terms of Service. For more information about openuse.io\'s privacy practices, see the openuse.io Privacy Statement. We\'ll occasionally send you account-related emails.'

  },
  newOrg:{
    createOrg:'Create your organization',
    createMsg:'Create your own organization, and you will find that you immediately have the right of admin to use service. Please remember to manage your organization in "Your organization" in the upper right corner.',
    orgTitle:'Organization title',
    serviceCategory:'Service category',
    Service:'Service',
    Service1:'Open book',
    Service2:'Open comm',
    Service3:'Open office',
    servicePackage:'Service package',
    servicePackage1:'PO Transactions',
    servicePackage2:'Inventory',
    servicePackage3:'Live sourcing',
    servicePackvalueTrial:'30 days trial subscription',
    servicePackvalueMonth:'Monthly subscription',
    servicePackComm1:'max 10 meeting attendees',
    servicePackComm2:'10+ meeting attendees',
    trialButton:'90 days trial',
    trialButton1:'30 days trial',
    payButton:'Pay',
    coupon:'please input coupon',
    payment:'payment',
    successPageTips:'Pay success，will redirect in 3 seconds',
    failPageTips:'Pay fail redirect in 3 seconds',
    contactTitle:'Contact sales',
    contactTips:'Please contact sales at product@openuse.io.',
    orgExpired:'Organization Expired',
    orgExpired1:'Trial expired, please contact organization admin to extend the service.',
    orgExpired2:'Would you like to extend your trial for another 30 days?',
    orgExistError:'Organization title duplicated, please choose another one',
    openbookPackage1:'month basic package/ 5 users',
    openbookPackage2:'month premium package/ 20 users',
    openbookPackage3:'year basic package/ 5 users',
    openbookPackage4:'year premium package/ 20 users',
    liveSourcingPackage1:'month basic package/ 10 users',
    liveSourcingPackage2:'month basic package/ 20 users',
    liveSourcingPackage3:'month basic package/ 30 users',
    liveSourcingPackage4:'month premium package/ 10 users',
    liveSourcingPackage5:'month premium package/ 20 users',
    liveSourcingPackage6:'month premium package/ 30 users',
  },
  toolbar:{
    switchDark:'Switch to dark theme',
    switchLight:'Switch to light theme',
    notification:'Notification',
    schedule:'Schedule',
    price:'Pricing'
  },
  delorgMember:{
    delMember:'Delete Member',
    infoMsg:'Are you sure to delete the member',
    button1:'Confirm',
    button2:'Cancel',
  },
  editOrganization:{
    editOrg:'Edit Organization',
    orgTitle:'Organization title',
    serCat:'Service category',
    service:'Service',
    button1:'Confirm',
    button2:'Cancel',
    coupon:'Use coupon',
  },
  delOrganization:{
    delOrg:'Delete Organization',
    infoMsg:'Are you sure to delete the org',
    infoMsg1:'The deleted organization will also unsubscribe the payment binding of your service package.',
    button1:'Confirm',
    button2:'Cancel',
  },
  paymentList:{
    createTime:'Create time',
    orgName:'Organization name',
    serviceName:'Service name',
    servicePackage:'Service package',
    paymentType:'Payment',
    price:'Price',
    status:'Payment status',
    enableStatus:'Service status',
    retry:'retry',
    noPaymentLine:'You don\'t have payment record！',
    enableTrue:'Active',
    enableFalse:'Deactive',
  },
  pricePage:{
    title:'Openuse.io is a digital platform for upstream and downstream enterprises in the supply chain to share services.',
    openCommTitle:'Open Comm',
    openCommContent:'A multi-functional video conference that provides agenda, tasks, signatures, meeting minutes, etc.',
    openCommFree:'Free',
    openCommButton:'Free Trial',
    OpenBookTitle:'Open Book',
    OpenBookContent:'A service that provides supply chain enterprises with whole chain cooperation from Po to Po, with special attention to the cooperation of inventory management.',
    OpenBookService1:'PO Transactions',
    OpenBookService1Price1:'Basic $100.00/month',
    OpenBookService1Price2:'Premium $200.00/month',
    OpenBookService2:'Inventory',
    OpenBookService2Price1:'Basic $50.00/month',
    OpenBookService2Price2:'Premium $100.00/month',
    OpenOfficeTitle:'Open Office',
    OpenOfficeContent:'A service that provides live sourcing, meta office, online audit and matching for supply chain enterprises.',
    OpenOfficeService1:'Live sourcing',
    OpenOfficeService1Price1:'Basic  $50.00/10/user/month',
    OpenOfficeService1Price2:'       $90.00/20/user/month',
    OpenOfficeService1Price3:'       $120.00/30/user/month',
    OpenOfficeService1Price4:'Premium $100.00/10/user/month',
    OpenOfficeService1Price5:'       $180.00/20/user/month',
    OpenOfficeService1Price6:'       $240.00/30/user/month',
    tailButton:'Trial',
    payButton:'Buy',
  },
  liveSourcingMain:{
    createOrgTips:'Create your own organization, and you will find that you immediately have the right of admin to use Live Sourcing.',
    description:'Sourcing is a process of vetting, selecting, and managing suppliers who can provide the inputs an enterprise needs for day-to-day running. Live Sourcing facilitates a virtual office which enables the communication between purchaser and supplier on 24/7 base. In this virtual office, purchaser can meet supplier “face-to-face” to create and execute the strategy; purchaser can tour the factories and define the metrics of quality and quantity. purchaser or supplier can host meetings to discuss how to meet supplier’s criteria; and supplier can also sign the agreements or contracts “on-site” with purchaser. All the activities can be done in a office with purchaser and supplier as office-mates.'
  },
  countries: {
    cn: "China",
    us: "United States of America",
    other: "Other"
  }
}
export default langEN;
