import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    button: {
        margin: theme.spacing(1),
        // padding: theme.spacing.unit
      },

    
      circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(1),
      },
  }));

export default function LoadingButtons(props) {
  const classes = useStyles();
  return (
      <Button variant="contained" color="primary" className={props.className ? props.className : classes.button} disabled={props.isLoading} onClick={props.onClick}>
        {props.isLoading&&<CircularProgress className={classes.circularProgress} size={20}/>}
        
        {props.children}
      </Button>
  );
}