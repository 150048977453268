import {Button, Popover, TextField, Grid, Typography, useMediaQuery} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import useToken from "../../hooks/useToken";
import PlatformHomeDemo from './platform-home-demo';
import { makeStyles } from '@material-ui/core/styles';
import platformHomePic11 from "../../assets/img/platform_home_1_1.gif";
import platformHomePic21 from "../../assets/img/platform_home_2_1.png";
import homepage1 from "../../assets/img/homepage1.svg";
import homepage2 from "../../assets/img/homepage2.svg";
import homepage3 from "../../assets/img/homepage3.png";
import homepage4 from "../../assets/img/homepage4.svg";

import homepage_bg_1_3 from "../../assets/img/homepage_bg1@3x.png";
import homepage_bg_2_3 from "../../assets/img/homepage_bg2@3x.png";
import homepage_bg_3_3 from "../../assets/img/homepage_bg3@3x.png";
import homepage_bg_4_3 from "../../assets/img/homepage_bg4@3x.png";

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        display: 'flex',
        flexFlow: 'column',
        color: theme.currentTheme.colors[1],
        backgroundColor: 'black',
    },
    box_base_1: {
        display: 'flex',
        flexFlow: 'column',
        backgroundColor:'#000',
        color: theme.currentTheme.colors[1],
        backgroundImage: `url(${platformHomePic11})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        justifyContent: 'center',
        alignItems: 'center',
        height: '630px',
        [theme.breakpoints.down('sm')]:{
            justifyContent: 'flex-start',
            height: '220px',
            backgroundSize: "100% 100%",
        }
    },
    box1: {
        display: 'inline-flex',
        flexFlow: 'column',
        maxWidth: '1200px',
        padding: '1px',
        [theme.breakpoints.down('sm')]:{
            minHeight: '200px',
            justifyContent: 'center',
        },

    },
    text: {
        color: "#ffffff",
        fontFamily: 'DinBold',
        letterSpacing: '-1.59px',
        fontSize: '80px',
        marginTop: "10%",
        textAlign: 'center',
        [theme.breakpoints.down('sm')]:{
            fontSize: '40px',
            lineHeight: 'unset',
        },
    },
    text1: {
        color: "#ffffff",
        fontFamily: 'DinBold',
        marginTop: "2%",
        fontSize: '24px',
        letterSpacing: '0.08px',
        textAlign: 'center',
        lineHeight: '22px',
        [theme.breakpoints.down('sm')]:{
            fontSize: '16px',
            width: '100%',
            margin: '30px auto 0 auto',
        }
    },
    text2: {
        marginTop: "2%",
        textAlign: 'center',
        fontSize: '18px',
        letterSpacing: '0.38px',
        color: 'white',
        lineHeight: '1.63',
        [theme.breakpoints.up('md')]:{
            paddingLeft: '18%',
            paddingRight: '18%',
        },
        [theme.breakpoints.down('sm')]:{
            fontSize: '16px',
            width: '85%',
            margin: '20px auto 0 auto',
            lineHeight: '22px',
            letterSpacing: '0.29px',
            textAlign: 'start',
            '@media only screen and (min-width: 700px) and (max-width: 1200px)':  {
                width: '62.66%',
            },
        },

    },
    footertext1: {
        color: '#acacac',
        fontFamily: 'DinRegular',
        [theme.breakpoints.down('sm')]:{
            marginRight: "20px",
        }
    },
    input: {
        backgroundColor: theme.currentTheme.backgrounds[3],
        width: "42%",
        [theme.breakpoints.down('sm')]:{
            width: "100%",
        },
    },
    form: {
        width: '100%',
        marginTop: "2%",
        marginBottom: "9%",
        display: "flex",
        alignItems: "center",
        maxWidth: '1200px',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]:{
            justifyContent: 'center'
        }
    },
    button: {
        height: '52px',
        [theme.breakpoints.up('md')]:{
            marginLeft: '12px',
        },
        [theme.breakpoints.down('sm')]:{
            width: '100%',
            marginTop: '10px'
        },
    },
    btnTxt: {
        fontFamily: 'RobotoBold',
        fontSize: '14px',
        letterSpacing: '0.04px',
        color: 'white',
        paddingRight: '38px',
        paddingLeft: '38px',
    },
    buttonProgress: {
        color: '#0e78f9',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    box_base_2: {
        display: 'flex',
        flexFlow: 'column',
        backgroundColor:'#000',
        color: theme.currentTheme.colors[1],
        backgroundImage: `url(${platformHomePic21})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition:'center',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]:{
            justifyContent: 'flex-start',
            paddingTop:'20px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
    },
    box_base_3: {
        display: 'flex',
        flexFlow: 'column',
        color: theme.currentTheme.colors[1],
        backgroundSize: "100% auto",
        backgroundPosition:'center',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'black',
        [theme.breakpoints.down('sm')]: {
            width: '85%',
            margin: '30px auto 0 auto',
            '@media only screen and (min-width: 700px) and (max-width: 1200px)':  {
                width: '62.66%',
            },
        }
    },
    box_base_3_2: {
        display: 'flex',
        flexFlow: 'row',
        color: theme.currentTheme.colors[1],
        justifyContent: 'flex-start',
        backgroundSize: "100% auto",
        alignItems: 'flex-start',
        backgroundColor: 'black',
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'column',
            width: '85%',
            margin: '60px auto 0 auto',
            '@media only screen and (min-width: 700px) and (max-width: 1200px)':  {
                width: '62.66%',
            },
        }
    },
    box_base_3_3: {
        display: 'flex',
        flexFlow: 'column',
        color: theme.currentTheme.colors[1],
        backgroundSize: "100% auto",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'black',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'black',
            width: '85%',
            margin: '50px auto 0 auto',
            '@media only screen and (min-width: 700px) and (max-width: 1200px)':  {
                width: '62.66%',
            },
        }
    },
    box_base_3_4: {
        display: 'flex',
        flexFlow: 'row',
        color: theme.currentTheme.colors[1],
        backgroundSize: "100% auto",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'black',
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'column',
            width: '85%',
            margin: '60px auto 0 auto',
            '@media only screen and (min-width: 700px) and (max-width: 1200px)':  {
                width: '62.66%',
            },
        }
    },
    box_base_4: {
        display: 'flex',
        flexFlow: 'column',
        color: theme.currentTheme.colors[1],
        backgroundColor:'#000',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]:{
            justifyContent: 'flex-start',
            paddingTop:'60px',
            marginBottom: '20px',
        }
    },
    text_bottom_1: {
        marginTop: '2%',
        color: '#fff',
        letterSpacing: '-0.94px',
        fontWeight: '400',
        [theme.breakpoints.up('md')]:{
            font: '50px/50px DinBold !important',
            marginLeft:'20px',
        },
        [theme.breakpoints.down('sm')]:{
            font: '40px/40px DinBold !important',
            zoom: '75%',
            marginTop: '70px',
        }
    },
    text_bottom_2: {
        color: "#fff",
        marginTop: "1%",
        font: '16px/22px DinLight',
        [theme.breakpoints.up('md')]:{
            marginLeft:'20px',
        },
    },
    homepage1: {
        width: '310px',
        height: '294px',
        [theme.breakpoints.down('sm')]:{
            width: '212px',
            height: '203px',
        }
    },
    homepage2: {
        width: '339px',
        height: '341px',
        [theme.breakpoints.down('sm')]:{
            width: '213px',
            height: '214px',
        }
    },
    homepage3: {
        width: '381px',
        height: '320px',
        [theme.breakpoints.down('sm')]:{
            width: '239px',
            height: '201px',
        }
    },
    homepage4: {
        width: '277px',
        height: '303px',
        [theme.breakpoints.down('sm')]:{
            width: '185px',
            height: '202px',
        }
    },
    learnMoreBtn: {
        color: 'white',
        marginTop: '30px',
        border: '2px solid #fff',
        [theme.breakpoints.up('md')]:{
            marginLeft: '20px',
            padding: '1.5rem 5.25rem'
        },
        [theme.breakpoints.down('sm')]:{
            border: '2px solid #fff',
            width: '100%',
            alignSelf: 'center',
            marginLeft: 'unset',
            paddingTop: '1.3rem',
            paddingBottom: '1.3rem',
            marginTop: '30px',
        },
    },
    learnMoreBtnTxt: {
        fontSize: '14px',
        paddingLeft: '15px',
        paddingRight: '15px',
        fontFamily: 'RobotoBold',
        lineHeight: '14px',
    },
    leftImgRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '768px',
        marginTop: '10px',
    },
    leftImgTxt: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: '50%',
        padding: '50px',
    },
    rightImgRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '768px',
        maxWidth: '50%',
        marginLeft: '70px',
    },
    m_1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '85%',
        margin: '20px auto 0 auto',
        '@media only screen and (min-width: 700px) and (max-width: 1200px)':  {
            width: '62.66%',
        },
    },

}))

export default function PlatformHome() {
    const history = useHistory();
    const {t, i18n} = useTranslation();
    let { token } = useToken();
    // let token = true;
    const emailPlaceHolder = 'EMAIL ADDRESS';
    const [values, setValues] = React.useState({
        emailAddress: '',
        isValidEmail: true,
        emailErrorMsg: "",
        emailPlaceHolder: emailPlaceHolder,
        openDialog: false,
        testValue: 1
    });
    const isMobile = useMediaQuery((theme)=>{
        return theme.breakpoints.down('sm');
    });
    React.useEffect(() => {
        const localLanguage = window.localStorage.getItem('language');
        const defaultLang = (navigator.language || navigator.browserLanguage).toLowerCase();

        if (localLanguage) {
            i18n.changeLanguage(localLanguage);
        } else if (defaultLang.indexOf('en') !== -1) {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage("zh");
        }
    }, []);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, emailAddress: event.target.value });
    };

    const handlePOLearnMore = () => {
        history.push({ pathname: '/home/po-learn-more' });
    };
    const handleInventoryLearnMore = () => {
        history.push({ pathname: '/home/inventory-learn-more' });
    };
    const handleSourcingLearnMore = () => {
        history.push({ pathname: '/home/source-learn-more' });
    }
    const handleOfficeLearnMore = () => {
        history.push({ pathname: '/home/office-learn-more' });
    }

    const onFinish = () => {
        if (!checkEmail(values.emailAddress)) {
            setValues({ ...values, isValidEmail: false, emailErrorMsg: 'invalid email address' });
            return;
        }
        if (values.emailAddress) {
            history.push({
                pathname: '/register',
                search: 'email=' + values.emailAddress
            });
        } else {
            history.push({
                pathname: '/register',
            });
        }

    };
    const checkEmail = (values) => {
        let reg = /([\w._-])+@([\w_-])+(\.([\w_-])+){1,2}/;
        if (values && !reg.test(values)) {
            return false;
        }

        return true;
    }

    const handleOpenDemo = () => {
        setValues({ ...values, openDialog: true, testValue: 2 });
    };

    const handleCloseDemo = () => {
        setValues({ ...values, openDialog: false });
    };

    const jump2TermsPage = () => {
        const url = '/terms-privacy?type=terms';
        history.push(url);
    };

    const jump2PrivacyPage = () => {
        const url = '/terms-privacy?type=privacy';
        history.push(url);
    };

    const style = useStyles();

    return (
        <div className={style.root} >
            <div className={style.box_base_1} >
                <div className={style.box1}>
                    <Typography variant="h1" className={style.text}>
                        openuse.co
                    </Typography>

                    <Typography gutterBottom variant="h3" className={style.text1}>
                        {t('platformHomepage.content1')}
                    </Typography>

                    {
                      !isMobile &&
                      <Typography variant="h3" gutterBottom className={style.text2}>
                          <span style={{fontFamily: 'DinRegular'}}>{t('platformHomepage.content2_1')}</span>
                          <span style={{fontWeight: '400'}}>{t('platformHomepage.content2_2')}</span>
                          <span style={{fontWeight: '400'}}>{t('platformHomepage.content2_3')}</span>
                          <span style={{fontWeight: '400'}}>{t('platformHomepage.content2_4')}</span>
                          <span style={{fontFamily: 'DinRegular'}}>{t('platformHomepage.content2_5')}</span>
                          <span style={{fontWeight: '400'}}>{t('platformHomepage.content2_6')}</span>
                          <span style={{fontFamily: 'DinRegular'}}>{t('platformHomepage.content2_7')}</span>
                      </Typography>
                    }

                </div>

                {
                  !token && !isMobile &&
                  <div className={style.form}>
                      <TextField id="my-input"
                                 className={style.input}
                                 value={values.emailAddress}
                                 error={!values.isValidEmail}
                                 variant="outlined"
                                 autoComplete='none'
                                 placeholder={values.emailPlaceHolder}
                                 helperText={values.emailErrorMsg}
                                 onChange={handleChange('emailAddress')}
                      />
                      <Button
                        type='primary'
                        variant='contained'
                        color="primary"
                        className={style.button}
                        onClick={onFinish}>
                          <span className={style.btnTxt}>{t('platformHomepage.button1')}</span>
                      </Button>
                  </div>
                }

                {
                  token && !isMobile &&
                  <div className={style.form} />
                }

            </div>

            {
              isMobile &&
              <Typography variant="h3" className={style.text2}>
                  <span style={{fontFamily: 'DinRegular'}}>{t('platformHomepage.content2_1')}</span>
                  <span style={{fontWeight: '400'}}>{t('platformHomepage.content2_2')}</span>
                  <span style={{fontWeight: '400'}}>{t('platformHomepage.content2_3')}</span>
                  <span style={{fontWeight: '400'}}>{t('platformHomepage.content2_4')}</span>
                  <span style={{fontFamily: 'DinRegular'}}>{t('platformHomepage.content2_5')}</span>
                  <span style={{fontWeight: '400'}}>{t('platformHomepage.content2_6')}</span>
                  <span style={{fontFamily: 'DinRegular'}}>{t('platformHomepage.content2_7')}</span>
              </Typography>
            }
            {
              isMobile && !token &&
              <div className={style.m_1}>
                  <TextField id="my-input"
                             className={style.input}
                             value={values.emailAddress}
                             error={!values.isValidEmail}
                             variant="outlined"
                             autoComplete='none'
                             placeholder={values.emailPlaceHolder}
                             helperText={values.emailErrorMsg}
                             onChange={handleChange('emailAddress')}
                  />

                  <Button
                    type='primary'
                    variant='contained'
                    color="primary"
                    className={style.button}
                    onClick={onFinish}>
                      {t('platformHomepage.button1')}
                  </Button>
              </div>
            }

            {
              // mobile - PO transactions
              isMobile &&
              <div className={style.box_base_3}>
                  <Typography variant="h2" className={style.text_bottom_1} style={{marginTop: '40px'}}>
                      {t('platformHomepage.content9Header')}
                  </Typography>
                  <img className={style.homepage1} src={homepage1} style={{marginTop: '40px', alignSelf: 'center'}} />
                  <Typography variant="h2" className={style.text_bottom_1}>
                      {t('platformHomepage.po_transactions')}
                  </Typography>

                  <Typography variant="body1" className={style.text_bottom_2} style={{marginTop: '20px'}}>
                      {t('platformHomepage.content9_1')}
                  </Typography>

                  <Button className={style.learnMoreBtn} variant="outlined" onClick={handlePOLearnMore}>
                      <span className={style.learnMoreBtnTxt}>{t('platformHomepage.button2')}</span>
                  </Button>
              </div>
            }

            {
              // desktop - PO transactions
              !isMobile &&
              <div className={style.box_base_3} style={{marginTop: '1px'}}>
                  <Typography variant="h2" className={style.text_bottom_1} style={{marginTop: '50px', width: '100%', textAlign: 'center'}}>
                      {t('platformHomepage.content9Header')}
                  </Typography>

                  <div className={style.leftImgRoot}>
                      <img className={style.homepage1} src={homepage_bg_1_3} style={{marginTop: '20px', alignSelf: 'center', width: '50%', height: '100%', objectFit: 'cover'}} />
                      <div className={style.leftImgTxt}>
                          <Typography variant="h2" className={style.text_bottom_1} style={{fontSize: '36px', letterSpacing: '0.01px'}}>
                              {t('platformHomepage.po_transactions')}
                          </Typography>
                          <Typography variant="body1" className={style.text_bottom_2} style={{marginTop: '10px'}}>
                              {t('platformHomepage.content9_1')}
                          </Typography>

                          <Button className={style.learnMoreBtn} variant="outlined" onClick={handlePOLearnMore}>
                              <span className={style.learnMoreBtnTxt}>{t('platformHomepage.button2')}</span>
                          </Button>
                      </div>
                  </div>

              </div>
            }

            {
              //  mobile - Inventory
              isMobile &&
              <div className={style.box_base_3_2}>
                  <img className={style.homepage2} src={homepage2} style={{marginTop: '20px', alignSelf: 'center'}} />
                  <Typography variant="h2" className={style.text_bottom_1}>
                      {t('platformHomepage.inventory_control')}
                  </Typography>
                  <Typography variant="body1" className={style.text_bottom_2} style={{marginTop: '20px'}}>
                      {t('platformHomepage.content9_2')}
                  </Typography>
                  <Button className={style.learnMoreBtn} variant="outlined" onClick={handleInventoryLearnMore}>
                      <span className={style.learnMoreBtnTxt}>{t('platformHomepage.button2')}</span>
                  </Button>
              </div>
            }

            {
              //  desktop - Inventory
              !isMobile &&
              <div className={style.box_base_3_2}>
                  <div className={style.rightImgRoot}>
                      <Typography variant="h2" className={style.text_bottom_1} style={{fontSize: '36px', letterSpacing: '0.01px'}}>
                          {t('platformHomepage.inventory_control')}
                      </Typography>
                      <Typography variant="body1" className={style.text_bottom_2} style={{marginTop: '10px', width: '80%'}}>
                          {t('platformHomepage.content9_2')}
                      </Typography>

                      <Button className={style.learnMoreBtn} variant="outlined" onClick={handleInventoryLearnMore}>
                          <span className={style.learnMoreBtnTxt}>{t('platformHomepage.button2')}</span>
                      </Button>
                  </div>
                  <img className={style.homepage2} src={homepage_bg_2_3} style={{alignSelf: 'center', height: '100%', width: '50%', objectFit: 'cover'}}/>
              </div>
            }

            {
              //  mobile - live sourcing
              isMobile &&
              <div className={style.box_base_3_3}>
                  <Typography variant="h2" className={style.text_bottom_1} style={{marginTop: '40px'}}>
                      {t('platformHomepage.content10Header')}
                  </Typography>
                  <img className={style.homepage3} src={homepage3} style={{marginTop: '40px', alignSelf: 'center'}} />
                  <Typography variant="h2" className={style.text_bottom_1}>
                      {t('platformHomepage.live_sourcing')}
                  </Typography>
                  <Typography variant="body1" className={style.text_bottom_2} style={{marginTop: '20px'}}>
                      {t('platformHomepage.content10_1')}
                  </Typography>

                  <Button className={style.learnMoreBtn} variant="outlined" onClick={handleSourcingLearnMore}>
                      <span className={style.learnMoreBtnTxt}>{t('platformHomepage.button2')}</span>
                  </Button>

              </div>
            }

            {
              //  desktop - live sourcing
              !isMobile &&
              <div className={style.box_base_3_3}>

                  <Typography variant="h2" className={style.text_bottom_1} style={{marginTop: '50px', width: '100%', textAlign: 'center'}}>
                      {t('platformHomepage.content10Header')}
                  </Typography>

                  <div className={style.leftImgRoot}>
                      <img className={style.homepage3} src={homepage_bg_3_3} style={{alignSelf: 'center', width: '50%', height: '100%', objectFit: 'cover'}} />
                      <div className={style.leftImgTxt}>
                          <Typography variant="h2" className={style.text_bottom_1} style={{fontSize: '36px', letterSpacing: '0.01px'}}>
                              {t('platformHomepage.live_sourcing')}
                          </Typography>

                          <Typography variant="body1" className={style.text_bottom_2} style={{marginTop: '10px'}}>
                              {t('platformHomepage.content10_1')}
                          </Typography>

                          <Button className={style.learnMoreBtn} variant="outlined" onClick={handleSourcingLearnMore}>
                              <span className={style.learnMoreBtnTxt}>{t('platformHomepage.button2')}</span>
                          </Button>
                      </div>
                  </div>

              </div>

            }

            {
              //  mobile - live office
              isMobile &&
              <div className={style.box_base_3_4}>
                  <img className={style.homepage4} src={homepage4} style={{marginTop: '20px', alignSelf: 'center'}} />
                  <Typography variant="h2" className={style.text_bottom_1}>
                      {t('platformHomepage.live_office')}
                  </Typography>
                  <Typography variant="body1" className={style.text_bottom_2} style={{marginTop: '20px'}}>
                      {t('platformHomepage.content10_2')}
                  </Typography>
                  <Button className={style.learnMoreBtn} variant="outlined" onClick={handleOfficeLearnMore}>
                      <span className={style.learnMoreBtnTxt}>{t('platformHomepage.button2')}</span>
                  </Button>
              </div>
            }

            {
              //  desktop - live office
              !isMobile &&
              <div className={style.box_base_3_4}>
                  <div className={style.rightImgRoot}>
                      <Typography variant="h2" className={style.text_bottom_1} style={{fontSize: '36px', letterSpacing: '0.01px'}}>
                          {t('platformHomepage.live_office')}
                      </Typography>
                      <Typography variant="body1" className={style.text_bottom_2} style={{marginTop: '10px', width: '80%'}}>
                          {t('platformHomepage.content10_2')}
                      </Typography>

                      <Button className={style.learnMoreBtn} variant="outlined" onClick={handleOfficeLearnMore}>
                          <span className={style.learnMoreBtnTxt}>{t('platformHomepage.button2')}</span>
                      </Button>
                  </div>
                  <img className={style.homepage4} src={homepage_bg_4_3} style={{alignSelf: 'center', height: '100%', width: '50%', objectFit: 'cover'}}/>
              </div>
            }

            <PlatformHomeDemo openDialog={values.openDialog} onClose={handleCloseDemo} />

            <div className={style.box_base_4}>

                {
                    isMobile &&
                  <>
                      <Typography variant="body1" gutterBottom className={style.footertext1} style={{marginLeft:'10px'}}>openuse.co © 2023</Typography>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>

                          <Typography variant="body1" gutterBottom className={style.footertext1} style={{textAlign:'right',cursor:'pointer'}} onClick={jump2TermsPage}>
                              {t('platformHomepage.terms')}
                          </Typography>
                          <Typography variant="body1" gutterBottom className={style.footertext1} style={{textAlign:'right',marginRight:'10px',cursor:'pointer'}} onClick={jump2PrivacyPage}>
                              {t('platformHomepage.privacy')}
                          </Typography>
                      </div>
                  </>
                }

                {
                    !isMobile &&
                  <Grid container style={{width:'95%', marginTop: '100px', marginBottom: '30px'}}>
                      <Grid item xs={5}>
                          <Typography variant="body1" gutterBottom className={style.footertext1} style={{marginLeft:'10px'}}>openuse.co © 2023</Typography>
                      </Grid>
                      <Grid item xs={7}>
                          <Grid container justifyContent="flex-end">
                              <Grid item xs={4} md={2}>
                                  <Typography variant="body1" gutterBottom className={style.footertext1} style={{textAlign:'right',cursor:'pointer'}} onClick={jump2TermsPage}>
                                      {t('platformHomepage.terms')}
                                  </Typography>
                              </Grid>
                              <Grid item xs={4} md={2}>
                                  <Typography variant="body1" gutterBottom className={style.footertext1} style={{textAlign:'right',marginRight:'10px',cursor:'pointer'}} onClick={jump2PrivacyPage}>
                                      {t('platformHomepage.privacy')}
                                  </Typography>
                              </Grid>
                          </Grid>
                      </Grid>
                  </Grid>
                }

            </div>
        </div>
    )
}

