import { makeStyles } from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '15% 20%',
    },
    hintsText: {
        color: theme.currentTheme.colors[3],
    },
}));

export default function RegisterPendingPage(props){
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    return (
        <div className={classes.container}>
            <Typography variant='h2' gutterBottom>
                {t('userReg.pendingTitle')}
            </Typography>
            <Typography variant='h5' gutterBottom className={classes.hintsText}>
                {t('userReg.pendingContent')}
            </Typography>
        </div>
    )
}