import light from './themes/lightTheme';
import dark from './themes/darkTheme';
import muiTheme from './muiTheme';
import merge from 'deepmerge';

export const lightTheme = merge({
  currentTheme: light,
  palette: {
    type: "light",
    primary: {
      main: 'rgba(10, 25, 41)',
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        border: 'inherit'
      }
    },
    MuiPickersStaticWrapper:{
      staticWrapperRoot:{            
          backgroundColor:'rgb(243 246 249)',
          border:'1px solid #dbdbdb',
          borderRadius:'8px'
      }
    },
    MuiPickersBasePicker:{
      pickerView:{
          maxWidth: '100%',
          minHeight:'405px'
      }
    },
    MuiPickersCalendarHeader:{
        iconButton:{
            backgroundColor:'none',
        },
        dayLabel:{
            width: '16%'
        }
    },
    MuiPickersCalendar:{
      transitionContainer:{
          minHeight:'290px'
      },
      week:{
          "& div":{
              width:'16%',
              textAlign: 'center',
              height:'50px'
          },
          "& p":{
            fontSize:'14px'
          }
      }
    },
    MuiPickersDay:{
      current:{
        border:'1px solid #2DA44E'
      },
      daySelected:{
        backgroundColor:'#2DA44E',
        "&:hover":{
          backgroundColor:'#2DA44E',
        }
      },
    },
  }
}, muiTheme);

export const darkTheme = merge({
  currentTheme: dark,
  palette: {
    type: "dark",
    primary: {
      main: 'rgba(10, 25, 41, 0.72)',
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        border: 'solid 1px #656565'
      }
    },
    MuiInput:{
      root:{
        '&:-webkit-autofill': {
            '-webkit-box-shadow': '#000000',
        }
      }
    },
    MuiPickersStaticWrapper:{
      staticWrapperRoot:{            
          backgroundColor:'#001e3c',
          border:'1px solid #1e4976',
          borderRadius:'8px'
      }
    },
    MuiPickersBasePicker:{
      pickerView:{
          maxWidth: '100%',
          minHeight:'405px'
      }
    },
    MuiPickersCalendarHeader:{
        iconButton:{
            backgroundColor:'none',
        },
        dayLabel:{
            width: '16%'
        }
    },
    MuiPickersCalendar:{
        transitionContainer:{
            minHeight:'290px'
        },
        week:{
            "& div":{
                width:'16%',
                textAlign: 'center',
                height:'50px'
            },
            "& p":{
              fontSize:'14px'
            },
        }
    },
    MuiPickersDay:{
      current:{
        border:'1px solid #2DA44E',
        color:'#ffffff'
      },
      daySelected:{
        backgroundColor:'#2DA44E',
        "&:hover":{
          backgroundColor:'#2DA44E',
        }
      },
    },
    MuiPaper:{
      root:{
        backgroundColor:'#1e4976',
      }
    },
    MuiPickersToolbar:{
      toolbar:{
        backgroundColor:'rgba(10, 25, 41, 0.72)',
      }
    }
  }
}, muiTheme);
console.log(darkTheme);