import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router';
import OpenDaisLogo from './OpenDaisLogo'
import { useState, useEffect } from 'react';
import { Badge, Button, IconButton, Menu, Tooltip, MenuItem, useMediaQuery} from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
import useToken from '../hooks/useToken';
import iamService from '../services/iamService';
import applicationService from '../services/applicationService';
import { makeStyles } from '@material-ui/styles';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import TodayIcon from '@material-ui/icons/Today';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Notification from './notification/notification';
import HelloUser from './HelloUser';
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@material-ui/icons/Translate';
import moment from "moment";
import "moment/locale/zh-cn";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
moment.locale("en");

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    header: {
        width: '100%',
        backgroundColor: theme.currentTheme.backgrounds[1],
        height: '58px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#FFFFFF'
    },
    iconButton: {
        color: '#FFFFFF'
    },
    tabbar: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            height: '61px',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '50px',
        },
    },
    tabItem: {
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        padding: "0 16px",
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: "rgb(18,46,74)"
        }
    },
    selected: {
        borderBottom: '4px solid #FF5722',
    },
    avatarBox: {
        cursor: 'pointer'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        height: '100%',
    },
    drawerPaper: {
        width: drawerWidth,
        height: '300px',
        backgroundColor: 'rgba(10, 25, 41)',
        marginTop: '56px',
    },
    drawerHeader: {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    menuButton: {
    },
    hide: {
        display: 'none',
    },
    fullList: {
        width: 'auto',
    },
    list: {
        width: 250,
    },
    signBtn: {
        [theme.breakpoints.up('md')]: {
            width: '100px',
            color: '#ffffff'
        },
        [theme.breakpoints.down('xs')]:{
            color: '#ffffff'
        },
    },
    signBtnTxt: {
        fontSize: '14px',
        fontFamily: 'DinRegular',
        letterSpacing: '0.08px',
        color: 'white'
    }
}));
export default function HeaderBar(props) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const [lang, setLang] = useState('en');
    let { token, setToken } = useToken();
    const history = useHistory();
    const [darkMode, setDarkMode] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationNumber, setNotificationNumber] = useState(0);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(1);
    const [currentMenuList, setCurrentMenuList] = useState([]);
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const isMobile = useMediaQuery((theme)=>{
        return theme.breakpoints.down('xs');
    });
    const isPad = useMediaQuery((theme)=>{
        return theme.breakpoints.down('sm');
    });
    const defaultCategoryList = [
        {id:1,name:"Open Book",display_name:"Open Book",
            children:[]
        },
        {id:2,name:"Open Office",display_name:"Open Office",
            children:[]
        },
        {id:3,name:"Open Comm",display_name:"Open Comm",children:[]},
        {id:4,name:"Open Trace",display_name:"Open Trace",children:[]},
    ];
    const [categoryList, setCategoryList ]= useState(defaultCategoryList);
    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme');
        if (localTheme && localTheme === 'dark') {
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
        const localLanguage = window.localStorage.getItem('language');
        const defaultLang = (navigator.language || navigator.browserLanguage).toLowerCase();
        if (localLanguage) {
            i18n.changeLanguage(localLanguage);
        } else if (defaultLang.indexOf('en') !== -1) {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('zh');
        }

    }, []);
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const [currentApp, setCurrentApp] = useState('');
    useEffect(() => {
        if (props.applications && props.applications.length > 0) {
            let path = history.location.pathname;
            // console.log(path);

            if(path.toLowerCase().startsWith("/open comm")){
                props.applications.map(item=>{
                    if(item.name.toLowerCase()==="open comm"){
                        switchApplication(item,false);
                    }
                })
                setCurrentCategory(3);
            }
            if(path.toLowerCase().startsWith("/po transactions")){
                props.applications.map(item=>{
                    if(item.name.toLowerCase()==="po transactions"){
                        switchApplication(item,false);
                    }
                })
                setCurrentCategory(1);
            }

            if(path.toLowerCase().startsWith("/inventory")){
                props.applications.map(item=>{
                    if(item.name.toLowerCase()==="inventory"){
                        switchApplication(item,false);
                    }
                })
                setCurrentCategory(1);
            }
            let menuList = [];

            props.applications.map(item=>{
                if(item.category===categoryList[currentCategory-1].name){
                    menuList.push(item);
                }
                defaultCategoryList.map((category)=>{
                    if(item.category === category.name){
                        category.children.push(item);
                    }
                })
            })

            // console.log(menuList);
            setCategoryList(defaultCategoryList);
            setCurrentMenuList(menuList)

        }
    }, [props.applications]);

    useEffect(() => {
        let menuList = [];
        props.applications.map(item=>{
            if(item.category===categoryList[currentCategory-1].name){
                menuList.push(item);
            }
        })
        // console.log(menuList);
        setCurrentMenuList(menuList)
        // console.log(currentMenuList);
    }, [currentCategory]);

    const changeTheme = (isDark) => {
        setDarkMode(isDark);
        props.themeChanged && props.themeChanged();
    };
    const getTabItemClassesFunc = (item) => {
        if (item.id === currentCategory) {
            return [classes.tabItem, classes.selected].join(' ');
        }
        return classes.tabItem;
    }
    const switchApplication = (app, jump) => {
        if (history.location.pathname !== '/' + app.name || app.id !== currentApp.id) {
            applicationService.getApplicationById(app.id).then((result) => {
                const currentApp = result.data;
                setCurrentApp(app);
                props.applicationChange(currentApp[0]);
                if (jump) {
                    history.push('/' + app.name)
                }
            })
        }
    }
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signInHandler = () => {
        const iamLoginURL = iamService.loginURL();
        window.location.href = iamLoginURL;
    }

    const signUpHandler = () => {
        window.location.href = '/register';
    }

    const signOutHandler = () => {
        if (token) {
            iamService.logout(token.access_token).then((result) => {
                setToken('');
                window.location.href = '/';
            });
        }
    }

    const jump2OrgHandler = () => {
        const orgURL = `/management/services/${currentApp.name}/organizations`;
        // window.location.href = orgURL;
        history.push({
            pathname: orgURL,
            state: { "fromYourOrg": true },
        });
        handleClose();
    }

    const jump2Setting = () => {
        const settingURL = '/setting';
        history.push(settingURL);
        handleClose();
    }

    const jump2TechnicalSupport = () => {
        const technicalSupportURL = '/technical-support';
        history.push(technicalSupportURL);
        handleClose();
    }

    const jump2Home = () => {
        const homeURL = '/';
        history.push(homeURL);
        handleClose();
    }
    const jump2UserList = () => {
        const userListUrl = '/management/userlist';
        history.push(userListUrl);
        handleClose();
    }
    const jump2UserApproval = () => {
        const approvalUrl = '/management/pendingapproval';
        history.push(approvalUrl);
        handleClose();
    }

    const jump2Schdule = () => {
        const bookingSchduleUrl = '/booking-schdule';
        history.push(bookingSchduleUrl);
        handleClose();
    }
    const jump2Price = () => {
        const pricePageUrl = '/price';
        history.push(pricePageUrl);
        handleClose();
    }
    const openNotificationhander = () => {
        setShowNotification(true);
    }

    const closeDetailHandler = () => {
        setShowNotification(false);
    }

    const notificationUpdateHandler = (number) => {
        setNotificationNumber(number);
        // console.log(number);
    }

    const handleCatagoryClick = (event,categoryId)=>{
        if(props.applications.length===0){
            applicationService.getApplications().then(result=>console.log("need login"));
        }
        setCurrentCategory(categoryId);
        setMenuAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () =>{
        setMenuAnchorEl(null);
    }

    const handleMenuClick = (app) =>{
        setMenuAnchorEl(null);
        switchApplication(app,true);
        handleMobileMenuClose();
    }

    const translateItem=(value)=>{
        if(value==="Open Office"){
            return t('header.openOffice');
        }
        if(value==="Live Officiating"){
            return t('header.liveOfficiating');
        }
        if(value==="Live Sourcing"){
            return t('header.liveSourcing');
        }
        if(value==="Live Auditing"){
            return t('header.liveAudit');
        }
        if(value==="Live Pairing"){
            return t('header.liveParing');
        }
        if(value==="Open Book"){
            return t('header.openBook');
        }
        if(value==="PO Transactions"){
            return t('header.poTransactions');
        }
        if(value==="Inventory"){
            return t('header.inventory');
        }
        if(value==="Open Trace"){
            return t('header.openTrace');
        }
        if(value==="Open Comm"){
            return t('header.openComm');
        }
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const renderMobileMenu = (
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >

        {categoryList && categoryList.length>0&&categoryList.map((item,index)=>{
            return (
                <>
                    <MenuItem key={index} dense>
                        <Typography variant="h4">{translateItem(item.display_name)}</Typography>
                    </MenuItem>

                    {
                        item.children && item.children.map((child)=>{
                            return (
                                <MenuItem key={index} onClick={()=>handleMenuClick(child)} dense>
                                    <Typography variant="body1">{translateItem(child.display_name)}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </>

            )
        })}
        </Menu>
    );

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const rightDrawer = (anchor) => (
      <div
        className={classes.drawer}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
          <div className={classes.drawerHeader}>
              <IconButton className={classes.iconButton} onClick={() => jump2Price()}>
                  <MonetizationOnIcon></MonetizationOnIcon>
                  <span style={{fontSize: '16px', marginLeft: '20px'}}>{t('toolbar.price')}</span>
              </IconButton>
              {
                  !darkMode ?
                    <IconButton className={classes.iconButton} onClick={() => {
                        changeTheme(true);
                    }}>
                        <Brightness4Icon/>
                        <span style={{fontSize: '16px', marginLeft: '20px'}}>{t('toolbar.switchDark')}</span>
                    </IconButton>
                    :
                    <IconButton className={classes.iconButton} onClick={() => {
                        changeTheme(false)
                    }}>
                        <Brightness7Icon/>
                        <span style={{fontSize: '16px', marginLeft: '20px'}}>{t('toolbar.switchLight')}</span>
                    </IconButton>
              }

              <IconButton className={classes.iconButton} onClick={() => {
                  i18n.changeLanguage(i18n.language === 'en' ? 'zh' : 'en');
                  if (i18n.language === 'en') {
                      window.localStorage.setItem('language', 'en');
                  } else {
                      window.localStorage.setItem('language', 'zh');
                  }
              }}>
                  <TranslateIcon/>
                  <span style={{fontSize: '16px', marginLeft: '20px'}}>{t('changeLang')}</span>
              </IconButton>

              <div style={{display: 'flex', flexDirection: 'row', marginTop: '30px', width: '100%', justifyContent: 'center'}}>

                  <Button style={{width: '100px', color: '#ffffff'}} onClick={signInHandler}><span
                    style={{fontSize: '16px'}}>{t('login.signIn')}</span></Button>
                  <Button style={{width: '100px', color: '#ffffff', border: '1px solid rgba(255, 255, 255, 0.4)'}} onClick={signUpHandler}><span
                    style={{fontSize: '16px'}}>{t('login.signUp')}</span></Button>

              </div>


          </div>
      </div>
    );

    return (
        <Box style={{width:'100%'}} sx={{ flexGrow: 1 }} id="header">
            <AppBar position="static" color="primary">
                <Toolbar>
                    {
                       isMobile && token && <div className={classes.sectionMobile}>
                            <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                            >
                            <DehazeIcon />
                            </IconButton>
                        </div>
                    }

                    {isMobile && !token && <OpenDaisLogo logoClick={jump2Home}></OpenDaisLogo>}
                    {!isMobile && <OpenDaisLogo logoClick={jump2Home}></OpenDaisLogo>}
                    {token&&<div className={classes.tabbar}>
                        {
                            categoryList.map((item) => {
                                return (
                                        <div className={getTabItemClassesFunc(item)} key={item.id} onClick={event => handleCatagoryClick(event,item.id)}>
                                            <Typography variant="body1">{translateItem(item.display_name)}</Typography>
                                        </div>
                                )
                            })
                        }

                        {currentMenuList&&currentMenuList.length>0&&<Menu
                                    id="simple-menu"
                                    anchorEl={menuAnchorEl}
                                    keepMounted
                                    open={Boolean(menuAnchorEl)}
                                    onClose={handleMenuClose}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                                    disableAutoFocusItem
                                >
                                {currentMenuList&&currentMenuList.length>0&&currentMenuList.map((item,index)=>{
                                    return (
                                        <MenuItem key={index} onClick={()=>handleMenuClick(item)} dense>
                                            <Typography variant="body1">{translateItem(item.display_name)}</Typography>
                                        </MenuItem>
                                    )
                                })}
                            </Menu>}
                    </div>}
                    <Box sx={{ flexGrow: 1 }} />
                    {
                      !isPad &&
                      <Tooltip title={t('toolbar.price')}>
                          <IconButton className={classes.iconButton} onClick={() => jump2Price()}>
                              <MonetizationOnIcon></MonetizationOnIcon>
                          </IconButton>
                      </Tooltip>
                    }

                    {
                      !isPad && !darkMode &&
                      <Tooltip title={t('toolbar.switchDark')}>
                          <IconButton className={classes.iconButton} onClick={() => changeTheme(true)}>
                              <Brightness4Icon></Brightness4Icon>
                          </IconButton>
                      </Tooltip>
                    }

                    {
                      !isPad && darkMode &&
                      <Tooltip title={t('toolbar.switchLight')}>
                          <IconButton className={classes.iconButton} onClick={() => changeTheme(false)}>
                              <Brightness7Icon></Brightness7Icon>
                          </IconButton>
                      </Tooltip>
                    }

                    {
                      !isPad &&
                      <Tooltip title={t('changeLang')}>
                          <IconButton className={classes.iconButton} onClick={() => {
                              i18n.changeLanguage(i18n.language === 'en' ? 'zh' : 'en');
                              if (i18n.language === 'en') {
                                  window.localStorage.setItem('language', 'en');
                                  moment.locale('en');

                              } else {
                                  moment.locale('zh-cn');
                                  window.localStorage.setItem('language', 'zh');
                              }
                          }}>
                              <TranslateIcon></TranslateIcon>
                          </IconButton>
                      </Tooltip>
                    }
                    {
                        token ?
                            <>
                                <Tooltip title={t('toolbar.notification')}>
                                    <IconButton className={classes.iconButton} onClick={openNotificationhander}>
                                        <Badge color="secondary" badgeContent={notificationNumber} max={9}>
                                            <NotificationsNoneIcon></NotificationsNoneIcon>
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Notification show={showNotification} anchorEl={anchorEl} onClose={closeDetailHandler} onUpdate={notificationUpdateHandler}></Notification>
                                <Tooltip title={t('toolbar.schedule')}>
                                    <IconButton className={classes.iconButton} onClick={jump2Schdule}>
                                        <TodayIcon></TodayIcon>
                                    </IconButton>
                                </Tooltip>
                                <div className={classes.avatarBox} onClick={handleClick}>
                                    <HelloUser></HelloUser>
                                </div>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    transformOrigin={{ vertical: "top", horizontal: "center" }}>
                                    <MenuItem onClick={jump2Setting}>
                                        <Typography variant="body1">{t('myLogoMenu.yourProfile')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={jump2OrgHandler}>
                                        <Typography variant="body1">{t('myLogoMenu.yourOrg')}</Typography>
                                    </MenuItem>
                                    {(process.env.REACT_APP_ADMINS.indexOf(token.username) >= 0) &&
                                        <MenuItem onClick={jump2UserList}>
                                            <Typography variant="body1">User list</Typography>
                                        </MenuItem>}
                                    {(process.env.REACT_APP_ADMINS.indexOf(token.username) >= 0) &&
                                        <MenuItem onClick={jump2UserApproval}>
                                            <Typography variant="body1">Pending approval</Typography>
                                        </MenuItem>}
                                    <MenuItem onClick={jump2TechnicalSupport}>
                                        <Typography variant="body1">{t('myLogoMenu.technicalSupport')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={signOutHandler}>
                                        <Typography variant="body1">{t('myLogoMenu.signOut')}</Typography>
                                    </MenuItem>
                                </Menu>

                            </>
                            :
                            <>
                                <Button className={classes.signBtn} onClick={signInHandler}>
                                    <span className={classes.signBtnTxt}>{t('login.signIn')}</span>
                                </Button>
                                <Button className={classes.signBtn} style={{ border: 'solid 1px #acacac', borderRadius: '5px' }} variant="outlined" onClick={signUpHandler}>
                                    <span className={classes.signBtnTxt}>{t('login.signUp')}</span>
                                </Button>

                                {
                                  isMobile && !token &&
                                  <IconButton
                                    style={{marginLeft: '10px'}}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleDrawer("right", true)}
                                    edge="start">
                                      <MenuIcon/>
                                  </IconButton>
                                }

                            </>
                    }
                </Toolbar>
            </AppBar>
            { token && renderMobileMenu}
            <Drawer anchor={'right'}
                    open={state['right']}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    onClose={toggleDrawer('right', false)}>
                {rightDrawer('right')}
            </Drawer>
        </Box>
    )
}
