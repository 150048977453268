import React , {useEffect ,useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useHistory} from "react-router-dom";

import { Grid, Select, Paper, TextField, Typography, Button, IconButton, Tooltip, MenuItem, Box, Collapse} from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';

import Autocomplete, { createFilterOptions }  from '@material-ui/lab/Autocomplete';
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { ApplicationContext } from "../../context/applicationContext";
import useToken from '../../hooks/useToken';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import accountingService from "../../services/accountingService";
import EditWarehouse from './edit-warehouse';
import inventoryService from '../../services/inventoryService';
import LoadingButton from "../../components/LoadingButton";
import { KeyboardReturnRounded } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import downloadFileUtils from '../../utils/downloadFile';
import organizationService from "../../services/organizationService";


const useStyles = makeStyles(theme=> ({
    warehouseItemBox: {
        width: "100%",
    },
    wareHouseItemContent: {
        padding: "0",
    },
    wareHouseItemSecContent: {
        margin: "18px 0 0 0",
    },
    wareHouseAction: {
        textAlign: "right",
    },
    itemTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: "#818181",
    },
    itemContent: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginTop: "20px",
        marginRight: "4px"

    },
    itemSpacing: {
        margin: "0",
        marginTop:'8px',
        placeSelf:'flex-start'
    },
    checkInBtn: {
        marginRight: "15px",
        padding: "4px 11px",
        [theme.breakpoints.down('md')]:{
            marginBottom: '8px'
        }
    },
    searchItem: {
        margin: "15px 0",
    },
    outlinedPadding: {
        padding: '11px 14px',
    },
    thTitle: {
        letterSpacing: "0.05px",
        '& > *': {
            color: "#818181",
        },
    },
    thBorder: {
        borderBottom: "none ",
        fontSize: "14px",
        padding: "6px",
    },
    detailRow:{
        backgroundColor: theme.currentTheme.theme2backgrounds[3]
    },
    selectField: {
        padding: "11px 14px",
    },
}))

export default function OrganizationListsItem(props){
    const {t, i18n} = useTranslation();

    const classes = useStyles();

    const history = useHistory();

    const filter = createFilterOptions();

    const { applications, refreshApplications} = useContext(ApplicationContext);
    const [warehouseOpen, setWarehouseOpen] = React.useState(false);

    const { token, setToken} = useToken();

    const [selSearchValue ,setSelSearchValue] = useState("itemTotalStock");

    const [searchKeyWords, setSearchKeyWords] = useState("");
    const [goodsCategory,setGoodsCategory] = useState(null);
    const [categoryData, setCategoryData] = useState([t('materialGoods.shapelistBar'),t('materialGoods.shapelistSeamless'),t('materialGoods.shapelistWelding'),t('materialGoods.shapelistSheet'),t('materialGoods.shapelistStrip'),t('materialGoods.shapelsitWire'),t('materialGoods.shapelistGrain'),t('materialGoods.shapelistPowder'),t('materialGoods.shapelistSpecial'),t('materialGoods.shapelistOthers')]);
    const [goodsType,setGoodsType] = useState("");
    const [goodsBrand,setGoodsBrand] = useState("");
    const [goodsESpec1,setGoodsESpec1] = useState(null);
    const [goodsESpec2,setGoodsESpec2] = useState(null);
    const [goodsESpec3,setGoodsESpec3] = useState(null);
    const [warehouseCanBeDeleted, setWarehouseCanBeDeleted] = useState(false);

    const [searchKeyWordsInput, setSearchKeyWordsInput] = useState("");

    const [editWareHouse, setEditWareHouse] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [members, setMembers] = useState([]);

    // const [wareHouseId, setWareHouseId] = useState("eb012072-1952-4d98-9690-9586d68aabbc");
    const [deleteConfirmWindow, setDeleteConfirmWindow] = useState(false);
    const [stockLineData, setStockLineData] = useState([]);
    useEffect(()=>{
        if(props.searchDefault&&props.searchDefault!==""){
            setSearchKeyWords(props.searchDefault);
            setSearchKeyWordsInput(props.searchDefault);
            getSearchResult(selSearchValue,props.searchDefault);
        }
        else{
            getSearchResult(selSearchValue,"");
        }
        organizationService.getUsersByOrgId(props.warehouseItem.orgId).then((result) => {
            if(result.status === 200){
                setMembers(result.data.users);
            }
        })
    },[])

    // useEffect(() => {
    //     if (props.warehouseItem.orgId) {
    //         console.log("orgId为：", props.warehouseItem.orgId);
    //         // accountingService.getInvoiceByPOId(props.po).then(result => {
    //         //     setInvoiceLineData(result.data);
    //         //     console.log("polines", result.data)
    //         // })
    //         let listType="total_stock";
    //         if(selSearchValue==="itemBatchStock"){
    //             listType = "batch_stock"
    //         }
    //         console.log(searchKeyWords);
    //         inventoryService.searchWarehouseStock(props.warehouseItem.warehouseId,listType,searchKeyWords).then(result=>{
    //             if(result.status === 200){
    //                 setStockLineData(result.data.datas);
    //                 console.log(result.data.canDelete);
    //                 setWarehouseCanBeDeleted(result.data.canDelete);
    //             }
    //         })
    //     }
    // }, [selSearchValue,searchKeyWords])

    const getSearchResult = (stype,keywords)=>{
        if (props.warehouseItem.orgId) {
            // console.log("orgId为：", props.warehouseItem.orgId);
            // accountingService.getInvoiceByPOId(props.po).then(result => {
            //     setInvoiceLineData(result.data);
            //     console.log("polines", result.data)
            // })
            let listType="total_stock";
            if(stype==="itemBatchStock"){
                listType = "batch_stock"
            }
            // console.log(keywords);
            let eSpec = {};
            if(props.warehouseItem.isMaterial){
                if(goodsESpec1&&goodsESpec1.value!==''){
                    eSpec[goodsESpec1.label]=goodsESpec1.value;
                }
                if(goodsESpec2&&goodsESpec2.value!==''){
                    eSpec[goodsESpec2.label]=goodsESpec2.value;
                }
                if(goodsESpec3&&goodsESpec3.value!==''){
                    eSpec[goodsESpec3.label]=goodsESpec3.value;
                }
            }   
            inventoryService.searchWarehouseStock(props.warehouseItem.warehouseId,listType,props.warehouseItem.isMaterial?true:false,keywords,goodsType,goodsCategory,goodsBrand,JSON.stringify(eSpec)).then(result=>{
                if(result.status === 200){
                    setStockLineData(result.data.datas);
                    // console.log(result.data.canDelete);
                    setWarehouseCanBeDeleted(result.data.canDelete);
                }
            })
        }
    }


    const searchValueChangeHandler = (event) =>{
        setStockLineData([]);
        setSelSearchValue(event.target.value);
        setSearchKeyWords("");
        getSearchResult(event.target.value,searchKeyWordsInput);
    }

    const handlerSearch = () => {
        setSearchKeyWords(searchKeyWordsInput);
        getSearchResult(selSearchValue,searchKeyWordsInput);
    }

    const handlerEnterSearch =(event)=>{
        // console.log(event);
        if (event.key == "Enter"){
            handlerSearch();
        }
    }

    function editWareHouseCloseHandler(){
        setEditWareHouse(false);
    }

    function handleRefresh(){
        props.onRefresh();
    }

    const jump2EditWareHousePage = ()=> {
        setEditWareHouse(true);
    }

    const jump2CheckInPage = () => {
        const settingURL = '/Inventory/CheckInPage';
        history.push({
            pathname: settingURL,
            state: { "warehouseData": props.warehouseItem },
        });
    }

    const jump2CheckOutPage = () => {
        const settingURL = '/Inventory/CheckOutPage';
        history.push({
            pathname: settingURL,
            state: { "warehouseData": props.warehouseItem },
        });
    }

    const jump2ReservePage = () => {
        const settingURL = '/Inventory/CheckOutPage';
        history.push({
            pathname: settingURL,
            state: { "warehouseData": props.warehouseItem, "type": "reserve" },
        });
    }

    const deleteWarehouseHandler = () =>{
        setIsLoading(true);
        inventoryService.deleteWarehouseById(props.warehouseItem.warehouseId).then(result=>{
            if(result.status === 200){
                handleRefresh();
                setIsLoading(false);
                handleDeleteWindowClose();
            }
        })
    }

    const handleDeleteWindowClose = () =>{
        setDeleteConfirmWindow(false);
    }

    const downloadSearch = () =>{
        let listType="total_stock";
        if(selSearchValue==="itemBatchStock"){
            listType = "batch_stock"
        }
        let eSpec = {};
        if(props.warehouseItem.isMaterial){
            if(goodsESpec1&&goodsESpec1.value!==''){
                eSpec[goodsESpec1.label]=goodsESpec1.value;
            }
            if(goodsESpec2&&goodsESpec2.value!==''){
                eSpec[goodsESpec2.label]=goodsESpec2.value;
            }
            if(goodsESpec3&&goodsESpec3.value!==''){
                eSpec[goodsESpec3.label]=goodsESpec3.value;
            }
        }   
        inventoryService.downloadWarehouseStock(props.warehouseItem.warehouseId,listType,props.warehouseItem.isMaterial?true:false,searchKeyWords,goodsType,goodsCategory,goodsBrand,JSON.stringify(eSpec),-(((new Date().getTimezoneOffset())/60))).then(result=>{
            let types = 'application/vnd.ms-excel';
            let fileName = '';
            if(searchKeyWords&&searchKeyWords!==''){
                fileName = `${props.warehouseItem.name}_${listType}_${searchKeyWords}.xlsx`;
            }
            else{
                fileName = `${props.warehouseItem.name}_${listType}.xlsx`;
            }
            let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
            ; // 保存的文件名
            downloadFileUtils.downLoadFile(blob, fileName); 
        })
    }

    const setGoodsESpec1Handler = (e)=>{
        {
            let copySepc = JSON.parse(JSON.stringify(goodsESpec1));
            // console.log(copySepc);
            copySepc.value=e.target.value;
            if(e.target.value===''&&copySepc.required===true){
                copySepc.error=true;
            }
            else{
                copySepc.error=false;
            }
            // console.log(copySepc);
            setGoodsESpec1(copySepc);
        }
    }

    const setGoodsESpec2Handler = (e)=>{
        {
            let copySepc = JSON.parse(JSON.stringify(goodsESpec2));
            // console.log(copySepc);
            copySepc.value=e.target.value;
            if(e.target.value===''&&copySepc.required===true){
                copySepc.error=true;
            }
            else{
                copySepc.error=false;
            }
            // console.log(copySepc);
            setGoodsESpec2(copySepc);
        }
    }

    const setGoodsESpec3Handler = (e)=>{
        {
            let copySepc = JSON.parse(JSON.stringify(goodsESpec3));
            // console.log(copySepc);
            copySepc.value=e.target.value;
            if(e.target.value===''&&copySepc.required===true){
                copySepc.error=true;
            }
            else{
                copySepc.error=false;
            }
            // console.log(copySepc);
            setGoodsESpec3(copySepc);
        }
    }

    const showExtraSpec = (lineData)=>{
        // console.log(lineData)
        let tempName = '';
        let arr=[];
        if(lineData.goodsType&&lineData.goodsType!==''){
            arr.push(lineData.goodsType);
        }
        
        if(lineData.category&&lineData.category!==''){
            arr.push(lineData.category);
        }
        if(lineData.brand!==''){
            arr.push(lineData.brand);
        }
        if(lineData.extralSpec&&lineData.extralSpec!==''){
            // arr.push(goodsESpec3.value);
            arr = arr.concat(Object.values(lineData.extralSpec));
        }
        tempName = arr.join('/');
        // console.log(tempName);
        return tempName;
    }

    const addUsernameHandler = (email)=>{
        let find = members.find(item=>item.email===email);
        if(find){
            return(find.username + " (" + find.email + ")");
        }
        else{
            return(email);
        }
    }

    const Row = (props) => {
        const { row } = props;
        const classes = useStyles();
        const [open, setOpen] = React.useState(false);
    
        return (
          <React.Fragment>
            <TableRow className={classes.thBorder}>
              <TableCell component="th" scope="row" className={classes.thBorder}>{row.id}</TableCell>
              <TableCell className={classes.thBorder}>{row.name}</TableCell>
              <TableCell className={classes.thBorder}>{row.description}</TableCell>
              <TableCell className={classes.thBorder}>{props.warehouseItem.isMaterial?showExtraSpec(row):row.spec}</TableCell>
              <TableCell className={classes.thBorder}>{row.currency}</TableCell>
              <TableCell className={classes.thBorder} style={{ color: "#2DA44E" }}>{row.totalQty}</TableCell>
              <TableCell className={classes.thBorder} style={{ color: "#2DA44E" }}>{row.totalCost}</TableCell>
              <TableCell className={classes.thBorder}>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            </TableRow>
            <TableRow className={classes.detailRow}>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow className={classes.thTitle}>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.goodsId')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.name')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.description')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.rev')}</TableCell>
                          <TableCell className={classes.thBorder}>{props.warehouseItem.isMaterial?t('goodsManagement.eSpec'):t('itemTotalStock.spec')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.unitforStorage')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.unitforCost')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          <TableRow key={row.goodsId}>
                            <TableCell className={classes.thBorder} component="th" scope="row">{row.id}</TableCell>
                            <TableCell className={classes.thBorder}>{row.name}</TableCell>
                            <TableCell className={classes.thBorder}>{row.description}</TableCell>
                            <TableCell className={classes.thBorder}>{row.rev}</TableCell>
                            <TableCell className={classes.thBorder}>{props.warehouseItem.isMaterial?showExtraSpec(row):row.spec}</TableCell>
                            <TableCell className={classes.thBorder}>{row.unitStorage}</TableCell>
                            <TableCell className={classes.thBorder}>{row.unitCost}</TableCell>
                          </TableRow>
                      </TableBody>
                    </Table>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow className={classes.thTitle}>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.lotNum')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.quantity')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.lotCost')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.checkInDate')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.location')}</TableCell>
                          <TableCell className={classes.thBorder}>{t('itemTotalStock.notes')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.details&&row.details.map((lineRow) => (
                          <TableRow key={lineRow.invoiceLineId}>
                            <TableCell className={classes.thBorder} component="th" scope="row">{lineRow.lotNumber}</TableCell>
                            <TableCell className={classes.thBorder}>{lineRow.totalLotQtyStorage}</TableCell>
                            <TableCell className={classes.thBorder}>{lineRow.totalLotCost}</TableCell>
                            <TableCell className={classes.thBorder}>
                                {lineRow.checkInDate.map((item,index)=>{
                                    return(<Typography key={index} variant="body2">{moment(item).format("YYYY/MM/DD HH:mm")}</Typography>);
                                })}
                            </TableCell>
                            <TableCell className={classes.thBorder}>
                                {lineRow.location.map(item=>{
                                    return(<Typography variant="body2">{item}</Typography>);
                                })} 
                            </TableCell>
                            <TableCell className={classes.thBorder}>
                                {lineRow.notes.map(item=>{
                                    return(<Typography variant="body2">{item}</Typography>);
                                })} 
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      }

      const BatchRow = (props) => {
        const { row } = props;
        const classes = useStyles();
    
        return (
          <React.Fragment>
            <TableRow className={classes.thBorder}>
                <TableCell component="th" scope="row" className={classes.thBorder}>{row.lotNumber}</TableCell>
                <TableCell className={classes.thBorder}>{row.id}</TableCell>
                <TableCell className={classes.thBorder}>{row.name}</TableCell>
                <TableCell className={classes.thBorder}>{row.description}</TableCell>
                <TableCell className={classes.thBorder}>{props.warehouseItem.isMaterial?showExtraSpec(row):row.spec}</TableCell>
                <TableCell className={classes.thBorder}>
                    {row.checkInDate&&row.checkInDate.map(item=>{
                        return(<Typography variant="body2">{moment(item).format("YYYY/MM/DD HH:mm")}</Typography>);
                    })}
                </TableCell>
                <TableCell className={classes.thBorder}>
                    {row.location&&row.location.map(item=>{
                        return(<Typography variant="body2">{item}</Typography>);
                    })} 
                </TableCell>
                <TableCell className={classes.thBorder}>{row.currency}</TableCell>
                <TableCell className={classes.thBorder} style={{ color: "#2DA44E" }}>{row.totalLotQtyStorage}</TableCell>
                <TableCell className={classes.thBorder} style={{ color: "#2DA44E" }}>{row.totalLotCost}</TableCell>
            </TableRow>
          </React.Fragment>
        );
      }


    return(
        <div className={classes.warehouseItemBox}>
            <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.wareHouseItemContent}>
                <Grid item xs={6}>
                    <Tooltip title={t('warehouse.viewHouse')}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setWarehouseOpen(!warehouseOpen)}>
                            {warehouseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={6} className={classes.wareHouseAction}>
                    <Tooltip title={t('warehouse.editHouse')}>
                        <span ><IconButton aria-label="edit warehouse" onClick={jump2EditWareHousePage}>
                            <CreateIcon></CreateIcon>
                        </IconButton></span>
                    </Tooltip>

                    <Tooltip title={t('warehouse.delHouse')}>
                        <span style={{marginLeft:'4px',marginRight:'4px',color:"#2DA44E"}}><IconButton disabled={!warehouseCanBeDeleted} aria-label="delete warehouse" onClick={e=>setDeleteConfirmWindow(true)}>
                            <HighlightOffIcon></HighlightOffIcon>
                        </IconButton></span>
                    </Tooltip>

                    <Button variant="contained"  size="large" className={classes.checkInBtn} onClick={jump2CheckInPage}>
                        <span style={{marginLeft:'4px',color:"#2DA44E"}}>{t('homePage.checkIn')}</span>
                    </Button>

                    <Button variant="contained"  size="large" className={classes.checkInBtn} onClick={jump2CheckOutPage}>
                        <span style={{marginLeft:'4px',color:"#2DA44E"}}>{t('homePage.checkOut')}</span>
                    </Button>

                    <Button variant="contained"  size="large" className={classes.checkInBtn} onClick={jump2ReservePage}>
                        <span style={{marginLeft:'4px',color:"#2DA44E"}}>{t('homePage.reserve')}</span>
                    </Button>

                </Grid>
                <Collapse in={warehouseOpen} timeout="auto" unmountOnExit style={{width:'100%'}}>
                    <Grid container xs={12} alignItems="center" justifyContent="flex-start" direction="row" className={classes.wareHouseItemSecContent}>
                        <Grid item xs={3} className={classes.itemSpacing}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t('warehouse.id')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.itemContent}>
                                        {props.warehouseItem.id}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.itemSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t('warehouse.manager')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemContent}>
                                    {addUsernameHandler(props.warehouseItem.manager)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.itemSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t('warehouse.staff')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemContent}>
                                    {props.warehouseItem.staff.map((item,index)=>{
                                        return(<Typography key={index}>{addUsernameHandler(item)}</Typography>)
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.itemSpacing}>
                            <Grid item xs={12} >
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t('warehouse.phone')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemContent}>
                                    {props.warehouseItem.phone}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className={classes.itemSpacing}>
                            <Grid item xs={12} >
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t('warehouse.address')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemContent}>
                                    {props.warehouseItem.address}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className={classes.itemSpacing}>
                            <Grid item xs={12} >
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t('warehouse.notes')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemContent}>
                                    {props.warehouseItem.notes}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Collapse>
                {props.warehouseItem.isMaterial&&selSearchValue==='itemTotalStock'&&<Grid container xs={12} spacing={1} className={classes.itemSpacing}>
                <Grid item xs={2}>
                            <Grid className={classes.editTitle}>
                                <Typography variant="body2" >
                                    {t('mGoodsManagement.goodsType')}
                                </Typography>  
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    // value={item.title}
                                    value={goodsType}
                                    freeSolo
                                    fullWidth
                                    size='small'
                                    options={[t('materialGoods.typelistSteel'),t('materialGoods.typelistStainless'),t('materialGoods.typelistAl'),t('materialGoods.typelistTi'),t('materialGoods.typelistNi'),t('materialGoods.typelistLi'),t('materialGoods.typelistZr'),t('materialGoods.typelistCu')].map(option=>option)}
                                    // getOptionLabel={(option) => option.name}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        {option}
                                        </React.Fragment>
                                    )}
                                    
                                    // onChange={(e, newValue) => {
                                    //     setUnitforCost(newValue);
                                    //     console.log(unitforCost);
                                    // }}
                                    onInputChange={(e,newInputValue)=>{
                                        setGoodsType(newInputValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('mGoodsManagement.goodsType')}/>}
                                    style={{marginTop:'8px'}}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid className={classes.editTitle}>
                                <Typography variant="body2" >
                                    {t('mGoodsManagement.brand')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField fullWidth
                                    id="description"
                                    variant="outlined"
                                    value={goodsBrand}
                                    size="small"
                                    margin="dense"
                                    required
                                    onChange={e=>{
                                        setGoodsBrand(e.target.value);
                                    }}
                                    placeholder={t('mGoodsManagement.brand')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={2}>
                            <Grid className={classes.editTitle}>
                                <Typography variant="body2" >
                                    {t('mGoodsManagement.shape')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    id="goodsCategory"
                                    value={goodsCategory}
                                    fullWidth
                                    required={true}
                                    variant="outlined"
                                    size="small"
                                    onChange={e=>{
                                        setGoodsCategory(e.target.value);
                                        
                                        if(e.target.value==='棒'||e.target.value==='Bar'){
                                            setGoodsESpec1({label:t('materialGoods.dimDiameter'),required:false,value:'',error:false});
                                            setGoodsESpec2({label:t('materialGoods.dimLength'),required:false,value:'',error:false});
                                            setGoodsESpec3(null);
                                        }
                                        if(e.target.value==='无缝管'||e.target.value==='焊接管'||e.target.value==='Seamless tube'||e.target.value==='Welding tube'){
                                            setGoodsESpec1({label:t('materialGoods.dimDiameter'),required:false,value:'',error:false});
                                            setGoodsESpec2({label:t('materialGoods.dimWall'),required:false,value:'',error:false});
                                            setGoodsESpec3(null);
                                        }
                                        if(e.target.value==='板'||e.target.value==='条-块-方棒'||e.target.value==='Sheet'||e.target.value==='Strip-Ingot-Square bar'){
                                            setGoodsESpec1({label:t('materialGoods.dimStickness'),required:false,value:'',error:false});
                                            setGoodsESpec2({label:t('materialGoods.dimWidth'),required:false,value:'',error:false});
                                            setGoodsESpec3({label:t('materialGoods.dimLength'),required:false,value:'',error:false});
                                        }
                                        if(e.target.value==='丝'||e.target.value==='Wire'){
                                            setGoodsESpec1({label:t('materialGoods.dimDiameter'),required:false,value:'',error:false});
                                            setGoodsESpec2(null);
                                            setGoodsESpec3(null);
                                        }
                                        if(e.target.value==='颗粒'||e.target.value==='Grain'){
                                            setGoodsESpec1({label:t('materialGoods.dimSize1'),required:false,value:'',error:false});
                                            setGoodsESpec2(null);
                                            setGoodsESpec3(null);
                                        }
                                        if(e.target.value==='粉'||e.target.value==='Powder'){
                                            setGoodsESpec1({label:t('materialGoods.dimSize2'),required:false,value:'',error:false});
                                            setGoodsESpec2(null);
                                            setGoodsESpec3(null);
                                        }
                                        if(e.target.value==='异形'||e.target.value==='Special shape'){
                                            setGoodsESpec1({label:t('mGoodsManagement.dimension'),required:false,value:'',error:false});
                                            setGoodsESpec2(null);
                                            setGoodsESpec3(null);
                                        }
                                        if(e.target.value==='其他'||e.target.value==='Others'){
                                            setGoodsESpec1({label:t('mGoodsManagement.dimension'),required:false,value:'',error:false});
                                            setGoodsESpec2(null);
                                            setGoodsESpec3(null);
                                        }
                                    }}
                                    classes={{ select: classes.selectField }}
                                    style={{marginTop:'7px'}}
                                >
                                    {categoryData && categoryData.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        {goodsESpec1&&<Grid item xs={2}>
                            <Grid className={classes.editTitle}>
                                <Typography variant="body2" >
                                    {goodsESpec1.label}{goodsESpec1.required&&<span className={classes.required}>*</span>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField fullWidth
                                    id="spec"
                                    variant="outlined"
                                    value={goodsESpec1.value}
                                    size="small"
                                    margin="dense"
                                    required
                                    onChange={setGoodsESpec1Handler}
                                    placeholder={t('goodsManagement.spec')}
                                />
                            </Grid>
                        </Grid>}
                        {goodsESpec2&&<Grid item xs={2}>
                            <Grid className={classes.editTitle}>
                                <Typography variant="body2" >
                                    {goodsESpec2.label}{goodsESpec2.required&&<span className={classes.required}>*</span>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField fullWidth
                                    id="spec"
                                    variant="outlined"
                                    value={goodsESpec2.value}
                                    size="small"
                                    margin="dense"
                                    required
                                    onChange={setGoodsESpec2Handler}
                                    placeholder={t('goodsManagement.spec')}
                                />
                            </Grid>
                        </Grid>}
                        {goodsESpec3&&<Grid item xs={2}>
                            <Grid className={classes.editTitle}>
                                <Typography variant="body2" >
                                    {goodsESpec3.label}{goodsESpec3.required&&<span className={classes.required}>*</span>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField fullWidth
                                    id="spec"
                                    variant="outlined"
                                    value={goodsESpec3.value}
                                    size="small"
                                    margin="dense"
                                    required
                                    onChange={setGoodsESpec3Handler}
                                    placeholder={t('goodsManagement.spec')}
                                />
                            </Grid>
                        </Grid>}
                </Grid>
                }
                <Grid container spacing={2} alignItems="center" justifyContent="flex-start" direction="row" className={classes.searchItem}>
                    <Select
                        value={selSearchValue}
                        variant="outlined"
                        color="primary"
                        classes={{ select: classes.outlinedPadding }}
                        onChange={searchValueChangeHandler}
                        style={{verticalAlign:'middle'}}
                    >                                       
                            <MenuItem key={1} value={"itemBatchStock"}>{t('itemBatchStock.title')}</MenuItem>
                            <MenuItem key={2} value={"itemTotalStock"}>{t('itemTotalStock.title')}</MenuItem>
                    </Select>

                    <TextField id="outlined-basic" variant="outlined" size="small" placeholder={selSearchValue==="itemBatchStock"?t("checkInOutManagement.searchHint1"):t("checkInOutManagement.searchHint")} style={{marginLeft:'16px',verticalAlign:'middle',minWidth:'250px'}} onChange={e => setSearchKeyWordsInput(e.target.value)} value={searchKeyWordsInput} onKeyPress={handlerEnterSearch}/>
                    
                    <IconButton onClick={handlerSearch} aria-label="search"><SearchIcon></SearchIcon></IconButton>
                    <IconButton onClick={downloadSearch} aria-label="download"><GetAppIcon/></IconButton>
                </Grid>
                
                <Grid container fullWidth>
                    <Grid item xs={12}>
                    {stockLineData && stockLineData.length > 0 ? (
                        <Grid key={stockLineData.invoiceId} >
                            {selSearchValue==="itemTotalStock" ? (<TableContainer component={Paper} className={classes.invoiceLinePanel}>
                                <Table aria-label="simple table"  >
                                <TableHead>
                                    <TableRow className={classes.thTitle}>
                                        <TableCell className={classes.thBorder}>{t('itemTotalStock.goodsId')}</TableCell>
                                        <TableCell className={classes.thBorder}>{t('itemTotalStock.name')}</TableCell>
                                        <TableCell className={classes.thBorder} align="left">{t('itemTotalStock.description')}</TableCell>
                                        <TableCell className={classes.thBorder} align="left">{props.warehouseItem.isMaterial?t('goodsManagement.eSpec'):t('itemTotalStock.spec')}</TableCell>
                                        <TableCell className={classes.thBorder} align="left">{t('itemTotalStock.currency')}</TableCell>
                                        <TableCell className={classes.thBorder} align="left" style={{ color: "#2DA44E" }}>{t('itemTotalStock.totalQty')}</TableCell>
                                        <TableCell className={classes.thBorder} align="left" style={{ color: "#2DA44E" }}>{t('itemTotalStock.totalCost')}</TableCell>
                                        <TableCell className={classes.thBorder} align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stockLineData.map((item) => (
                                    <Row key={item.invoiceId} row={item} warehouseItem={props.warehouseItem}/>
                                    ))}
                                </TableBody>
                                </Table>
                            </TableContainer>):(
                                <TableContainer component={Paper} className={classes.invoiceLinePanel}>
                                    <Table aria-label="simple table"  >
                                        <TableHead>
                                            <TableRow className={classes.thTitle}>
                                                <TableCell className={classes.thBorder}>{t('itemBatchStock.lotNum')}</TableCell>
                                                <TableCell className={classes.thBorder}>{t('itemBatchStock.goodsId')}</TableCell>
                                                <TableCell className={classes.thBorder} align="left">{t('itemBatchStock.name')}</TableCell>
                                                <TableCell className={classes.thBorder} align="left">{t('itemBatchStock.description')}</TableCell>
                                                <TableCell className={classes.thBorder} align="left">{t('itemBatchStock.spec')}</TableCell>
                                                <TableCell className={classes.thBorder} align="left">{t('itemBatchStock.checkInDate')}</TableCell>
                                                <TableCell className={classes.thBorder} align="left">{t('itemBatchStock.location')}</TableCell>
                                                <TableCell className={classes.thBorder} align="left">{t('itemBatchStock.currency')}</TableCell>
                                                <TableCell className={classes.thBorder} align="left" style={{ color: "#2DA44E" }}>{t('itemBatchStock.totalLotQty')}</TableCell>
                                                <TableCell className={classes.thBorder} align="left" style={{ color: "#2DA44E" }}>{t('itemBatchStock.totalLotCost')}</TableCell>
                                                <TableCell className={classes.thBorder} align="left"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {stockLineData.map((item) => (
                                            <BatchRow key={item.invoiceId} row={item} warehouseItem={props.warehouseItem}/>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Grid>
                    )
                        :
                        (
                        <>
                            {/* <Grid item xs={12} className={classes.issuePoGrid}>
                                <img src={noImage} className={classes.issuePoImage}></img>
                            </Grid> */}
                            <Grid item xs={12} className={classes.issuePoGrid}>
                                {t('warehouse.noRecord')}
                            </Grid>
                        </>
                        )
                    }
                    </Grid>
                </Grid>
                <Dialog open={deleteConfirmWindow} onClose={handleDeleteWindowClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="body1">
                        {t('warehouse.deleteTitle')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                                {t('warehouse.deleteConfirmation')}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton isLoading={isLoading} variant="contained" color="primary"  onClick={deleteWarehouseHandler}>
                        <Typography variant="body2">
                            {t('common.confirm')}
                        </Typography>
                    </LoadingButton>
                    <Button variant="contained"  onClick={handleDeleteWindowClose}>
                        <Typography variant="body2">
                            {t('common.cancel')}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <EditWarehouse open={editWareHouse} wareHouseId={props.warehouseItem.warehouseId} onRefresh={props.onRefresh} method="update"  onClose={editWareHouseCloseHandler} onRefresh={handleRefresh}></EditWarehouse>

            </Grid>
        </div>
    )
}
