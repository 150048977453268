import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import Types from "./typography";
import Buttons from "./button";
import FormItems from "./form-items";
export default function DesignSystemHome() {
    const history = useHistory();
    let { path, url } = useRouteMatch();
    console.log('path', path);
    console.log('url', url);
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log(newValue)
        switch (newValue) {
            case 0:
                history.push(`${url}/buttons`);
                break;
            case 1:
                history.push(`${url}/typography`);
                break;
            case 2:
                history.push(`${url}/form-items`);
                break;
            default:
                history.push(`${url}/buttons`);
        }
    };
    return (
        <div>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Buttons" id="buttons" />
                    <Tab label="Typography" />
                    <Tab label="Form items" />
                </Tabs>
            </AppBar>
            <Switch>
                <Route path={`${path}/buttons`}>
                    <Buttons></Buttons>
                </Route>
                <Route path={`${path}/typography`}>
                    <Types></Types>
                </Route>
                <Route path={`${path}/form-items`}>
                    <FormItems></FormItems>
                </Route>
            </Switch>

        </div>
    )
}