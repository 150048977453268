import {axiosInventory as axios} from './axiosHelper';

const getWarehouses = function(orgId){
    const url = '/auth/warehouses?orgId='+orgId+'';
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const addWarehouse = function(messages){
    const url = '/auth/warehouses';
    return new Promise((resolve, reject)=>{
        axios.post(url,messages).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getWarehouse = function(warehouseId,messages){
    const url = `/auth/warehouses/${warehouseId}`;
    return new Promise((resolve, reject)=>{
        axios.get(url,messages).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateWarehouse = function(warehouseId,messages){
    const url = `/auth/warehouses/${warehouseId}`;
    return new Promise((resolve, reject)=>{
        axios.put(url,messages).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getGoods = function(orgId){
    const url = '/auth/goods?orgId='+orgId;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const addGood = function(data){
    const url = '/auth/goods';
    return new Promise((resolve, reject)=>{
        axios.post(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getGoodById = function(goodId,data){
    const url = `/auth/goods/${goodId}`;
    return new Promise((resolve, reject)=>{
        axios.get(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getGoodDetailById = function(orgId,goodId,warehouseId){
    const url = `/auth/goods/${goodId}/details?orgId=${orgId}&warehouseId=${warehouseId}`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateGood = function(goodId,data){
    const url = `/auth/goods/${goodId}`;
    return new Promise((resolve, reject)=>{
        axios.put(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const deleteGood = function(goodId){
    const url = `/auth/goods/${goodId}`;
    return new Promise((resolve, reject)=>{
        axios.delete(url,{}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const searchGoodByNameOrId = function(orgId,keywords,isMaterial){
    let url = "";
    if(keywords===""){
        url = `/auth/goods/like/?orgId=${orgId}`;
    }
    else{
        url = `/auth/goods/like/?orgId=${orgId}&goodsIdOrName=${keywords}`;
    }
    if(isMaterial){
        url = url + '&isMaterial=true';
    }
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const searchGoodBySpec = function(orgId,goodsType,brand,category,extralSpec){
    let url = "";
    console.log(goodsType);
    url = '/auth/material/goods/?orgId='+ orgId +'&limit=0&offset=20&status=normal'
    if(goodsType!==""){
        console.log('xxx');
        url = url+ '&goodsType='+goodsType;
    }
    if(brand&brand!==""){
        url = url+ '&brand='+brand;
    }
    if(category&&category!==""){
        url = url+ '&category='+category;
        if(extralSpec!=="{}"){
            url = url+ '&extralSpec='+extralSpec;
        }
    }
    
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const addCompany = function(data){
    const url = '/auth/company';
    return new Promise((resolve, reject)=>{
        axios.post(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const deleteCompanyById = function(companyId){
    const url = '/auth/company/' + companyId;
    return new Promise((resolve, reject)=>{
        axios.delete(url,{}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateCompany = function(companyId,data){
    const url = '/auth/company/' + companyId;
    return new Promise((resolve, reject)=>{
        axios.put(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const searchCompany = function(orgId,companyType,keywords){
    let url = '/auth/company/like?orgId=' + orgId + "&companyType=" + companyType;
    if(keywords!==""){
        url = url + '&comapnyIdOrName='+keywords;
    } 
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const searchWarehouseStock = function(warehouseId,listType,isMaterial,searchContent,goodsType,goodsCategory,goodsBrand,spec){
    let url = '/auth/warehouses/'+ warehouseId +'/details?limit=99999&offset=0&listType=' + listType;
    if(isMaterial){
        url=url +'&isMaterial='+isMaterial
    }
    if(isMaterial&&listType==='total_stock'){
        if(goodsType&&goodsType!==''){
            url=url +'&goodsType='+goodsType
        }
        if(goodsCategory&&goodsCategory!==''){
            url=url +'&category='+goodsCategory
        }
        if(goodsBrand&&goodsBrand!==''){
            url=url +'&brand='+goodsBrand
        }

        if(spec&&spec!=='{}'){
            url=url +'&extralSpec='+spec
        }
    }
    if(searchContent&&searchContent!=""){
        url = url + "&searchContent=" + searchContent;
    }
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const downloadWarehouseStock = function(warehouseId,listType,isMaterial,searchContent,goodsType,goodsCategory,goodsBrand,spec,utcOffset){
    let url = '/auth/warehouses/'+ warehouseId +'/download?limit=99999&offset=0&listType=' + listType;
    if(isMaterial){
        url=url +'&isMaterial='+isMaterial
    }
    if(isMaterial&&listType==='total_stock'){
        if(goodsType&&goodsType!==''){
            url=url +'&goodsType='+goodsType
        }
        if(goodsCategory&&goodsCategory!==''){
            url=url +'&category='+goodsCategory
        }
        if(goodsBrand&&goodsBrand!==''){
            url=url +'&brand='+goodsBrand
        }

        if(spec&&spec!=='{}'){
            url=url +'&extralSpec='+spec
        }
    }
    if(searchContent&&searchContent!=""){
        url = url + "&searchContent=" + searchContent;
    }
    if(utcOffset&&utcOffset!==''){
        url = url + "&utcOffset=" + utcOffset;  
    }
    return new Promise((resolve, reject)=>{
        axios({
            url: url,
            method: 'GET',
            responseType:'arraybuffer',
            headers: {
                "content-type": "application/json",
                "accept": "*/*"
            }
        }).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const deleteWarehouseById = function(warehouseId){
    const url = '/auth/warehouses/' + warehouseId;
    return new Promise((resolve, reject)=>{
        axios.delete(url,{}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getWarehouseStaff = function(warehouseId,messages){
    const url = `/auth/warehouses/${warehouseId}/staff`;
    return new Promise((resolve, reject)=>{
        axios.get(url,messages).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const addCheckIn = function(data){
    const url = '/auth/warehouse/checkins';
    return new Promise((resolve, reject)=>{
        axios.post(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const addCheckOut = function(data){
    const url = '/auth/warehouse/checkout';
    return new Promise((resolve, reject)=>{
        axios.post(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const searchCheckIn = function(orgId,startDate,endDate,goods,lotNumber){
    let url = '/auth/warehouse/checkins?orgId='+ orgId +'&limit=99999&offset=0';
    if(goods&&goods!=""){
        url = url + "&goods=" + goods;
    }
    if(lotNumber&&lotNumber!=""){
        url = url + "&lotNumber=" + lotNumber;
    }
    if(startDate&&startDate!=""){
        url = url  +'&startDate='+ startDate;
    }
    if(endDate&&endDate!=""){
        url = url +'&endDate='+ endDate;
    }
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const searchCheckOut = function(orgId,startDate,endDate,isReserved,goods,lotNumber){
    let url = '/auth/warehouse/checkouts?orgId='+ orgId +'&startDate='+ startDate +'&endDate='+ endDate + '&limit=99999&offset=0';
    console.log(isReserved);
    if(isReserved){
        url = url + '&isReserved='+ isReserved;
    }
    if(goods&&goods!=""){
        url = url + "&goods=" + goods;
    }
    if(lotNumber&&lotNumber!=""){
        url = url + "&lotNumber=" + lotNumber;
    }
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const verifyCheckIn = function(checkInId){
    const url = `/auth/warehouse/checkins/${checkInId}/check`;
    return new Promise((resolve, reject)=>{
        axios.put(url,{}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const verifyCheckOut = function(checkOutId){
    const url = `/auth/warehouse/checkout/${checkOutId}/check`;
    return new Promise((resolve, reject)=>{
        axios.put(url,{}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const deleteCheckIn = function(checkInId){
    const url = `/auth/warehouse/checkins/${checkInId}`;
    return new Promise((resolve, reject)=>{
        axios.delete(url,{}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const deleteCheckOut = function(checkOutId){
    const url = `/auth/warehouse/checkouts/${checkOutId}`;
    return new Promise((resolve, reject)=>{
        axios.delete(url,{}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getBillNumber = function(orgId,billType){
    const url = `/auth/warehouse/max-number?orgId=${orgId}&numberType=${billType}`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}
const getCheckInById = function(checkInId){
    const url = `/auth/warehouse/checkins/${checkInId}`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getCheckOutById = function(checkOutId){
    const url = `/auth/warehouse/checkouts/${checkOutId}`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateCheckInById = function(checkInId,data){
    const url = `/auth/warehouse/checkins/${checkInId}`;
    return new Promise((resolve, reject)=>{
        axios.put(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateCheckOutById = function(checkOutId,data){
    const url = `/auth/warehouse/checkouts/${checkOutId}`;
    return new Promise((resolve, reject)=>{
        axios.put(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const reserveToCheckOutById = function(checkOutId,data){
    const url = `/auth/warehouse/checkouts/${checkOutId}/reserved-checkout`;
    return new Promise((resolve, reject)=>{
        axios.patch(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getCheckOutFilter = function(orgId,warehouseId,goodsId,checkOutStorage,filterType,checkInDetailIds){
    let url = `/auth/warehouse/checkout/filter?orgId=${orgId}&warehouseId=${warehouseId}&goodsId=${goodsId}&checkOutStorage=${checkOutStorage}&filterType=${filterType}`;
    if(filterType!=="FIFO"){
        url = url + `&checkInDetailIds=${checkInDetailIds}`;
    }
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const checkLotNumber = function(data){
    const url = '/auth/warehouse/checkins/check-lot-number';
    return new Promise((resolve, reject)=>{
        axios.post(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const downloadCheckInById = function(checkInId,utcOffset){
    let url = `/auth/warehouse/checkins/${checkInId}/download`;
    if(utcOffset&&utcOffset!==''){
        url = url + "?utcOffset=" + utcOffset;
    }
    return new Promise((resolve, reject)=>{
        axios({
            url: url,
            method: 'GET',
            responseType:'arraybuffer',
            headers: {
                "content-type": "application/json",
                "accept": "*/*"
            }
        }).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}


const downloadCheckOutById = function(checkOutId,utcOffset){
    let url = `/auth/warehouse/checkouts/${checkOutId}/download`;
    if(utcOffset&&utcOffset!==''){
        url = url + "?utcOffset=" + utcOffset;
    }
    return new Promise((resolve, reject)=>{
        axios({
            url: url,
            method: 'GET',
            responseType:'arraybuffer',
            headers: {
                "content-type": "application/json",
                "accept": "*/*"
            }
        }).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const batchUploadGoods = function(data){
    const url = '/auth/goods/batch';
    return new Promise((resolve, reject)=>{
        axios.post(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

export default {
    getWarehouses,
    addWarehouse,
    getWarehouse,
    updateWarehouse,
    getGoods,
    addGood,
    getGoodById,
    updateGood,
    deleteGood,
    searchGoodByNameOrId,
    addCompany,
    deleteCompanyById,
    updateCompany,
    searchCompany,
    searchWarehouseStock,
    downloadWarehouseStock,
    deleteWarehouseById,
    getWarehouseStaff,
    addCheckIn,
    addCheckOut,
    searchCheckIn,
    searchCheckOut,
    getGoodDetailById,
    verifyCheckIn,
    verifyCheckOut,
    deleteCheckIn,
    deleteCheckOut,
    getBillNumber,
    getCheckInById,
    getCheckOutById,
    updateCheckInById,
    updateCheckOutById,
    getCheckOutFilter,
    checkLotNumber,
    downloadCheckInById,
    downloadCheckOutById,
    searchGoodBySpec,
    batchUploadGoods,
    reserveToCheckOutById
}