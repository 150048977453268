import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import BookingMain from "./pages/booking/booking-main";
import BookingSchdule from "./pages/schdule/booking-schdule";
import OrganizationPage from './pages/organization/organizationPage';
import OrganizationCreate from './pages/organization/organizationCreate';
import PaySuccessPage from './pages/organization/PaySuccess';
import PayFailPage from './pages/organization/payFail';
import PoFormIssue from'./pages/openbook/poFormIssue';
import InvoiceAddPage from './pages/openbook/invoiceAddPage';
import PaymentAddPage from './pages/openbook/paymentAddPage';
import ShippingAddPage from './pages/openbook/shippingAddPage';
import BatchShippingAddPage from './pages/openbook/batchShippingAddPage';
import BatchInvoiceAddPage from './pages/openbook/batchInvoiceAddPage';
import BatchPaymentAddPage from './pages/openbook/batchPaymentAddPage';

import BeforeJoining from './pages/booking/BeforeJoining';
import UserList from './pages/manage/userList';
import PendingApproval from './pages/manage/pendingApproval';
import SettingPage from './pages/setting/setting-page';
import RegisterPage from './pages/register/RegisterPage';
import RegisterError from './pages/register/RegisterErrorPage';
import RegisterSuccess from './pages/register/RegisterSuccessPage';
import RegisterPending from './pages/register/RegisterPendingPage';
import useStyles from './styles/global';
import React, { useContext, useEffect, useState } from "react";
import PlatformHome from "./pages/platform-home/platform-home";
import TermsPage from "./pages/platform-home/termsPrivacy";
import ComingSoon from "./components/ComingSoon";
import DesignSystemHome from "./pages/design-system/home";
import NewBook from "./pages/openbook/new-book"
import AddWarehouse from "./pages/inventory/add-warehouse";
import EditWareHouse from "./pages/inventory/edit-warehouse";
import OpenBookMain from "./pages/openbook/main";
import OpenBookDetail from "./pages/openbook/detail";
import InventoryMain from "./pages/inventory/main";
import CheckInOutManagement from "./pages/inventory/checkInOutManagement";
import ReservationManagement from "./pages/inventory/reservationManagement";

import CheckInPage from "./pages/inventory/checkInPage";
import CheckOutPage from "./pages/inventory/checkOutPage";
import CustomerManagement from "./pages/inventory/customerManagement";
import GoodsManagement from "./pages/inventory/goodsManagement";
import MaterialGoodsManagement from "./pages/inventory/MaterialGoodsManagement";

import SupplierManagement from "./pages/inventory/supplierManagement";
import POLineMgt from "./pages/openbook/poLineManagement"
import StatementMgt from "./pages/openbook/statementManagement"
import PricePage from "./pages/price/pricePage"
import LiveSourcingPage from "./pages/liveSourcing/main"
import TechnicalSupport from './pages/technical/technical-support';
import PlatformHomeLearnMore from './pages/platform-home/platform-home-learn-more'

import POTransLearnMore from './pages/learn-more/po-transactions-learnmore';
import InventoryControlLearnMore from './pages/learn-more/inventory-control-learnmore';
import LiveSourcingLearnMore from './pages/learn-more/live-sourcing-learnmore';
import LiveOfficeLearnMore from './pages/learn-more/live-office-learnmore';

export default function AppRouters(props) {
    useStyles();
    // const [app1, setApp1] = useState();
    // useEffect(()=>{
    //     if(props.apps && props.apps.length > 0){
    //         setApp1(props.apps[0]);
    //     }
    // },[props.apps])
    return (

            <Switch>
                <Route path="/Live Officiating">
                    <ComingSoon title="Live Officiating"></ComingSoon>
                </Route>
                <Route path="/Live Sourcing">
                    <LiveSourcingPage></LiveSourcingPage>
                </Route>
                <Route path="/Live Auditing">
                    <ComingSoon title="Live Auditing"></ComingSoon>
                </Route>
                <Route path="/Live Pairing">
                    <ComingSoon title="Live Pairing"></ComingSoon>
                </Route>
                <Route path="/Open Comm">
                    <BookingMain></BookingMain>
                </Route>
                <Route path="/booking-schdule">
                    <BookingSchdule></BookingSchdule>
                </Route>
                <Route path="/Open Trace" exact>
                    <ComingSoon title="Open Trace"></ComingSoon>
                </Route>
                <Route path="/Inventory" exact>
                    <InventoryMain></InventoryMain>
                </Route>
                <Route path="/Inventory/AddWareHouse" exact>
                    <AddWarehouse></AddWarehouse>
                </Route>
                <Route path="/Inventory/EditWareHouse" exact>
                    <EditWareHouse></EditWareHouse>
                </Route>
                <Route path="/Inventory/CheckInOutMgt" exact>
                    <CheckInOutManagement></CheckInOutManagement>
                </Route>
                <Route path="/Inventory/ReservationMgt" exact>
                    <ReservationManagement></ReservationManagement>
                </Route>
                <Route path="/Inventory/CheckInPage" exact>
                    <CheckInPage></CheckInPage>
                </Route>
                <Route path="/Inventory/CheckOutPage" exact>
                    <CheckOutPage></CheckOutPage>
                </Route>
                <Route path="/Inventory/GoodsMgt" exact>
                    <GoodsManagement></GoodsManagement>
                </Route>
                <Route path="/Inventory/MaterialGoodsMgt" exact>
                    <MaterialGoodsManagement></MaterialGoodsManagement>
                </Route>
                <Route path="/Inventory/SupplierMgt" exact>
                    <SupplierManagement></SupplierManagement>
                </Route>
                <Route path="/Inventory/CustmorMgt" exact>
                    <CustomerManagement></CustomerManagement>
                </Route>
                <Route path="/PO Transactions" exact>
                    <OpenBookMain></OpenBookMain>
                </Route>
                <Route path="/PO Transactions/poLineMgt" exact>
                    <POLineMgt></POLineMgt>
                </Route>
                <Route path="/PO Transactions/statementMgt" exact>
                    <StatementMgt></StatementMgt>
                </Route>
                <Route path="/PO Transactions/detail/:bookId">
                    <OpenBookDetail></OpenBookDetail>
                </Route>
                <Route path="/PO Transactions/newbook">
                    <NewBook></NewBook>
                </Route>
                <Route path="/PO Transactions/PoForm/issue" exact>
                    <PoFormIssue></PoFormIssue>
                </Route>
                <Route path="/PO Transactions/Invoice/add" exact>
                    <InvoiceAddPage></InvoiceAddPage>
                </Route>
                <Route path="/PO Transactions/Payment/add" exact>
                    <PaymentAddPage></PaymentAddPage>
                </Route>
                <Route path="/PO Transactions/Shipping/add" exact>
                    <ShippingAddPage></ShippingAddPage>
                </Route>
                <Route path="/PO Transactions/Shipping/batchAdd" exact>
                    <BatchShippingAddPage></BatchShippingAddPage>
                </Route>
                <Route path="/PO Transactions/Invoice/batchAdd" exact>
                    <BatchInvoiceAddPage></BatchInvoiceAddPage>
                </Route>
                <Route path="/PO Transactions/Payment/batchAdd" exact>
                    <BatchPaymentAddPage></BatchPaymentAddPage>
                </Route>
                <Route path="/requests/:meetingId/:conferenceId">
                    <BeforeJoining></BeforeJoining>
                </Route>
                <Route path="/design-system">
                    <DesignSystemHome></DesignSystemHome>
                </Route>
                <Route path="/management/services/:service_name/organizations" exact>
                    <OrganizationPage></OrganizationPage>
                </Route>
                <Route path="/management/services/:service_name/organizations/create" >
                    <OrganizationCreate></OrganizationCreate>
                </Route>
                <Route path="/organization/success" exact>
                    <PaySuccessPage></PaySuccessPage>
                </Route>
                <Route path="/organization/fail" exact>
                    <PayFailPage></PayFailPage>
                </Route>
                <Route path="/management/userlist">
                    <UserList></UserList>
                </Route>
                <Route path="/management/pendingapproval">
                    <PendingApproval></PendingApproval>
                </Route>
                <Route path='/register'>
                    <RegisterPage></RegisterPage>
                </Route>
                <Route path="/register-error" component={RegisterError} />
                <Route path="/register-success" component={RegisterSuccess} />
                <Route path="/register-pending" component={RegisterPending} />
                <Route exact path="/setting">
                    <Redirect to="/setting/personal-profile" />
                </Route>
                <Route path='/setting/'>
                    <SettingPage />
                </Route>
                <Route path="/Inventory/goodsManagement" exact>
                    <GoodsManagement></GoodsManagement>
                </Route>
                <Route path="/Inventory/customerManagement" exact>
                    <CustomerManagement></CustomerManagement>
                </Route>
                <Route path="/Inventory/supplierManagement" exact>
                    <SupplierManagement></SupplierManagement>
                </Route>
                <Route path="/price">
                    <PricePage></PricePage>
                </Route>
                <Route path='/terms-privacy'>
                    <TermsPage />
                </Route>
                <Route path='/technical-support'>
                  <TechnicalSupport />
                </Route>
                <Route path='/home/learn-more'>
                  <PlatformHomeLearnMore />
                </Route>
                <Route path='/home/po-learn-more'>
                  <POTransLearnMore />
                </Route>
                <Route path='/home/inventory-learn-more'>
                  <InventoryControlLearnMore />
                </Route>
                <Route path='/home/source-learn-more'>
                  <LiveSourcingLearnMore />
                </Route>
                <Route path='/home/office-learn-more'>
                  <LiveOfficeLearnMore />
                </Route>
                <Route path='/'>
                    <PlatformHome />
                </Route>
            </Switch>
    )

}
