import React,{useContext}  from 'react';
import { Component, useEffect, useState, useRef} from 'react';
import { Link, useParams, useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, ButtonGroup,Menu, Select, MenuItem, FormControl, InputLabel, TextField, Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';

import useToken from '../../hooks/useToken';
import CommonLayout from "../../components/CommonLayout";
import { ApplicationContext } from "../../context/applicationContext";
import OrganizationSelect from "../../components/OrganizationSelect";
import inventoryService from '../../services/inventoryService';
import userService from "../../services/userService";
import WareHouseItem from "./wareHouseItem";
import inventoryDescriptionPic1 from '../../assets/img/inventory-introduction.png';
import inventoryDescriptionPic2 from '../../assets/img/inventory-introduction@2x.png';
import inventoryDescriptionPic3 from '../../assets/img/inventory-introduction@2x.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '50px'
    },
    box: {
        padding: '30px',
        width:'100%',
        [theme.breakpoints.down('md')]:{
            padding: 0,
        }
    },
    outlinedPadding: {
        padding: '11px 14px',
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft:0,
        width: '100%'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    tabsTitle: {
        margin: "30px 0 0 0",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[3],
    },
    tabsRoot: {
        textTransform: "none",
    },
    tabsLabel: {
        fontFamily: "IBMPlexSans-Regular",
        fontSize: "16px"
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

export default function Main() {
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const { token } = useToken();

    const history = useHistory();

    const [value, setValue] = React.useState(0);

    const [initing, setIniting] = React.useState(true);

    const [orgExpired, setOrgExpired] = React.useState(false);

    const [openCreateOrg, setOpenCreateOrg] = React.useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [canAddNewOrg, setCanAddNewOrg] = useState(true);

    const {application,refreshApplication} = useContext(ApplicationContext);

    const [currentOrg, setCurrentOrg] = useState('');

    const [tabItem, setTabItem] = useState([]);
    useEffect(()=>{
        if(currentOrg!==""){
            getWarehousesList();
        }
    },[currentOrg])

    const getWarehousesList = () =>{
        inventoryService.getWarehouses(currentOrg).then((result) => {
            if(result.data && result.data.datas){
                // let temp=[];
                // result.data.datas.map(item =>{
                //     temp.push(item.name);
                // })
                //setTabItem(["Finished Goods", "Material", "Transfer", "Scraped"]);
                let openedWarehouse = [];
                result.data.datas.map(item=>{
                    if(item.status === 'normal'){
                        openedWarehouse.push(item);
                    }
                })
                // console.log(openedWarehouse);
                setTabItem(openedWarehouse);
            }
        })
    }

    useEffect(() => {
        userService.getUserDetailsByEmail(token.username).then((result)=>{
            if(result&&result.status===200){
                // console.log(result)
                if(result.data.orgNumber>=10){
                    setCanAddNewOrg(false);
                }
                else{
                    setCanAddNewOrg(true);
                }
            }
        });
    },[])

    useEffect(() => {
        const currentWTab = sessionStorage.getItem('currentWTab');
        if(currentWTab){
            if(tabItem.length>parseInt(currentWTab)){
                setValue(parseInt(currentWTab))
            }
            else{
                setValue(0);
            }
            // console.log(currentWTab);
        }
    },[tabItem])

    const handleChange = (event, newValue) => {
        sessionStorage.setItem('currentWTab', newValue);
        setValue(newValue);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const jump2CheckInOutMgt = () => {
        const settingURL = '/Inventory/CheckInOutMgt';
        history.push({
            pathname: settingURL,
            state: { "orgId": currentOrg },
        });
        handleClose();
    }

    const jump2ReservationMgt = () => {
        const settingURL = '/Inventory/ReservationMgt';
        history.push({
            pathname: settingURL,
            state: { "orgId": currentOrg },
        });
        handleClose();
    }

    const jump2GoodsMgt = () => {
        const settingURL = '/Inventory/GoodsMgt';
        history.push({
            pathname: settingURL,
            state: { "orgId": currentOrg },
        });
        handleClose();
    }

    const jump2MaterialGoodsMgt = () => {
        const settingURL = '/Inventory/MaterialGoodsMgt';
        history.push({
            pathname: settingURL,
            state: { "orgId": currentOrg },
        });
        handleClose();
    }

    const jump2SupplierMgt = () => {
        const settingURL = '/Inventory/SupplierMgt';
        history.push(settingURL);
        handleClose();
    }

    const jump2CustmorMgt = () => {
        const settingURL = '/Inventory/CustmorMgt';
        history.push(settingURL);
        handleClose();
    }

    const jump2AddWareHouse = () => {
        const settingURL = '/Inventory/AddWareHouse';
        history.push({
            pathname: settingURL,
            state: { "orgId": currentOrg },
        });
    }

    return (
        <CommonLayout>
            <div className={classes.box}>
                <OrganizationSelect onSetIniting={setIniting} onSetOpenCreateOrg={setOpenCreateOrg} onSetCurrentOrg={setCurrentOrg} onSetOrgExpired={setOrgExpired} initing={initing} openCreateOrg={openCreateOrg} currentOrg={currentOrg} orgExpired={orgExpired}></OrganizationSelect>
                {
                    (!initing && (openCreateOrg || !currentOrg)) &&
                    <Grid>
                        <div style={{padding:'20px'}}>
                            <Typography variant="body1" style={{marginBottom:"20px"}}>
                            {t('homePage.createOrgTips')}
                            </Typography>
                            <Grid container spacing={4}>
                                <Grid item md={8} xs={12}>
                                    <img src={inventoryDescriptionPic1} srcSet={`${inventoryDescriptionPic1} 300w, ${inventoryDescriptionPic2} 768w, ${inventoryDescriptionPic3} 1280w`} style={{width:"100%",objectFit:"contain"}}></img>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography variant="body1" style={{color:"#818181"}}>
                                    <b style={{fontFamily:"Lato-Black"}}>{t('homePage.description1')}</b> {t('homePage.description2')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        
                    </Grid>
                }
                {(!initing && !openCreateOrg && currentOrg)&&<div style={{padding:'8px'}}>
                    <Grid container spacing={0} justifyContent="flex-start" direction="row" alignItems="center">
                        <Grid item xs={12} md={9}>
                            <Typography variant="body1">{t('homePage.warehouse')}</Typography>
                        </Grid>
    
                        <Grid item xs={12} md={3} style={{textAlign:"right"}}>
                            <Button variant="contained"  size="small" onClick={handleClick}>
                                <span style={{marginLeft:'4px',color:"#2DA44E",width:'150px',fontSize:'16px'}}>{t('homePage.management')}</span>
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                            >
                                <MenuItem onClick={jump2CheckInOutMgt}>
                                    <Typography variant="body1">{t('homePage.checkInOutMgt')}</Typography>
                                </MenuItem>
                                <MenuItem onClick={jump2ReservationMgt}>
                                    <Typography variant="body1">{t('homePage.resvationMgt')}</Typography>
                                </MenuItem>
                                <MenuItem onClick={jump2GoodsMgt}>
                                    <Typography variant="body1">{t('homePage.goodsMgt')}</Typography>
                                </MenuItem>
                                <MenuItem onClick={jump2MaterialGoodsMgt}>
                                    <Typography variant="body1">{t('homePage.materialGoodsMgt')}</Typography>
                                </MenuItem>
                                {/* <MenuItem onClick={jump2SupplierMgt}>
                                    <Typography variant="body1">{t('homePage.supplierMgt')}</Typography>
                                </MenuItem>
                                <MenuItem onClick={jump2CustmorMgt}>
                                    <Typography variant="body1">{t('homePage.customerMgt')}</Typography>
                                </MenuItem> */}
                            </Menu>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between" alignItems="center" className={classes.tabsTitle} >
                        <Grid item xs={11}>
                            <Tabs 
                                value={value} 
                                onChange={handleChange} 
                                aria-label="basic tabs example"  
                                TabIndicatorProps={{ style: { background: "#FF5722" } }}
                            >
                            {   
                                tabItem.map( (item, index) => {
                                    return <Tab key={index} label={item.name} classes={{root: classes.tabsRoot, wrapper: classes.tabsLabel}} {...a11yProps(index)} />
                                })
                            }
                            </Tabs>
                        </Grid>
                        <Grid item xs={1} style={{textAlign:"right"}}>
                            <Tooltip title={t(t('homePage.addWarehouse'))}>
                                <IconButton onClick={jump2AddWareHouse} aria-label="search">
                                    <AddIcon style={{color: "#2DA44E"}}></AddIcon>
                                </IconButton>
                            </Tooltip>
                            
                        </Grid>
                    </Grid>

                    <Grid>
                        {   
                            tabItem.map( (item, index) => {
                                return (
                                    <TabPanel value={value} key={index} index={index}>
                                        <WareHouseItem warehouseItem={item} searchDefault={history.location.state&&history.location.state.searchKeywords?history.location.state.searchKeywords:''} onRefresh={getWarehousesList}></WareHouseItem>
                                    </TabPanel>
                                )
                            })
                        }
                    </Grid>
                </div>}
            </div>
        </CommonLayout>
    )
}