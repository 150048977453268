import superAdminService from "../../services/superAdminService";
import {useEffect, useState} from "react";
import { Grid, Button } from '@material-ui/core';
import moment from "moment";
import countriesConfig from "../../mocks/countries.json";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    box: {
      padding: '30px 30px 10px 30px',
      width:'100%',
      maxWidth:'1200px'
    }
}))
export default function PendingApproval(props){
    const classes = useStyles();
    const columnsConfig = [
        {title: "Email", field: 'email', width: "20%"},
        {title:"Company", field: 'company_name', width: "25%"},
        {title: "Name", field: 'username', width: "15%"},
        {title: "Location", field: 'location', width: "10%"},
        {title: "Apply Date", field: 'createdAt', width: "10%"},
        {title: "Operation", field: '', width: "20%"}]
    const [pendingApprovals, setPendingApprovals] = useState([]);

    useEffect(()=>{
        refreshApprovals()
    },[])

    const refreshApprovals = ()=> {
        superAdminService.getPendingApprovals().then((result)=> {
            if(result.code === 200) {
                result.data.forEach((item)=> {
                    item.createdAt = moment(item.createdAt).format("YYYY-MM-DD")
                    let countryConfig = countriesConfig.find((country)=> {
                        return country.code === item.location
                    })
                    item.location = countryConfig? countryConfig.name: item.location
                })
                setPendingApprovals(result.data)
            }
        })
    }

    const approveApply = (request)=> {
        superAdminService.approve(request.email).then(()=> {
            refreshApprovals()
        })
    }

    const rejectApply = (request)=> {
        superAdminService.reject(request.email).then(()=> {
            refreshApprovals()
        })
    }

    const rowStyle = {padding: "10px 0px", borderBottom: "1px solid #cdcdcd"}
    return (
        <div className={classes.box}>
            <div style={rowStyle}>
                {columnsConfig.map((column)=> {
                    return (<div style={{display: 'inline-block', width: column.width}}>{column.title}</div>)
                })}
            </div>

            {pendingApprovals.map((request)=> {
                return (<div style={rowStyle}>
                    {columnsConfig.map((column,index)=> {
                        return (<div style={{display: 'inline-block',wordWrap:'break-word', width: column.width}} key={index}>
                            {column.field?
                                request[column.field]:
                                (<div>
                                    <Button type="primary" onClick={()=> approveApply(request)}>Approve</Button>
                                    <Button onClick={()=> rejectApply(request)} style={{marginLeft: '10px'}}>Reject</Button>
                                </div>)}
                        </div>)
                    })}
                </div>)
            })}
        </div>
    )

}