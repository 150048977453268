import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Checkbox,Input, TextField, Grid} from '@material-ui/core';
import {
    Link, useParams,useHistory
} from "react-router-dom";
import CommonLayout from "../../components/CommonLayout";
import meetingService from '../../services/meetingService';
import useToken from '../../hooks/useToken';
import requestsMock from '../../mocks/requests.json';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    errorShow: {
        display:'',
        color:'red',
        paddingLeft:'20px'
    },
}));

export default function BeforeJoining() {
    const classes = useStyles();
    const {token,setToken} = useToken();
    let params = useParams();
    const history = useHistory();
    const [requests, setRequest] = useState([]);
    useEffect(()=>{
        
        if(history.location.state &&  history.location.state.requests){
            const values = history.location.state.requests.map((item)=>{
                return {label:item.displayName,value:item.name};
            });
            setRequest(values);
        }

    },[])
    const [checkedValues, setCheckedValues] = useState([]);
    const [otherError, setOtherError] = useState(false);

    const checkValid = (values)=> {
        // if(values.length===0)
        // {
        //     setOtherError('Please select 1 item at least!')
        //     return false;
        // }
        // else if(values && values.includes('other')){
        //     if(!otherRequest || !otherRequest.trim()) {
        //         setOtherError('Please input other request!')
        //         return false;
        //     }
        // }
        return true
    }

    function onFinish(){
        if(!checkValid(checkedValues)) {
            return;
        }
        // if(checkedValues && checkedValues.includes('other')){
        //     let otherIndex = checkedValues.findIndex(item => item === 'other');
        //     checkedValues[otherIndex] = 'other:' + otherRequest;
        // }
        const meetingId = params.meetingId;
        meetingService.submitRequests(meetingId,checkedValues).then((result)=>{
            window.open(getMeetingUrl(),'_blank');
            history.push('/Open Comm');
        })
    };

    function getMeetingUrl(){
        return process.env.REACT_APP_BOOTH_VIDEO_URL + params.conferenceId;
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const cancelHandler = () =>{
        history.push('/Open Comm');
    }
    function onChange(event) {
        let options = checkedValues;
        if(event.target.checked){
            options.push(event.target.value)
        }
        else{
            let removeIndex = options.findIndex(item => item === event.target.value);
            options.splice(removeIndex,1);
        }
        setCheckedValues(options);
        if(!checkedValues || !checkedValues.includes('other')){
            setOtherError(true)
        }
    }
    //const disabled = (!token.user_info.signature || !token.user_info.signature.name)? true :false;
    const [joinButtonDisabled,setJoinButtonDisabled] = useState(false);
      

    return (
        <CommonLayout>
            <Grid container spacing={2} direction="row" style={{marginTop:"50px"}}>
                <Grid item xs={4} style={{textAlign:"right"}}>
                </Grid>
                <Grid item xs={8}>
                
                    <Grid container spacing={2} direction="column">
                        <Grid item><span>Choose your inquires:</span></Grid>
                        {requests.map((item)=>{
                            return(<Grid item key={item.value}>
                                <Checkbox value={item.value} onChange={onChange} color="primary"></Checkbox><span className="title_small" style={{fontSize:'14px'}}>{item.label}</span>
                                </Grid>)
                        })}
                        {/* <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item><Checkbox value="other" onChange={onChange} color="primary"></Checkbox><span className="title_small h-gap-8">Other:</span> </Grid> 
                                <Grid item><TextField variant="outlined" size="small" onChange={otherRequestsHandler}/></Grid>
                            </Grid>                                     
                        </Grid>                                      */}
                        <span className={classes.errorShow}>{otherError}</span>
                        {/* {   
                            !joinButtonDisabled ||
                            <SignatureSetting onSuccess={()=> setJoinButtonDisabled(false)}></SignatureSetting>
                        } */}
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                            <Grid item>
                                    <Button type="primary" variant="contained" color="primary" onClick={onFinish}>
                                    Confirm and Join
                                </Button>
                            </Grid>
                            <Grid item>
                                <Link onClick={cancelHandler}>
                                    <Button variant="contained" color="default">
                                        Cancel
                                    </Button>
                                </Link>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CommonLayout>
    );
}