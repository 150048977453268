import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {useTranslation} from "react-i18next";

import homepage_bg_4_3 from "../../assets/img/homepage_bg4@3x.png";

import HowToRegIcon from '@material-ui/icons/HowToReg';
import GroupIcon from '@material-ui/icons/Group';
import PaymentIcon from '@material-ui/icons/Payment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import InfoIcon from '@material-ui/icons/Info';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import BusinessIcon from '@material-ui/icons/Business';
import InputIcon from '@material-ui/icons/Input';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import WarningIcon from '@material-ui/icons/Warning';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import StorageIcon from '@material-ui/icons/Storage';
import SearchIcon from '@material-ui/icons/Search';
import SecurityIcon from '@material-ui/icons/Security';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import EditIcon from '@material-ui/icons/Edit';
import {useMediaQuery} from "@material-ui/core";
import SpeedDial from '@material-ui/lab/SpeedDial';
import Drawer from "@material-ui/core/Drawer";

const drawerWidth = 280;

const videoArray = [
  'https://player.bilibili.com/player.html?aid=981219293&bvid=BV1P44y1u779&cid=710897969&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=383695815&bvid=BV1WZ4y1y7WE&cid=711062116&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=468833199&bvid=BV1N5411d7kz&cid=711608619&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=213837037&bvid=BV12a411a7F4&cid=711839644&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=341365592&bvid=BV1fR4y1w7b6&cid=711812410&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=684179755&bvid=BV1fU4y1m7Jh&cid=723904056&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=469140415&bvid=BV1M5411R7R4&cid=724220453&page=1&high_quality=1',
  '',
  'https://player.bilibili.com/player.html?aid=256635940&bvid=BV1nY411w7G6&cid=724916005&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=639245753&bvid=BV1VY4y167p4&cid=725300970&page=1&high_quality=1',
  '',
  '',
  '',
  'https://player.bilibili.com/player.html?aid=811997655&bvid=BV1T34y177k5&cid=731185163&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=939390598&bvid=BV1BT4y1q7vN&cid=731180721&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=770118391&bvid=BV1Vr4y1G7Xg&cid=748183254&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=726244537&bvid=BV1AS4y1h7Mp&cid=711086801&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=896208608&bvid=BV1uA4y1Q7s6&cid=711096683&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=683635889&bvid=BV1ES4y1w7Nj&cid=711116367&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=938685080&bvid=BV1LT4y1k7yD&cid=711131968&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=938628240&bvid=BV1mT4y1r78D&cid=711111408&page=1&high_quality=1',
  'https://player.bilibili.com/player.html?aid=811208375&bvid=BV1X34y1a7bF&cid=711132690&page=1&high_quality=1',
];

const menuArray = [
  '注册和登录',
  '组群',
  '付费订阅服务',
  '优惠券',
  '个人信息设置',
  'PO追记 - 简介',
  'PO追记 - 账本',
  'PO追记 - 采购商和供应商权限',
  'PO追记 - PO表单',
  'PO追记 - PO行操作',
  'PO追记 - PO行关联号',
  'PO追记 - PO行预警',
  'PO追记 - 收发',
  'PO追记 - 发票',
  'PO追记 - 支付',
  'PO追记 - 总账本',
  '库存管理 - 仓库',
  '库存管理 - 成品目录和入库',
  '库存管理 - 预留',
  '库存管理 - 出库',
  '库存管理 - 出入库审核和存量查询',
  '库存管理 - 材料库'

]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  treeRoot: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent !important',
    },
    width: '30%',
    [theme.breakpoints.down('sm')]:{
      width: '100%',
    }
  },
  treeItemRoot: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent !important',
    },
    width: '80%',
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  box_base_3_4: {
    display: 'flex',
    flexFlow: 'column',
    backgroundSize: "100% auto",
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${homepage_bg_4_3})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition:'center',
    height: '100%',
  },
  menuContentRoot: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px',
    marginLeft: '20px',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]:{
      marginLeft: 'unset',
    },
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    marginTop: '-51.5px',
    [theme.breakpoints.down('sm')]:{
      width: '100%',
      marginTop: 'unset',
    },
  },
  videoRoot: {
    marginTop: '30px',
    width: '800px',
    height: '600px',
    backgroundColor: 'black',
    [theme.breakpoints.down('sm')]:{
      width: '320px',
      // height: '95%',
      marginTop: 'unset',
      height: '240px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  moreTitle: {
    marginTop: '30px',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]:{
      alignSelf: 'center',
    }
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: '700px',
    backgroundColor: theme.currentTheme.backgrounds[0],
    marginTop: '56px',
  },
  drawerHeader: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  menuButton: {
  },
  hide: {
    display: 'none',
  },
  fullList: {
    width: 'auto',
  },
  list: {
    width: 250,
  },
  titleRoot: {
    marginTop: '30px',
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]:{
      marginLeft: '10px',
    }
  }
}));

export default function TechnicalSupport() {
  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const isMobile = useMediaQuery((theme)=>{
    return theme.breakpoints.down('sm');
  });

  const [videoSrc, setVideoSrc] = React.useState('');
  const [menuTitle, setMenuTitle] = React.useState('')

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
    setState({ ...state, [anchor]: open });
  };

  const handlerTreeItem = (index) => {
    setMenuTitle(menuArray[index]);
    setVideoSrc(videoArray[index]);
  }

  function TreeMenu() {

    return (
      <TreeView
        className={classes.treeRoot}
        defaultExpanded={['6', '18']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}>
        <StyledTreeItem nodeId="1" labelText="注册和登录" color="#3c8039" bgColor="#e6f4ea" labelIcon={HowToRegIcon}
          onClick={() => {handlerTreeItem(0)}}
        />
        <StyledTreeItem nodeId="2" labelText="群组" color="#3c8039" bgColor="#e6f4ea" labelIcon={GroupIcon}
          onClick={() => handlerTreeItem(1)}
        />
        <StyledTreeItem nodeId="3" labelText="付费订阅服务" color="#3c8039" bgColor="#e6f4ea" labelIcon={AttachMoneyIcon}
          onClick={() => handlerTreeItem(2)}
        />
        <StyledTreeItem nodeId="4" labelText="优惠券" color="#3c8039" bgColor="#e6f4ea" labelIcon={PaymentIcon} onClick={() => handlerTreeItem(3)}/>
        <StyledTreeItem nodeId="5" labelText="个人信息设置" color="#3c8039" bgColor="#e6f4ea" labelIcon={PermIdentityIcon} onClick={() => handlerTreeItem(4)}/>
        <StyledTreeItem nodeId="6" labelText="PO追记" color="#3c8039" bgColor="#e6f4ea" labelIcon={LibraryBooksIcon}>
          <StyledTreeItem
            nodeId="7"
            labelText="简介"
            labelIcon={InfoIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(5)}
          />
          <StyledTreeItem
            nodeId="8"
            labelText="账本"
            labelIcon={LocalLibraryIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(6)}
          />
          <StyledTreeItem
            nodeId="9"
            labelText="采购商和供应商权限"
            labelIcon={SecurityIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(7)}
          />
          <StyledTreeItem
            nodeId="10"
            labelText="PO表单"
            labelIcon={InputIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(8)}
          />
          <StyledTreeItem
            nodeId="11"
            labelText="PO行操作"
            labelIcon={LinearScaleIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(9)}
          />
          <StyledTreeItem
            nodeId="12"
            labelText="PO行关联号"
            labelIcon={LocalOfferIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(10)}
          />
          <StyledTreeItem
            nodeId="13"
            labelText="PO行预警"
            labelIcon={WarningIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(11)}
          />
          <StyledTreeItem
            nodeId="14"
            labelText="收发"
            labelIcon={SyncAltIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(12)}
          />
          <StyledTreeItem
            nodeId="15"
            labelText="发票"
            labelIcon={ReceiptIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(13)}
          />
          <StyledTreeItem
            nodeId="16"
            labelText="支付"
            labelIcon={PaymentIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(14)}
          />
          <StyledTreeItem
            nodeId="17"
            labelText="总账本"
            labelIcon={MenuBookIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(15)}
          />

        </StyledTreeItem>
        <StyledTreeItem nodeId="18" labelText="库存管理" color="#3c8039" bgColor="#e6f4ea" labelIcon={StorageIcon}>
          <StyledTreeItem
            nodeId="19"
            labelText="仓库"
            labelIcon={BusinessIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(16)}
          />
          <StyledTreeItem
            nodeId="20"
            labelText="成品目录和入库"
            labelIcon={AddCircleOutlineIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(17)}
          />
          <StyledTreeItem
            nodeId="21"
            labelText="预留"
            labelIcon={EditIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(18)}
          />
          <StyledTreeItem
            nodeId="22"
            labelText="出库"
            labelIcon={RemoveCircleOutlineIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(19)}
          />
          <StyledTreeItem
            nodeId="23"
            labelText="出入库审核和存量查询"
            labelIcon={SearchIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(20)}
          />
          <StyledTreeItem
            nodeId="24"
            labelText="材料库"
            labelIcon={SelectAllIcon}
            color="#3c8039"
            bgColor="#e6f4ea"
            onClick={() => handlerTreeItem(21)}
          />
        </StyledTreeItem>
      </TreeView>
    );
  }

  function StyledTreeItem(props) {
    const classes = useStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.treeItemRoot,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    );
  }

  StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
  };

  const rightDrawer = (anchor) => (
    <div
      className={classes.drawer}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <div className={classes.drawerHeader}>
        <TreeMenu />
      </div>
    </div>
  );

  return (

    <div className={classes.root}>
      <Typography variant="h3" className={classes.titleRoot}>
       技术支持
        {
          isMobile && menuTitle && <span style={{marginLeft: '5px'}}> >&nbsp; {menuTitle}</span>
        }
      </Typography>

      <div className={classes.menuContentRoot}>
        {
          !isMobile &&
          <TreeView
            className={classes.treeRoot}
            defaultExpanded={['6', '18']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}>
            <StyledTreeItem nodeId="1" labelText="注册和登录" color="#3c8039" bgColor="#e6f4ea" labelIcon={HowToRegIcon}
              onClick={() => {handlerTreeItem(0)}}
            />
            <StyledTreeItem nodeId="2" labelText="群组" color="#3c8039" bgColor="#e6f4ea" labelIcon={GroupIcon}
              onClick={() => handlerTreeItem(1)}
            />
            <StyledTreeItem nodeId="3" labelText="付费订阅服务" color="#3c8039" bgColor="#e6f4ea" labelIcon={AttachMoneyIcon}
              onClick={() => handlerTreeItem(2)}
            />
            <StyledTreeItem nodeId="4" labelText="优惠券" color="#3c8039" bgColor="#e6f4ea" labelIcon={PaymentIcon} onClick={() => handlerTreeItem(3)}/>
            <StyledTreeItem nodeId="5" labelText="个人信息设置" color="#3c8039" bgColor="#e6f4ea" labelIcon={PermIdentityIcon} onClick={() => handlerTreeItem(4)}/>
            <StyledTreeItem nodeId="6" labelText="PO追记" color="#3c8039" bgColor="#e6f4ea" labelIcon={LibraryBooksIcon}>
              <StyledTreeItem
                nodeId="7"
                labelText="简介"
                labelIcon={InfoIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(5)}
              />
              <StyledTreeItem
                nodeId="8"
                labelText="账本"
                labelIcon={LocalLibraryIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(6)}
              />
              <StyledTreeItem
                nodeId="9"
                labelText="采购商和供应商权限"
                labelIcon={SecurityIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(7)}
              />
              <StyledTreeItem
                nodeId="10"
                labelText="PO表单"
                labelIcon={InputIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(8)}
              />
              <StyledTreeItem
                nodeId="11"
                labelText="PO行操作"
                labelIcon={LinearScaleIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(9)}
              />
              <StyledTreeItem
                nodeId="12"
                labelText="PO行关联号"
                labelIcon={LocalOfferIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(10)}
              />
              <StyledTreeItem
                nodeId="13"
                labelText="PO行预警"
                labelIcon={WarningIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(11)}
              />
              <StyledTreeItem
                nodeId="14"
                labelText="收发"
                labelIcon={SyncAltIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(12)}
              />
              <StyledTreeItem
                nodeId="15"
                labelText="发票"
                labelIcon={ReceiptIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(13)}
              />
              <StyledTreeItem
                nodeId="16"
                labelText="支付"
                labelIcon={PaymentIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(14)}
              />
              <StyledTreeItem
                nodeId="17"
                labelText="总账本"
                labelIcon={MenuBookIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(15)}
              />

            </StyledTreeItem>
            <StyledTreeItem nodeId="18" labelText="库存管理" color="#3c8039" bgColor="#e6f4ea" labelIcon={StorageIcon}>
              <StyledTreeItem
                nodeId="19"
                labelText="仓库"
                labelIcon={BusinessIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(16)}
              />
              <StyledTreeItem
                nodeId="20"
                labelText="成品目录和入库"
                labelIcon={AddCircleOutlineIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(17)}
              />
              <StyledTreeItem
                nodeId="21"
                labelText="预留"
                labelIcon={EditIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(18)}
              />
              <StyledTreeItem
                nodeId="22"
                labelText="出库"
                labelIcon={RemoveCircleOutlineIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(19)}
              />
              <StyledTreeItem
                nodeId="23"
                labelText="出入库审核和存量查询"
                labelIcon={SearchIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(20)}
              />
              <StyledTreeItem
                nodeId="24"
                labelText="材料库"
                labelIcon={SelectAllIcon}
                color="#3c8039"
                bgColor="#e6f4ea"
                onClick={() => handlerTreeItem(21)}
              />
            </StyledTreeItem>
          </TreeView>
        }

        <div className={classes.contentRoot}>

          {
            !isMobile && menuTitle && <Typography variant="h3">{menuTitle}</Typography>
          }

          {
            !isMobile && !menuTitle && <Typography variant="h3">&nbsp;</Typography>
          }

          <div className={classes.videoRoot} style={{alignSelf: 'center'}}>
            {
              videoSrc &&
              <iframe src={videoSrc}
                      scrolling="no" border="0" frameBorder="no" framespacing="0" allowFullScreen="true" style={{width: '100%', height: '100%'}}></iframe>
            }

            {
              !videoSrc &&
                <div className={classes.box_base_3_4}>
                  <Typography variant="h3" style={{color: 'white'}}>openuse.co 技术支持</Typography>
                  <Typography variant="h3" style={{color: 'white', marginTop: '20px'}}>请选择主题</Typography>
                </div>
            }

          </div>

          <Typography variant="h3" className={classes.moreTitle}>
            获得更多支持，请联系 support@openuse.io
          </Typography>

          {
            isMobile &&
            <SpeedDial
              ariaLabel="SpeedDial"
              className={classes.speedDial}
              icon={<Typography variant="h3">主题</Typography>}
              onClick={toggleDrawer("left", true)}
              open={open}
            />
          }

          {
            isMobile &&
            <Drawer anchor={'left'}
                    open={state['left']}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    onClose={toggleDrawer('left', false)}>
              {rightDrawer('left')}
            </Drawer>
          }

        </div>
      </div>

    </div>


  );
}
