import './RegisterPage.style'
import RegisterForm from './RegisterForm'
import { Typography } from '@material-ui/core';
import useStyle from './RegisterPage.style';

export default function RegisterPage(props){
    const classes = useStyle();

    return (
        <div className={classes.container}>
            <RegisterForm></RegisterForm>
        </div>
    )
}
