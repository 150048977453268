import React , {useEffect ,useState, useRef, useContext}from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from "react-router-dom";
import { Grid, Paper, Typography, Button, IconButton, Box, Collapse} from '@material-ui/core';
import { Table, TableBody, TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import { ApplicationContext } from "../../context/applicationContext";
import useToken from '../../hooks/useToken';
import accountingService from '../../services/accountingService';
import noImage from '../../assets/img/invoice&payment.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme=> ({
    orgListItemDetailPanel: {
        marginBottom:"13px",
        borderBottom: "none ",
    },
    thTitle: {
        letterSpacing: "0.05px",
        color: "#5d5d5d",
    },
    thBorder: {
        borderBottom: "none ",
        fontSize:"14px",
        padding:'6px'
    },
    issuePoGrid:{
        width:'100%',
        textAlign:'center',
    },
    issuePoImage:{
        maxWidth:'150px'
    },
}))

export default function PayMentLineItem(props){
    const PaymentAddPage = "/PO Transactions/Payment/add";

    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const history = useHistory();

    const {token, setToken} = useToken();
    const [currentBook,setCurrentBook] = useState(null);
    const [paymentLineData,setPaymentLineData] = useState([]);
    useEffect(()=>{
        setCurrentBook(props.book);
        accountingService.getPaymentsByPOID(props.po).then(result=>{
            setPaymentLineData(result.data);
        })
    },[])
    const params = useParams();

    const handleToAddInvoice = () => {
        history.push({
            pathname: PaymentAddPage,
            state: {"bookId": params.bookId},
        });
    };

    return(
        <div>
            <Grid container fullwidth>
                <Grid item xs={12} style={{textAlign:"right", margin:"20px 20px 20px 0"}}>
                    {/* {accountingService.checkPermission("add_payment",currentBook)&&<Button variant="contained" color="primary" size="large" onClick={handleToAddInvoice}>
                        <Typography variant="body2">
                            {t('paymentPage.addPayment')}
                        </Typography>
                    </Button>} */}
                </Grid>
           
                <Grid item xs={12}>
                { paymentLineData && paymentLineData.length>0 ?  (
                    <Grid key={paymentLineData.id} >
                        <TableContainer component={Paper} className={classes.orgListItemDetailPanel}>
                            <Table aria-label="simple table"  >
                                <TableHead>
                                    <TableRow className={classes.thTitle}>
                                        <TableCell className={ classes.thBorder}></TableCell>
                                        <TableCell className={ classes.thBorder}>{t('poFormPage.line')}</TableCell>
                                        <TableCell className={ classes.thBorder}>{t('paymentPage.user')}</TableCell>
                                        <TableCell className={ classes.thBorder}>{t('paymentPage.paymentActions')}</TableCell>
                                        <TableCell className={ classes.thBorder}>{t('paymentPage.paymentAmount')}</TableCell>
                                        <TableCell className={ classes.thBorder}>{t('paymentPage.invoiceNumber')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {paymentLineData.map((temp, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thBorder} component="th" scope="row">
                                            {temp.date}
                                        </TableCell>
                                        <TableCell className={classes.thBorder}>{temp.lines.map(item => item.lineNumber).join(',')}</TableCell>
                                        <TableCell className={classes.thBorder}>{temp.createBy}</TableCell>
                                        <TableCell className={classes.thBorder}>{temp.paymentAction==="deposit"?t('paymentPage.deposit'):t('paymentPage.batch')}</TableCell>
                                        <TableCell className={classes.thBorder}>{temp.paymentAmount}</TableCell>
                                        <TableCell className={classes.thBorder}>{temp.invoiceNumber}</TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    ) 
                    :   (
                        <>
                          <Grid item xs={12} className={classes.issuePoGrid}>
                            <img src={noImage} className={classes.issuePoImage}></img>
                          </Grid>
                          <Grid item xs={12} className={classes.issuePoGrid}>
                            {t('paymentPage.noPaymentLine')}
                          </Grid>
                        </>
                        )
                }
                </Grid>
            </Grid>
        </div>
    )
}
