import React ,{ useContext, useState, useEffect } from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { useParams,useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import paymentService from '../../services/paymentService';
import organizationService from '../../services/organizationService';

const useStyles = makeStyles(theme=> ({
    orgBox: {
        width:"100%",
        maxWidth:"1200px",
        height:'400px',
        justifyContent:'center',
        alignContent:'center',
        display:'flex',
        flexWrap:'wrap'
    },
}))

export default function PayFail(props){
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const params = useParams();
    const history = useHistory();

    useEffect(()=>{
        console.log(history.location);
        const params = new URLSearchParams(history.location.search);
        const orderId = params.get('orderId');
        const isChange = params.get('isChange');
        // console.log(orderId);
        // let status = {
        //     "paymentStatus": "failed",
        //     "isChange": isChange==="true"
        // }

        // if(orderId){
        //     organizationService.updateOrgOrderStatus(orderId,status).then(result=>{
        //         if(result.status===200){
        //             let timer = setTimeout(()=>{
        //                 history.push("/management/services/undefined/organizations");
        //             },3000);
        //         }
        //     })
        // }
        let timer = setTimeout(()=>{
            history.push("/management/services/undefined/organizations");
        },3000);
    },[])

    return(
        <div className={classes.orgBox}>{t('newOrg.failPageTips')}</div>
    )
}
