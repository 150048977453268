const lightTheme = {
    headerBackground: '#127bb0',
    background: '#ffffff',
    text: '#000000',
    backgrounds: [
        '#ffffff',
        '#127bb0',
        '#000000',
        '#ffffff',
        '#FFBA08',
        "#fafbfc",
        "#FFFFFF",
        "#FFFFFF",
        "#eaf6ff",
        "#e6e6e6",
        "rgb(243 246 249)",
        "#FFFFFF",
        "rgb(243 246 249)",
        "#FFFFFF",
    ],
    theme2backgrounds:[
        "rgb(243 246 249)",
        "#fff",
        "RGB(215,220,225)",
        "RGB(242,245,248)",
        "rgba(255, 255, 255, 0.2)"
    ],
    colors: {
        '0': '#000000',
        '1': '#000000',
        '2': '#ffffff',
        '3': '#6e6e6e',
        '4': '#a6a6a6',
        '5': '#7f7f7f',
        '6': '#dbdbdb',
    },
    border: {
        '1': 'solid 1px #ffffff',
        '2': 'solid 1px #dbdbdb',
        '3': 'solid 1px #dbdbdb',
    },
}
export default lightTheme;
