import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Paper, Typography, Button, IconButton, Box, Collapse, Tooltip, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LoadingButton from "../../components/LoadingButton";

import moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import accountingService from "../../services/accountingService";
import noImage from '../../assets/img/invoice&payment.svg';
import { useTranslation } from 'react-i18next';
import PublicIcon from '@material-ui/icons/Public';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import downloadFileUtils from "../../utils/downloadFile";
import BaoguanDialog from "./baoguanDialog";

const useStyles = makeStyles(theme => ({
  invoiceLinePanel: {
    marginBottom: "13px",
    borderBottom: "none ",
  },
  thTitle: {
    letterSpacing: "0.05px",
    color: "#5d5d5d",
  },
  thBorder: {
    borderBottom: "none ",
    fontSize: "14px",
    padding: "6px",
  },
  issuePoGrid: {
    width: '100%',
    textAlign: 'center',
  },
  issuePoImage: {
    maxWidth: '150px'
  },
  dashedLine: {
    margin: "23px 0 10px 0",
    borderTop: "1px solid #dbdbdb",
    width: "100%",
  },
  itemTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginBottom: "20px"
  },
  itemContent: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
  },
  selectField: {
    padding: "11px 14px",
  },
}))

export default function ShippingLine(props) {
  const shippingAddPage = "/PO Transactions/Shipping/add";

  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const history = useHistory();

  const params = useParams();

  const [currentBook, setCurrentBook] = useState(null);
  const [baoguanOpen, setBaoguanOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [bookData, setBookData] = useState([]);
  const [currentDeclareInfo, setCurrentDeclareInfo] = useState(null);

  useEffect(()=> {
    accountingService.viewBook(props.book.bookId).then(result => {
      setBookData(result.data);
      console.log("book data", result.data);
    })
  }, [])

  const [poData, setPoData] = useState([]);
  useEffect(()=> {
    accountingService.getPOByBookId(props.book.bookId).then(result => {
      setPoData(result.data[0]);
      console.log("po data", result.data[0]);
    })
  }, [])

  const [shippingLineData, setShippingLineData] = useState([]);
  useEffect(() => {
    setCurrentBook(props.book);
    if(props.po){
      refreshShippingLines();
    }
  }, [])

  const refreshShippingLines = () =>{
    accountingService.getShippingByPOId(props.po).then(result => {
      setShippingLineData(result.data);
    })
  }

  const titleCase = (str) => {
    let newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
    if(newStr==='Shipped'){
      newStr = t('shippingPage.shipped');
    }
    if(newStr==='Received'){
      newStr = t('shippingPage.received');
    }
    return newStr;
  }

  const downloadAttachment = (attachments) => {

    accountingService.downloadAttachment(attachments[0].fileId).then((result) => {

      let blob = new Blob([result.data], );
      downloadFileUtils.downLoadFile(blob, attachments[0].fileName);

    });

  }

  const Row = (props) => {
    const { row } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow className={classes.thBorder}>
          <TableCell component="th" scope="row" className={classes.thBorder}
            style={{ color: "rgb(18, 123, 176)" }}>{row.wayBill}</TableCell>
          <TableCell className={classes.thBorder}>{row.totalQty}</TableCell>
          <TableCell className={classes.thBorder}>{titleCase(row.status)}</TableCell>
          <TableCell className={classes.thBorder}>{row.shippingFrom}</TableCell>
          <TableCell className={classes.thBorder}>{row.shippingTo}</TableCell>
          <TableCell className={classes.thBorder}>{moment(row.shippingDate).format("YYYY/MM/DD")}</TableCell>

          {
            row.shippingTrade && row.shippingTrade.length > 0 && <TableCell className={classes.thBorder}>{row.shippingTrade[0].exportBill}</TableCell>
          }
          {
            (!row.shippingTrade || row.shippingTrade.length <= 0) && <TableCell className={classes.thBorder}>&nbsp;</TableCell>
          }
          {
            row.shippingTrade && row.shippingTrade.length > 0 && <TableCell className={classes.thBorder}>{row.shippingTrade[0].declarationBill}</TableCell>
          }
          {
            (!row.shippingTrade || row.shippingTrade.length <= 0) && <TableCell className={classes.thBorder}>&nbsp;</TableCell>
          }
          {
            row.shippingTrade && row.shippingTrade.length > 0 && <TableCell className={classes.thBorder}>{row.shippingTrade[0].declarationDate?moment(row.shippingTrade[0].declarationDate).format("YYYY/MM/DD"):''}</TableCell>
          }
          {
            (!row.shippingTrade || row.shippingTrade.length <= 0) && <TableCell className={classes.thBorder}>&nbsp;</TableCell>
          }

          <TableCell className={classes.thBorder}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          {/*</TableCell>*/}
          {/*<TableCell className={classes.thBorder}>*/}
          {accountingService.checkPermission("add_shipping",currentBook)&&<IconButton variant="contained" color="primary" size="large" onClick={e=>baoguanHandler(row)}>
                <Tooltip title={t('shippingPage.waimaoxinxi')}>
                  <PublicIcon style={{color:'green'}}/>
                </Tooltip>
            </IconButton>}
            {accountingService.checkPermission("receive_shipping",currentBook)&&row.status==='shipped'&&<Button variant="contained" color="primary" size="large" onClick={e=>handleToGetShipping(row)}>
                <Typography variant="body2">
                    {t('shippingPage.getShipping')}
                </Typography>
            </Button>}

            {
              row.attachments && row.attachments[0].fileName ?
                <Tooltip title={t('common.downloadAttachment') + ': ' + row.attachments[0].fileName}>
                  <IconButton aria-label="download attachment" size="small" onClick={() => downloadAttachment(row.attachments)}>
                    <CloudDownloadIcon style={{color: 'green'}}/>
                  </IconButton>
                </Tooltip>
                :
                <Tooltip title={t('common.noAttach')}>
                    <span>
                      <IconButton disabled>
                          <CloudDownloadIcon/>
                      </IconButton>
                    </span>
                </Tooltip>
            }

          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.thBorder}>{t('poFormPage.line')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.item')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.rev')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.product')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.QTY')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.unit')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.shippingLines.map((lineRow) => (
                      <TableRow key={lineRow.invoiceLineId}>
                        <TableCell className={classes.thBorder} component="th" scope="row">
                          {lineRow.lineNumber}
                        </TableCell>
                        <TableCell className={classes.thBorder}>{lineRow.itemNumber}</TableCell>
                        <TableCell className={classes.thBorder}>{lineRow.itemRev}</TableCell>
                        <TableCell className={classes.thBorder}>{lineRow.productName}</TableCell>
                        <TableCell className={classes.thBorder}>{lineRow.qty}</TableCell>
                        <TableCell className={classes.thBorder}>
                          {lineRow.unit}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handleToAddShipping = () => {
    history.push({
      pathname: shippingAddPage,
      state: { "bookId": params.bookId },
    });
  };

  const handleToGetShipping = (rowData) =>{
    accountingService.shippingReceived(rowData.shippingId).then(result=>{
      if(result.status===200){
        refreshShippingLines();
      }
    })
  }

  const baoguanHandler = (row)=>{
    setCurrentRow(row);
    if (row && row.shippingTrade && row.shippingTrade.length > 0) {
      let tradeInfo = row.shippingTrade[0];
      setCurrentDeclareInfo({
        exportBill: tradeInfo.exportBill,
        declarationBill: tradeInfo.declarationBill,
        declarationDate: tradeInfo.declarationDate?moment(tradeInfo.declarationDate).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD')
      })
    } else {
      setCurrentDeclareInfo(null);
    }
    setBaoguanOpen(true);
  }

  const onDeclareSuccess = () => {
    refreshShippingLines();
    setBaoguanOpen(false);
  }

  Row.propTypes = {
    row: PropTypes.shape({
      invoiceNumber: PropTypes.string.isRequired,
      paymentTerm: PropTypes.string.isRequired,
      invoiceTotalAmount: PropTypes.string.isRequired,
      invoiceDate: PropTypes.string.isRequired,
      lines: PropTypes.arrayOf(
        PropTypes.shape({
          lineNumber: PropTypes.string.isRequired,
          itemNumber: PropTypes.string.isRequired,
          productName: PropTypes.string.isRequired,
          qty: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          lineTotal: PropTypes.string.isRequired,
          lineDate: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  };

  return (
    <div>
      <Grid container fullWidth>
        <Grid item xs={12} style={{textAlign:"right", margin:"20px 20px 20px 0"}}>
            {accountingService.checkPermission("add_shipping",currentBook)&&<Button variant="contained" color="primary" size="large" onClick={handleToAddShipping}>
                <Typography variant="body2">
                    {t('shippingPage.addShipping')}
                </Typography>
            </Button>}
        </Grid>

        <Grid container className={classes.dashedLine}></Grid>

        <Grid item xs={12}>
          {shippingLineData && shippingLineData.length > 0 ? (
            <Grid key={shippingLineData.invoiceId} >
              <TableContainer component={Paper} className={classes.invoiceLinePanel}>
                <Table aria-label="simple table"  >
                  <TableHead>
                    <TableRow className={classes.thTitle}>
                      <TableCell className={classes.thBorder}>{t('shippingPage.shippingNumber')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('shippingPage.shippingQTY')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('shippingPage.status')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('shippingPage.shippingAddressFrom')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('shippingPage.shippingAddressTo')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('shippingPage.shippingDate')} </TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('shippingPage.exportContractNo')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('shippingPage.baoguanNo')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('shippingPage.baoguanDate')} </TableCell>
                      <TableCell className={classes.thBorder} align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shippingLineData.map((item) => (
                      <Row key={item.invoiceId} row={item} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )
            :
            (
              <>
                <Grid item xs={12} className={classes.issuePoGrid}>
                  <img src={noImage} className={classes.issuePoImage}></img>
                </Grid>
                <Grid item xs={12} className={classes.issuePoGrid}>
                  {t('shippingPage.noShippingLine')}
                </Grid>
              </>
            )
          }
        </Grid>

        {baoguanOpen && <BaoguanDialog shippingIds={[currentRow.shippingId]} declarationInfo={currentDeclareInfo}
                                       onSuccess={onDeclareSuccess}
                                       onCancel={e=>setBaoguanOpen(false)}></BaoguanDialog>}

      </Grid>
    </div>
  )
}
