import CommonLayout from "../../components/CommonLayout";
import React,{useContext, useState,useEffect}  from 'react';
import {
    Link, useParams, useHistory
} from "react-router-dom";
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { Grid,Button } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useToken from '../../hooks/useToken';
import Tooltip from '@material-ui/core/Tooltip';
import POLine from './po-line';
import InvoiceLine from './invoice-line';
import PaymentLine from './payment-line';
import ShippingLine from './shipping-line';

import accountingService from "../../services/accountingService";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import issuePoImage from '../../assets/img/issue-po.png'
import { classExpression } from "@babel/types";
import LoadingButton from "../../components/LoadingButton";
import UndoIcon from '@material-ui/icons/Undo';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '30px'
    },
    infoDiv: {
        border: '1px solid #cdcdcd',
        borderRadius: '5px',
        padding: theme.spacing(1),
    },
    editTitle:{
        marginBottom:'4px',
        marginTop:'10px',
        fontWeight:'600',
        fontSize:'16px'
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    dialogHr: {
        border: '1px',
        borderStyle: 'dashed none none none',
        borderColor: theme.currentTheme.colors[6],
        marginTop: '10px',
        marginBottom: '10px',
        width:'100%',
    },
    issuePoImage:{
        maxWidth:'300px'
    },
    issuePoGrid:{
        width:'100%',
        textAlign:'center',
        paddingTop:'40px'
    },
    dialogRoot:{
        marginBottom:'20px',
        color:theme.currentTheme.text,
        fontSize:'14px'
    },
    balanceRow:{
        lineHeight:'30px',
        '& td':{
            paddingRight:'16px'
        }
    },
    balanceTable:{
        tableLayout:'fixed',
        "& th" : {
            backgroundColor: theme.currentTheme.theme2backgrounds[1],
            "& p":{fontFamily:'IBMPlexSans-Bold'},
            "& a":{color:theme.currentTheme.text}
        },
        "& tr:nth-child(even)" : {
            backgroundColor: theme.currentTheme.theme2backgrounds[1]
        },
        "& tr:nth-child(odd)" : {
            backgroundColor: theme.currentTheme.theme2backgrounds[2]
        },
        "& p":{
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
        }
    },
    tabsRoot: {
        textTransform: "none",
    },
    tabsLabel: {
        fontFamily: "IBMPlexSans-Regular",
        fontSize:"16px"
    },
    undoIcon: {
        marginRight: "23px",
        color:"#3f51b5",
        cursor:'pointer'
    },

    noBox: {
        boxShadow: "none",
    },
    toolTipDiv:{
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        widt:'100%'
    }
}))

export default function Detail() {
    const issuePOformPage = '/PO Transactions/PoForm/issue';
    const classes = useStyles();
    const history = useHistory();
    const [currentBook, setCurrentBook]=useState(null);
    const [showBalance, setShowBalance]=useState(false);
    const [value, setValue] = React.useState(5);
    const { token } = useToken();
    const {t, i18n} = useTranslation();

    const [currentPO, setCurrentPO] = useState(null);
    const [openDialog, setOpenDialog]=useState(false);
    const [bookBlances, setBookBlances]=useState([]);

    const [lineAllUsed, setLineAllUsed] = useState(false);
    useEffect(()=> {
        if(currentPO && currentPO.length>0){
            accountingService.getPOLineByPOId(currentPO).then(result=>{
                if(result.data&&result.data.length>0){
                    let lineNotUsed = 0;
                    result.data.map(item=>{
                        if(item.status==='normal'){
                            lineNotUsed = lineNotUsed + 1;
                        }
                    })
                    if(lineNotUsed>0){
                        setLineAllUsed(false);
                    }
                    else{
                        setLineAllUsed(true);
                    }
                }
                else{
                    setLineAllUsed(true);
                }
            })
        }
    }, [bookBlances])
    const [closeBookConfirmWindow,setCloseBookConfirmWindow] = useState(false);
    const [deleteBookConfirmWindow,setDeleteBookConfirmWindow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentSort, setCurrentSort] =useState("");
    const theme = useTheme();

    const toggleBalanceShowHandler = ()=>{
        if(showBalance){
            setShowBalance(false);
        }
        else{
            setShowBalance(true);
        }
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }} style={{padding: "0px"}}>
                {children}
              </Box>
            )}
          </div>
        );
      }

      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const params = useParams();
    useEffect(()=>{
        if(params.bookId){
            accountingService.viewBook(params.bookId).then(result=>{
                if(result.status===200){
                    setCurrentBook(result.data);
                    if(result.data.poDatas.length>0&&result.data.poDatas[0].poNumber){
                        setCurrentPO(result.data.poDatas[0].poId);
                        accountingService.getBalanceByPOID(result.data.poDatas[0].poId).then(result=>{
                            if(result.status===200){
                                setBookBlances(result.data);
                            }
                        })
                        // accountingService.getPOLineByPOId(result.data.poDatas[0].poId).then(result=>{
                        //     if(result.data&&result.data.length>0){
                        //         let lineNotUsed = 0;
                        //         result.data.map(item=>{
                        //             if(item.status==='normal'){
                        //                 lineNotUsed = lineNotUsed + 1;
                        //             }
                        //         })
                        //         if(lineNotUsed>0){
                        //             setLineAllUsed(false);
                        //         }
                        //         else{
                        //             setLineAllUsed(true);
                        //         }
                        //     }
                        //     else{
                        //         setLineAllUsed(true);
                        //     }
                        // })
                    }
                }
                setValue(0);
            })
        }
    },[params.bookId])


    useEffect(()=>{
        let tempArr = bookBlances;
        console.log(currentSort);
        if(currentSort==="up")
        {
            tempArr.sort(compare);
        }
        if(currentSort==="down")
        {
            tempArr.sort(compareRevise);
        }
        setBookBlances(tempArr);

    },[currentSort])

    const handleClose =()=>{
        setOpenDialog(false);
    }

    const editBookHandler =() =>{
        history.push({
            pathname: "/PO Transactions/newbook/",
            state: {orgId:currentBook.orgId,bookId:params.bookId},
        });
    }

    const openDialogHandler =()=>{
        setOpenDialog(true);
    }
    const issuePoHandler=()=>{
        console.log(history.location.state);
        history.push({
            pathname: issuePOformPage,
            state: {bookId: currentBook.bookId},
        });
    }
    const closeBook=()=>{
        setCloseBookConfirmWindow(true);
    }

    const deleteBook=()=>{
        setDeleteBookConfirmWindow(true);
    }


    const handleBookClose =()=>{
        setIsLoading(false);
        accountingService.closeBook(params.bookId).then(result=>{
            if(result.status===200){
                setIsLoading(false);
                setCloseBookConfirmWindow(false);
                history.go(0);
            }
        })
    }

    const handleCloseBookWindowClose =()=>{
        setCloseBookConfirmWindow(false);
    }

    const handleDeleteBookWindowClose =()=>{
        setDeleteBookConfirmWindow(false);
    }

    const refreshBalance = ()=> {
        if(params.bookId){
            accountingService.viewBook(params.bookId).then(result=>{
                if(result.status===200){
                    if(result.data.poDatas.length>0&&result.data.poDatas[0].poNumber){
                        accountingService.getBalanceByPOID(result.data.poDatas[0].poId).then(result=>{
                            if(result.status===200){
                                setBookBlances(result.data);
                            }
                        })
                    }
                }
            })
        }
    }

    const goBackHandler = ()=>{
        history.push("/PO Transactions")
    }


    const tableIcons = {
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    };
    const handleLineSort =()=>{
        if(currentSort===""){
            setCurrentSort("up");
        }
        if(currentSort==="up"){
            setCurrentSort("down");
        }
        if(currentSort==="down"){
            setCurrentSort("up");
        }
    }

    const compare = (a, b) => {
        // 使用 toUpperCase() 忽略字符大小写
        const lineA = a.lineNumber.toUpperCase();
        const lineB = b.lineNumber.toUpperCase();

        let comparison = 0;
        if (lineA > lineB) {
          comparison = 1;
        } else if (lineA < lineB) {
          comparison = -1;
        }
        return comparison;
    }

    const compareRevise = (a, b) => {
        // 使用 toUpperCase() 忽略字符大小写
        const lineA = a.lineNumber.toUpperCase();
        const lineB = b.lineNumber.toUpperCase();

        let comparison = 0;
        if (lineA > lineB) {
          comparison = -1;
        } else if (lineA < lineB) {
          comparison = 1;
        }
        return comparison;
    }

    const handleBookDelete=()=>{
        accountingService.deleteBook(params.bookId).then(result=>{
            if(result.status===200){
                history.push("/PO Transactions");
            }
        })
    }

    const formatDate=(value)=> {
        return moment(value).format("YYYY/MM/DD HH:MM:SS");
    }

    const addToolTip=(value)=> {
        let temp = actionsTranslate(value);
        return (<div title={temp} className={classes.toolTipDiv}>{temp}</div>);
    }

    const actionsTranslate = (value)=>{
        if(value==='New PO Line'){
            return t('outstandingBalancePage.action1');
        }
        if(value==='Cancel PO Line'){
            return t('outstandingBalancePage.action2');
        }
        if(value==='Revise PO Line Qty'){
            return t('outstandingBalancePage.action3');
        }
        if(value==='Revise PO Line Date'){
            return t('outstandingBalancePage.action4');
        }
        if(value==='Close PO Line'){
            return t('outstandingBalancePage.action5');
        }
        if(value==='New Invoice Line'){
            return t('outstandingBalancePage.action6');
        }
        if(value==='Invoice Payment'){
            return t('outstandingBalancePage.action7');
        }
        if(value==='Deposit Payment'){
            return t('outstandingBalancePage.action8');
        }
        if(value==='close PO line by system'){
            return t('outstandingBalancePage.ClosePOLineBySystem');
        }
        return value;
    }

    return (
        <CommonLayout>
            {currentBook&&<div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <UndoIcon color="primary" className={classes.undoIcon} onClick={goBackHandler}></UndoIcon>
                </Grid>

                <Grid container className={classes.infoDiv}>
                    <Grid item xs={12} >
                        <Grid container justifyContent="flex-end">
                            <Tooltip title={t('viewBookPage.viewBook')}>
                                <span>
                                    <IconButton aria-label="view book" onClick={openDialogHandler}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {accountingService.checkPermission("update_book", currentBook) &&
                              <Tooltip title={t('newBookPage.editBook')}>
                                  <span>
                                      <IconButton onClick={editBookHandler} aria-label="close">
                                        <EditIcon />
                                      </IconButton>
                                  </span>
                              </Tooltip>
                            }
                            {accountingService.checkPermission("close_book", currentBook) && (currentBook && currentBook.poDatas.length > 0) &&
                              <Tooltip title={t('poManagementHomepage.closeBook')}>
                                  <span>
                                      <IconButton disabled={currentBook && ((currentBook.poDatas.length > 0 && Math.abs(parseFloat(currentBook.poDatas[0].outstandingBalance)) > parseFloat("0.1000")) || (currentBook.poDatas.length > 0 && !lineAllUsed))} aria-label="close" onClick={closeBook}>
                                            <MenuBookIcon />
                                      </IconButton>
                                  </span>
                              </Tooltip>
                            }
                            {accountingService.checkPermission("close_book", currentBook) && (currentBook && currentBook.poDatas.length === 0) &&
                              <Tooltip title={t('poManagementHomepage.deleteBook')}>
                                  <span>
                                      <IconButton disabled={currentBook && currentBook.poDatas.length > 0} aria-label="delete" onClick={deleteBook}>
                                          <DeleteIcon />
                                      </IconButton>
                                  </span>
                              </Tooltip>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{t('poFormPage.poNumber')} : {currentBook.poDatas&&currentBook.poDatas.length>0?currentBook.poDatas[0].poNumber:""}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1">{t('poFormPage.purchaserName')} : {currentBook.purchaserName}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1">{t('poFormPage.supplierName')} : {currentBook.supplierName}</Typography>
                    </Grid>
                </Grid>

                {currentBook.poDatas&&currentBook.poDatas.length>0&&currentBook.poDatas[0].poNumber&&<div style={{width:'100%'}}>
                    <Grid item xs={12}>
                        <div onClick={toggleBalanceShowHandler} style={{margin:'20px 0px'}}>
                            <Typography variant="h3" style={{color:'#127bb0',verticalAlign:'middle'}}>{t('outstandingBalancePage.outstandingBalance')}：{showBalance?<ExpandLessIcon style={{verticalAlign:'middle'}}/>:<ExpandMoreIcon style={{verticalAlign:'middle'}}/>}</Typography>
                            <Typography variant="h3" style={{color:'#127bb0',verticalAlign:'middle'}}>{currentBook.poDatas[0].currency} {accountingService.getCurrencyLabel(currentBook.poDatas[0].currency)} {currentBook.poDatas[0].outstandingBalance}</Typography>
                        </div>
                        <Collapse in={showBalance}>
                            <Typography variant="h4" style={{margin:'20px 0px'}}>{t('outstandingBalancePage.outstandingLineBalance')} = （Σ{t('outstandingBalancePage.receivable')}-Σ{t('outstandingBalancePage.payable')}）- （Σ{t('outstandingBalancePage.received')} - Σ{t('outstandingBalancePage.paid')}）</Typography>

                            <MaterialTable
                                // classes={{MuiPaper:classes.noBox}}
                                title=""
                                columns={[
                                    { field: 'actions',
                                        title: t('outstandingBalancePage.actions'),
                                        sorting: false,
                                        //   width: '5%',
                                        render: rowData => addToolTip(rowData.actions),
                                        cellStyle:{
                                            maxWidth:'170px',
                                            padding:'4px',
                                            fontSize:'14px',
                                            overflow:'hidden',
                                            textOverflow:'ellipsis',
                                            whiteSpace:'nowrap',
                                        },
                                    },
                                    {
                                        field: 'lineNumber',
                                        title: t('outstandingBalancePage.line'),
                                        //   width: '10%',
                                        sorting: true,
                                    },
                                    {
                                        field: 'receivable',
                                        title: t('outstandingBalancePage.receivable'),
                                        //   width: '8%',
                                        sorting: false,
                                    },
                                    {
                                        field: 'payable',
                                        title: t('outstandingBalancePage.payable'),
                                        //   width: '8%',
                                        sorting: false,
                                    },
                                    {
                                        field: 'received',
                                        title: t('outstandingBalancePage.received'),
                                        //   width: '8%',
                                        sorting: false,
                                    },
                                    {
                                        field: 'paid',
                                        title: t('outstandingBalancePage.paid'),
                                        //   width: '8%',
                                        sorting: false,
                                    },
                                    {
                                        field: 'outstandingBalance',
                                        title: t('outstandingBalancePage.balance'),
                                        // width: '8%',
                                        sorting: false,
                                    },
                                    {
                                        field: 'timestamp',
                                        title: t('outstandingBalancePage.timestamp'),
                                        //   width: '14%',
                                        sorting: false,
                                        render: rowData => formatDate(rowData.timestamp),
                                    }
                                ]}
                                icons={tableIcons}
                                data={bookBlances}
                                style={{width:'100%'}}
                                options={{
                                    actionsColumnIndex: -1,
                                    search: false,
                                    toolbar:false,
                                    sorting: true,
                                    paging:false,
                                    // tableLayout:"fixed",
                                    headerStyle: {
                                        backgroundColor:"transparent",
                                        fontFamily:'IBMPlexSans-Bold',
                                        padding:'4px'
                                    },
                                    cellStyle:{
                                        padding:'4px',
                                        fontSize:'14px',
                                        overflow:'hidden',
                                        textOverflow:'ellipsis',
                                        whiteSpace:'nowrap',
                                    },
                                    rowStyle: (rowData, index ) => ({
                                        backgroundColor:
                                            index % 2 === 0 ?theme.currentTheme.theme2backgrounds[1]:theme.currentTheme.theme2backgrounds[2]                                                ,
                                    }),
                                }}
                            />
                        </Collapse>
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" style={{marginTop:'20px'}} TabIndicatorProps={{ style: { background: "#FF5722" } }}>
                            <Tab label={t('poManagementHomepage.outstandingPO')} classes={{root: classes.tabsRoot, wrapper: classes.tabsLabel}} {...a11yProps(0)} />
                            <Tab label={t('poManagementHomepage.delivery')} classes={{root: classes.tabsRoot, wrapper: classes.tabsLabel}} {...a11yProps(1)} />
                            <Tab label={t('poManagementHomepage.invoice')} classes={{root: classes.tabsRoot, wrapper: classes.tabsLabel}} {...a11yProps(2)} />
                            <Tab label={t('poManagementHomepage.payment')} classes={{root: classes.tabsRoot, wrapper: classes.tabsLabel}} {...a11yProps(3)} />
                        </Tabs>
                        {value===0&&<POLine po={currentPO} refresh={refreshBalance} book={currentBook}></POLine>}
                        {value===1&&<ShippingLine po={currentPO} book={currentBook}></ShippingLine>}
                        {value===2&&<InvoiceLine po={currentPO} book={currentBook}></InvoiceLine>}
                        {value===3&&<PaymentLine po={currentPO} book={currentBook}></PaymentLine>}
                    </Grid>
                </div>}
                {currentBook.poDatas&&currentBook.poDatas.length===0&&<Grid item xs={12}>
                    <Grid item xs={12}>
                        {accountingService.checkPermission("issue_po",currentBook)&&<Button onClick={issuePoHandler} variant="contained" color="primary" size="large" style={{marginTop:'20px'}}>
                            <Typography variant="body2" style={{paddingLeft:'6px'}}>{t('poManagementHomepage.issuePO')}</Typography>
                        </Button>}
                    </Grid>
                    <Grid item xs={12} className={classes.issuePoGrid}>
                        <img src={issuePoImage} className={classes.issuePoImage}></img>
                    </Grid>
                </Grid> }
            </Grid>

            <Dialog open={openDialog} scroll="body" maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><Typography variant="body1">{t('viewBookPage.viewBook')}</Typography></DialogTitle>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Divider variant="middle"></Divider>
                <DialogContent classes={{ root: classes.dialogRoot, }}>
                    <Grid container spacing={0} >
                        <Grid item xs={5}>
                            <Grid className={classes.editTitle}>
                            {t('poManagementHomepage.bookTitle')}
                            </Grid>
                            <Grid item>
                                {currentBook.bookTitle}
                            </Grid>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid className={classes.editTitle}>
                                {t('viewBookPage.status')}
                            </Grid>
                            <Grid item>
                                {currentBook.status}
                            </Grid>
                        </Grid>
                        <hr className={classes.dialogHr}></hr>
                        <Grid item xs={12}>
                            <Grid className={classes.editTitle}>
                            {t('newBookPage.purchaserName')}
                            </Grid>
                            <Grid item>
                                {currentBook.purchaserName}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row">
                                    <Grid item xs={5} className={classes.editTitle}>{t('newBookPage.purchaserMember')}</Grid>
                                    <Grid item xs={7} className={classes.editTitle}>{t('newBookPage.title')}</Grid>
                            </Grid>
                            {currentBook.purchaserMembers&&currentBook.purchaserMembers.length>0&&currentBook.purchaserMembers.map((item,index)=>
                                <Grid container direction="row" style={{marginBottom:'5px'}} key={index}>
                                    <Grid item xs={5}>{item.memberName}</Grid>
                                    <Grid item xs={7}>{item.memberTitle}</Grid>
                                </Grid>
                            )}
                        </Grid>
                        <hr className={classes.dialogHr}></hr>

                        <Grid item xs={12}>
                            <Grid className={classes.editTitle}>
                            {t('newBookPage.supplierName')}
                            </Grid>
                            <Grid item>
                                {currentBook.supplierName}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row">
                                    <Grid item xs={5} className={classes.editTitle}>{t('newBookPage.supplierMember')}</Grid>
                                    <Grid item xs={7} className={classes.editTitle}>{t('newBookPage.title')}</Grid>
                            </Grid>
                            {currentBook.supplierMembers&&currentBook.supplierMembers.length>0&&currentBook.supplierMembers.map((item,index)=>
                                <Grid container direction="row"style={{marginBottom:'5px'}} key={index}>
                                    <Grid item xs={5}>{item.memberName}</Grid>
                                    <Grid item xs={7}>{item.memberTitle}</Grid>
                                </Grid>
                            )}
                        </Grid>
                        <hr className={classes.dialogHr}></hr>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={5} className={classes.editTitle}>{t('poManagementHomepage.bookOwner')}</Grid>
                            <Grid item xs={7} className={classes.editTitle}>{t('viewBookPage.creator')}</Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={5}>{currentBook.owner}</Grid>
                            <Grid item xs={7}>{currentBook.createBy}</Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={5} className={classes.editTitle}>{t('viewBookPage.createTime')}</Grid>
                            <Grid item xs={7} className={classes.editTitle}>{t('viewBookPage.lastUpdate')}</Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={5}>{new Date(currentBook.createTime).toLocaleString()}</Grid>
                            <Grid item xs={7}>{currentBook.updateTime?new Date(currentBook.createTime).toLocaleString():new Date(currentBook.updateTime).toLocaleString()}</Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog open={closeBookConfirmWindow} onClose={handleCloseBookWindowClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="body1">
                    {t('closeBookPage.closeBook')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                                {t('closeBookPage.closeBookContent')}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton isLoading={isLoading} variant="contained" color="primary"  onClick={handleBookClose}>
                        <Typography variant="body2">
                        {t('common.confirm')}
                        </Typography>
                    </LoadingButton>
                    <Button variant="contained"  onClick={handleCloseBookWindowClose}>
                        <Typography variant="body2">
                        {t('common.cancel')}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteBookConfirmWindow} onClose={handleDeleteBookWindowClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="body1">
                    {t('deleteBookPage.deleteBook')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                            {t('deleteBookPage.deleteBookContent')}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton isLoading={isLoading} variant="contained" color="primary"  onClick={handleBookDelete}>
                        <Typography variant="body2">
                        {t('common.confirm')}
                        </Typography>
                    </LoadingButton>
                    <Button variant="contained"  onClick={handleDeleteBookWindowClose}>
                        <Typography variant="body2">
                        {t('common.cancel')}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            </div>}
        </CommonLayout>
    )
}
