import Header from "./Header";
import Footer from "./Footer";
import Navigator from "./Navigator";
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
const useStyles = makeStyles({
    container1: {
        flex:1,
        display: 'flex',
        flexDirection: "row",
        maxWidth: '1200px',
        width: '100%',
        // justifyContent:'center',
    },
    container2: {
        flex:1,
        display: 'flex',
        flexDirection: "row",
        maxWidth: '1200px',
        width: '100%',
        '@media (max-width:960px)':  {
            flexDirection: "column",
        },
        // justifyContent:'center',
    }
});
export default function CommonLayout(props){
    const classes = useStyles();
    const location = useLocation();
    const [showNav, setShowNav] = useState(true);
    useEffect(()=>{
        console.log('location',location);
        if(location.pathname === '/Inventory'){
            setShowNav(false);
        }
    },[location.pathname])
    return (
        <div id="container" className={classes.container1}>
            <div className="hbox" className={classes.container2}>
                {showNav && <Navigator></Navigator> }
                {props.children}
            </div>
        </div>
    )
}
