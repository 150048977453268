import {Button, TextField, Typography} from '@material-ui/core'
import React, { useState, useEffect } from "react";
import {useHistory, useLocation} from "react-router-dom";
import iamService from '../../services/iamService'
import inviteService from '../../services/inviteService'
import countriesMock from '../../mocks/simple_countries.json'
import Autocomplete from '@material-ui/lab/Autocomplete';
import PasswordWithHint from "../../components/PasswordWithHint";
import PasswordInput from "../../components/PasswordInput";
import FormFunction from './FormFunction.js';
import useStyle from './RegisterPage.style';
import urlReader from "../../utils/urlReader";
import { useTranslation } from 'react-i18next';

export default function RegisterForm(props){
    const classes = useStyle();
    const {t, i18n} = useTranslation();

    const history = useHistory();
    const location = useLocation();
    const [urlParams, setUrlParams] = useState(null);
    const [fromSignUp, setFromSignUp] = useState(false);

    const [emailInfo, setEmailInfo] = useState(null);
    const [invitation, setInvitation] = useState(null);
    const [showPassword, setShowPassword] = useState(true);
    const [countries, setCountries] = useState([]);
    const [formInfo, setFormInfo] = useState({
        valid: null,
        msg: null
    })
    const [busy, setBusy] = useState(false)
    const {fields, setFields, isFormValid, handleChange} = FormFunction();

    useEffect( ()=> {
        initCountries();

        if(location && location.search) {
            let data = { ...fields };
            
            let query = urlReader.readQuery(location.search);
            if(query.email) {
                query.email = decodeURIComponent(query.email);
                data.email.value = query.email;
                data.email.editable = false;
                checkEmailExist(query.email);
            }
            if(query.name) {
                query.name = decodeURIComponent(query.name);
                data.nickName.value = query.name;
            }
            if(query.code) {
                getInvitation(query.email, query.code);
            }
            setUrlParams(query);
            setShowPassword(query.app? false : true);
            setFields(data);
        } else {
            setUrlParams({});
            setFromSignUp(true);
        }
    }, []);

    const [currentLan, setCurrentLan] = useState();
    useEffect(()=>{
        const localLanguage = window.localStorage.getItem('language');
        var defaultLang =(navigator.language || navigator.browserLanguage).toLowerCase();

        if(localLanguage){
            setCurrentLan(localLanguage);
        }else if(defaultLang.indexOf('en')!=-1){
            setCurrentLan('en');
        }
        else{
            setCurrentLan("zh");
        }
    },[])

    useEffect( ()=> {
        if(urlParams && emailInfo && emailInfo.existed) {
            if(!urlParams.code) {
                window.location.href = iamService.loginURL();
            } else if(invitation) {
                inviteService.finishInvitation(
                    invitation.invitationCode,
                    invitation.invitee,
                    invitation.organizationId);

                window.location.href = iamService.loginURL();
            }
        }
    }, [urlParams, emailInfo, invitation]);

    const initCountries = function() {
        let countries = JSON.parse(JSON.stringify(countriesMock));
        countries.forEach((item)=> {
            item.name = t(item.name);
        });
        setCountries(countries);
    }

    const checkEmailExist = function(email) {
        inviteService.checkEmailExist(email).then((serverData)=> {
            console.error(serverData);
            if(serverData) {
                if(serverData.status === 1 || serverData.status === 2) {
                    serverData.existed = true; //user active or pending
                } else {
                    serverData.existed = false;
                }
                setEmailInfo(serverData);
            }
            else{
                setFromSignUp(true);
            }
        })
    }

    const getInvitation = function(email, code) {
        inviteService.getInvitationDetail(code).then((serverData)=> {
            if(serverData) {
                if(serverData.invitee !== email || serverData.status !== 0) {
                    history.push('/register-error')
                } else {
                    setFormInfo({
                        valid: "",
                        msg: ""
                    } )
                    serverData.valid = true
                }
                setInvitation( serverData);
            }
        })
    }

    const onSubmit = function() {
        if(invitation && !invitation.valid) {
            console.warn('Invitation state is invalid!');
            return;
        }
        if(!isFormValid()) {
            console.warn('Form is invalid!');
            return;
        }

        setBusy(true);

        let params = {
            email: fields.email.value,
            password: fields.password.value,
            nickname: fields.nickName.value? fields.nickName.value: fields.email.value,
            location: fields.location.value? fields.location.value.code: '',
            companyName: fields.companyName.value
        }
        if(urlParams && urlParams.state) {
            params.state = urlParams.state
        }
        if(invitation) {
            params.code = invitation.invitationCode
            params.inviter = invitation.inviter
            params.orgId = invitation.organizationId
        }
        sendRequest(params);
    }

    const sendRequest = function(params) {
        iamService.register(params, urlParams.app_params).then((data)=> {
            setBusy(false)
            if(data.code === 200) {
                if(data && data.access_token) {
                    history.push('/iamCallback#access_token=' + data.access_token)
                } else {
                    history.push('/register-success')
                }
            } else if(data.code === 409 || data.code === 1200) {
                setFormInfo({
                    valid: "error",
                    msg: t('userReg.userExist')
                } )
            }
        }).catch((err)=> {
            console.error('sendRequest: ', err)
            setFormInfo({
                valid: "error",
                msg: t('userReg.userExist')
            })
            setBusy(false)
        })
    }

    // const addLinks1 =(text) =>{
    //     let finnalText = text.toString();
    //     const link=[
    //         {text:'隐私条款',href:'/terms-privacy?type=terms'},];
    //         // {text:'隐私声明',href:'/terms-privacy?type=privacy'},
    //         // {text:'Terms of Service',href:'/terms-privacy?type=terms'},
    //         // {text:'Privacy Statement',href:'/terms-privacy?type=privacy'},];
    //     link.map(item=>{
    //         finnalText = linkPartOfText(item.text,item.href,finnalText);
    //     })
    //     return (
    //         <div>
    //             {finnalText}
    //         </div>
    //     );
    // }

    const addLinks = (targetText) => {
        if (!targetText) {
          return "";
        }
        let termsText = 'Terms of Service';
        let privacyText = 'Privacy Statement';
        if(currentLan==='zh'){
            termsText = '服务条款';
            privacyText = '隐私声明';
        }
    
        let termsLink = '/terms-privacy?type=terms';
        let privacyLink = '/terms-privacy?type=privacy';
        const indexOfText = targetText.indexOf(termsText);
        const indexOfText1 = targetText.indexOf(privacyText);

        if (indexOfText === -1 && indexOfText1===-1) {
          return targetText;
        }
        const endIndexOfText = indexOfText + termsText.length;
        const endIndexOfText1 = indexOfText1 + privacyText.length;

        return (
            <>
            {`${targetText.slice(0, indexOfText)}`}
            <a href={termsLink}>{targetText.slice(indexOfText, endIndexOfText)}</a>
            {`${targetText.slice(endIndexOfText, indexOfText1)}`}
            <a href={privacyLink}>{targetText.slice(indexOfText1, endIndexOfText1)}</a>
            {`${targetText.slice(endIndexOfText1)}`}
            </>
        )
    };

    return (
        <div>
        {(emailInfo && emailInfo.existed) && <Typography variant='h6' style={{textAlign:"center"}} gutterBottom>Please wait for processing</Typography>}
        {((fromSignUp)||(emailInfo&&!emailInfo.existed)) && <form className={classes.form}>
            <Typography variant='body1' gutterBottom>
                {t('userReg.signUp')}
            </Typography>
            <Typography variant='body1' gutterBottom className={classes.hintsText}>
                {t('userReg.startWork')}
            </Typography>
            <TextField fullWidth
                       placeholder={fields.email.title}
                       variant='outlined'
                       autoComplete='none'
                       disabled={fields.email.editable===false}
                       value={fields.email.value}
                       onChange={(e)=> {
                           handleChange(fields.email.name, e.target.value)
                       }}
                       error={!fields.email.valid}
                       size="small"
                       helperText={!fields.email.valid && fields.email.errorMsg}
            >
            </TextField>

            {showPassword &&
            <PasswordWithHint field={fields.password}
                                 onChange={(value)=> {
                                     handleChange(fields.password.name, value)
                                 }}></PasswordWithHint>}
            {showPassword &&
            <PasswordInput title={fields.repassword.title}
                           initValue={fields.repassword}
                           valid={fields.repassword.valid}
                           size="small"
                           errorMsg={fields.repassword.errorMsg}
                           onChange={(e)=> {
                               handleChange(fields.repassword.name, e.target.value)
                           }}
            ></PasswordInput>}

            <TextField fullWidth
                       placeholder={fields.companyName.title}
                       variant='outlined'
                       autoComplete='none'
                       size="small"
                       value={fields.companyName.value}
                       onChange={(e)=> {
                           handleChange(fields.companyName.name, e.target.value)
                       }}
                       error={!fields.companyName.valid}
                       helperText={fields.companyName.errorMsg}
            >
            </TextField>

            <TextField fullWidth
                       placeholder={fields.nickName.title}
                       variant='outlined'
                       autoComplete='none'
                       size="small"
                       value={fields.nickName.value}
                       onChange={(e)=> {
                           handleChange(fields.nickName.name, e.target.value)
                       }}
                       error={!fields.nickName.valid}
                       helperText={fields.nickName.errorMsg}
            >
            </TextField>

            <Autocomplete
                id={fields.location.name}
                name={fields.location.name}
                options={countries}
                getOptionLabel={(option) => option.name}
                value={fields.location.value}
                placeholder={fields.location.title}
                onChange={(event, value) => {
                    handleChange(fields.location.name, value)
                }}
                renderInput={(params) => <TextField {...params} placeholder={fields.location.title}
                                                    error={!fields.location.valid}
                                                    helperText={!fields.location.valid && fields.location.errorMsg}
                                                    size="small" variant="outlined" />}
            >
            </Autocomplete>

            <Typography variant='body2' className={classes.errorText} gutterBottom>{formInfo.msg}</Typography>
            <Button variant="contained" color="primary"
                    className={classes.submitButton}
                    onClick={onSubmit}>
                {t('userReg.signUp')}
            </Button>
        </form>
        }
        <div style={{marginTop:'20px'}}>
            <Typography variant='body1' gutterBottom className={classes.hintsText}>
                {addLinks(t('userReg.termsPrivacy'))}
            </Typography>
        </div>
        </div>
    );
}