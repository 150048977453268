import React,{useContext}  from 'react';
import { Component, useEffect, useState, useRef} from 'react';
import { Link, useParams, useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { Grid, Button, Select, MenuItem, FormControl, InputLabel, TextField, Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import useToken from '../../hooks/useToken';
import { ApplicationContext } from "../../context/applicationContext";
import userService from "../../services/userService";
import UndoIcon from '@material-ui/icons/Undo';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import MaterialTable from 'material-table'
import moment from 'moment';
import { forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import LoadingButton from "../../components/LoadingButton";
import Autocomplete from '@material-ui/lab/Autocomplete';
import inventoryService from "../../services/inventoryService";

import AddBox from '@material-ui/icons/AddBox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {QRCodeSVG} from 'qrcode.react';
import CropFreeIcon from '@material-ui/icons/CropFree';
import * as htmlToImage from 'html-to-image';
import { toPng } from 'html-to-image';

const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
        display: 'flex',
        flexDirection: "row",
        maxWidth: '1200px',
        width: '100%',
    },
    box: {
        paddingLeft:'30px',
        paddingTop:'40px',
        width:'100%'
    },
    undoIcon: {
        marginRight: "23px",
        color:"#3f51b5",
        cursor:'pointer'
    },
    managementTitle: {
        padding: "21px 0 21px 27px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[3],
    },
    managementContent:{
        padding: "21px 0 21px 27px",
    },
    editTitle:{
        // marginBottom:'4px',
        // marginTop:'4px',
        fontSize:'14px'
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    dialogHr: {
        border: '1px',
        borderStyle: 'dashed none none none',
        borderColor: theme.currentTheme.colors[6],
        marginTop: '10px',
        marginBottom: '10px',
        width:'100%',
    },
    dialogRoot:{
        marginBottom:'20px',
        color:theme.currentTheme.text,
        fontSize:'14px'
    },
    qrCode:{
        textAlign:'center',
        marginBottom:'20px',
        color:theme.currentTheme.text,
        fontSize:'14px'
    },
    selectField: {
        padding: "5px 6px",
    },
    selectField1: {
        padding: "11px 14px",
    },
    required:{
        color:'red'
    },
    upLoadInput: {
        display: 'none',
    },
}));


export default function GoodsManagement() {
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const { token } = useToken();
    const theme = useTheme();
    const fileInput = React.useRef();
    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>
        ),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        Cancel: forwardRef((props, ref) => <CancelOutlinedIcon {...props} ref={ref} />)
    };


    const history = useHistory();
    const [searchKeyWordsInput, setSearchKeyWordsInput] = useState("");
    const [searchKeyWords, setSearchKeyWords] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [batchUploadResultOpen, setBatchUploadResultOpen] = useState(false);
    const [loading, setLoading] = React.useState(false);    
    const [currencyData, setCurrencyData] = useState(["USD", "CNY", "EUR", "HKD", "JPY", "CAD", "AUD", "GBP"]);
    const [selCurrency, setSelCurrency] = useState(null);
    const [unitforCost,setUnitforCost] = useState("");
    const [unitforCostError,setUnitforCostError] = useState(false);
    const [unitforStorage,setUnitforStorage] = useState("");
    const [unitforStorageError,setUnitforStorageError] = useState(false);
    const [goodsName,setGoodsName] = useState("");
    const [goodsNameError,setGoodsNameError] = useState(false);
    const [goodsId,setGoodsId] = useState("");
    const [goodsIdError,setGoodsIdError] = useState(false);
    const [goodsCategory,setGoodsCategory] = useState("");
    const [goodsDescription,setGoodsDescription] = useState("");
    const [goodsRev,setGoodsRev] = useState("");
    const [goodsSpec,setGoodsSpec] = useState("");
    const [goodsStandard,setGoodsStandard] = useState("");
    const [goodsNotes,setGoodsNotes] = useState("");
    const [currentOrg, setCurrentOrg] = useState("");
    const [currentGoods, setCurrentGoods] = useState([])
    const [uploadResult, setUploadResult] = useState([])
    const [errorMessgesOpen, setErrorMessgesOpen] =useState(false);
    const [submitError, setSubmitError] =useState('');
    const [qrCodeOpen, setQrCodeOpen] =useState(false);
    const [currentGoodsId, setCurrentGoodsId] =useState('');

    useEffect(() => {
        if(history.location.state.orgId){
            setCurrentOrg(history.location.state.orgId);
        }
        if(history.location.state.addOpen){
            setOpenDialog(history.location.state.addOpen);
        }
    },[])

    useEffect(() => {
        if(currentOrg!==""){
            refreshGoodsList();
        }
    },[currentOrg,searchKeyWords])

    const goBackHandler = ()=>{
        history.push("/Inventory")
    }

    const handleSearch = () => {
        setSearchKeyWords(searchKeyWordsInput);
    }

    const hanlerEnterSearch =(event)=>{
        console.log(event);
        if (event.key == "Enter"){
            handleSearch();
        }

    }

    const formatDate=(value)=> {
        return moment(value).format("YYYY/MM/DD HH:MM:SS");
    }

    const addToolTip=(value)=> {
        return (<div title={value} className={classes.toolTipDiv}>{value}</div>);
    }

    const handleUnitforCostChange =(e,newValue)=>{
        setUnitforCost(newValue);
        if(newValue===""){
            setUnitforCostError(true);
        }
        else{
            setUnitforCostError(false);
        }
    }

    const handleUnitforStorageChange =(e,newValue)=>{
        setUnitforStorage(newValue);
        if(newValue===""){
            setUnitforStorageError(true);
        }
        else{
            setUnitforStorageError(false);
        }
    }

    const openDialogHandler =()=>{
        setGoodsId("");
        setGoodsName("");
        setGoodsCategory("");
        setGoodsDescription("");
        setGoodsRev("");
        setGoodsSpec("");
        setGoodsStandard("");
        setUnitforStorage("");
        setUnitforCost("");
        setSelCurrency(null);
        setGoodsNotes("");
        setGoodsIdError(false);
        setGoodsNameError(false);
        setUnitforCostError(false);
        setUnitforStorageError(false);
        setOpenDialog(true);
    }

    const handleClose =()=>{
        setLoading(false);
        setOpenDialog(false);
    }

    const handleUploadResultClose =()=>{
        setBatchUploadResultOpen(false);
    }

    const handleGoodsIdChange =(event)=>{
        setGoodsId(event.target.value)
        if (event.target.value === "") {
            setGoodsIdError(true)
        }
        else {
            setGoodsIdError(false)
        }
    }

    const handleGoodsNameChange =(event)=>{
        setGoodsName(event.target.value)
        if (event.target.value === "") {
            setGoodsNameError(true)
        }
        else {
            setGoodsNameError(false)
        }
    }

    const handleSubmit=()=>{
        setLoading(true);
        let isValid = true;
        if(goodsId===""){
            setGoodsIdError(true);
            isValid = false;
        }
        if(goodsName===""){
            setGoodsNameError(true);
            isValid = false;
        }
        if(unitforCost===""){
            setUnitforCostError(true);
            isValid = false;
        }
        if(unitforStorage===""){
            setUnitforStorageError(true);
            isValid = false;
        }
        if(!selCurrency||selCurrency===""){
            setSelCurrency("");
            isValid = false;
        }

        if(!isValid){
            setLoading(false);
            return;
        }

        let submitValue={
            orgId: currentOrg,
            id: goodsId,
            name: goodsName,
            description: goodsDescription,
            unitCost: unitforCost,
            unitStorage: unitforStorage,
            currency: selCurrency,
            category: goodsCategory,
            rev: goodsRev,
            spec: goodsSpec,
            standard: goodsStandard,
            notes: goodsNotes,
            createBy: token.username
        };

        inventoryService.addGood(submitValue).then((result)=>{
            if(result&&result.status===200){
                setLoading(false);
                if(history.location.state.addOpen){
                    history.push({
                        pathname: '/Inventory/CheckInPage',
                        state: { 
                            "selectedGoods": result.data.goodsId,
                            "warehouseData": history.location.state.warehouseData
                        },
                    });
                }
                else{
                    refreshGoodsList();
                    handleClose();
                } 
            }
            else{
                setLoading(false);
            }
        }).catch((e) => {
            if(e.response.data.message.indexOf("OutStorage greater than zero")>-1){
                setSubmitError(t('goodsManagement.errorTips1'));
                setErrorMessgesOpen(true);
            }
            else{
                setSubmitError(e.response.data.message);
                setErrorMessgesOpen(true);
            }
        })
    }

    const refreshGoodsList = ()=>{
        inventoryService.searchGoodByNameOrId(currentOrg,searchKeyWords).then((result)=>{
            if(result&&result.status===200){
                setCurrentGoods(result.data.datas);
            }
        });
    }

    const handleFileChange =(e) => {
        const file = e.currentTarget.files[0];
        console.log(file);
        let formData = new FormData();
        formData.append("goodsFile", file);
        formData.append("orgId",history.location.state.orgId);
        formData.append("uploadType","standard");
        inventoryService.batchUploadGoods(formData).then(result=>{
            if(result.status===200 && result.data){
                setBatchUploadResultOpen(true);
                setUploadResult(result.data);
                console.log(result.data);
            }else
            {
                setBatchUploadResultOpen(true);
                setUploadResult(result.data);
            }
        }).catch((err)=>{
            setBatchUploadResultOpen(true);
            setUploadResult([t('goodsManagement.unSupportCsv')]);
        })

    }

    const getQRCode = (rowData) =>{
        setCurrentGoodsId(rowData);
        setQrCodeOpen(true);
    }

    const print =() =>{
        var printwin = window.open("");
        printwin.document.write(document.getElementById("QRCode").innerHTML);
        printwin.print();
    }

    const download =()=>{
        var node = document.getElementById('QRCode');
        htmlToImage.toPng(node).then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = currentGoodsId.id+'/'+ currentGoodsId.name+'.png';
            link.href = dataUrl;
            link.click();
        }).catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
    }

    return (
        <div className={classes.root}> 
            <div className={classes.box}>
                <Grid container justifyContent="flex-start" alignItems="center" className={classes.managementTitle}>
                    <div onClick={goBackHandler}>
                        <UndoIcon color="primary" className={classes.undoIcon} ></UndoIcon>
                    </div>
                    <Typography variant="h3" gutterBottom style={{"fontWeight":"bold"}}>
                        {t('goodsManagement.title')}
                    </Typography>
                </Grid>
                <Grid container justifyContent="flex-start" className={classes.managementContent}>
                    <Grid md={6}>
                        <Grid container justifyContent="flex-start">
                            <TextField id="outlined-basic" variant="outlined" size="small" placeholder={t("customerManagement.searchHint")} style={{width:'300px',verticalAlign:'middle'}} onChange={e => setSearchKeyWordsInput(e.target.value)} onKeyPress={hanlerEnterSearch}/>
                            <IconButton onClick={handleSearch} aria-label="search"><SearchIcon></SearchIcon></IconButton>
                        </Grid>
                    </Grid>
                    <Grid md={6}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title={t('goodsManagement.addButton')}>
                                <IconButton onClick={openDialogHandler}>
                                    <AddCircleOutlineOutlinedIcon size="large"></AddCircleOutlineOutlinedIcon>
                                </IconButton>
                            </Tooltip>
                            <div className={classes.upLoadRoot}>
                                <label htmlFor="file">
                                    <input
                                        ref={fileInput} 
                                        accept='.csv'
                                        className={classes.upLoadInput}
                                        id="file"
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    <Tooltip title={t('goodsManagement.uploadButton')}>
                                        <IconButton onClick={()=>fileInput.current.click()}>
                                            <CloudUploadIcon size="large"></CloudUploadIcon>
                                        </IconButton>
                                    </Tooltip>
                                </label>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid md={12} style={{marginTop:'30px'}}>
                    <MaterialTable
                            classes={{MuiPaper:classes.noBox}}
                            title=""
                            columns={[
                                { field: 'id', 
                                    title: t('goodsManagement.goodsId'),
                                    sorting: false,
                                    width: '8%',
                                    validate: rowData => rowData.id !== '' && rowData.id !== undefined,
                                    render: rowData => addToolTip(rowData.id),
                                    cellStyle:{
                                        maxWidth:'120px',
                                        padding:'4px',
                                        fontSize:'14px',
                                        overflow:'hidden',
                                        textOverflow:'ellipsis',
                                        whiteSpace:'nowrap',
                                    },
                                },
                                {
                                    field: 'name',
                                    title: t('goodsManagement.goodsName'),
                                    validate: rowData => rowData.name !== '' && rowData.name !== undefined,
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'category',
                                    title: t('goodsManagement.category'),
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'description',
                                    title: t('goodsManagement.description'),
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'rev',
                                    title: t('goodsManagement.rev'),
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'spec',
                                    title: t('goodsManagement.spec'),
                                    width: '10%',
                                    sorting: false,
                                },
                                {
                                    field: 'standard',
                                    title: t('goodsManagement.standard'),
                                    width: '10%',
                                    sorting: false,
                                },
                                {
                                    field: 'unitStorage',
                                    title: t('goodsManagement.unitforStorage'),
                                    validate: rowData => rowData.unitStorage !== '' && rowData.unitStorage !== undefined,
                                    width: '10%',
                                    sorting: false,
                                    editComponent: props => (
                                        <Autocomplete
                                            // value={item.title}
                                            value={props.rowData.unitStorage}
                                            freeSolo
                                            fullWidth
                                            size='small'
                                            options={["pc","kg"].map(option=>option)}
                                            // getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                {option}
                                                </React.Fragment>
                                            )}
                                            
                                            // onChange={(e, newValue) => {
                                            //     setUnitforStorage(newValue);
                                            // }}
                                            onInputChange={(e,newInputValue)=>{
                                                props.onRowDataChange({
                                                    ...props.rowData,
                                                    unitStorage:newInputValue})
                                            }}
                                            renderInput={(params) => <TextField {...params} error={!props.rowData.unitStorage || props.rowData.unitStorage === undefined || props.rowData.unitStorage === ""}/>}
                                        />
                                    ),
                                },
                                {
                                    field: 'unitCost',
                                    title: t('goodsManagement.unitforCost'),
                                    validate: rowData => rowData.unitCost !== '' && rowData.unitCost !== undefined,
                                    width: '10%',
                                    sorting: false,
                                    editComponent: props => (
                                        <Autocomplete
                                            // value={item.title}
                                            value={props.rowData.unitCost}
                                            freeSolo
                                            fullWidth
                                            size='small'
                                            options={["pc","kg"].map(option=>option)}
                                            // getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                {option}
                                                </React.Fragment>
                                            )}
                                            
                                            // onChange={(e, newValue) => {
                                            //     setUnitforStorage(newValue);
                                            // }}
                                            onInputChange={(e,newInputValue)=>{
                                                props.onRowDataChange({
                                                    ...props.rowData,
                                                    unitCost:newInputValue})
                                            }}
                                            renderInput={(params) => <TextField {...params} error={!props.rowData.unitCost || props.rowData.unitCost === undefined || props.rowData.unitCost === ""}/>}
                                        />
                                    ),
                                },
                                {
                                    field: 'currency',
                                    title: t('goodsManagement.currency'),
                                    validate: rowData => rowData.currency !== '' && rowData.currency !== undefined,
                                    width: '10%',
                                    sorting: false,
                                    // render: rowData => formatDate(rowData.timestamp),
                                    editComponent: props => (
                                        <Select
                                            id="currency"
                                            value={props.rowData.currency}
                                            error={!props.rowData.currency || props.rowData.currency === undefined || props.rowData.currency === ""}
                                            fullWidth
                                            size="small"
                                            onChange={e=>{
                                                props.onRowDataChange({
                                                    ...props.rowData,
                                                    currency:e.target.value})
                                            }}
                                            classes={{ select: classes.selectField }}
                                        >
                                            {currencyData && currencyData.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ),
                                },
                                {
                                    field: 'notes',
                                    title: t('goodsManagement.notes'),
                                    width: '10%',
                                    sorting: false,

                                    cellStyle:{
                                    maxWidth:'120px',
                                    padding:'4px',
                                    fontSize:'14px',
                                    overflow:'hidden',
                                    textOverflow:'ellipsis',
                                    whiteSpace:'nowrap',
                                    },
                                    render: rowData => addToolTip(rowData.notes),
                                },
                                ]}
                            icons={tableIcons}
                            data={currentGoods}
                            style={{width:'100%'}} 
                            options={{      
                                actionsColumnIndex: -1,                                      
                                search: false,
                                toolbar:false,
                                sorting: true,
                                paging:true,
                                pageSize:10,
                                // tableLayout:"fixed",
                                headerStyle: {
                                    backgroundColor:"transparent",
                                    fontFamily:'IBMPlexSans-Bold',
                                    padding:'4px'
                                },
                                cellStyle:{
                                    padding:'4px',
                                    fontSize:'14px',
                                    overflow:'hidden',
                                    textOverflow:'ellipsis',
                                    whiteSpace:'nowrap',
                                    },
                                rowStyle: (rowData, index ) => ({
                                    backgroundColor:index % 2 === 0 ?theme.currentTheme.theme2backgrounds[1]:theme.currentTheme.theme2backgrounds[2],
                                    fontSize:'14px',
                                    }),
                            }}
                            editable={{
                                // isEditable: rowData => accountingService.checkPermission("revise_po_line", props.book) && rowData.isUsed === false,
                                // isDeletable: rowData => accountingService.checkPermission("cancel_po_line", props.book) && rowData.isUsed === false,
                                onRowUpdate: (newData) =>
                                    new Promise((resolve, reject) => {
                                        inventoryService.updateGood(newData.goodsId,newData).then(result=>{
                                            if(result&&result.status===200){
                                                refreshGoodsList();
                                                resolve();
                                            }
                                        }).catch((e) => {
                                            resolve();
                                            if(e.response.data.message.indexOf("OutStorage greater than zero")>-1){
                                                setSubmitError(t('goodsManagement.errorTips1'));
                                                setErrorMessgesOpen(true);
                                            }
                                            else{
                                                setSubmitError(e.response.data.message);
                                                setErrorMessgesOpen(true);
                                            }
                                        })
                                    }),
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        inventoryService.deleteGood(oldData.goodsId).then(result=>{
                                            if(result&&result.status===200){
                                                refreshGoodsList();
                                                resolve();
                                            }
                                        }).catch((e) => {
                                            resolve();
                                            if(e.response.data.message.indexOf("OutStorage greater than zero")>-1){
                                                setSubmitError(t('goodsManagement.errorTips1'));
                                                setErrorMessgesOpen(true);
                                            }
                                            else{
                                                setSubmitError(e.response.data.message);
                                                setErrorMessgesOpen(true);
                                            }
                                        })
                                    }),
                            }}
                            localization={{
                                header: {
                                    actions: t("common.actions")
                                },
                                body:{
                                    deleteTooltip:t("common.delete"),
                                    editTooltip:t("common.edit"),
                                    editRow:{
                                        deleteText:t("goodsManagement.deleteConfirm"),
                                        cancelTooltip:t("common.cancel"),
                                        saveTooltip:t("common.save"),
                                    },
                                    emptyDataSourceMessage:t("common.noRecord"),
                                }

                            }}
                            actions={[
                                rowData => ({
                                    icon: ()=> <CropFreeIcon></CropFreeIcon>,
                                    tooltip: t('common.qrCode'),
                                    onClick: (event, rowData) => getQRCode(rowData),
                                    // disabled: (rowData.status !== 'saved'),
                                }),
                            ]}
                        />
                    </Grid>
                </Grid>
                <Dialog open={openDialog} scroll="body" maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><Typography variant="body1">{t('goodsManagement.addFormTitle')}</Typography></DialogTitle>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <Divider variant="middle"></Divider>
                    <DialogContent classes={{ root: classes.dialogRoot, }}>
                            <Grid container spacing={1} >
                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.goodsId')} <span className={classes.required}>*</span>
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="goodsId"
                                            error={goodsIdError}
                                            variant="outlined"
                                            value={goodsId}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={handleGoodsIdChange}
                                            placeholder={t('goodsManagement.goodsId')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.goodsName')} <span className={classes.required}>*</span>
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="goodsName"
                                            error={goodsNameError}
                                            variant="outlined"
                                            value={goodsName}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={handleGoodsNameChange}
                                            placeholder={t('goodsManagement.goodsName')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.category')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="category"
                                            // error={titleError}
                                            variant="outlined"
                                            value={goodsCategory}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setGoodsCategory(e.target.value)}
                                            placeholder={t('goodsManagement.category')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.description')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="description"
                                            // error={titleError}
                                            variant="outlined"
                                            value={goodsDescription}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setGoodsDescription(e.target.value)}
                                            placeholder={t('goodsManagement.description')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.rev')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="rev"
                                            // error={titleError}
                                            variant="outlined"
                                            value={goodsRev}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setGoodsRev(e.target.value)}
                                            placeholder={t('goodsManagement.rev')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.spec')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="spec"
                                            // error={titleError}
                                            variant="outlined"
                                            value={goodsSpec}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setGoodsSpec(e.target.value)}
                                            placeholder={t('goodsManagement.spec')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.standard')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="standard"
                                            // error={titleError}
                                            variant="outlined"
                                            value={goodsStandard}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setGoodsStandard(e.target.value)}
                                            placeholder={t('goodsManagement.standard')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.unitforStorage')} <span className={classes.required}>*</span>
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            // value={item.title}
                                            value={unitforStorage}
                                            freeSolo
                                            fullWidth
                                            size='small'
                                            options={["pc","kg"].map(option=>option)}
                                            // getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                {option}
                                                </React.Fragment>
                                            )}
                                            
                                            // onChange={(e, newValue) => {
                                            //     setUnitforStorage(newValue);
                                            // }}
                                            onInputChange={(e,newInputValue)=>{
                                                handleUnitforStorageChange(e,newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" error={unitforStorageError} placeholder={t('goodsManagement.unitforStorage')}/>}
                                            style={{marginTop:'8px'}}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.unitforCost')} <span className={classes.required}>*</span>
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            // value={item.title}
                                            value={unitforCost}
                                            freeSolo
                                            fullWidth
                                            size='small'
                                            options={["pc","kg"].map(option=>option)}
                                            // getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                {option}
                                                </React.Fragment>
                                            )}
                                            
                                            // onChange={(e, newValue) => {
                                            //     setUnitforCost(newValue);
                                            //     console.log(unitforCost);
                                            // }}
                                            onInputChange={(e,newInputValue)=>{
                                                handleUnitforCostChange(e,newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" error={unitforCostError} placeholder={t('goodsManagement.unitforCost')}/>}
                                            style={{marginTop:'8px'}}
                                            />
                                        </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.currency')} <span className={classes.required}>*</span>
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            id="currency"
                                            value={selCurrency}
                                            error={selCurrency===""?true:false}
                                            fullWidth
                                            required={true}
                                            variant="outlined"
                                            size="small"
                                            onChange={e=>setSelCurrency(e.target.value)}
                                            classes={{ select: classes.selectField1 }}
                                            style={{marginTop:'7px'}}
                                        >
                                            {currencyData && currencyData.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid className={classes.editTitle}>
                                        {t('goodsManagement.notes')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="title"
                                            // error={titleError}
                                            multiline={true}
                                            variant="outlined"
                                            minRows={5}
                                            value={goodsNotes}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setGoodsNotes(e.target.value)}
                                            placeholder={t('goodsManagement.notes')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={loading} variant="contained" onClick={handleSubmit} color="primary">
                            {t('common.confirm')}
                        </LoadingButton>
                        <Button variant="contained" onClick={handleClose}>                            
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={batchUploadResultOpen} scroll="body" maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><Typography variant="body1">{t('goodsManagement.batchUploadResultTitle')}</Typography></DialogTitle>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleUploadResultClose}>
                        <CloseIcon />
                    </IconButton>
                    <Divider variant="middle"></Divider>
                    <DialogContent classes={{ root: classes.dialogRoot, }}>
                        {uploadResult&&uploadResult.map((item,index)=>{
                            return(<Typography variant="body2">
                                {JSON.stringify(item).replace("{","").replace("}","")}
                            </Typography>)
                        })}
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={loading} variant="contained" onClick={handleUploadResultClose} color="primary">
                            {t('common.ok')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
                <Dialog open={errorMessgesOpen} scroll="body" maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogContent classes={{ root: classes.dialogRoot, }}>
                        {submitError}
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={loading} variant="contained" onClick={e=>setErrorMessgesOpen(false)} color="primary">
                            {t('common.ok')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>

                <Dialog open={qrCodeOpen} scroll="body" maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogContent classes={{ root: classes.qrCode, }} id='QRCode'>
                          <QRCodeSVG value={currentGoodsId.goodsId} />
                          <Typography>{currentGoodsId.id+'/'+currentGoodsId.name}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button isLoading={loading} variant="contained" onClick={download} color="primary">
                            {t('common.download')}
                        </Button>
                        <Button isLoading={loading} variant="contained" onClick={print} color="primary">
                            {t('common.print')}
                        </Button>
                        <LoadingButton isLoading={loading} variant="contained" onClick={e=>setQrCodeOpen(false)} color="primary">
                            {t('common.ok')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
            
        </div>
    )
}