import CommonLayout from "../../components/CommonLayout";
import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import {ApplicationContext} from "../../context/applicationContext";
import OrgRoleReader from "../../utils/orgRoleReader";
import openbookDescriptionPic1 from '../../assets/img/openbook_d_1.png';
import openbookDescriptionPic2 from '../../assets/img/openbook_d_2.png';
import openbookDescriptionPic3 from '../../assets/img/openbook_d_3.png';
import Pagination from '@material-ui/lab/Pagination';
import accountingService from "../../services/accountingService";
import OrganizationSelect from "../../components/OrganizationSelect";
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import XLSX from 'xlsx'
import {useTranslation} from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import downloadFileUtils from "../../utils/downloadFile";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomerSelector from "../../components/CustomerSelector";
import ColumnsSelectDialog from "./columnsSelectDialog";

export default function Main() {

    const {t} = useTranslation();
    const history = useHistory();
    const [initing, setIniting] = React.useState(true);
    const [orgExpired, setOrgExpired] = React.useState(false);
    const [openCreateOrg, setOpenCreateOrg] = React.useState(false);
    const [poLines, setPoLines] = useState([]);
    const [poLinesOld, setPoLinesOld] = useState([]);
    const [poLinesAllPages, setPoLinesAllPages] = useState([]);
    const [warningOpen, setWarningOpen] = useState(false);
    const [searchKeyWords, setSearchKeyWords] = useState("");

    const [startDate, setStartDate] = useState(moment().subtract(12, 'months'));
    const [endDate, setEndDate] = useState(moment().subtract(-12, 'months'));

    const [startInvoiceDate, setStartInvoiceDate] = useState(null);
    const [endInvoiceDate, setEndInvoiceDate] = useState(null);

    const [startRequestDate, setStartRequestDate] = useState(null);
    const [endRequestDate, setEndRequestDate] = useState(null);

    const [errorTips, setErrorTips] = useState('');
    const [pages, setPages] = useState(0);
    const {application,refreshApplication} = useContext(ApplicationContext);
    const [currentOrg, setCurrentOrg] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentCustomer,setCurrentCustomer] = useState('all');
    const [customers,setCustomers] = useState([]);
    const [selPOLines, setSelPOLines] = useState([]);
    const [invoiced, setInvoiced] = useState('all');
    const [shipped, setShipped] = useState('all');
    const pageSize = 20;
    const [sortBy, setSortBy] = useState('');
    const [sortDesc, setSortDesc] = useState('');
    const [canShip, setCanShip] = useState(true);
    const [canStatement, setCanStatement] = useState(true);
    const [canPay, setCanPay] = useState(true);
    const [selectOpen, setSelectOpen] = useState(false);
    const [noteOpen, setNoteOpen] = useState(false);
    const [noteValue, setNoteValue] = useState('');
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [poLine, setPOLine] = useState({});
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [originalColumns, setOriginalColumns] = useState([]);

    const dynamicColumns = [
        {label: t('poFormPage.poNumber')},
        {label: t('poFormPage.customer')},
        {label: t('poFormPage.line')},
        {label: t('poFormPage.item') + '/' + t('poFormPage.product')},
        {label: t('poFormPage.storageUnit')},
        {label: t('poFormPage.QTY')},
        {label: t('poFormPage.price')},
        {label: t('poLineManagement.balance')},
        {label: t('poLineManagement.date')},
        {label: t('poFormPage.notes')},
        {label: t('poLineManagement.shipDate')},
        {label: t('poLineManagement.actualDate')},
        {label: t('poFormPage.alarmDate')},
        {label: t('shippingPage.shippingNumber')},
        {label: t('shippingPage.exportContractNo')},
        {label: t('shippingPage.baoguanNo')},
        {label: t('shippingPage.baoguanDate')},
        {label: t('shippingPage.invoiceNumber')},
    ]

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: '50px'
        },
        box: {
            width: '100%',
            padding: '30px',
            boxSizing: 'border-box',
            '@media (max-width:960px)':  {
                padding: '16px',
            },
        },
        dialogRoot: {
            minHeight: '500px',
            marginTop: '10px',
        },
        dialogHr: {
            border: '1px',
            dashed: '#000',
            marginTop: '20px',
            marginBottom: '20px'
        },
        required: {
            color: '#981414',
        },
        outlinedPadding: {
            padding: '11px 14px',
        },
        formControl: {
            margin: theme.spacing(1),
            marginLeft:0,
            width: '100%'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },

        bookItemTitle:{
            paddingLeft:'8px',
            marginTop:'12px',
            "& p":{
                overflow:'hidden',
                textOverflow:'ellipsis',
                whiteSpace:'nowrap',
                width:'90%'
            }
        },

        bookItemPo:{
            paddingLeft:'8px',
            marginTop:'5px',

        },

        bookItemBalance:{
            padding:'4px',
        },
        detailBook:{
            minWidth:'200px',
            backgroundColor:'none',
            border: theme.currentTheme.border[3],
            borderRadius:'8px',
            padding: '20px 12px 15px 20px',
            lineHeight:'70px'
        },
        dataPicker: {
            width: 'calc(200px)',
            '@media only screen and (max-width: 1200px)':  {
                width: 'calc(100%)',
            }
        },
        filter2Line: {
            '@media (max-width:960px)':  {
                marginTop: theme.spacing(1),
            },
            '@media (min-width:960px)':  {
                paddingLeft: theme.spacing(2),
            },
        },
        popover: {
            pointerEvents: 'none',
        },
        balanceTable:{
            tableLayout:'fixed',
            "& th" : {
                backgroundColor: theme.currentTheme.theme2backgrounds[1],
                "& p":{fontFamily:'IBMPlexSans-Bold'},
                "& a":{color:theme.currentTheme.text}
            },
            "& td" : {
                paddingTop:'4px',
                verticalAlign:'middle'
            },
            "& tr:nth-child(even)" : {
                backgroundColor: theme.currentTheme.theme2backgrounds[1],
                height:'35px',
                verticalAlign:'top'
            },
            "& tr:nth-child(odd)" : {
                backgroundColor: theme.currentTheme.theme2backgrounds[1],
                height:'35px',
                verticalAlign:'top'

            },
            "& p":{
                overflow:'hidden',
                textOverflow:'ellipsis',
                whiteSpace:'nowrap',
            },
        },
        balanceRow:{
            color:'red'
        },
        select1: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '@media only screen and (max-width: 1200px) and (min-width: 601px)': {
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '62%',
            },
            '@media only screen and (max-width: 600px)':  {
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
            },
        },
        moreBtn: {
            width: '100px',
            "&:hover": {
                backgroundColor: 'transparent',
                color: 'green',
            },
            '@media only screen and (max-width: 1200px)':  {
                marginLeft: '-20px',
            }
        },

        selectBtn: {
          width: '100px',
          "&:hover": {
            backgroundColor: 'transparent',
            color: 'green',
          },
        },

        select1_1: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '@media only screen and (max-width: 1200px)':  {
                flexDirection: 'column',
                width: '100%',
                alignItems: 'flex-start',
            },
        },
        select1_2: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '@media only screen and (max-width: 1200px)':  {
                marginTop: '5px',
            }
        },
        select1_6: {
            width: '30%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: '10px',
            marginRight: '10px',
            '@media only screen and (max-width: 1200px)':  {
                marginTop: '5px',
                width: '100%',
                marginLeft: 'unset',
                marginRight: 'unset',
            }
        },
        select1_3: {
            verticalAlign:'middle',
            flexGrow: 1,
            marginLeft: '10px',
            marginRight: '10px',
            '@media only screen and (max-width: 1200px) and (min-width: 601px)':  {
                marginLeft: 'unset',
                marginRight: 'unset',
                width: '62%',
                marginTop: '5px',
            },
            '@media only screen and (max-width: 600px)':  {
                marginLeft: 'unset',
                marginRight: 'unset',
                marginTop: '5px',
                width: '100%',
            },
        },
        select1_4: {
            verticalAlign:'middle',
            width:'10%',
            '@media only screen and (max-width: 1200px)':  {
                marginLeft: 'unset',
                marginRight: 'unset',
                width: '100%',
                marginTop: '5px',
            },
        },
        select1_5: {
            verticalAlign:'middle',
            width:'10%',
            marginLeft: '10px',
            '@media only screen and (max-width: 1200px)':  {
                marginLeft: 'unset',
                marginRight: 'unset',
                width: '100%',
                marginTop: '5px',
            },
        },
        select2: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '20px',
            '@media only screen and (max-width: 1200px) and (min-width: 601px)':  {
                flexDirection: 'column',
                width: '62%',
                alignItems: 'flex-start',
            },
            '@media only screen and (max-width: 600px)':  {
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
            }
        },
        select2_1: {
            '@media only screen and (max-width: 1200px) and (min-width: 601px)':  {
                marginTop: '5px',
            }
        }

    }));
    const classes = useStyles();

    useEffect(() => {
        if (currentOrg && application) {
            if (new Date(OrgRoleReader.getOrgExpiredDateById(currentOrg, application)) < new Date()) {
                setOrgExpired(true)
                // setOrgExpired(false);
            } else {
                setSelPOLines([]);
                accountingService.getPOLinesByOrgId(currentOrg,
                  moment(startDate).format('yyyy/MM/DD'),
                  moment(endDate).format('yyyy/MM/DD'),
                  currentCustomer=='all'? '' :currentCustomer,
                    99999, 0).then((result) => {
                    if (result.data && result.data.datas.length >= 0) {
                        let temp = result.data.datas;
                        // result.data.showColumns;
                        if(temp && temp.length > 0) {
                            temp.forEach(item=> {
                                item.invoiceNumbers = !item.invoiceData? []: item.invoiceData.map(invoice=>{
                                    return invoice.invoiceNumber
                                })
                                item.invoiceNumbers = item.invoiceNumbers.join(',')
                            })
                        }
                        setPoLinesOld(temp);
                        filterPoLines(temp);
                        if (result.data.showColumns.length > 0) {
                          let columnsSelection = result.data.showColumns[0]
                          if (columnsSelection === 'all') {
                            setOriginalColumns(Array.from({length: dynamicColumns.length}, () => 1));
                          } else {
                            let tmp2 = dynamicColumns.map((_, index)=> {
                                return index < columnsSelection.length? parseInt(columnsSelection[index]): 1;
                            })
                            setOriginalColumns(tmp2);
                          }
                        }
                    }
                });

            }
        }
    }, [currentOrg, application, startDate, endDate, currentCustomer]);

    useEffect(() => {
        setSelPOLines([]);
        filterPoLines(poLinesOld);
    }, [invoiced, shipped, startInvoiceDate, endInvoiceDate, startRequestDate, endRequestDate]);

    useEffect(() => {
        filterPoLines(poLinesOld);
    }, [currentPage, sortBy, sortDesc]);

    const filterPoLines = (data) => {
        let temp = [];

        if (invoiced === 'invoiced') {
            data.forEach(item => {
                if (item.status === 'closed') {
                    temp.push(item);
                }
            })
        } else if (invoiced === 'notInvoiced') {
            data.forEach(item => {
                if (item.status !== 'closed') {
                    temp.push(item);
                }
            })
        } else {
            temp = data;
        }

        let invoiceDataArray = [];

        if (temp) {
            if (startInvoiceDate && endInvoiceDate && !moment(startInvoiceDate).isAfter(moment(endInvoiceDate))) {
                temp.forEach(item => {
                    if (item.invoiceData && item.invoiceData.length > 0) {
                        for (let i = 0; i < item.invoiceData.length; i++) {
                            let it1 = item.invoiceData[i];

                            if (moment(it1.invoiceDate).isAfter(moment(startInvoiceDate))
                              && moment(it1.invoiceDate).isBefore(moment(endInvoiceDate))) {
                                invoiceDataArray.push(item);
                                break;
                            }
                        }
                    }
                })
            } else {
                invoiceDataArray = temp;
            }
        }

        let requestDataArray = [];

        if (temp) {

            if (startRequestDate && endRequestDate && !moment(startRequestDate).isAfter(moment(endRequestDate))) {
                temp.forEach(item => {
                    if (item.shippingData && item.shippingData.length > 0) {
                        for (let i = 0; i < item.shippingData.length; i++) {
                            let it1 = item.shippingData[i];

                            if (moment(it1.declarationDate).isAfter(moment(startRequestDate))
                              && moment(it1.declarationDate).isBefore(moment(endRequestDate))) {
                                requestDataArray.push(item);
                                break;
                            }
                        }

                    }
                })
            } else {
                requestDataArray = temp;
            }

        }

        temp = invoiceDataArray.filter((v) => requestDataArray.includes(v));

        let temp1 = [];
        if (shipped === 'shipped') {
            temp.forEach(item => {
                if (parseInt(item.shippingOutstanding) <= 0) {
                    temp1.push(item);
                }
            })
        } else if (shipped === 'notShipped') {
            temp.forEach(item => {
                if (parseInt(item.shippingOutstanding) > 0) {
                    temp1.push(item);
                }
            })
        } else {
            temp1 = temp;
        }

        setPages(Math.ceil(temp1.length / pageSize));
        if (sortBy === '') {
            temp1.sort(defaultSort);
        } else if (sortBy === 'itemNumber') {
            temp1.sort(sortByItemNumber);
        } else if (sortBy === 'poNumber') {
            temp1.sort(sortByPoNumber);
        } else if (sortBy === 'lineDate') {
            temp1.sort(defaultSort);
        } else if (sortBy === 'shippingDate') {
            temp1.sort(sortByShippingDate);
        } else if (sortBy === 'invoiceDate') {
            temp1.sort(sortByInvoiceDate);
        } else if (sortBy === 'invoiceNumber') {
            temp1.sort(sortByInvoiceNumber);
        }
        setPoLinesAllPages(temp1);
        let temp2 = temp1.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);
        setPoLines(temp2);
    }

    const customerChangeHandler = (value) =>{
        // setBookStatus();
        setCurrentPage(1);
        setSearchKeyWords("");
        setCurrentCustomer(value);
    }

    const pageChangeHandler =(event, value)=>{
        setCurrentPage(value);
    }

    function sheet2blob(sheet, sheetName) {
        sheetName = sheetName || 'sheet1';
        const workbook = {
            SheetNames: [sheetName],
            Sheets: {}
        };
        workbook.Sheets[sheetName] = sheet; // 生成excel的配置项

        const wopts = {
            bookType: 'xlsx', // 要生成的文件类型
            bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
            type: 'binary'
        };
        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream"
        }); // 字符串转ArrayBuffer
        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        return blob;
    }

    function openDownloadDialog(url, saveName) {
        if (typeof url == 'object' && url instanceof Blob) {
            url = URL.createObjectURL(url); // 创建blob地址
        }
        const aLink = document.createElement('a');
        aLink.href = url;
        aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
        let event;
        if (window.MouseEvent) event = new MouseEvent('click');
        else {
            event = document.createEvent('MouseEvents');
            event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        }
        aLink.dispatchEvent(event);
    }

    function btn_export() {
        if (poLinesAllPages && poLinesAllPages.length >= 0) {
            let tables = [];
            poLinesAllPages.map(item => {
                let record = {};
                record.poNumber = item.poNumber;
                record.customer = item.customer;
                record.lineNumber = item.lineNumber;
                record.productName = item.itemNumber + '/' + item.productName;
                record.storageUnit = item.storageUnit;
                record.qty = { t:"n", v:item.qty, z: "#,##0.0000" };
                record.price = { t:"n", v:item.price, z: "#,##0.0000" };
                record.balance = { t:"n", v:item.lineOutstandingBalance, z: "#,##0.0000" };
                record.date = moment(item.lineDate).format('yyyy/MM/DD');
                record.notes = item.notes;

                let shippingData = [];
                let wayBill = [];
                let exportBill = [];
                let declarationBill = [];
                let declarationDate = [];
                if (item.shippingData && item.shippingData.length > 0) {
                    item.shippingData.map(item => {
                        shippingData.push(moment(item.shippingDate).format('yyyy/MM/DD') + " (" + item.wayBill + ' : ' + item.qty + ")")
                        wayBill.push(item.wayBill);
                        if (item.shippingTrade && item.shippingTrade.length > 0) {
                            item.shippingTrade.map(billValue => {
                                if (billValue.exportBill) {
                                    exportBill.push(billValue.exportBill);
                                    declarationBill.push(billValue.declarationBill);
                                    declarationDate.push(moment(billValue.declarationDate).format('yyyy/MM/DD'));
                                }
                            });
                        }
                    });
                }
                record.shippingData = shippingData.toString();
                record.date = moment(item.lineDate).format('yyyy/MM/DD');
                record.alarmDate = item.alarmDate ? moment(item.alarmDate).format('yyyy/MM/DD') : "";
                record.alarmNotes = item.alarmNotes;
                record.alarmOn = moment(item.alarmDate) < moment() && !item.invoiceData ? t('poFormPage.alarmTrue') : t('poFormPage.alarmFalse');
                let invoiceData = [];
                if (item.invoiceData && item.invoiceData.length > 0) {
                    item.invoiceData.forEach(item => {
                        if (item.invoiceNumber) {
                            invoiceData.push(moment(item.invoiceDate).format('yyyy/MM/DD') + "(" + item.invoiceNumber + ")");
                        }
                    })
                }
                record.actualDate = invoiceData.toString();
                record.wayBill = wayBill.toString();
                record.exportBill = exportBill.toString();
                record.declarationBill = declarationBill.toString();
                record.declarationDate = declarationDate.toString();
                tables.push(record);
            })
            const header = {
                poNumber: t('poFormPage.poNumber'),
                customer: t('poFormPage.customer'),
                lineNumber: t('poFormPage.line'),
                productName: t('poFormPage.item') + '/' + t('poFormPage.product'),
                storageUnit: t('poFormPage.storageUnit'),
                qty: t('poFormPage.QTY'),
                price: t('poFormPage.price'),
                balance: t('poLineManagement.balance'),
                date: t('poLineManagement.date'),
                notes: t('poFormPage.notes'),
                shippingData: t('poLineManagement.shipDate'),
                alarmDate: t('poFormPage.alarmDate'),
                alarmNotes: t('poFormPage.alarmNotes'),
                alarmOn: t('poFormPage.alarmOn'),
                actualDate: t('poLineManagement.actualDate'),
                wayBill: t('shippingPage.shippingNumber'),
                exportBill: t('shippingPage.exportContractNo'),
                declarationBill: t('shippingPage.baoguanNo'),
                declarationDate: t('shippingPage.baoguanDate'),
            };
            const data = [header, ...tables]
            const sheet = XLSX.utils.json_to_sheet(data, {skipHeader: true});   // 将一个table对象转换成一个sheet对象
            openDownloadDialog(sheet2blob(sheet), 'PO Line Management.xlsx');
        }
    }

  const editPOLine = (item) => {
    return () => {
      setNoteOpen(true);
      setNoteValue(item.notes);
      setPOLine(item);
    }
  }

  const checkLineNotification = (item) => {
    return () => {
        setNotificationOpen(true);
        setPOLine(item);
    }
  }

  const handleNoteClose = () => {
    setNoteOpen(false);
  }

  const handleNotificationClose = () => {
      setNotificationOpen(false);
  }

  const handleNotesChange = (e) => {
    setNoteValue(e.target.value);
  }

  const saveNotes = () => {
    accountingService.editPONote(poLine.lineId, {
      notes: noteValue,
    }).then((result) => {
      poLine.notes = noteValue;
      setNoteOpen(false);
    });
  }

  const readPOLineNotifications = () => {
    let notifyIds = poLine.notifyData.map((column) => {
      return column.notifyId;
    });

    accountingService.readPOLineNotifications({
      'notifyIds': notifyIds,
    }).then((_) => {
      // poLine.notes = noteValue;
      poLine.notifyData = null;
      setNotificationOpen(false);
    });

  }

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  }

  const handleSettingsOpen = () => {
    setSettingsOpen(true);
  }

  const saveSettings = (data) => {
      setOriginalColumns(data);
      setSettingsOpen(false);
  }

    const tableStyle = () => {
        let width = 0;
        originalColumns.forEach((column) => {
            if (column === 1) {
                width += 100;
            }
        });

        return {
            minWidth: width + 'px',
        };
    }

    const issueShipping = () => {
        let valid = true;
        if (selPOLines.length === 0) {
            valid = false;
            setErrorTips('errorTipsNoLineSelected');
        } else {
            selPOLines.map(item => {
                if (parseInt(item.shippingOutstanding) <= 0) {
                    valid = false;
                    setErrorTips('errorTipsAlreadyShpped');
                }
                if (!item.permissions.includes('add_shipping')) {
                    valid = false;
                    setErrorTips('errorTipsNoPermission');
                }
            })
        }
        if (valid) {
            const issueShippURL = '/PO Transactions/Shipping/batchAdd';
            history.push({
                pathname: issueShippURL,
                state: {"selectedLines": selPOLines},
            });
        } else {
            setWarningOpen(true);
        }
    }

    const report = () => {
        if (selPOLines.length === 0) {
            setErrorTips('errorTipsNoLineSelected');
            setWarningOpen(true);
            return;
        }

        let filename = '';
        let poLineIds = '';
        selPOLines.map((item, index) => {
            if (index < selPOLines.length - 1) {
                poLineIds += item.lineId + ',';
            } else {
                poLineIds += item.lineId;
            }

            if (index === 0) {
                if (item.shippingData && item.shippingData.length > 0) {
                    filename = `报关`;
                } else if (item.invoiceData && item.invoiceData.length > 0) {
                    filename = `发票`;
                }
            }

        });

        accountingService.reflineDownload(currentOrg, poLineIds).then(result => {

            let types = 'application/vnd.ms-excel';
            if (filename) {
                filename = filename + `${moment().format('YYYYMMDD')}.xlsx`;
            } else {
                filename = `${moment().format('YYYYMMDD')}.xlsx`;
            }

            let blob = new Blob([result.data], {type: types});
            downloadFileUtils.downLoadFile(blob, filename);
        });

    }

    const issuePayment = () => {
        let valid = true;
        if (selPOLines.length === 0) {
            valid = false;
            setErrorTips('errorTipsNoLineSelected');
        } else {
            let currency = [];
            selPOLines.map(item => {
                if (item.status === 'closed') {
                    valid = false;
                    setErrorTips('errorTipsAlreadyClosed');
                }
                if (!item.permissions.includes('add_payment')) {
                    valid = false;
                    setErrorTips('errorTipsNoPermission');
                }
                currency.push(item.currency);
            })
            if (!isAllEqual(currency)) {
                valid = false;
                setErrorTips('errorTipsNotSameCurrency');
            }
        }
        if (valid) {
            const url = '/PO Transactions/Payment/batchAdd';
            history.push({
                pathname: url,
                state: {"selectedLines": selPOLines},
            });
        } else {
            setWarningOpen(true);
        }

    }

    const issueInvoice = () => {
        let valid = true;
        if (selPOLines.length === 0) {
            valid = false;
            setErrorTips('errorTipsNoLineSelected');
        } else {
            let customerNameList = [];
            let supplierNameList = [];
            let sTaxIdList = [];
            let pTaxIdList = [];
            let currency = [];
            selPOLines.map(item => {
                if (item.status === 'closed') {
                    valid = false;
                    setErrorTips('errorTipsAlreadyClosed');
                }
                if (item.lineOutstanding === '0.0000') {
                    valid = false;
                    setErrorTips('errorTipsAlreadyChecked');
                }
                if (!item.permissions.includes('new_invoice_line')) {
                    valid = false;
                    setErrorTips('errorTipsNoPermission');
                }
                customerNameList.push(item.customer);
                supplierNameList.push(item.supplierName);
                if (item.supplierTaxId) {
                    sTaxIdList.push(item.supplierTaxId);
                }
                if (item.purchaserTaxId) {
                    pTaxIdList.push(item.purchaserTaxId);
                }
                currency.push(item.currency);
            })
            if (!isAllEqual(customerNameList)) {
                valid = false;
                setErrorTips('errorTipsNotSameCustomer');
            }
            if (!isAllEqual(supplierNameList)) {
                valid = false;
                setErrorTips('errorTipsNotSameSupplier');
            }
            if (!isAllEqual(sTaxIdList)) {
                valid = false;
                setErrorTips('errorTipsNotSameSupplierTax');
            }
            if (!isAllEqual(pTaxIdList)) {
                valid = false;
                setErrorTips('errorTipsNotSamePurchaserTax');
            }
            if (!isAllEqual(currency)) {
                valid = false;
                setErrorTips('errorTipsNotSameCurrency');
            }
        }
        if (valid) {
            const issueShippURL = '/PO Transactions/Invoice/batchAdd';
            selPOLines.map(item=>{
              item.checked = null;
            });
            history.push({
                pathname: issueShippURL,
                state: {"selectedLines": selPOLines},
            });
        } else {
            setWarningOpen(true);
        }

    }

    function isAllEqual(array) {
        if (array.length > 0) {
          return !array.some(function (value) {
              return value !== array[0];
          });
        } else {
          return true;
        }
    }

    const handlerwarningOpenClose = () =>{
        setWarningOpen(false);
    }

    function defaultSort(a,b){
        if ( moment(a.lineDate).format('YYYY-MM-DD') !== moment(b.lineDate).format('YYYY-MM-DD')) return moment(a.lineDate).format('YYYY-MM-DD') < moment(b.lineDate).format('YYYY-MM-DD') ? ((sortDesc === 'up')?-1:1) : ((sortDesc === 'up')?1:-1)
        else if (a.itemNumber !== b.itemNumber) return a.itemNumber < b.itemNumber ? -1 : 1
        else if (a.poNumber !== b.poNumber) return a.poNumber < b.poNumber ? -1 : 1
        else if (a.lineNumber !== b.lineNumber) return a.lineNumber < b.lineNumber ? -1 : 1
    }

    function sortByPoNumber(a,b){
        if (a.poNumber !== b.poNumber) return a.poNumber < b.poNumber ? ((sortDesc === 'up')? -1 : 1) : ((sortDesc === 'up')? 1 : -1)
        else if (a.lineNumber !== b.lineNumber) return a.lineNumber < b.lineNumber ? -1 : 1
    }

    function sortByItemNumber(a,b){
        if (a.itemNumber !== b.itemNumber) return a.itemNumber < b.itemNumber ? ((sortDesc === 'up')?-1:1) : ((sortDesc === 'up')?1:-1)
        else if ( moment(a.lineDate).format('YYYY-MM-DD') !== moment(b.lineDate).format('YYYY-MM-DD')) return moment(a.lineDate).format('YYYY-MM-DD') < moment(b.lineDate).format('YYYY-MM-DD') ? 1 : -1
        else if (a.poNumber !== b.poNumber) return a.poNumber < b.poNumber ? -1 : 1
        else if (a.lineNumber !== b.lineNumber) return a.lineNumber < b.lineNumber ? -1 : 1
    }

    function sortByShippingDate(a,b){
        let aDate = '1900-01-01';
        let bDate = '1900-01-01';
        if (a.shippingData && a.shippingData.length > 0) {
            aDate = a.shippingData[0].shippingDate;
        }
        if (b.shippingData && b.shippingData.length > 0) {
            bDate = b.shippingData[0].shippingDate;
        }
        if ( moment(aDate).format('YYYY-MM-DD') !== moment(bDate).format('YYYY-MM-DD')) return moment(aDate).format('YYYY-MM-DD') < moment(bDate).format('YYYY-MM-DD') ? ((sortDesc === 'up')? -1 : 1) : ((sortDesc === 'up')? 1 : -1)
        else if (a.itemNumber !== b.itemNumber) return a.itemNumber < b.itemNumber ? -1:1
        else if (a.poNumber !== b.poNumber) return a.poNumber < b.poNumber ? -1 : 1
        else if (a.lineNumber !== b.lineNumber) return a.lineNumber < b.lineNumber ? -1 : 1
    }

    function sortByInvoiceDate(a,b){
        let aDate = '1900-01-01';
        let bDate = '1900-01-01';
        if (a.invoiceData && a.invoiceData.length > 0) {
            aDate = a.invoiceData[0].invoiceDate;
        }
        if (b.invoiceData && b.invoiceData.length > 0) {
            bDate = b.invoiceData[0].invoiceDate;
        }
        if ( moment(aDate).format('YYYY-MM-DD') !== moment(bDate).format('YYYY-MM-DD')) return moment(aDate).format('YYYY-MM-DD') < moment(bDate).format('YYYY-MM-DD') ? ((sortDesc === 'up')? -1 : 1) : ((sortDesc === 'up')? 1 : -1)
        else if (a.itemNumber !== b.itemNumber) return a.itemNumber < b.itemNumber ? -1:1
        else if (a.poNumber !== b.poNumber) return a.poNumber < b.poNumber ? -1 : 1
        else if (a.lineNumber !== b.lineNumber) return a.lineNumber < b.lineNumber ? -1 : 1
    }

    function sortByInvoiceNumber(a,b){
        let aNumber, bNumber = '';
        if (a.invoiceData && a.invoiceData.length > 0) {
            aNumber = a.invoiceNumbers;
        }
        if (b.invoiceData && b.invoiceData.length > 0) {
            bNumber = b.invoiceData[0].invoiceNumbers;
        }
        if ( a.invoiceNumbers !== b.invoiceNumbers) return a.invoiceNumbers < b.invoiceNumbers ? ((sortDesc === 'up')? -1 : 1) : ((sortDesc === 'up')? 1 : -1)
        else if (a.itemNumber !== b.itemNumber) return a.itemNumber < b.itemNumber ? -1:1
        else if (a.poNumber !== b.poNumber) return a.poNumber < b.poNumber ? -1 : 1
        else if (a.lineNumber !== b.lineNumber) return a.lineNumber < b.lineNumber ? -1 : 1
    }

    const handleItemNumberSort = () => {
        setSortBy('itemNumber');
        setupSort();
    }

    const handlePONumberSort = () => {
        setSortBy('poNumber');
        setupSort();
    }

    const handleLineDateSort = () => {
        setSortBy('lineDate');
        setupSort();
    }

    const handleShippingDateSort = () => {
        setSortBy('shippingDate');
        setupSort();
    }

    const handleInvoiceDateSort = () => {
        setSortBy('invoiceDate');
        setupSort();
    }

    const handleInvoiceNumberSort = () => {
        setSortBy('invoiceNumber');
        setupSort();
    }

    const setupSort = () => {
        if (sortDesc === "") {
            setSortDesc("up");
        }
        if (sortDesc === "up") {
            setSortDesc("down");
        }
        if (sortDesc === "down") {
            setSortDesc("up");
        }
    }

    const SelectComponent = () => {
        return (
          <div style={{display: 'flex', flexDirection: 'column'}}>

             <div className={classes.select1}>

                 <div className={classes.select1_1}>
                     <div style={{minWidth: t('poManagementHomepage.bookingDateWidth')}}>
                         <Typography variant="body1">{t('poManagementHomepage.bookingDate')}:</Typography>
                     </div>
                     <div className={classes.select1_2}>
                         <MuiPickersUtilsProvider utils={MomentUtils}>
                             <KeyboardDatePicker
                               autoOk
                               id="date"
                               variant="inline"
                               inputVariant="outlined"
                               size="small"
                               format="yyyy/MM/DD"
                               InputAdornmentProps
                               value={startDate}
                               onChange={date => setStartDate(date)}
                               className={classes.dataPicker}
                             />
                         </MuiPickersUtilsProvider>
                         <span>&nbsp;-&nbsp;</span>
                         <MuiPickersUtilsProvider utils={MomentUtils}>
                             <KeyboardDatePicker
                               autoOk
                               id="date"
                               variant="inline"
                               inputVariant="outlined"
                               size="small"
                               format="yyyy/MM/DD"
                               InputAdornmentProps
                               value={endDate}
                               onChange={date => setEndDate(date)}
                               className={classes.dataPicker}
                             />
                         </MuiPickersUtilsProvider>
                     </div>

                 </div>

                 <div className={classes.select1_6}>
                     <CustomerSelector org={currentOrg} customer={currentCustomer}
                                       onValueChange={customerChangeHandler} />
                 </div>

                 <Select
                   value={invoiced}
                   variant="outlined"
                   color="primary"
                   fontSize="small"
                   className={classes.select1_4}
                   classes={{ select: classes.outlinedPadding }}
                   onChange={e => setInvoiced(e.target.value)}
                 >
                     <MenuItem key={0} value={'all'}>{t('poLineManagement.all')}</MenuItem>
                     <MenuItem key={1} value={'invoiced'}>{t('poLineManagement.invoiced')}</MenuItem>
                     <MenuItem key={2} value={'notInvoiced'}>{t('poLineManagement.notInvoiced')}</MenuItem>
                 </Select>

                 <Select
                   value={shipped}
                   variant="outlined"
                   color="primary"
                   fontSize="small"
                   classes={{ select: classes.outlinedPadding }}
                   onChange={e => setShipped(e.target.value)}
                   className={classes.select1_5}
                 >
                     <MenuItem key={0} value={'all'}>{t('poLineManagement.all')}</MenuItem>
                     <MenuItem key={1} value={'shipped'}>{t('poLineManagement.shipped')}</MenuItem>
                     <MenuItem key={2} value={'notShipped'}>{t('poLineManagement.notShipped')}</MenuItem>
                 </Select>


                 <Button className={classes.moreBtn} onClick={() => setSelectOpen(!selectOpen)}>
                     <Typography variant="body1" style={{fontSize: '14px'}}>
                         {selectOpen ? t('poLineManagement.collapse') : t('poLineManagement.expand') }
                     </Typography>
                     {selectOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                 </Button>

             </div>

              <Collapse in={selectOpen} timeout="auto" unmountOnExit>
                  {
                    selectOpen &&
                    <div className={classes.select2}>

                        <div style={{minWidth: t('poManagementHomepage.invoiceDateWidth')}}>
                            <Typography variant="body1">{t('poManagementHomepage.invoiceDate')}:</Typography>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  autoOk
                                  id="date"
                                  variant="inline"
                                  inputVariant="outlined"
                                  size="small"
                                  format="yyyy/MM/DD"
                                  InputAdornmentProps
                                  value={startInvoiceDate}
                                  onChange={date => setStartInvoiceDate(date)}
                                  className={classes.dataPicker}
                                />
                            </MuiPickersUtilsProvider>
                            <span>&nbsp;-&nbsp;</span>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  autoOk
                                  id="date"
                                  variant="inline"
                                  inputVariant="outlined"
                                  size="small"
                                  format="yyyy/MM/DD"
                                  InputAdornmentProps
                                  value={endInvoiceDate}
                                  onChange={date => setEndInvoiceDate(date)}
                                  className={classes.dataPicker}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className={classes.select2_1} style={{minWidth: t('poManagementHomepage.invoiceDateWidth')}}>
                            <Typography variant="body1">{t('poManagementHomepage.requestDate')}:</Typography>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  autoOk
                                  id="date"
                                  variant="inline"
                                  inputVariant="outlined"
                                  size="small"
                                  format="yyyy/MM/DD"
                                  InputAdornmentProps
                                  value={startRequestDate}
                                  onChange={date => setStartRequestDate(date)}
                                  className={classes.dataPicker}
                                />
                            </MuiPickersUtilsProvider>
                            <span>&nbsp;-&nbsp;</span>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  autoOk
                                  id="date"
                                  variant="inline"
                                  inputVariant="outlined"
                                  size="small"
                                  format="yyyy/MM/DD"
                                  InputAdornmentProps
                                  value={endRequestDate}
                                  onChange={date => setEndRequestDate(date)}
                                  className={classes.dataPicker}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                    </div>
                  }
              </Collapse>

          </div>
        );
    }

  const downloadAttachment = (attachments) => {

    accountingService.downloadAttachment(attachments[0].fileId).then((result) => {

      let blob = new Blob([result.data], );
      downloadFileUtils.downLoadFile(blob, attachments[0].fileName);

    });

  }

    return (
        <CommonLayout>
            <div className={classes.box}>
                <OrganizationSelect onSetIniting={setIniting} onSetOpenCreateOrg={setOpenCreateOrg} onSetCurrentOrg={setCurrentOrg} onSetOrgExpired={setOrgExpired} initing={initing} openCreateOrg={openCreateOrg} currentOrg={currentOrg} orgExpired={orgExpired}></OrganizationSelect>
                {
                    (!initing && (openCreateOrg || !currentOrg)) &&
                    <Grid>
                        <div style={{padding:'20px'}}>
                            <Typography variant="body1" style={{marginBottom:"20px"}}>
                                {t('poManagementHomepage.createOrgTips')}
                            </Typography>
                            <Grid container spacing={4}>
                                <Grid item md={8} xs={12}>
                                    <img src={openbookDescriptionPic1} srcSet={`${openbookDescriptionPic1} 300w, ${openbookDescriptionPic2} 768w, ${openbookDescriptionPic3} 1280w`} style={{width:"100%",objectFit:"contain"}}></img>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography variant="body1" style={{color:"#818181"}}>
                                        <b style={{fontFamily:"Lato-Black"}}>{t('poManagementHomepage.description1')}</b> {t('poManagementHomepage.description2')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>

                    </Grid>
                }
                {(!initing && !openCreateOrg && currentOrg)&&<Grid container spacing={4} justifyContent="center" direction="row" alignItems="flex-start">
                    <Grid xs={12} item className={classes.root}>

                        {SelectComponent()}

                        <Grid xs={12} item>
                            <Grid container  justifyContent="flex-end">
                                <IconButton onClick={issueInvoice} disabled={!canStatement}>
                                    <Tooltip title={t('poLineManagement.issueInvoice')}>
                                        <ReceiptIcon style={canStatement?{color:'green'}:{}}/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton onClick={issueShipping} disabled={!canShip}>
                                    <Tooltip title={t('poLineManagement.issueShipping')}>
                                        <LocalShippingIcon style={canShip?{color:'green'}:{}}></LocalShippingIcon>
                                    </Tooltip>
                                </IconButton>
                                <IconButton onClick={issuePayment} disabled={!canPay}>
                                    <Tooltip title={t('poLineManagement.issuePayment')}>
                                        <PaymentIcon style={canPay?{color:'green'}:{}}></PaymentIcon>
                                    </Tooltip>
                                </IconButton>
                                <IconButton onClick={btn_export}>
                                    <Tooltip title={t('common.download')}>
                                        <GetAppIcon style={{color:'green'}}></GetAppIcon>
                                    </Tooltip>
                                </IconButton>
                                <IconButton onClick={report}>
                                    <Tooltip title={t('poLineManagement.taxReconciliation')}>
                                        <AssessmentIcon style={{color:'green'}} />
                                    </Tooltip>
                                </IconButton>
                                <IconButton onClick={handleSettingsOpen}>
                                    <Tooltip title={t('common.settings')}>
                                        <SettingsIcon style={{color:'green'}} />
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid container style={{overflowX:'auto'}}>
                            <Grid item spacing={2} style={tableStyle()}>
                                <table id="table1" width="100%" className={classes.balanceTable}>
                                    <thead>
                                    <tr style={{textAlign:'left',height:'30px'}}>
                                        <th width='8%'></th>
                                        <th width="10%"><a href="#" onClick={()=>handlePONumberSort()}><Typography variant="body1">{t('poFormPage.poNumber')} {(sortDesc==="up"&&sortBy==='poNumber')&&<ArrowUpwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowUpwardIcon>}{(sortDesc==="down"&&sortBy==='poNumber')&&<ArrowDownwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowDownwardIcon>}</Typography></a></th>
                                      {
                                        originalColumns[1] === 1
                                        &&
                                        <th width="10%"><Typography variant="body1">{t('poFormPage.customer')}</Typography></th>
                                      }

                                      {
                                        originalColumns[2] === 1
                                        &&
                                        <th width="5%"><Typography variant="body1">{t('poFormPage.line')}</Typography></th>
                                      }

                                      {
                                        originalColumns[3] === 1
                                        &&
                                        <th width="10%"><a href="#" onClick={()=>handleItemNumberSort()}><Typography variant="body1">{t('poFormPage.item')+'/'+t('poFormPage.product')} {(sortDesc==="up"&&sortBy==='itemNumber')&&<ArrowUpwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowUpwardIcon>}{(sortDesc==="down"&&sortBy==='itemNumber')&&<ArrowDownwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowDownwardIcon>}</Typography></a></th>
                                      }

                                      {
                                        originalColumns[4] === 1
                                        &&
                                        <th width="7%"><Typography variant="body1">{t('poFormPage.storageUnit')}</Typography></th>
                                      }

                                      {
                                        originalColumns[5] === 1
                                        &&
                                        <th width="7%"><Typography variant="body1">{t('poFormPage.QTY')}</Typography></th>
                                      }

                                      {
                                        originalColumns[6] === 1
                                        &&
                                        <th width="6%"><Typography variant="body1">{t('poFormPage.price')}</Typography></th>
                                      }

                                      {
                                        originalColumns[7] === 1
                                        &&
                                        <th width="6%"><Typography variant="body1">{t('poLineManagement.balance')}</Typography></th>
                                      }

                                      {
                                        originalColumns[8] === 1
                                        &&
                                        <th width="6%"><a href="#" onClick={()=>handleLineDateSort()}><Typography variant="body1">{t('poLineManagement.date')} {(sortDesc==="up"&&sortBy==='lineDate')&&<ArrowUpwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowUpwardIcon>}{(sortDesc==="down"&&sortBy==='lineDate')&&<ArrowDownwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowDownwardIcon>}</Typography></a></th>
                                      }

                                      {
                                        originalColumns[9] === 1
                                        &&
                                        <th width="6%"><Typography variant="body1">{t('poFormPage.notes')}</Typography></th>
                                      }

                                      {
                                        originalColumns[10] === 1
                                        &&
                                        <th width="6%"><a href="#" onClick={()=>handleShippingDateSort()}><Typography variant="body1">{t('poLineManagement.shipDate')} {(sortDesc==="up"&&sortBy==='shippingDate')&&<ArrowUpwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowUpwardIcon>}{(sortDesc==="down"&&sortBy==='shippingDate')&&<ArrowDownwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowDownwardIcon>}</Typography></a></th>
                                      }

                                      {
                                        originalColumns[11] === 1
                                        &&
                                        <th width="6%"><a href="#" onClick={()=>handleInvoiceDateSort()}><Typography variant="body1">{t('poLineManagement.actualDate')} {(sortDesc==="up"&&sortBy==='invoiceDate')&&<ArrowUpwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowUpwardIcon>}{(sortDesc==="down"&&sortBy==='invoiceDate')&&<ArrowDownwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowDownwardIcon>}</Typography></a></th>
                                      }

                                      {
                                        originalColumns[12] === 1
                                        &&
                                        <th width="6%"><Typography variant="body1">{t('poFormPage.alarmDate')}</Typography></th>
                                      }

                                        {
                                            originalColumns[17] === 1
                                            &&
                                            <th width="6%"><a href="#" onClick={()=>handleInvoiceNumberSort()}><Typography variant="body1">{t('shippingPage.invoiceNumber')} {(sortDesc==="up"&&sortBy==='invoiceNumber')&&<ArrowUpwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowUpwardIcon>}{(sortDesc==="down"&&sortBy==='invoiceNumber')&&<ArrowDownwardIcon style={{verticalAlign:'middle'}} fontSize="small"></ArrowDownwardIcon>}</Typography></a></th>
                                        }
                                      {
                                        originalColumns[13] === 1
                                        &&
                                        <th width="6%"><Typography variant="body1">{t('shippingPage.shippingNumber')}</Typography></th>
                                      }

                                      {
                                        originalColumns[14] === 1
                                        &&
                                        <th width="6%"><Typography variant="body1">{t('shippingPage.exportContractNo')}</Typography></th>
                                      }

                                      {
                                        originalColumns[15] === 1
                                        &&
                                        <th width="6%"><Typography variant="body1">{t('shippingPage.baoguanNo')}</Typography></th>
                                      }

                                      {
                                        originalColumns[16] === 1
                                        &&
                                        <th width="6%"><Typography variant="body1">{t('shippingPage.baoguanDate')}</Typography></th>
                                      }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {poLines&&poLines.length>0&&poLines.map((item,index) =>{
                                        return(
                                            <tr key={'poline' + index}
                                                className={item.alarmDate && moment(item.alarmDate) < moment() && !item.invoiceData ? classes.balanceRow : ''}>
                                                <td>
                                                  <Checkbox disabled={!(item.permissions.includes('new_invoice_line')||item.permissions.includes('add_payment')||item.permissions.includes('add_shipping'))} checked={selPOLines.findIndex(item1=>item1.lineId===item.lineId)>=0} onChange={e=>{
                                                    let temp = selPOLines;
                                                    if (e.target.checked) {
                                                        temp.push(item)
                                                    } else {
                                                        let find = temp.findIndex(item1 => item1.lineId === item.lineId);
                                                        temp.splice(find,1);
                                                    }
                                                    let temp1 = poLines;
                                                    let temp2 = [];
                                                    setCanPay(true);
                                                    setCanShip(true);
                                                    setCanStatement(true);
                                                    temp1.map(item3 => {
                                                        let tempLine = item3;
                                                        tempLine.checked = temp.find(item2 => item2.lineId === item3.lineId);
                                                        temp2.push(tempLine);
                                                    });
                                                    setSelPOLines(temp);
                                                    temp.map(item => {
                                                        if (!item.permissions.includes('add_shipping') || parseInt(item.shippingOutstanding) <= 0) {
                                                            setCanShip(false);
                                                        }
                                                        if (!item.permissions.includes('new_invoice_line') || item.status === 'closed') {
                                                            setCanStatement(false);
                                                        }
                                                        if (!item.permissions.includes('add_payment') || item.status === 'closed') {
                                                            setCanPay(false);
                                                        }
                                                    });
                                                    setPoLines(temp2);
                                                }}/>
                                                  <IconButton onClick={editPOLine(item)}>
                                                    <Tooltip title={t('common.edit')}>
                                                      <EditIcon />
                                                    </Tooltip>
                                                  </IconButton>

                                                    {
                                                      item.notifyData &&
                                                      <IconButton onClick={checkLineNotification(item)}>
                                                          <Tooltip title={t('common.review_notifications')}>
                                                              <NotificationImportantIcon style={{color: 'red'}}/>
                                                          </Tooltip>
                                                      </IconButton>
                                                    }


                                                </td>
                                                <td><Typography variant="body2" title={item.poNumber}>{item.poNumber}</Typography></td>
                                              {
                                                originalColumns[1] === 1
                                                &&
                                                <td><Typography variant="body2" title={item.customer}>{item.customer}</Typography></td>
                                              }
                                              {
                                                originalColumns[2] === 1
                                                &&
                                                <td><Typography variant="body2" title={item.lineNumber}>{item.lineNumber}</Typography></td>
                                              }
                                              {
                                                originalColumns[3] === 1
                                                &&
                                                <td><Typography variant="body2" title={item.itemNumber+'/'+item.productName}>{item.itemNumber+'/'+item.productName}</Typography></td>
                                              }
                                              {
                                                originalColumns[4] === 1
                                                &&
                                                <td><Typography variant="body2" title={item.storageUnit}>{item.storageUnit}</Typography></td>
                                              }
                                              {
                                                originalColumns[5] === 1
                                                &&
                                                <td><Typography variant="body2" title={item.qty}>{item.qty}</Typography></td>
                                              }
                                              {
                                                originalColumns[6] === 1
                                                &&
                                                <td><Typography variant="body2" title={item.price}>{item.price}</Typography></td>
                                              }
                                              {
                                                originalColumns[7] === 1
                                                &&
                                                <td><Typography variant="body2" title={item.lineOutstandingBalance}>{item.lineOutstandingBalance}</Typography></td>
                                              }
                                              {
                                                originalColumns[8] === 1
                                                &&
                                                <td><Typography variant="body2" title={moment(item.lineDate).format('yyyy/MM/DD')}>{moment(item.lineDate).format('yyyy/MM/DD')}</Typography></td>
                                              }
                                              {
                                                originalColumns[9] === 1
                                                &&
                                                <td><Typography variant="body2" title={item.notes}>{item.notes}</Typography></td>
                                              }
                                              {
                                                originalColumns[10] === 1
                                                &&
                                                <td>
                                                    {item.shippingData&&item.shippingData.length>0&&item.shippingData.map((item)=>{
                                                        return(
                                                            <Tooltip title={'Shipping Number: '+ item.wayBill + ' QTY: ' + item.qty}>
                                                                <Typography variant="body2">{moment(item.shippingDate).format('yyyy/MM/DD')}</Typography>
                                                            </Tooltip>
                                                        )
                                                    })}
                                                </td>
                                              }
                                              {
                                                originalColumns[11] === 1
                                                &&
                                                <td>
                                                    {item.invoiceData&&item.invoiceData.length>0&&item.invoiceData.map((item,index)=>{
                                                        if(item.invoiceNumber){
                                                            return(
                                                                <Tooltip title={'Invoice Number: '+item.invoiceNumber}>
                                                                    <Typography variant="body2">{moment(item.invoiceDate).format('yyyy/MM/DD')}</Typography>
                                                                </Tooltip>
                                                            )
                                                        }
                                                    })}
                                                </td>
                                              }
                                              {
                                                originalColumns[12] === 1
                                                &&
                                                <td><Typography variant="body2" title={item.alarmNotes}>{item.alarmDate?moment(item.alarmDate).format('yyyy/MM/DD'):""}</Typography></td>
                                              }
                                                {
                                                    originalColumns[17] === 1
                                                    &&
                                                    <td><Typography variant="body2">{item.invoiceNumbers}</Typography></td>
                                                }
                                              {
                                                originalColumns[13] === 1
                                                &&
                                                <td>
                                                  {item.shippingData && item.shippingData.length > 0 && item.shippingData.map((item, index) => {

                                                    if (item.attachments && item.attachments[0].fileName) {
                                                      return (
                                                        <Tooltip title={t('common.downloadAttachment') + ': ' + item.attachments[0].fileName}>
                                                          <a href="#" onClick={() => downloadAttachment(item.attachments)}>
                                                            <Typography variant="body2" style={{color: 'green', fontWeight: 'bold'}}>{item.wayBill}</Typography>
                                                          </a>
                                                        </Tooltip>
                                                      );
                                                    } else {
                                                      return (
                                                        <Typography variant="body2">{item.wayBill}</Typography>
                                                      );
                                                    }

                                                  })}
                                                </td>
                                              }
                                              {
                                                originalColumns[14] === 1
                                                &&
                                                <td>

                                                    {
                                                      item.shippingData && item.shippingData.length > 0 && item.shippingData.map((ship) => {
                                                          return (
                                                            ship.shippingTrade && ship.shippingTrade.length > 0 && ship.shippingTrade.map((trade) => {
                                                                return (
                                                                  <Typography variant="body2">{trade.exportBill}</Typography>
                                                                );
                                                            })
                                                          )
                                                      })

                                                    }

                                                </td>
                                              }
                                              {
                                                originalColumns[15] === 1
                                                &&
                                                <td>
                                                    {
                                                        item.shippingData && item.shippingData.length > 0 && item.shippingData.map((ship) => {
                                                            return (
                                                              ship.shippingTrade && ship.shippingTrade.length > 0 && ship.shippingTrade.map((trade) => {
                                                                  return (
                                                                    <Typography variant="body2">{trade.declarationBill}</Typography>
                                                                  );
                                                              })
                                                            )
                                                      })

                                                    }
                                                </td>
                                              }
                                              {
                                                originalColumns[16] === 1
                                                &&
                                                <td>
                                                    {
                                                      item.shippingData && item.shippingData.length > 0 && item.shippingData.map((ship) => {
                                                          return (
                                                            ship.shippingTrade && ship.shippingTrade.length > 0 && ship.shippingTrade.map((trade) => {
                                                                return (
                                                                  <Typography variant="body2">{trade.declarationDate?moment(trade.declarationDate).format('YYYY-MM-DD'):''}</Typography>
                                                                );
                                                            })
                                                          )
                                                      })
                                                    }

                                                </td>
                                              }
                                            </tr>
                                        )})}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                        <Grid xs={12} item>
                            <Pagination count={pages} shape="rounded" showFirstButton showLastButton onChange={pageChangeHandler}/>
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Dialog open={warningOpen} onClose={handlerwarningOpenClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="body1">
                            {t('poLineManagement.'+ errorTips)}
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handlerwarningOpenClose}>
                            <Typography variant="body2">
                                {t('common.ok')}
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={noteOpen} onClose={handleNoteClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogContent>
                      <Typography variant="body2" style={{fontSize: '18px'}}>{t('poFormPage.po_note')}</Typography>
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        rows={6}
                        variant="outlined"
                        value={noteValue}
                        style={{marginTop: '10px'}}
                        onChange={handleNotesChange}
                      />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={saveNotes}>
                            <Typography variant="body2">
                                {t('common.confirm')}
                            </Typography>
                        </Button>
                        <Button variant="contained" onClick={handleNoteClose}>
                            <Typography variant="body2">
                                {t('common.cancel')}
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={notificationOpen} onClose={handleNotificationClose} maxWidth="sm" fullWidth
                        aria-labelledby="form-dialog-title">
                    <DialogContent>
                        {poLine && poLine.notifyData &&
                          poLine.notifyData.map((column) => {
                              return (
                                <div style={{marginLeft: '20px'}}>
                                  <Typography variant="body2">
                                      <li>
                                        {t('common.add_new_note').replace('$username', column.notifyContent.parameters.fromUser)}
                                      </li>
                                  </Typography>
                                </div>
                              )
                          })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={readPOLineNotifications}>
                            <Typography variant="body2">
                                {t('common.mark_read')}
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>

                {settingsOpen && <ColumnsSelectDialog onSuccess={saveSettings} onCancel={handleSettingsClose}
                                     columns={dynamicColumns} columnSelection={originalColumns}></ColumnsSelectDialog>}

            </div>
        </CommonLayout>
    )
}
