import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Button, FormControl, FormHelperText, OutlinedInput, Slider } from "@material-ui/core";
import useToken from "../../hooks/useToken";
import userService from "../../services/userService";
import LoadingButton from "../../components/LoadingButton";
import AvatarEditor from "react-avatar-editor";
import AddIcon from "@material-ui/icons/Add";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.currentTheme.backgrounds[10],
    border: theme.currentTheme.border[3],
    borderRadius: "8px",
  },
  box: {
    padding: "30px 30px 10px 30px",
    color: theme.currentTheme.colors[0],
  },
  box1: {
    padding: "30px",
  },
  text: {
    color: theme.currentTheme.colors[0],
  },
  text2: {
    color: theme.currentTheme.colors[5],
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "24px",
  },
  buttonProgress: {
    color: "#0e78f9",
    position: "absolute",
    top: "50%",
    left: "0",
    marginTop: -12,
    marginLeft: 5,
  },
  errorMsg: {
    color: "#ff4d4f",
    marginTop: "20px",
  },
  msg: {
    color: "#0e78f9",
    marginTop: "20px",
  },
  input: {
    border: theme.currentTheme.border[3],
    backgroundColor: "#fafbfc",
    color: "black",
    borderRadius: "4px",
  },
  inputErrMsg: {
    color: "#ff4d4f",
  },
  hrColor: {
    border: theme.currentTheme.border[3],
  },
  addBox: {
    width: "100px",
    height: "100px",
    border: "1px dashed gray",
    borderRadius: "50%",
    margin: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: 'pointer',
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarPreviewBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height:'280px',
  },
  adjustBox: {
    width: '200px',
    display: 'flex',
    alignItems: 'center',
  },
  editorBox: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  previewBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  sliderBox: {
    display: 'flex',
    width: '100%',
    paddingLeft: '4px',
  },
  previewImgBox: {
    width: "200px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
  }
  
}));

export default function PersonalProfile() {
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const { token, setToken } = useToken();

  const [avatar, setAvatar] = React.useState();
  useEffect(()=>{
    if(token.user_info.avatar){
      const url = process.env.REACT_APP_CORE_API_BASE + '/public/avatar/'+ token.user_info.avatar;
      setAvatar(url);
    }
  },[token.user_info.avatar])

  const [loading, setLoading] = React.useState(false);


  const [mode, setMode] = React.useState("preview");
  const fileInputRef = React.useRef();
  const editorRef = React.useRef();

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);

      userService
        .updateAvatar(token.username, previewSrc)
        .then((result) => {
          console.log("---> result: ", result);
          token.user_info.avatar = result.data.avatar + '?timestamp=' + Date.now();
          setToken(token);
          setLoading(false);
          setMode('preview');
          // const url = process.env.REACT_APP_CORE_API_BASE + '/public/avatar/'+ token.user_info.avatar;
          // setAvatar(url);
        })
        .catch((err) => {
          console.log("---> err: ", err);
          setLoading(false);
        });
    }
  };

  const addAvatarHandler = () => {
    fileInputRef.current.click();
  }
  const [avatarSrc,setAvatarSrc] = React.useState();
  const onFileChange = (e) => {
      console.log(e.target.files[0]);
      const fileInfo = e.target.files[0];
      if(!fileCheck(fileInfo)){
        return;
      }
      const src = URL.createObjectURL(fileInfo);
      setMode('edit');
      setAvatarSrc(src);
  }
  const fileCheck = (file)=>{
    if(file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg'){
      return false;
    }
    if(file.size > 2097152){
      return false;
    }
    return true;
  }
  const [scaleRate, setScaleRate] = React.useState(1);

  const zoomChangeHandler = (e,value)=>{
    //console.log(value);
    setScaleRate(value/50);
  }

  const [previewSrc, setPreviewSrc] = React.useState();

  const previewHandler = () => {
    const cropperWidth = 150;
    const cropperHeight = 150;
    const scale = 1;
    const canvas = editorRef.current.getImage();
    const offScreenCanvas = document.createElement('canvas');
    const width = cropperWidth * scale;
    const height = cropperHeight * scale;
    offScreenCanvas.width = width;
    offScreenCanvas.height = height;
    offScreenCanvas.getContext('2d').drawImage(canvas, 0, 0, width, height);
    const dataUrl =  offScreenCanvas.toDataURL('image/jpeg', 1);
    setPreviewSrc(dataUrl);
  }

  const changeImgHandler = ()=> {
    setMode('preview');
    setPreviewSrc('');
  }
  const changeAvatarHandler = () => {
    setAvatar(null);
    setMode('preview');
    setPreviewSrc('');
  }

  return (
    <div className={classes.root}>
      <Typography variant="body2" gutterBottom className={classes.box}>
        {t('myAvatar.Avatar')}
      </Typography>
      <hr className={classes.hrColor} />
      <input ref={fileInputRef} type="file" name="file" hidden onChange={onFileChange}/>
      {avatar && mode === "preview" && 
      <div className={classes.avatarPreviewBox}>
          <div className={classes.previewImgBox}>
            <img style={{width:'150px',borderRadius:'50%',border: '1px solid #D8D8D8'}} src={avatar}></img>
          </div>
          <div style={{display:'flex',justifyContent: 'space-between'}}>
            <Button variant="contained" onClick={changeAvatarHandler}>{t('myAvatar.changeAvaButton')}</Button>
          </div>
      </div>
      }
      {!avatar && mode === "preview" && (
        <div className={classes.avatarBox}>
          <div className={classes.addBox} onClick={addAvatarHandler}>
            <AddIcon></AddIcon>
          </div>
          <Typography variant="body1">
          {t('myAvatar.avaMsg')}
          </Typography>
        </div>
        
      )}
      {mode === "edit" && (
        <div className={classes.editorBox}>       
          <div>
            <AvatarEditor
              ref={editorRef}
              image={avatarSrc}
              width={100}
              height={100}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              borderRadius={50}
              scale={scaleRate}
              rotate={0}
            />
            <div className={classes.adjustBox}>
              <Typography variant="body2">
              {t('myAvatar.Zoom')}:
              </Typography>
              <div className={classes.sliderBox}>
                <Slider defaultValue={50} min={0} max={500} onChange={zoomChangeHandler}></Slider>
              </div>
            </div>
            <div style={{display:'flex',justifyContent: 'space-between'}}>
              <Button variant="contained" onClick={changeImgHandler}>{t('myAvatar.cancelButton')}</Button>
              <Button variant="contained" onClick={previewHandler}>{t('myAvatar.previewButton')}</Button>
            </div>
            
          </div>
          
          {previewSrc &&
            <div className={classes.previewBox}>
              <div className={classes.previewImgBox}>
                <img style={{width:'150px',borderRadius:'50%',border: '1px solid #D8D8D8'}} src={previewSrc}></img>
              </div>
              <div className={classes.buttonDiv}>
                <LoadingButton
                  variant='contained'
                  isLoading={loading}
                  onClick={handleButtonClick}>
                  {t('myAvatar.updateButton')}
                </LoadingButton>
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
}
