import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Button, IconButton, TextField, Select, MenuItem, Tooltip, setRef } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import LoadingButton from "../../components/LoadingButton";
import accountingService from "../../services/accountingService";
import POToSO from './potoso';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AlarmIcon from '@material-ui/icons/Alarm';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ApplicationContext } from "../../context/applicationContext";
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';
import downloadFileUtils from '../../utils/downloadFile';
import Big from 'big.js'
import NotesEditor from "../../components/NotesEditor";
import LinkIcon from '@material-ui/icons/Link';
import './po-line.css'
import CloseIcon from '@material-ui/icons/Close';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles(theme => ({
    lineDateInput: {
        '& > *': {
            '& > *': {
                textAlign: "center"
            },
        },
    },
    buttonCol: {
        color: theme.currentTheme.colors[0],
    },
    tableStyle: {
        backgroundColor: theme.currentTheme.theme2backgrounds[1],
    },
    poFormContentItem: {
        padding: "30px 10px 0 10px",
        width: "100%",
        margin: "0",
    },
    poFormContentItem2: {
        padding: "0 0 50px 0",
    },
    itemTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginBottom: "20px"
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.grey[500],
    },
    itemContent: {
        // overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    required: {
        color: "#fd4747",
        display: "inline",
    },
    root: {
        borderBottom: "none ",
        fontSize: "medium",
        backgroundColor: theme.currentTheme.backgrounds[11],
    },
    dashedLine: {
        margin: "45px 0 20px 0",
        borderTop: "1px dashed #dbdbdb",
        width: "100%",
    },
    selectField: {
        padding: "11px 14px",
    },
    selectTopItem: {
        margin: "32px 0 12px 0",
    },
    selectTopItem2: {
        margin: "13px 0 12px 0",
    },
    dialogConvertHeader: {
        backgroundColor: "#e1f0f7"
    },
    dialogConvertBody: {
        padding: "0 0 30px 75px"
    },
    dialogConvertFooter: {
        padding: "0 50px 45px"
    },
    convertTitle:{
        width:'140px',
        margin:'8px 10px 8px 0px',
        display:'inline-block',
        height:'25px',
    },
    convertLineTitle:{
        margin:'10px 0px 10px 0px'
    },
    outlinedPadding: {
        padding: '11px 14px',
    },
}))

export default function PoLine(props) {
    const classes = useStyles();
    const history = useHistory();
    const {t, i18n} = useTranslation();

    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>
        ),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        Cancel: forwardRef((props, ref) => <CancelOutlinedIcon {...props} ref={ref} />)
    };

    const params = useParams();

    const [editAble, setEditAble] = useState(null);
    const [canGotoInventory, setCanGotoInventory] = useState(false);
    const [allOrgLines, setAllOrgLines] = useState("");

    const { applications, refreshApplications, switchApplication} = useContext(ApplicationContext);

    const [poLineData, setPoLineData] = useState([]);
    useEffect(() => {
        console.log(props);
        let arr = [];
        applications.forEach(item => {
            // console.log(item);
            if(item.organizations){
                item.organizations.forEach(temp => {
                    let multiOrg = arr.find(item=>{
                        return(item.id===temp.id)
                    })
                    if(multiOrg){
                        multiOrg["app_name"] = multiOrg["app_name"]+","+item.display_name;
                        // console.log(multiOrg)
                    }
                    else{
                        temp["app_name"] = item.display_name;
                        temp["app_info"] = item;
                        arr.push(temp);
                    }

                })
            }
        })
        // console.log("ALL OWN ORG LIST",arr);
        let currentOrg = arr.find(item=>{
            return item.id === window.sessionStorage.getItem("currentOrg");
        });
        if(currentOrg.app_name.indexOf('Inventory')>-1){
            setCanGotoInventory(true);
        }
        setCurrentBook(props.book);
        if (props.po) {
            accountingService.getPOByBookId(params.bookId).then(result => {
                setPoLineData(result.data[0]);
                setEditRef(result.data[0].refNumber)
            })
            accountingService.getPOLineByPOId(props.po).then(result => {
                setPoLines(result.data);
            })
        }

        let editable1 = {}
        if (accountingService.checkPermission("new_po_line", props.book)) {
            editable1 = {
                isEditable: rowData => accountingService.checkPermission("revise_po_line", props.book) && rowData.isUsed === false && rowData.status !==  "closed",
                isDeletable: rowData => accountingService.checkPermission("cancel_po_line", props.book) && rowData.isUsed === false && rowData.status !== "closed",
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        handleRowAdd(newData);
                        resolve();
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        handleRowUpdate(newData, oldData);
                        resolve();
                    }),
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        handleRowDelete(oldData);
                        resolve();
                    }),
            };
        }
        else {
            editable1 = {
                isEditable: rowData => accountingService.checkPermission("revise_po_line", props.book) && rowData.isUsed === false && rowData.status !== "closed",
                isDeletable: rowData => accountingService.checkPermission("cancel_po_line", props.book) && rowData.isUsed === false && rowData.status !== "closed",
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        handleRowUpdate(newData, oldData);
                        resolve();
                    }),
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        handleRowDelete(oldData);
                        resolve();
                    }),
            };
        }
        setEditAble(editable1);
        accountingService.getLinesByOrgId(currentOrg.id).then(result=>{
            setAllOrgLines(result.data.datas);
        })
    }, [params.bookId])

    const [poLines, setPoLines] = useState([]);

    const [currentBook, setCurrentBook] = useState(null);
    const [currentLine, setCurrentLine] = useState(null);
    const [editRef,setEditRef] = useState("");
    const [open, setOpen] = useState(false);

    const [noteOpen, setNoteOpen] = useState(false);

    const [noteAddOpen, setNoteAddOpen] = useState(false);

    const [noteDelOpen, setNoteDelOpen] = useState(false);
    const [editRefNumberOpen, setEditRefNumberOpen] = useState(false);
    const [alarmManageOpen,setAlarmManageOpen] = useState(false);
    const [qtyAlarmOpen, setQtyAlarmOpen] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);

    const [alarmNotes,setAlarmNotes] = useState(null);
    const [alarmDate,setAlarmDate] = useState(null);
    const [alarmLineId,setAlarmLineId] = useState(null);
    const [lineCloseOpen, setLineCloseOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [notes, setNotes] = useState("");
    const [preCloseLineNo, setPreCloseLineNo] = useState("");
    const [preCloseLineItemNo, setPreCloseLineItemNo] = useState("");
    const [newData, setNewData] = useState("");
    const [oldData, setOldData] = useState("");
    const [selLinesToRef, setSelLinesToRef] = useState(null);
    const [selLinesToRefError, setSelLinesToRefError] = useState(false);
    const [refNumber, setRefNumber] = useState(0);
    const [refNumberError, setRefNumberError] = useState(false);

    const [canRefLines, setCanRefLines] = useState([]);

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    };

    const handleConvert = () => {
        setOpen(true);
    };

    const handleConvertClose = () => {
        setOpen(false);
    };

    const handleNoteClose = () => {
        setNoteOpen(false);
        setNotes("");
        setNewData("");
        setOldData("");
    };

    const handleAddNoteClose = () => {
        setNoteAddOpen(false);
        setNotes("");
        setNewData("");
        setOldData("");
    };

    const handleDelNoteClose = () => {
        setNoteDelOpen(false);
        setNotes("");
        setNewData("");
        setOldData("");
    };

    const handlerLineClosedClose =()=> {
        setPreCloseLineNo("")
        setPreCloseLineItemNo("");
        setLineCloseOpen(false);
        setNotes("");
        setNewData("");
        setOldData("");
    };

    const handleRowAdd = (newData) => {
        setPoLines([...poLines, newData]);
        setNewData(newData);
        let temp = {
            lineNumber: newData.lineNumber,
            itemNumber: newData.itemNumber,
            itemRev: newData.itemRev && newData.itemRev !== undefined ? newData.itemRev : "",
            productName: newData.productName,
            deliveryTerm: newData.deliveryTerm,
            qty: newData.qty,
            unit: newData.unit && newData.unit !== undefined ? newData.unit : "",
            storageUnit: newData.storageUnit && newData.storageUnit !== undefined ? newData.storageUnit : "",
            price: newData.price,
            tax: newData.tax && newData.tax !== undefined ? newData.tax : "",
            discount: newData.discount && newData.discount !== undefined ? newData.discount : "",
            lineTotal: newData.lineTotal,
            lineDate: newData.lineDate && newData.lineDate !== undefined ? newData.lineDate : moment().format("YYYY/MM/DD"),
            notes: newData.notes && newData.notes !== undefined ? newData.notes : "",
        }
        console.log("Add New PoLine Data", [temp]);
        console.log("poLineData", poLineData);

        accountingService.addPOLineByPOId(props.po, [temp]).then(result => {
            if (result.data) {
                refreshPoLineAndBalance();
            }
            setNewData("");
            setNotes("");
            setIsLoading(false);
            setNoteAddOpen(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        })
    }

    const handleRowUpdate = (newData, oldData) => {
        const dataUpdate = [...poLines];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setIsLoading(true);

        let data = [];

        let temp = {
          lineId: dataUpdate[index].lineId,
          qty: dataUpdate[index].qty,
          storageUnit: dataUpdate[index].storageUnit,
          lineDate: dataUpdate[index].lineDate && dataUpdate[index].lineDate !== undefined ? moment(dataUpdate[index].lineDate).format("YYYY/MM/DD") : moment().format("YYYY/MM/DD"),
          lineTotal: dataUpdate[index].lineTotal,
        };

        if (dataUpdate[index].notes !== oldData.notes) {
            temp.notes = dataUpdate[index].notes === undefined ? '' : dataUpdate[index].notes;
        }

        data.push(temp);

        accountingService.revisePOLineByPOId(props.po, data).then(result => {

            if (result.message
              && result.message.toLowerCase() === 'the modified qty is less than the quantity shipped in this line.') {
                setQtyAlarmOpen(true);
                return;
            }

            if (result.data) {
                refreshPoLineAndBalance();
            }
            setNewData("");
            setOldData("");
            setNotes("");
            setIsLoading(false);
            setNoteOpen(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        })
    }

    const handleRowDelete = (oldData) => {
        setIsLoading(true);
        const dataDelete = [...poLines];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setPoLines([...dataDelete]);

        let data = [];
        data.push({ lineId: oldData.lineId, notes: notes });

        console.log("delete lineId data", data);

        accountingService.cancelPoLineByPOId(oldData.poId, data).then(result => {
            if (result.data) {
                refreshPoLineAndBalance();
                setOldData("");
                setNotes("");
                setIsLoading(false);
                setNoteDelOpen(false);
            }
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        })
    }

    const closeLineHandler = (event, rowData) => {
        setPreCloseLineNo(rowData.lineId);
        setPreCloseLineItemNo(rowData.itemNumber);
        setLineCloseOpen(true);
    };

    const handlerLineClosedConfirm = ()=> {
        setIsLoading(true);
        let data = {
            lineId: preCloseLineNo,
            notes: "close po line"
        };
        console.log("close data",data);

        accountingService.closeLine(props.po, data).then(result => {
            if (result.data) {
                refreshPoLineAndBalance();
            }
            setPreCloseLineNo("");
            setPreCloseLineItemNo("");
            setIsLoading(false);
            setLineCloseOpen(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        })
    };

    const refreshPoLineAndBalance = () => {
        props.refresh();
        if (props.po) {
            accountingService.getPOLineByPOId(props.po).then(result => {
                setPoLines(result.data);
            })
        }
    };

    const refreshPoLines = () => {
        if (props.po) {
            accountingService.getPOLineByPOId(props.po).then(result => {
                setPoLines(result.data);
                result.data.map(item=>{
                    if(item.lineId === currentLine.lineId){
                        setCurrentLine(item);
                        let temp = [];
                        if(allOrgLines.length>0){
                            allOrgLines.map(item1=>{
                                let canRef = true;
                                if(item.refLines&&item.refLines.length>0){
                                    item.refLines.map(item2=>{
                                        if(item1.lineId===item2.lineId){
                                            canRef = false;
                                        }
                                    })
                                }
                                if(canRef){
                                    temp.push(item1);
                                }
                            })
                            setCanRefLines(temp);
                        }
                    }
                })
            })
        }
    };

    const formatData = (value) => {
        let data = parseFloat(value).toFixed(4).replace(/d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
        return data;
    };

    const checkNumber = (values) => {
        let reg = /^\d*(\.\d{1,4})?$/;
        if (values && !reg.test(values)) {
            return false;
        }
        return true;
    }

    const checkDuplication = (rowData) => {
      if (rowData.tableData && rowData.tableData.editing) {
        return true;
      } else {
        for (let i = 0; i < poLines.length; i++) {
          if (poLines[i]["lineNumber"] === rowData.lineNumber) {
            return false;
          }
        }
        return true;
      }
    };

    const checkInventoryHandler =(event, rowData)=>{
        const settingURL = '/Inventory';
        let inventoryApp = applications.find(item=>{
            return item.name==='Inventory'
        })
        switchApplication(inventoryApp);
        history.push({
            pathname: settingURL,
            state: { "searchKeywords": rowData.itemNumber },
        });
    }

    const checkAlarmHandler =(event, rowData)=>{
        setAlarmDate(rowData.alarmDate);
        setAlarmNotes(rowData.alarmNotes);
        setAlarmLineId(rowData.lineId);
        setAlarmManageOpen(true);
    }

    const handleEditRefOpen = ()=>{
        setEditRefNumberOpen(true);
    }

    const handleEditRefClose =()=>{
        setEditRefNumberOpen(false);
    }

    const handleAlarmManageClose =()=>{
        setAlarmManageOpen(false);
    }

    const handleQTYAlarmClose = () => {
        setQtyAlarmOpen(false);
    }

    const handleNotificationClose = () => {
        setNotificationOpen(false);
    }

    const readPOLineNotifications = () => {
      let notifyIds = currentLine.notifyData.map((column) => {
        return column.notifyId;
      });

      accountingService.readPOLineNotifications({
        'notifyIds': notifyIds,
      }).then((_) => {
        // poLine.notes = noteValue;
        currentLine.notifyData = null;
        setNotificationOpen(false);
      });
    }

    const handleEditRefChange =(e)=>{
        setEditRef(e.target.value);
    }

    const handlerUpdateRefNumberConfirm = ()=>{
        let data ={
            refNumber: editRef
        }
        accountingService.updateRefNumberByPoId(poLineData.poId,data).then(result=>{
            if(result.status===200){
                refreshPoLineAndBalance();
                setEditRefNumberOpen(false);
            }
        })
    }

    const handlerAlarmUpdateConfirm=()=>{
        let valid = true;
        if(!alarmDate||alarmDate===''){
            valid = false;
        }
        if(valid){
            let data = {
                alarmDate:alarmDate,
                alarmNotes:alarmNotes
            }
            accountingService.updateAlarmByLineId(alarmLineId,data).then(result=>{
                if(result.status===200){
                    refreshPoLineAndBalance();
                    setAlarmManageOpen(false);
                }
            })
        }
    }

    const handleAlarmDelete=()=>{
        accountingService.deleteAlarmByLineId(alarmLineId).then(result=>{
            if(result.status===200){
                refreshPoLineAndBalance();
                setAlarmManageOpen(false);
            }
        })
    }

    const handleDownloadPO=()=>{
        accountingService.downloadPOByPOID(props.po,"pdf").then(result=>{
            let types = 'application/pdf';
            let fileName = `Po ${props.po}.pdf`;
            let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
            downloadFileUtils.downLoadFile(blob, fileName);
        })
    }

    const checkLineNotification = (event, rowData) => {
        setCurrentLine(rowData);
        setNotificationOpen(true);
    }

    const notificationButtonStyle = (rowData) => {

      if (rowData.notifyData) {
        return {color: 'red'}
      }

      return {}
    }

    const notificationButtonTooltip = (rowData) => {

      if (rowData.notifyData) {
        return t('common.review_notifications')
      }

      return t('common.no_notifications')
    }

    const lineRefManagement = (event, rowData) => {
        setSelLinesToRefError(false);
        setCurrentLine(rowData);
        setSelLinesToRef(null);
        setRefNumber(0);
        setRefNumberError(false);
        let temp = [];
        if (allOrgLines.length > 0) {
            allOrgLines.map(item => {
                let canRef = true;
                if (rowData.refLines && rowData.refLines.length > 0) {
                    rowData.refLines.map(item1 => {
                        if (item1.lineId === item.lineId) {
                            canRef = false;
                        }
                    })
                }
                if (canRef) {
                    temp.push(item);
                }
            })
            setCanRefLines(temp);
        }
        setEditRefNumberOpen(true);
    }

    const addRefLinks = ()=>{
        if(!selLinesToRef){
            setSelLinesToRefError(true);
            return;
        }
        if(refNumber===''){
            setRefNumberError(true);
            return;
        }
        let refLine = {
            toPoId: selLinesToRef.poId,
            toLineId: selLinesToRef.lineId,
            refAmount: refNumber
        }
        let refLines = [];
        refLines.push(refLine);
        let currentOrg = window.sessionStorage.getItem("currentOrg");
        let data = {
            fromPoId: currentLine.poId,
            fromLineId: currentLine.lineId,
            orgId: currentOrg,
            refLines: refLines,
        }
        accountingService.addReflines(data).then(result=>{
            if (result.status === 200) {
                refreshPoLines();
            }
        })

    }

    const deleteRefLine = (linkId)=>{
        accountingService.deleteRefLine(linkId).then(result=>{
            if(result.status===200){
                refreshPoLines();
            }
        })
    }

    return (
        <>
            <Grid container >
                <Grid item xs={12}>
                    {poLineData ? (
                        <Grid key={poLineData.poId} style={{overflowX:"auto"}}>
                            <Grid container spacing={2} alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem} style={{minWidth:"960px"}}>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body2" className={classes.itemTitle}>
                                                {t('poFormPage.poNumber')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" className={classes.itemContent}>
                                                {poLineData.poNumber ? poLineData.poNumber : (<span>&nbsp;</span>)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body2" className={classes.itemTitle}>
                                            {t('poFormPage.poDate')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" className={classes.itemContent}>
                                                {moment(poLineData.poDate).format('YYYY/MM/DD')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body2" className={classes.itemTitle}>
                                            {t('poFormPage.deliveryTerm')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" className={classes.itemContent}>
                                                {poLineData.deliveryTerm ? poLineData.deliveryTerm : (<span>&nbsp;</span>)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body2" className={classes.itemTitle}>
                                            {t('poFormPage.paymentTerm')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" className={classes.itemContent}>
                                                {poLineData.paymentTerm ? poLineData.paymentTerm : (<span>&nbsp;</span>)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body2" className={classes.itemTitle}>
                                                {t('poFormPage.currency')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" className={classes.itemContent}>
                                                {poLineData.currency ? poLineData.currency : (<span>&nbsp;</span>)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={2} >
                                    <Grid container>
                                        <Grid item xs={12} style={{ textAlign: "right" }}>
                                            <Grid container justifyContent="flex-end" alignItems="center" >
                                                <Tooltip title={t('poManagementHomepage.downloadPo')}>
                                                    <IconButton aria-label="close" style={{ marginRight: "10px" }} onClick={handleDownloadPO}>
                                                        <GetAppIcon></GetAppIcon>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem2}>
                                    <Grid item container xs={12}>
                                        <MaterialTable
                                            title=""
                                            icons={tableIcons}
                                            style={{ width: "100%" }}
                                            columns={[
                                                {
                                                    title: t('poFormPage.line')+'*', field: 'lineNumber', editable: 'onAdd',
                                                    validate: rowData => rowData.lineNumber !== '' && rowData.lineNumber !== undefined && checkDuplication(rowData),
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                },
                                                {
                                                    title: t('poFormPage.item')+'*', field: 'itemNumber', editable: 'onAdd', sorting: false,
                                                    validate: rowData => rowData.itemNumber !== '' && rowData.itemNumber !== undefined,
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                },
                                                {
                                                    title: t('poFormPage.rev'), field: 'itemRev', editable: 'onAdd', sorting: false,
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                },
                                                {
                                                    title: t('poFormPage.product')+'*', field: 'productName', editable: 'onAdd', sorting: false,
                                                    validate: rowData => rowData.productName !== '' && rowData.productName !== undefined && rowData.productName.length<=200,
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                },
                                                {
                                                    title: t('poFormPage.storageUnit'), field: 'storageUnit', sorting: false,
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                    editComponent: props => (
                                                      <TextField
                                                        id="storage_unit_text"
                                                        value={props.rowData.storageUnit}
                                                        required={true}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        size="small"
                                                        onChange={e => {
                                                            props.onChange(e.target.value)
                                                        }}
                                                      />
                                                    ),
                                                },
                                                {
                                                    title: t('poFormPage.QTY')+'*', field: 'qty', sorting: false,
                                                    validate: rowData => rowData.qty !== '' && rowData.qty !== undefined && checkNumber(rowData.qty),
                                                    render: rowData => formatData(rowData.qty),
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                    editComponent: props => (
                                                        <TextField
                                                            id="qty_text"
                                                            value={props.rowData.qty}
                                                            error={!props.rowData.qty || props.rowData.qty === undefined || props.rowData.qty === "" || !checkNumber(props.rowData.qty)}
                                                            required={true}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            size="small"
                                                            onChange={e => {
                                                                props.onRowDataChange({
                                                                    ...props.rowData,
                                                                    lineTotal: e.target.value && props.rowData.price ? new Big(e.target.value).times(props.rowData.price).toFixed(4).toString() : "",
                                                                    qty: e.target.value,
                                                                })
                                                            }}
                                                        >
                                                        </TextField>
                                                    ),
                                                },
                                                {
                                                    title: t('poFormPage.unit'), field: 'unit', editable: 'onAdd', sorting: false,
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                },

                                                {
                                                    title: t('poFormPage.price')+'*', field: 'price', editable: 'onAdd', sorting: false,
                                                    validate: rowData => rowData.price !== '' && rowData.price !== undefined && checkNumber(rowData.price),
                                                    render: rowData => formatData(rowData.price),
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                    editComponent: props => (
                                                        <TextField
                                                            id="price_text"
                                                            value={props.rowData.price}
                                                            error={!props.rowData.price || props.rowData.price === undefined || props.rowData.price === "" || !checkNumber(props.rowData.price)}
                                                            required={true}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            size="small"
                                                            onChange={e => {
                                                                props.onRowDataChange({
                                                                    ...props.rowData,
                                                                    lineTotal: e.target.value && props.rowData.qty ? new Big(e.target.value).times(props.rowData.qty).toFixed(4).toString() : "",
                                                                    price: e.target.value,
                                                                })
                                                            }}
                                                        >
                                                        </TextField>
                                                    ),
                                                },
                                                {
                                                    title: t('poFormPage.lineTotal')+'*', field: 'lineTotal', sorting: false,
                                                    validate: rowData => rowData.lineTotal !== '' && rowData.lineTotal !== undefined && checkNumber(rowData.lineTotal),
                                                    render: rowData => formatData(rowData.lineTotal),
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                    editComponent: (props) => {
                                                        return (
                                                            <TextField
                                                                id="linetotal_text"
                                                                value={props.rowData.lineTotal ? props.rowData.lineTotal : "0"}
                                                                error={!props.rowData.lineTotal || props.rowData.lineTotal === undefined || props.rowData.lineTotal === ""||!checkNumber(props.rowData.lineTotal)}
                                                                required={true}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                size="small"
                                                                onChange={e => {
                                                                    props.onChange(e.target.value)
                                                                }}
                                                            >
                                                            </TextField>
                                                        )
                                                    }
                                                },
                                                {
                                                    title: t('poLineManagement.date')+'*', field: 'lineDate', type: 'date', emptyValue: moment().format("YYYY/MM/DD"),
                                                    editComponent: props => (
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <DatePicker
                                                                autoOk
                                                                //disableToolbar
                                                                id="date"
                                                                variant="inline"
                                                                size="small"
                                                                format="YYYY/MM/DD"
                                                                value={props.value}
                                                                className={classes.lineDateInput}
                                                                onChange={date => props.onChange(moment(date).format("YYYY/MM/DD"))}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    ),
                                                    render: rowData => moment(rowData.lineDate).format("YYYY/MM/DD"),
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                },
                                                {
                                                    title: t('poFormPage.alarmDate'), field: 'alarmDate', type: 'date', emptyValue: '',editable:false,
                                                    render: rowData => <span title={rowData.alarmNotes}>{moment(rowData.alarmDate).format("YYYY/MM/DD")}</span>,
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                },
                                                {
                                                    title: t('poFormPage.lineRefNO'), field: 'refLines', editable:false, sorting: false,
                                                    render: rowData => <div>{rowData.refLines&&rowData.refLines.length>0&&rowData.refLines.map((item,index)=>{
                                                        return(
                                                            <Typography variant="body2" index={index}>{item.poNumber + '/' + item.lineNumber}</Typography>
                                                    )})}</div>,
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                },
                                                {
                                                    title: t('poFormPage.notes'), field: 'notes', emptyValue: '',sorting: false,
                                                    cellStyle: {
                                                        textAlign: "center",
                                                        padding: "4px"
                                                    },
                                                    editComponent: props => (
                                                        <NotesEditor
                                                            id="notes"
                                                            value={props.rowData.notes ? props.rowData.notes : ""}
                                                            onChange={value=>props.onChange(value)}
                                                        >
                                                        </NotesEditor>
                                                    ),
                                                }
                                            ]}
                                            data={poLines}
                                            options={{
                                                actionsColumnIndex: -1,
                                                search: false,
                                                paging: false,
                                                sorting: true,
                                                cellStyle: {
                                                    padding: "4px",
                                                    textAlign: "center",
                                                },
                                                headerStyle: {
                                                    padding: "4px",
                                                    textAlign: "center",
                                                    backgroundColor: "transparent",
                                                },
                                                rowStyle: (rowData, index ) => ({
                                                    fontSize: "14px",
                                                    //color: ((rowData.isUsed === true && rowData.lineOutstanding <= 0.1) || rowData.isUsed === false || rowData.status !== "closed") ? "grey" : ""
                                                    color: (rowData.status && rowData.status === "closed") ? "grey" : moment(rowData.alarmDate)<moment() && rowData.alarmNotes !== ""&&rowData.isUsed===false?"red":""
                                                }),
                                                actionCellStyle:{
                                                    textAlign:"center",
                                                    alignItems:'center'
                                                },
                                            }}
                                            editable={editAble}
                                            localization={{
                                                header:{
                                                    actions: t('poFormPage.actions')
                                                },
                                                body: {
                                                    deleteTooltip: t('common.cancel'),
                                                    editTooltip:t('common.edit'),
                                                    addTooltip:t('common.add'),
                                                    editRow: {
                                                        saveTooltip: t('common.check'),
                                                        cancelTooltip: t('common.cancel')
                                                    },
                                                    emptyDataSourceMessage:t("common.noRecord"),
                                                },
                                            }}
                                            actions={[

                                                rowData => ({
                                                    icon: ()=> <NotificationImportantIcon style={notificationButtonStyle(rowData)}/>,
                                                    tooltip: notificationButtonTooltip(rowData),
                                                    onClick: (event, rowData) => checkLineNotification(event, rowData),
                                                    disabled: !rowData.notifyData
                                                }),

                                                rowData => ({
                                                    icon: ()=> <LinkIcon></LinkIcon>,
                                                    tooltip: t('poManagementHomepage.addLineRef'),
                                                    onClick: (event, rowData) => lineRefManagement(event, rowData),
                                                }),

                                                rowData => ({
                                                    icon: ()=> <AlarmIcon></AlarmIcon>,
                                                    tooltip: t('poManagementHomepage.addAlarm'),
                                                    onClick: (event, rowData) => checkAlarmHandler(event, rowData),
                                                    hidden: !currentBook.roles.includes("Book_Owner"),
                                                    disabled: rowData.isUsed === true,
                                                }),

                                                rowData => ({
                                                    icon: ()=> <FindInPageIcon></FindInPageIcon>,
                                                    tooltip: t('poManagementHomepage.checkInventory'),
                                                    onClick: (event, rowData) => checkInventoryHandler(event, rowData),
                                                    disabled: !canGotoInventory,
                                                }),

                                                rowData => ({
                                                  icon: ()=> <CancelOutlinedIcon></CancelOutlinedIcon>,
                                                  tooltip: t('poManagementHomepage.closeLine'),
                                                  onClick: (event, rowData) => closeLineHandler(event, rowData),
                                                  disabled: !(rowData.isUsed === true && rowData.lineOutstanding > 0.1 && rowData.status === "normal"),
                                                }),

                                              ]}
                                            components={{
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                        : "You do not have any po line."
                    }
                </Grid>

            </Grid>

            <Dialog open={open} onClose={handleConvertClose} maxWidth="md" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="body1">
                        Convert PO to SO
                    </Typography>
                </DialogTitle>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleConvertClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <POToSO poId={props.po} onClose={handleConvertClose}></POToSO>
                </DialogContent>
            </Dialog>

            <Dialog open={lineCloseOpen} onClose={handlerLineClosedClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="body1">
                        Are you sure to close the Line {preCloseLineItemNo}?
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <LoadingButton isLoading={isLoading} variant="contained" color="primary" onClick={handlerLineClosedConfirm}>
                        <Typography variant="body2">
                            {t('common.confirm')}
                        </Typography>
                    </LoadingButton>
                    <Button variant="contained" onClick={handlerLineClosedClose}>
                        <Typography variant="body2">
                            {t('common.cancel')}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={editRefNumberOpen} onClose={handleEditRefClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><Typography variant="body1">{t('poManagementHomepage.addLineRef')}</Typography></DialogTitle>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleEditRefClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid container spacing={1} xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body2">{t('poFormPage.refLines')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {currentLine && currentLine.refLines && currentLine.refLines.map((item) => {
                                return(
                                    <Grid container>
                                        <Grid container xs={10} alignItems="center">
                                            <Typography
                                              variant="body2">{item.poNumber + '/' + item.lineNumber + ' - ' + t('poFormPage.refAmount') + ':' + item.refAmount}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton onClick={e=>deleteRefLine(item.refLineId)}><DeleteIcon/></IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            {currentLine && !currentLine.refLines && <Typography>{t('poFormPage.noLines')}</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">{t('poFormPage.addRefLine')}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                id="Host"
                                freeSolo
                                value={selLinesToRef}
                                options={canRefLines}
                                getOptionLabel={(option) => option.poNumber+"/"+option.lineNumber}
                                onChange={(event, newValue) => {
                                    setSelLinesToRef(newValue);
                                    if(newValue){
                                        setSelLinesToRefError(false);
                                    }
                                    else{
                                        setSelLinesToRefError(true);
                                    }
                                }}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.poNumber+"/"+option.lineNumber}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} margin="dense" onKeyDown={e=>{
                                        if(e.keyCode === 13){
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }
                                    }} variant="outlined" size="small" error={selLinesToRefError}
                                    />
                                )}
                            />
                            <Typography variant="body2" style={{paddingTop:'20px', paddingBottom:'4px'}}>{t('poFormPage.refAmount')}</Typography>
                            <TextField
                                id="outlined-multiline-static"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={refNumberError}
                                margin="dense"
                                variant="outlined"
                                value={refNumber}
                                onChange={e=>{
                                    setRefNumber(e.target.value);
                                    setRefNumberError(e.target.value === '' || !checkNumber(e.target.value));
                                }}
                            />
                        </Grid>
                        <Grid item xs={8} style={{paddingTop: '20px'}}>
                            <Button variant="contained" color="primary" onClick={addRefLinks}>添加</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>

            <Dialog open={alarmManageOpen} onClose={handleAlarmManageClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="body1">
                        {t('poManagementHomepage.addAlarm')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                minRows={4}
                                variant="outlined"
                                value={alarmNotes}
                                onChange={e=>setAlarmNotes(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    autoOk
                                    //disableToolbar
                                    id="date"
                                    size="small"
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="YYYY/MM/DD"
                                    value={alarmDate}
                                    error={!alarmDate || alarmDate === ""}
                                    onChange={date => setAlarmDate(moment(date).format("YYYY/MM/DD"))}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton isLoading={isLoading} variant="contained" color="primary" onClick={handlerAlarmUpdateConfirm}>
                        <Typography variant="body2">
                            {t("common.confirm")}
                        </Typography>
                    </LoadingButton>
                    <Button variant="contained" onClick={handleAlarmDelete}>
                        <Typography variant="body2">
                            {t("common.delete")}
                        </Typography>
                    </Button>
                    <Button variant="contained" onClick={handleAlarmManageClose}>
                        <Typography variant="body2">
                            {t("common.cancel")}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={qtyAlarmOpen} onClose={handleQTYAlarmClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="body1">
                        {t('poManagementHomepage.qtyAlarm')}
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleQTYAlarmClose}>
                        <Typography variant="body2">
                            {t('common.ok')}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={notificationOpen} onClose={handleNotificationClose} maxWidth="sm" fullWidth
                    aria-labelledby="form-dialog-title">
                <DialogContent>
                    {currentLine && currentLine.notifyData &&
                      currentLine.notifyData.map((column) => {
                          return (
                            <div style={{marginLeft: '20px'}}>
                                <Typography variant="body2">
                                    <li>
                                        {t('common.add_new_note').replace('$username', column.notifyContent.parameters.fromUser)}
                                    </li>
                                </Typography>
                            </div>
                          )
                      })
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={readPOLineNotifications}>
                        <Typography variant="body2">
                            {t('common.mark_read')}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
