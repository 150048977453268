import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Typography, useMediaQuery} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import CheckIcon from '@material-ui/icons/Check';

import learn_more_bg01 from "../../assets/img/learn_more_bg01@3x.png";

const useStyles = makeStyles((theme) => ({

  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  part1: {
    height: '404px',
    width: '100%',
    backgroundSize: '100%, auto',
    backgroundImage: `url(${learn_more_bg01})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  text_bottom_1: {
    color: "#fff",
    fontFamily: 'DinBold',
    fontSize: '36px',
    letterSpacing: '0.01px',
    [theme.breakpoints.down('sm')]:{
      letterSpacing: '-0.44px',
      fontSize: '26px',
    }
  },

  text_bottom_2: {
    // color: "#fff",
    fontFamily: 'Din',
    fontSize: '26px',
    letterSpacing: '0.08px',
    lineHeight: '1.62',
  },

  text_bottom_3: {
    // color: "#fff",
    fontFamily: 'Din',
    fontSize: '24px',
    letterSpacing: '0.08px',
    lineHeight: '1.25',
  },

  part1_1: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.currentTheme.backgrounds[13],
    padding: '20px',
    justifyContent: 'center',
    marginLeft: '30px',
    marginRight: '30px',
  },

  part1_2: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    alignItems: 'center'
  },

}));

export default function PlatformHomeLearnMore() {

  const classes = useStyles();

  const {t, i18n} = useTranslation();

  const isMobile = useMediaQuery((theme)=>{
    return theme.breakpoints.down('sm');
  });

  return (

    <div className={classes.root}>
      {/*<img className={classes.learn_more_bg01} src={learn_more_bg01} />*/}
      <div className={classes.part1}>
        <Typography variant="h2" className={classes.text_bottom_1} style={{textAlign: 'right'}}>
          PO TRANSACTIONS
        </Typography>
        <div className={classes.part1_1} style={{}}>

          <Typography variant="h2" className={classes.text_bottom_2} style={{}}>
            BENEFITS
          </Typography>

          <div className={classes.part1_2}>
            <CheckIcon style={{ color: '#2DA44E' }} />
            <Typography variant="h2" className={classes.text_bottom_3} style={{marginLeft: '10px'}}>
              do you need a book keeping purchase orders instead of searching the PO attachments in email?
            </Typography>
          </div>

          <div className={classes.part1_2}>
            <CheckIcon style={{ color: '#2DA44E' }} />
            <Typography variant="h2" className={classes.text_bottom_3} style={{marginLeft: '10px'}}>
              do you need to understand the outstanding PO and account balance?
            </Typography>
          </div>

          <div className={classes.part1_2}>
            <CheckIcon style={{ color: '#2DA44E' }} />
            <Typography variant="h2" className={classes.text_bottom_3} style={{marginLeft: '10px'}}>
              do you need a vendor or customer reconciliation?
            </Typography>
          </div>

          <div className={classes.part1_2}>
            <CheckIcon style={{ color: '#2DA44E' }} />
            <Typography variant="h2" className={classes.text_bottom_3} style={{marginLeft: '10px'}}>
              are you worried about the disputes caused by lost PO transaction records?
            </Typography>
          </div>



        </div>
      </div>

    </div>

  );



}
