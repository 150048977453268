import logo from '../assets/img/newLogo.svg';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    header:{
        height:'100%',
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        width: '40px',
        paddingLeft: '20px',
        cursor: 'pointer',
    }

  }));
export default function OpenDaisLogo(props){
    const classes = useStyles();
    const clickLogoHandler = ()=> {
        if(props.logoClick){
            props.logoClick();
        }
    }
    return (
        <div className={classes.header}>
            <img className={classes.logo} src={logo} onClick={clickLogoHandler}></img>
        </div>
    )
}