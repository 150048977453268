import React,{useContext}  from 'react';
import { Component, useEffect, useState, useRef} from 'react';
import { Link, useParams, useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, ButtonGroup,Menu, Select, MenuItem, FormControl, InputLabel, TextField, Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';

import useToken from '../../hooks/useToken';
import CommonLayout from "../../components/CommonLayout";
import { ApplicationContext } from "../../context/applicationContext";
import OrganizationSelect from "../../components/OrganizationSelect";
import inventoryService from '../../services/inventoryService';
import userService from "../../services/userService";
import inventoryDescriptionPic1 from '../../assets/img/inventory-introduction.png';
import inventoryDescriptionPic2 from '../../assets/img/inventory-introduction@2x.png';
import inventoryDescriptionPic3 from '../../assets/img/inventory-introduction@2x.png';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import LoadingButton from "../../components/LoadingButton";
import timezoneData from './timezones.json';
import Autocomplete from '@material-ui/lab/Autocomplete';
import organizationService from '../../services/organizationService';
import OrgRoleReader from "../../utils/orgRoleReader";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '50px'
    },
    box: {
        padding: '30px',
        width:'100%'
    },
    outlinedPadding: {
        padding: '11px 14px',
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft:0,
        width: '100%'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    tabsTitle: {
        margin: "30px 0 0 0",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[3],
    },
    tabsRoot: {
        textTransform: "none",
    },
    tabsLabel: {
        fontFamily: "IBMPlexSans-Regular",
        fontSize: "16px"
    }
}));


export default function Main() {
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const { token } = useToken();

    const history = useHistory();

    const [value, setValue] = React.useState(0);

    const [initing, setIniting] = React.useState(true);

    const [orgExpired, setOrgExpired] = React.useState(false);

    const [openCreateOrg, setOpenCreateOrg] = React.useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [canAddNewOrg, setCanAddNewOrg] = useState(true);

    const {application,refreshApplication} = useContext(ApplicationContext);

    const [currentOrg, setCurrentOrg] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [loading, setLoading] = React.useState(false);    
    const [timezoneError, setTimezoneError] = React.useState(false);    

    useEffect(()=>{
        if(currentOrg!==""){
            
        }
    },[currentOrg])

    useEffect(() => {
        userService.getUserDetailsByEmail(token.username).then((result)=>{
            if(result&&result.status===200){
                // console.log(result)
                if(result.data.orgNumber>=10){
                    setCanAddNewOrg(false);
                }
                else{
                    setCanAddNewOrg(true);
                }
            }
        });
    },[])

    useEffect(() => {
        if(currentOrg!==''){
            organizationService.getUsersByOrgId(currentOrg).then((result)=>{
                if(result&&result.status===200){
                    if(result.data.timeZone){
                        let find = timezoneData.find(item=> item.text===result.data.timeZone.split('|')[0])
                        if(find){
                            setSelectedTimezone(find);
                        }
                    }
                }
            });
        }
        
    },[currentOrg])


    const jump2Office =()=>{
        let url = process.env.REACT_APP_LIVESOURCING_BASE + currentOrg;
        const w = window.open('about:blank');
        w.location.href= url;
    }

    const handleSettingsOpenClose = ()=>{
        setSettingsOpen(false);
    }

    const submitSettingsChangeHandler =()=>{
        let valid = true;
        console.log(selectedTimezone);
        if(!selectedTimezone){
            valid = false;
            setTimezoneError(true);
        }
        if(valid){
            let submitValue ={
                'timeZone': selectedTimezone.text + '|' + selectedTimezone.offset.toString()
            }
            organizationService.updateOrgTimezone(currentOrg,submitValue).then(result=>{
                if(result.status===200){
                    setSettingsOpen(false);
                }
            })
        }
    }

    return (
        <CommonLayout>
            <div className={classes.box}>
                <OrganizationSelect onSetIniting={setIniting} onSetOpenCreateOrg={setOpenCreateOrg} onSetCurrentOrg={setCurrentOrg} onSetOrgExpired={setOrgExpired} initing={initing} openCreateOrg={openCreateOrg} currentOrg={currentOrg} orgExpired={orgExpired}></OrganizationSelect>
                {
                    (!initing && (openCreateOrg || !currentOrg)) &&
                    <Grid>
                        <div style={{padding:'20px'}}>
                            <Typography variant="body1" style={{marginBottom:"20px"}}>
                            {t('liveSourcingMain.createOrgTips')}
                            </Typography>
                            <Grid container spacing={4}>
                                <Grid item md={8} xs={12}>
                                    <img src={inventoryDescriptionPic1} srcSet={`${inventoryDescriptionPic1} 300w, ${inventoryDescriptionPic2} 768w, ${inventoryDescriptionPic3} 1280w`} style={{width:"100%",objectFit:"contain"}}></img>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography variant="body1" style={{color:"#818181"}}>
                                        {t('liveSourcingMain.description')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        
                    </Grid>
                }
                {(!initing && !openCreateOrg && currentOrg)&&<div>
                    <Button variant="outlined" size="large" onClick={()=>jump2Office()}>
                        <Typography variant="body1" style={{color:"#2DA44E"}}>
                            Enter Meta Office
                        </Typography>
                    </Button>
                    {OrgRoleReader.IsOrgAdmin(currentOrg,application)&&<IconButton onClick={()=>setSettingsOpen(true)}>
                        <SettingsIcon style={{color:'blue'}} fontSize="large"/>
                    </IconButton>}
                </div>}
                <Dialog open={settingsOpen} onClose={handleSettingsOpenClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title" className={classes.dialogBox}>
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="body1">
                            Settings
                        </Typography>
                    </DialogTitle>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleSettingsOpenClose}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Typography variant="body1">
                            Time Zone
                        </Typography>
                        <Autocomplete
                            id="combo-box-demo"
                            options={timezoneData}
                            value={selectedTimezone}
                            getOptionLabel={(option) => option.text}
                            onChange={(e,newValue)=>{
                                setSelectedTimezone(newValue);
                                if(newValue){
                                    setTimezoneError(false);
                                }
                            }}
                            fullWidth
                            renderInput={(params) => <TextField {...params} error={timezoneError} margin="dense" variant="outlined" />}
                        />
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={loading} variant="contained">
                            <Typography variant="body2" onClick={submitSettingsChangeHandler}>
                                {t('common.confirm')}
                            </Typography>
                        </LoadingButton>
                        <Button variant="contained"  onClick={handleSettingsOpenClose}>
                            <Typography variant="body2" >
                                {t('common.cancel')}
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </CommonLayout>
    )
}