import './RegisterPage.style'
import {useEffect, useState} from "react";
import iamService from '../../services/iamService'
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '12% 20%',
    },
    hintsText: {
        color: theme.currentTheme.colors[3],
        marginBottom: theme.spacing(5)
    },
}));

export default function RegisterSuccessPage(props){
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const [countdown, setCountdown] = useState(6);

    useEffect(()=>{
        if(countdown > 0) {
            setTimeout(()=> {
                setCountdown(countdown - 1)
            }, 1000)
        } else {
            window.location.href = iamService.loginURL()
        }
    },[countdown])

    return (
        <div className={classes.container}>
            <Typography variant='h2' gutterBottom>
                {t('userReg.pendingTitle')}
            </Typography>
            <Typography variant='h4' className={classes.hintsText}>
                {t('userReg.successContent1')}
            </Typography>
            <Typography variant='h6' gutterBottom>
                {t('userReg.successContent2')}
            </Typography>
            <Typography variant='h6' className={classes.hintsText}>
                ( {t('userReg.successContent3')} {countdown}S )
            </Typography>
        </div>
    )
}