import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import React, { useContext, useRef } from 'react';
import {
  FormControl,
  OutlinedInput,
  IconButton,
  Box,
  Grid,
  Button,
  Select,
  InputLabel,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useState, setUseState } from 'react';
import ReplyIcon from '@material-ui/icons/Reply';
import LoadingButton from "../../components/LoadingButton";

import inventoryService from "../../services/inventoryService"
import organizationService from "../../services/organizationService";
import {
  useHistory
} from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { KeyboardReturnOutlined } from '@material-ui/icons';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "1200px",
    backgroundColor: theme.currentTheme.backgrounds[7],
    // border: theme.currentTheme.border[2],
    borderRadius: '8px'
  },
  box: {
    padding: '30px 30px 10px 30px',
    // color: theme.currentTheme.colors[0],
  },
  box1: {
    padding: '10px 30px 30px 30px',
  },
  box2: {
    width: "90%",
    marginLeft: "1%",
    marginTop: "2%",
    display: "inline-flex",
    flexFlow: "column",
  },
  text: {
    color: theme.currentTheme.colors[0],
  },
  text2: {
    // color: theme.currentTheme.colors[5],
  },
  width1: {
    width: '100%',
  },
  width2: {
    width: '90%',
  },
  buttonDiv: {
    display: 'flex',


    // flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px',
  },
  field_name_red: {
    color: "#C00000",
  },
  input: {
    height: "32px !important",
    fontFamily: "Lato",
    fontSize: "15px",
    marginTop: "1%",
    fontFamily: "Lato",
  },
  box_left: {
    width: "30%",
    marginTop: "2%",
    marginLeft: "0%",
    display: "inline-flex",
    flexFlow: "column",
  },
  box_right: {
    width: "30%",
    marginTop: "2%",
    marginLeft: "22%",
    display: "inline-flex",
    flexFlow: "column"
  },
  box_add: {
    marginTop: "20px",
    display: "inline-flex",
    marginBottom: "20px",
  },
  buttonProgress: {
    color: '#0e78f9',
    position: 'absolute',
    top: '50%',
    left: '0',
    marginTop: -12,
    marginLeft: 5,
  },
  errorMsg: {
    color: '#ff4d4f',
    marginTop: '20px',
  },
  msg: {
    color: '#0e78f9',
    marginTop: '20px',
  },
  disable_box: {
    display: "none",
    marginLeft: "1%",
    marginTop: "2%",
  },
  input: {
    // border: theme.currentTheme.border[1],
    // backgroundColor: '#fafbfc',
    // color: 'black',
    borderRadius: '4px',

  },
  select: {
    height: "32px !important",
    width: "500px !important",
    fontFamily: "Lato",
    fontSize: "15px",
    marginTop: "1%",
  },
  inputErrMsg: {
    color: '#ff4d4f'
  },
  hrColor: {
    border: theme.currentTheme.border[2],
  },
  section1: {
    margin: theme.spacing(0, 1),
  },
  section2: {
    margin: theme.spacing(1),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  errorShow: {
    display: '',
    color: 'red',
  },
  errorNotShow: {
    display: 'none'
  },
  buttonSpacing: {
    width: "100px",
    marginLeft: "10px",
  },
  buttonSpacing1: {
    marginLeft: "10px",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditWarehouse(props) {

  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const [name, setName] = useState("");
  const [staff, setStaff] = useState([]);
  const [staffError, setStaffError] = useState(false);
  const [staffInput, setStaffInput] = useState('');

  const [id, setId] = useState("");
  const [orgId, setOrgId] = useState("");

  const [manager, setManager] = useState(null);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [friends, setFriends] = useState([]);

  const history = useHistory();

  const [managerError, setManagerError] = useState(false);
  const [isMaterial, setIsMaterial] = useState(false);

  const [currentOrg, setCurrentOrg] = useState('');
  const filter = createFilterOptions();
  const submitValues = {}
  const wareHouseId = props.wareHouseId;

  React.useEffect(() => {
    // console.log(1111111111111111111111111111)



    // console.log("wareHouseId:" + wareHouseId)

    inventoryService.getWarehouse(wareHouseId).then((result) => {
      // console.log(result.data);
      setId(result.data.id);
      setName(result.data.name);
      setPhone(result.data.phone);
      setAddress(result.data.address);
      setNotes(result.data.notes);
      setOrgId(result.data.orgId);
      if(result.data.isMaterial&&result.data.isMaterial===true){
        setIsMaterial(true);
      }
      else{
        setIsMaterial(false);
      }
      let managerTemp = result.data.manager
      let staffTemp = result.data.staff

      organizationService.getUsersByOrgId(result.data.orgId).then((result) => {
        if (result.data && result.data.users) {
          setFriends(result.data.users);
          result.data.users.map(item=>{
            if(managerTemp === item.email){
              setManager({
                value: item.email,
                label: item.email,
              });
            }
          })
          let staffArray = [];
          if(staffTemp&&staffTemp.length>0){
            staffTemp.map(item=>{
              let staffItem = item;
              result.data.users.map(item=>{
                if(staffItem === item.email){
                  staffArray.push({
                    value: item.email,
                    label: item.email,
                  });
                }
              })
            })
            setStaff(staffArray);
          }
        }
      })
      

    })

    
  }, [])


  const handleClose = () => {
    props.onClose();
  }
  const onEdit = () => {
    let valid = true;
    if (!manager) {
      setManagerError(true);
      valid = false;
    }
    else {
      setManagerError(false)
    }

    if (staff.length===0) {
      setStaffError(true)
      valid = false;
    }
    else {
      setStaffError(false)
    }

    if(!valid){
      return;
    }
    submitValues.orgId = orgId
    submitValues.id = id
    submitValues.manager = manager.value
    submitValues.phone = phone
    submitValues.address = address
    submitValues.notes = notes
    submitValues.isMaterial = isMaterial

    let staffEmail = [];
    staff.map(item=>{
      staffEmail.push(item.value);
    })
    submitValues.staff = staffEmail;

    console.log(submitValues)
    inventoryService.updateWarehouse(wareHouseId, submitValues).then((result) => {
      props.onRefresh();
      props.onClose();
    })
  };





  return (
    <div className={classes.box}>
      <Dialog open={props.open} scroll="body" classes={{ paper: classes.dialogContainer, }} onClose={handleClose} maxWidth="md" fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="alert-dialog-slide-title">{t('warehouse.editHouse') + ' - ' + name}</DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Divider variant="middle"></Divider>
        <DialogContent>
          <div className={classes.root}>


            <form className={classes.box1} noValidate autoComplete='off'>
              <div>
                <Grid item>
                  <div className={classes.section2}>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" >

                      <Grid item xs={6}>
                        <div>
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                            {t('warehouse.id')}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div>
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                            {t('warehouse.manager')}<span className={classes.field_name_red}>*</span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start">

                      <Grid item xs={6}>

                        <FormControl variant='outlined' size='small' className={classes.width1}>
                          <OutlinedInput className={classes.input}
                            value={id}
                            variant="outlined"
                            // placeholder={values.fields.purchaserName.holder}
                            //  error={nameError}
                            onChange={(event) => { setId(event.target.value); }}
                          />
                        </FormControl>
                      </Grid>


                      <Grid item xs={6}>

                        <Autocomplete
                          freeSolo
                          fullWidth
                          selectOnFocus
                          size='small'
                          className={classes.input}
                          value={manager}
                          options={[]}
                          // onChange={(e,newValue)=>{
                          //   setManager(newValue)
                          //   if(!newValue){
                          //     setManagerError(true);
                          //   }
                          //   else{
                          //     setManagerError(false);
                          //   }
                          //   console.log(newValue)
                          // }}
                          onInputChange={(e,newValue)=>{
                            let find = friends.find(item=>{
                              return item.email ===newValue;
                            });
                            if(find){
                              setManagerError(false);
                              let found = {
                                value: find.email,
                                label: find.email,
                              }
                              setManager(found);
                            }
                            else{
                              setManagerError(true);
                            }
                            // console.log(newValue);
                          }}
                          getOptionLabel={(option) => option.label}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              {option.label}
                            </React.Fragment>
                          )}

                          // onInputChange={handleManagerChange}
                          // filterOptions={(options, params) => {
                          //   const filtered = filter(options, params);
                          //   let keyword = params.inputValue.toLowerCase();
                          //   // Suggest the creation of a new value
                          //   friends.map((item,index)=>{
                          //     if(item.email === keyword){
                          //       filtered.push({
                          //         value: item.email,
                          //         label: `${item.username} ( ${item.email} )`,
                          //       })
                          //     }
                          //   })
                    
                          //   return filtered;
                          // }}
                          renderInput={(params) => <TextField {...params} onKeyDown={e => {
                            if (e.keyCode === 13) {
                              e.stopPropagation();
                              e.preventDefault();
                            }
                          }} variant="outlined" error={managerError} />}
                        />                        
                      </Grid>

                    </Grid>
                  </div>
                  <div className={classes.section2}>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" >
                      <Grid item xs={6}>
                        <div >
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                            {t('warehouse.phone')}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div>
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                            {t('warehouse.address')}
                          </Typography>
                        </div>
                      </Grid>

                    </Grid>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start">
                      <Grid item xs={6}>

                        <FormControl variant='outlined' size='small' className={classes.width1}>
                          <OutlinedInput className={classes.input}
                            value={phone}
                            variant="outlined"
                            onChange={(event) => {
                              setPhone(event.target.value);
                            }}


                          // onChange={handleChange('purchaserName')}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>

                        <FormControl variant='outlined' size='small' className={classes.width1}>
                          <OutlinedInput className={classes.input}
                            value={address}
                            variant="outlined"
                            onChange={(event) => {
                              setAddress(event.target.value);
                            }}
                          />
                        </FormControl>
                      </Grid>

                    </Grid>
                  </div>
                  <div className={classes.section2}>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" >
                      <Grid item xs={12}>
                        <div >
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                            {t('warehouse.staff')}<span className={classes.field_name_red}>*</span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start">
                      <Grid item xs={12}>

                        <FormControl variant='outlined' size='small' className={classes.width1}>
                          <Autocomplete
                            freeSolo
                            multiple
                            fullWidth
                            selectOnFocus
                            size='small'
                            className={classes.input}
                            value={staff}
                            options={[]}
                            inputValue={staffInput}
                            onInputChange={(e,newValue)=>{
                              // console.log(newValue);
                              setStaffInput(newValue);
                              let find = friends.find(item=>{
                                return item.email ===newValue;
                              });
                              if(find){
                                setStaffInput('');
                                setStaffError(false);
                                let found = {
                                  value: find.email,
                                  label: find.email,
                                }
                                let tempStaff = staff;
                                tempStaff.push(found);
                                setStaff(tempStaff);
                                // console.log(newValue);
                              }
                              else{
                                setStaffError(true);
                              }
                              // console.log(newValue);
                            }}
                            onChange={(e,newValue)=>{
                              setStaff(newValue)
                              if(newValue.length===0){
                                setStaffError(true);
                              }
                              else{
                                setStaffError(false);
                              }
                              // console.log(newValue)
                            }}
                            getOptionLabel={(option) => option.label}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                {option.label}
                              </React.Fragment>
                            )}

                            // onInputChange={handleManagerChange}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              let keyword = params.inputValue.toLowerCase();
                              // Suggest the creation of a new value
                              friends.map((item,index)=>{
                                if(item.email === keyword){
                                  filtered.push({
                                    value: item.email,
                                    label: `${item.username} ( ${item.email} )`,
                                  })
                                }
                              })
                      
                              return filtered;
                            }}
                            renderInput={(params) => <TextField {...params} onKeyDown={e => {
                              if (e.keyCode === 13) {
                                e.stopPropagation();
                                e.preventDefault();
                              }
                            }} variant="outlined" error={staffError} />}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.section2}>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" >
                    <Grid item xs={6}>
                      <div >
                        <Typography variant='body2' gutterBottom className={classes.text2}>
                          {t('warehouse.notes')}
                        </Typography>
                      </div>

                    </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start">

                      <Grid item xs={12}>
                      <FormControl variant='outlined' size='large' className={classes.width1}>
                        <TextField className={classes.input}
                          value={notes}
                          variant="outlined"
                          multiline
                          rows={4}
                          // placeholder={values.fields.purchaserName.holder}

                          onChange={(event) => {
                            setNotes(event.target.value);
                          }}
                        />
                        <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center" style={{marginTop:'8px'}}>
                          <Grid item xs={2}>
                              <Typography variant='body2' className={classes.text2}>
                              {t('warehouse.isMaterial')}
                              </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Switch
                                checked={isMaterial}
                                disabled
                                onChange={event=>{
                                    setIsMaterial(event.target.checked);
                                    // getFilerResult(event.target.checked,selLotNo,checkOutQtyForStorage,selGood);
                                }}
                                color="secondary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container alignItems="center" justifyContent="center" direction="row">
                <Grid className={classes.buttonDiv} >
                  <div>
                    <LoadingButton
                      className={classes.buttonSpacing}
                      variant='contained'
                      onClick={onEdit}
                    >
                      {t('common.confirm')}
                    </LoadingButton></div>
                  <div>
                    <Button
                      className={classes.buttonSpacing}
                      variant='contained'
                      onClick={handleClose}
                    >
                      {t('common.cancel')}
                    </Button></div>
                </Grid>
              </Grid>
                      </FormControl>

                    </Grid>
                    </Grid>
                  </div>

                </Grid>
              </div>



              

            </form>
          </div>
        </DialogContent>





      </Dialog>
    </div>






  );
}