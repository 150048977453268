function userSortByRole(users){
    users.map(item => {
        if(item.user_role[0]==="Org_Admin"){
            item.role_level = 0;
        }else if(item.user_role[0]==="Org_Manager"){
            item.role_level = 1;
        }else if(item.user_role[0]==="Org_Member"){
            item.role_level = 2;
        }
    })
    users.sort(function(a,b){
        return (a.role_level - b.role_level)
    }) 
    return users;
}

export default {
    userSortByRole
}
  