import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

export default function FormFunction(){
    const {t, i18n} = useTranslation();
    const [fields, setFields] = useState({});

    useEffect( ()=> {
        setFields({
            email: {
                title: t('userReg.Email'),
                name: 'email',
                valid: true,
                errorMsg: null,
                value: '',
                editable: true,
                rules: {
                    required: true,
                    requiredMessage: t('userReg.emailMsg1'),
                    regex: /^[-!#$%&\'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&\'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
                    regexMessage: t('userReg.emailMsg2'),
                }
            },
            password: {
                title: t('userReg.newPsd'),
                name: 'password',
                valid: true,
                errorMsg: '',
                value: '',
                rules: {
                    required: true,
                    requiredMessage: t('userReg.passMsg1'),
                    regex: '(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}',
                    regexMessage: t('userReg.passMsg2'),
                }
            },
            repassword: {
                title: t('userReg.rePsd'),
                name: 'repassword',
                valid: true,
                errorMsg: '',
                value: '',
                rules: {
                    required: true,
                    requiredMessage: t('userReg.repsdMsg'),
                }
            },
            companyName: {
                title: t('userReg.company'),
                name: 'companyName',
                errorMsg: '',
                value: '',
                valid: true
            },
            nickName: {
                title: t('userReg.name'),
                name: 'nickName',
                value: '',
                valid: true,
                rules: {
                    required: true,
                    requiredMessage: t('userReg.nameMsg'),
                }
            },
            location: {
                title: t('userReg.location'),
                name: 'location',
                value: '',
                valid: true,
                rules: {
                    required: true,
                    requiredMessage: t('userReg.locationMsg'),
                }
            }
        });
    }, []);

    const handleChange = function(fieldName, newValue) {
        const data = { ...fields }
        const field = data[fieldName];
        field.value = newValue;
        field.valid = checkValid(field, newValue);
        setFields(data)
    }

    const isFormValid = function() {
        let formValid = true
        const data = { ...fields }
        Object.keys(data).forEach((key)=> {
            formValid = checkValid(data[key], data[key].value) && formValid;
        })
        setFields(data);
        return formValid;
    }

    const checkValid = function(field, newValue) {
        let valid = checkRequired(field, newValue);

        if(valid) {
            if(field.name === 'email' || field.name === 'password') {
                valid = checkRegex(field, newValue);
            } else if(field.name === 'repassword') {
                valid = checkSame();
            }
        }

        return valid;
    }

    const checkRequired = function(field, newValue) {
        let isRequired = field.rules && field.rules.required;
        let valid = true;
        if(isRequired) {
            if (typeof newValue === "string") {
                valid = !!newValue && !!newValue.trim();
            } else if (typeof newValue === "undefined") {
                valid = false;
            } else if (typeof newValue === "object") {
                valid = !!newValue;
            }
        }
        if(!valid) {
            field.errorMsg = field.rules.requiredMessage;
        } else {
            field.errorMsg = '';
        }
        field.valid = valid;
        return valid;
    }

    const checkRegex = function(field, newValue) {
        let valid = true;
        const strongRegex = new RegExp(field.rules.regex)
        if (!strongRegex.test(newValue) ) {
            valid = false;
            field.errorMsg = field.rules.regexMessage;
        }
        return valid;
    }

    const checkSame = function() {
        let valid = true;
        if(fields.password.value !== fields.repassword.value) {
            valid = false;
            fields.repassword.errorMsg = t('userReg.errorMsg3');
        }
        return valid;
    }

    return {
        fields: fields,
        setFields: setFields,
        isFormValid: isFormValid,
        handleChange: handleChange,
    }
}