import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React, {useEffect, useState} from "react";
import accountingService from "../../services/accountingService";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    selectBtn: {
        width: '100px',
        "&:hover": {
            backgroundColor: 'transparent',
            color: 'green',
        },
    },
}));

export default function ColumnsSelectDialog(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [onSuccess, onCancel] = [props.onSuccess, props.onCancel];
    const [columns, columnSelection] = [props.columns, props.columnSelection];
    const [showColumns, setShowColumns] = useState([]);
    const [arrangedColumns, setArrangedColumns] = useState([]);
    const [open, setOpen] = useState(false);
    const lineCapacity = 6;
    const totalCapacity = columns.length;

    useEffect(() => {
        if(columns && Array.isArray(columns) && columns.length > 0) {
            let processColumns = [];
            //Ignore the first column, it's fixed.
            for(let i=1; i < columns.length; i+=lineCapacity) {
                processColumns.push(columns.slice(i, i + lineCapacity))
            }
            console.log(processColumns);
            setArrangedColumns(processColumns);
            setShowColumns(columnSelection);
            setOpen(true);
        }
    }, [columns]);

    const getShowColumns = (lineIndex, columnIndex) => {
        let index = lineIndex * lineCapacity + columnIndex + 1;
        return showColumns[index] === 1;
    }

    const allShowColumns = () => {
        setShowColumns(Array.from({length: totalCapacity}, () => 1));
    }

    const deselectShowColumns = () => {
        setShowColumns(Array.from({length: totalCapacity}, (_, index) => index === 0 ? 1 : 0));
    }

    const handleChange = (target, lineIndex, columnIndex) => {
        let showIndex = lineIndex * lineCapacity + columnIndex + 1;
        showColumns[showIndex] = target.checked? 1 : 0;
        setShowColumns(showColumns.slice());
    };

    const saveSettings = () => {
        accountingService.userSettings(
          {
              settingType: 'show_ledger_column',
              'settingData': [
                  showColumns.join(''),
              ],
          }
        ).then((_) => {
            setOpen(false);
            if(onSuccess)
                onSuccess(showColumns);
        });
    }

    const closeHandler = ()=> {
        setOpen(false);
        if(onCancel) {
            onCancel()
        }
    }

    return (
      <Dialog open={open} onClose={closeHandler} maxWidth="md" fullWidth aria-labelledby="form-dialog-title">
          <DialogContent>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Typography variant="body2" style={{fontSize: '18px'}}>{t('poFormPage.display_column')}</Typography>
                  <Button className={classes.selectBtn} onClick={allShowColumns} style={{marginLeft: '30px'}}>
                      <Typography variant="body1" style={{fontSize: '14px'}}>
                          {t('common.all_selected')}
                      </Typography>
                      <CheckIcon />
                  </Button>
                  <Button className={classes.selectBtn} onClick={deselectShowColumns}>
                      <Typography variant="body1" style={{fontSize: '14px'}}>
                          {t('common.all_not_selected')}
                      </Typography>
                      <ClearIcon />
                  </Button>
              </div>
              <table style={{marginTop: '20px'}}>
                  {
                      arrangedColumns.map((lineColumns, lineIndex)=> {
                          return (<tr>
                              {lineColumns.map((item, index)=> {
                                  return (
                                    <td>
                                        <FormControlLabel style={{marginLeft: index===0? '0px': '10px'}}
                                                          control={<Checkbox color="primary" checked={getShowColumns(lineIndex, index)}
                                                          onChange={(e)=>handleChange(e.target, lineIndex, index)}
                                                          name={'select-column-'+lineIndex+'-'+index}/>}
                                                          label={<Typography variant="body2">{item.label}</Typography>}
                                        />
                                    </td>
                                  )
                              })}

                          </tr>)
                      })
                  }
              </table>

          </DialogContent>

          <DialogActions >
              <Button variant="contained" color="primary" onClick={saveSettings}>
                  <Typography variant="body2">
                      {t('common.confirm')}
                  </Typography>
              </Button>
              <Button variant="contained" onClick={closeHandler}>
                  <Typography variant="body2">
                      {t('common.cancel')}
                  </Typography>
              </Button>
          </DialogActions>
      </Dialog>
    )
}