import {axiosNotification as axios} from './axiosHelper';

const getUnReadMessages = function(){
    const url = '/auth/messages/new';
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getHistoryMessages = function(){
    const url = '/auth/messages/history';
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const readMessages = function(messages){
    const url = '/auth/messages/read';
    return new Promise((resolve, reject)=>{
        axios.post(url,messages).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

export default {
    getUnReadMessages,
    readMessages,
    getHistoryMessages
}