import CommonLayout from "../../components/CommonLayout";
import React,{useContext}  from 'react';
import {
    Link, useParams, useHistory
} from "react-router-dom";
import VideocamIcon from '@material-ui/icons/Videocam';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Select, MenuItem,FormControl,InputLabel} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import MeetingList from '../../components/booking/MeetingList';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { Component, useEffect, useState, useRef} from 'react';
import useToken from '../../hooks/useToken';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import './booking-main.css';
import { ApplicationContext } from "../../context/applicationContext";
import organizationService from "../../services/organizationService";
// import CreateOrg from "../intro/create-org"
import OrgRoleReader from "../../utils/orgRoleReader";
import orgRoleReader from "../../utils/orgRoleReader";
import userService from "../../services/userService";
import serviceDescriptionPic1 from '../../assets/img/service_description_1.png';
import serviceDescriptionPic2 from '../../assets/img/service_description_2.png';
import serviceDescriptionPic3 from '../../assets/img/service_description_3.png';
import MeeingEdit from "../../components/booking/MeetingEdit";
import OrganizationSelect from "../../components/OrganizationSelect";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '50px'
    },
    box: {
        padding: '30px',
        width: '100%',
    },
    dialogRoot: {
        minHeight: '500px',
        marginTop: '10px',
    },
    dialogHr: {
        border: '1px',
        dashed: '#000',
        marginTop: '20px',
        marginBottom: '20px'
    },
    required: {
        color: '#981414',
    },
    outlinedPadding: {
        padding: '11px 14px',
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        margin: '4px'
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
        margin: '4px'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    selectedmargin: {
        borderRadius: '4px',
        marginTop: '8px',
        marginBottom: '4px'
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft:0,
        width: '100%'
    },
    link: {
        textDecoration: "none",
    },
    errorShow: {
        display:'',
        color:'red',
    },
    errorNotShow: {
        display:'none'
    },
    orangeButton:{
        backgroundColor:'#ff7247',
        '&:hover': { 
            backgroundColor: '#ff7247',
        },
    }
}));

export default function BookingMain() {
    const classes = useStyles();
    const { token } = useToken();
    const history = useHistory();
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [editDetail, setEditDetail] = React.useState(false);
    const [initing, setIniting] = React.useState(true);
    const [orgExpired, setOrgExpired] = React.useState(false);
    const [openCreateOrg, setOpenCreateOrg] = React.useState(false);
    const [canAddNewOrg, setCanAddNewOrg] = useState(true);
    const [newMeeting, setNewMeeting] = useState({});
    const meetingListRef = useRef();
    
    const [createOrgURL,setCreateOrgURL] = useState('');
    useEffect(() => {
        userService.getUserDetailsByEmail(token.username).then((result)=>{
            if(result&&result.status===200){
                console.log(result)
                if(result.data.orgNumber>=10){
                    setCanAddNewOrg(true);
                }
                else{
                    setCanAddNewOrg(false);
                }
            }
        });
    },[])

    const {application,refreshApplication} = useContext(ApplicationContext);
    useEffect(()=>{
        if(application){
            const url = `/management/services/${application.name}/organizations/create`;
            setCreateOrgURL(url);
            console.log(token)
        }
        if(application && application.organizations && application.organizations.length > 0){
            // setCurrentOrg(application.organizations[0].id);
            // console.log(openCreateOrg);
            // console.log(application.organizations[0].id)
            // console.log(currentOrg);
            setOpenCreateOrg(false);
            setIniting(false);
        }else{
            if(application && application.organizations && application.organizations.length === 0){
                setIniting(false);
                setOpenCreateOrg(true);
            }
        }   
    },[application])

    const [currentOrg, setCurrentOrg] = useState('');
    useEffect(()=>{
        if(currentOrg && application){
            if(new Date(OrgRoleReader.getOrgExpiredDateById(currentOrg,application)) < new Date()){
                setOrgExpired(true)
                // setOrgExpired(false);
            }
        }
    },[currentOrg,application]);

    const handleClickOpen = () => {
        let values = {};
        values.title = '';
        values.agendaItems = [{ value: "" }];
        values.startTime = moment(new Date());
        values.startDate=moment(new Date());
        values.duration=30;
        values.organizationId=currentOrg;
        values.host= token.username;
        values.participants=[];
        values.requests=[];
        values.guestCanJoin = false;
        setNewMeeting(values);
        setEditDetail(true);
    };

    function handleRefresh(){
        meetingListRef.current.refreshMeetingList();
    }
    
    function editDetailCloseHandler(){
        setEditDetail(false);
    }


    const handleDateChange = (date) => {
        setSelectedDate(new Date(date));
    };
    return (
        <CommonLayout>
            <div className={classes.box}>
            <OrganizationSelect onSetIniting={setIniting} onSetOpenCreateOrg={setOpenCreateOrg} onSetCurrentOrg={setCurrentOrg} onSetOrgExpired={setOrgExpired} initing={initing} openCreateOrg={openCreateOrg} currentOrg={currentOrg} orgExpired={orgExpired}></OrganizationSelect>

            
                {
                    (!initing && (openCreateOrg || !currentOrg)) &&
                    <Grid>
                        <div style={{padding:'20px'}}>
                        <Typography variant="body1" style={{marginBottom:"20px"}}>
                        Create your own organization, and you will find that you immediately have the right of admin to use Open Comm. 
                        </Typography>
                        <div width="100%" style={{textAlign:"center"}}>
                        <img src={serviceDescriptionPic1} srcSet={`${serviceDescriptionPic1} 300w, ${serviceDescriptionPic2} 768w, ${serviceDescriptionPic3} 1280w`} style={{width:"100%",objectFit:"contain"}}></img>
                        </div>
                        <Typography variant="body1" style={{marginTop:"20px",color:"#818181"}}>
                        <b style={{fontFamily:"IBMPlexSans-Bold"}}>Open Comm</b> is a solution for both of purchasers and suppliers and enables supplier to host a trade show anywhere in the world, in your office, manufacturing facility, or at home. 
                        </Typography>
                        <Typography variant="body1" style={{color:"#818181"}}>
                            As a host, you can invite your business partners to join a video meeting to show and tell your products and also use the <span style={{fontFamily:"IBMPlexSans-Italic"}}>Video Touring</span> to give your customers a real time tour to your factory. Furthermore, you can ask your customers for their inquiries and sign a pre-agreement.
                            </Typography>
                            <Typography variant="body1" style={{color:"#818181"}}>
                        <b style={{fontFamily:"IBMPlexSans-Bold"}}>Open Comm</b> facilitates the show for the host and presents more than a physical booth does. <b style={{fontFamily:"IBMPlexSans-Bold"}}>Open Comm</b> benefits enterprises not only avoiding travel, but also increasing efficiency and saving cost.                        </Typography>
                        </div>
                        
                    </Grid>
                }
            {(!initing && !openCreateOrg && currentOrg)&&<Grid container spacing={4} justifyContent="center" direction="row" alignItems="flex-start">
                <Grid xs={12} md={6} item>
                    <Grid container spacing={2} justifyContent="center" direction="row" alignItems="center">
                    <Grid xs={12} item>
                            <Button onClick={handleClickOpen} variant="contained" color="primary" size="large">
                                <VideocamIcon />
                                <Typography variant="body2" style={{paddingLeft:'6px'}}>New Meeting</Typography>
                            </Button>
                            {editDetail&&<MeeingEdit open={editDetail} method="new" meet={newMeeting} onClose={editDetailCloseHandler} onRefresh={handleRefresh}></MeeingEdit>}
                        </Grid>
                        {/* <Grid xs={6} item>
                            <Link onClick={handleJoinLatest}>

                                <div className="big-button">
                                    <div className="big-button-icon">
                                        <VideoCallIcon style={{ fontSize: '48px', color: "white" }} />
                                    </div>
                                    <div className="big-button-content">
                                        <Typography variant="body1">Join Latest Meeting</Typography>
                                        <Typography variant="body2">join the latest meeting</Typography>
                                    </div>
                                </div>
                            </Link>
                        </Grid> */}
                        <Grid xs={12} item>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker disableToolbar
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    variant="static"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6} item className={classes.root}>
                    <MeetingList ref={meetingListRef} date={selectedDate.toDateString()} orgId={currentOrg}></MeetingList>
                </Grid>
            </Grid>
            }
            </div>
        </CommonLayout >
    )
}