import { makeStyles } from '@material-ui/core/styles';
import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import PasswordInput from "./PasswordInput";
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
    hintsDiv: {
        color: '#6e6e6e',
        marginTop: theme.spacing(1)
    }
}));

export default function PasswordWithHint(props) {
    const classes = useStyle();
    const {t, i18n} = useTranslation();

    const {title, value, valid, errorMsg} = props.field;
    const field = props.field;
    const handleChange = (e)=> {
        if(props.onChange) {
            props.onChange(e.target.value)
        }
    };

    return (
        <div style={{width: '100%'}}>
            <PasswordInput title={title}
                           initValue={value}
                           valid={valid}
                           errorMsg={errorMsg}
                           onChange={handleChange}
            ></PasswordInput>
            <div className={classes.hintsDiv}>
                <Typography variant="body2" >{t('userReg.infoMsg1')}</Typography>
                <ul>
                    <li><Typography variant="body2" >{t('userReg.infoMsg2')}</Typography></li>
                    <li><Typography variant="body2" >{t('userReg.infoMsg3')}</Typography></li>
                    <li><Typography variant="body2" >{t('userReg.infoMsg4')}</Typography></li>
                </ul>
            </div>
        </div>
    )
}