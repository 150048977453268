import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {useEffect, useState} from "react";
import moment from "moment";
import superAdminService from "../../services/superAdminService";
import CommonLayout from "../../components/CommonLayout";
import useToken from '../../hooks/useToken';
import Buttos from '../design-system/button';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, Button } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    box: {
      padding: '30px 30px 10px 30px',
      width:'100%',
      maxWidth:'1200px'
    },
    originInput:{
        textAlign:"center"
    }
}))
export default function UserList(props){
    const classes = useStyles();
    const columnsConfig = [
        {title: "Email", field: 'email', width: "20%"},
        {title: "Company", field: 'company_name', width: "25%"},
        {title: "Name", field: 'username', width: "15%"},
        {title: "Inviter", field: 'inviter', width: "20%"},
        {title: "Create Date", field: 'createdAt', width: "10%"},
        {title: "State", field: 'stateText', width: "10%"}]
    const [userList, setUserList] = useState([]);

    useEffect(()=>{
        refreshUseList()
    },[])
    const [keyWords, setKeyWords] = useState("");
    const handleChange = (event) =>{
        setKeyWords(event.target.value);
    }
    const refreshUseList = ()=> {
        superAdminService.getUserList(keyWords).then((result)=> {
            if(result.code === 200) {
                result.data.forEach((item)=> {
                    item.stateText = item.state == 0? 'Invited': 'Registered'
                    item.createdAt = moment(item.createdAt).format("YYYY-MM-DD")
                })
                setUserList(result.data)
            }
        })
    }

    const rowStyle = {padding: "10px 0px", borderBottom: "1px solid #cdcdcd"}
    const searchStyle = {width: '50%', marginBottom: '20px'}
    return (
        <div className={classes.box}>
            <div className={classes.originInput}>
                <TextField variant="outlined" placeholder="Search for email or inviter" margin="dense" onChange={handleChange}/><Button onClick={refreshUseList} variant="contained" color="primary" size="large" style={{marginTop:"8px",marginLeft:"8px"}}>
                                        <SearchIcon></SearchIcon><span style={{marginLeft:'4px'}}>Search</span>
                                    </Button>
            </div>

            <div style={rowStyle}>
                {columnsConfig.map((column)=> {
                    return (<div style={{display: 'inline-block', width: column.width}}>{column.title}</div>)
                })}
            </div>

            {userList.map((request)=> {
                return (<div style={rowStyle}>
                    {columnsConfig.map((column,index)=> {
                        return (<div style={{display: 'inline-block',wordWrap:'break-word', width: column.width}} key={index}>
                            {request[column.field]}
                        </div>)
                    })}
                </div>)
            })}
        </div>
    )
}