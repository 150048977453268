import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import html2canvas from 'html2canvas';
import {
  FormControl,
  FormControlLabel,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import useToken from '../../hooks/useToken';
import userService from '../../services/userService';
import LoadingButton from '../../components/LoadingButton';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1,
    backgroundColor: theme.currentTheme.backgrounds[10],
    border: theme.currentTheme.border[3],
    borderRadius: '8px'
  },
  box: {
    padding: '30px 30px 10px 30px',
    color: theme.currentTheme.colors[0],
  },
  box1: {
    padding: '30px',
  },
  text: {
    color: theme.currentTheme.colors[0],
  },
  input: {
    border: theme.currentTheme.border[3],
    backgroundColor: '#fafbfc',
    color: 'black',
    borderRadius: '4px',
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '40px',
  },
  dialogButtonDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: '40px',
  },
  buttonProgress: {
    color: '#0e78f9',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorMsg: {
    color: '#ff4d4f',
    marginTop: '20px',
  },
  msg: {
    color: '#0e78f9',
    marginTop: '20px',
  },
  inputErrMsg: {
    color: '#ff4d4f'
  },
  hrColor:{
    border: theme.currentTheme.border[3],
  }
}));

export default function PersonalSignature() {

  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const search = queryString.parse(location.search);
  const {token, setToken} = useToken();
  let defaultFont = '';
  let defaultSignature = '';
  if (token && token.user_info && token.user_info.signature) {
    defaultSignature = token.user_info.signature.name;
    defaultFont = token.user_info.signature.font;
  }

  const [displayName, setDisplayName] = React.useState(defaultSignature);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [font, setFont] = React.useState(defaultFont);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
      setMessage('');

      let temp = {...errors};
      temp.changeSignatureError = ''

      if (!displayName) {
        temp.displayName = 'This field is required.';
      }

      setErrors({
        ...temp
      });

      if (temp && temp.displayName) {
        setLoading(false);
        return;
      }

      const domElement = document.getElementById(font);
      html2canvas(domElement).then((canvas) => {
        const base64 = canvas.toDataURL();
        console.log(base64);
        const body = {name: displayName, font: font, picture: base64};

        userService.submitSignature(token.username, body).then((result) => {
          token.user_info.signature = body;
          setToken(token);
          setLoading(false);
          if (search.meeting) {
            setIsModalVisible(true);
          }
        }).catch(e1 => {
          console.error(e1);
          setLoading(false);
        });

      }).catch(e2 => {
        console.error(e2);
        setLoading(false);
      });

    }
  }

  const handleRadioChange = (prop) => (event) => {
    defaultFont = event.target.value
    setFont(event.target.value);
  }

  const handleChange = (prop) => (event) => {
    setDisplayName(event.target.value);
    defaultSignature = event.target.value;
    validate({[prop]: event.target.value});
  };

  const validate = (fieldValues = displayName) => {
    let temp = {...errors};
    if ('displayName' in fieldValues) {
      temp.displayName = fieldValues.displayName ? '' : 'This field is required.';
      if (fieldValues.displayName) {
        if (fieldValues.displayName.length > 100) {
          temp.displayName = 'The signature you have entered is too long';
        } else {
          temp.displayName = '';
        }
      }
    }
    setErrors({
      ...temp
    });
  }

  const closeHintModal = () => {
    setIsModalVisible(false);
    window.close();
  }

  return (
    <div className={classes.root}>
      <Typography variant='body2' gutterBottom className={classes.box}>
        {t('mySignature.Signature')}
      </Typography>
      <hr className={classes.hrColor}/>
      <form className={classes.box1} noValidate autoComplete='off'>
        <Typography variant='body2' gutterBottom className={classes.text}>
        {t('mySignature.yourSignature')} *
        </Typography>
        <FormControl variant='outlined' size='small' fullWidth>
          <OutlinedInput
            id='outlined-display-name'
            // className={classes.input}
            value={displayName}
            onChange={handleChange('displayName')}
            onBlur={handleChange('displayName')}
            {...(errors['displayName'] && {error: true})}
          />
          {errors['displayName'] && (<FormHelperText><Typography className={classes.inputErrMsg} variant='caption'
                                                                 gutterBottom>{errors['displayName']}</Typography></FormHelperText>)}
        </FormControl>

        <RadioGroup style={{marginTop: '25px'}} value={font} onChange={handleRadioChange('font')}>
          <FormControlLabel value='HighSummit' control={<Radio/>}
                            label={<span id='HighSummit' style={{fontSize:'24px', fontFamily:'HighSummit'}}>{displayName}</span>} />
          <FormControlLabel value='Juliette' style={{marginTop: '40px'}} control={<Radio />}
                            label={<span id='Juliette' style={{fontSize:'24px', fontFamily:'Juliette'}}>{displayName}</span>} />
          <FormControlLabel value='MarckScript' style={{marginTop: '40px'}} control={<Radio />}
                            label={<span id='MarckScript' style={{fontSize:'24px', fontFamily:'MarckScript'}}>{displayName}</span>} />
        </RadioGroup>

        {errors['changeSignatureError'] && (<Typography className={classes.errorMsg} variant='body2' gutterBottom>{errors['changeSignatureError']}</Typography>)}
        {message && (<Typography className={classes.msg} variant='body2' gutterBottom>{message}</Typography>)}

        <div className={classes.buttonDiv}>
          <LoadingButton
            variant='contained'
            isLoading={loading}
            onClick={handleButtonClick}>
            {t('mySignature.updateSigButton')}
          </LoadingButton>
        </div>
      </form>

      <Dialog
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">

        <DialogTitle id="alert-dialog-title">{"Signature"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='body2'>
              Please go back to the meeting then
            </Typography>
            <Typography variant='body2'>
              click 'Yes, I've set my signature' button to continue
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.dialogButtonDiv}>
            <LoadingButton
              variant='contained'
              isLoading={loading}
              onClick={() => setIsModalVisible(false)}>
              Cancel
            </LoadingButton>
            <LoadingButton
              variant='contained'
              isLoading={loading}
              onClick={closeHintModal}>
              OK
            </LoadingButton>
          </div>
        </DialogActions>
      </Dialog>

    </div>
  );

}
