import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Button, IconButton, TextField, Select, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import FormatIndentIncreaseOutlinedIcon from '@material-ui/icons/FormatIndentIncreaseOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import LoadingButton from "../../components/LoadingButton";
import accountingService from "../../services/accountingService";
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    lineDateInput: {
        '& > *': {
            '& > *': {
                textAlign: "center"
            },
        },
    },
    buttonCol: {
        color: theme.currentTheme.colors[0],
    },
    tableStyle: {
        backgroundColor: theme.currentTheme.theme2backgrounds[1],
    },
    poFormContentItem: {
        padding: "30px 10px 0 10px",
        width: "100%",
        margin: "0",
    },
    poFormContentItem2: {
        padding: "0 0 50px 0",
    },
    itemTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    itemContent: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginTop: "20px"
    },
    required: {
        color: "#fd4747",
        display: "inline",
    },
    root: {
        borderBottom: "none ",
        fontSize: "medium",
        backgroundColor: theme.currentTheme.backgrounds[11],
    },
    dashedLine: {
        margin: "45px 0 20px 0",
        borderTop: "1px dashed #dbdbdb",
        width: "100%",
    },
    selectField: {
        padding: "11px 14px",
    },
    selectTopItem: {
        margin: "32px 0 12px 0",
    },
    selectTopItem2: {
        margin: "13px 0 12px 0",
    },
    dialogConvertHeader: {
        backgroundColor: "#e1f0f7"
    },
    dialogConvertBody: {
        padding: "0 0 30px 75px"
    },
    dialogConvertFooter: {
        padding: "0 50px 45px"
    },
    convertTitle:{
        width:'140px',
        margin:'8px 10px 8px 0px',
        display:'inline-block',
        height:'25px',
        fontSize:'14px'
    },
    convertLineTitle:{
        margin:'10px 0px 10px 0px',
    },
    outlinedPadding: {
        padding: '11px 14px',
    },
    selectedmargin:{
        marginRight:'8px'
    },
    confirmBtn: {
        width: 'calc(100% - 90px)',
    }
}))

export default function POToSO(props) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const [convertPoLines, setConvertPoLines] = React.useState([]);
    const [poLines, setPoLines] = React.useState([]);
    const [soData, setSoData] = React.useState(null);
    const [downloadFileType, setDownloadFileType] = React.useState("excel");
    const [loading, setLoading] = React.useState(false);    

    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>
        ),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const formatData = (value) => {
        let data = parseFloat(value).toFixed(4).replace(/d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
        return data;
    };

    const checkNumber = (values) => {
        let reg = /^\d*(\.\d{1,4})?$/;
        if (values && !reg.test(values)) {
            return false;
        }
        return true;
    }

    const checkDuplication = (rowData) => {
        if(rowData.tableData && rowData.tableData.editing){
            return true;
        }else{
            for (var i = 0; i < poLines.length; i++) {
                if (poLines[i]["lineNumber"] === rowData.lineNumber) {
                    return false;
                }
            }
            return true;
        }
    };

    useEffect(() => {
        accountingService.getSOByPOID(props.poId).then(result=>{
            if(result.status===200){
                setSoData(result.data);
                setPoLines(result.data.soLines);      
            }
        })

    }, [])

    const soNumberChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.soNumber = e.target.value;
        setSoData(copy_soData);
    }

    const soDateChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.soDate = new Date(e).toISOString();
        setSoData(copy_soData);
    }

    const soCustomerIdChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.customerId = e.target.value;
        setSoData(copy_soData);
    }

    const soCustomerNameChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.customerName = e.target.value;
        setSoData(copy_soData);
    }

    const soReferenceChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.reference = e.target.value;
        setSoData(copy_soData);
    }

    const soSalesPersonChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.salesPerson = e.target.value;
        setSoData(copy_soData);
    }

    const soAddressFromChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.addressFrom = e.target.value;
        setSoData(copy_soData);
    }

    const soAddressToChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.addressTo = e.target.value;
        setSoData(copy_soData);
    }
    const soPTermChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.paymentTerm = e.target.value;
        setSoData(copy_soData);
    }
    const soDTermChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.deliveryTerm = e.target.value;
        setSoData(copy_soData);
    }
    const soAmountChangeHandler = (e)=>{
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.soTotalAmount = e.target.value;
        setSoData(copy_soData);
    }

    const submitHandler =() =>{
        setLoading(true);
        let copy_soData = JSON.parse(JSON.stringify(soData));
        copy_soData.soLines = poLines;
        setSoData(copy_soData);
        accountingService.updateSOByPOId(props.poId,copy_soData).then(result=>{
            if(result.status===200){
                accountingService.downloadSOByPOID(props.poId,downloadFileType).then(result=>{
                    let types = 'application/vnd.ms-excel';
                    let fileName = `So ${props.poId}.xlsx`;
                    if(downloadFileType === 'pdf'){
                        types = 'application/pdf';
                        fileName = `So ${props.poId}.pdf`;
                    }
                    let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
                    ; // 保存的文件名
                    downLoadFile(blob, fileName);   
                    setLoading(false);

                })
            }
        })
    }

    const downLoadFile = (blob, fileName) => {
        if (window.navigator.msSaveOrOpenBlob) { // IE10
            navigator.msSaveBlob(blob, fileName);
        } else {
            let link = document.createElement('a');
            link.style.display = 'none';
    
            link.href = URL.createObjectURL(blob); //创建一个指向该参数对象的URL
            link.download = fileName;
            link.click(); // 触发下载
            URL.revokeObjectURL(link.href); // 释放通过 URL.createObjectURL() 创建的 URL
        }
    }

    return(
        <Grid container spacing={1}>
        <Grid item xs={12} md={12}><Typography align="center" variant="h3" className={classes.convertLineTitle}>Product sales contract</Typography></Grid>
        <Grid item xs={6} md={6}><div className={classes.convertTitle}>SO Number:</div><TextField variant="outlined" size="small" value={soData&&soData.soNumber?soData.soNumber:''} onChange={soNumberChangeHandler}></TextField></Grid>
        <Grid item xs={6} md={6}><div className={classes.convertTitle}>SO Date:</div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    autoOk
                    disableToolbar
                    id="date"
                    size="small"
                    format="YYYY/MM/DD"
                    variant="inline"
                    inputVariant="outlined"
                    value={soData&&soData.soDate?new Date(soData.soDate):''}
                    className={classes.lineDateInput}
                    onChange={date => soDateChangeHandler(date)}
                />
            </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} md={6}><div className={classes.convertTitle}>Customer ID:</div><TextField variant="outlined" size="small" value={soData&&soData.customerId?soData.customerId:''} onChange={soCustomerIdChangeHandler}></TextField></Grid>
        <Grid item xs={6} md={6}><div className={classes.convertTitle}>Customer Name:</div><TextField variant="outlined" size="small" value={soData&&soData.customerName?soData.customerName:''} onChange={soCustomerNameChangeHandler}></TextField></Grid>
        <Grid item xs={6} md={6}><div className={classes.convertTitle}>Reference:</div><TextField variant="outlined" size="small" value={soData&&soData.reference?soData.reference:''} onChange={soReferenceChangeHandler}></TextField></Grid>
        <Grid item xs={6} md={6}><div className={classes.convertTitle}>Sales Person:</div><TextField variant="outlined" size="small" value={soData&&soData.salesPerson?soData.salesPerson:''} onChange={soSalesPersonChangeHandler}></TextField></Grid>
        <Grid item xs={12} md={12}><div className={classes.convertTitle}>Address From:</div><TextField variant="outlined" size="small" style={{width:'100%', maxWidth: '500px'}} value={soData&&soData.addressFrom?soData.addressFrom:''} onChange={soAddressFromChangeHandler}></TextField></Grid>
        <Grid item xs={12} md={12}><div className={classes.convertTitle}>Address To:</div><TextField variant="outlined" size="small" style={{width:'100%', maxWidth: '500px'}} value={soData&&soData.addressTo?soData.addressTo:''} onChange={soAddressToChangeHandler}></TextField></Grid>
        <Grid item xs={12} md={12}><div className={classes.convertTitle}>Delivery Term:</div><TextField variant="outlined" size="small" style={{width:'100%', maxWidth: '500px'}} value={soData&&soData.deliveryTerm?soData.deliveryTerm:''} onChange={soDTermChangeHandler}></TextField></Grid>
        <Grid item xs={6} md={6}><div className={classes.convertTitle}>Payment Term:</div><TextField variant="outlined" size="small" value={soData&&soData.paymentTerm?soData.paymentTerm:''} onChange={soPTermChangeHandler}></TextField></Grid>
        <Grid item xs={6} md={6}><div className={classes.convertTitle}>SO Total Amount:</div><TextField variant="outlined" size="small" value={soData&&soData.soTotalAmount?soData.soTotalAmount:''} onChange={soAmountChangeHandler}></TextField></Grid>
        <Grid item xs={12} md={12}><Typography align="center" variant="h3" className={classes.convertLineTitle}>Line Details</Typography></Grid>
        <Grid item xs={12} md={12}>
        <MaterialTable
            title=""
            icons={tableIcons}
            columns={[
                {
                    title: 'Line*', field: 'lineNumber',
                    validate: rowData => rowData.lineNumber !== '' && rowData.lineNumber !== undefined && checkDuplication(rowData),
                    cellStyle: {
                        width: '4%',
                        textAlign: "center",
                        padding: "4px"
                    },
                },
                {
                    title: 'Item*', field: 'productId',
                    validate: rowData => rowData.productId !== '' && rowData.productId !== undefined,
                    cellStyle: {
                        width: '4%',
                        textAlign: "center",
                        padding: "4px"
                    },
                },
                {
                    title: 'Description', field: 'productDescription',
                    cellStyle: {
                        textAlign: "center",
                        padding: "4px"
                    },
                },
                {
                    title: 'Qty*', field: 'Quantity',
                    validate: rowData => rowData.quantity !== '' && rowData.quantity !== undefined && checkNumber(rowData.quantity),
                    render: rowData => formatData(rowData.quantity),
                    cellStyle: {
                        width: '5%',
                        textAlign: "center",
                        padding: "4px"
                    },
                    editComponent: props => (
                        <TextField
                            id="qty_text"
                            value={props.rowData.quantity}
                            error={!props.rowData.quantity || props.rowData.quantity === undefined || props.rowData.quantity === ""}
                            required={true}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size="small"
                            onChange={e => {
                                props.onRowDataChange({
                                    ...props.rowData,
                                    lineTotal: e.target.value * props.rowData.unitPrice,
                                    quantity: e.target.value,
                                })
                            }}
                        >
                        </TextField>
                    ),
                },
                {
                    title: 'Unit', field: 'unitOfMeasurement',
                    cellStyle: {
                        width: '5%',
                        textAlign: "center",
                        padding: "4px"
                    },
                },
                {
                    title: 'Price*', field: 'unitPrice',
                    validate: rowData => rowData.unitPrice !== '' && rowData.unitPrice !== undefined && checkNumber(rowData.unitPrice),
                    render: rowData => formatData(rowData.unitPrice),
                    cellStyle: {
                        width: '5%',
                        textAlign: "center",
                        padding: "4px",
                    },
                    editComponent: props => (
                        <TextField
                            id="price_text"
                            value={props.rowData.unitPrice}
                            error={!props.rowData.unitPrice || props.rowData.unitPrice === undefined || props.rowData.unitPrice === ""}
                            required={true}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size="small"
                            onChange={e => {
                                props.onRowDataChange({
                                    ...props.rowData,
                                    lineTotal: e.target.value * props.rowData.quantity,
                                    unitPrice: e.target.value,
                                })
                            }}
                        >
                        </TextField>
                    ),
                },
                {
                    title: 'Tax', field: 'tax',
                    cellStyle: {
                        width: '5%',
                        textAlign: "center",
                        padding: "4px"
                    },
                },
                {
                    title: 'Discount', field: 'discount',
                    cellStyle: {
                        textAlign: "center",
                        padding: "4px"
                    },
                },
                {
                    title: 'Line total*', field: 'lineTotal',
                    validate: rowData => rowData.lineTotal !== '' && rowData.lineTotal !== undefined && checkNumber(rowData.lineTotal),
                    render: rowData => formatData(rowData.lineTotal),
                    cellStyle: {
                        width: '10%',
                        textAlign: "center",
                        padding: "4px",
                    },
                    editComponent: (props) => {
                        return (
                            <TextField
                                id="linetotal_text"
                                value={props.rowData.lineTotal ? props.rowData.lineTotal : "0"}
                                error={!props.rowData.lineTotal || props.rowData.lineTotal === undefined || props.rowData.lineTotal === ""}
                                required={true}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                onChange={e => {
                                    props.onChange(e.target.value)
                                }}
                            >
                            </TextField>
                        )
                    }
                },
                {
                    title: 'Date*', field: 'deliveryDate', type: 'date', emptyValue: moment().format("YYYY/MM/DD"),
                    editComponent: props => (
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                autoOk
                                disableToolbar
                                id="date"
                                variant="inline"
                                size="small"
                                format="YYYY/MM/DD"
                                value={props.value}
                                className={classes.lineDateInput}
                                onChange={date => props.onChange(moment(date).format("YYYY/MM/DD"))}
                            />
                        </MuiPickersUtilsProvider>
                    ),
                    cellStyle: {
                        width: '10%',
                        textAlign: "center",
                        padding: "4px"
                    },
                },

                {
                    title: 'Notes', field: 'notes',
                    cellStyle: {
                        width: '15%',
                        textAlign: "center",
                        padding: "4px"
                    },
                    editComponent: props => (
                        <TextField
                            id="price_text"
                            multiline={true}
                            minRows = {3}
                            value={props.rowData.notes}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size="small"
                            onChange={e => props.onChange(e.target.value)}

                        >
                        </TextField>
                    ),
                },

                {
                    title: 'Sales', field: 'salesPerson',
                    cellStyle: {
                        textAlign: "center",
                        padding: "4px"
                    },
                },
            ]}
            data={poLines}
            style={{ width: "100%" }}
            options={{
                actionsColumnIndex: -1,
                search: false,
                paging: false,
                sorting: false,
                toolbar:false,
                cellStyle: {
                    padding: "4px",
                    textAlign: "center",
                },
                headerStyle: {
                    padding: "4px",
                    textAlign: "center",
                    backgroundColor: "transparent"
                },
                rowStyle: (rowData ) => ({
                    fontSize: "14px",
                    //color: ((rowData.isUsed === true && rowData.lineOutstanding <= 0.1) || rowData.isUsed === false || rowData.status !== "closed") ? "grey" : ""
                    }),
            }}
            actions={[
            ]}
            localization={{
                body: {
                    editRow: {
                        saveTooltip: 'Check'
                    },
                    emptyDataSourceMessage:t("common.noRecord"),
                },
            }}
            editable={{
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataUpdate = [...poLines];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setPoLines([...dataUpdate]);
                            resolve();
                        }, 1000)
                    })
            }}
        />
        </Grid>
        <Grid container spacing={1} justifyContent="center" style={{marginTop:'20px',marginBottom:'20px'}}>
            <Grid item xs={12} sm={7} md={4}>
                <Select
                    labelId="duration"
                    id="duration"
                    variant="outlined"
                    defaultValue="30"
                    value={downloadFileType}
                    size="small"
                    onChange={(event) => {
                        setDownloadFileType(event.target.value);
                    }}
                    classes={{ select: classes.outlinedPadding }} className={classes.selectedmargin}
                >
                    <MenuItem key={15} value={"excel"}>Excel</MenuItem>
                    <MenuItem key={30} value={"pdf"}>PDF</MenuItem>
                </Select>
                <LoadingButton variant="contained" isLoading={loading} color="primary" onClick={submitHandler} className={classes.confirmBtn} >
                    <Typography variant="body2">
                        Confirm and Download
                    </Typography>
                </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
                <Button variant="contained" onClick={props.onClose} style={{width:'100%'}}>
                    <Typography variant="body2">
                        Cancel
                    </Typography>
                </Button>
            </Grid>
            {/* <Button variant="contained" onClick={handleConvertClose}>
                <Typography variant="body2">
                    Cancel
                </Typography>
            </Button> */}
        </Grid>
    </Grid>
)

}