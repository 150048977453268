import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 1000,
    padding: '100px'
  },
});

export default function Types() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1" gutterBottom>
        h1. Heading: for ad headline use. (42px bold)
      </Typography>
      <hr/>
      <Typography variant="h2" gutterBottom>
        h2. Heading: for headline use. (36px bold)
      </Typography>
      <hr/>
      <Typography variant="h3" gutterBottom>
        h3. Heading: for title, placeholder botton use. (18px bold)
      </Typography>
      <hr/>
      <Typography variant="h4" gutterBottom>
        h4. Heading: for small button use. (14px bold)
      </Typography>
      <hr/>
      
      <Typography variant="body1" gutterBottom>
        body1:for description use.  (24px bold) <br/>
        .Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
        dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </Typography>
      <hr/>
      <Typography variant="body2" gutterBottom>
        body2: for ad body use. (22px regular) <br/> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
        dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </Typography>
      <hr/>
      <Typography variant="subtitle1" gutterBottom>
        subtitle1. for sub description use. (18px regular) <br/> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
        dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </Typography>
      <hr/>
      <Typography variant="subtitle2" gutterBottom>
        subtitle2. for sub description use. (16px regular) <br/> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
        dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
      </Typography>

    </div>
  );
}