import React,{useContext}  from 'react';
import { Component, useEffect, useState, useRef} from 'react';
import { Link, useParams, useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { Grid, Button, Select, MenuItem, FormControl, InputLabel, TextField, Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import UndoIcon from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { forwardRef } from 'react';

import randomIcon from '../../assets/img/random.svg';
import useToken from '../../hooks/useToken';
import LoadingButton from "../../components/LoadingButton";
import { ApplicationContext } from "../../context/applicationContext";
import inventoryService from '../../services/inventoryService';

import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
 
import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import organizationService from "../../services/organizationService";
import accountingService from "../../services/accountingService";
import Alert from '@material-ui/lab/Alert';
import downloadFileUtils from '../../utils/downloadFile';
import { QrReader } from 'react-qr-reader';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
        display: 'flex',
        flexDirection: "row",
        maxWidth: '1200px',
        width: '100%',
    },
    box: {
        paddingLeft:'30px',
        paddingTop:'40px',
        width:'100%',
        [theme.breakpoints.down('md')]: {
            paddingLeft:'4px',
            paddingTop:'4px',
        }

    },
    undoIcon: {
        marginRight: "23px",
        color:"#3f51b5",
        cursor:'pointer'
    },
    pageTitle: {
        padding: "21px 0 21px 27px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[3],
    },
    pageContent:{
        width: "100%",
        margin: "0",
        padding: "13px 0 21px 55px",
        [theme.breakpoints.down('md')]: {
            padding: "8px 0 8px 12px",
        }
    },
    firstPart: {
        margin: "8px 0 8px 0"
    },
    secondPart: {
        margin: "8px 29px 8px 0"
    },
    thirdPart: {
        margin: "8px 0 8px 0"
    },
    forthPart: {
        margin: "8px 0 8px 0"
    },
    dashedLine: {
        borderTop: "1px solid #dbdbdb",
        width: "100%",
    },
    selectField: {
        padding: "11px 14px",
    },
    required:{
        color:'red'
    },
    itemTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginBottom: "4px",
        color: "#818181",
    },
    editTitle:{
        color: "#818181",
    },
    itemContent: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin:"4px 0 0 0"
    },
    required: {
        color: "#fd4747",
        display: "inline",
    },
    thirdItemSpacing: {
        margin: "auto 7px auto 0",
    },
    itemSpacing: {
        marginBottom: "13px",
    },
    secondItemSpacing: {
        marginBottom: "20px",
    },
    textField: {
        margin: "0",
    },
    randomImage: {

    },
    buttonSpacing: {
        width: "150px",
        marginLeft: "10px",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    btnBottom: {
        margin:"42px 0 53px 0"
    },
    customMargin: {
        margin: "0 67px 14px 0",
        [theme.breakpoints.down('md')]: {
            margin: "0 10px 14px 0",
        }
    },
    customMargin2: {
        margin: "0 0 18px 0",
        [theme.breakpoints.down('md')]: {
            margin: 0,
            justifyContent: 'center',
        }
    }
}));

export default function CheckInPage(props) {
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const { token } = useToken();

    const theme = useTheme();

    const history = useHistory();

    const [staffArray,setStaffArray] = useState([])

    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>
        ),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        Cancel: forwardRef((props, ref) => <CancelOutlinedIcon {...props} ref={ref} />)
    };


    const defaultlotItemData = {
        lotNo: {value: "", error: false}, 
        refLotNo: {value: "", error: false}, 
        checkInCost:{value: "", error: false}, 
        qtyForCost: {value: "", error: false}, 
        qtyForStorage:{value: "", error: false}, 
        certification: {value: "", error: false}, 
        location: {value: "", error: false}, 
        lotCheckError:{value: "", error: false}
    };

    const defaultRefFileItemData = {
        fileType: {value: "", error: false},
        fileNo: {value: "", error: false}
    };

    const [isLoading, setIsLoading] = useState(false);

    const [billNumber, setBillNumber] = useState("");
    const [refBillNumber, setRefBillNumber] = useState("");
    const [pageType, setPageType] = useState("new");
    const [goodsData, setGoodsData] = useState([]);
    const [friends,setFriends] = useState([]);
    const [companyList,setCompanyList] = useState([]);
    const [timer,_setTimer] = useState(null);
    const [goodsCategory,setGoodsCategory] = useState(null);
    const [categoryData, setCategoryData] = useState([t('materialGoods.shapelistBar'),t('materialGoods.shapelistSeamless'),t('materialGoods.shapelistWelding'),t('materialGoods.shapelistSheet'),t('materialGoods.shapelistStrip'),t('materialGoods.shapelsitWire'),t('materialGoods.shapelistGrain'),t('materialGoods.shapelistPowder'),t('materialGoods.shapelistSpecial'),t('materialGoods.shapelistOthers')]);
    const [goodsType,setGoodsType] = useState("");
    const [goodsBrand,setGoodsBrand] = useState("");
    const [goodsESpec1,setGoodsESpec1] = useState(null);
    const [goodsESpec2,setGoodsESpec2] = useState(null);
    const [goodsESpec3,setGoodsESpec3] = useState(null);
    const [isMaterial, setIsMaterial] = useState(false);
    const [qrCodeOpen, setQrCodeOpen] = useState(false);
    const timerRef = useRef(timer);
    const setTimer = (data) =>{
        timerRef.current=data;
        _setTimer(timerRef);
    }
    useEffect(()=> {
        // console.log("history.location.state.stockLineData", history.location.state.stockLineData)
        // if(history.location.state && history.location.state.stockLineData){
        //     setGoodsData(history.location.state.stockLineData);
        // }
        if(history.location.state.operationType&&history.location.state.operationType==="view"){
            setSubmited(true);
        }
        if(history.location.state.warehouseData.isMaterial){
            setIsMaterial(history.location.state.warehouseData.isMaterial);
        }
        else{
            inventoryService.getWarehouse(history.location.state.warehouseData.warehouseId).then(result=>{
                setIsMaterial(result.data.isMaterial);
            })
        }
        accountingService.addName(history.location.state.warehouseData.orgId).then((result) => {
            setCompanyList(result.data)
        })
        organizationService.getUsersByOrgId(history.location.state.warehouseData.orgId).then((result) => {
            if (result.data && result.data.users) {
              setFriends(result.data.users);
              let tempFriends = result.data.users;
              inventoryService.getWarehouseStaff(history.location.state.warehouseData.warehouseId,{}).then(result=>{
                if(result.status===200){
                    let tempStaffArray = [];
                    result.data.map(item=>{
                        let tempItem = {};
                        tempItem.email = item;
                        let findResult = tempFriends.find(item=>{
                            return (item.email === tempItem.email);
                        })
                        if(findResult){
                            tempItem.username = findResult.username;
                        }
                        else{
                            tempItem.username = "";
                        }
                        tempStaffArray.push(tempItem);
                    })
                    // console.log(tempStaffArray);
                    setStaffArray(tempStaffArray);
                    if(history.location.state.checkInId&&history.location.state.checkInId!==""){
                        setPageType("edit");
                        inventoryService.getCheckInById(history.location.state.checkInId).then(result=>{
                            if(result.status===200){
                                // console.log(result.data);
                                setBillNumber(result.data.inBillNumber);
                                setRefBillNumber(result.data.refInBillNumber);
                                setSelGood(result.data.goods[0].goods_id);
                                let tempGoodsInfo = {};
                                tempGoodsInfo = result.data.goods[0];
                                tempGoodsInfo.unitCost = tempGoodsInfo.unit_cost;
                                tempGoodsInfo.unitStorage = tempGoodsInfo.unit_storage;
                                setSelGoodInfo(result.data.goods[0]);
                                let lotItemDataTemp = [];
                                result.data.checkInDetails.map(item=>{
                                    let tempItem = defaultlotItemData;
                                    tempItem.lotNo.value = item.lot_number;
                                    // tempItem.refLotNo.value = item.ref_lot_number;
                                    tempItem.checkInCost.value = item.check_in_cost;
                                    tempItem.qtyForCost.value = item.qty_cost;
                                    tempItem.qtyForStorage.value = item.qty_storage;
                                    tempItem.certification.value = item.certification;
                                    tempItem.location.value = item.location;
                                    lotItemDataTemp.push(tempItem);
                                })
                                setLotItemData(lotItemDataTemp);
            
                                let refFileItemDataTemp = [];
                                result.data.referenceInfo.map(item=>{
                                    let tempItem = defaultRefFileItemData;
                                    tempItem.fileType.value = item.referenceType;
                                    tempItem.fileNo.value = item.referenceNumber;
                                    refFileItemDataTemp.push(tempItem);
                                })
                                setRefFileItemData(refFileItemDataTemp);
                                setSupplier(result.data.supplier)
                                setInspector(result.data.inspector)
                                setInspectionDate(result.data.inspectorDate);
                                setRequestor(result.data.requestor);
                                let staffTemp = {};
                                let findtemp = friends.find(item=>{
                                    return(item.email === result.data.staff)
                                });
                                staffTemp.email =result.data.staff;
                                if(findtemp){
                                    staffTemp.username = findtemp.username;
                                }
                                else{
                                    staffTemp.username = "";
                                }
                                setWarehouseStaff(staffTemp);
                                setNotes(result.data.notes)
                            }
                        })
                    }
                }
            })
            }
        })
        // console.log(history.location.state.warehouseData);

        inventoryService.searchGoodByNameOrId(history.location.state.warehouseData.orgId,"",history.location.state.warehouseData.isMaterial?true:false).then(result=>{
            if(result.status===200){
                setGoodsData(result.data.datas);
                if(history.location.state.selectedGoods){
                    setSelGood(history.location.state.selectedGoods);
                    result.data.datas.map(item=>{
                        if(item.goodsId === history.location.state.selectedGoods){
                            setSelGoodInfo(item);
                        }
                    })
                }
            }
        })
    },[])

    useEffect(() => {
        if(isMaterial){
            let eSpec = {};
            if(goodsESpec1&&goodsESpec1.value!==''){
                eSpec[goodsESpec1.label]=goodsESpec1.value;
            }
            if(goodsESpec2&&goodsESpec2.value!==''){
                eSpec[goodsESpec2.label]=goodsESpec2.value;
            }
            if(goodsESpec3&&goodsESpec3.value!==''){
                eSpec[goodsESpec3.label]=goodsESpec3.value;
            }
            console.log(JSON.stringify(eSpec));
            inventoryService.searchGoodBySpec(history.location.state.warehouseData.orgId,goodsType,goodsBrand,goodsCategory,JSON.stringify(eSpec)).then(result=>{
                if(result.status===200){
                    setGoodsData(result.data.datas);
                }
            })
        }   
    },[goodsType,goodsCategory,goodsBrand,goodsESpec1,goodsESpec2,goodsESpec3,isMaterial])

    const [selGood, setSelGood] = useState("");

    const [selGoodInfo, setSelGoodInfo] = useState("");

    const [lotItemData, setLotItemData] = useState([defaultlotItemData]);

    // useEffect(()=>{
    //     timer = setTimeout(() => {
    //         console.log(lotItemData);
    //         lotItemData.map((item,index)=>{
    //             console.log(item);
    //             if(item&&selGood!==""&&item.lotNo.value!==""&&item.checkInCost.value!==""&&item.qtyForCost.value!==""&&item.qtyForStorage.value!==""){
    //                 let playload = {
    //                     orgId:history.location.state.warehouseData.orgId,
    //                     lotNumber:item.lotNo.value,
    //                     goodsId:selGood,
    //                     checkInCost:parseFloat(item.checkInCost.value),
    //                     qtyCost:parseFloat(item.qtyForCost.value),
    //                     qtyStorage:parseFloat(item.qtyForStorage.value)
    //                 }
    //                 console.log(playload);
    //                 inventoryService.checkLotNumber(playload).then(result=>{
    //                     if(result.status===200){
    //                         if(result.data.checkPass===true){
    //                             lotNumberCheckErrorHandler("");
    //                         }
    //                     }
    //                 }).catch((e)=>{
    //                     console.log(e.response);
    //                     lotNumberCheckErrorHandler(e.response.data.message);
    //                 })
    //             }
    //         })},500)

    //     return() => {clearTimeout(timer);}

    // },[selGood,lotItemData])

    const [refFileItemData, setRefFileItemData] = useState([defaultRefFileItemData]);

    const [fileTypeData, setFileTypeData] = useState([]);

    useEffect(()=>{
        setFileTypeData([t("checkIn.po"),t("checkIn.so"),t("checkIn.wo"),t("checkIn.sncr"),t("checkIn.rma"),t("checkIn.other")]);
    },[])

    const [supplier, setSupplier] = useState(null);

    const [inspector, setInspector] = useState("");

    const [inspectionDate, setInspectionDate] = useState(null);

    const [requestor, setRequestor] = useState("");

    const [warehouseStaff, setWarehouseStaff] = useState(null);

    const [notes, setNotes] = useState("");

    const [billNumberError, setBillNumberError] = useState(false);

    const [selGoodError, setSelGoodError] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [lotNumberCheckError, setLotNumberCheckError] = useState(false);

    const [requestorError, setRequestorError] = useState(false);
    const [currentCheckInNo, setCurrentCheckInNo] = useState(null);
    const [warehouseStaffError, setWarehouseStaffError] = useState(false);
    const [submited, setSubmited] = useState(false);
    const filter = createFilterOptions();

    const billNumberChangeHandler = (e) => {
        if(e.target.value === ""){
            setBillNumberError(true);
        }else{
            setBillNumberError(false);
        }
        setBillNumber(e.target.value);
    };

    const refBillNumberChangeHandler = (e) => {
        setRefBillNumber(e.target.value);
    };
    
    const goodsChangeHandler = (e) => {
        if(e.target.value === ""){
            setSelGoodError(true);
        }else{
            setSelGoodError(false);
        }
        setSelGood(e.target.value);
        goodsData.map( (item)=> {
            if(item.goodsId === e.target.value){
                setSelGoodInfo(item);
            }
        })
        lotItemData.map((item,index)=>{
            checkLotNumber(index);
        })
    };

    const lotNumberChangeHandler = (index, event) => {
        const copy_lotItemData = [...lotItemData];
        if(event.target.value === ""){
            copy_lotItemData[index].lotNo.error = true;
        }else{
            copy_lotItemData[index].lotNo.error = false;
        }
        copy_lotItemData[index].lotNo.value = event.target.value;
        setLotItemData(copy_lotItemData);
        checkLotNumber(index);
    };

    const refLotNumberChangeHandler = (index, event) => {
        const copy_lotItemData = [...lotItemData];
        if(event.target.value === ""){
            copy_lotItemData[index].refLotNo.error = true;
        }else{
            copy_lotItemData[index].refLotNo.error = false;
        }
        copy_lotItemData[index].refLotNo.value = event.target.value;
        setLotItemData(copy_lotItemData);
    };

    const setLotNoError = (index) => {
        const copy_lotItemData = [...lotItemData];
        copy_lotItemData[index].lotNo.error = true;
        console.log('set error');
        setLotItemData(copy_lotItemData);
    };
    const setLotCheckInCostError = (index) => {
        const copy_lotItemData = [...lotItemData];
        copy_lotItemData[index].checkInCost.error = true;
        console.log('set error');
        setLotItemData(copy_lotItemData);
    };
    const setLotQtyCostError = (index) => {
        const copy_lotItemData = [...lotItemData];
        copy_lotItemData[index].qtyForCost.error = true;
        console.log('set error');
        setLotItemData(copy_lotItemData);
    };
    const setLotQtyStorageError = (index) => {
        const copy_lotItemData = [...lotItemData];
        copy_lotItemData[index].qtyForStorage.error = true;
        console.log('set error');
        setLotItemData(copy_lotItemData);
    };
    const checkInCostHandler = (index, event) => {
        const copy_lotItemData = [...lotItemData];
        if(event.target.value === ""||!checkNumber(event.target.value)){
            copy_lotItemData[index].checkInCost.error = true;
        }else{
            copy_lotItemData[index].checkInCost.error = false;
        }
        copy_lotItemData[index].checkInCost.value = event.target.value;
        setLotItemData(copy_lotItemData);
        checkLotNumber(index);
    };
    
    const qtyForCostHandler = (index, event) => {
        const copy_lotItemData = [...lotItemData];
        if(event.target.value === ""||!checkNumber(event.target.value)){
            copy_lotItemData[index].qtyForCost.error = true;
        }else{
            copy_lotItemData[index].qtyForCost.error = false;
        }
        copy_lotItemData[index].qtyForCost.value = event.target.value;
        setLotItemData(copy_lotItemData);
        checkLotNumber(index);
    };

    const qtyForStorageHandler = (index, event) => {
        const copy_lotItemData = [...lotItemData];
        if(event.target.value === ""||!checkNumber(event.target.value)){
            copy_lotItemData[index].qtyForStorage.error = true;
        }else{
            copy_lotItemData[index].qtyForStorage.error = false;
        }
        copy_lotItemData[index].qtyForStorage.value = event.target.value;
        setLotItemData(copy_lotItemData);
        checkLotNumber(index);
    };

    const certificationHandler = (index, event) => {
        const copy_lotItemData = [...lotItemData];
        copy_lotItemData[index].certification.value = event.target.value;
        setLotItemData(copy_lotItemData);
    };

    const locationHandler = (index, event) => {
        const copy_lotItemData = [...lotItemData];
        copy_lotItemData[index].location.value = event.target.value;
        setLotItemData(copy_lotItemData);
    };

    const lotNumberCheckErrorHandler = (index, value) => {
        const copy_lotItemData = [...lotItemData];
        if(value === ""){
            copy_lotItemData[index].lotCheckError.error = false;
        }else{
            copy_lotItemData[index].lotCheckError.error = true;
        }
        copy_lotItemData[index].lotCheckError.value = value;
        setLotItemData(copy_lotItemData);
    };

    const lotItemDataAddHandler = () => {
        const copy_lotItemData = [...lotItemData];
        copy_lotItemData.push(defaultlotItemData);
        setLotItemData(copy_lotItemData);
    };

    const removeLotItemDataHandler = (index) => {
        const copy_lotItemData = [...lotItemData];
        copy_lotItemData.splice(index, 1);

        // console.log("copy_lotItemData", copy_lotItemData)

        setLotItemData(copy_lotItemData);
    };

    const fileTypeChangeHandler = (index, event) => {
        const copy_refFileItemData = [...refFileItemData];
        if(event.target.value === ""){
            copy_refFileItemData[index].fileType.error = true;
        }else{
            copy_refFileItemData[index].fileType.error = false;
        }
        copy_refFileItemData[index].fileType.value = event.target.value;
        setRefFileItemData(copy_refFileItemData);
    };

    const setRefFileTypeError = (index) => {
        const copy_refFileItemData = [...refFileItemData];
        copy_refFileItemData[index].fileType.error = true;
        setRefFileItemData(copy_refFileItemData);
    };

    const fileNoChangeHandler = (index, event) => {
        const copy_refFileItemData = [...refFileItemData];
        if(event.target.value === ""){
            copy_refFileItemData[index].fileNo.error = true;
        }else{
            copy_refFileItemData[index].fileNo.error = false;
        }
        copy_refFileItemData[index].fileNo.value = event.target.value;
        setRefFileItemData(copy_refFileItemData);
    };

    const setRefFileNoError = (index) => {
        const copy_refFileItemData = [...refFileItemData];
        copy_refFileItemData[index].fileNo.error = true;
        setRefFileItemData(copy_refFileItemData);
    };

    const refFileItemDataAddHandler = () => {
        const copy_refFileItemData = [...refFileItemData];
        copy_refFileItemData.push(defaultRefFileItemData);
        setRefFileItemData(copy_refFileItemData);
    };

    const removeRefFileItemDataHandler = (index) => {
        const copy_refFileItemData = [...refFileItemData];
        copy_refFileItemData.splice(index, 1);

        // console.log("copy_refFileItemData", copy_refFileItemData)

        setRefFileItemData(copy_refFileItemData);
    };

    const inspectorChangeHandler = (e) => {
        setInspector(e.target.value);
    };

    const inspectionDateChangeHandler = (date) => {
        setInspectionDate(date);
    };

    const requestorChangeHandler = (e) => {
        if(e.target.value===''){
            setRequestorError(true);
        }else{
            setRequestorError(false);
        }
        setRequestor(e.target.value);
    };

    const warehouseStaffChangeHandler = (e,newValue) => {
        if(!newValue){
            setWarehouseStaffError(true);
        }else{
            setWarehouseStaffError(false);
        }
        setWarehouseStaff(newValue);
    };

    const notesChangeHandler = (e) => {
        setNotes(e.target.value);
    };

    const checkNumber = (values) => {
        let reg = /^\d*(\.\d{1,4})?$/;
        if (values && !reg.test(values)) {
            // console.log("不是数字")
            return false;
        }
        // console.log("是数字")
        return true;
    }

    const submitCheckInFormHandler = (e) => {
        inventoryService.verifyCheckIn(currentCheckInNo).then(result=>{
            if(result.status===200){
                let haveRefPo = false;
                let poNumber = '';
                result.data.referenceInfo.map(item=>{
                    if(item.referenceType==='PO 订货合同'||item.referenceType==='PO'){
                        haveRefPo = true;
                        poNumber = item.referenceNumber;
                    }
                })
                if(haveRefPo){
                    let totalNumber = 0;
                    result.data.details.map(item=>{
                        totalNumber = totalNumber + parseInt(item.qtyStorage);
                    })
                    let notificationValues={};
                    notificationValues.orgId = result.data.orgId;
                    notificationValues.poNumber = poNumber;
                    notificationValues.warehouseName = history.location.state.warehouseData.name;
                    notificationValues.goodsIdAndName = result.data.details[0].goods.id + "/" + result.data.details[0].goods.name;
                    notificationValues.checkinTotal = totalNumber.toString();
                    notificationValues.checkinDate = moment().format('yyyy-MM-DD');
                    accountingService.shippingNotification(notificationValues);
                }
                history.push('/Inventory');
            }
        });
    };

    const generateOrderHandler =(e) => {
        setSubmited(true);
        let valid = true;
        if(billNumber===""){
            setBillNumberError(true);
            valid = false;
        }
        if(selGood===""){
            setSelGoodError(true);
            valid = false;
        }
        lotItemData.map((item,index)=>{
            // console.log(item);
            if(item.lotNo.value===''){
                setLotNoError(index);
                valid = false;
            }
            if(item.checkInCost.value===''){
                setLotCheckInCostError(index);
                valid = false;
            }
            if(item.qtyForCost.value===''){
                setLotQtyCostError(index);
                valid = false;
            }
            if(item.qtyForStorage.value===''){
                setLotQtyStorageError(index);
                valid = false;
            }
        })
        refFileItemData.map((item,index)=>{
            if(item.fileType.value===''){
                setRefFileTypeError(index);
                valid = false;
            }
            if(item.fileNo.value===''){
                setRefFileNoError(index);
                valid = false;
            }
        })
        if(!requestor){
            setRequestorError(true);
            valid = false;
        }
        if(!warehouseStaff){
            setWarehouseStaffError(true);
            valid = false;
        }
        if(!valid){
            setSubmited(false);
            return;
        }
        let submitValue = {};
        submitValue.inBillNumber = billNumber;
        submitValue.refInBillNumber = refBillNumber;
        submitValue.warehouseId = history.location.state.warehouseData.warehouseId;
        submitValue.orgId = history.location.state.warehouseData.orgId;
        submitValue.inspector = inspector;
        if(inspectionDate){
            submitValue.inspectorDate = moment(inspectionDate).format('yyyy-MM-DD');
        }
        submitValue.requestor = requestor;
        submitValue.staff = warehouseStaff.email;
        submitValue.supplier = supplier;
        submitValue.notes = notes;
        let tempRfData = [];
        refFileItemData.map(item=>{
            tempRfData.push({
                referenceType: item.fileType.value,
                referenceNumber: item.fileNo.value
            });
        })
        submitValue.referenceInfo = tempRfData;
        let tempLotData =[];
        lotItemData.map(item=>{
            tempLotData.push({
                lotNumber:item.lotNo.value,
                // refLotNumber:item.refLotNo.value,
                goodsId:selGood,
                checkInCost:item.checkInCost.value,
                qtyCost:item.qtyForCost.value,
                qtyStorage:item.qtyForStorage.value,
                certification:item.certification.value,
                location:item.location.value,
            })
        })
        submitValue.checkInDetails = tempLotData;
        // console.log(submitValue);
        if(pageType==="new"){
            inventoryService.addCheckIn(submitValue).then(result=>{
                if(result.status===200){
                    setCurrentCheckInNo(result.data.checkInId);
                    setSubmited(true);
                    inventoryService.downloadCheckInById(result.data.checkInId,-(((new Date().getTimezoneOffset())/60))).then(result=>{
                        let types = 'application/vnd.ms-excel';
                        let fileName = `${t('checkIn.title')} ${billNumber}.xlsx`;
                        let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
                        ; // 保存的文件名
                        downloadFileUtils.downLoadFile(blob, fileName);   
                    })
                }
            }).catch(e=>{
                setSubmited(false);
                // console.log(e.response.data.message);
                // console.log(e.response.data.message.indexOf("The inBillNumber"));
                if(e.response.data.message.indexOf("The inBillNumber")>-1 && e.response.data.message.indexOf("exist in this org")>-1){
                    setSubmitError(t("checkIn.checkInBillNumerExist"));
                    setBillNumberError(true);
                }
                else{
                    setSubmitError(e.response.data.message)
                }
            })
        }
        else{
            inventoryService.updateCheckInById(history.location.state.checkInId,submitValue).then(result=>{
                if(result.status===200){
                    setCurrentCheckInNo(result.data.checkInId);
                    setSubmited(true);
                }
            })
        }
    };

    const goBackHandler = () => {
        history.go(-1);
    };

    const getBillNumberHandler =()=>{
        inventoryService.getBillNumber(history.location.state.warehouseData.orgId,"check_in").then(result=>{
            if(result.status===200){
                let tempNumber = "CI" + moment().format("YYYYMMDD") + ("0000" + parseInt(result.data.maxNumber+1)).slice(-4);
                setBillNumber(tempNumber);
                setBillNumberError(false);
                // console.log(tempNumber);
            }
        })
    }

    const lotNumberGenrateHandler=(event,index)=>{
        inventoryService.getBillNumber(history.location.state.warehouseData.orgId,"lot").then(result=>{
            if(result.status===200){
                let tempNumber = "L" + moment().format("YYYYMMDD") + ("0000" + parseInt(result.data.maxNumber+index+1)).slice(-4);
                // console.log(index);
                const copy_lotItemData = [...lotItemData];
                if(tempNumber === ""){
                    copy_lotItemData[index].lotNo.error = true;
                }else{
                    copy_lotItemData[index].lotNo.error = false;
                }
                copy_lotItemData[index].lotNo.value = tempNumber;
                setLotItemData(copy_lotItemData);
                // console.log(tempNumber);
            }
        })
    }

    const checkLotNumber = (index)=>{
        // console.log(timer);
        setLotNumberCheckError(true);
        if(timer){
            clearTimeout(timerRef.current);
            setTimer(null);
            // console.log("cleard")
        }
        let temptimer = setTimeout(() => {
            let item = lotItemData[index];
            if(item&&selGood!==""&&item.lotNo.value!==""&&item.checkInCost.value!==""&&checkNumber(item.checkInCost.value)&&item.qtyForCost.value!==""&&checkNumber(item.qtyForCost.value)&&item.qtyForStorage.value!==""&&checkNumber(item.qtyForStorage.value)){
                let playload = {
                    orgId:history.location.state.warehouseData.orgId,
                    lotNumber:item.lotNo.value,
                    goodsId:selGood,
                    checkInCost:parseFloat(item.checkInCost.value),
                    qtyCost:parseFloat(item.qtyForCost.value),
                    qtyStorage:parseFloat(item.qtyForStorage.value)
                }
                inventoryService.checkLotNumber(playload).then(result=>{
                    if(result.status===200){
                        if(result.data.checkPass===true){
                            lotNumberCheckErrorHandler(index,"");
                            setLotNumberCheckError(false);
                        }
                    }
                }).catch(e=>{
                    console.log(e.response);
                    if(e.response.data.message==='Lot number already exists, please select the correct goods.'){
                        lotNumberCheckErrorHandler(index,t('checkIn.LotNumberAlreadyExists'));
                    }
                    if(e.response.data.message==='The calculated (QTYforCost : QTYforStorage) is inconsistent with the same Lot number check-in last time.'){
                        lotNumberCheckErrorHandler(index,t('checkIn.calculatedQTYforCostInconsistent'));
                    }
                    if(e.response.data.message==='The calculated unit price is inconsistent with the same Lot number check-in last time.'){
                        lotNumberCheckErrorHandler(index,t('checkIn.calculatedUnitpriceInconsistent'));
                    }
                    setLotNumberCheckError(true);
                })
            }
        },500);
        setTimer(temptimer)
        // console.log(timer);
    }

    const setGoodsESpec1Handler = (e)=>{
        {
            let copySepc = JSON.parse(JSON.stringify(goodsESpec1));
            console.log(copySepc);
            copySepc.value=e.target.value;
            if(e.target.value===''&&copySepc.required===true){
                copySepc.error=true;
            }
            else{
                copySepc.error=false;
            }
            console.log(copySepc);
            setGoodsESpec1(copySepc);
        }
    }

    const setGoodsESpec2Handler = (e)=>{
        {
            let copySepc = JSON.parse(JSON.stringify(goodsESpec2));
            console.log(copySepc);
            copySepc.value=e.target.value;
            if(e.target.value===''&&copySepc.required===true){
                copySepc.error=true;
            }
            else{
                copySepc.error=false;
            }
            console.log(copySepc);
            setGoodsESpec2(copySepc);
        }
    }

    const setGoodsESpec3Handler = (e)=>{
        {
            let copySepc = JSON.parse(JSON.stringify(goodsESpec3));
            console.log(copySepc);
            copySepc.value=e.target.value;
            if(e.target.value===''&&copySepc.required===true){
                copySepc.error=true;
            }
            else{
                copySepc.error=false;
            }
            console.log(copySepc);
            setGoodsESpec3(copySepc);
        }
    }

    const goodsItemAddHandler =()=>{
        let settingURL = '/Inventory/GoodsMgt';
        if(history.location.state.warehouseData.isMaterial){
            settingURL='/Inventory/MaterialGoodsMgt'
        }
        let eSpec = {};
        if(goodsESpec1&&goodsESpec1.value!==''){
            eSpec[goodsESpec1.label]=goodsESpec1.value;
        }
        if(goodsESpec2&&goodsESpec2.value!==''){
            eSpec[goodsESpec2.label]=goodsESpec2.value;
        }
        if(goodsESpec3&&goodsESpec3.value!==''){
            eSpec[goodsESpec3.label]=goodsESpec3.value;
        }
        history.push({
            pathname: settingURL,
            state: { 
                "orgId": history.location.state.warehouseData.orgId, 
                "addOpen":true,
                "goodsType":goodsType,
                "goodsBrand":goodsBrand,
                "goodsCategory":goodsCategory,
                "spec":eSpec,
                "warehouseData":history.location.state.warehouseData
            },
        });
    }

    const showExtraSpec = (lineData)=>{
        console.log(lineData)
        let tempName = '';
        let arr=[];
        if(lineData.goodsType&&lineData.goodsType!==''){
            arr.push(lineData.goodsType);
        }
        
        if(lineData.category&&lineData.category!==''){
            arr.push(lineData.category);
        }
        if(lineData.brand!==''){
            arr.push(lineData.brand);
        }
        if(lineData.extralSpec&&lineData.extralSpec!==''){
            // arr.push(goodsESpec3.value);
            arr = arr.concat(Object.values(lineData.extralSpec));
        }
        tempName = arr.join('/');
        console.log(tempName);
        return tempName;
    }

    const scanQRCodeHandler = () =>{
        setQrCodeOpen(true);
    }

    return (
        <div className={classes.root}> 
            <div className={classes.box}>
                <Grid container justifyContent="flex-start" alignItems="center" className={classes.pageTitle}>
                    <div onClick={goBackHandler}>
                        <UndoIcon color="primary" className={classes.undoIcon} ></UndoIcon>
                    </div>
                    <Typography variant="h3" gutterBottom style={{"fontWeight":"bold"}}>
                        {t('checkIn.title')}  -  {history.location.state.warehouseData.name}
                    </Typography>
                </Grid>

                <Grid container justifyContent="flex-start" alignItems="center" direction="row" className={classes.pageContent}>
                    <Grid item xs={12} container className={classes.firstPart}>
                        <Grid item xs={12} className={classes.itemSpacing}>
                            <Grid container xs={12}>
                                <Grid item xs={12} >
                                    <Grid container xs={12}>
                                        <Grid item md={3} xs={6}>
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkIn.billNo")}<span className={classes.required}>*</span>
                                            </Typography>
                                        </Grid>
                                        <Grid md={1} >

                                        </Grid>
                                        <Grid item md={3} xs={5}>
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkIn.refBillNo")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container xs={12}>
                                        <Grid item md={3} xs={5} style={{ margin: "auto 0" }}>
                                            <TextField
                                                id="invoice_number_text"
                                                error={billNumberError}
                                                disabled = {true}
                                                value={billNumber}
                                                required={true}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                                onChange={billNumberChangeHandler}
                                            />
                                        </Grid>
                                        <Grid md={1} xs={2}>
                                            <Tooltip title="">
                                                <IconButton disabled = {submited||pageType!=="new"} onClick={getBillNumberHandler}>
                                                    <img src={randomIcon} className={classes.randomImage}></img>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={3} xs={4} style={{ margin: "auto 0" }}>
                                            <TextField
                                                id="invoice_number_text"
                                                disabled = {submited}
                                                value={refBillNumber}
                                                required={true}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                                onChange={refBillNumberChangeHandler}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {history.location.state.warehouseData.isMaterial&&<Grid container xs={12} spacing={1} className={classes.itemSpacing}>
                        <Grid item xs={2}>
                                    <Grid className={classes.editTitle} >
                                        {t('mGoodsManagement.goodsType')}
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            // value={item.title}
                                            value={goodsType}
                                            freeSolo
                                            fullWidth
                                            size='small'
                                            options={[t('materialGoods.typelistSteel'),t('materialGoods.typelistStainless'),t('materialGoods.typelistAl'),t('materialGoods.typelistTi'),t('materialGoods.typelistNi'),t('materialGoods.typelistLi'),t('materialGoods.typelistZr'),t('materialGoods.typelistCu')].map(option=>option)}
                                            // getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                {option}
                                                </React.Fragment>
                                            )}
                                            
                                            // onChange={(e, newValue) => {
                                            //     setUnitforCost(newValue);
                                            //     console.log(unitforCost);
                                            // }}
                                            onInputChange={(e,newInputValue)=>{
                                                setGoodsType(newInputValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('mGoodsManagement.goodsType')}/>}
                                            style={{marginTop:'8px'}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid className={classes.editTitle}>
                                        {t('mGoodsManagement.brand')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="description"
                                            variant="outlined"
                                            value={goodsBrand}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>{
                                                setGoodsBrand(e.target.value);
                                            }}
                                            placeholder={t('mGoodsManagement.brand')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={2}>
                                    <Grid className={classes.editTitle}>
                                        {t('mGoodsManagement.shape')}
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            id="goodsCategory"
                                            value={goodsCategory}
                                            fullWidth
                                            required={true}
                                            variant="outlined"
                                            size="small"
                                            onChange={e=>{
                                                setGoodsCategory(e.target.value);
                                                
                                                if(e.target.value==='棒'||e.target.value==='Bar'){
                                                    setGoodsESpec1({label:t('materialGoods.dimDiameter'),required:false,value:'',error:false});
                                                    setGoodsESpec2({label:t('materialGoods.dimLength'),required:false,value:'',error:false});
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='无缝管'||e.target.value==='焊接管'||e.target.value==='Seamless tube'||e.target.value==='Welding tube'){
                                                    setGoodsESpec1({label:t('materialGoods.dimDiameter'),required:false,value:'',error:false});
                                                    setGoodsESpec2({label:t('materialGoods.dimWall'),required:false,value:'',error:false});
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='板'||e.target.value==='条-块-方棒'||e.target.value==='Sheet'||e.target.value==='Strip-Ingot-Square bar'){
                                                    setGoodsESpec1({label:t('materialGoods.dimStickness'),required:false,value:'',error:false});
                                                    setGoodsESpec2({label:t('materialGoods.dimWidth'),required:false,value:'',error:false});
                                                    setGoodsESpec3({label:t('materialGoods.dimLength'),required:false,value:'',error:false});
                                                }
                                                if(e.target.value==='丝'||e.target.value==='Wire'){
                                                    setGoodsESpec1({label:t('materialGoods.dimDiameter'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='颗粒'||e.target.value==='Grain'){
                                                    setGoodsESpec1({label:t('materialGoods.dimSize1'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='粉'||e.target.value==='Powder'){
                                                    setGoodsESpec1({label:t('materialGoods.dimSize2'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='异形'||e.target.value==='Special shape'){
                                                    setGoodsESpec1({label:t('mGoodsManagement.dimension'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                                if(e.target.value==='其他'||e.target.value==='Others'){
                                                    setGoodsESpec1({label:t('mGoodsManagement.dimension'),required:false,value:'',error:false});
                                                    setGoodsESpec2(null);
                                                    setGoodsESpec3(null);
                                                }
                                            }}
                                            classes={{ select: classes.selectField }}
                                            style={{marginTop:'7px'}}
                                        >
                                            {categoryData && categoryData.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                {goodsESpec1&&<Grid item xs={2}>
                                    <Grid className={classes.editTitle}>
                                        {goodsESpec1.label}{goodsESpec1.required&&<span className={classes.required}>*</span>}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="spec"
                                            variant="outlined"
                                            value={goodsESpec1.value}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={setGoodsESpec1Handler}
                                            placeholder={t('goodsManagement.spec')}
                                        />
                                    </Grid>
                                </Grid>}
                                {goodsESpec2&&<Grid item xs={2}>
                                    <Grid className={classes.editTitle}>
                                        {goodsESpec2.label}{goodsESpec2.required&&<span className={classes.required}>*</span>}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="spec"
                                            variant="outlined"
                                            value={goodsESpec2.value}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={setGoodsESpec2Handler}
                                            placeholder={t('goodsManagement.spec')}
                                        />
                                    </Grid>
                                </Grid>}
                                {goodsESpec3&&<Grid item xs={2}>
                                    <Grid className={classes.editTitle}>
                                        {goodsESpec3.label}{goodsESpec3.required&&<span className={classes.required}>*</span>}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="spec"
                                            variant="outlined"
                                            value={goodsESpec3.value}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={setGoodsESpec3Handler}
                                            placeholder={t('goodsManagement.spec')}
                                        />
                                    </Grid>
                                </Grid>}
                        </Grid>
                        }
                        <Grid item xs={12} className={classes.itemSpacing}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkIn.goodsIdOrName")}<span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={5} >
                                    <Select
                                        fullWidth
                                        required={true}
                                        disabled = {submited}
                                        error={selGoodError}
                                        variant="outlined"
                                        value={selGood}
                                        onChange={goodsChangeHandler}
                                        classes={{ select: classes.selectField }}
                                    >
                                        {goodsData && goodsData.map((item, index) => (
                                            <MenuItem key={index} value={item.goodsId}>
                                                {item.id}/{item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={4} md={2} alignItems="flex-start">
                                    <IconButton id="goodsItemAdd" disabled={submited} onClick={goodsItemAddHandler}>
                                        <AddCircleIcon >
                                        </AddCircleIcon>
                                    </IconButton>
                                    <IconButton id="scanQRCode" disabled={submited} onClick={scanQRCodeHandler}>
                                        <CropFreeIcon >
                                        </CropFreeIcon>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.secondItemSpacing}>
                            <Grid container>
                            {!history.location.state.warehouseData.isMaterial&&<Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkIn.category")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.category}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>}

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkIn.description")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3} >
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkIn.rev")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.rev}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {selGoodInfo&&history.location.state.warehouseData.isMaterial?t("goodsManagement.eSpec"):t("checkIn.spec")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo&&history.location.state.warehouseData.isMaterial?showExtraSpec(selGoodInfo):selGoodInfo.spec}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.secondItemSpacing}>
                            <Grid container>
                                

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkIn.standard")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.standard}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3} >
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkIn.unitforCost")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.unitCost}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkIn.unitforStorage")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.unitStorage}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="body1" className={classes.itemTitle}>
                                                {t("checkIn.currency")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.itemContent}>
                                                {selGoodInfo.currency}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12} className={classes.secondItemSpacing}>
                            <Grid container>
                                

                                <Grid item xs={3} >

                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>

                    <Grid container className={classes.dashedLine}></Grid>

                    <Grid item xs={12} className={classes.secondPart}>
                        {lotItemData.map((item, index) => {
                            return (
                                <Grid container key={index}>
                                    <Grid item xs={12} >
                                        <Grid container>
                                            <Grid item xs={3} style={{ margin: "auto 0" }}>
                                                <Typography variant="body1" className={classes.itemTitle}>
                                                    {t("checkIn.lotNo")}<span className={classes.required}>*</span>
                                                </Typography>
                                            </Grid>
                                            <Grid xs={1}>
                                            </Grid>
                                            {/* <Grid item xs={3} style={{ margin: "auto 0" }}>
                                                <Typography variant="body1" className={classes.itemTitle}>
                                                    {t("checkIn.refLotNo")}
                                                </Typography>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Grid container>
                                            <Grid item xs={3} style={{ margin: "auto 0" }}>
                                                <TextField
                                                    id="invoice_number_text"
                                                    error={item.lotNo.error}
                                                    disabled = {submited}
                                                    value={item.lotNo.value}
                                                    required={true}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.textField}
                                                    onChange={(event) => lotNumberChangeHandler(index, event)}
                                                />
                                            </Grid>
                                            {/* <Grid xs={1}>
                                                <Tooltip title="">
                                                    <IconButton disabled = {submited} onClick={(event) => lotNumberGenrateHandler(event,index)}>
                                                        <img src={randomIcon} className={classes.randomImage}></img>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid> */}
                                            {/* <Grid item xs={2} style={{ margin: "auto 0" }}>
                                                <TextField
                                                    id="invoice_number_text"
                                                    error={item.refLotNo.error}
                                                    disabled = {submited}
                                                    value={item.refLotNo.value}
                                                    required={true}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.textField}
                                                    onChange={(event) => refLotNumberChangeHandler(index, event)}
                                                />
                                            </Grid> */}
                                            <Grid xs={3}>
                                                <Tooltip title="">
                                                    <IconButton style={{ visibility: lotItemData.length > 1 ? "visible" : "hidden" }} disabled = {submited} onClick={() => removeLotItemDataHandler(index)}>
                                                        <DeleteIcon size="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent="flex-start" className={classes.customMargin2}>
                                        <Grid item xs={2} style={{marginRight:'8px'}}>
                                            <Grid container item xs={12} >
                                                <Typography variant="body1" className={classes.itemTitle}>
                                                    {t("checkIn.checkInCost")}<span className={classes.required}>*</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="check_in_cost_text"
                                                    error={item.checkInCost.error}
                                                    disabled = {submited}
                                                    value={item.checkInCost.value}
                                                    required={true}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.textField}
                                                    onChange={(event) => checkInCostHandler(index, event)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} style={{marginRight:'8px'}}>
                                            <Grid item xs={12} >
                                                <Typography variant="body1" className={classes.itemTitle}>
                                                    {t("checkIn.qtyForCost")}<span className={classes.required}>*</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="qty_for_cost_text"
                                                    disabled = {submited}
                                                    error={item.qtyForCost.error}
                                                    value={item.qtyForCost.value}
                                                    required={true}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.textField}
                                                    onChange={(event) => qtyForCostHandler(index, event)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} style={{marginRight:'8px'}}>
                                            <Grid item xs={12} >
                                                <Typography variant="body1" className={classes.itemTitle}>
                                                    {t("checkIn.qtyForStorage")}<span className={classes.required}>*</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    id="qty_for_storage_text"
                                                    disabled = {submited}
                                                    error={item.qtyForStorage.error}
                                                    value={item.qtyForStorage.value}
                                                    required={true}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.textField}
                                                    onChange={(event) => qtyForStorageHandler(index, event)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} style={{marginRight:'8px'}}>
                                            <Grid item xs={12} >
                                                <Typography variant="body1" className={classes.itemTitle}>
                                                    {t("checkIn.certification")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="certification_text"
                                                    disabled = {submited}
                                                    error={item.certification.error}
                                                    value={item.certification.value}
                                                    required={true}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.textField}
                                                    onChange={(event) => certificationHandler(index, event)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} style={{marginRight:'8px'}}>
                                            <Grid item xs={12} >
                                                <Typography variant="body1" className={classes.itemTitle}>
                                                    {t("checkIn.location")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="location_text"
                                                    disabled = {submited}
                                                    error={item.location.error}
                                                    value={item.location.value}
                                                    required={true}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.textField}
                                                    onChange={(event) => locationHandler(index, event)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {item.lotCheckError.error && <Grid container alignItems="center" justifyContent="flex-start" direction="row" style={{marginTop:'8px'}}>
                                        <Alert severity="error" style={{marginBottom:'6px'}}><Typography>{item.lotCheckError.value}</Typography></Alert>
                                    </Grid>}
                                </Grid>
                            )
                        })}
                        
                        <IconButton id="lotNoItemAdd" disabled={submited} onClick={lotItemDataAddHandler} style={{ margin: '0 20px' }}>
                            <AddCircleIcon >
                            </AddCircleIcon>
                        </IconButton>
                        
                    </Grid>

                    <Grid container className={classes.dashedLine}></Grid>

                    <Grid item xs={12} className={classes.thirdPart}>
                        <Grid container>
                            <Grid item xs={12} >
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t("checkIn.refNo")}<span className={classes.required}>*</span>
                                </Typography>
                            </Grid>
                            {refFileItemData.map((item, index) => {
                                return (
                                    <Grid container style={{ margin: "0 0 0px 0" }} key={index}>
                                        <Grid item md={2} xs={3} className={classes.thirdItemSpacing}>
                                            <Select
                                                fullWidth
                                                disabled = {submited}
                                                required={true}
                                                error={item.fileType.error}
                                                variant="outlined"
                                                value={item.fileType.value}
                                                onChange={(event) => fileTypeChangeHandler(index, event)}
                                                classes={{ select: classes.selectField }}
                                            >
                                                {fileTypeData && fileTypeData.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item md={2} xs={5} className={classes.thirdItemSpacing}>
                                            <TextField
                                                id="location_text"
                                                disabled = {submited}
                                                error={item.fileNo.error}
                                                value={item.fileNo.value}
                                                required={true}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                                onChange={(event) => fileNoChangeHandler(index, event)}
                                            />
                                        </Grid>
                                        <Grid xs={1}>
                                            <Tooltip title="">
                                                <IconButton style={{ visibility: refFileItemData.length > 1 ? "visible" : "hidden" }} disabled = {submited} onClick={() => removeRefFileItemDataHandler(index)}>
                                                    <DeleteIcon size="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            <IconButton onClick={refFileItemDataAddHandler} disabled={submited} style={{ margin: '0 20px' }}>
                                <AddCircleIcon >
                                </AddCircleIcon>
                            </IconButton>
                            

                            <Grid container>
                                <Grid item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkIn.supplier")}
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={6} >
                                    <Autocomplete
                                        id="Host"
                                        freeSolo
                                        disabled = {submited}
                                        value={supplier}
                                        options={[]}
                                        getOptionLabel={(option) => option?option.name:""}
                                        onChange={(event, newValue) => {
                                            setSupplier(newValue);
                                        }}
                                        // onInputChange={(event, newValue) => {
                                        //     setSupplier(newValue);
                                        // }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} margin="dense" onKeyDown={e=>{
                                                if(e.keyCode === 13){
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }
                                            }} variant="outlined" size="small" />
                                        )}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            let keyword = params.inputValue;
                                            if(keyword!==""&&keyword.length>0){
                                                companyList.map((item) => {
                                                    if (item.counterpartyName.toLowerCase().indexOf(keyword.toLowerCase())>=0) {
                                                        filtered.push({
                                                            id: item.orgId,
                                                            name: item.counterpartyName
                                                        })
                                                    }
                                                })
                                                if(filtered.length===0){
                                                    filtered.push({
                                                        id: '',
                                                        name: keyword
                                                    });
                                                }
                                            }
                                            return filtered;
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.dashedLine}></Grid>

                    <Grid item xs={12} className={classes.forthPart}>
                        <Grid container>
                            <Grid item md={3} xs={5} className={classes.customMargin}>
                                <Grid container item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkIn.inspector")}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} >
                                    <TextField
                                        id="inspector_text"
                                        disabled = {submited}
                                        value={inspector}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={classes.textField}
                                        onChange={inspectorChangeHandler}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item md={3} xs={5}>
                                <Grid container item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkIn.inspectionDate")}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} >
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled={submited}
                                            autoOk
                                            id="date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            size="small"
                                            format="yyyy/MM/DD"
                                            InputAdornmentProps
                                            value={inspectionDate}
                                            onChange={date => inspectionDateChangeHandler(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item md={3} xs={5} className={classes.customMargin}>
                                <Grid container item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkIn.requestor")}<span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} >

                                    {/* <Autocomplete
                                        freeSolo
                                        disabled={submited}
                                        fullWidth
                                        selectOnFocus
                                        size='small'
                                        className={classes.input}
                                        value={requestor}
                                        options={staffArray}
                                        onChange={requestorChangeHandler}
                                        getOptionLabel={(option) => option}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                            {option}
                                            </React.Fragment>
                                        )}

                                        // onInputChange={handleManagerChange}
                                        
                                        renderInput={(params) => <TextField {...params} onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            }
                                        }} variant="outlined" error={requestorError} />}
                                        /> */}
                                    <TextField
                                        id="requestor_text"
                                        error={requestorError}
                                        value={requestor}
                                        disabled={submited}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={classes.textField}
                                        onChange={requestorChangeHandler}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item md={3} xs={5}>
                                <Grid container item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t("checkIn.warehouseStaff")}<span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} >

                                    <Autocomplete
                                        freeSolo
                                        disabled={submited}
                                        fullWidth
                                        selectOnFocus
                                        size='small'
                                        className={classes.input}
                                        value={warehouseStaff}
                                        options={staffArray}
                                        onChange={warehouseStaffChangeHandler}
                                        getOptionLabel={(option) => option?option.username+"("+ option.email +")":""}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                            {option.username+"("+ option.email +")"}
                                            </React.Fragment>
                                        )}

                                        // onInputChange={handleManagerChange}
                                        
                                        renderInput={(params) => <TextField {...params} onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            }
                                        }} variant="outlined" error={warehouseStaffError} />}
                                        />
                                </Grid>
                            </Grid>
                        </Grid>
  
                        <Grid container>
                            <Grid item xs={12} >
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t("checkIn.notes")}
                                </Typography>
                            </Grid>
                            <Grid container item md={10} xs={11}>
                                <Grid container>
                                    <TextField
                                        id="notes_text"
                                        disabled = {submited}
                                        value={notes}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        rows={4}
                                        className={classes.textField}
                                        onChange={notesChangeHandler}
                                    />
                                </Grid>
                                {submitError!=="" && <Grid container alignItems="center" justifyContent="center" direction="row" style={{marginTop:'8px'}}>
                                    <Alert severity="error" style={{marginBottom:'6px'}}><Typography>{submitError}</Typography></Alert>
                                </Grid>}
                                <Grid container alignItems="center" spacing={2} justifyContent="center" direction="row" className={classes.btnBottom}>
                                    <Grid item xs={4}>
                                        <Button variant="contained" className={classes.buttonSpacing} onClick={goBackHandler}>
                                            <Typography variant="subtitle2">
                                                {t("common.cancel")}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="contained" color="primary" className={classes.buttonSpacing}  disabled={!submited||history.location.state.operationType==='view'} onClick={submitCheckInFormHandler}>
                                            <Typography variant="subtitle2">
                                                {t("checkIn.title")}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="contained" color="primary" className={classes.buttonSpacing} disabled={submited||lotNumberCheckError} onClick={generateOrderHandler}>
                                            <Typography variant="subtitle2">
                                                {pageType==="new"?t("checkIn.generate"):t("common.save")}
                                            </Typography>
                                        </Button>
                                    </Grid>

                                </Grid>

                                
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
                <Dialog open={qrCodeOpen} scroll="body" maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogContent classes={{ root: classes.qrCode, }}>
                          <QrReader
                            onResult={(result, error) => {
                              if (!!result) {
                                setSelGood(result?.text);
                                goodsData.map( (item)=> {
                                if(item.goodsId === result?.text){
                                    setSelGoodInfo(item);
                                }
                                })
                                lotItemData.map((item,index)=>{
                                    checkLotNumber(index);
                                })
                                setQrCodeOpen(false);
                              }
                              if (!!error) {
                                console.info(error);
                              }
                            }}
                            style={{ width: '100%' }}
                            constraints={{facingMode:'environment'}}
                          />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={e=>setQrCodeOpen(false)} color="primary">
                            {t('common.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            
        </div>
    )
}