import { Button, Popover, TextField, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {useEffect, useState} from "react";
import CommonLayout from "../../components/CommonLayout";
import useToken from '../../hooks/useToken';
import termsPDFEn from '../../assets/pdf/TermsOfServiceEn.pdf'
import termsPDFZh from '../../assets/pdf/TermsOfServiceZh.pdf'
import privacyPDFEn from '../../assets/pdf/PrivacyPolicyEn.pdf'
import privacyPDFZh from '../../assets/pdf/PrivacyPolicyZh.pdf'
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    box: {
      padding: '30px 30px 10px 30px',
      width:'100%',
      maxWidth:'1100px',
      minHeight:'700px'
    },
}))

export default function TermsPage() {
    const history = useHistory();
    const style = useStyles();
	const { token, setToken} = useToken();
	const [currentLan, setCurrentLan] = useState();

	const search = queryString.parse(history.location.search);

    useEffect(()=>{
    	const localLanguage = window.localStorage.getItem('language');
        var defaultLang =(navigator.language || navigator.browserLanguage).toLowerCase();

        if(localLanguage){
            setCurrentLan(localLanguage);
        }else if(defaultLang.indexOf('en')!=-1){
            setCurrentLan('en');
        }
        else{
            setCurrentLan("zh");
        }
    },[])

    return (
        <div className={style.box} >
        	<iframe src={`${search.type&&search.type==='privacy'?(currentLan==='en'?privacyPDFEn:privacyPDFZh):(currentLan==='en'?termsPDFEn:termsPDFZh)}#view=FitH,top&toolbar=0`} frameBoder="0" style={{width:'100%',height:'100%'}}></iframe>
        </div>
    )
}