function readQuery(url) {
    let queries = {}

    if(url) {
        url = url.substr(1, url.length-1)
        console.log("Queries in url: " + url)

        let strKeyValues = url.split('&')
        if(strKeyValues && strKeyValues.length > 0) {
            strKeyValues.forEach((strKeyValue)=> {
                let equalIndex = strKeyValue.indexOf('=')
                let key = strKeyValue.substring(0, equalIndex)
                let value = strKeyValue.substring(equalIndex+1)
                queries[key] = value
            })
        }
    }

    return queries
}

export default {
    readQuery
}