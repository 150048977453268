import {axiosAccounting as axios} from './axiosHelper';

const uploadPdf = function(data){
    const url = '/auth/pdf';
    return new Promise((resolve, reject)=>{
        axios.post(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

export default {
    uploadPdf,
}