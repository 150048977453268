import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, Tooltip, TextField, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import CommonLayout from "../../components/CommonLayout";
import accountingService from "../../services/accountingService";
import uploadFileService from '../../services/uploadFileService';
import LoadingButton from "../../components/LoadingButton";
import { useTranslation } from 'react-i18next';
import Big from 'big.js'
import NotesEditor from "../../components/NotesEditor";
import {ImportExport} from "@material-ui/icons";
import XLSX from "xlsx";

const useStyles = makeStyles(theme => ({
    buttonCol: {
        color: theme.currentTheme.colors[0],
    },
    root: {
        borderBottom: "none",
        fontSize: "medium",
        backgroundColor: theme.currentTheme.backgrounds[11],
    },
    dateInput: {
        textAlign: "center"
    },
    dateAdornedEnd: {
        marginTop: "12px",
        '& > *': {
            paddingRight: "0px"
        },
    },
    lineDateInput: {
        '& > *': {
            '& > *': {
                textAlign: "center"
            },
        },
    },
    textMargin: {
        marginTop: "40px",
    },
    tableCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    poFormBox: {
        width: "100%",
        padding: "4vw",
    },
    poFormTitle: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "28px 0 20px 23px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[2],
        marginTop: "30px",

    },
    poFormContent: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "0 40px 0 40px",
    },
    poFormContentItem: {
        padding: "20px 0 0 0",
    },
    poFormContentItemBottom: {
        padding: "20px 0 30px 0",
    },
    textField: {
        margin: "12px 0 0 0",
    },
    selectField: {
        padding: "11px 14px",
    },
    selectTopItem: {
        marginBottom: "12px",
    },
    itemTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    required: {
        color: "#fd4747",
        display: "inline",
    },
    itemSpacing: {
        marginRight: "25px",
    },
    dashedLine: {
        margin: "23px 0 0 0",
        borderTop: "1px solid #dbdbdb",
        width: "100%",
    },
    buttonSpacing: {
        width: "150px",
        marginLeft: "10px",
    },
    upLoadRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    upLoadInput: {
        display: 'none',
    }
}))

export default function POFormIssue(props) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>
        ),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const history = useHistory();

    const [poNo, setPoNo] = useState("");
    const [refNumber, setRefNumber] = useState("");
    const [rev, setRev] = useState("");

    const [unsupportedPdfDialogOpen, setUnsupportedPdfDialogOpen] = useState(false);

    const [payTerm, setPayTerm] = useState("");

    const [deliveryTerm, setDeliveryTerm] = useState("");

    const [currencyData, setCurrencyData] = useState(["USD", "CNY", "EUR", "HKD", "JPY", "CAD", "AUD", "GBP"]);

    const [selCurrency, setSelCurrency] = useState("");

    const [poDate, setPoDate] = useState("");
    useEffect(() => {
        setPoDate(moment().format("YYYY/MM/DD"));
    }, []);

    const [supplierCode, setSupplierCode] = useState("");

    const [supplierPhoneNo, setSupplierPhoneNo] = useState("");

    const [supplierAddress, setSupplierAddress] = useState("");

    const [shippingAddress, setShippingAddress] = useState("");

    const [notes, setNotes] = useState("");

    const [poLines, setPoLines] = useState([]);

    const [formDataTemp, setFormDataTemp]= useState("");

    const [excelFormDataTemp, setExcelFormDataTemp]= useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [noteOverwriteOpen, setNoteOverwriteOpen] = useState(false);
    const [excelNoteOverwriteOpen, setExcelNoteOverwriteOpen] = useState(false);
    const [importExcelWarning, setImportExcelWarning] = useState(false);
    const [errorTips, setErrorTips] = useState([1, 1, 1]);

    const [poNoError, setPoNoError] = useState(false);

    const [revError, setRevError] = useState(false);

    const [deliveryTermError, setDeliveryTermError] = useState(false);

    const [payTermError, setPayTermError] = useState(false);

    const [supplierCodeError, setSupplierCodeError] = useState(false);

    const [supplierPhoneNoError, setSupplierPhoneNoError] = useState(false);

    const [currencyError, setCurrencyError] = useState(false);

    const [suppAddressError, setSuppAddressError] = useState(false);

    const [shipAddressError, setShipAddressError] = useState(false);

    const [notesError, setNotesError] = useState(false);

    const [responseMessage, setResponseMessage] = useState("");

    const handleOverwriteClose = () => {
        setNoteOverwriteOpen(false);
        setFormDataTemp("");
        setIsLoading(false);
      document.getElementById('file').value = null;
    }

  const handleExcelOverwriteClose = () => {
    setExcelNoteOverwriteOpen(false);
    setExcelFormDataTemp(null);
    setIsLoading(false);
    document.getElementById('excel').value = null;
  }

  const handleExcelWarningClose = () => {
    setImportExcelWarning(false);
    setIsLoading(false);
  }

    const handleUnsupportedPdfDialogClose = ()=> {
        setUnsupportedPdfDialogOpen(false);
    }

    const handelCurrencyChange = (e) => {
        setSelCurrency(e.target.value);
        if (e.target.value === "") {
            setCurrencyError(true);
        } else {
            setCurrencyError(false);
        }
    };

    const handlePONoChange = (e) => {
        setPoNo(e.target.value);
        if (e.target.value === "" || e.target.value.length > 50) {
            setPoNoError(true);
        } else {
            setPoNoError(false);
        }
    };

    const handleRefNoChange = (e) => {
        setRefNumber(e.target.value);
    };

    const handleRevChange = (e) => {
        setRev(e.target.value);
        if (e.target.value.length > 10) {
            setRevError(true);
        } else {
            setRevError(false);
        }
    };

    const handlePayTermChange = (e) => {
        setPayTerm(e.target.value);
        if (e.target.value === "" || e.target.value.length > 200) {
            setPayTermError(true);
        } else {
            setPayTermError(false);
        }
    };

    const handleDeliveryTermChange = (e) => {
        setDeliveryTerm(e.target.value);
        if (e.target.value.length > 200) {
            setDeliveryTermError(true);
        } else {
            setDeliveryTermError(false);
        }
    };

    const handlePODateChange = (date) => {
        const momentDate = moment(date).format("YYYY/MM/DD");
        setPoDate(momentDate);
    };

    const handleSupplierChange = (e) => {
        setSupplierCode(e.target.value);
        if (e.target.value.length > 50) {
            setSupplierCodeError(true);
        } else {
            setSupplierCodeError(false);
        }
    };

    const handleSupplierPhoneNoChange = (e) => {
        setSupplierPhoneNo(e.target.value);
        if (e.target.value.length > 20) {
            setSupplierPhoneNoError(true);
        } else {
            setSupplierPhoneNoError(false);
        }
    };

    const handleSupplierAddressChange = (e) => {
        setSupplierAddress(e.target.value);
        if (e.target.value.length > 200) {
            setSuppAddressError(true);
        } else {
            setSuppAddressError(false);
        }
    };

    const handleShippingAddressChange = (e) => {
        setShippingAddress(e.target.value);
        if (e.target.value.length > 200) {
            setShipAddressError(true);
        } else {
            setShipAddressError(false);
        }
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        if (e.target.value.length > 500) {
            setNotesError(true);
        } else {
            setNotesError(false);
        }
    };

    const handleSubmitPOForm = () => {
        setIsLoading(true);
        let newPoLines = [];
        let temp = {}
        poLines.map(item => {
            temp = {
                lineNumber: item.lineNumber,
                itemNumber: item.itemNumber,
                itemRev: item.itemRev && item.itemRev !== undefined ? item.itemRev : "",
                productName: item.productName,
                qty: item.qty,
                unit: item.unit && item.unit !== undefined ? item.unit : "",
                storageUnit: item.storageUnit && item.storageUnit !== undefined ? item.storageUnit : "",
                price: item.price,
                tax: item.tax && item.tax !== undefined ? item.tax : "",
                discount: item.discount && item.discount !== undefined ? item.discount : "",
                lineTotal: item.lineTotal,
                lineDate: item.lineDate && item.lineDate !== undefined ? item.lineDate : moment().format("YYYY/MM/DD"),
                notes: item.notes && item.notes !== undefined ? item.notes : "",
            }
            newPoLines.push(temp);
        })
        let data = {
            poNumber: poNo,
            refNumber:refNumber,
            poRev: rev,
            paymentTerm: payTerm,
            deliveryTerm: deliveryTerm,
            currency: selCurrency,
            poDate: poDate,
            supplierCode: supplierCode,
            supplierPhoneNumber: supplierPhoneNo,
            supplierAddress: supplierAddress,
            shippingAddress: shippingAddress,
            poNotes: notes,
            poLines: newPoLines,
        }
        if (validate()) {
            console.log("Validated PoForm Data:", data);
            if (history.location.state && history.location.state.bookId) {
                accountingService.addPo(history.location.state.bookId, data).then(
                    result => {
                        if (result.data) {
                            console.log("Issue Poform Data Result:", result.data);
                            setIsLoading(false);
                            setResponseMessage("");
                            history.push("/PO Transactions/detail/" + history.location.state.bookId);
                        }else{
                            console.log("Error Result:", result);
                        }
                    }
                ).catch((err) => {
                    setIsLoading(false);
                    if (err) {
                        console.error('---> Issue Poform Data error: ', err);
                    }
                    if (err.response && err.response.data) {
                        setResponseMessage(err.response.data.message);
                    }
                });
            }
        }else{
            setIsLoading(false);
        }
    };

    const validate = () => {
        let temp = false;
        setResponseMessage();
        if (poNo === "") {
            setPoNoError(true);
            temp = false;
        }
        if (rev && rev.length > 10) {
            setRevError(true);
        }
        if (deliveryTerm && deliveryTerm.length > 200) {
            setDeliveryTermError(true);
        }
        if (payTerm === "") {
            setPayTermError(true)
            temp = false;
        }
        if (supplierCode && supplierCode.length > 50) {
            setSupplierCodeError(true);
        }
        if (supplierPhoneNo && supplierPhoneNo.length > 20) {
            setSupplierPhoneNoError(true);
        }
        if (selCurrency === "") {
            setCurrencyError(true)
            temp = false;
        }
        if (notes && notes.length > 200) {
            setNotesError(true);
        }
        if (poNo && !revError && !deliveryTermError && payTerm && !supplierCodeError && !supplierPhoneNoError && selCurrency && !notesError) {
            temp = true;
        }
        return temp;
    };

    const goBack = () => {
        if (history.location.state && history.location.state.bookId) {
            history.push("/PO Transactions/detail/" + history.location.state.bookId);
        }
    };

    const checkNumber = (values) => {
        let reg = /^\d*(\.\d{1,4})?$/;
        if (values && !reg.test(values)) {
            return false;
        }
        return true;
    }

    const formatData = (value) => {
        let data = parseFloat(value).toFixed(4).replace(/d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
        return data;
    };

    const checkDuplication = (rowData) => {
        if(rowData.tableData && rowData.tableData.editing){
            return true;
        }else{
            for (var i = 0; i < poLines.length; i++) {
                if (poLines[i]["lineNumber"] === rowData.lineNumber) {
                    return false;
                }
            }
            return true;
        }
    };

    const getCaption =(obj) =>{
        const index = obj.lastIndexOf("\:");
        obj=obj.substring(index+1,obj.length);
        return obj;
    }

    const getBeforeCaption =(obj) => {
        obj = obj.split('Phone:')[0];
        return obj;
    }

    const getBeforeTrimCaption =(obj) => {
        obj = obj.split(' ')[0];
        return obj;
    }

    const delcommafy =(num) => {
        if((num+"").trim()==""){
         return"";
        }
        num = num.replace(/,/gi,'').trim();
        return num;
      }

  const handleFileChange = (e) => {
    const file = e.currentTarget.files[0];

    let formData = new FormData();
    formData.append("file", file);

    if (file && file.name) {
      const index = file.name.lastIndexOf("\.");
      let suffix = file.name.substring(index + 1, file.name.length);
      if (suffix.toLowerCase() === "pdf") {
        if (payTerm || poDate || poNo || rev || deliveryTerm || selCurrency || poDate || supplierCode || supplierPhoneNo || supplierAddress || shippingAddress || (poLines && poLines.length > 0)) {
          setNoteOverwriteOpen(true);
          setFormDataTemp(formData);
        } else {
          uploadPdfFunction(formData);
        }
      }
    }
  }

  function writePOWithExcel(files) {
    let name = files.name
    let suffix = name.substr(name.lastIndexOf("."));
    let reader = new FileReader();
    reader.onload = (event) => {
      try {
        if (".xls" !== suffix && ".xlsx" !== suffix) {
          console.error("选择Excel格式的文件导入!");
          setExcelNoteOverwriteOpen(false);
          return false;
        }
        let {result} = event.target;
        // 读取文件
        let workbook = XLSX.read(result, {type: 'binary', cellText: false, cellDates: true});
        let data = [];
        // 循环文件中的每个表
        for (let sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
              header: 1,
              raw: false,
              dateNF: 'mm/dd/yyyy'
            }));
          }
        }
        let newPoLines = [];
        let needShowWarning = false;
        let lineNumbers = [];
        const mom = new MomentUtils();
        let error = [1, 1, 1];

        data.map((item, index) => {
          if (index !== 0 && index !== 1 && item.length > 0) {
            if (item[0] && item[1] && item[3] && item[5] && item[7] && item[8] && item[9]
              && lineNumbers.indexOf(item[0]) < 0 && mom.isValid(item[9])) {

              lineNumbers.push(item[0]);

              newPoLines.push(
                {
                  lineNumber: item[0],
                  itemNumber: item[1],
                  itemRev: item[2],
                  productName: item[3],
                  storageUnit: item[4],
                  qty: item[5],
                  unit: item[6],
                  price: item[7],
                  lineTotal: item[8],
                  lineDate: moment(item[9]).format("YYYY/MM/DD"),
                  notes: item[10],
                }
              );

            } else {

              if (!item[9] || !item[8] || !item[7] || !item[5] || !item[3] || !item[1] || !item[0]) {
                error[0] = 0;
              }

              if (lineNumbers.indexOf(item[0]) >= 0) {
                error[1] = 0;
              }

              if (!mom.isValid(item[9])) {
                error[2] = 0;
              }

              needShowWarning = true;

            }


          }

        });

        setErrorTips(error);

        setExcelNoteOverwriteOpen(false);
        setPoLines(newPoLines);
        if (needShowWarning) {
          console.error('tip warning: ', error)
          setImportExcelWarning(true);
        }
        document.getElementById('excel').value = null;

      } catch (e) {
        console.error('文件类型不正确！');
        setExcelNoteOverwriteOpen(false);
        document.getElementById('excel').value = null;
      }
    }
    reader.readAsBinaryString(files);
  }

  const handleExcelChange = (e) => {

    let file = e.currentTarget.files[0];
    if (poLines && poLines.length > 0) {
      setExcelNoteOverwriteOpen(true);
      setExcelFormDataTemp(file);
    } else {
      writePOWithExcel(file);
    }

  }

    const handleOverwriteConfirm =()=> {
        uploadPdfFunction(formDataTemp);
    }

  const handleExcelOverwriteConfirm =()=> {
    writePOWithExcel(excelFormDataTemp);
  }

    const uploadPdfFunction = (formData)=> {
        setIsLoading(true);
        uploadFileService.uploadPdf(formData).then(
            result => {
                if (result.status === 200 && result.data) {
                    let supplier_phone = getCaption(result.data.static.supplier_info);
                    let supplier_address =getBeforeCaption(result.data.static.supplier_info);
                    let newPoLines = [];
                    let temp = {}
                    result.data.dynamic.map(item => {
                        temp = {
                            lineNumber: item.pos.trim(),
                            itemNumber: item.item.trim(),
                            itemRev: item.rev && item.itemRev !== undefined ? item.itemRev.trim() : "",
                            productName: item.product_name.trim(),
                            qty: delcommafy(item.qty),
                            unit: item.unit && item.unit !== undefined ? item.unit.trim() : "",
                            price: delcommafy(item.price),
                            tax: item.tax && item.tax !== undefined ? item.tax.trim() : "",
                            discount: item.discount && item.discount !== undefined ? item.discount.trim() : "",
                            lineTotal: delcommafy(item.line_total),
                            lineDate: item.etd && item.etd !== undefined ? moment(getBeforeTrimCaption(item.etd)).format("YYYY/MM/DD") : moment().format("YYYY/MM/DD"),
                            notes: notes,
                        }
                        newPoLines.push(temp);
                    })
                    setDeliveryTerm(result.data.static.delivery_term)
                    if (result.data.static.payment_term === "") {
                        setPayTermError(true);
                    }else {
                        setPayTermError(false);
                    }
                    setPayTerm(result.data.static.payment_term);
                    setPoDate(result.data.static.po_date);
                    if (result.data.static.po_number === "") {
                        setPoNoError(true);
                    }else {
                        setPoNoError(false);
                    }
                    setPoNo(result.data.static.po_number);
                    setRev(result.data.static.po_rev);
                    setShippingAddress(result.data.static.shipping_address);
                    setSupplierAddress(supplier_address);
                    setSupplierPhoneNo(supplier_phone);
                    setSupplierCode(result.data.static.supplier_code);
                    setPoLines(newPoLines);
                }
                setFormDataTemp("");
                setIsLoading(false);
                setNoteOverwriteOpen(false);
              document.getElementById('file').value = null;
            }
        ).catch((err) => {
            if (err) {
                setFormDataTemp("");
                setIsLoading(false);
                setNoteOverwriteOpen(false);
                setUnsupportedPdfDialogOpen(true);
              document.getElementById('file').value = null;
            }
        });

    }

    const emptyValue =()=>{
        setDeliveryTerm("");
        setPayTerm("");
        setPoNo("");
        setRefNumber("")
        setRev("");
        setSelCurrency("");
        setShippingAddress("");
        setSupplierAddress("");
        setSupplierPhoneNo("");
        setSupplierCode("");
        setPoLines([]);
    }

    return (
        <CommonLayout>
            <div className={classes.poFormBox}>
                <Grid container justifyContent="flex-end" alignItems="center" direction="row">
                    <Grid style={{ textAlign: "right" }}>
                        <div className={classes.upLoadRoot}>
                            <input
                                accept='application/pdf'
                                className={classes.upLoadInput}
                                id="file"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                            />
                            <input
                              accept='.xls, .xlsx'
                              className={classes.upLoadInput}
                              id="excel"
                              type="file"
                              onChange={handleExcelChange}
                            />
                            <label htmlFor="file">
                                <Tooltip title={t('poFormPage.scanTitle')}>
                                    <Button variant="contained" color="primary" component="span" >
                                        <PublishOutlinedIcon></PublishOutlinedIcon>
                                        <Typography variant="subtitle2" style={{ marginLeft: '4px' }}>
                                            {t('poFormPage.scan')}
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </label>
                        </div>
                    </Grid>
                </Grid>

                <Grid container justifyContent="flex-start" alignItems="center" className={classes.poFormTitle}>
                    <Typography variant="h6" gutterBottom >
                    {t('poFormPage.poForm')}
                    </Typography>
                </Grid>

                <Grid className={classes.poFormContent}>
                    <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                        <Grid item xs={2} className={classes.itemSpacing}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('poFormPage.poNumber')} <span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="poNo"
                                        value={poNo}
                                        error={poNoError}
                                        required={true}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={classes.textField}
                                        onChange={handlePONoChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} className={classes.itemSpacing}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('poFormPage.refNumber')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="poNo"
                                        value={refNumber}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={classes.textField}
                                        onChange={handleRefNoChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} className={classes.itemSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                {t('poFormPage.rev')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="rev"
                                    value={rev}
                                    error={revError}
                                    required={true}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    onChange={handleRevChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={2} className={classes.itemSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                {t('poFormPage.deliveryTerm')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="deliveryTerm"
                                    value={deliveryTerm}
                                    error={deliveryTermError}
                                    required={true}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    onChange={handleDeliveryTermChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={2} className={classes.itemSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                {t('poFormPage.paymentTerm')} <span className={classes.required}>*</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="payTerm"
                                    value={payTerm}
                                    error={payTermError}
                                    required={true}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    onChange={handlePayTermChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                        <Grid item xs={2} className={classes.itemSpacing}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('poFormPage.poDate')} <span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}
                                    >
                                        <KeyboardDatePicker
                                            id="date"
                                            value={poDate}
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            size="small"
                                            format="YYYY/MM/DD"
                                            className={classes.dateAdornedEnd}
                                            onChange={date => handlePODateChange(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} className={classes.itemSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                {t('poFormPage.supplierCode')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="supplier_code"
                                    value={supplierCode}
                                    error={supplierCodeError}
                                    required={true}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    onChange={handleSupplierChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.itemSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                {t('poFormPage.supplierPhoneNumber')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="supplierPhoneNo"
                                    value={supplierPhoneNo}
                                    error={supplierPhoneNoError}
                                    required={true}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    onChange={handleSupplierPhoneNoChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={2} className={classes.itemSpacing}>
                            <Grid item xs={12} className={classes.selectTopItem}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                {t('poFormPage.currency')} <span className={classes.required}>*</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    id="currency"
                                    value={selCurrency}
                                    error={currencyError}
                                    fullWidth
                                    required={true}
                                    variant="outlined"
                                    onChange={handelCurrencyChange}
                                    classes={{ select: classes.selectField }}
                                >
                                    {currencyData && currencyData.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                {t('poFormPage.supplierAddress')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="supplierAddress"
                                    value={supplierAddress}
                                    error={suppAddressError}
                                    required={true}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    onChange={handleSupplierAddressChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                {t('poFormPage.shippingAddress')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="shippingAddress"
                                    value={shippingAddress}
                                    error={shipAddressError}
                                    required={true}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    onChange={handleShippingAddressChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.dashedLine}>

                    </Grid>

                    <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                        <Grid item container xs={12}>
                            <MaterialTable
                                title=""
                                icons={tableIcons}
                                columns={[
                                    {
                                        title: t('poFormPage.line')+'*', field: 'lineNumber',
                                        validate: rowData =>  rowData.lineNumber !== '' && rowData.lineNumber !== undefined && checkDuplication(rowData),
                                        cellStyle: {
                                            textAlign: "center",
                                            padding: "4px"
                                        },
                                    },
                                    {
                                        title: t('poFormPage.item')+'*', field: 'itemNumber',
                                        validate: rowData => {
                                            if (rowData.tableData && rowData.tableData.editing === "delete") {
                                                return true;
                                            } else return rowData.itemNumber !== '' && rowData.itemNumber !== undefined;
                                        },
                                        cellStyle: {
                                            textAlign: "center",
                                            padding: "4px"
                                        },
                                    },
                                    {
                                        title: t('poFormPage.rev'), field: 'itemRev',
                                        cellStyle: {
                                            textAlign: "center",
                                            padding: "4px"
                                        },
                                    },
                                    {
                                        title: t('poFormPage.product')+'*', field: 'productName',
                                        validate: rowData => {
                                            if (rowData.tableData && rowData.tableData.editing==="delete"){
                                                return true;
                                            } else return rowData.productName !== '' && rowData.productName !== undefined;
                                        },
                                        cellStyle: {
                                            textAlign: "center",
                                            padding: "4px"
                                        },
                                    },
                                    {
                                        title: t('poFormPage.storageUnit'), field: 'storageUnit', sorting: false,
                                        cellStyle: {
                                            textAlign: "center",
                                            padding: "4px"
                                        },
                                    },
                                    {
                                        title: t('poFormPage.QTY')+'*', field: 'qty',
                                        validate: rowData => {
                                            if (rowData.tableData && rowData.tableData.editing === "delete") {
                                                return true;
                                            } else return rowData.qty !== '' && rowData.qty !== undefined && checkNumber(rowData.qty);
                                        },
                                        render: rowData => formatData(rowData.qty),
                                        cellStyle: {
                                            textAlign: "center",
                                            padding: "4px"
                                        },
                                        editComponent: props => (
                                            <TextField
                                                id="qty_text"
                                                value={props.rowData.qty}
                                                error={!props.rowData.qty || props.rowData.qty === undefined || props.rowData.qty === ""||!checkNumber(props.rowData.qty)}
                                                required={true}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                                onChange={e => {
                                                    props.onRowDataChange({
                                                        ...props.rowData,
                                                        lineTotal: e.target.value && props.rowData.price ? new Big(e.target.value).times(props.rowData.price).toFixed(4).toString() : "",
                                                        qty: e.target.value,
                                                    })
                                                }}
                                            >
                                            </TextField>
                                        ),
                                    },
                                    {
                                        title: t('poFormPage.unit'), field: 'unit',
                                        cellStyle: {
                                            textAlign: "center",
                                            padding: "4px"
                                        },
                                    },

                                    {
                                        title: t('poFormPage.price')+'*', field: 'price',
                                        validate: rowData => {
                                            if (rowData.tableData && rowData.tableData.editing === "delete") {
                                                return true;
                                            } else return rowData.price !== '' && rowData.price !== undefined && checkNumber(rowData.price);
                                        },
                                        render: rowData => formatData(rowData.price),
                                        cellStyle: {
                                            textAlign: "center",
                                            padding: "4px",
                                        },
                                        editComponent: props => (
                                            <TextField
                                                id="price_text"
                                                value={props.rowData.price}
                                                error={!props.rowData.price || props.rowData.price === undefined || props.rowData.price === ""||!checkNumber(props.rowData.price)}
                                                required={true}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                                onChange={e => {
                                                    props.onRowDataChange({
                                                        ...props.rowData,
                                                        lineTotal: e.target.value && props.rowData.qty ? new Big(e.target.value).times(props.rowData.qty).toFixed(4).toString() : "",
                                                        price: e.target.value,
                                                    })
                                                }}
                                            >
                                            </TextField>
                                        ),
                                    },
                                    {
                                        title: t('poFormPage.lineTotal')+'*', field: 'lineTotal',
                                        validate: rowData => {
                                            if (rowData.tableData && rowData.tableData.editing === "delete") {
                                                return true;
                                            } else return rowData.lineTotal !== '' && rowData.lineTotal !== undefined && checkNumber(rowData.lineTotal);
                                        },
                                        render: rowData => formatData(rowData.lineTotal),
                                        cellStyle: {
                                            width: '10%',
                                            textAlign: "center",
                                            padding: "4px",
                                        },
                                        editComponent: (props) => {
                                            return (
                                                <TextField
                                                    id="linetotal_text"
                                                    value={props.rowData.lineTotal ? props.rowData.lineTotal : "0"}
                                                    error={!props.rowData.lineTotal || props.rowData.lineTotal === undefined || props.rowData.lineTotal === ""||!checkNumber(props.rowData.lineTotal)}
                                                    required={true}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    size="small"
                                                    onChange={e => {
                                                        props.onChange(e.target.value)
                                                    }}
                                                >
                                                </TextField>
                                            )
                                        }
                                    },
                                    {
                                        title: t('poFormPage.date')+'*', field: 'lineDate', type: 'date', emptyValue: moment().format("YYYY/MM/DD"),
                                        editComponent: props => (
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DatePicker
                                                    autoOk
                                                    //disableToolbar
                                                    id="date"
                                                    variant="inline"
                                                    size="small"
                                                    format="YYYY/MM/DD"
                                                    value={props.value}
                                                    className={classes.lineDateInput}
                                                    onChange={date => props.onChange(moment(date).format("YYYY/MM/DD"))}
                                                />
                                            </MuiPickersUtilsProvider>
                                        ),
                                        cellStyle: {
                                            width: '15%',
                                            textAlign: "center",
                                            padding: "4px"
                                        },
                                    },
                                    {
                                        title: t('poFormPage.notes'), field: 'notes', emptyValue: '', sorting: false,
                                        cellStyle: {
                                            width: '8%',
                                            textAlign: "center",
                                            padding: "4px"
                                        },
                                        editComponent: props => (
                                            <NotesEditor
                                                id="notes"
                                                value={props.rowData.notes ? props.rowData.notes : ""}
                                                onChange={value => props.onChange(value)} />
                                        ),
                                    }
                                ]}
                                data={poLines}
                                style={{ width: "100%" }}
                                options={{
                                    actionsColumnIndex: -1,
                                    search: false,
                                    paging: false,
                                    sorting: false,
                                    cellStyle: {
                                        padding: "4px",
                                        textAlign: "center",
                                    },
                                    headerStyle: {
                                        padding: "4px",
                                        textAlign: "center",
                                        backgroundColor: "transparent"
                                    },
                                }}
                                actions={[
                                    {
                                        icon: () => {
                                            return (
                                              <label htmlFor="excel" style={{cursor:'pointer'}}>
                                                  <ImportExport className={classes.buttonCol}></ImportExport>
                                              </label>
                                            )
                                        },
                                        tooltip: t('poFormPage.importExcel'),
                                        isFreeAction: true,
                                    }
                                ]}
                                localization={{
                                    header:{
                                        actions: t('poFormPage.actions')
                                    },
                                    body: {
                                        editTooltip: t('common.edit'),
                                        deleteTooltip: t('common.delete'),
                                        addTooltip:t('common.add'),
                                        editRow: {
                                            saveTooltip: t('common.check'),
                                            cancelTooltip: t('common.cancel')
                                        },
                                        emptyDataSourceMessage:t("common.noRecord"),
                                    },
                                }}
                                editable={{

                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                setPoLines([...poLines, newData]);
                                                resolve();
                                            }, 1000)
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                const dataUpdate = [...poLines];
                                                const index = oldData.tableData.id;
                                                dataUpdate[index] = newData;
                                                setPoLines([...dataUpdate]);
                                                resolve();
                                            }, 1000)
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                const dataDelete = [...poLines];
                                                const index = oldData.tableData.id;
                                                dataDelete.splice(index, 1);
                                                setPoLines([...dataDelete]);
                                                resolve();
                                            }, 1000)
                                        }),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container className={classes.dashedLine}>

                    </Grid>

                    <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.itemTitle}>
                                    {t('poFormPage.notes')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-static"
                                    error={notesError}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    rows={4}
                                    variant="outlined"
                                    onChange={handleNotesChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justifyContent="center" direction="row" className={classes.poFormContentItemBottom}>
                        <Grid>
                            <LoadingButton isLoading={isLoading} className={classes.buttonSpacing} classes={{ button: classes.buttonSpacing }} onClick={handleSubmitPOForm}>
                                <Typography variant="subtitle2">
                                {t('common.confirm')}
                                </Typography>
                            </LoadingButton>
                        </Grid>
                        <Grid>
                            <Button variant="contained" className={classes.buttonSpacing} onClick={goBack}>
                                <Typography variant="subtitle2">
                                {t('common.cancel')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justifyContent="center" direction="row" style={{ margin: "auto 5px", padding:"0 0 30px 0"}}>
                        <span style={{ color: "red" }}>
                            {responseMessage && responseMessage.length > 0 && responseMessage.indexOf("The PO Number")>-1 && responseMessage.indexOf("already exists")>-1? t('poFormPage.poNumberExistTips') : ""}
                        </span>
                    </Grid>

                </Grid>

              <Dialog open={noteOverwriteOpen} onClose={handleOverwriteClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="body1">
                        {t('poFormPage.fileOverwrite')}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" gutterBottom>
                        {t('poFormPage.fileOverwriteTips')}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={isLoading} variant="contained" color="primary" onClick={handleOverwriteConfirm}>
                            <Typography variant="body2">
                            {t('common.confirm')}
                            </Typography>
                        </LoadingButton>
                        <Button variant="contained" onClick={handleOverwriteClose}>
                            <Typography variant="body2">
                            {t('common.cancel')}
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>

              <Dialog open={excelNoteOverwriteOpen} onClose={handleExcelOverwriteClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                  <Typography variant="body1">
                    {t('poFormPage.fileOverwrite')}
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Typography variant="body2" gutterBottom>
                    {t('poFormPage.fileOverwriteTips')}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <LoadingButton isLoading={isLoading} variant="contained" color="primary" onClick={handleExcelOverwriteConfirm}>
                    <Typography variant="body2">
                      {t('common.confirm')}
                    </Typography>
                  </LoadingButton>
                  <Button variant="contained" onClick={handleExcelOverwriteClose}>
                    <Typography variant="body2">
                      {t('common.cancel')}
                    </Typography>
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={importExcelWarning} onClose={handleExcelWarningClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                  <Typography variant="body1">
                    {t('poFormPage.warningTitle')}
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Typography variant="body2" gutterBottom>

                    {
                      errorTips && errorTips.toString() === [0, 0, 0].toString() && t('poFormPage.warningTips1')
                    }

                    {
                      errorTips && errorTips.toString() === [0, 0, 1].toString() && t('poFormPage.warningTips2')
                    }

                    {
                      errorTips && errorTips.toString() === [0, 1, 0].toString() && t('poFormPage.warningTips3')
                    }

                    {
                      errorTips && errorTips.toString() === [1, 0, 0].toString() && t('poFormPage.warningTips4')
                    }

                    {
                      errorTips && errorTips.toString() === [0, 1, 1].toString() && t('poFormPage.warningTips5')
                    }

                    {
                      errorTips && errorTips.toString() === [1, 1, 0].toString() && t('poFormPage.warningTips6')
                    }

                    {
                      errorTips && errorTips.toString() === [1, 0, 1].toString() && t('poFormPage.warningTips7')
                    }


                  </Typography>
                </DialogContent>
                <DialogActions>
                  <LoadingButton isLoading={isLoading} variant="contained" color="primary" onClick={handleExcelWarningClose}>
                    <Typography variant="body2">
                      {t('common.confirm')}
                    </Typography>
                  </LoadingButton>
                </DialogActions>
              </Dialog>

              <Dialog open={unsupportedPdfDialogOpen} onClose={handleUnsupportedPdfDialogClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="body1">
                        {t('poFormPage.unSupportPDFTitle')}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" gutterBottom>
                        {t('poFormPage.unSupportPDF')}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleUnsupportedPdfDialogClose}>
                            <Typography variant="body2">
                            {t('common.ok')}
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </CommonLayout>
    )
}
