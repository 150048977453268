import {axiosCore as axios} from './axiosHelper';

const getApplications = function(){
    const url = '/auth/applications';
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getApplicationById = function(id){
    const url = `/auth/applications/${id}`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}


export default {
    getApplications,
    getApplicationById
}