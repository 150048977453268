import React, {useEffect, useState} from 'react';
import {Link, Route, useLocation} from "react-router-dom";
import { Typography } from '@material-ui/core';
import ChangePassword from '../profile/change-password';
import PersonalProfile from '../profile/personal-profile';
import PersonalSignature from '../profile/personal-signature';
import PersonalAvatar from '../profile/personal-avatar';
import useStyle from './setting-page.style';
import { useTranslation } from 'react-i18next';

import {useTheme} from '@material-ui/core/styles';
export default function SettingPage(props){
    const {t, i18n} = useTranslation();
    const manageMenus = [
        {
            path: '/personal-profile',
            label: t('profileMenu.Profile'),
            component: PersonalProfile
        },
        {
            path: '/change-password',
            label: t('profileMenu.accountSecurity'),
            component: ChangePassword
        },
        {
            path: '/personal-signature',
            label: t('profileMenu.Signature'),
            component: PersonalSignature
        },
        {
            path: '/avatar',
            label: t('profileMenu.Avatar'),
            component: PersonalAvatar
        }
    ]
    const theme = useTheme();
    const style = useStyle();
    const basePath = '/setting';
    const location = useLocation();
    const [displayMenus, setDisplayMenus] = useState([]);

    useEffect(()=>{
        let menus = []
        manageMenus.forEach((menu)=> {
            menus.push(menu)
        })
        setDisplayMenus(menus)
    },[])

    const getMenuClass = (menu)=>{
        if(isSelected(menu)){
            return `${style.subMenuItem} ${style.subMenuItemSelected}`;
        }else{
            return `${style.subMenuItem}`;
        }
    }

    const isSelected = (menu)=>{
        // console.error(props.match)
        let path = location.pathname;
        return path && path.indexOf(menu.path) > 0;
    }
    return (
        <div className={style.pageContainer} >
            <div className={style.subMenu} >
                {displayMenus && displayMenus.map((menu, index)=> {
                    return (
                        <Link to={basePath + `${menu.path}`} key={index}>
                            <div className={getMenuClass(menu)} style={{borderBottom: index<displayMenus.length-1? theme.currentTheme.border[3]: "none"}}>
                                {isSelected(menu) && <div className={style.itemSelectedIndicator}></div>}
                                <Typography variant="body2" >
                                    {menu.label}
                                </Typography>
                            </div>
                        </Link>
                    )
                })}
            </div>
            <div className={style.manageContent}>
                {displayMenus.map((config, index)=> {
                    return <Route path={basePath + `${config.path}`} key={index} exact component={config.component}></Route>
                })}
            </div>
        </div>
    )
}
