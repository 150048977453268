import {axiosCore as axios} from './axiosHelper';

const submitSignature = function (email, signature) {
  const url = '/auth/users/' + email;
  return new Promise((resolve, reject) => {
    axios.patch(url, [{op: "replace", path: "signature", value: signature}]).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const updateDisplayName = (email, displayName) => {
  const url = '/auth/users/' + email;
  return new Promise((resolve, reject) => {
    axios.patch(url, [{op: "replace", path: "username", value: displayName}]).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getUserDetailsByEmail = (email) => {
  const url = '/auth/users/detail/' + email;
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const updateLanguage = (email, language) => {
  const url = '/auth/users/' + email;
  return new Promise((resolve, reject) => {
    axios.patch(url, [{op: "replace", path: "language", value: language}]).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const updateAvatar = (email, avatar) => {
  const url = '/auth/users/' + email;
  return new Promise((resolve, reject) => {
    axios.patch(url, [{op: "replace", path: "avatar", value: avatar}]).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

export default {
  submitSignature,
  updateDisplayName,
  getUserDetailsByEmail,
  updateLanguage,
  updateAvatar
}
