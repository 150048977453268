import React,{useContext}  from 'react';
import { Component, useEffect, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';

import {
    Link, useHistory
} from "react-router-dom";
import { HomeOutlined, AssignmentOutlined, AccountBoxOutlined } from '@material-ui/icons';
import TodayIcon from '@material-ui/icons/Today';
import Logout from './Logout';
import { makeStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { ApplicationContext } from "../context/applicationContext";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BookIcon from '@material-ui/icons/Book';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';


const useStyles = makeStyles({
    bar: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: "10px",
        paddingLeft: "10px",
    },
    logout: {
        paddingLeft: "20px",
    }
});

export default function Navigator(props) {
    const history = useHistory();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const [navItems, setNavItems] = React.useState([]);
    const {application,refreshApplication} = useContext(ApplicationContext);
    useEffect(()=>{
        console.log(application);
        if(application && application.name==="Open Comm"){
            setNavItems(navItems1)
        }  
        if(application && application.name==="PO Transactions"){
            setNavItems(navItems2)
        }
        if(application && application.name==="Inventory"){
            setNavItems(navItems3)
        }    
    },[application])
    const navItems1 = [
        { name: 'Home', disabled: false, path: '/Open Comm', icon: () => { return (<Tooltip title="Home"><IconButton><HomeOutlined  fontSize="large" /></IconButton></Tooltip>) } },
        // { name: 'Schedule', disabled: false, path: '/booking-schdule', icon: () => { return (<Tooltip title="Schedule (coming soon)"><IconButton><TodayIcon color="disabled" fontSize="large" /></IconButton></Tooltip>) } },
        { name: 'Tasks', disabled: true, path: '/tasks', icon: () => { return (<Tooltip title="Tasks (coming soon)"><IconButton><AssignmentOutlined color="disabled" fontSize="large" /></IconButton></Tooltip>) } },
    ];

    const navItems2 = [
        { name: 'Home', disabled: false, path: '/PO Transactions', icon: () => { return (<Tooltip title={t('poManagementHomepage.bookMenu')}><IconButton><BookIcon fontSize="large" /></IconButton></Tooltip>) } },
        // { name: 'Schedule', disabled: false, path: '/booking-schdule', icon: () => { return (<Tooltip title="Schedule (coming soon)"><IconButton><TodayIcon color="disabled" fontSize="large" /></IconButton></Tooltip>) } },
        { name: 'Inventory', disabled: false, path: '/PO Transactions/poLineMgt', icon: () => { return (<Tooltip title={t('poManagementHomepage.totalBookMenu')}><IconButton><LibraryBooksIcon fontSize="large" /></IconButton></Tooltip>) } },
        { name: 'Inventory', disabled: false, path: '/PO Transactions/statementMgt', icon: () => { return (<Tooltip title={t('poManagementHomepage.invoiceCheck')}><IconButton><PlaylistAddCheckIcon fontSize="large" /></IconButton></Tooltip>) } },
    ];

    const navItems3 = [
        { name: 'Home', disabled: false, path: '/Inventory', icon: () => { return (<Tooltip title={t('common.home')}><IconButton><HomeOutlined fontSize="large" /></IconButton></Tooltip>) } },
    ];

    const getNavItemState = (nav) => {
        if (!nav.disabled) {
            let path = window.location.pathname
            if (path && path.indexOf(nav.path) >= 0) {
                return 'navItem navItem_selected';
            } else {
                return 'navItem';
            }
        } else {
            return 'navItem navItem_disabled'
        }
    }

    const onLinkClick = (nav) => {
        if (nav.disabled === false) {
            history.push(nav.path)
        }
    }

    const navList = navItems.map(nav =>
        <div className={getNavItemState(nav)} key={nav.path} onClick={() => onLinkClick(nav)} disabled={nav.disabled}>
            <div className="vbox vcenter">
                <nav.icon/>
                {/* <span>{nav.name}</span> */}
            </div>
        </div>
    )
    return (
        <div className={classes.bar}>
            <div className="nav common-box">
                {navList}
            </div>
            {/* <Logout></Logout> */}
        </div>

    );
}