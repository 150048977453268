import React, {useContext, useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {
  Button,
  DialogTitle,
  Grid,
  MenuItem,
  NativeSelect,
  Select,
  Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import OrganizationListsItem from './organizationListsItem';
import InviationListItem from './inviationListItem';
import MyPaymentListItem from './myPaymentList';

import {ApplicationContext} from "../../context/applicationContext";
import userSort from "../../utils/userSort";
import useToken from '../../hooks/useToken';
import {useTranslation} from 'react-i18next';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import organizationService from "../../services/organizationService";

const useStyles = makeStyles(theme => ({
  orgBox: {
    width: "100%",
    maxWidth: "1200px",
  },
  orgBorder: {
    margin: "2vw 4vw",
    borderRadius: "8px",
    '@media (max-width:600px)': {
      margin: '0px',
    },
  },
  orgTitle: {
    padding: "21px 27px",
    borderRadius: "8px 8px 0 0",
    borderBottom: theme.currentTheme.border[3],
  },
  newOrg: {
    '@media (max-width:960px)': {
      display: 'none',
    },
  },
  newOrgMobile: {
    '@media (min-width:960px)': {
      display: 'none',
    },
  },
  orgLists: {
    padding: "14px 24px 22px 24px",
    '@media (max-width:600px)': {
      padding: "14px 0px 0px 0px",
    },
  },
  tabsRoot: {
    textTransform: "none",
  },
  tabsLabel: {
    fontFamily: "IBMPlexSans-Regular",
    fontSize: "16px"
  },
  selectField: {
    padding: "12px 12px",
  },
}))

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function OrganizationPage(props) {
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const {token} = useToken();

  const [orgLimit, setOrgLimit] = useState(true);
  const [companyOrgOpen, setCompanyOrgOpen] = useState(false);

  const {applications, refreshApplications} = useContext(ApplicationContext);

  const history = useHistory();
  useEffect(() => {
    if (history.location.state && history.location.state.refresh) {
      refreshApplications();
    }
  }, [history.location])

  const [orgList, setOrgList] = useState([]);

  const [transactionsOrg, setTransactionsOrg] = useState([]);

  useEffect(() => {
    organizationService.getTransactionsOrg().then(result => {
      if (result.status === 200) {
        console.log('Transactions Org Result: ', result);
        let tmp = [];
        result.data.orgs.map(org => {
          tmp.push({
            id: org.id,
            display_name: org.display_name,
          });
        });

        setTransactionsOrg(tmp);
      }
    });

  }, []);

  const [transactionOrgSelected, setTransactionOrgSelected] = React.useState("");
  useEffect(() => {
    organizationService.getTransactionsOrgByEmail(token.username).then(result => {

      let companyOrgId = '';

      if (result.status === 200 && result.data.orgs && result.data.orgs.length > 0) {
        console.log('User Transactions Org Result: ', result.data.orgs[0]);
        setTransactionOrgSelected(result.data.orgs[0].id);
        companyOrgId = result.data.orgs[0].id;
      }

      if (applications) {
        let arr = [];
        applications.forEach(item => {
          if (item.organizations) {
            item.organizations.forEach(temp => {
              let multiOrg = arr.find(item => {
                return (item.id === temp.id)
              });
              if (multiOrg) {
                multiOrg["app_name"] = multiOrg["app_name"] + "," + item.display_name;
                multiOrg["app_id"] = multiOrg["app_info"].id + "," + item.id;
              } else {
                temp["app_name"] = item.display_name;
                temp["app_id"] = item.id;
                temp["app_info"] = item;
                temp.users = userSort.userSortByRole(temp.users);
                arr.push(temp);
              }
            })
          }
        });

        let tmp = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id === companyOrgId) {
            arr[i].companyOrg = true;
            tmp.push(arr[i]);
            break;
          }
        }
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id !== companyOrgId) {
            arr[i].companyOrg = false;
            tmp.push(arr[i]);
          }
        }

        setOrgList(tmp);

        let temp = 0;
        arr.forEach(item => {
          if (token && item) {
            // console.log(token);
            for (let i = 0; i < item.users.length; i++) {
              const user = item.users[i];
              if (user.email === token.username) {
                if (user.user_role) {
                  for (let i = 0; i < user.user_role.length; i++) {
                    const role = user.user_role[i];
                    if (role === "Org_Admin") {
                      temp = temp + 1;
                    }
                  }
                }
              }
            }
          }
        });
        if (temp > 99) {
          setOrgLimit(false);
        } else {
          setOrgLimit(true);
        }
      }

    });

  }, [applications]);

  const handleToOrgCreate = () => {
    if (history.location.state && history.location.state.fromYourOrg) {
      history.push({
        pathname: '/management/services/:service_name/organizations/create',
        state: {"fromYourOrg": history.location.state.fromYourOrg},
      });
    } else {
      history.push({
        pathname: '/management/services/:service_name/organizations/create',
      });
    }
  }

  const handleCompanyOrg = () => {
    setCompanyOrgOpen(true);
  }

  const handleCompanyOrgClose = () => {
    setCompanyOrgOpen(false);
  }

  const handleCompanyConfirm = () => {
    setCompanyOrgOpen(false);
    organizationService.setTransactionsOrg(transactionOrgSelected).then(_ => {

      let tem = [];
      for (let i = 0; i < orgList.length; i++) {
        if (orgList[i].id === transactionOrgSelected) {
          orgList[i].companyOrg = true;
          tem.push(orgList[i]);
          break;
        }
      }
      for (let i = 0; i < orgList.length; i++) {
        if (orgList[i].id !== transactionOrgSelected) {
          orgList[i].companyOrg = false;
          tem.push(orgList[i]);
        }
      }

      setOrgList(tem);

    });
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      refreshApplications();
    }
  };

  const handleChangeTransactionOrg = (e) => {
    setTransactionOrgSelected(e.target.value);
  }

  return (
    <div className={classes.orgBox}>
      <Grid className={classes.orgBorder}>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.orgTitle}>
          <Grid container className={classes.newOrgMobile}>
            <Button variant="contained" color="primary" disabled={!orgLimit} onClick={handleToOrgCreate}>
              <Typography variant="body2">{t('myOrgtab.newOrg')}</Typography>
            </Button>
          </Grid>

          <Grid item>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                  TabIndicatorProps={{style: {background: "#FF5722"}}}>
              <Tab label={t('myOrgtab.Org')}
                   classes={{root: classes.tabsRoot, wrapper: classes.tabsLabel}} {...a11yProps(0)} />
              <Tab label={t('myOrgtab.orgInvitation')}
                   classes={{root: classes.tabsRoot, wrapper: classes.tabsLabel}} {...a11yProps(1)} />
              <Tab label={t('myOrgtab.orgPayment')}
                   classes={{root: classes.tabsRoot, wrapper: classes.tabsLabel}} {...a11yProps(2)} />
            </Tabs>

          </Grid>
          <Grid item className={classes.newOrg}>
            <Button variant="contained" color="primary" disabled={!orgLimit} onClick={handleToOrgCreate}>
              <Typography variant="body2">{t('myOrgtab.newOrg')}</Typography>
            </Button>
            &nbsp;&nbsp;
            <Button variant="contained" color="primary" disabled={!orgLimit} onClick={handleCompanyOrg}>
              <Typography variant="body2">{t('myOrgtab.companyOrg')}</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid className={classes.orgLists}>
          <TabPanel value={value} index={0}>
            {orgList && orgList.length > 0 ? (
              <Grid>
                {orgList.map((item) => {
                  return (
                    <OrganizationListsItem orgListItem={item} key={item.id}></OrganizationListsItem>
                  )
                })}
              </Grid>
            ) : ("")
            }
          </TabPanel>
          <TabPanel value={value} index={1}>
            <InviationListItem></InviationListItem>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MyPaymentListItem></MyPaymentListItem>
          </TabPanel>
        </Grid>
      </Grid>

      <Dialog open={companyOrgOpen} onClose={handleCompanyOrgClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography variant="body1">
            {t('myOrgtab.companyOrg')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div>{t('newOrg.serviceCategory')}: </div>
            <div style={{marginLeft: '20px'}}>
                <NativeSelect
                  inputProps={{
                    name: 'name',
                    id: 'name-native-disabled',
                  }}
                  disabled
                >
                  <option value="">{t('newOrg.Service1')}</option>
                </NativeSelect>

            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
            <div>{t('myOrgtab.Org')}: </div>
            <div style={{width: '60%', marginLeft: '20px'}}>
              <Select
                fullWidth
                required={true}
                variant="outlined"
                value={transactionOrgSelected}
                classes={{ select: classes.selectField }}
                onChange={handleChangeTransactionOrg}
              >

                {transactionsOrg && transactionsOrg.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.display_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleCompanyConfirm}>
            <Typography variant="body2">
              {t('common.ok')}
            </Typography>
          </Button>
          <Button variant="contained" onClick={handleCompanyOrgClose}>
            <Typography variant="body2">
              {t('common.cancel')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}
