import {axiosAccounting as axios} from './axiosHelper';


const addName = function (orgId, messages) {
  const url = `/auth/${orgId}/counterparty`;
  return new Promise((resolve, reject) => {
    axios.get(url, messages).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const addBook = function (messages) {
  const url = '/auth/books';
  return new Promise((resolve, reject) => {
    axios.post(url, messages).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const editBookByBookId = function (bookId, messages) {
  const url = `/auth/books/${bookId}`;
  return new Promise((resolve, reject) => {
    axios.get(url, messages).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}
const updateBookByBookId = function (bookId, messages) {
  const url = `/auth/books/${bookId}`;
  return new Promise((resolve, reject) => {
    axios.put(url, messages).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}
const addPo = function (bookId, messages) {
  const url = `/auth/${bookId}/pos`;
  return new Promise((resolve, reject) => {
    axios.post(url, messages).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const addPoLine = function (id) {
  const url = `/auth/${id}/lines`;
  return new Promise((resolve, reject) => {
    axios.post(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const cancelPoLine = function (messages) {
  const url = '/auth/cancel/lines';
  return new Promise((resolve, reject) => {
    axios.put(url, messages).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getBooks = function (limit, offset, orgId, status) {
  const url = '/auth/books?limit=' + limit + '&offset=' + offset + '&orgId=' + orgId + '&status=' + status;
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const viewBook = function (bookid) {
  const url = '/auth/books/' + bookid;
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getPOByBookId = function (bookId) {
  const url = '/auth/' + bookId + '/pos';
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getPOLineByPOId = function (poId) {
  const url = '/auth/' + poId + '/lines';
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const revisePOLineByPOId = function (poId, data) {
  const url = '/auth/' + poId + '/lines/revise';
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(result => {
      resolve(result);
    }).catch(err => {
      if (err && err.response && err.response.data) {
        resolve(err.response.data);
      } else {
        reject(err);
      }
    })
  })
}

const addPOLineByPOId = function (poId, data) {
  const url = '/auth/' + poId + '/lines';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const cancelPoLineByPOId = function (poId, data) {
  const url = '/auth/' + poId + '/lines/cancel';
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getInvoiceByPOId = function (poId) {
  const url = '/auth/' + poId + '/invoices';
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const createInvoiceByPOId = function (poId, data) {
  const url = '/auth/' + poId + '/invoices';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const updateInvoiceByPOId = function (invoiceId, data) {
  const url = '/auth/invoices/' + invoiceId;
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const deleteInvoiceByPOId = function (invoiceId) {
  const url = '/auth/invoices/' + invoiceId;
  return new Promise((resolve, reject) => {
    axios.delete(url, {}).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getShippingByPOId = function (poId) {
  const url = '/auth/' + poId + '/shippings';
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const createShippingByPOId = function (poId, data) {
  const url = '/auth/' + poId + '/shippings';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const attach4Shipping = (data) => {

  const config = {
    method: 'post',
    url: 'auth/shipping/attachments',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data
  };

  return new Promise((resolve, reject) => {
    axios(config).then(result => {
      resolve(result.data);
    }).catch(e => {
      reject(e);
    })
  })

}

const getCurrencyLabel = function (currency) {
  switch (currency) {
    case 'CNY':
      return '¥';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'HKD':
      return '$';
    case 'JPY':
      return '¥';
    case 'CAD':
      return '$';
    case 'AUD':
      return '$';
    case 'GPB':
      return '£';
  }
}

const checkPermission = function (permission, currentBook) {
  if (currentBook && currentBook.status === "normal" && (currentBook.permissions.includes(permission))) {
    return true;
  } else {
    return false;
  }
}

const addPaymentByPOId = function (poId, data) {
  const url = '/auth/' + poId + '/payments';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getPaymentsByPOID = function (poId) {
  const url = '/auth/' + poId + '/payments';
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const closeBook = function (bookId) {
  const url = '/auth/books/' + bookId + '/close';
  return new Promise((resolve, reject) => {
    axios.put(url, {}).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const deleteBook = function (bookId) {
  const url = '/auth/books/' + bookId;
  return new Promise((resolve, reject) => {
    axios.delete(url, {}).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getBalanceByPOID = function (poId) {
  const url = '/auth/' + poId + '/balance';
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getSOByPOID = function (poId) {
  const url = '/auth/' + poId + '/so';
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getPOLinesByOrgId = function (orgId, startDate, endDate, customer, limit, offset) {
  let url = '/auth/' + orgId + '/pos/lines?' + 'startDate=' + startDate + '&endDate=' + endDate + '&limit=' + limit + '&offset=' + offset;
  if (customer !== '') {
    url = url + '&customer=' + customer;
  }
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const updateSOByPOId = function (poId, data) {
  const url = '/auth/' + poId + '/so';
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const downloadSOByPOID = function (poId, filetype) {
  const url = '/auth/' + poId + '/so/download?fileType=' + filetype;
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        "content-type": "application/json",
        "accept": "*/*"
      }
    }).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const downloadPOByPOID = function (poId, filetype) {
  const url = '/auth/pos/' + poId + '/download?fileType=' + filetype;
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        "content-type": "application/json",
        "accept": "*/*"
      }
    }).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const closeLine = function (poId, data) {
  const url = '/auth/' + poId + '/lines/close';
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const updateRefNumberByPoId = function (PoId, messages) {
  const url = `/auth/pos/${PoId}`;
  return new Promise((resolve, reject) => {
    axios.put(url, messages).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const updateAlarmByLineId = function (lineId, messages) {
  const url = `/auth/lines/${lineId}/alarm`;
  return new Promise((resolve, reject) => {
    axios.put(url, messages).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const deleteAlarmByLineId = function (lineId) {
  const url = `/auth/lines/${lineId}/alarm`;
  return new Promise((resolve, reject) => {
    axios.delete(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getCustomers = function (orgId) {
  const url = `/auth/${orgId}/pos/customer`;
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const shippingReceived = function (shippingId) {
  const url = `/auth/shippings/${shippingId}/received`;
  return new Promise((resolve, reject) => {
    axios.patch(url, {}).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const addBatchShipping = function (data) {
  const url = '/auth/shippings/batch';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const addBatchPayment = function (data) {
  const url = '/auth/payments/batch-deposit';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const addBatchPInvoice = function (data) {
  const url = '/auth/invoices/batch';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const shippingNotification = function (data) {
  const url = '/auth/shippings/notification';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const addReflines = function (data) {
  const url = '/auth/ref-lines';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getLinesByOrgId = function (orgId) {
  const url = `/auth/orgs/${orgId}/lines`;
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const deleteRefLine = function (refLineId) {
  const url = '/auth/ref-lines/' + refLineId;
  return new Promise((resolve, reject) => {
    axios.delete(url, {}).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const declarationAdd = function (shippingId, data) {
  const url = `/auth/shippings/${shippingId}/trade`;
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getAllStatements = function (orgId, searchInfo) {
  let url = `/auth/orgs/${orgId}/statements?limit=99999&offset=0`;
  if (searchInfo !== '') {
    url = url + `&searchInfo=${searchInfo}`
  }
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const downloadStatementById = function (id, orgId) {
  const url = '/auth/statement/download?statementNumber=' + id + '&orgId=' + orgId;
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        "content-type": "application/json",
        "accept": "*/*"
      }
    }).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const batchUpdateInvoice = function (data) {
  const url = '/auth/batch/invoices';
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const batchPaymentByInvoice = function (data) {
  const url = '/auth/payments/batch-invoice';
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  });
}

const deleteStatementByIds = function (invoiceIds) {
  const url = '/auth/invoices?invoiceIds=' + invoiceIds;
  return new Promise((resolve, reject) => {
    axios.delete(url, {}).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const getStatementNumberByOrgId = function (orgId) {
  const url = `/auth/orgs/${orgId}/statements/statement-number`;
  return new Promise((resolve, reject) => {
    axios.get(url).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  })
}

const reflineDownload = (orgId, lineIds) => {
  const url = `/auth/orgs/` + orgId +`/refline/download?lineIds=`+lineIds;
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        "content-type": "application/json",
        "accept": "*/*"
      }
    }).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  });
}

const downloadAttachment = (fileId) => {
  const url = `/auth/attachments/` + fileId;
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        "content-type": "application/json",
        "accept": "*/*"
      }
    }).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  });
}

const editPONote = (lineId, data) => {
  const url = `/auth/lines/` + lineId + `/notes`;
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  });
}

const userSettings = (data) => {
  const url = `/auth/user/settings`;
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  });
}

const readPOLineNotifications = (data) => {
  const url = `/auth/notify/read`;
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  });
}

const shareBook = (bookId, data) => {
  const url = `/auth/books/${bookId}/share`;
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  });
}

const unShareBook = (shareId) => {
  const url = '/auth/sharebooks/' + shareId;
  return new Promise((resolve, reject) => {
    axios.delete(url, {}).then(result => {
      resolve(result);
    }).catch(e => {
      reject(e);
    })
  });
}

export default {
  addBook,
  addPo,
  addPoLine,
  cancelPoLine,
  getBooks,
  viewBook,
  getPOByBookId,
  getPOLineByPOId,
  getCurrencyLabel,
  revisePOLineByPOId,
  addPOLineByPOId,
  cancelPoLineByPOId,
  addPaymentByPOId,
  getPaymentsByPOID,
  getInvoiceByPOId,
  createInvoiceByPOId,
  getShippingByPOId,
  createShippingByPOId,
  attach4Shipping,
  closeBook,
  getBalanceByPOID,
  checkPermission,
  updateBookByBookId,
  editBookByBookId,
  getSOByPOID,
  updateSOByPOId,
  downloadSOByPOID,
  downloadPOByPOID,
  deleteBook,
  getPOLinesByOrgId,
  closeLine,
  addName,
  updateRefNumberByPoId,
  updateAlarmByLineId,
  deleteAlarmByLineId,
  getCustomers,
  shippingReceived,
  addBatchShipping,
  addBatchPayment,
  addBatchPInvoice,
  shippingNotification,
  addReflines,
  getLinesByOrgId,
  deleteRefLine,
  declarationAdd,
  updateInvoiceByPOId,
  deleteInvoiceByPOId,
  getAllStatements,
  downloadStatementById,
  batchUpdateInvoice,
  batchPaymentByInvoice,
  deleteStatementByIds,
  getStatementNumberByOrgId,
  reflineDownload,
  editPONote,
  userSettings,
  readPOLineNotifications,
  downloadAttachment,
  shareBook,
  unShareBook,
}
