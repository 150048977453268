import {axiosBooking as axios} from './axiosHelper';
import {axiosCore as axiosCore} from './axiosHelper';

const loginURL = function () {
  let iamUrl = process.env.REACT_APP_IAM_URL;
  if(process.env.REACT_APP_IAM_CLIENT_ID && process.env.REACT_APP_IAM_REDIRECT_URI && process.env.REACT_APP_IAM_RESPONSE_TYPE){
    iamUrl += '?client_id=';
    iamUrl += process.env.REACT_APP_IAM_CLIENT_ID;
    iamUrl += '&redirect_uri=';
    iamUrl += process.env.REACT_APP_IAM_REDIRECT_URI;
    iamUrl += '&response_type=';
    iamUrl += process.env.REACT_APP_IAM_RESPONSE_TYPE;
  }
  console.info('redirect to iam service url=' + iamUrl);
  //window.location.href = iamUrl;
  return iamUrl;
}

const logout = function () {
  return new Promise(function (resolve, reject) {
    axios({
      url: '/auth/logout',
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*',
      }
    }).then(function (result) {
      resolve()
    }).catch(error => {
      console.warn('logout error: ', error)
      resolve()
    })
  })
}

const checkToken = function (access_token) {
  return new Promise((resolve, reject) => {
    axios.request({
      url: '/auth/token',
      method: 'get',
      headers: {
        'Authorization': 'Opendais ' + access_token,
        'content-type': 'application/json',
        'accept': '*/*'
      },
      params: {
        'access_token': access_token
      }
    }).then(function (result) {
      if (result && (result.status === 200 || result.status === 201)) {
        resolve(result)
      } else {
        throw result;
      }
    }).catch(function (e) {
      reject(e);
    });
  })
}

const register = function (params, appParams) {
  let url = '/public/register/';
  if (appParams) {
    url += '?app_params=' + appParams
  }
  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*'
      },
      data: params
    }).then(function (result) {
      console.log('register: ', result)
      if (result && result.data) {
        resolve(result.data)
      } else {
        throw result;
      }
    }).catch(function (e) {
      console.error(e)
      reject(e);
    });
  })
}

const changePassword = (username, access_token, original_password, password) => {
  const url = process.env.REACT_APP_IAM_BASE_URL + '/v1/users/' + username + '/password';

  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'patch',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*',
        'x-access-token': 'Bearer ' + access_token
      },
      data: JSON.stringify({
        original_password: original_password,
        password: password
      })
    }).then(result => {
      console.log('---> Change password: ', result)
      resolve(result.data);
    }).catch(e => {
      console.error('---> Change password error: ', e)
      reject(e);
    });
  });

}

export default {
  loginURL,
  logout,
  checkToken,
  register,
  changePassword
}
