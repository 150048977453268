import React,{useContext}  from 'react';
import { useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button, Select, MenuItem, FormControl, NativeSelect} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import {useEffect, useState} from 'react';
import useToken from '../hooks/useToken';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ApplicationContext } from "../context/applicationContext";
import OrgRoleReader from "../utils/orgRoleReader";
import orgRoleReader from "../utils/orgRoleReader";
import userService from "../services/userService";
import organizationService from "../services/organizationService";

const useStyles = makeStyles((theme) => ({
    outlinedPadding: {
        padding: '11px 14px',
    },
    formControl: {
        width: '100%'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    popover: {
        pointerEvents: 'none',
    },
    newOrg: {
        width: 'max-content',
        '@media (max-width:600px)':  {
            width: '100%',
        },
    }
}));


export default function OrganizationSelect(props) {
    const history = useHistory();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const {application,refreshApplication} = useContext(ApplicationContext);
    const [canAddNewOrg, setCanAddNewOrg] = useState(true);
    const { token } = useToken();
    const [createOrgURL,setCreateOrgURL] = useState('');

    useEffect(() => {
        userService.getUserDetailsByEmail(token.username).then((result) => {
            if (result && result.status === 200) {
                console.log(result)
                if (result.data.orgNumber > 99) {
                    setCanAddNewOrg(true);
                } else {
                    setCanAddNewOrg(false);
                }
            }
            // refreshApplication();
        });
    }, [])

    useEffect(() => {
        if (application) {
            const url = `/management/services/${application.name}/organizations/create`;
            setCreateOrgURL(url);
            console.log(token)
        }
        if (application && application.organizations && application.organizations.length > 0) {
            // setCurrentOrg(application.organizations[0].id);
            // console.log(openCreateOrg);
            // console.log(application.organizations[0].id)
            // console.log(currentOrg);
            props.onSetOpenCreateOrg(false);
            props.onSetIniting(false);
            if (window.sessionStorage.getItem("currentOrg") && window.sessionStorage.getItem("currentOrg") !== "") {
                let currentOrg = window.sessionStorage.getItem("currentOrg");
                props.onSetCurrentOrg('');
                application.organizations.map(item => {
                    if (item.id === currentOrg) {
                        props.onSetCurrentOrg(currentOrg);
                    }
                });
            }
        } else {
            props.onSetIniting(false);
            props.onSetOpenCreateOrg(true);
        }
    }, [application]);

    const goToNewOrgPage =()=>{
        history.push(createOrgURL)
    }

    const handleClose = () => {
        props.onSetOrgExpired(false);
        props.onSetCurrentOrg()
    };

    const handleOK = () =>{
        props.onSetOrgExpired(false);
        props.onSetCurrentOrg()
    }

    const handleExtendOrg = () =>{
        organizationService.extendOrgByOrgId(props.currentOrg).then((result) => {
            if(result&&result.status===200){
                props.onSetOrgExpired(false);
                refreshApplication();
            }
        });
    }

    const tranlateService = (value) => {
        if(value==='Trial subscription'){
            return "";
        }
        if(value==='Monthly subscription'){
            return " (" + t('newOrg.servicePackvalueMonth') + ") ";
        }
        if(value==='max 10 meeting attendees'){
            return " (" +t('newOrg.servicePackComm1') + ") ";
        }
        if(value==='10+ meeting attendees'){
            return " (" +t('newOrg.servicePackComm2') + ") ";
        }
        return " (" +value  + ") ";
    }

    const companyOrg = (orgList) => {

        let currentOrg = window.sessionStorage.getItem('currentOrg');

        if (currentOrg) {
            return currentOrg;
        }

        if(orgList && orgList.length > 0) {
            for (let i = 0; i < orgList.length; i++) {
                let org = orgList[i];
                if (org['is_company'] && org['is_company'] === true) {
                    props.onSetCurrentOrg(org['id']);
                    window.sessionStorage.setItem("currentOrg", org['id']);
                    return org['id'];
                }
            }
        }
    }

    return (
        <Grid container spacing={0} justifyContent="flex-start" direction="row" alignItems="center" style={{paddingBottom:'8px',marginBottom:'16px'}}>
            <Grid xs={12} item>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="body1">{t('select.selectOrg')}</Typography>
                    </Grid>
                    <Grid item style={{marginTop: '8px', width: '100%'}}>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={8} md={9}>
                                <ApplicationContext.Consumer>
                                { value => (
                                    value.application && <FormControl variant="filled" fullWidth className={classes.formControl}>
                                        <Select
                                            value={companyOrg(value.application.organizations)}
                                            onChange={(e)=> {
                                                props.onSetCurrentOrg(e.target.value);
                                                window.sessionStorage.setItem("currentOrg", e.target.value);
                                            }}
                                            disabled={props.initing || props.openCreateOrg}
                                            variant="outlined"
                                            color="primary"
                                            classes={{ select: classes.outlinedPadding }}>
                                            {
                                              value.application.organizations && value.application.organizations.length > 0 && value.application.organizations.map(org =>
                                                <MenuItem key={org.id}
                                                          value={org.id}>{org.name + tranlateService(org.service_package) + " - "}{OrgRoleReader.IsOrgAdmin(org.id, application) ? t('myOrg.roleValueadmin') : ""}{OrgRoleReader.IsOrgManager(org.id, application) ? t('myOrg.roleValuemanager') : ""} {OrgRoleReader.IsOrgMember(org.id, application) ? t('myOrg.roleValuemember') : ""}</MenuItem>
                                              )
                                            }
                                        </Select>
                                    </FormControl>
                                )}
                                </ApplicationContext.Consumer>
                            </Grid>
                            {!props.initing && <Grid item xs={12} sm={4} md={3} style={{textAlign:"right"}}>
                                <Button variant="contained" color="primary" size="large" disabled={canAddNewOrg} onClick={goToNewOrgPage} className={classes.newOrg}>
                                    <span style={{marginLeft:'4px'}}>{t('homePage.newOrg')}</span>
                                </Button>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                (!props.initing && !props.openCreateOrg && props.orgExpired) &&
                <Grid>
                    <Dialog open={props.orgExpired} onClose={handleClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">{t('newOrg.orgExpired')}</DialogTitle>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleOK}>
                                <CloseIcon />
                            </IconButton>
                            <Divider variant="middle"></Divider>
                            <DialogContent style={{minHeight:'100px'}}>
                            {(orgRoleReader.IsOrgAdmin(props.currentOrg,application))&&<span>{application.name==='Open Comm'?t('newOrg.orgExpired1'):t('newOrg.contactTips')}</span>}
                            {(!orgRoleReader.IsOrgAdmin(props.currentOrg,application))&&<span>{t('newOrg.orgExpired1')}</span>}
                            </DialogContent>
                            <DialogActions>
                            {(orgRoleReader.IsOrgAdmin(props.currentOrg,application))&&application.name==='Open Comm'&&<div><Button variant="contained" style={{marginRight:"8px"}} onClick={handleOK}>{t('common.cancel')}</Button><Button variant="contained" color="primary" onClick={handleExtendOrg}>{t('common.confirm')}</Button></div>}
                            {(orgRoleReader.IsOrgAdmin(props.currentOrg,application))&&application.name!=='Open Comm'&&<Button variant="contained" color="primary" onClick={handleOK}>{t('common.ok')}</Button>}
                            {(!orgRoleReader.IsOrgAdmin(props.currentOrg,application))&&<Button variant="contained" color="primary" onClick={handleOK}>{t('common.ok')}</Button>}</DialogActions>
                    </Dialog>

                </Grid>
            }
        </Grid>
    );
}
