import React,{useContext}  from 'react';
import { Component, useEffect, useState, useRef} from 'react';
import { Link, useParams, useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { Grid, Button, Select, MenuItem, FormControl, InputLabel, TextField, Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import useToken from '../../hooks/useToken';
import { ApplicationContext } from "../../context/applicationContext";
import userService from "../../services/userService";
import UndoIcon from '@material-ui/icons/Undo';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import MaterialTable from 'material-table'
import moment from 'moment';
import { forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import LoadingButton from "../../components/LoadingButton";
import Autocomplete from '@material-ui/lab/Autocomplete';
import inventoryService from "../../services/inventoryService";

import AddBox from '@material-ui/icons/AddBox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
        display: 'flex',
        flexDirection: "row",
        maxWidth: '1200px',
        width: '100%',
    },
    box: {
        paddingLeft:'30px',
        paddingTop:'40px',
        width:'100%'
    },
    undoIcon: {
        marginRight: "23px",
        color:"#3f51b5",
        cursor:'pointer'
    },
    managementTitle: {
        padding: "21px 0 21px 27px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[3],
    },
    managementContent:{
        padding: "21px 0 21px 27px",
    },
    editTitle:{
        // marginBottom:'4px',
        // marginTop:'4px',
        fontSize:'14px'
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    dialogHr: {
        border: '1px',
        borderStyle: 'dashed none none none',
        borderColor: theme.currentTheme.colors[6],
        marginTop: '10px',
        marginBottom: '10px',
        width:'100%',
    },
    dialogRoot:{
        marginBottom:'20px',
        color:theme.currentTheme.text,
        fontSize:'14px'
    },
    selectField: {
        padding: "11px 14px",
    },

    required:{
        color:'red'
    }
}));


export default function GoodsManagement() {
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const { token } = useToken();
    const theme = useTheme();


    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>
        ),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        Cancel: forwardRef((props, ref) => <CancelOutlinedIcon {...props} ref={ref} />)
    };


    const history = useHistory();
    const [searchKeyWordsInput, setSearchKeyWordsInput] = useState("");
    const [searchKeyWords, setSearchKeyWords] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = React.useState(false);    
    const [companyId, setCompanyId] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyNameError, setCompanyNameError] = useState(false);

    const [companyContact, setCompanyContact] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyNotes, setCompanyNotes] = useState("");
    const [currentOrg, setCurrentOrg] = useState("");
    const [currentCompanys, setCurrentCompanys] = useState([]);

    useEffect(() => {
        refreshCompanyList();
    },[])

    useEffect(() => {
        refreshCompanyList();
    },[currentOrg,searchKeyWords])

    const goBackHandler = ()=>{
        history.push("/Inventory")
    }

    const handleSearch = () => {
        if(searchKeyWordsInput.length>2||searchKeyWordsInput.length===0){
            setSearchKeyWords(searchKeyWordsInput);
        }
    }

    const hanlerEnterSearch =(event)=>{
        console.log(event);
        if (event.key == "Enter"){
            handleSearch();
        }

    }

    const formatDate=(value)=> {
        return moment(value).format("YYYY/MM/DD HH:MM:SS");
    }

    const addToolTip=(value)=> {
        return (<div title={value} className={classes.toolTipDiv}>{value}</div>);
    }

    const openDialogHandler =()=>{
        setCompanyId("");
        setCompanyName("");
        setCompanyNameError(false);
        setCompanyAddress("");
        setCompanyContact("");
        setCompanyPhone("");
        setCompanyNotes("");
        setOpenDialog(true);
    }

    const handleClose =()=>{
        setOpenDialog(false);
    }

    const refreshCompanyList = () =>{
        inventoryService.searchCompany(currentOrg,"supplier",searchKeyWords).then((result)=>{
            if(result&&result.status===200){
                setCurrentCompanys(result.data.datas);
            }
        });
    }

    const handleSubmit =()=>{
        setLoading(true);
        let isValid = true;
        if(companyName===""){
            isValid = false;
            setCompanyNameError(true);
        }
        if(!isValid){
            setLoading(false);
            return;
        }

        let submitValue={
            orgId: currentOrg,
            id: companyId,
            name: companyName,
            companyType: "supplier",
            contact: companyContact,
            phone: companyPhone,
            address: companyAddress,
            notes: companyNotes,
            createBy: token.username
        };

        inventoryService.addCompany(submitValue).then((result)=>{
            if(result&&result.status===200){
                setLoading(false);
                refreshCompanyList();
                handleClose();
            }
            else{
                setLoading(false);
            }
        })
    }

    return (
        <div className={classes.root}> 
            <div className={classes.box}>
                <Grid container justifyContent="flex-start" alignItems="center" className={classes.managementTitle}>
                    <div onClick={goBackHandler}>
                        <UndoIcon color="primary" className={classes.undoIcon} ></UndoIcon>
                    </div>
                    <Typography variant="h3" gutterBottom style={{"fontWeight":"bold"}}>
                        {t('supplierManagement.title')}
                    </Typography>
                </Grid>
                <Grid container justifyContent="flex-start" className={classes.managementContent}>
                    <Grid md={6}>
                        <Grid container justifyContent="flex-start">
                            <TextField id="outlined-basic" variant="outlined" size="small" placeholder={t("supplierManagement.searchHint")} style={{width:'300px',verticalAlign:'middle'}} onChange={e => setSearchKeyWordsInput(e.target.value)} error={(searchKeyWordsInput.length<3&&searchKeyWordsInput.length>0)} onKeyPress={hanlerEnterSearch}/>
                            <IconButton onClick={handleSearch} aria-label="search"><SearchIcon></SearchIcon></IconButton>
                        </Grid>
                    </Grid>
                    <Grid md={6}>
                        <Grid container justifyContent="flex-end">
                            <IconButton onClick={openDialogHandler}>
                                <AddCircleOutlineOutlinedIcon size="large"></AddCircleOutlineOutlinedIcon>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid md={12} style={{marginTop:'30px'}}>
                    <MaterialTable
                            classes={{MuiPaper:classes.noBox}}
                            title=""
                            columns={[
                                { field: 'id', 
                                    title: t('supplierManagement.id'),
                                    sorting: false,
                                    width: '8%',
                                    render: rowData => addToolTip(rowData.id),
                                    cellStyle:{
                                        maxWidth:'120px',
                                        padding:'4px',
                                        fontSize:'14px',
                                        overflow:'hidden',
                                        textOverflow:'ellipsis',
                                        whiteSpace:'nowrap',
                                    },
                                },
                                {
                                    field: 'name',
                                    title: t('supplierManagement.name'),
                                    validate: rowData => rowData.name !== '' && rowData.name !== undefined,
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'contact',
                                    title: t('supplierManagement.contact'),
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'phone',
                                    title: t('supplierManagement.phone'),
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'address',
                                    title: t('supplierManagement.address'),
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'notes',
                                    title: t('supplierManagement.notes'),
                                    width: '10%',
                                    sorting: false,

                                    cellStyle:{
                                    maxWidth:'120px',
                                    padding:'4px',
                                    fontSize:'14px',
                                    overflow:'hidden',
                                    textOverflow:'ellipsis',
                                    whiteSpace:'nowrap',
                                    },
                                    render: rowData => addToolTip(rowData.notes),
                                },
                                ]}
                            icons={tableIcons}
                            data={currentCompanys}
                            style={{width:'100%'}} 
                            options={{      
                                actionsColumnIndex: -1,                                      
                                search: false,
                                toolbar:false,
                                sorting: true,
                                paging:true,
                                pageSize:10,
                                // tableLayout:"fixed",
                                headerStyle: {
                                    backgroundColor:"transparent",
                                    fontFamily:'IBMPlexSans-Bold',
                                    padding:'4px'
                                },
                                cellStyle:{
                                    padding:'4px',
                                    fontSize:'14px',
                                    overflow:'hidden',
                                    textOverflow:'ellipsis',
                                    whiteSpace:'nowrap',
                                    },
                                rowStyle: (rowData, index ) => ({
                                    backgroundColor:
                                    index % 2 === 0 ?theme.currentTheme.theme2backgrounds[1]:theme.currentTheme.theme2backgrounds[2]                                                ,
                                    }),
                            }}
                            editable={{
                                // isEditable: rowData => accountingService.checkPermission("revise_po_line", props.book) && rowData.isUsed === false,
                                // isDeletable: rowData => accountingService.checkPermission("cancel_po_line", props.book) && rowData.isUsed === false,
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        inventoryService.updateCompany(newData.companyId,newData).then(result=>{
                                            if(result&&result.status===200){
                                                refreshCompanyList();
                                                resolve();
                                            }
                                        })
                                    }),
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        inventoryService.deleteCompanyById(oldData.companyId).then(result=>{
                                            if(result&&result.status===200){
                                                refreshCompanyList();
                                                resolve();
                                            }
                                        })
                                    }),
                            }}
                            localization={{
                                header: {
                                    actions: t("common.actions")
                                },
                                body:{
                                    deleteTooltip:t("common.delete"),
                                    editTooltip:t("common.edit"),
                                    editRow:{
                                        deleteText:t("supplierManagement.deleteConfirm"),
                                        cancelTooltip:t("common.cancel"),
                                        saveTooltip:t("common.save"),
                                    },
                                    emptyDataSourceMessage:t("common.noRecord"),
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Dialog open={openDialog} scroll="body" maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><Typography variant="body1">{t('supplierManagement.addFormTitle')}</Typography></DialogTitle>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <Divider variant="middle"></Divider>
                    <DialogContent classes={{ root: classes.dialogRoot, }}>
                            <Grid container spacing={1} >
                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('supplierManagement.id')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="goodsId"
                                            // error={titleError}
                                            variant="outlined"
                                            value={companyId}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setCompanyId(e.target.value)}
                                            placeholder={t('supplierManagement.id')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('supplierManagement.name')} <span className={classes.required}>*</span>
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="name"
                                            error={companyNameError}
                                            variant="outlined"
                                            value={companyName}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>{
                                                setCompanyName(e.target.value);
                                                if(e.target.value===""){
                                                    setCompanyNameError(true);
                                                }
                                                else{
                                                    setCompanyNameError(false);
                                                }
                                            }}
                                            placeholder={t('supplierManagement.name')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('supplierManagement.contact')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="contact"
                                            // error={titleError}
                                            variant="outlined"
                                            value={companyContact}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setCompanyContact(e.target.value)}
                                            placeholder={t('supplierManagement.contact')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid className={classes.editTitle}>
                                        {t('supplierManagement.phone')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="phone"
                                            // error={titleError}
                                            variant="outlined"
                                            value={companyPhone}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setCompanyPhone(e.target.value)}
                                            placeholder={t('supplierManagement.phone')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={10}>
                                    <Grid className={classes.editTitle}>
                                        {t('supplierManagement.address')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="address"
                                            // error={titleError}
                                            variant="outlined"
                                            value={companyAddress}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setCompanyAddress(e.target.value)}
                                            placeholder={t('supplierManagement.address')}
                                        />
                                    </Grid>
                                </Grid>
                            
                                <Grid item xs={12}>
                                    <Grid className={classes.editTitle}>
                                        {t('supplierManagement.notes')}
                                    </Grid>
                                    <Grid item>
                                        <TextField fullWidth
                                            id="notes"
                                            // error={titleError}
                                            multiline={true}
                                            variant="outlined"
                                            minRows={5}
                                            value={companyNotes}
                                            size="small"
                                            margin="dense"
                                            required
                                            onChange={e=>setCompanyNotes(e.target.value)}
                                            placeholder={t('supplierManagement.notes')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton isLoading={loading} onClick={handleSubmit} variant="contained" color="primary">
                            {t('common.confirm')}
                        </LoadingButton>
                        <Button variant="contained" onClick={handleClose}>                            
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>

                
            </div>
            
        </div>
    )
}