import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import iamService from '../../services/iamService';
import useToken from "../../hooks/useToken";
import LoadingButton from "../../components/LoadingButton";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.currentTheme.backgrounds[10],
    border: theme.currentTheme.border[3],
    borderRadius: '8px'
  },
  box: {
    padding: '30px 30px 10px 30px',
    color: theme.currentTheme.colors[0],
  },
  box1: {
    padding: '30px',
  },
  text: {
    color: theme.currentTheme.colors[0],
  },
  text2: {
    color: theme.currentTheme.colors[5],
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  buttonDiv: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '40px',
  },
  buttonProgress: {
    color: '#0e78f9',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorMsg: {
    marginTop: '20px',
    color: '#ff4d4f',
  },
  msg: {
    marginTop: '20px',
    color: '#0e78f9',
  },
  iconButton: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  input: {
    border: theme.currentTheme.border[3],
    backgroundColor: '#fafbfc',
    color: 'black',
    borderRadius: '4px',
  },
  inputErrMsg: {
    color: '#ff4d4f'
  },
  hrColor:{
    border: theme.currentTheme.border[3],
  }
}));

export default function ChangePassword() {

  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const [values, setValues] = React.useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false
  });
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const {token} = useToken();

  const validate = (fieldValues = values) => {
    let temp = {...errors};
    if ('oldPassword' in fieldValues) {
      temp.oldPassword = fieldValues.oldPassword ? '' : t('accountSecurity.errorMsg1');
    }
    if ('newPassword' in fieldValues) {
      temp.newPassword = fieldValues.newPassword ? '' : t('accountSecurity.errorMsg1');
      if (fieldValues.newPassword) {
        const strongRegex = new RegExp('(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}');
        if (strongRegex.test(fieldValues.newPassword)) {
          temp.newPassword = '';
        } else {
          temp.newPassword = t('accountSecurity.errorMsg2');
        }
      }
    }

    if ('confirmPassword' in fieldValues) {
      temp.confirmPassword = fieldValues.confirmPassword ? '' : t('accountSecurity.errorMsg1');
      if (fieldValues.confirmPassword) {
        if (values.newPassword !== fieldValues.confirmPassword) {
          temp.confirmPassword = t('accountSecurity.errorMsg3');
        } else {
          temp.confirmPassword = '';
        }
      }
    }

    setErrors({
      ...temp
    });
  }

  const handleChange = (prop) => (event) => {
    setValues({...values, [prop]: event.target.value});
    validate({[prop]: event.target.value});
  };

  const handleClickShowOldPassword = () => {
    setValues({...values, showOldPassword: !values.showOldPassword});
  };

  const handleClickShowNewPassword = () => {
    setValues({...values, showNewPassword: !values.showNewPassword});
  };

  const handleClickShowConfirmPassword = () => {
    setValues({...values, showConfirmPassword: !values.showConfirmPassword});
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
      setMessage('');
      let temp = {...errors};
      temp.changePasswordError = ''

      if (!values.oldPassword) {
        temp.oldPassword = t('accountSecurity.errorMsg1');
      }
      if (!values.newPassword) {
        temp.newPassword = t('accountSecurity.errorMsg1');
      } else {
        const strongRegex = new RegExp('(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}');
        if (!strongRegex.test(values.newPassword)) {
          temp.newPassword = t('accountSecurity.errorMsg2');
        }
      }

      if (!values.confirmPassword) {
        temp.confirmPassword = t('accountSecurity.errorMsg1');
      } else {
        if (values.newPassword !== values.confirmPassword) {
          temp.confirmPassword = t('accountSecurity.errorMsg3');
        }
      }

      setErrors({
        ...temp
      });

      if (temp
        && (temp.oldPassword || temp.newPassword || temp.confirmPassword || temp.changePasswordError)) {
        setLoading(false);
        return;
      }

      if (token && token.access_token && token.username) {

        iamService.changePassword(token.username, token.access_token,
          values.oldPassword, values.newPassword).then((res) => {
          console.log('---> Change password res: ', res);
          if (res.code === 1000) {
            setMessage('Your password has been changed successfully!');
          }
          setLoading(false);
        }).catch((err) => {
          if (err) {
            console.error('---> Change password error: ', err);
          }
          setLoading(false);
          let temp = {...errors};
          if (err.response && err.response.data) {
            const err_data = err.response.data;
            if (err_data.code === 2700) {
              temp.changePasswordError = t('accountSecurity.errorMsg4');
            } else if (err_data.code === 2701) {
              temp.changePasswordError = t('accountSecurity.errorMsg4') + t('accountSecurity.errorMsg5');
            } else if (err_data.code === 2702) {
              temp.changePasswordError = t('accountSecurity.errorMsg4') + t('accountSecurity.errorMsg6');
            } else if (err_data.code === 2703) {
              temp.changePasswordError = t('accountSecurity.errorMsg4');
            } else if (err_data.code === 2704) {
              temp.changePasswordError = t('accountSecurity.errorMsg4') + t('accountSecurity.errorMsg7');
            } else if (err_data.code === 2705) {
              temp.changePasswordError = t('accountSecurity.errorMsg4');
            }
          } else {
            temp.changePasswordError = t('accountSecurity.errorMsg4');
          }
          setErrors({
            ...temp
          });
        });
      }
    }
  };


  return (
    <div className={classes.root}>
      <Typography variant='body2' gutterBottom className={classes.box}>
        {t('accountSecurity.changePsd')}
      </Typography>
      <hr className={classes.hrColor}/>
      <form className={classes.box1}>
        <div>
          <Typography variant='body2' gutterBottom className={classes.text}>
          {t('accountSecurity.oldPsd')} *
          </Typography>
          <FormControl fullWidth variant='outlined' size='small' autoComplete='none'>
            <OutlinedInput
              id='outlined-old-adornment-password'
              // className={classes.input}
              type={values.showOldPassword ? 'text' : 'password'}
              value={values.oldPassword}
              onBlur={handleChange('oldPassword')}
              onChange={handleChange('oldPassword')}
              {...(errors['oldPassword'] && {error: true})}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    className={classes.iconButton}
                    aria-label='toggle password visibility'
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'>
                    {values.showOldPassword ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors['oldPassword'] && (<FormHelperText><Typography className={classes.inputErrMsg} variant='caption'
                                                                   gutterBottom>{errors['oldPassword']}</Typography></FormHelperText>)}
          </FormControl>
        </div>
        <div style={{marginTop: '20px'}}>
          <Typography variant='body2' gutterBottom className={classes.text}>
          {t('accountSecurity.newPsd')} *
          </Typography>
          <FormControl variant='outlined' size='small' fullWidth>
            <OutlinedInput
              id='outlined-new-adornment-password'
              // className={classes.input}
              type={values.showNewPassword ? 'text' : 'password'}
              value={values.newPassword}
              onBlur={handleChange('newPassword')}
              onChange={handleChange('newPassword')}
              {...(errors['newPassword'] && {error: true})}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    className={classes.iconButton}
                    aria-label='toggle password visibility'
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'>
                    {values.showNewPassword ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors['newPassword'] && (<FormHelperText><Typography className={classes.inputErrMsg} variant='caption'
                                                                   gutterBottom>{errors['newPassword']}</Typography></FormHelperText>)}
          </FormControl>
        </div>
        <Typography variant='body2' gutterBottom className={classes.text2} style={{marginTop: '20px'}}>
        {t('accountSecurity.infoMsg1')}
        </Typography>
        <ul className={classes.text2}>
          <li><Typography variant='caption' gutterBottom>{t('accountSecurity.infoMsg2')}</Typography></li>
          <li><Typography variant='caption' gutterBottom>{t('accountSecurity.infoMsg3')}</Typography></li>
          <li><Typography variant='caption' gutterBottom>{t('accountSecurity.infoMsg4')}</Typography></li>
        </ul>
        <div style={{marginTop: '20px'}}>
          <Typography variant='body2' gutterBottom className={classes.text}>
          {t('accountSecurity.confirmPsd')} *
          </Typography>
          <FormControl variant='outlined' size='small' fullWidth>
            <OutlinedInput
              id='outlined-confirm-adornment-password'
              // className={classes.input}
              type={values.showConfirmPassword ? 'text' : 'password'}
              value={values.confirmPassword}
              onBlur={handleChange('confirmPassword')}
              onChange={handleChange('confirmPassword')}
              {...(errors['confirmPassword'] && {error: true})}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    className={classes.iconButton}
                    aria-label='toggle password visibility'
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'>
                    {values.showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors['confirmPassword'] && (<FormHelperText><Typography className={classes.inputErrMsg} variant='caption'
                                                                       gutterBottom>{errors['confirmPassword']}</Typography></FormHelperText>)}
          </FormControl>
        </div>

        {errors['changePasswordError'] && (<Typography className={classes.errorMsg} variant='body2' gutterBottom>{errors['changePasswordError']}</Typography>)}
        {message && (<Typography className={classes.msg} variant='body2' gutterBottom>{message}</Typography>)}

        <div className={classes.buttonDiv}>
          <LoadingButton
            variant='contained'
            isLoading={loading}
            onClick={handleButtonClick}>
            {t('accountSecurity.updatePsdButton')}
          </LoadingButton>
        </div>

      </form>
    </div>
  )

}
