import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  OutlinedInput,
  IconButton,
  Box,
  Grid,
  Button,
  Select,
  InputLabel,
  TextField,
  MenuItem,
  Chip,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useState, setUseState } from 'react';
import ReplyIcon from '@material-ui/icons/Reply';
import LoadingButton from "../../components/LoadingButton";
// import addWarehouseConstants from "./add-warehouse-constant";
import inventoryService from "../../services/inventoryService"
import organizationService from "../../services/organizationService";
import {
  useHistory
} from "react-router-dom";
import EditWarehouse from "./edit-warehouse"
import MeeingEdit from '../../components/booking/MeetingEdit';
import Manager from 'socket.io-client/lib/manager';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "1000px",
    // backgroundColor: theme.currentTheme.backgrounds[7],
    // border: theme.currentTheme.border[2],
    // borderRadius: '8px'
    marginTop: "2%",
  },
  box: {
    padding: '30px 30px 10px 30px',
    // color: theme.currentTheme.colors[0],
  },
  box1: {
    padding: '10px 30px 30px 30px',
  },
  box2: {
    width: "90%",
    marginLeft: "1%",
    marginTop: "2%",
    display: "inline-flex",
    flexFlow: "column",
  },
  text: {
    color: theme.currentTheme.colors[0],
  },
  text2: {
    // color: theme.currentTheme.colors[5],
  },
  width1: {
    width: '80%',
  },
  width2: {
    width: '90%',
  },
  width3: {
    width: '93.3%',
  },
  buttonDiv: {
    display: 'flex',


    // flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px',
  },
  field_name_red: {
    color: "#C00000",
  },
  input: {
    height: "32px !important",
    fontFamily: "Lato",
    fontSize: "15px",
    marginTop: "1%",
    fontFamily: "Lato",
  },
  box_left: {
    width: "30%",
    marginTop: "2%",
    marginLeft: "0%",
    display: "inline-flex",
    flexFlow: "column",
  },
  box_right: {
    width: "30%",
    marginTop: "2%",
    marginLeft: "22%",
    display: "inline-flex",
    flexFlow: "column"
  },
  box_add: {
    marginTop: "20px",
    display: "inline-flex",
    marginBottom: "20px",
  },
  buttonProgress: {
    color: '#0e78f9',
    position: 'absolute',
    top: '50%',
    left: '0',
    marginTop: -12,
    marginLeft: 5,
  },
  errorMsg: {
    color: '#ff4d4f',
    marginTop: '20px',
  },
  msg: {
    color: '#0e78f9',
    marginTop: '20px',
  },
  disable_box: {
    display: "none",
    marginLeft: "1%",
    marginTop: "2%",
  },
  input: {
    // border: theme.currentTheme.border[1],
    // backgroundColor: '#fafbfc',
    // color: 'black',
    borderRadius: '4px',

  },
  select: {
    height: "32px !important",
    width: "500px !important",
    fontFamily: "Lato",
    fontSize: "15px",
    marginTop: "1%",
  },
  inputErrMsg: {
    color: '#ff4d4f'
  },
  hrColor: {
    border: theme.currentTheme.border[2],
  },
  section1: {
    margin: theme.spacing(0, 1),
  },
  section2: {
    margin: theme.spacing(1),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  errorShow: {
    display: '',
    color: 'red',
  },
  errorNotShow: {
    display: 'none'
  },
  buttonSpacing: {
    width: "100px",
    marginLeft: "10px",
  },
  buttonSpacing1: {
    marginLeft: "10px",
  },
}));

export default function AddWarehouse() {


  const { t, i18n } = useTranslation();
  const addWarehouseConstants = {
    title: [
      t('homePage.finishedGoods'),
      t('homePage.material'),
      t('homePage.transfer'),
      t('homePage.scraped'),
    ],
    
};


const re_email_format = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  const classes = useStyles();
  const history = useHistory();

  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [manager, setManager] = useState(null);

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [friends, setFriends] = useState([]);
  const [staff, setStaff] = useState([]);
  const [staffInput, setStaffInput] = useState('');

  const [isMaterial, setIsMaterial] = useState(false);

  const [userOptions,setUserOptions] = useState([]);

  const [chipData, setChipData] = React.useState([]);


  React.useEffect(() => {
    organizationService.getUsersByOrgId(history.location.state.orgId).then((result) => {
        if (result.data && result.data.users) {
          setFriends(result.data.users);
        }
    })
  }, [])

  const [nameError, setNameError] = useState(false);
  const [managerError, setManagerError] = useState(false);
  const [staffError, setStaffError] = useState(false);

  const [currentOrg, setCurrentOrg] = useState('');
  const filter = createFilterOptions();
  const submitValues = {}


  const cancelAddWarehouse = () => {
    history.go(-1);
  }

  const handleNameInputChange = (event,value) => {
    setName(value);
    if (value === "") {
      setNameError(true);
    }
    else{
      setNameError(false);
    }
    if(value==='材料库'||value==='material'){
      setIsMaterial(true);
    }
  }

  const handleManagerChange = (event,value) => {
    setManager(value)
    if (value != "") {
      setManagerError(false)
    }
    else{
      setManagerError(true);
    }
  }

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

 



  const onFinish = () => {
    let valid = true;
    if (name === "") {
      setNameError(true);
      valid = false;
    }
    else {
      setNameError(false)
    }
    if (!manager) {
      setManagerError(true)
      valid = false;
    }
    else {
      setManagerError(false)
    }
    if(!staffError){
      if (staff.length===0) {
        setStaffError(true)
        valid = false;
      }
      else {
        setStaffError(false)
      }
    }

    if(!valid){
      return;
    }

    submitValues.orgId = history.location.state.orgId;
    submitValues.name = name;
    submitValues.id = id;
    submitValues.manager = manager.value;
    let staffEmail = [];
    staff.map(item=>{
      staffEmail.push(item.value);
    })
    submitValues.staff = staffEmail;
    submitValues.phone = phone;
    submitValues.address = address;
    submitValues.notes = notes;
    submitValues.isMaterial = isMaterial;
    console.log(submitValues);
    inventoryService.addWarehouse(submitValues).then((result) => {
      history.go(-1)
    })
  }


  return (

    <div className={classes.root}>

      <Grid container xs={12}>


        <div>
          <IconButton onClick={cancelAddWarehouse}>
            <ReplyIcon></ReplyIcon>
          </IconButton>
        </div>
        <div style={{ margin: "auto 0" }}>
          <Typography variant='body1'  >
            {t('warehouse.addHouse')}
          </Typography>
        </div>


      </Grid>

      <hr className={classes.hrColor} />
      <form className={classes.box1} noValidate autoComplete='off'>
        <div>
          <Grid item>
            <div className={classes.section2}>
              <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" >
                <Grid item xs={4}>
                  <div >
                    <Typography variant='body2' gutterBottom className={classes.text2}>
                      {t('warehouse.name')}<span className={classes.field_name_red}>*</span>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <Typography variant='body2' gutterBottom className={classes.text2}>
                      {t('warehouse.id')}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <Typography variant='body2' gutterBottom className={classes.text2}>
                      {t('warehouse.manager')}<span className={classes.field_name_red}>*</span>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start">
                <Grid item xs={4}>

                  <FormControl variant='outlined' size='small' className={classes.width1}>

                    <Autocomplete
                      freeSolo
                      fullWidth
                      selectOnFocus
                      size='small'
                      className={classes.input}
                      options={addWarehouseConstants.title.map((option) => option)}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      onInputChange={handleNameInputChange}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        let keyword = params.inputValue.toLowerCase();
                        return filtered;
                      }}
                      renderInput={(params) => <TextField {...params} onKeyDown={e => {
                        if (e.keyCode === 13) {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }} variant="outlined" error={nameError} />}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>

                  <FormControl variant='outlined' size='small' className={classes.width1}>
                    <OutlinedInput className={classes.input}
                      variant="outlined"

                      onChange={(event) => { setId(event.target.value); }}
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={4}>

                  <Autocomplete
                      freeSolo
                      fullWidth
                      selectOnFocus
                      size='small'
                      className={classes.input}
                      value={manager}
                      options={[]}
                      onInputChange={(e,newValue)=>{
                        if(newValue!==''){
                          let find = friends.find(item=>{
                            return item.email ===newValue;
                          });
                          if(find){
                            setManagerError(false);
                            let found = {
                              value: find.email,
                              label: find.email,
                            }
                            setManager(found);
                          }
                          else{
                            setManagerError(true);
                          }
                        }
                        // console.log(newValue);
                      }}
                      // onChange={(e,newValue)=>{
                      //   setManager(newValue)
                      //   if(!newValue){
                      //     setManagerError(true);
                      //   }
                      //   else{
                      //     setManagerError(false);
                      //   }
                      //   console.log(newValue)
                      // }}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          {option.label}
                        </React.Fragment>
                      )}

                      // onInputChange={handleManagerChange}
                      // filterOptions={(options, params) => {
                      //   const filtered = filter(options, params);
                      //   let keyword = params.inputValue.toLowerCase();
                      //   // Suggest the creation of a new value
                      //   friends.map((item,index)=>{
                      //     if(item.email === keyword){
                      //       filtered.push({
                      //         value: item.email,
                      //         label: `${item.username} ( ${item.email} )`,
                      //       })
                      //     }
                      //   })
                      //   return filtered;
                      // }}
                      renderInput={(params) => <TextField {...params} onKeyDown={e => {
                        if (e.keyCode === 13) {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }} variant="outlined" error={managerError} />}
                    />
                </Grid>

              </Grid>
            </div>



            <div className={classes.section2}>
              <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" >              
                <Grid item xs={12}>
                  <div>
                    <Typography variant='body2' gutterBottom className={classes.text2}>
                      {t('warehouse.staff')}<span className={classes.field_name_red}>*</span>
                    </Typography>
                  </div>
                </Grid>


                <FormControl variant='outlined' size='small' className={classes.width1}>
                    {/* <Select
                      multiple
                      value={staff}
                      onChange={(e) => setStaff(e.target.value)}
                      variant="outlined"
                      color="primary"
                      onChange={handleStaffChange}
                      renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip label="Deletable primary" onDelete={handleDelete}  key={value} label={value} className={classes.chip} />
                            ))}
                          </div>
                        )}                     
                    >
                      {/* {friends && friends.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))} */}
                      {/* <Autocomplete
                          id="Participants"
                          multiple
                          value={staff}
                          freeSolo
                          variant="outlined"
                          onChange={handleStaffChange}
                          onInputChange={handleStaffChange}
                          options={userOptions}
                          filterSelectedOptions={true}
                           
                          getOptionLabel={(option) => option}
                          // getOptionSelected={(option, value) => option.value === value.value}          
                          
                          renderInput={(params) => (
                                                            <TextField {...params} onKeyDown={e=>{
                                                                if(e.keyCode === 13){
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                }
                                                            }} margin="dense"  variant="outlined" size="small"/>
                                                        )}
                        /> */}

                    <Autocomplete
                      freeSolo
                      multiple
                      fullWidth
                      selectOnFocus
                      size='small'
                      className={classes.input}
                      value={staff}
                      options={[]}
                      inputValue={staffInput}
                      onInputChange={(e,newValue)=>{
                        // console.log(newValue);
                        setStaffInput(newValue);
                        let find = friends.find(item=>{
                          return item.email ===newValue;
                        });
                        if(find){
                          setStaffInput('');
                          setStaffError(false);
                          let found = {
                            value: find.email,
                            label: find.email,
                          }
                          let tempStaff = staff;
                          tempStaff.push(found);
                          setStaff(tempStaff);
                          console.log(newValue);
                        }
                        else{
                          setStaffError(true);
                        }
                        // console.log(newValue);
                      }}
                      onChange={(e,newValue)=>{
                        setStaff(newValue)
                        if(newValue.length===0){
                          setStaffError(true);
                        }
                        else{
                          setStaffError(false);
                        }
                        console.log(newValue)
                      }}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          {option.label}
                        </React.Fragment>
                      )}

                      // onInputChange={handleManagerChange}
                      // filterOptions={(options, params) => {
                      //   const filtered = filter(options, params);
                      //   let keyword = params.inputValue.toLowerCase();
                      //   // Suggest the creation of a new value
                      //   friends.map((item,index)=>{
                      //     if(item.email === keyword){
                      //       filtered.push({
                      //         value: item.email,
                      //         label: `${item.username} ( ${item.email} )`,
                      //       })
                      //     }
                      //   })
                      //   return filtered;
                      // }}
                      renderInput={(params) => <TextField {...params} onKeyDown={e => {
                        if (e.keyCode === 13) {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }} variant="outlined" error={staffError} />}
                    />
                     
                                                      
                    
                  </FormControl>
              </Grid>
            </div>








            <div className={classes.section2}>
              <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" >
                <Grid item xs={4}>
                  <div >
                    <Typography variant='body2' gutterBottom className={classes.text2}>
                      {t('warehouse.phone')}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div>
                    <Typography variant='body2' gutterBottom className={classes.text2}>
                      {t('warehouse.address')}
                    </Typography>
                  </div>
                </Grid>

              </Grid>
              <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start">
                <Grid item xs={4}>

                  <FormControl variant='outlined' size='small' className={classes.width1}>
                    <OutlinedInput className={classes.input}
                      variant="outlined"
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}

                    />
                  </FormControl>
                </Grid>

                <Grid item xs={8}>

                  <FormControl variant='outlined' size='small' className={classes.width2}>
                    <OutlinedInput className={classes.input}
                      // value={newPurName}
                      variant="outlined"
                      // placeholder={values.fields.purchaserName.holder}
                      onChange={(event) => {
                        setAddress(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className={classes.section2}>
              <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" >
                <Grid item xs={4}>
                  <div >
                    <Typography variant='body2' gutterBottom className={classes.text2}>
                      {t('warehouse.notes')}
                    </Typography>
                  </div>
                </Grid>

              </Grid>
              <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start">

                <Grid item xs={12}>
                  <FormControl variant='outlined' size='small' className={classes.width3}>
                    <TextField className={classes.input}
                      variant="outlined"
                      multiline
                      rows={4}
                      // placeholder={values.fields.purchaserName.holder}

                      onChange={(event) => {
                        setNotes(event.target.value);
                      }}
                    />

                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center" style={{marginTop:'8px'}}>
                      <Grid item xs={1}>
                          <Typography variant='body2' className={classes.text2}>
                          {t('warehouse.isMaterial')}
                          </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Switch
                            checked={isMaterial}
                            onChange={event=>{
                                setIsMaterial(event.target.checked);
                                // getFilerResult(event.target.checked,selLotNo,checkOutQtyForStorage,selGood);
                            }}
                            color="secondary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center" direction="row">
                      <Grid className={classes.buttonDiv} >
                        <div >
                          <LoadingButton
                            className={classes.buttonSpacing}
                            variant='contained'
                            onClick={onFinish}
                          >
                            {t('common.confirm')}
                          </LoadingButton></div>
                        <div>
                          <Button
                            className={classes.buttonSpacing}
                            variant='contained'
                            onClick={cancelAddWarehouse}
                          >
                            {t('common.cancel')}
                          </Button></div>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>



      </form>
    </div>







  );
}