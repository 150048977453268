import React from 'react'
import { Switch, FormControlLabel, Grid, Hidden, Typography, Button ,Menu,MenuItem, IconButton, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import OpenDaisLogo from './OpenDaisLogo';
import HelloUser from './HelloUser';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import TodayIcon from '@material-ui/icons/Today';
import applicationService from '../services/applicationService';
import useToken from "../hooks/useToken";
import iamService from '../services/iamService';
import {Link, useHistory} from 'react-router-dom';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Notification from './notification/notification';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
    header: {
        width: '100%',
        backgroundColor: theme.currentTheme.backgrounds[1],
        height: '58px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#FFFFFF'
    },
    iconButton: {
        color:'#FFFFFF'
    },
    tabbar: {
        display: 'flex',
        height: '58px',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '50px',
    },
    tabItem: {
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        marginRight: '20px',
        padding: '20px',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: theme.currentTheme.backgrounds[4]
        }
    },
    selected: {
        borderBottom: '4px solid #FF5722',
    },
    avatarBox: {
        cursor: 'pointer'
    }
}));

function Header(props) {
    const classes = useStyles();
    let {token,setToken} = useToken();
    const history = useHistory();
    const [darkMode, setDarkMode] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationNumber, setNotificationNumber] = useState(0);

    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme');
        if (localTheme && localTheme === 'dark') {
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
    }, []);

    const [currentApp, setCurrentApp] = useState('');
    useEffect(() => {
        if (props.applications && props.applications.length > 0) {
            switchApplication(props.applications[0],false);
        }
    }, [props.applications]);
    const changeTheme = (isDark) => {
        setDarkMode(isDark);
        props.themeChanged && props.themeChanged();
    };
    const getTabItemClassesFunc = (item) => {
        if (item.id === currentApp.id) {
            return [classes.tabItem, classes.selected].join(' ');
        }
        return classes.tabItem;
    }
    const switchApplication = (app,jump) => {
        if (history.location.pathname !== '/'+app.name || app.id !== currentApp.id) {
            applicationService.getApplicationById(app.id).then((result) => {
                const currentApp = result.data;
                setCurrentApp(app);
                props.applicationChange(currentApp);
                if(jump){
                    history.push('/'+app.name)
                }
            })
        }
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signInHandler = () => {
        const iamLoginURL = iamService.loginURL();
        window.location.href = iamLoginURL;
    }

    const signUpHandler = () => {
        window.location.href = '/register';
    }

    const signOutHandler = () => {
        if(token){
            iamService.logout(token.access_token).then((result)=>{
                setToken('');
                window.location.href = '/';
            });
        }
    }

    const jump2OrgHandler = () => {
        const orgURL = `/management/services/${currentApp.name}/organizations`;
        // window.location.href = orgURL;
        history.push({
            pathname:orgURL,
            state: {"fromYourOrg": true},
        });
        handleClose();
    }

    const jump2Setting = () => {
        const settingURL = '/setting';
        history.push(settingURL);
        handleClose();
    }
    const jump2Home = () => {
        const homeURL = '/';
        history.push(homeURL);
        handleClose();
    }
    const jump2UserList = () => {
        const userListUrl = '/management/userlist';
        history.push(userListUrl);
        handleClose();
    }
    const jump2UserApproval = () => {
        const approvalUrl = '/management/pendingapproval';
        history.push(approvalUrl);
        handleClose();
    }

    const jump2Schdule = () => {
        const bookingSchduleUrl = '/booking-schdule';
        history.push(bookingSchduleUrl);
        handleClose();
    }
    const openNotificationhander =() =>{
        setShowNotification (true);
    }

    const closeDetailHandler =() =>{
        setShowNotification (false);
    }

    const notificationUpdateHandler = (number) =>{
        setNotificationNumber(number);
        console.log(number);
    }
    return (
        <div className={classes.header} id="header">
            <Grid container justifyContent="space-between">
                <Grid item xs={1}>
                    <OpenDaisLogo logoClick={jump2Home}></OpenDaisLogo>
                </Grid>
                <Hidden smDown>
                    <Grid container item md={8} alignItems="center">
                        <Grid xs={12} item>
                            <div className={classes.tabbar}>
                                {
                                    props.applications.map((app) => {
                                        return (
                                            <div className={getTabItemClassesFunc(app)} key={app.id} onClick={() => switchApplication(app,true)}>

                                                <Typography variant="h6">{app.display_name}</Typography>
                                                
                                            </div>
                                        )
                                    })
                                }
                                {/* <div className={classes.tabItem} key="designSys" onClick={() => history.push('/design-system')}>
                                    <Typography variant="h6">Design System</Typography>
                                </div> */}
                            </div>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid container item xs={3} md={3} alignItems="center" justifyContent="center">   
                    {
                        token ?
                        <Grid xs={12} item container>
                            <Grid xs={3} item>
                                {
                                    !darkMode?
                                    <Tooltip title="Switch to dark theme">
                                        <IconButton className={classes.iconButton} onClick={()=> changeTheme(true)}>
                                            <Brightness4Icon fontSize="large"></Brightness4Icon>
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Switch to light theme">
                                        <IconButton className={classes.iconButton} onClick={()=> changeTheme(false)}>
                                            <Brightness7Icon fontSize="large"></Brightness7Icon>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item xs={3} >
                                    <IconButton className={classes.iconButton} onClick={openNotificationhander}>
                                        <Badge color="secondary" badgeContent={notificationNumber} max={9}>
                                            <NotificationsNoneIcon fontSize="large"></NotificationsNoneIcon>
                                        </Badge>

                                    </IconButton>
                                <Notification show={showNotification} anchorEl={anchorEl} onClose={closeDetailHandler} onUpdate={notificationUpdateHandler}></Notification>
                            </Grid>
                            <Grid item xs={3} >
                                <IconButton className={classes.iconButton} onClick={jump2Schdule}>
                                    <TodayIcon fontSize="large"></TodayIcon>
                                </IconButton>
                            </Grid>          
                            <Grid item xs={3} container alignItems="center" >
                                <div className={classes.avatarBox} onClick={handleClick}>
                                    <HelloUser ></HelloUser>
                                </div>
                            </Grid>
                        </Grid>
                        :
                        <Grid xs={12} item container>
                             <Grid xs={2} item>
                                {
                                    !darkMode?
                                    <Tooltip title="Switch to dark theme">
                                        <IconButton className={classes.iconButton} onClick={() => changeTheme(true)}>
                                            <Brightness4Icon fontSize="large"></Brightness4Icon>
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Switch to light theme">
                                        <IconButton className={classes.iconButton} onClick={() => changeTheme(false)}>
                                            <Brightness7Icon fontSize="large"></Brightness7Icon>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item xs={5} container alignItems="center">
                                <Button size="large" style={{width:'100px',color:'#ffffff'}} variant="outlined" onClick={signInHandler}>Sign in</Button>
                            </Grid>
                            <Grid item xs={5}  container alignItems="center">
                                <Button size="large" style={{width:'100px',color:'#ffffff'}} variant="outlined" onClick={signUpHandler}>Sign up</Button>
                            </Grid>        
                        </Grid>
                        
                    }
                </Grid>
            </Grid>
            {token && <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MenuItem onClick={jump2Setting}>
                    <Typography variant="body1">Your profile</Typography>
                </MenuItem>
                <MenuItem onClick={jump2OrgHandler}>
                    <Typography variant="body1">Your organizations</Typography>
                </MenuItem>
                {(process.env.REACT_APP_ADMINS.indexOf(token.username) >= 0)&&
                    <MenuItem onClick={jump2UserList}>
                        <Typography variant="body1">User list</Typography>
                    </MenuItem>}
                {(process.env.REACT_APP_ADMINS.indexOf(token.username) >= 0)&&
                    <MenuItem onClick={jump2UserApproval}>
                        <Typography variant="body1">Pending approval</Typography>
                    </MenuItem>}
                <MenuItem onClick={signOutHandler}>
                    <Typography variant="body1">Sign out</Typography>
                </MenuItem>
            </Menu>
            }
        </div>
    )
}
export default Header;