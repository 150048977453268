import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {useEffect, useState} from "react";
import moment from "moment";
import superAdminService from "../../services/superAdminService";
import CommonLayout from "../../components/CommonLayout";
import useToken from '../../hooks/useToken';
import Buttos from '../design-system/button';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@material-ui/icons/Done';
import {
    Link, useParams, useHistory
} from "react-router-dom";
import iamService from '../../services/iamService';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    box: {
      padding: '30px 30px 10px 30px',
      width:'100%',
      maxWidth:'1100px'
    },
    originInput:{
        textAlign:"center"
    },
    border:{
    	backgroundColor: theme.currentTheme.backgrounds[10],
        border: theme.currentTheme.border[3],
        borderRadius:'8px',
    },
    ServiceTitle:{
    	margin:'24px 20px 8px 20px',
    	color:'#2DA44E'
    },
    ServiceCommFree:{
    	margin:'8px 20px',
    	minHeight:'220px',
    	display:'flex',
    	justifyContent:'center',
    	alignItems:'center'
    },
    ServiceContent:{
    	margin:'8px 20px',
    },
    ServiceButton:{
    	margin:'8px 20px 20px 20px',
    	textAlign:"center"
    },
    tickIcon:{
    	color: 'blue',
    	marginRight:'4px',
    	marginLeft:'4px'
    },
    priceContent:{
    	width:'100%',
    	height:'300px'
    }
}))
export default function PricePage(props){
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const { token, setToken} = useToken();

    useEffect(()=>{
    },[])


    const jump2CreateOpenCommOrg = ()=>{
    	if(token){
    		const openCommUrl = "/management/services/Open%20Comm/organizations/create";
    		history.push({
            	pathname: openCommUrl,
            	state: { 
                	"fromPrice": true
            	}
        	});
    	}else{
    		signInHandler();
    	}
    }

    const jump2CreateOpenBookOrg = ()=>{
    	if(token){
    		const openBookUrl = "/management/services/Open%20Book/organizations/create";
    		history.push({
            	pathname: openBookUrl,
            	state: { 
                	"fromPrice": true
            	}
        	});
    	}else{
    		signInHandler();
    	}
    }

  	const signInHandler = () => {
        const iamLoginURL = iamService.loginURL();
        window.location.href = iamLoginURL;
    }

    return (
        <div className={classes.box}>
        	<Grid spacing={2}>
        		<Grid item xs={12} className={classes.originInput} style={{marginBottom:'36px',marginTop:'20px'}}>
	            	<Typography variant='h3'>{t('pricePage.title')}</Typography>
	            </Grid>
	            <Grid container xs={12} justifyContent='space-around' spacing={2}>
		            <Grid item xs={12} md={4}>
		            	<Grid container className={classes.border}>
		            		<Grid item xs={12} className={classes.ServiceTitle}>
		            			<Typography variant='h3'>{t('pricePage.openCommTitle')}</Typography>
		            		</Grid>
		            		<div className={classes.priceContent}>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Typography variant='body2'>{t('pricePage.openCommContent')}</Typography>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceCommFree}>
			            			<Typography variant='h2'>{t('pricePage.openCommFree')}</Typography>
			            		</Grid>
		            		</div>
		            		<Grid item xs={12} className={classes.ServiceButton}>
		            			<Grid container xs={12} direction="row" justifyContent="space-around">
		            				<Grid item xs={5}>
		            					<Button variant='contained' color='primary' fullWidth onClick={e=>jump2CreateOpenCommOrg()}>{t('pricePage.openCommButton')}</Button>
		            				</Grid>
		            			</Grid>
		            		</Grid>
		            	</Grid>
		            </Grid>
		            <Grid item xs={12} md={4}>
		            	<Grid container className={classes.border}>
		            		<Grid item xs={12} className={classes.ServiceTitle}>
		            			<Typography variant='h3'>{t('pricePage.OpenBookTitle')}</Typography>
		            		</Grid>
		            		<div className={classes.priceContent}>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Typography variant='body2'>{t('pricePage.OpenBookContent')}</Typography>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Typography variant='body2'>{t('pricePage.OpenBookService1')}</Typography>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<DoneIcon fontSize="small" className={classes.tickIcon}></DoneIcon><Typography variant='body2'>{t('pricePage.OpenBookService1Price1')}</Typography>
			            			</Grid>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<DoneIcon fontSize="small" className={classes.tickIcon}></DoneIcon><Typography variant='body2'>{t('pricePage.OpenBookService1Price2')}</Typography>
			            			</Grid>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Typography variant='body2'>{t('pricePage.OpenBookService2')}</Typography>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<DoneIcon fontSize="small" className={classes.tickIcon}></DoneIcon><Typography variant='body2'>{t('pricePage.OpenBookService2Price1')}</Typography>
			            			</Grid>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<DoneIcon fontSize="small" className={classes.tickIcon}></DoneIcon><Typography variant='body2'>{t('pricePage.OpenBookService2Price2')}</Typography>
			            			</Grid>
			            		</Grid>
			            	</div>
		            		<Grid item xs={12} className={classes.ServiceButton} >
		            			<Grid container xs={12} direction="row" justifyContent="space-around">
		            				<Grid item xs={5}>
		            					<Button variant='contained' color='primary' fullWidth onClick={e=>jump2CreateOpenBookOrg()}>{t('pricePage.tailButton')}</Button>
		            				</Grid>
		            				<Grid item xs={5}>
		            					<Button variant='contained' fullWidth onClick={e=>jump2CreateOpenBookOrg()}>{t('pricePage.payButton')}</Button>
		            				</Grid>
		            			</Grid>
		            		</Grid>
		            	</Grid>
		            </Grid>
		            <Grid item xs={12} md={4}>
		            	<Grid container className={classes.border}>
		            		<Grid item xs={12} className={classes.ServiceTitle}>
		            			<Typography variant='h3'>{t('pricePage.OpenOfficeTitle')}</Typography>
		            		</Grid>
		            		<div className={classes.priceContent}>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Typography variant='body2'>{t('pricePage.OpenOfficeContent')}</Typography>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Typography variant='body2'>{t('pricePage.OpenOfficeService1')}</Typography>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<DoneIcon fontSize="small" className={classes.tickIcon}></DoneIcon><Typography variant='body2'>{t('pricePage.OpenOfficeService1Price1')}</Typography>
			            			</Grid>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<Typography variant='body2' style={{marginLeft:'73px'}}>{t('pricePage.OpenOfficeService1Price2')}</Typography>
			            			</Grid>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<Typography variant='body2' style={{marginLeft:'73px'}}>{t('pricePage.OpenOfficeService1Price3')}</Typography>
			            			</Grid>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<DoneIcon fontSize="small" className={classes.tickIcon}></DoneIcon><Typography variant='body2'>{t('pricePage.OpenOfficeService1Price4')}</Typography>
			            			</Grid>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<Typography variant='body2' style={{marginLeft:'73px'}}>{t('pricePage.OpenOfficeService1Price5')}</Typography>
			            			</Grid>
			            		</Grid>
			            		<Grid item xs={12} className={classes.ServiceContent}>
			            			<Grid container xs={12} direction="row">
			            				<Typography variant='body2' style={{marginLeft:'73px'}}>{t('pricePage.OpenOfficeService1Price6')}</Typography>
			            			</Grid>
			            		</Grid>
			            	</div>
		            		<Grid item xs={12} className={classes.ServiceButton} >
		            			<Grid container xs={12} direction="row" justifyContent="space-around">
		            				<Grid item xs={5}>
		            					<Button variant='contained' color='primary' fullWidth>{t('pricePage.tailButton')}</Button>
		            				</Grid>
		            				<Grid item xs={5}>
		            					<Button variant='contained' fullWidth>{t('pricePage.payButton')}</Button>
		            				</Grid>
		            			</Grid>
		            		</Grid>
		            	</Grid>
		            </Grid>
		        </Grid>
        	</Grid>
        </div>
    )
}