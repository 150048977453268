import React , {useEffect ,useState, useRef, useContext}from 'react';
import { useHistory } from "react-router-dom";
import { Grid, Paper, Typography, Button, IconButton} from '@material-ui/core';
import { Table, TableBody, TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import LoadingButton from "../../components/LoadingButton";
import { ApplicationContext } from "../../context/applicationContext";
import inviteService from '../../services/inviteService';
import useToken from '../../hooks/useToken';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import organizationService from '../../services/organizationService';
import {loadStripe} from '@stripe/stripe-js';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import paymentIcon from '../../assets/img/dollar-circle-fill.svg';

const useStyles = makeStyles(theme=> ({
    orgListItemDetailPanel: {
        marginBottom:"13px",
        border: theme.currentTheme.border[3],
    },
    thTitle: {
        letterSpacing: "0.05px",
        color: "#5d5d5d",
    },
    root: {
        borderBottom: "none ",
        backgroundColor: theme.currentTheme.backgrounds[10],
    },
}))

export default function MyPaymentList(props){
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const history = useHistory();

    const {token, setToken} = useToken();

    const ref = useRef();

    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>
        ),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        Cancel: forwardRef((props, ref) => <CancelOutlinedIcon {...props} ref={ref} />)
    };

    const [showDetail,setShowDetail] = useState(false);

    const { applications, refreshApplications} = useContext(ApplicationContext);

    const [isLoading, setIsLoading] = React.useState(false);

    const [paymentList,setPaymentList] = useState([]);
    useEffect(()=>{
        organizationService.getPaymentOrders().then((result)=> {
            result.data.map( item => {
                item["isLoading"] = false;
            })
            setPaymentList(result.data);
        }) 
    },[])

    const transferName = (beforeName) => {
        if(beforeName==="Org_Admin"){
            return t('myOrg.roleValueadmin');
        }else if (beforeName==="Org_Manager"){
            return t('myOrg.roleValuemanager');
        }else if (beforeName==="Org_Member"){
            return t('myOrg.roleValuemember');
        }
    } 

    const retryPaymentHandler = (payItem) =>{
        if(payItem.paymentType==='stripe'){
            let sessionId = payItem.stripeSessionId;
            loadStripe(process.env.REACT_APP_PAYMENT_STRIPE_PK).then(stripe=>{
                stripe.redirectToCheckout({ sessionId: sessionId })
            });
        }
    }

    const getServicesById = (appIds) => {
        let services = [];
        appIds&&appIds.map(item=>{
            applications&&applications.map(app=>{
                if(app.id===item){
                    services.push(app.name);
                }
            })
        })
        return services.toString();
    }

    const PaymentButtonhidden = (rowData) => {
        let show = false;
        if(rowData.paymentStatus==='paid'){
            show = true;
        }
        else{
            paymentList&&paymentList.map(item=>{
                console.log(moment(item.createAt).diff(moment(rowData.createAt), 'seconds'));
                if(item.orderId!==rowData.orderId&&item.paymentStatus==='paid'&&item.orgId===rowData.orgId&&moment(item.createAt).diff(moment(rowData.createAt), 'seconds')>0){
                    show = true;
                }
            })
        }
        return show;
    }

    return(
        <div>
           {/* { paymentList && paymentList.length>0 ?  (
                    <Grid key={paymentList.id} >
                        <TableContainer component={Paper} className={classes.orgListItemDetailPanel}>
                            <Table aria-label="simple table" size="small" classes={{ root: classes.root}} >
                                <TableHead >
                                    <TableRow className={classes.thTitle}>
                                        <TableCell className={ classes.root}>{t('paymentList.createTime')}</TableCell>
                                        <TableCell className={ classes.root}>{t('paymentList.orgName')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('paymentList.serviceName')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('paymentList.servicePackage')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('paymentList.paymentType')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('paymentList.price')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('paymentList.status')}</TableCell>
                                        <TableCell className={ classes.root} align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                {paymentList.map((item ,index)=>(
                                    <TableBody key={index}>
                                        <TableRow >
                                            <TableCell component="th" scope="item" className={ classes.root}>{new moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.orgName}</TableCell>
                                            <TableCell className={ classes.root} align="left">{getServicesById(item.serviceIds)}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.servicePackage}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.paymentType}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.currency} {item.totalPrice}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.paymentStatus}</TableCell>
                                            <TableCell className={ classes.root} align="left">
                                                {item.paymentStatus==='pending'&&!item.enable&&<LoadingButton  isLoading={item.isLoading} variant="contained" color="primary" onClick={()=>{retryPaymentHandler(item)}}>
                                                    <Typography variant="body2"  >
                                                    {t('paymentList.retry')}
                                                    </Typography>
                                                </LoadingButton>}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                            </Table>
                        </TableContainer>
                    </Grid>
                    ) 
                :t('paymentList.noPaymentLine')
            }
*/}
            <MaterialTable
                classes={{MuiPaper:classes.noBox}}
                title=""
                icons={tableIcons}
                columns={[
                    {
                        field: 'createAt',
                        title: t('paymentList.createTime'),
                        width: '8%',
                        sorting: true,
                        defaultSort: 'desc',
                        render: rowData => new moment(rowData.createAt).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                        field: 'orgName',
                        title: t('paymentList.orgName'),
                        width: '8%',
                        sorting: false,
                        defaultGroupOrder: 0
                    },
                    {
                        field: 'serviceIds',
                        title: t('paymentList.serviceName'),
                        width: '8%',
                        sorting: false,
                        render: rowData => getServicesById(rowData.serviceIds),
                    },
                    {
                        field: 'packageName',
                        title: t('paymentList.servicePackage'),
                        width: '8%',
                        sorting: false,
                        
                    },
                    {
                        field: 'paymentType',
                        title: t('paymentList.paymentType'),
                        width: '10%',
                        sorting: false,
                    },
                    // {
                    //     field: 'requestor',
                    //     title: t('checkInOutManagement.requestor'),
                    //     width: '10%',
                    //     sorting: false,
                    // },
                    {
                        field: 'totalPrice',
                        title: t('paymentList.price'),
                        width: '10%',
                        sorting: false,
                        render: rowData => rowData.currency + " " +  rowData.totalPrice
,

                    },
                    {
                        field: 'paymentStatus',
                        title: t('paymentList.status'),
                        width: '10%',
                        sorting: false,
                    },
                    {
                        field: 'enable',
                        title: t('paymentList.enableStatus'),
                        width: '10%',
                        sorting: false,
                        render: rowData => rowData.enable?t('paymentList.enableTrue'):t('paymentList.enableFalse')
                    },
                ]}
                data={paymentList}
                style={{width:'100%'}} 
                options={{      
                    actionsColumnIndex: -1,                                      
                    search: false,
                    grouping: true,
                    toolbar: false,
                    // toolbar:false,
                    sorting: true,
                    paging: false,
                    headerStyle: {
                        backgroundColor:"transparent",
                        fontFamily:'IBMPlexSans-Bold',
                        padding:'4px'
                    },
                    cellStyle:{
                        maxWidth:'160px',
                        padding:'4px',
                        fontSize:'14px',
                        whiteSpace:'normal',
                        overflowWrap:'break-word'
                    },
                }}
                localization={{
                    header: {
                        actions: t("common.actions")
                    },
                    emptyDataSourceMessage:t("common.noRecord"),
                }}
                actions={[
                    rowData => ({
                        icon: ()=> <img src={paymentIcon}></img>,
                        tooltip: t('paymentList.retry'),
                        onClick: (event, rowData) => retryPaymentHandler(rowData),
                        hidden: PaymentButtonhidden(rowData),
                    }),
                ]}
            />
        </div>
    )
}
