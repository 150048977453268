import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import LoadingButton from "../../components/LoadingButton";
import React, {useState} from "react";
import moment from "moment";
import accountingService from "../../services/accountingService";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    selectField: {
        padding: "11px 14px",
    },
}));

export default function BaoguanDialog(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [shippingIds, declareInfo, onSuccess, onCancel] = [props.shippingIds, props.declarationInfo, props.onSuccess, props.onCancel];
    const [exportBill, setExportBill] = useState(declareInfo? declareInfo.exportBill: '');
    const [exportBillError, setExportBillError] = useState(false);
    const [declarationBill, setDeclarationBill] = useState(declareInfo? declareInfo.declarationBill: '');
    const [declarationBillError, setDeclarationBillError] = useState(false);
    const [declarationDate, setDeclarationDate] = useState(declareInfo? declareInfo.declarationDate: moment().format('MM-DD-YYYY'));
    const [isLoading, setIsLoading] = useState(false);

    const changeExportBill = (value) => {
        setExportBillError(value === '');
        setExportBill(value);
    }

    const changeDeclarationBill =(value) =>{
        setDeclarationBillError(value==='');
        setDeclarationBill(value);
    }

    const changeDeclarationDate =(value) =>{
        setDeclarationDate(value);
    }

    const declarationSubmit = () => {
        setIsLoading(true);
        let valid = true;
        if (!exportBill) {
            setExportBillError(true);
            valid = false;
        }
        if (!declarationBill) {
            setDeclarationBillError(true);
            valid = false;
        }
        if(!shippingIds || shippingIds.length <= 0) {
            valid = false;
        }
        if (valid === false) {
            setIsLoading(false);
            return;
        }
        let currentOrg = window.sessionStorage.getItem("currentOrg");
        let value = {
            orgId: currentOrg,
            exportBill: exportBill,
            declarationBill: declarationBill,
            declarationDate: declarationDate
        }

        shippingIds.forEach((item, index) => {
            accountingService.declarationAdd(item, value).then(result => {
                if (result.status === 200 && index === shippingIds.length - 1) {
                    setIsLoading(false);
                    if(onSuccess)
                        onSuccess();
                }
            })
        })
    }

    const cancelHandler = ()=> {
        if(onCancel) {
            onCancel()
        }
    }

    return (
        <Dialog open={true} onClose={cancelHandler} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Typography variant="body1">
                    {t('shippingPage.waimaoxinxi')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {t('shippingPage.exportContractNo')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-multiline-static"
                            fullWidth
                            error={exportBillError}
                            variant="outlined"
                            margin="dense"
                            value={exportBill}
                            onChange={e=>changeExportBill(e.target.value)}
                            classes={{ select: classes.selectField }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {t('shippingPage.baoguanNo')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-multiline-static"
                            fullWidth
                            error={declarationBillError}
                            variant="outlined"
                            margin="dense"
                            value={declarationBill}
                            onChange={e=>changeDeclarationBill(e.target.value)}
                            classes={{ select: classes.selectField }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {t('shippingPage.baoguanDate')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                id="date"
                                variant="inline"
                                inputVariant="outlined"
                                format="YYYY/MM/DD"
                                value={declarationDate}
                                size="small"
                                style={{ marginTop: "12px" }}
                                onChange={date => changeDeclarationDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton isLoading={isLoading} variant="contained" color="primary" onClick={declarationSubmit}>
                    <Typography variant="body2">
                        {t('shippingPage.btnConfirmRequest')}
                    </Typography>
                </LoadingButton>
                <Button variant="contained" onClick={cancelHandler}>
                    <Typography variant="body2">
                        {t('shippingPage.btnCancelRequest')}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}