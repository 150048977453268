import moment from 'moment';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import Button from '@material-ui/core/Button';
import { useState,useEffect,useRef} from 'react';
import {
    useParams,useHistory
} from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useToken from '../../hooks/useToken';
import meetingService from '../../services/meetingService';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=> ({
    meetingDetailPanel:{
        width:'80%',
        borderRadius:'8px',
        border: 'solid 1px #979797',
        backgroundColor: theme.currentTheme.backgrounds[7],
        padding: '20px 12px 15px 20px',
        overflow: 'auto'
    },
    meetingItem:{
        padding: '0px 16px',
        paddingTop:'12px',
        borderRadius: '0px',
        cursor: 'pointer',
        justifyContent: 'space-between',
        display: 'flex',
        flexFlow: 'column',
        minHeight: '48px',
        position:'relative',
        backgroundColor: 'none',
        "&:hover":{
            backgroundColor:theme.currentTheme.theme2backgrounds[0]
        },
        borderBottom: theme.currentTheme.border[3]
    }
}))
export default function MeetingItem(props){
    const ref = useRef();
    const history = useHistory();
    const { token } = useToken();
    const classes = useStyles();
    const [isOganizer, setIsOganizer] = useState(false);
    useEffect(()=>{
        if(props.meet && token && token.username === props.meet.organizer){
            setIsOganizer(true);
        }
    },[props.meet,token])
    function getMeetingTime(){
        const endTime = moment(props.meet.startTime).add(props.meet.duration,'minutes');
        return moment(props.meet.startTime).format("HH:mm") + ' ~ ' + endTime.format("HH:mm");
    }
    const [showDetail,setShowDetail] = useState(false);
    function showMeetingDetail(){
        let element = document.getElementById(props.meet.id);
        props.onClick(props.meet,element);
    }
    
    return ( 
        <div className={classes.meetingItem} id={props.meet.id}>     
            <div onClick={showMeetingDetail}>
                <div className="text_medium meetingTime"><span>{getMeetingTime()}</span></div>
                <div className="meetingTitle"><span >{props.meet.title}</span></div>
            </div>
        </div>
    )
}