import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding:'50px'
  },
  box: {
    padding: '50px',
  }
});

export default function Buttons() {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <Grid container spacing={2} justifyContent="center" direction="row" alignItems="center">
        <Grid xs={4} item className={classes.root}>
          <Button variant="contained" color="primary">Primary Button</Button>
        </Grid>
        <Grid xs={4} item className={classes.root}>
          <Button variant="contained">Normal Button</Button>
        </Grid>
        <Grid xs={4} item className={classes.root}>
          <Button variant="contained" disabled>Disabled Button</Button>
        </Grid>
      </Grid>
    </div>
  );
}