import { Button, Grid, Typography, useMediaQuery } from "@material-ui/core";
import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import PlatformHomeDemo from '../platform-home/platform-home-demo';

import homepageBg1 from "../../assets/img/homepage_bg4@3x.png";
import checkmark from '../../assets/img/check-solid.svg';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        display: 'flex',
        flexFlow: 'column',
        color: theme.currentTheme.colors[1],
        backgroundColor: 'black',
    },
    box_base_1: {
        color: theme.currentTheme.colors[1],
        backgroundImage: `url(${homepageBg1})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    box_base_bg_1: {
        background: theme.currentTheme.theme2backgrounds[4],
        position: 'relative',
        color: theme.currentTheme.colors[1],
        backgroundSize: "cover",
        minHeight:'600px',
        [theme.breakpoints.down('sm')]:{
            minHeight:'200px',
            backgroundSize: "100% 100%",
        }
    },
    box1: {
        margin: '0',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '100%',
        [theme.breakpoints.down('sm')]:{
            minHeight: '200px',
        },
    },
    box2: {
        padding: '0px 40px'
    },
    box_base_2: {
        backgroundColor: theme.currentTheme.backgrounds[13],
        padding: '20px 30px',
    },

    box_base_3: {
        backgroundColor: theme.currentTheme.backgrounds[13],
        color: theme.currentTheme.colors[1],
        backgroundSize: "cover",
        minHeight:'330px',
        [theme.breakpoints.down('sm')]:{
            minHeight:'200px',
            backgroundSize: "100% 100%",
        }
    },
    box3: {
        margin: '35px 25px',
        width: 'auto',
        [theme.breakpoints.down('sm')]:{
            width: '66%',
            margin: '0px auto'
        },
        [theme.breakpoints.down('xs')]:{
            width: 'auto',
            margin: '35px 25px',
        }
    },

    button: {
        borderWidth: '2px',
        borderColor: theme.currentTheme.colors[0],
        color: theme.currentTheme.colors[0],
        fontFamily: 'DinBold',
        fontSize: '16px',
        textTransform: 'uppercase',
        minWidth: '200px',
        letterSpacing: '-0.94px',
        [theme.breakpoints.down('sm')]:{
            letterSpacing: '-0.44px',
            fontSize: '16px',
            minWidth: '100%',
        }
    },

    list_base: {
        listStyleImage: `url(${checkmark})`,
        fontSize: '1.5em',
        margin: '20px 0px',
        paddingLeft: '35px',
    },
    list_base2: {
        margin: '20px 0px',
        paddingLeft: '15px',
        fontSize: '1.5em',
        listStyle: 'none'
    },
    list_item: {
        margin: '5px 0px'
    },

    center_align: {
        margin: '0',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '100%',
    },

    text1: {
        color: theme.currentTheme.colors[2],
        fontFamily: 'DinBold',
        textTransform: 'uppercase',
        marginTop: "2%",
        fontSize: '50px',
        letterSpacing: '0.08px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]:{
            fontSize: '40px',
        }
    },
    text2: {
        marginTop: "2%",
        margin:'0px 15px',
        color: theme.currentTheme.colors[0],
        fontFamily: 'DinBold',
        textTransform: 'uppercase',
        fontSize: '26px',
        letterSpacing: '-0.94px',
        [theme.breakpoints.down('sm')]:{
            letterSpacing: '-0.44px',
            fontSize: '26px',
        }
    },
    text_list1: {
        color: theme.currentTheme.colors[0],
        fontFamily: 'DinRegular',
        fontSize: '16px',
        letterSpacing: '-0.94px',
        [theme.breakpoints.down('sm')]:{
            letterSpacing: '-0.44px',
            fontSize: '14px',
        }
    },

    video_wrapper: {
        position: 'relative',
        paddingTop: '56.25%',
        overflow: 'hidden',
    },
    video_frame: {
        overflow: 'hidden',
        border: '0',
        alignSelf: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%'
    }
}));

export default function LiveOfficeLearnMore() {

    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useState({
        openDialog: false,
        testValue: 1
    });

    const isMobile = useMediaQuery(theme => {
        return theme.breakpoints.down('sm')
    });

    const handlePrice = () => {
        history.push({
            pathname: '/price'
        });
    };

    const handleOpenDemo = () => {
        setValues({ ...values, openDialog: true, testValue: 2 });
    };

    const handleCloseDemo = () => {
        setValues({ ...values, openDialog: false });
    };


    const style = useStyles();

    return (
        <div className={style.root}>
            <div className={style.box_base_1}>
                <div className={style.box_base_bg_1}>
                    <div className={style.box1}>
                        {
                            !isMobile &&
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                className={style.box2}
                                spacing={3}
                            >

                                <Grid item md={5}>
                                    <Typography gutterBottom variant="h1" className={style.text1}>{t('liveOfficeLearnMore.title')}</Typography>
                                </Grid>

                                <Grid item md={7}>
                                    <div className={style.box_base_2}>
                                        <Typography gutterBottom variant="h2" className={style.text2}>{t('liveOfficeLearnMore.benefitsTitle')}</Typography>
                                        <ul className={style.list_base}>
                                            <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits1')}</Typography></li>
                                            <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits2')}</Typography></li>
                                            <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits3')}</Typography></li>
                                            <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits4')}</Typography></li>
                                            <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits5')}</Typography></li>
                                        </ul>
                                    </div>
                                </Grid>

                            </Grid>


                        }

                        {
                            isMobile &&
                            <div className={style.center_align}>
                                <Typography gutterBottom variant="h1" className={style.text1}>{t('liveOfficeLearnMore.title')}</Typography>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {
                !isMobile &&
                <div className={style.box_base_3}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        spacing={3}
                        className={style.box3}
                    >
                        <Grid item xs={5}>
                            <Typography gutterBottom variant="h2" className={style.text2}>{t('liveOfficeLearnMore.experienceTitle')}</Typography>
                            <ul className={style.list_base2}>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience1')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience2')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience3')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience4')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience5')}</Typography></li>
                            </ul>
                        </Grid>

                        <Grid item xs={7}>
                            <div className={style.video_wrapper}>
                                <iframe
                                    src={t('liveOfficeLearnMore.video')}
                                    frameBorder="0"
                                    allow="autoplay"
                                    allowfullscreen
                                    title="video"
                                    className={style.video_frame}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={5} style={{ textAlign: 'center'}}>
                            <Button variant='outlined' className={style.button} onClick={handlePrice}>{t('liveOfficeLearnMore.price')}</Button>
                        </Grid>

                        <Grid item xs={7} style={{ textAlign: 'center'}}>
                            <Button variant='outlined' className={style.button} onClick={handleOpenDemo}>{t('liveOfficeLearnMore.demo')}</Button>
                        </Grid>

                    </Grid>
                </div>
            }

            {
                isMobile &&
                <div className={style.box_base_3}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        spacing={3}
                        className={style.box3}
                    >

                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h2" className={style.text2}>{t('liveOfficeLearnMore.benefitsTitle')}</Typography>
                            <ul className={style.list_base}>
                                <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits1')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits2')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits3')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits4')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>{t('liveOfficeLearnMore.benefits5')}</Typography></li>
                            </ul>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <Button variant='outlined' className={style.button} onClick={handlePrice}>{t('liveOfficeLearnMore.price')}</Button>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={style.video_wrapper}>
                                <iframe
                                    src={t('liveOfficeLearnMore.video')}
                                    frameBorder="0"
                                    allow="autoplay"
                                    allowfullscreen
                                    title="video"
                                    className={style.video_frame}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}><Typography gutterBottom variant="h2" className={style.text2}>{t('liveOfficeLearnMore.experienceTitle')}</Typography></Grid>

                        <Grid item xs={12}>
                            <ul className={style.list_base2}>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience1')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience2')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience3')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience4')}</Typography></li>
                                <li className={style.list_item}><Typography className={style.text_list1}>•  {t('liveOfficeLearnMore.experience5')}</Typography></li>
                            </ul>
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'center'}}>
                            <Button variant='outlined' className={style.button} onClick={handleOpenDemo}>{t('liveOfficeLearnMore.demo')}</Button>
                        </Grid>

                    </Grid>
                </div>
            }

            <PlatformHomeDemo openDialog={values.openDialog} onClose={handleCloseDemo} />
        </div>
    );

}
