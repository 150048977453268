import React, {useEffect, useState} from "react";
import accountingService from "../services/accountingService";
import {MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    select1_3: {
        width: '100%',
        verticalAlign: 'middle',
        flexGrow: 1,
        marginLeft: 'unset',
        marginRight: 'unset',
    },
    outlinedPadding: {
        padding: '11px 14px',
    },
}));

export default function CustomerSelector(props) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [currentOrg, changeHandler, styles] = [props.org, props.onValueChange, props.style];
    const [currentCustomer,setCurrentCustomer] = useState(props.customer? props.customer: 'all');
    const allOption = {value: 'all', label: t('poLineManagement.all')};
    const [customers,setCustomers] = useState([allOption]);

    useEffect(() => {
        if(currentOrg) {
            accountingService.getCustomers(currentOrg).then(result => {
                if (result.status === 200) {
                    let customerOptions = [allOption]
                    if(result.data && result.data.length > 0) {
                        customerOptions = customerOptions.concat(result.data.map((item)=> {
                            return { value: item.customer, label: item.customer }
                        }));
                    }
                    setCustomers(customerOptions);
                }
            })
        }
    }, [currentOrg]);

    const onValueChange = (event) =>{
        setCurrentCustomer(event.target.value);
        if(changeHandler) {
            changeHandler(event.target.value);
        }
    }

    return (
        <Select
            value={currentCustomer}
            variant="outlined"
            color="primary"
            fontSize="small"
            className={classes.select1_3}
            classes={{ select: classes.outlinedPadding }}
            style={styles}
            onChange={onValueChange}
        >
            {customers && customers.length > 0 && customers.map((item, index) => {
                return (
                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                )
            })}
        </Select>
    )
}