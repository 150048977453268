import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    OutlinedInput,
    Select,
    MenuItem, NativeSelect
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Typography from '@material-ui/core/Typography';
import React, {useEffect} from "react";
import PlatformHomeConstant from "./platform-home-constant";
import {axiosBooking as axios} from "../../services/axiosHelper";
import {makeStyles} from "@material-ui/core/styles";
import c from 'classnames';

const useStyles = makeStyles(theme=> ({
    root: {
        marginTop: "0",
    },
    title: {
        marginLeft: "1%",
    },
    box_left: {
        width: "30%",
        marginTop: "2%",
        display: "inline-flex",
        flexFlow: "column",
        [theme.breakpoints.down('sm')]:{
            width: '100%',
        },
        [theme.breakpoints.up('md')]:{
            marginLeft: "1%",
        }
    },
    box_job_title: {
        [theme.breakpoints.down('sm')]:{
            marginTop: '5%',
        },
    },
    box_right: {
        width: "30%",
        marginTop: "2%",
        display: "inline-flex",
        flexFlow: "column",
        [theme.breakpoints.up('md')]:{
            marginLeft: "22%",
        },
        [theme.breakpoints.down('sm')]:{
            width: '100%',
            marginTop: "5%",
        }
    },
    box: {
        width: "90%",
        marginTop: "2%",
        display: "inline-flex",
        flexFlow: "column",
        [theme.breakpoints.up('md')]:{
            marginLeft: "1%",
        },
        [theme.breakpoints.down('sm')]:{
            marginTop: "5%",
        }
    },
    disable_box: {
        display: "none",
        marginLeft: "1%",
        marginTop: "2%",
    },
    field_name_red: {
        color: "#C00000",
    },
    input_short: {
        height: "32px !important",
        width: "220px !important",
        fontFamily: "Lato",
        fontSize: "15px",
        marginTop: "3%",
    },
    input: {
        marginTop: "1%",
        width: "500px !important",
        height: "32px !important",
        fontFamily: "Lato",
        fontSize: "15px",
        [theme.breakpoints.down('sm')]:{
            marginTop: "3%",
            width: "260px !important",
        },
    },
    button: {
        marginTop: "2%",
        marginBottom: "3.5%",
        border: "none",
        [theme.breakpoints.up('md')]:{
            marginLeft: "1%",
        },
        [theme.breakpoints.down('sm')]:{
            marginTop: "8%",
            width: '95%',
        },
    },
    close_button: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    disable_select: {

    },
    select: {
        height: "32px !important",
        width: "500px !important",
        fontFamily: "Lato",
        fontSize: "15px",
        marginTop: "1%",
        [theme.breakpoints.down('sm')]:{
            width: "260px !important",
            marginTop: "3%",
        }

    }
}))

export default function PlatformHomeDemo(props) {
    const style = useStyles();

    const firstNameDefaultHolder = "Enter your first name";
    const lastNameDefaultHolder = "Enter your last name";
    const emailDefaultHolder = "Enter your business email address";
    const jobDefaultHolder = "Enter your job title";
    const companyDefaultHolder = "Enter your company name";
    const countryDefaultHolder = "Select your country/region";
    const companyTypeHolder = "Enter your company type";
    const industrySegmentHolder = "Enter your Industry Segment";
    const channelDefaultHolder = "Select the way in which you hear about openuse.co";

    const initField = function (fieldName, defaultHolder, isRequired) {
        return {
            value: "",
            fieldName: fieldName,
            holder: defaultHolder,
            defaultHolder: defaultHolder,
            isRequired: isRequired,
            isInvalid: false,
        };
    };

    const defaultValue = {
        fields: {
            firstName: initField('first_name', firstNameDefaultHolder, true),
            lastName: initField('last_name', lastNameDefaultHolder, true),
            emailAddress: initField('email', emailDefaultHolder, true),
            jobTitle: initField('job_title', jobDefaultHolder, true),
            companyName: initField('company_name', companyDefaultHolder, true),
            countryRegion: initField('country_or_region', countryDefaultHolder, true),
            companyType: initField('company_type', companyTypeHolder, true),
            industrySegment: initField('industry_segment', industrySegmentHolder, true),
            hearChannel: initField('channel', channelDefaultHolder, false),
            specifyReason: initField('', "", false)
        },
        inputHolder: {},
        openDialog: false,
        specialReasonStyle: style.disable_box,
    }

    const [values, setValues] = React.useState(defaultValue);

    React.useEffect(() => {
        setValues({...values, openDialog: props.openDialog});
    }, [props.openDialog])

    const handleClose = () => {
        setValues(defaultValue);
        props.onClose();
    };

    const handleChange = (fieldName) => (event) => {
        let curValue = event.target.value;
        let fieldValues = values.fields;
        if (fieldName === "hearChannel") {
            values.specialReasonStyle = curValue === "Other" ? style.box : style.disable_box;
        }

        fieldValues[fieldName].value = curValue;
        fieldValues[fieldName].holder = curValue? "" : fieldValues.defaultHolder;
        setValues({...values, fields: fieldValues});
    };

    const checkValues = function () {
        let isInvalid = false;
        let fields = values.fields;
        let submitValues = {};
        let reg = /([\w._-])+@([\w_-])+(\.([\w_-])+){1,2}/;
        for (let fieldName in fields) {
            let fieldInfo = fields[fieldName];
            let submitName = fieldInfo.fieldName;
            if (!submitName) {
                continue;
            }
            let fieldValue = fieldInfo.value;
            let fieldValid = (fieldName === 'emailAddress') ? reg.test(fieldValue) : true;
            if (fieldInfo.isRequired && (!fieldValue || !fieldValid)) {
                fieldInfo.isInvalid = true;
                isInvalid = true;
            } else {
                fieldInfo.isInvalid = false;
                submitValues[submitName] = fieldValue;
            }
        }

        if (!isInvalid) {
            let channelName = values.fields.hearChannel.fieldName;
            if (submitValues[channelName] === "Other") {
                submitValues[channelName] = values.fields.specifyReason.value;
            }
        }

        return [isInvalid, fields, submitValues];
    };

    const sendRequestDemo = function (data) {
        return new Promise((resolve, reject) => {
            axios.post('/public/request_demo', data).then(result=>{
                resolve(result);
            }).catch(e=>{
                reject(e);
            })
        });
    };

    const onFinish = function () {
        let [isInvalid, fields, submitValues] = checkValues();

        console.log("checkValues的isInvalid",isInvalid);
        console.log("checkValues的fields",fields);
        console.log("checkValues的submitValues",submitValues);

        setValues({...values, fields: fields});
        if (isInvalid) {
            return;
        }

        props.onClose();
        return sendRequestDemo(submitValues);
    };

    const countryOptions = function () {
        let options = PlatformHomeConstant.country;
        return options.map((item) => {
            if (item === "") {
                return <option value={item} selected disabled>{values.fields.countryRegion.holder}</option>;
            }
            return <option value={item}>{item}</option>;
        });
    };

    const channelOptions = function () {
        let options = PlatformHomeConstant.channel;
        return options.map((item) => {
            if (item === "") {
                return <option value={item} selected disabled>{values.fields.hearChannel.holder}</option>
            }
            return <option value={item}>{item}</option>;
        });
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="request-demo-title" open={values.openDialog}>

            <DialogTitle id="request-demo-title">
                <IconButton aria-label="close" className={style.close_button} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
                <Typography variant="h3" style={{maxWidth: '90%'}}>
                    <span className={style.title}>I'm interested in an openuse.co demo. Please contact me.</span>
                </Typography>
            </DialogTitle>

            <DialogContent>
                <div className={style.box_left}>
                    <Typography variant="h4">
                        First Name <span className={style.field_name_red}>*</span>
                    </Typography>
                    <OutlinedInput className={style.input_short}
                                   variant="outlined"
                                   placeholder={values.fields.firstName.holder}
                                   onChange={handleChange('firstName')}
                                   error={values.fields.firstName.isInvalid}
                    />
                </div>
                <div className={style.box_right}>
                    <Typography variant="h4">
                        Last Name <span className={style.field_name_red}>*</span>
                    </Typography>
                    <OutlinedInput className={style.input_short}
                                   variant="outlined"
                                   placeholder={values.fields.lastName.holder}
                                   onChange={handleChange('lastName')}
                                   error={values.fields.lastName.isInvalid}
                    />
                </div>

                <div className={style.box}>
                    <Typography variant="h4">
                        Email <span className={style.field_name_red}>*</span>
                    </Typography>
                    <OutlinedInput className={style.input}
                                   variant="outlined"
                                   placeholder={values.fields.emailAddress.holder}
                                   onChange={handleChange('emailAddress')}
                                   error={values.fields.emailAddress.isInvalid}
                    />
                </div>
                <div className={c(style.box_left, style.box_job_title)}>
                    <Typography variant="h4">
                        Job Title <span className={style.field_name_red}>*</span>
                    </Typography>
                    <OutlinedInput className={style.input_short}
                                   variant="outlined"
                                   placeholder={values.fields.jobTitle.holder}
                                   onChange={handleChange('jobTitle')}
                                   error={values.fields.jobTitle.isInvalid}
                    />
                </div>
                <div className={style.box_right}>
                    <Typography variant="h4">
                        Company Name <span className={style.field_name_red}>*</span>
                    </Typography>
                    <OutlinedInput className={style.input_short}
                                   variant="outlined"
                                   placeholder={values.fields.companyName.holder}
                                   onChange={handleChange('companyName')}
                                   error={values.fields.companyName.isInvalid}
                    />
                </div>
                <div className={style.box}>
                    <Typography variant="h4">
                        Country/Region <span className={style.field_name_red}>*</span>
                    </Typography>
                    <NativeSelect className={style.select}
                            placeholder={values.fields.countryRegion.holder}
                            onChange={handleChange("countryRegion")}
                            error={values.fields.countryRegion.isInvalid}
                            input={<OutlinedInput />}
                    >
                        {countryOptions()}
                    </NativeSelect>
                </div>
                <div className={style.box}>
                    <Typography variant="h4">
                        Company Type <span className={style.field_name_red}>*</span>
                    </Typography>
                    <OutlinedInput className={style.input}
                                   variant="outlined"
                                   placeholder={values.fields.companyType.holder}
                                   onChange={handleChange('companyType')}
                                   error={values.fields.companyType.isInvalid}
                    />
                </div>
                <div className={style.box}>
                    <Typography variant="h4">
                        Industry Segment <span className={style.field_name_red}>*</span>
                    </Typography>
                    <OutlinedInput className={style.input}
                                   variant="outlined"
                                   placeholder={values.fields.industrySegment.holder}
                                   onChange={handleChange('industrySegment')}
                                   error={values.fields.industrySegment.isInvalid}
                    />
                </div>
                <div className={style.box}>
                    <Typography variant="h4">
                            How did you hear about openuse.co
                    </Typography>
                    <NativeSelect className={style.select}
                                  placeholder={values.fields.hearChannel}
                                  error={values.fields.hearChannel.isInvalid}
                                  input={<OutlinedInput />}
                                  onChange={handleChange("hearChannel")}
                    >
                        {channelOptions()}
                    </NativeSelect>
                </div>
                <div className={values.specialReasonStyle} style={{marginTop: '5%'}}>
                    <Typography variant="h4">
                        Specify the reason
                    </Typography>
                    <OutlinedInput className={style.input}
                                   variant="outlined"
                                   onChange={handleChange('specifyReason')}
                    />
                </div>
                <Button className={style.button}
                        type='primary'
                        variant='contained'
                        color="primary"
                        onClick={onFinish}
                >
                    <Typography variant="h4" >
                        Submit
                    </Typography>
                </Button>
            </DialogContent>

        </Dialog>
    )
}
