import React ,{ useContext, useState, useEffect } from 'react';
import { useHistory,useParams } from "react-router-dom";
import { Button, Grid, Typography, Tooltip, TextField,TextareaAutosize, Select, MenuItem, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import CommonLayout from "../../components/CommonLayout";
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingButton from "../../components/LoadingButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import UndoIcon from '@material-ui/icons/Undo';
import accountingService from "../../services/accountingService";
import moment from 'moment';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme=> ({
    root: {
        borderBottom: "none ",
        fontSize:"medium",
        backgroundColor: theme.currentTheme.backgrounds[11],
    },
    thBorder: {
        borderBottom: "none ",
        fontSize: "medium",
    },
    tableCell:{
        whiteSpace:'nowrap',
        overflow:'hidden',
        textOverflow:'ellipsis',
    },
    poFormBox: {
        width:"100%",
        padding:"4vw",
    },
    poFormTitle: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "28px 0 20px 23px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[2],
        //marginTop:"30px",
    },
    poFormContent: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "0 40px 0 40px",
    },
    poFormContentItem: {
        padding: "20px 0 0 0",
    },
    poFormContentBottom: {
        marginBottom:"9px"
    },
    poFormContentItemBottom: {
        padding: "20px 0 30px 0",
    },
    selectField: {
        padding: "11px 14px",
    },
    selectTopItem: {
        marginBottom:"12px",
    },
    itemTitle: {
        overflow:"hidden",
        textOverflow:"ellipsis",
        whiteSpace:"nowrap",
        marginBottom:"10px"
    },
    itemContent: {
        overflow:"hidden",
        textOverflow:"ellipsis",
        whiteSpace:"nowrap",
    },
    required: {
        color: "#fd4747",
        display: "inline",
    },
    itemSpacing: {
        marginRight:"50px",
    },
    dashedLine: {
        margin:"23px 0 0 0",
        borderTop:"1px solid #dbdbdb",
        width:"100%",
    },
    largeInput: {
        height:"5.1876em",
    },
    buttonSpacing: {
        width: "150px",
        marginLeft: "10px",
    },
    buttonBottom: {
        marginBottom: "20px",
    },
    itemPOFormContent: {
        marginTop: "6px",
    },
    itemPOFormItem: {
        margin: "0",
    },
    link: {
        cursor: "pointer"
    },
    undoIcon: {
        marginRight: "23px",
        color:"#3f51b5",
    },
}))

export default function PayMentAddPage(props){
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const history = useHistory();
    const [paymentActionData, setPaymentActionData] = React.useState([]);
    useEffect(()=> {
    },[])
    const [paymentFormData, setPaymentFormData] = useState([]);
    const [paymentActionError, setPaymentActionError] = useState(false);
    const [invoiceSelectError, setInvoiceSelectError] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [selLinesError, setSelLinesError] = useState(false);

    const [invoiceData, setInvoiceActionData] = React.useState([]);

    useEffect(()=> {
        let payment_term; 
        if(history.location.state && history.location.state.bookId){
            accountingService.getPOByBookId(history.location.state.bookId).then(result=>{
                if(result.data[0] && result.data[0].poId){
                    setPaymentFormData(result.data[0]);
                    payment_term=result.data[0].paymentTerm;
                    let data = [payment_term,"Deposit"];
                    setPaymentActionData(data);
                    accountingService.getInvoiceByPOId(result.data[0].poId).then(result=>{
                        let notPayedInvoices=[];
                        result.data.map((item,index)=>{
                            if(item.status==="billed"){
                                notPayedInvoices.push(item);
                            }
                        })
                        setInvoiceActionData(notPayedInvoices);
                        if(notPayedInvoices.length===0){
                            setLineSelectStatus(true);
                        }
                        if(history.location.state && history.location.state.invoice){
                            console.log(payment_term);
                            handlePaymentActionChange(payment_term);
                            notPayedInvoices.map(item=>{
                                if(item.invoiceId === history.location.state.invoice.invoiceId){
                                    handleInvoiceChange(item);
                                    setPoFormData(item.lines);
                                    setSelLine(item.lines);
                                    let total = 0;
                                    item.lines.map((item)=>{
                                        total = total + parseFloat(item.lineTotal);
                                    })
                                    setInvoiceTotal(total);
                                    console.log(total);
                                }
                            })
                        }
                    })
                }
            })
        }
    },[history.location.state.bookId])

    const [poFormData, setPoFormData]= React.useState([]);

    const [selLine, setSelLine] = React.useState([]);
    const [closeBookConfirmWindow,setCloseBookConfirmWindow] = React.useState(false);
    const [paymentAction, setPaymentAction] = React.useState("");
    const [invoiceSelected, setInvoiceSelected] = React.useState("");
    const [paymentAmount, setPaymentAmount] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [invoiceTotal, setInvoiceTotal] = React.useState(0);
    const [invoiceTotalWarning, setInvoiceTotalWarning] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    const [lineSelectStatus, setLineSelectStatus] = React.useState(false);

    const handlePaymentActionChange =(value)=> {
        setPaymentAction(value);
        setSelLinesError(false);
        setInvoiceTotal(0);
        setInvoiceTotalWarning(false);
        if(value===""){
            setPaymentActionError(true);
        }else{
            if(value==='Deposit'){
                setPoFormData([]);
                setInvoiceSelectError(false);
                accountingService.getPOLineByPOId(paymentFormData.poId).then(result=>{
                    if(result.status===200){
                        let tableData = [];
                        if(result.data.length>0){
                            result.data.map((item,index)=>{
                                if(item.status==='normal'){
                                    let itemData = item;
                                    itemData.tableData = { checked: true };
                                    tableData.push(itemData);
                                }
                            })
                        }
                        setPoFormData(tableData);
                        setSelLine(tableData);
                    }
                });
                setInvoiceSelected("");
            }
            else{
                setPoFormData([]);
                setInvoiceSelected("");
                setSelLine([]);
            }
            setPaymentActionError(false);
        }
        
    };

    const handleInvoiceChange =(value)=> {
        if(value!==""){
            setInvoiceSelectError(false);
        }
        else{
            setInvoiceSelectError(true);
        }
        setInvoiceSelected(value);
        invoiceData.map((item,index)=>{
            if(item.invoiceId === value.invoiceId ){
                setPoFormData(item.lines);
                setSelLine(item.lines);
                let total = 0;
                item.lines.map((item)=>{
                    total = total + parseFloat(item.lineTotal);
                })
                setInvoiceTotal(total);
                console.log(total);
            }
        })
    };

    const handleSubmitPOForm =()=> {
        let validated = true;
        if(paymentAction===""){
            // console.log("paymentAction error")
            setPaymentActionError(true);
            validated = false;
        }
        if(paymentAction!==""&&paymentAction!=="Deposit"&&invoiceSelected===""){
            // console.log("invoiceSelected error")
            setInvoiceSelectError(true);
            validated = false;
        }
        if(paymentAmount===""||!checkNumber(paymentAmount)){
            // console.log("paymentAmount error")
            setAmountError(true);
            validated = false;
        }
        if(paymentAction!==""&&invoiceSelected!==""&&selLine.length===0){
            // console.log("selLine error")
            setSelLinesError(true);
            validated = false;
        }
        if(paymentAction==="Deposit"&&selLine.length===0){
            // console.log(" Deposit selLine error")
            setSelLinesError(true);
            validated = false;
        }
        if(!validated){
            return;
        }
        let values={};
        values.paymentAction=paymentAction==='Deposit'?'deposit':paymentAction;
        values.paymentDate=new Date();
        values.invoiceId=invoiceSelected?invoiceSelected.invoiceId:"";
        values.invoiceNumber=invoiceSelected?invoiceSelected.invoiceNumber:"";
        values.paymentAmount = paymentAmount;
        let lines = [];
        selLine.map((item, index) => {
            if(paymentAction.toLowerCase()==='deposit'){
                lines.push(item.lineId);
            }
            else{
                lines.push(item.invoiceLineId);
            }
        })
        values.lineIds = lines;
        values.notes = notes;
        console.log(values)
        accountingService.addPaymentByPOId(paymentFormData.poId,values).then(result=>{
            if(result.status===200){
                accountingService.viewBook(history.location.state.bookId).then(result=>{
                    if(result.status===200){
                        let booktemp = result.data;
                        console.log("---------");
                        console.log(booktemp);
                        if(accountingService.checkPermission("close_book",booktemp)&&Math.abs(parseFloat(booktemp.poDatas[0].outstandingBalance))<parseFloat("0.1000")){
                            accountingService.getPOLineByPOId(result.data.poDatas[0].poId).then(result=>{
                                if(result.status===200){
                                    let notUsedLine = 0;
                                    result.data.map(item=>{
                                        if(item.status==='normal'){
                                            notUsedLine = notUsedLine + 1;
                                        }
                                    })
                                    console.log("----2222-----");
                                    console.log(notUsedLine);
                                    if(notUsedLine>0){
                                        history.push("/PO Transactions/detail/" + history.location.state.bookId);
                                    }
                                    else{
                                        console.log("confirm window open")
                                        setCloseBookConfirmWindow(true);
                                    }
                                }
                            })
                        }
                        else{
                            history.push("/PO Transactions/detail/" + history.location.state.bookId);
                        }
                    }
                })
            }
        })
    };

    const checkNumber = (values) =>{
        let reg =/^\d*(\.\d{1,4})?$/;
        if (values && !reg.test(values)) {
            return false;
        }
        return true;
    }
    
    const goBack = () => {
        history.go(-1);
    }

    const handleCloseBookWindowClose =()=>{
        setCloseBookConfirmWindow(false);
        history.push("/PO Transactions/detail/" + history.location.state.bookId);
    }

    const handleBookClose =()=>{
        setIsLoading(false);
        accountingService.closeBook(history.location.state.bookId).then(result=>{
            if(result.status===200){
                setIsLoading(false);
                setCloseBookConfirmWindow(false);
                history.push("/PO Transactions/detail/" + history.location.state.bookId);
            }
        })
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    return(
    <CommonLayout>
        <div className={classes.poFormBox}>

            <Grid container justifyContent="flex-start" alignItems="center" className={classes.poFormTitle}>
                {/* <div className={classes.link} onClick={goBack}>
                    <UndoIcon color="primary" className={classes.undoIcon} ></UndoIcon>
                </div> */}
                <Typography variant="h6" gutterBottom >
                    {t('paymentPage.addPayment')}
                </Typography>
            </Grid>

            <Grid className={classes.poFormContent}>
                <Grid container alignItems="center" justifyContent="flex-start"  direction="row" className={classes.poFormContentItem}>
                    <Grid item xs={2} className={classes.itemSpacing}>
                        <Grid container>
                            <Grid item xs={12} >
                                <Typography variant="body1"  className={classes.itemTitle}>
                                {t('poFormPage.poNumber')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1"  className={classes.itemContent}>
                                    {paymentFormData.poNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className={classes.itemSpacing}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                                {t('paymentPage.poDate')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemContent}>
                                {moment(paymentFormData.poDate).format("YYYY/MM/DD")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className={classes.itemSpacing}>
                        <Grid item xs={12} className={classes.selectTopItem}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                                {t('paymentPage.currency')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemContent}>
                                {paymentFormData.currency}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.dashedLine}></Grid>

                <Grid container alignItems="center" justifyContent="flex-start"  direction="row" className={classes.poFormContentItem}>
                    <Grid item xs={2} className={classes.itemSpacing}>
                        <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                                {t('paymentPage.paymentActions')} <span className={classes.required}>*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select 
                                fullWidth
                                required={true}
                                error={paymentActionError}
                                variant="outlined"
                                value={paymentAction}
                                onChange={(e)=>handlePaymentActionChange(e.target.value)}
                                classes={{ select: classes.selectField }}
                            >
                                {paymentActionData && paymentActionData.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item==='Deposit'?t('paymentPage.deposit'):item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        </Grid>
                    </Grid>
                    {paymentAction!=='Deposit'&&<Grid item xs={2} className={classes.itemSpacing}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                            {t('paymentPage.invoice')} <span className={classes.required}>*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select 
                                fullWidth
                                disabled={lineSelectStatus}
                                required={true}
                                error={invoiceSelectError}
                                variant="outlined"
                                value={invoiceSelected}
                                onChange={(e)=>handleInvoiceChange(e.target.value)}
                                classes={{ select: classes.selectField }}
                            >
                                {invoiceData && invoiceData.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item.invoiceNumber}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>}
                    <Grid item xs={2} className={classes.itemSpacing}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                            {t('paymentPage.paymentAmount')}<span className={classes.required}>*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="invoice_total_amount_text"
                                required
                                fullWidth
                                margin="none"
                                error={amountError}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={paymentAmount}
                                onChange={(event) => {
                                    setPaymentAmount(event.target.value);
                                    if(checkNumber(event.target.value)){
                                        setAmountError(false);
                                    }
                                    else{
                                        setAmountError(true);
                                    }
                                    if(invoiceTotal>0&&invoiceTotal!==parseFloat(event.target.value)){
                                        setInvoiceTotalWarning(true);
                                    }
                                    else{
                                        setInvoiceTotalWarning(false);
                                    }
                                }}
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems="center" justifyContent="flex-start"  direction="row" className={classes.poFormContentItem}>
                    <Grid item container xs={12}>
                        { poFormData &&<div style={{width:'100%'}}>
                            <MaterialTable
                                title=""
                                icons={tableIcons}
                                columns={
                                    [
                                        { title: t('poFormPage.line'), field: 'lineNumber', },
                                        { title: t('poFormPage.item'), field: 'itemNumber'},
                                        { title: t('poFormPage.product'), field: 'productName'},
                                        { title: t('poFormPage.QTY'), field: 'qty'},
                                        { title: t('poFormPage.price'), field: 'price',},
                                        { title: t('poFormPage.lineTotal'), field: 'lineTotal'},
                                        { title: t('poLineManagement.date'), field: 'lineDate', type: 'date'}
                                    ]
                                }
                                data={poFormData}
                                options={{
                                    actionsColumnIndex: -1,
                                    search: false,
                                }}
                                style={{width:'100%'}} 
                                options={{
                                    selection: !(paymentAction !== ''&paymentAction!=='Deposit'),
                                    toolbar:false,
                                    paging:false,
                                    headerStyle: {
                                        backgroundColor:"transparent"
                                    },
                                }}
                                
                                onSelectionChange={(rows) => {
                                    setSelLine(rows);
                                    if(rows.lenght===0){
                                        setSelLinesError(true);
                                    }
                                    else{
                                        setSelLinesError(false);
                                    }
                                    console.log(rows);
                                }}
                            />
                            { selLinesError===true && <Alert severity="error" style={{marginTop:'6px'}}><Typography>{t('paymentPage.errorTips3')}</Typography></Alert>}
                            { invoiceTotalWarning===true &&<Alert severity="warning" style={{marginTop:'6px'}}><Typography>{t('paymentPage.errorTips1')}</Typography></Alert>}
                            </div>
                        }
                    </Grid> 
                </Grid>

                <Grid container className={classes.dashedLine}>
                        
                </Grid>

                <Grid container alignItems="center" justifyContent="flex-start"  direction="row" className={classes.poFormContentItem}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                                {t('paymentPage.notes')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                rows={4}
                                variant="outlined"
                                onChange = {(event)=>{setNotes(event.target.value)}}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems="center" justifyContent="center"  direction="row" className={classes.poFormContentItemBottom}>
                    <Grid>
                        <LoadingButton isLoading={isLoading}  className={classes.buttonSpacing} classes={{ button: classes.buttonSpacing }} onClick={handleSubmitPOForm}>
                            <Typography variant="subtitle2">
                                {t('common.confirm')}
                            </Typography>
                        </LoadingButton>
                    </Grid>
                    <Grid>
                        <Button variant="contained" className={classes.buttonSpacing} onClick={goBack}>
                            <Typography variant="subtitle2">
                                {t('common.cancel')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Dialog open={closeBookConfirmWindow} onClose={handleCloseBookWindowClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {t('paymentPage.errorTips2')}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleCloseBookWindowClose}>
                        <Typography variant="body2">
                            {t('common.ok')}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            </Grid>
        </div>
    </CommonLayout>
    )
}
