import React, {useEffect, useState, useContext} from 'react';
import {Grid, Select, Paper, TextField, Typography, Button, IconButton, Tooltip, MenuItem} from '@material-ui/core';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LoadingButton from "../../components/LoadingButton";
import organizationService from '../../services/organizationService';
import {ApplicationContext} from "../../context/applicationContext";
import useToken from '../../hooks/useToken';
import OrgRoleReader from "../../utils/orgRoleReader";
import userSort from "../../utils/userSort";
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import {useTranslation} from 'react-i18next';
import moment from "moment";
import Alert from '@material-ui/lab/Alert';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import alipayIcon from '../../assets/img/alipay.svg';
import stripeIcon from '../../assets/img/stripe.svg';
import paymnetService from '../../services/paymentService';
import {loadStripe} from '@stripe/stripe-js';
import {useHistory} from 'react-router-dom';
import paymentIcon from '../../assets/img/dollar-circle-fill.svg';

const useStyles = makeStyles(theme => ({
  orgListsBoxOutterBox: {
    border: theme.currentTheme.border[3],
    padding: "5px 22px 7px 22px",
    marginBottom: "14px",
    borderRadius: "5px",
    backgroundColor: theme.currentTheme.backgrounds[12],
  },
  orgListsInnerBox: {
    overflow: "hidden",
  },
  orgListsOrgName: {
    marginRight: "19px ",
  },
  orgListsServiceName: {
    marginRight: "19px ",
  },
  orgListItemDetailPanel: {
    marginBottom: "13px",
    borderBottom: "none ",
  },
  root: {
    borderBottom: "none ",
    backgroundColor: theme.currentTheme.backgrounds[11],
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titleTextField: {
    marginTop: "8px",
  },
  overflow: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  thBorder: {
    borderBottom: "none ",
  },
  outlinedPadding: {
    padding: '11px 14px',
  },
  marginBottomStyle: {
    marginBottom: '0.8em',
  },
  dialogBox: {
    // backgroundColor: theme.currentTheme.backgrounds[12],
    border: theme.currentTheme.border[3],
  },
  muiInput: {
    padding: "9.5px 7px",
  },
  orgCreateContentBox: {
    marginBottom: '30px'
  }
}))

export default function OrganizationListsItem(props) {
  let timer;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const re_email_format = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const classes = useStyles();
  const {t} = useTranslation();

  const filter = createFilterOptions();

  const {applications, refreshApplications} = useContext(ApplicationContext);

  const {token} = useToken();

  const [showDetail, setShowDetail] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [couponOpen, setCouponOpen] = React.useState(false);
  const [coupon, setCoupon] = React.useState(null);

  const [paymentOpen, setPaymentOpen] = React.useState(false);
  const [delMemOpen, setDelMemOpen] = React.useState(false);
  const [changePackageSuccessOpen, setChangePackageSuccessOpen] = React.useState(false);
  const [upgradeConfirmOpen, setUpgradeConfirmOpen] = React.useState(false);
  const [orgTitle, setOrgTitle] = React.useState("");
  const [selProduct, setSelProduct] = useState("");
  const [addProduct, setAddProduct] = useState([{
    label: t('newOrg.Service1'),
    value: "Open Book"
  }, {label: t('newOrg.Service2'), value: "Open Comm"}, {label: t('newOrg.Service3'), value: "Open Office"}]);
  // const [addProduct, setAddProduct] = useState([{label:t('newOrg.Service1'),value:"Open Book"},{label:t('newOrg.Service2'),value:"Open Comm"}]);
  const [allService, setAllService] = useState([]);
  const [addService, setAddService] = useState([]);
  const [selService, setSelService] = useState([]);
  const [oldSelService, setOldSelService] = useState([]);
  const [selServicePkgs, setSelServicePkgs] = useState('');
  const [selOldServicePkgs, setOldSelServicePkgs] = useState('');
  const [lastCard4, setLastCard4] = useState('');
  const [pkgsValidate, setPkgsValidate] = useState(false);
  const [openBookPackages, setOpenBookPackages] = useState([
    {label: t('newOrg.openbookPackage1'), value: 'month basic package'},
    {label: t('newOrg.openbookPackage2'), value: 'month premium package'},
    // {label:t('newOrg.openbookPackage3'),value:'year basic package/ 5 users'},
    // {label:t('newOrg.openbookPackage4'),value:'year premium package/ 20 users'},
    {label: t('newOrg.servicePackvalueTrial'), value: 'Trial subscription'}]);
  const [liveSourcingPackages, setLiveSourcingPackages] = useState([
    {label: t('newOrg.liveSourcingPackage1'), value: 'month basic package/ 10 users'},
    {label: t('newOrg.liveSourcingPackage2'), value: 'month basic package/ 20 users'},
    {label: t('newOrg.liveSourcingPackage3'), value: 'month basic package/ 30 users'},
    {label: t('newOrg.liveSourcingPackage4'), value: 'month premium package/ 10 users'},
    {label: t('newOrg.liveSourcingPackage5'), value: 'month premium package/ 20 users'},
    {label: t('newOrg.liveSourcingPackage6'), value: 'month premium package/ 30 users'},
    {label: t('newOrg.servicePackvalueTrial'), value: 'Trial subscription'}]);
  const [orgTitleError, setOrgTitleError] = useState(false);
  const [isOrgAdmin, setIsOrgAdmin] = React.useState(false);
  useEffect(() => {
    setIsOrgAdmin(OrgRoleReader.IsOrgAdmin(props.orgListItem.id, props.orgListItem.app_info));
  }, [])

  const [isOrgMgr, setIsOrgMgr] = React.useState(false);
  useEffect(() => {
    setIsOrgMgr(OrgRoleReader.IsOrgManager(props.orgListItem.id, props.orgListItem.app_info));
  }, [])

  const [isOrgMember, setIsOrgMember] = React.useState(false);
  useEffect(() => {
    setIsOrgMember(OrgRoleReader.IsOrgMember(props.orgListItem.id, props.orgListItem.app_info));
  }, [])
  const [addServicePkgsTime, setAddServicePkgsTime] = useState("");

  const [addServicePkgs, setAddServicePkgs] = useState([]);
  useEffect(() => {
    if (selProduct) {
      if (selProduct === "Open Comm") {
        setAddServicePkgs([{
          label: t('newOrg.servicePackComm1'),
          value: 'max 10 meeting attendees'
        }, {label: t('newOrg.servicePackComm2'), value: '10+ meeting attendees'}]);
        setAddServicePkgsTime("30 days trial");
      } else if (selProduct === "Open Book") {
        setAddServicePkgs(openBookPackages);
        setAddServicePkgsTime("90 days trial");
      } else if (selProduct === "Open Office") {
        setAddServicePkgs(liveSourcingPackages);
        setAddServicePkgsTime("90 days trial");
      }
    }
  }, [selProduct])

  const [delPermission, setDelPermission] = useState(true);
  useEffect(() => {
    if (OrgRoleReader.IsOrgAdmin(props.orgListItem.id, props.orgListItem.app_info)) {
      setDelPermission(false);
    } else {
      setDelPermission(true);
    }
  }, [props.orgListItem])

  useEffect(() => {
    if (selProduct) {
      let arr = [];
      let allArr = [];
      applications.forEach(item => {
        if (item.category === selProduct) {
          let arr2 = {app_id: item.id, app_display_name: item.display_name};
          arr2.app_display_name_Lan = arr2.app_display_name;
          if (item.display_name === 'PO Transactions') {
            arr2.app_display_name_Lan = t('newOrg.servicePackage1');
            arr.push(arr2);
          }
          if (item.display_name === 'Inventory') {
            arr2.app_display_name_Lan = t('newOrg.servicePackage2');
            arr.push(arr2);
          }
          if (item.display_name === 'Open Comm') {
            arr2.app_display_name_Lan = t('newOrg.Service2');
            arr.push(arr2);
          }
          if (item.display_name === 'Live Sourcing') {
            arr2.app_display_name_Lan = t('newOrg.servicePackage3');
            arr.push(arr2);
          }

        }
        let allArr2 = {app_id: item.id, app_display_name: item.display_name};
        allArr.push(allArr2);
      })
      setAddService(arr);
      setAllService(allArr);
    }
  }, [selProduct])

  const [addRole, setAddRole] = React.useState([]);
  useEffect(() => {
    if (props.orgListItem && props.orgListItem.id && props.orgListItem.app_info) {
      let admindata = [{
        display_name: t('myOrg.roleValuemember'),
        name: "Org_Member"
      }, {display_name: t('myOrg.roleValuemanager'), name: "Org_Manager"}];
      let mgrdata = [{display_name: t('myOrg.roleValuemember'), name: "Org_Member"}];
      if (OrgRoleReader.IsOrgAdmin(props.orgListItem.id, props.orgListItem.app_info)) {
        setAddRole(admindata);
      } else if (OrgRoleReader.IsOrgManager(props.orgListItem.id, props.orgListItem.app_info)) {
        setAddRole(mgrdata);
      }
      setOrgTitle(props.orgListItem.name);
    }
  }, [])

  const [data, setData] = useState();
  useEffect(() => {
    if (props.orgListItem && props.orgListItem.id && props.orgListItem.users) {
      let newArr = [];
      let newInviter = "";
      let isOrgMem = "";
      let isOrgMgr = "";
      if (props.orgListItem) {
        isOrgMem = OrgRoleReader.IsOrgMember(props.orgListItem.id, props.orgListItem.app_info);
        isOrgMgr = OrgRoleReader.IsOrgManager(props.orgListItem.id, props.orgListItem.app_info);
      }
      props.orgListItem.users.map(item => {
        if (item.email === token.username) {
          newInviter = item.inviter;
        }
      })
      if (isOrgMem) {
        props.orgListItem.users.map(item => {
          if (item.email === token.username || item.email === newInviter) {
            newArr.push(item);
          }
        })
        props.orgListItem["users"] = newArr;
      }
      if (isOrgMgr) {
        props.orgListItem.users.map(item => {
          if (item.email === token.username || item.user_role[0] === "Org_Admin" || (item.user_role[0] === "Org_Member" && item.inviter === token.username)) {
            newArr.push(item);
          }
        })
        props.orgListItem["users"] = newArr;
      }
      props.orgListItem["headCount"] = props.orgListItem.users.length;
      let sortedUsers = userSort.userSortByRole(props.orgListItem.users);
      setData(sortedUsers);
      setSelProduct(props.orgListItem.app_info.category);
      setSelService(props.orgListItem.app_name.split('').join('').split(','));
      setSelServiceIds(props.orgListItem.app_id.split('').join('').split(','));
      setSelServicePkgs(props.orgListItem.service_package);
      setOldSelServicePkgs(props.orgListItem.service_package);
      setOldSelService(props.orgListItem.app_name.split('').join('').split(','));
    }
  }, [props.orgListItem])

  const [emailList, setEmailList] = React.useState([]);

  const [inputEmail, setInputEmail] = React.useState('');

  const [inputTitle, setInputTitle] = React.useState('');

  const [selRoleDisp, setSelRoleDisp] = React.useState('');

  const [emailValidate, setEmailValidate] = React.useState(false);

  const [titleValidate, setTitleValidate] = React.useState(false);

  const [roleValidate, setRoleValidate] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const organizationPage = "/management/services/:service_name/organizations"

  const [isAdded, setIsAdded] = React.useState(true);

  const [inputValues, setInputValues] = React.useState('');

  const [delUserId, setDelUserId] = React.useState('');

  const [delUserName, setDelUserName] = React.useState('');
  const [submitError, setSubmitError] = useState("");
  const [showPayBtn, setShowPayBtn] = useState(false);
  const [currency, setCurrency] = useState('RMB');
  const [currencyList, setCurrencyList] = useState([]);
  const [payment, setPayment] = useState('stripe');

  const handleShowOrgListsDetail = () => {
    if (showDetail) {
      setShowDetail(false);
    } else {
      setShowDetail(true);
    }
  }

  const handleSerPkgsChange = (event) => {
    let temp = event.target.value;
    setSelServicePkgs(temp);
    if (temp === "") {
      setPkgsValidate(true);
    } else {
      setPkgsValidate(false);
    }
  };


  const handleAddEmailChange = (event, newValue) => {
    if (!newValue || !newValue.value || newValue.value === "") {
      setInputEmail("");
      setEmailValidate(true);
    } else {
      setInputEmail(newValue.value);
      setEmailValidate(false);
    }
  }

  const handleAddTitleChange = (event) => {
    setInputTitle(event.target.value);
    if (event.target.value === "") {
      setTitleValidate(true);
    } else {
      setTitleValidate(false);
    }
  }

  const handleRoleSelectChange = (event) => {
    setSelRoleDisp(event.target.value);
    if (event.target.value === "") {
      setRoleValidate(true);
    } else {
      setRoleValidate(false);
    }
  };

  const handleCurrencyChange = (e) => {
    console.log(e.target.value);
    setCurrency(e.target.value);
  }

  const handleEmailInputChange = (event, value) => {
    let userOptions = [];
    if (timer) {
      clearTimeout(timer);
    }
    if (value !== "" && value.length > 2) {
      timer = setTimeout(() => {
        organizationService.getUsersBySearch(value.toLowerCase()).then((result) => {
          if (result && result.data.length > 0) {
            result.data.map((item) => {
              userOptions.push({
                value: item.email,
                label: item.username + " (" + item.email + ")"
              });
            })
            //console.log("ALL EMAIL lIST",userOptions);
            setEmailList(userOptions);
          }
          ;
        })
      }, 500);
    } else {
      let options = [];
      setEmailList(options);
    }
  }

  const validate = () => {
    let temp = false;
    let sameEmail = false;
    data.map(item => {
      if (item.email === inputEmail) {
        sameEmail = true;
      }
    })
    if (!inputEmail || !re_email_format.test(inputEmail) || sameEmail) {
      setEmailValidate(true);
      temp = false;
    }
    if (!inputTitle) {
      setTitleValidate(true);
      temp = false;
    }
    if (!selRoleDisp) {
      setRoleValidate(true);
      temp = false;
    }
    if (inputEmail && inputTitle && selRoleDisp && re_email_format.test(inputEmail) && !sameEmail) {
      temp = true;
    }
    return temp;
  }

  const handleAddOrgListItem = () => {
    if (validate()) {
      let roles = [];
      roles.push(selRoleDisp.name);
      let temp = {email: inputEmail, UserOrganization: {user_title: inputTitle, user_role: roles}};
      addOrgMember(temp);
    }
  }

  const addOrgMember = (value) => {
    setIsAdded(false);
    let obj = {};
    obj["email"] = value.email;
    obj["title"] = value.UserOrganization.user_title;
    obj["role"] = [value.UserOrganization.user_role[0]];
    // console.log("UPDATE ORG MEMBER LIST",obj);
    organizationService.updateOrgMember(props.orgListItem.id, obj).then((result) => {
      if (result.data) {
        setIsAdded(true);
        refreshApplications();
      }
      clearInput();
    }).catch(error => {
      setIsAdded(true);
      clearInput();
      refreshApplications();
    });
  }

  const clearInput = () => {
    //setInputEmail('');
    setInputTitle('');
    setSelRoleDisp('');
    setInputValues('');
  }

  const handleOpenDelMemberDialog = (userId, username) => {
    setDelUserId(userId);
    setDelUserName(username);
    setDelMemOpen(true);
  }

  const handleDelTableItem = () => {
    let arr = {};
    // console.log("DELETE MEMBER ID",delUserId);
    setIsLoading(true);
    if (delUserId && delUserId !== "undefined") {
      arr["userId"] = delUserId;
      organizationService.delOrgMember(props.orgListItem.id, arr).then((result) => {
        if (result.data.code === 200) {
          setDelUserId("");
          setDelUserName("");
          setIsLoading(false);
          setDelMemOpen(false);
          refreshApplications();
        }
      }).catch(error => {
        setDelUserId("");
        setDelUserName("");
        setIsLoading(false);
        setDelMemOpen(false);
        refreshApplications();
      });
    }
  }

  const [selServiceIds, setSelServiceIds] = useState([]);
  // useEffect(()=> {
  //     let appIds=[];
  //     allService.map(item => {
  //         selService.map(newItem =>{
  //             if(item.app_display_name === newItem){
  //                 let arr = item.app_id
  //                 appIds.push(arr);
  //             }
  //         })
  //     })
  //     setSelServiceIds(appIds);
  // },[selService])

  const setAppIdsBySelServices = (services) => {
    let appIds = [];
    console.log(allService);
    allService.map(item => {
      services.map(newItem => {
        if (item.app_display_name === newItem) {
          let arr = item.app_id
          appIds.push(arr);
        }
      })
    })
    setSelServiceIds(appIds);
  }

  useEffect(() => {
    if (selServiceIds !== "" && selServicePkgs !== "" && paymentOpen === true && selServicePkgs !== 'Trial subscription') {
      organizationService.getServicePackages(selServiceIds.toString(), selServicePkgs).then(result => {
        if (result.status === 200) {
          setCurrencyList(result.data);
          if (openBookPackages.some(item => item.value === selServicePkgs) || liveSourcingPackages.some(item => item.value === selServicePkgs)) {
            setShowPayBtn(true);
          } else {
            setShowPayBtn(false);
          }
        }
      })
    }
    if (selServicePkgs === 'max 10 meeting attendees' || selServicePkgs === 'Trial subscription') {
      setShowPayBtn(false);
    }

  }, [selServiceIds, selServicePkgs, paymentOpen])

  const handleEditTableItem = () => {
    let isValid = true;
    if (orgTitle === "") {
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    let org = {
      "name": orgTitle,
      "display_name": orgTitle,
      "description": orgTitle,
    }
    organizationService.updateOrg(props.orgListItem.id, org).then((result) => {
      if (result.data) {
        setSubmitError('');
        refreshApplications();
        handleEditOrgClose();
      }
    }).catch((e) => {
      if (e.response.data.message.indexOf("The org name") > -1 && e.response.data.message.indexOf("is existed, please check") > -1) {
        setSubmitError(t('newOrg.orgExistError'));
      }
    })
  }

  const handleDelMemClose = () => {
    setDelUserId("");
    setDelUserName("");
    setDelMemOpen(false);
  }

  const handleEditOrgClose = () => {
    setOrgTitle(props.orgListItem.name);
    // console.log(props.orgListItem.app_info.app_name);
    setSelService(props.orgListItem.app_name.split('').join('').split(','));
    setAppIdsBySelServices(props.orgListItem.app_name.split('').join('').split(','));
    setSubmitError('');
    setEditOpen(false);
  }

  const handlePaymentClose = () => {
    // console.log(props.orgListItem.app_info.app_name);
    setSelService(props.orgListItem.app_name.split('').join('').split(','));
    setAppIdsBySelServices(props.orgListItem.app_name.split('').join('').split(','));
    setSelServicePkgs(props.orgListItem.service_package);
    setSubmitError('');
    setShowPayBtn(false);
    setPaymentOpen(false);
    setLoading(false);
  }

  const handleDelOrg = () => {
    setIsLoading(true);
    if (props.orgListItem && props.orgListItem.id) {
      organizationService.deleteOrg(props.orgListItem.id).then((result) => {
        if (result.data) {
          setIsLoading(false);
          setOpen(false);
          refreshApplications();
        }
      }).catch(error => {
        setIsLoading(false);
        setOpen(false);
        refreshApplications();
      });
    }
  }

  const handleDelOrgClose = () => {
    setOpen(false);
  }

  const handleCouponClose = () => {
    setCouponOpen(false);
    setSubmitError("");
  }


  const transferName = (beforeName) => {
    if (beforeName === "Org_Admin") {
      return t('myOrg.roleValueadmin');
    } else if (beforeName === "Org_Manager") {
      return t('myOrg.roleValuemanager');
    } else if (beforeName === "Org_Member") {
      return t('myOrg.roleValuemember');
    }
  }

  const tranlateServicePackage = (value) => {
    if (value === 'Trial subscription') {
      return t('newOrg.servicePackvalueTrial');
    }
    if (value === 'Monthly subscription') {
      return t('newOrg.servicePackvalueMonth');
    }
    if (value === 'max 10 meeting attendees') {
      return t('newOrg.servicePackComm1');
    }
    if (value === '10+ meeting attendees') {
      return t('newOrg.servicePackComm2');
    }
    let find = openBookPackages.find(item => item.value === value)
    if (find) {
      return find.label;
    }
    let find1 = liveSourcingPackages.find(item => item.value === value)
    if (find1) {
      return find1.label;
    }
    return value;
  }

  const tranlateService = (value) => {
    if (value === 'Open Comm') {
      return t('newOrg.Service2');
    }
    if (value === 'PO Transactions') {
      return t('newOrg.servicePackage1');
    }
    if (value === 'Inventory') {
      return t('newOrg.servicePackage2');
    }
    if (value === 'PO Transactions,Inventory') {
      return t('newOrg.servicePackage1') + ',' + t('newOrg.servicePackage2');
    }
    if (value === 'Live Sourcing') {
      return t('newOrg.servicePackage3');
    }
    return value;
  }

  const handlePaymentChange = (e) => {
    setPayment(e.target.value);
  }

  const changePaymentSubmitHandler = (orgItem) => {
    if (selService.toString() !== oldSelService.toString() || selServicePkgs !== selOldServicePkgs) {
      setLoading(true);
      if (selOldServicePkgs === 'Trial subscription') {
        let org = {
          "name": orgItem.name,
          "display_name": orgItem.display_name,
          "description": orgItem.description,
          "appIds": selServiceIds,
          "servicePackage": selServicePkgs,
          "product": selProduct,
          "currency": currency,
          "paymentType": payment,
          "successUrl": process.env.REACT_APP_PAYMENT_STRIPE_SUCCESS_URL,
          "failureUrl": process.env.REACT_APP_PAYMENT_STRIPE_FAIL_URL,
          "orgId": orgItem.id
        }
        // console.log("CREATE ORG ITEM", org);
        organizationService.createOrgOrder(org).then((result) => {
          if (result.status === 200) {
            console.log(result.data);
            if (result.data) {
              if (payment === 'stripe') {
                let sessionId = result.data.stripeSessionId;
                loadStripe(process.env.REACT_APP_PAYMENT_STRIPE_PK).then(stripe => {
                  stripe.redirectToCheckout({sessionId: sessionId})
                });
              }
            }
          }
        }).catch((e) => {
          setLoading(false);
          setSubmitError(e.response.data.message);
        })
      } else {
        let order = {
          "appIds": selServiceIds,
          "servicePackage": selServicePkgs,
          "currency": currency,
          "paymentType": payment
        }
        // console.log("CONTACT ORG ITEM", order);
        organizationService.changeOrgService(orgItem.id, order).then((result) => {
          if (result.status === 200) {
            console.log(result.data);
            if (result.data) {
              let timer = setTimeout(() => {
                let status = {
                  "paymentStatus": "paid",
                  "isChange": true
                }
                organizationService.updateOrgOrderStatus(result.data.orderId, status).then(result1 => {
                  if (result1.status === 200) {
                    refreshApplications();
                    setPaymentOpen(false);
                    setLoading(false);
                    setUpgradeConfirmOpen(false);
                    setChangePackageSuccessOpen(true);
                  }
                }).catch((e) => {
                  setLoading(false);
                  setSubmitError(e.response.data.message);
                })
              }, 3000);

            }
          }
        }).catch((e) => {
          setLoading(false);
          setSubmitError(e.response.data.message);
        })
      }
    } else {
      setPaymentOpen(false);
    }

  }

  const handleApplyCoupon = () => {
    let isValid = true;
    if (!coupon || coupon === "") {
      isValid = false;
      setCoupon("");
    }
    if (!isValid) {
      return;
    }
    let data = {
      "orgId": props.orgListItem.id,
      "couponCode": coupon,
    }
    organizationService.applyCoupon(data).then((result) => {
      if (result.data) {
        setCoupon(null);
        setSubmitError("");
        refreshApplications();
        handleCouponClose();
      }
    }).catch((e) => {
      setSubmitError(e.response.data.message);
    })
  }

  const getPaymentValues = (orgId) => {
    organizationService.getPaymentOrdersByOrgId(orgId).then(result => {
      if (result.status === 200 && result.data.length > 0) {
        setCurrency(result.data[0].currency);
        setLastCard4(result.data[0].cardLast4);
      }
    })
  }

  const handleChangePackageSuccessClose = () => {
    setChangePackageSuccessOpen(false);
  }
  const handleUpgradeConfirmOpenClose = () => {
    setUpgradeConfirmOpen(false);
  }


  return (
    <Grid key={props.orgListItem.id}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={12} className={classes.orgListsBoxOutterBox}>
          <Grid item container xs={9} justifyContent="flex-start" alignItems="center"
                className={classes.orgListsInnerBox} onClick={handleShowOrgListsDetail}>
            <Grid item className={classes.orgListsOrgName}>
              <Typography variant="body2">
                {props.orgListItem.companyOrg && <span style={{color: 'red'}}>[{t('myOrgtab.companyOrg')}]</span>}
                {props.orgListItem.display_name}
                {!delPermission ? "(" + props.orgListItem.headCount + ")" : ""}
              </Typography>
            </Grid>
            <Grid item className={classes.orgListsServiceName}>
              <Typography variant="body2">{tranlateService(props.orgListItem.app_name)}</Typography>
            </Grid>
            <Grid item style={{marginRight: '19px'}}>
              {props.orgListItem.service_package !== 'Trial subscription' &&
                <Typography variant="body2">{tranlateServicePackage(props.orgListItem.service_package)}</Typography>}
            </Grid>
            <Grid item>
              {(props.orgListItem.service_package === 'Trial subscription' || props.orgListItem.service_package === 'max 10 meeting attendees') &&
                <Typography
                  variant="body2">{t('myOrg.expireDate')}:{moment(props.orgListItem.expire_date).format("YYYY/MM/DD")}</Typography>}
            </Grid>
          </Grid>

          <Grid item container xs={2} justifyContent="flex-end" alignItems="center">
            {(props.orgListItem.app_info.category === 'Open Book' || props.orgListItem.app_info.category === 'Open Office') &&
              <Tooltip title={t('newOrg.payButton')}>
                <div>
                  <IconButton onClick={() => {
                    setPaymentOpen(true);
                    getPaymentValues(props.orgListItem.id);
                  }} disabled={delPermission}>
                    <img src={paymentIcon}/>
                  </IconButton>
                </div>
              </Tooltip>}
            <Tooltip title={t('myOrg.editOrgHint')}>
              <div>
                <IconButton onClick={() => {
                  // console.log(props.orgListItem);
                  setEditOpen(true);
                }} disabled={delPermission}>
                  <EditIcon/>
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={t('myOrg.deleteOrgHint')}>
              <div>
                <IconButton onClick={() => {
                  setOpen(true)
                }} disabled={delPermission}>
                  <DeleteIcon/>
                </IconButton>
              </div>
            </Tooltip>
          </Grid>

          <Grid item container xs={1} justifyContent="flex-end" alignItems="center" onClick={handleShowOrgListsDetail}>
            {
              showDetail ?
                <ExpandLessIcon></ExpandLessIcon>
                :
                <ExpandMoreIcon></ExpandMoreIcon>
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction='column' className={classes.organizationContent}
              style={{display: showDetail ? 'flex' : 'none'}}>
          <Grid item xs={12}>
            {!isOrgMember &&
              <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start">
                <Grid item xs={5}>
                  <Grid item>
                    <Typography variant='body2' gutterBottom className={classes.overflow}>
                      {t('myOrg.searchEmail')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      freeSolo
                      options={emailList}
                      getOptionLabel={(option) => option && option.label ? option.label : ''}
                      filterSelectedOptions
                      size="small"
                      onChange={handleAddEmailChange}
                      onInputChange={handleEmailInputChange}
                      renderInput={(params) => (
                        <TextField {...params} onKeyDown={e => {
                          if (e.keyCode === 13) {
                            e.stopPropagation();
                            e.preventDefault();
                          }
                        }} margin="dense" error={emailValidate} variant="outlined" size="small"
                        />
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        let keyword = params.inputValue.toLowerCase();
                        if (re_email_format.test(keyword)) {
                          filtered.push({
                            value: keyword,
                            label: `Invite ("${keyword}") to join openuse.io`,
                          });
                        }
                        return filtered;
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={4}>
                  <Grid item>
                    <Typography variant='body2' gutterBottom>
                      {t('myOrg.Title')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      required={true}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={titleValidate}
                      variant="outlined"
                      className={classes.titleTextField}
                      onChange={handleAddTitleChange}
                      size="small"
                      value={inputTitle}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid item>
                    <Typography variant='body2' className={classes.marginBottomStyle}>
                      {t('myOrg.Role')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Select
                      fullWidth
                      required={true}
                      variant="outlined"
                      value={selRoleDisp}
                      size="small"
                      onChange={handleRoleSelectChange}
                      error={roleValidate}
                      classes={{select: classes.outlinedPadding}}
                    >
                      {addRole.map(item => (
                        <MenuItem key={item.name} value={item}>
                          {item.display_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid item xs={1}>
                  <Typography variant='body2' gutterBottom>
                    &nbsp;
                  </Typography>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <IconButton variant="contained" onClick={handleAddOrgListItem} disabled={!isAdded}>
                      <AddCircleIcon fontSize="medium"></AddCircleIcon>
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            }

            <Grid item container xs={12}>
              {data &&
                <TableContainer component={Paper} className={classes.orgListItemDetailPanel}>
                  <Table aria-label="simple table" size="small" classes={{root: classes.root,}}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.thBorder}>{t('myOrg.Email')}</TableCell>
                        <TableCell className={classes.thBorder}>{t('myOrg.Title')}</TableCell>
                        <TableCell className={classes.thBorder}>{t('myOrg.Status')}</TableCell>
                        <TableCell className={classes.thBorder}>{t('myOrg.Role')}</TableCell>
                        <TableCell className={classes.thBorder}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item, index) => (
                        <TableRow key={item.email + index}>
                          <TableCell component="th" scope="item"
                                     className={classes.thBorder}>{item.username + "(" + item.email + ")"}</TableCell>
                          <TableCell className={classes.thBorder}>{item.user_title}</TableCell>
                          <TableCell
                            className={classes.thBorder}>{item.user_status === 'Invited' ? t('myOrg.status1') : t('myOrg.status2')}</TableCell>
                          <TableCell
                            className={classes.thBorder}>{item.user_role[0] ? transferName(item.user_role[0]) : ''}</TableCell>
                          <TableCell className={classes.thBorder}>
                            {item && item.email && item.user_role[0]
                            && (item.user_role[0] === "Org_Manager" && isOrgAdmin)
                            || (item.user_role[0] === "Org_Member" && isOrgAdmin)
                            || (item.user_role[0] === "Org_Member" && isOrgMgr)
                            || (!isOrgAdmin && item.email === token.username)
                              ? (
                                <Tooltip title={t('myOrg.deleteMemberHint')}>
                                  <IconButton onClick={() => handleOpenDelMemberDialog(item.UserId, item.email)}>
                                    <DeleteIcon/>
                                  </IconButton>
                                </Tooltip>
                              )
                              : ('')
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleDelOrgClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography variant="body1">
            {t('delOrganization.delOrg')}
          </Typography>
        </DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDelOrgClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <Typography variant="body2">
            {t('delOrganization.infoMsg')}({props.orgListItem.display_name})?
          </Typography>
          <Typography variant="body2" style={{marginTop: '8px'}}>
            {t('delOrganization.infoMsg1')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton isLoading={isLoading} variant="contained" color="primary" onClick={handleDelOrg}>
            <Typography variant="body2">
              {t('delOrganization.button1')}
            </Typography>
          </LoadingButton>
          <Button variant="contained" onClick={handleDelOrgClose}>
            <Typography variant="body2">
              {t('delOrganization.button2')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={delMemOpen} onClose={handleDelMemClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title"
              className={classes.dialogBox}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="body1">
            {t('delorgMember.delMember')}
          </Typography>
        </DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDelMemClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            {t('delorgMember.infoMsg')}({delUserName})?
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton isLoading={isLoading} variant="contained" color="primary" onClick={handleDelTableItem}>
            <Typography variant="body2">
              {t('delorgMember.button1')}
            </Typography>
          </LoadingButton>
          <Button variant="contained" onClick={handleDelMemClose}>
            <Typography variant="body2">
              {t('delorgMember.button2')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={handleEditOrgClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title"
              className={classes.dialogBox}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="body1">
            {t('editOrganization.editOrg')}
          </Typography>
        </DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleEditOrgClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <Grid className={classes.orgCreateContentBox}>
            <Grid item>
              <Grid container style={{margin: '8px 0 4px 0'}}>
                <Typography variant="body2">
                  {t('editOrganization.orgTitle')}
                </Typography>
                <Typography variant="body2" className={classes.required}>
                  *
                </Typography>
              </Grid>

              <TextField
                id="standard-full-width"
                required={true}
                fullWidth
                // margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                value={orgTitle}
                size="small"
                error={orgTitle === ""}
                onChange={e => {
                  setOrgTitle(e.target.value);
                  if (e.target.value === "") {
                    setOrgTitleError(true);
                  } else {
                    setOrgTitleError(false);
                  }
                }}
                variant="outlined"
                className={classes.textField}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          {submitError !== "" &&
            <Grid container alignItems="center" justifyContent="center" direction="row" style={{marginTop: '8px'}}>
              <Alert severity="error" style={{marginBottom: '6px'}}><Typography>{submitError}</Typography></Alert>
            </Grid>}
          <Button variant="contained" color="primary" onClick={handleEditTableItem}>
            <Typography variant="body2">
              {t('editOrganization.button1')}
            </Typography>
          </Button>
          <Button variant="contained" onClick={handleEditOrgClose}>
            <Typography variant="body2">
              {t('editOrganization.button2')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={paymentOpen} onClose={handlePaymentClose} maxWidth="sm" fullWidth
              aria-labelledby="form-dialog-title" className={classes.dialogBox}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="body1">
            {t('newOrg.payButton')}
          </Typography>
        </DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handlePaymentClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <Grid className={classes.orgCreateContentBox}>
            <Grid item>
              <Grid container style={{margin: '8px 0 4px 0'}}>
                <Typography variant="body2" gutterBottom>
                  {t('editOrganization.serCat')}
                </Typography>
                <Typography variant="body2" gutterBottom className={classes.required}>
                  *
                </Typography>
              </Grid>
              <Select
                fullWidth
                required={true}
                variant="outlined"
                size="small"
                // error={proValidate}
                // onChange={handlerProductChange}
                value={selProduct}
                className={classes.textField}
                disabled={true}
                classes={{select: classes.muiInput}}
              >
                {addProduct && addProduct.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <Typography variant="body2">
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item>
              <Grid container style={{margin: '8px 0 4px 0'}}>
                <Typography variant="body2" gutterBottom>
                  {t('editOrganization.service')}
                </Typography>
                <Typography variant="body2" gutterBottom className={classes.required}>
                  *
                </Typography>
              </Grid>
              <Select
                fullWidth
                multiple
                required={true}
                variant="outlined"
                size="small"
                value={selService}
                error={!selService || selService.length === 0}
                className={classes.textField}
                //disabled={!selProduct || servicePer}
                // disabled={!selProduct}
                classes={{select: classes.muiInput}}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setSelService(e.target.value);
                  setAppIdsBySelServices(e.target.value);
                }}
                renderValue={(selected) => selected.length > 0 ? selected.join(',') : ''}
                MenuProps={MenuProps}
              >
                {addService && addService.map(item => (
                  <MenuItem key={item.app_id} value={item.app_display_name}
                            disabled={oldSelService.length > 0 && oldSelService.indexOf(item.app_display_name) > -1}>
                    <Checkbox style={{color: "#ff5722"}}
                              checked={selService.length > 0 && selService.indexOf(item.app_display_name) > -1}/>
                    <ListItemText primary={item.app_display_name_Lan}/>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Grid container style={{margin: '8px 0 4px 0'}}>
                <Typography variant="body2" gutterBottom>
                  {t('newOrg.servicePackage')}
                </Typography>
                <Typography variant="body2" gutterBottom className={classes.required}>
                  *
                </Typography>
              </Grid>
              <Select
                fullWidth
                required={true}
                variant="outlined"
                size="small"
                value={selServicePkgs}
                error={pkgsValidate}
                defaultValue={addServicePkgs[0]}
                onChange={handleSerPkgsChange}
                className={classes.textField}
                disabled={!selProduct || !(selService && selService.length > 0)}
                classes={{select: classes.muiInput}}
              >
                {addServicePkgs && addServicePkgs.map(item => (
                  <MenuItem key={item.label} value={item.value}>
                    <Typography variant="body2">
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {showPayBtn && <Grid item>
              <Grid container direction="row" style={{margin: '8px 0 4px 0'}}>
                <FormControl component="fieldset">
                  <RadioGroup row aria-label="gender" name="gender1" value={currency} onChange={handleCurrencyChange}>
                    {currencyList && currencyList.map((item, index) => (
                      <Grid item key={index}><FormControlLabel value={item.currency} control={<Radio
                        disabled={selOldServicePkgs !== 'Trial subscription'}/>}
                                                               label={item.currency + " " + item.totalPrice}/></Grid>
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>}
            {showPayBtn && <Grid item>
              <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={payment} onChange={handlePaymentChange}>
                  <Grid container alignItems="center">
                    <Grid item><FormControlLabel value="stripe" control={<Radio
                      disabled={selOldServicePkgs !== 'Trial subscription'}/>} label=""/></Grid>
                    <Grid item><img width="80px" src={stripeIcon}></img></Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          {submitError !== "" &&
            <Grid container alignItems="center" justifyContent="center" direction="row" style={{marginTop: '8px'}}>
              <Alert severity="error" style={{marginBottom: '6px'}}><Typography>{submitError}</Typography></Alert>
            </Grid>}
          {selOldServicePkgs === 'Trial subscription' &&
            <LoadingButton isLoading={loading} variant="contained" color="primary" onClick={() => {
              setCouponOpen(true);
              setPaymentOpen(false);
            }}>
              <Typography variant="body2">
                {t('editOrganization.coupon')}
              </Typography>
            </LoadingButton>}
          <Button variant="contained" color="primary" onClick={() => {
            if (selService.toString() !== oldSelService.toString() || selServicePkgs !== selOldServicePkgs) {
              if (showPayBtn) {
                setUpgradeConfirmOpen(true);
              } else {
                let org = {
                  "appIds": selServiceIds,
                }
                organizationService.updateOrg(props.orgListItem.id, org).then((result) => {
                  if (result.data) {
                    setSubmitError('');
                    refreshApplications();
                    setPaymentOpen(false);
                  }
                }).catch((e) => {
                  setSubmitError(e.response.data.message);
                })
              }
            } else {
              setPaymentOpen(false);
            }
          }}>
            <Typography variant="body2">
              {t('newOrg.payButton')}
            </Typography>
          </Button>
          <Button variant="contained" onClick={handlePaymentClose}>
            <Typography variant="body2">
              {t('editOrganization.button2')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={couponOpen} onClose={handleCouponClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title"
              className={classes.dialogBox}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="body1">
            {t('editOrganization.coupon')}
          </Typography>
        </DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCouponClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <Grid className={classes.orgCreateContentBox}>
            <Grid item>

              <TextField
                id="standard-full-width"
                required={true}
                fullWidth
                // margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                value={coupon}
                size="small"
                error={coupon === "" ? true : false}
                onChange={e => {
                  setCoupon(e.target.value);
                }}
                variant="outlined"
                className={classes.textField}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {submitError !== "" &&
            <Grid container alignItems="center" justifyContent="center" direction="row" style={{marginTop: '8px'}}>
              <Alert severity="error" style={{marginBottom: '6px'}}><Typography>{submitError}</Typography></Alert>
            </Grid>}
          <Button variant="contained" color="primary" onClick={handleApplyCoupon}>
            <Typography variant="body2">
              {t('editOrganization.button1')}
            </Typography>
          </Button>
          <Button variant="contained" onClick={handleCouponClose}>
            <Typography variant="body2">
              {t('editOrganization.button2')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={changePackageSuccessOpen} onClose={handleChangePackageSuccessClose} maxWidth="sm" fullWidth
              aria-labelledby="form-dialog-title" className={classes.dialogBox}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="body1">
            {t('myOrg.paySuccessTitle')}
          </Typography>
        </DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleChangePackageSuccessClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            {t('myOrg.paySuccessContent')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleChangePackageSuccessClose}>
            <Typography variant="body2">
              {t('common.ok')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={upgradeConfirmOpen} onClose={handleUpgradeConfirmOpenClose} maxWidth="sm" fullWidth
              aria-labelledby="form-dialog-title" className={classes.dialogBox}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="body1">
            {t('myOrg.payConfirmTitle')}
          </Typography>
        </DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleUpgradeConfirmOpenClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            {selOldServicePkgs === 'Trial subscription' ? t('myOrg.payConfirmContent3') : t('myOrg.payConfirmContent1') + " " + lastCard4 + " " + t('myOrg.payConfirmContent2')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton isLoading={loading} variant="contained"
                         onClick={() => changePaymentSubmitHandler(props.orgListItem)}>
            <Typography variant="body2">
              {t('common.confirm')}
            </Typography>
          </LoadingButton>
          <Button variant="contained" onClick={handleUpgradeConfirmOpenClose}>
            <Typography variant="body2">
              {t('common.cancel')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
