import { makeStyles } from '@material-ui/core/styles';

const GlobalStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
            padding: '0',
            margin: '0',
            fontFamily: "Baskerville-Regular, BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
            transition: 'all 0.25s linear',
            background: theme.currentTheme.background,
            color: theme.currentTheme.text
        },
        h1: {
            color: theme.currentTheme.text
        },
        '.MuiFormLabel-root.Mui-focused': {
            color: '#000000 !important',
            backgroundColor: '#ffffff !important',
        },
        '.MuiFilledInput-root.Mui-focused': {
            color: '#000000 !important',
            backgroundColor: '#ffffff !important',
        }
    }
}));



export default GlobalStyles;