import { useEffect, useState,forwardRef, useImperativeHandle } from 'react';
import meetingService from '../../services/meetingService';
import MeetingDetail from './MeetingDetail';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import MeetingItem from './MeetingItem';
import { makeStyles } from '@material-ui/core/styles';
import { ContactSupportTwoTone } from '@material-ui/icons';
import noMeetingContentImg from '../../assets/img/noMeetingContent.png'

const useStyles = makeStyles({
    meetingListTitle: {
      marginBottom:'20px'
    },
    noMeetingContent:{
        width:'100%', 
        textAlign:'center',
        minHeight:'405px', 
        display:'block'
    }
  });

export default forwardRef((props, ref) => {
    const [meetings, setMeetings] = useState([]);
    const [currentMeeting, setCurrentMeeting] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const selectedDate = props.date;

    useEffect(()=>{
        window.addEventListener('resize', closeDetailHandler);
        return ()=> {
            window.removeEventListener('resize', closeDetailHandler);
        }
    },[]);

    //const orgId = props.orgId;
    useEffect(()=>{
        if(props.date && props.orgId){
            getMeetingList();
        }
    },[props.date, props.orgId]);

    useImperativeHandle(
        ref,
        () => ({
            refreshMeetingList() {
                if(props.date && props.orgId){
                    getMeetingList();
                }
            },
            getMeetingList()
            {
                return meetings;
            }
        }),

    )
    function meetingItemClickHandler(meeting,element){
        setAnchorEl(element);
        setCurrentMeeting(meeting);
        setShowDetail(true);
    }

    function refreshMeetingListHandler(){
        getMeetingList();
    }

    function closeDetailHandler(){
        setShowDetail(false);
    }

    function getMeetingList(){
        meetingService.getMyMeetingsByDate(new Date(selectedDate).toString(),props.orgId).then((result)=>{
            if(result.data && result.data.length >= 0){
                const filtered = result.data.filter((item)=>{
                    return parseInt(item.status) > 0;
                })
                setMeetings(filtered);
            }
            
        });
    }

    return (
        <div>
            <Typography variant="body1" className={classes.meetingListTitle}>
            {new Date().toDateString()=== new Date(selectedDate).toDateString()?'Today\'s':selectedDate} Schedule 
            </Typography>
            <div>
                    { meetings && meetings.map((item)=>{
                       return ( 
                       <MeetingItem meet={item} key={item.id} refreshMeetingList={refreshMeetingListHandler} onClick={meetingItemClickHandler}></MeetingItem>)
                    })}

                    { meetings && meetings.length===0&&
                        <div className={classes.noMeetingContent}>
                            <div style={{width:'70%', margin:'auto'}}>
                                <img src={noMeetingContentImg} width='90%' style={{marginBottom:'35px', marginTop:'50px'}}>
                                </img>
                                <Typography variant="body1">Click <b>New Meeting</b> to share the smooth meeting experience with others</Typography>
                            </div>
                        </div>
                    }
            </div>
            {currentMeeting&&<MeetingDetail showDetail={showDetail} meet={currentMeeting} anchorEl={anchorEl} onClose={closeDetailHandler} onRefresh={getMeetingList}></MeetingDetail>}
        </div>
    )
})