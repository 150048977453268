import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => (
{
    pageContainer: {
        display: 'flex',
        padding: '45px',
        maxWidth: '1200px',
        width: '100%',
    },
    subMenu: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        borderRadius: '8px',
        border: theme.currentTheme.border[3],
        minWidth: '200px',
        height: 'max-content',
        overflowY: 'auto'
    },
    subMenuItem: {
        color: theme.currentTheme.colors[0],
        backgroundColor: 'transparent',
        fontSize: '18px',
        fontWeight: '600',
        position: 'relative',
        padding: '20px 30px',
        cursor: 'pointer',
        outline: 'none'
    },
    subMenuItemSelected: {
        cursor: 'default !important',
        '& h6': {
            fontWeight: '600 !important',
        }
    },
    itemSelectedIndicator: {
        backgroundColor: '#FF5722',
        width: '2px',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0
    },
    manageContent: {
        flex: 1,
        height: '100%',
        marginLeft: '30px',
        borderRadius: '8px',
        overflowY: 'auto'
    }
}));

export default styles;
