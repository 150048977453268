import { useState,useEffect,useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import notificationService from '../../services/notificationService';
import moment from 'moment';
import socketIOClient from 'socket.io-client';
import useToken from '../../hooks/useToken';

const useStyles = makeStyles(theme=> ({
    notificaion:{
        marginTop:"5px",
        width:"30%",
        minWidth:"400px",
        border: theme.currentTheme.border[3],
        backgroundColor: theme.currentTheme.backgrounds[10],
        minHeight:"600px;"
    },
    notificaionPanel:{
        padding:"0px 15px"
    },
    notificaionTitle:{
        textAlign:"center",
    }, 
    notificaionContent:{
        minHeight:"500px"
    },
    hrBorder:{
        border: theme.currentTheme.border[3],
    },
    contentItem:{
        padding:"10px",
    }
}))

export default function Notification(props){
    const ref = useRef();
    const classes = useStyles();
    const { token } = useToken();
    const [messageList, _setMessageList] = useState([]);
    const messageListRef = useRef(messageList);
    const setMessageList = (data)=>{
        messageListRef.current = data;
        _setMessageList(data);
    }
    const endpoint=process.env.REACT_APP_NOTIFICATION_SOCKET_BASE;
    const tokenSocket='Opendais ' + token.access_token
    const url = `${endpoint}/?token=${tokenSocket}`;
    
    
    useEffect(()=>{
        const socket = socketIOClient(url);

        notificationService.getUnReadMessages().then((result)=>{
            setMessageList(result.data.datas);
            props.onUpdate(result.data.total);

        });

        socket.on('pushMessage', (data) => {
            console.log('++++++++++++start recive data from service +++++++++++++++++++');
            let arr = messageListRef.current;
            arr.unshift(JSON.parse(data));
            setMessageList(arr);
            console.log(arr);
            props.onUpdate(arr.length);
            console.log('++++++++++++++++++++=======end ++++++++++++++++++++++++');
        });
    },[])

    useOnClickOutside(ref, () => props.onClose());
    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              // Do nothing if clicking ref's element or descendent elements
              if (!ref.current || !ref.current.parentNode || ref.current.parentNode.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
      }

    

    function hideNotification(){
        props.onClose();
    }
    function readNotification(){
        let mlist = [];
        messageList.map(item=>mlist.push(item.id));
        notificationService.readMessages({"msgIds":mlist}).then((result)=>{
            if(result.status===200){
                setMessageList([]);
                props.onUpdate(0);
            }
        });
    }
    return (
        <Popover
                id="notificaion"
                open={props.show}
                anchorEl={document.getElementById("header")}
                anchorOrigin={{
                vertical:'bottom',
                horizontal:'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                classes={{paper:classes.notificaion}}
            >
        <div className={classes.notificaionPanel} ref={ref}>
            <Grid container alignItems="center">
                <Grid item xs={10}>
                    <Typography variant="body1" style={{textAlign:"center",paddingLeft:"60px"}}>Notification</Typography>
                </Grid>
                <Grid item xs={2} style={{textAlign:"right"}}>
                    <IconButton aria-label="close" onClick={hideNotification}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                </Grid>
            </Grid>
            <hr style={{marginTop:"0px"}} className={classes.hrBorder}></hr>
            <div className={classes.notificaionContent}>
            { messageList && messageList.map((item, index)=>{
                       return ( 
                           <div key={index}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" style={{textAlign:"left",color:"#818181"}}>{new moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{textAlign:"left"}}>
                                        <Typography variant="body1" style={{textAlign:"left"}}>{item.msgContent.message}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(index < messageList.length-1)&&<hr className={classes.hrBorder}></hr>}
                                    </Grid>
                                </Grid>
                            </div>
            )})}   
            </div>
                <Grid container alignItems="center">
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <hr style={{marginBottom:"0px"}} className={classes.hrBorder}></hr> 
                        <IconButton aria-label="close" onClick={readNotification}>
                            <DeleteForeverOutlinedIcon fontSize="large"></DeleteForeverOutlinedIcon>
                        </IconButton>
                    </Grid>
                </Grid>
        </div> 
        </Popover>
    )
}