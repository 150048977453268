import React,{useContext}  from 'react';
import {
    Link, useParams, useHistory
} from "react-router-dom";
import { Component, useEffect, useState, useRef} from 'react';
import { Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useToken from '../../hooks/useToken';

// import 'react-big-calendar/lib/css/react-big-calendar.css';

// import 'react-big-calendar/lib/sass/styles.scss';
import './calendar-custom.scss';

import './booking-schdule.css';

import meetingService from '../../services/meetingService';
import MeetingDetail from "../../components/booking/MeetingDetail";
import Typography from '@material-ui/core/Typography';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '30px',
        maxWidth: '1200px',
        width: '100%',
    },
    box: {
        width: '100%',
    }
}))
const localizer = momentLocalizer(moment)

export default function BookingSchdule() {

    const classes = useStyles();
    const [myEventsList, setMyEventsList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [currentMeeting, setCurrentMeeting] = useState(null);
    const theme = useTheme();
    const [cStart, setCStart] = useState(null);
    const [cEnd, setCEnd] = useState(null);
    useEffect(() => {
        if(cStart&&cEnd){
            getMeetingList(cStart,cEnd);
        }
    },[cStart,cEnd])

    useEffect(() => {
        let now = new Date();
        console.log(now);
        setCStart(getFirstDateOfWeek(now));
        setCEnd(getLastDateOfWeek(now));
    },[])

    const getFirstDateOfWeek = (theDate) => {
        var firstDateOfWeek;
        theDate.setDate(theDate.getDate() - theDate.getDay()); //    
        firstDateOfWeek = theDate;
        const startTime = new Date(firstDateOfWeek.getFullYear(),firstDateOfWeek.getMonth(),firstDateOfWeek.getDate(),0,0,0).toISOString();
        console.log(startTime);
        return startTime;    
    }

    const getLastDateOfWeek = (theDate) => {
        var lastDateOfWeek;
        theDate.setDate(theDate.getDate() + 6 - theDate.getDay()); //    
        lastDateOfWeek = theDate;
        const endTime = new Date(lastDateOfWeek.getFullYear(),lastDateOfWeek.getMonth(),lastDateOfWeek.getDate(),23,59,59).toISOString();
        console.log(endTime);
        return endTime;    
    }
    const handleRangeChange = (values)=>{
        console.log(values)
        let startTime, endTime
        //判断选择的是week或者day(week and day retrun array)
        if (values instanceof Array){
            if(values.length>1){
                startTime = values[0];
                endTime = values[6];
            }
            else{
                const inputDate = new Date(values[0])
                startTime = new Date(inputDate.getFullYear(),inputDate.getMonth(),inputDate.getDate(),0,0,0).toString();
                endTime = new Date(inputDate.getFullYear(),inputDate.getMonth(),inputDate.getDate(),23,59,59).toString();
            }
        }
        else{
            startTime= values.start;
            endTime = values.end;
        }
        setCStart(startTime);
        setCEnd(endTime);
    }
    const closeDetailHandler = ()=>{
        setShowDetail(false);
    }
    const detailRefreshHandler = ()=>{
        getMeetingList(cStart,cEnd);
    }
    const handleOpenMeetingItem = (event,SyntheticEvent) =>{
        setAnchorEl(SyntheticEvent.currentTarget);
        setCurrentMeeting(event);
        setShowDetail(true);
    }

    function getMeetingList(startTime,endTime){
        meetingService.getMyMeetingsByRange(startTime,endTime).then((result)=>{
            if(result.data && result.data.length >= 0){
                const filtered = result.data.filter((item)=>{
                    item.start = new Date(item.startTime);
                    let d = new Date(item.start);
                    d.setMinutes ( d.getMinutes() + item.duration );
                    item.end =  d;
                    item.allDay = false;
                    return parseInt(item.status) > 0;
                })
                setMyEventsList(filtered);
            }
            
        });
    }

    const dayPropGetter = (date) => {
        const CURRENT_DATE = moment().toDate();
        let backgroundColor;
        
        let d1 = moment(cStart);
        let d2 = moment(cEnd);
        if(d2.diff(d1,"day")>7){
            if (moment(date).isBefore(d1.add(7,'days').toDate(), "month")) {
                backgroundColor = theme.currentTheme.backgrounds[9];
            }
            if (moment(date).isAfter(d2.add(-7,'days').toDate(), "month")) {
                backgroundColor = theme.currentTheme.backgrounds[9];
        }}
        if (moment(date).isSame(CURRENT_DATE, "day")) {
            backgroundColor = theme.currentTheme.backgrounds[8];
        }
        if (moment(date).isSame(CURRENT_DATE, "day")) {
            backgroundColor = theme.currentTheme.backgrounds[8];
        }
        var style = {
            backgroundColor
        };
        return {
            style: style
        };
    }

    return (
        <div className={classes.root}>
            <Calendar
                localizer={localizer}
                views={['month', 'week' , 'day']}
                defaultView = {'week'}
                events={myEventsList}
                startAccessor="start"
                endAccessor="end"                                
                style={{ height: 800 }}
                onRangeChange={handleRangeChange}
                step={60}
                timeslots={1}
                onSelectEvent={handleOpenMeetingItem}
                scrollToTime={moment().set({ h: 7, m: 0 }).toDate()}
                dayPropGetter={dayPropGetter}
            />
            {currentMeeting&&<MeetingDetail showDetail={showDetail} meet={currentMeeting} anchorEl={anchorEl} onClose={closeDetailHandler} onRefresh={detailRefreshHandler}></MeetingDetail>}
        </div>
    )
}
