import React, {forwardRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';

import {Button, Dialog, DialogActions, DialogTitle, Grid, TextField, Typography} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import MaterialTable from 'material-table'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import CommonLayout from "../../components/CommonLayout";
import LoadingButton from "../../components/LoadingButton";
import accountingService from "../../services/accountingService";
import {useTranslation} from 'react-i18next';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {v4 as uuidv4} from "uuid";
import BaoguanDialog from "./baoguanDialog";

const useStyles = makeStyles(theme => ({
    root: {
        borderBottom: "none ",
        fontSize: "medium",
        backgroundColor: theme.currentTheme.backgrounds[11],
    },
    buttonCol: {
        color: theme.currentTheme.colors[0],
    },
    lineDateInput: {
        '& > *': {
            '& > *': {
                textAlign: "center"
            },
        },
    },
    poFormBox: {
        '@media (min-width:600px)':  {
            padding: "4vw",
        },
    },
    poFormTitle: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "28px 0 20px 23px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[2],
    },
    poFormContent: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "0 40px 0 40px",
        '@media (max-width:600px)':  {
            padding: "0 24px 0 24px",
        },
    },
    poFormContentItem: {
        padding: "20px 0 0 0",
    },
    poFormContentItemBottom: {
        padding: "20px 0 30px 0",
    },
    textField: {
        margin: "4px 0 0 0",
        width:'100%',
    },
    fieldOverflow: {
        '& > *': {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
    selectField: {
        padding: "11px 14px",
    },
    selectTopItem: {
        marginTop: "12px",
    },
    itemTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    itemContent: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginTop: "12px"
    },
    required: {
        color: "#fd4747",
        display: "inline",
    },
    dashedLine: {
        margin: "23px 0 0 0",
        borderTop: "1px solid #dbdbdb",
        width: "100%",
    },
    buttonSpacing: {
        width: "150px",
        marginLeft: "10px",
    }
}))

export default function BatchShippingAddPage(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const tableIcons = {
        Add: forwardRef((props, ref) =><AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [delBtnDisabled, setDelBtnDisabled] = useState(false);
    const [foreverLineData, setForeverLineData] = useState([]);
    const [invoiceLineData, setInvoiceLineData] = useState([]);
    const [poFormData, setPoFormData] = React.useState([]);
    const [shippingAddressFrom, setShippingAddressFrom] = useState("");
    const [shippingAddressTo, setShippingAddressTo] = useState("");
    const [attachmentFormData, setAttachmentFormData]= useState([]);
    const [baoguanOpen, setBaoguanOpen] = useState(false);
    const [shippingIds, setShippingIds] = useState([]);

    const [lineData, setLineData] = React.useState([]);
    useEffect(() => {
        if (history.location.state && history.location.state.selectedLines){
            setDelBtnDisabled(true);
            let tempLines = history.location.state.selectedLines;
            tempLines.map(item => {
                item.qty = item.shippingOutstanding;
            });
            tempLines.forEach((item) => {item.checked = null});
            setPoFormData(tempLines);
        }
    }, []);

    const [selLine, setSelLine] = React.useState("");

    const [lineSelectStatus, setLineSelectStatus] = React.useState(false);
    useEffect(() => {
        if (lineData && lineData.length > 0) {
            setLineSelectStatus(false);
        } else {
            setLineSelectStatus(true);
        }
    }, [lineData]);

    const [invoiceNumber, setInvoiceNumber] = useState("");

    const [invoiceDate, setInvoiceDate] = useState("");
    useEffect(() => {
        setInvoiceDate(moment().format("YYYY/MM/DD"));
    }, []);

    const [invoiceTotalAmount, setInvoiceTotalAmount] = useState("");
    useEffect(() => {
        if (poFormData) {
            let data = 0;
            poFormData.map(item => {
                if (item.rowStatus) {
                    data += Math.round((item.lineTotal) * 10000) / 10000;
                } else {
                    data += Math.round((item.lineOutstanding) * 10000) / 10000;
                }
            })
            setInvoiceTotalAmount(data);
        }
    }, [poFormData]);

    const [customerId, setCustomerId] = useState("");
    const [notes, setNotes] = useState("");
    const [invoiceNumberError, setInvoiceNumberError] = useState(false);
    const [invoiceDateError, setInvoiceDateError] = useState(false);
    const [selectError, setSelectedError] = useState(false);
    const [shippingAddressFromError, setShippingAddressFromError] = useState(false);
    const [shippingAddressToError, setShippingAddressToError] = useState(false);
    const [invoiceHelp, setInvoiceHelp] = useState(false);
    const [lineTotalBalOpen, setLineTotalBalOpen] = useState(false);

    const handlerLineTotalBalClose = (e) => {
        setLineTotalBalOpen(false);
    };

    const handleInvoiceNumberChange = (e) => {
        setInvoiceNumber(e.target.value);

        if (e.target.value === "") {
            setInvoiceNumberError(true);
            setInvoiceHelp(false);
        } else if (checkDuplication(e.target.value)) {
            setInvoiceNumberError(true);
            setInvoiceHelp(true);
        } else {
            setInvoiceNumberError(false);
            setInvoiceHelp(false);
        }
    };

    const checkDuplication = (value) => {
        for (let i = 0; i < invoiceLineData.length; i++) {
            if (invoiceLineData[i]["invoiceNumber"] === value) {
                return true;
            }
        }
        return false;
    };

    const handleInvoiceDateChange = (date) => {
        const momentDate = moment(date).format("YYYY/MM/DD");
        setInvoiceDate(momentDate);
        setInvoiceDateError(date === "");
    };

    const shippingAddressFromChangeHandler = (e) => {
        setShippingAddressFromError(e.target.value === "");
        setShippingAddressFrom(e.target.value);
    }

    const purchaserIdChangeHandler = (e) => {
        setCustomerId(e.target.value);
    };

    const shippingAddressToChangeHandler = (e) => {
        setShippingAddressToError(e.target.value === "");
        setShippingAddressTo(e.target.value);
    }

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    };
    const handleLineChange = (e) => {
        setSelLine(e.target.value);
    };

    const handleRowDelete = (oldData) => {
        let copy_poFormData = [...poFormData];
        let copy_lineData = [...lineData];
        let oldDataIndex = oldData.tableData.id;

        foreverLineData.map(item => {
            if (item.lineId === oldData.lineId) {
                copy_lineData.push(item);
                setLineData(copy_lineData);

                copy_poFormData.splice(oldDataIndex, 1);
                setPoFormData(copy_poFormData);
            }
        })
    };

    const handleRowUpdate = (newData, oldData) => {
        const dataUpdate = [...poFormData];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setPoFormData([...dataUpdate]);
        if(newData.rowStatus &&  parseInt(newData.qty)  > parseInt(oldData.shippingOutstanding) ){
            setLineTotalBalOpen(true);
        }

    };

    const validate = () => {
        let temp = false;
        if (invoiceNumber === "" || invoiceNumber === undefined) {
            setInvoiceNumberError(true);
            temp = false;
        }
        if (invoiceDate === "" || invoiceDate === undefined) {
            setInvoiceDateError(true)
            temp = false;
        }

        if (shippingAddressFrom === "" || shippingAddressFrom === undefined) {
            setShippingAddressFromError(true)
            temp = false;
        }
        if (shippingAddressTo === "" || shippingAddressTo === undefined) {
            setShippingAddressToError(true)
            temp = false;
        }
        if (invoiceNumber && invoiceDate && shippingAddressTo && shippingAddressFrom) {
            temp = true;
        }

        if (poFormData && poFormData.length > 0) {
            setSelectedError(false);
        } else {
            setSelectedError(true);
        }
        return temp;
    };

    const handleShipping = (nextStep) => {
        let temp = {}
        let count = 0;
        let shippingData = [];
        poFormData.map(item => {
            let foundIndex = shippingData.findIndex(item1 => item1.poId===item.poId);
            temp = {
                lineId: item.lineId,
                lineNumber: item.lineNumber,
                itemNumber: item.itemNumber,
                itemRev: item.itemRev ? item.itemRev : "",
                productName: item.productName,
                qty: item.qty,
                unit: item.unit ? item.unit : "",
                notes: notes,
            }
            if (foundIndex >= 0) {
                shippingData[foundIndex].lines.push(temp);
            } else {
                let newPoRow = {};
                newPoRow.poId = item.poId;
                newPoRow.lines = [];
                newPoRow.lines.push(temp);
                shippingData.push(newPoRow);
            }
            count = count + parseInt(temp.qty);
        })
        let data = {
            wayBill: invoiceNumber,
            totalQty: count,
            shippingDate: invoiceDate,
            shippingFrom: !shippingAddressFrom ? "" : shippingAddressFrom,
            shippingTo: shippingAddressTo ? shippingAddressTo : "",
            notes: notes,
            shippingDatas: shippingData,
        }
        if (validate()) {
          if (attachmentFormData && attachmentFormData[0] && attachmentFormData[0].file) {
            setIsLoading(true);
            let formData = new FormData();
            formData.append("attachment", attachmentFormData[0].file);
            accountingService.attach4Shipping(formData).then((result) => {
              if (result.data) {
                data.attachments = [];
                data.attachments.push(
                  {
                    fileId: result.data.fileId,
                    fileName: result.data.fileName,
                  }
                );
                submitShipping(data, nextStep);
              } else {
                setIsLoading(false);
              }
            });
          } else {
            setIsLoading(true);
            submitShipping(data, nextStep);
          }
        }
    };

    const submitShipping = (data, nextStep) => {
        accountingService.addBatchShipping(data).then(
            result => {
                setIsLoading(false);
                if (result.data) {
                    nextStep? nextStep(result.data): goBack();
                }
            }
        );
    }

    const handleShippingAndDeclaration = () => {
        handleShipping((data)=> {
            const ids = data.map(item => {
                return item.shippingId
            });
            setShippingIds(ids);
            setBaoguanOpen(true);
        });
    }

    const onBaoguanReturn = () => {
        setBaoguanOpen(false);
        goBack();
    }

    const goBack = () => {
        history.go(-1);
    };

    const formatData = (value) => {
        return parseFloat(value).toFixed(4).replace(/d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
    };

    const checkNumber = (values) => {
        let reg = /^\d*(\.\d{1,4})?$/;
        return !(values && !reg.test(values));
    };

  const handleFileChange = (e) => {

    let tmp = [];
    let files = e.currentTarget.files;
    for (let i=0; i<files.length; i++) {
      tmp.push({
        id: uuidv4(),
        name: files[i].name,
        file: files[i],
      })
    }

    setAttachmentFormData(tmp);
  };

  const cancelAttachment = (id) => {
    let tmp = [];
    attachmentFormData.forEach((attachment) => {
      if (attachment.id !== id) {
        tmp.push(attachment);
      }
    });
    setAttachmentFormData(tmp);
  }

    return (
      <CommonLayout>
        <div className={classes.poFormBox}>

          <Grid container justifyContent="flex-start" alignItems="center" className={classes.poFormTitle}>
            <Typography variant="h6" gutterBottom>
              {t('shippingPage.addShipping')}
            </Typography>
          </Grid>

          <Grid container className={classes.poFormContent}>

            <div style={{display: 'flex', width: '100%', height: '80px', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-start', }}>

              <input
                id="file"
                type="file"
                style={{display: 'none'}}
                onChange={handleFileChange}
              />

              <label htmlFor="file" style={{minWidth: '140px'}}>
                <Button variant="contained" color="primary" component="span">
                  <AttachFileIcon/>
                  <Typography variant="subtitle2" style={{marginLeft: '4px'}}>
                    {t('common.attach')}
                  </Typography>
                </Button>
              </label>

              <div style={{overflowY: 'auto', flexWrap: 'wrap', maxHeight: '80px', flexGrow: 1, display: 'flex', flexDirection: 'row'}}>

                {

                  attachmentFormData.map((attachment) => {
                    return (
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Typography variant="body1" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          {attachment.name}
                          {
                            attachment &&
                            <IconButton onClick={() => cancelAttachment(attachment.id)} style={{marginLeft: '-10px', alignItems: 'flex-end'}}>
                              <Tooltip title={t('common.cancel')}>
                                <HighlightOffIcon style={{width: '20px', height: '20px'}} />
                              </Tooltip>
                            </IconButton>

                          }
                        </Typography>
                      </div>
                    );
                  })

                }

              </div>
            </div>

            <Grid container spacing={2} justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.itemTitle}>
                      {t('shippingPage.shippingNumber')} <span className={classes.required}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="invoice_number_text"
                      value={invoiceNumber}
                      error={invoiceNumberError}
                      required={true}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      size="small"
                      className={[classes.textField, classes.fieldOverflow].join(' ')}
                      onChange={handleInvoiceNumberChange}
                      helperText={invoiceHelp ? t('shippingPage.existedInvoiceNumber') : ""}
                    />
                    <span style={{color: '#ff4d4f'}}>
                      {invoiceHelp}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.itemTitle}>
                    {t('shippingPage.shippingDate')} <span className={classes.required}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      id="date"
                      variant="inline"
                      inputVariant="outlined"
                      format="YYYY/MM/DD"
                      value={invoiceDate}
                      size="small"
                      className={classes.textField}
                      onChange={date => handleInvoiceDateChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" justifyContent="flex-start" direction="row"
                  className={classes.poFormContentItem}>
              <Grid item xs={12} sm={6}>
                <div>
                  <Typography variant="body1" className={classes.itemTitle}>
                    {t('shippingPage.shippingAddressFrom')}<span className={classes.required}>*</span>
                  </Typography>
                </div>
                <div>
                  <TextField
                    id="purchaser_tin_text"
                    error={shippingAddressFromError}
                    value={shippingAddressFrom}
                    required={true}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    className={[classes.textField, classes.fieldOverflow].join(' ')}
                    onChange={shippingAddressFromChangeHandler}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <Typography variant="body1" className={classes.itemTitle}>
                    {t('shippingPage.shippingAddressTo')}<span className={classes.required}>*</span>
                  </Typography>
                </div>
                <div>
                  <TextField
                    id="supplier_tin_text"
                    error={shippingAddressToError}
                    value={shippingAddressTo}
                    required={true}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    className={[classes.textField, classes.fieldOverflow].join(' ')}
                    onChange={shippingAddressToChangeHandler}
                  />
                </div>
              </Grid>

            </Grid>

            <Grid container className={classes.dashedLine}>

            </Grid>

            <Grid item xs={10}>
            </Grid>

            <Grid container alignItems="center" justifyContent="flex-start" direction="row"
                  className={classes.poFormContentItem} style={{overflowX: "auto", width: "100%"}}>
              <MaterialTable
                icons={tableIcons}
                style={{minWidth: "900px"}}
                columns={
                  [
                    {
                      title: t('poFormPage.poNumber') + '*', field: 'poNumber', editable: 'onAdd',
                      validate: rowData => rowData.poNumber !== '' && rowData.poNumber !== undefined,
                      cellStyle: {
                        width: '15%',
                        textAlign: "center",
                        padding: "4px"
                      },
                    },
                    {
                      title: t('poFormPage.line') + '*', field: 'lineNumber', editable: 'onAdd',
                      validate: rowData => rowData.lineNumber !== '' && rowData.lineNumber !== undefined,
                      cellStyle: {
                        width: '5%',
                        textAlign: "center",
                        padding: "4px"
                      },
                    },
                    {
                      title: t('poFormPage.item') + '*', field: 'itemNumber', editable: 'onAdd',
                      validate: rowData => rowData.itemNumber !== '' && rowData.itemNumber !== undefined,
                      cellStyle: {
                        textAlign: "center",
                        padding: "4px"
                      },
                    },
                    {
                      title: t('poFormPage.rev'), field: 'itemRev', editable: 'onAdd',
                      cellStyle: {
                        textAlign: "center",
                        padding: "4px"
                      },
                    },
                    {
                      title: t('poFormPage.product') + '*', field: 'productName', editable: 'onAdd',
                      validate: rowData => rowData.productName !== '' && rowData.productName !== undefined,
                      cellStyle: {
                        textAlign: "center",
                        padding: "4px"
                      },
                    },
                    {
                      title: t('poFormPage.storageUnit'), field: 'storageUnit', editable: 'onAdd',
                      cellStyle: {
                        textAlign: "center",
                        padding: "4px"
                      },
                    },
                    {
                      title: t('poFormPage.QTY') + '*', field: 'qty',
                      validate: rowData => rowData.qty !== '' && rowData.qty !== undefined && checkNumber(rowData.qty),
                      render: rowData => formatData(rowData.qty),
                      cellStyle: {
                        width: '10%',
                        textAlign: "center",
                        padding: "4px"
                      },
                      editComponent: props => (
                        <TextField
                          id="qty_text"
                          value={props.rowData.qty}
                          error={!props.rowData.qty || props.rowData.qty === ""}
                          required={true}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          onChange={e => {
                            props.onRowDataChange({
                              ...props.rowData,
                              lineTotal: e.target.value * props.rowData.price,
                              qty: e.target.value,
                              rowStatus: true,
                            })
                          }}
                        >
                        </TextField>
                      ),
                    },
                    {
                      title: t('poFormPage.unit'), field: 'unit', editable: 'onAdd',
                      cellStyle: {
                        textAlign: "center",
                        padding: "4px"
                      },
                    },
                  ]
                }
                data={poFormData}
                localization={{
                  header: {
                    actions: t('poFormPage.actions')
                  },
                  body: {
                    deleteTooltip: t("common.delete"),
                    editTooltip: t("common.edit"),
                    emptyDataSourceMessage: t("common.noRecord"),
                  },
                }}
                options={{
                  actionsColumnIndex: -1,
                  search: false,
                  toolbar: false,
                  paging: false,
                  sorting: false,
                  cellStyle: {
                    padding: "4px",
                    textAlign: "center",
                  },
                  headerStyle: {
                    padding: "4px",
                    textAlign: "center",
                    backgroundColor: "transparent"
                  },
                }}
                editable={{
                  isDeletable: rowData => !history.location.state.poLine,
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, _) => {
                      handleRowUpdate(newData, oldData);
                      resolve();
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      handleRowDelete(oldData);
                      resolve();
                    }),

                }}
              />
            </Grid>

            <Grid container className={classes.dashedLine}>

            </Grid>

            <Grid container alignItems="center" justifyContent="flex-start" direction="row"
                  className={classes.poFormContentItem}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.itemTitle}>
                    {t('invoicePage.notes')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    value={notes}
                    multiline
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    minRows={4}
                    variant="outlined"
                    onChange={handleNotesChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container alignItems="center" justifyContent="center" direction="row"
                  className={classes.poFormContentItemBottom}>
              <Grid>
                <LoadingButton isLoading={isLoading} color="primary" className={classes.buttonSpacing}
                               classes={{button: classes.buttonSpacing}} onClick={()=>handleShipping()}>
                  <Typography variant="subtitle2">
                    {t('shippingPage.btnShipping')}
                  </Typography>
                </LoadingButton>
              </Grid>

                <Grid>
                    <LoadingButton isLoading={isLoading} color="primary" className={classes.buttonSpacing}
                                   classes={{button: classes.buttonSpacing}} onClick={handleShippingAndDeclaration}>
                        <Typography variant="subtitle2">
                            {t('shippingPage.btnShippingAndDeclare')}
                        </Typography>
                    </LoadingButton>
                </Grid>
              <Grid>
                <Button variant="contained" className={classes.buttonSpacing} onClick={goBack}>
                  <Typography variant="subtitle2">
                    {t('common.cancel')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Dialog open={lineTotalBalOpen} onClose={handlerLineTotalBalClose} maxWidth="sm" fullWidth
                    aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">
                <Typography variant="body1">
                  {t('shippingPage.errorTips1')}
                </Typography>
              </DialogTitle>
              <DialogActions>
                <Button variant="contained" color="primary" onClick={handlerLineTotalBalClose}>
                  <Typography variant="body2">
                    {t('common.ok')}
                  </Typography>
                </Button>
              </DialogActions>
            </Dialog>

              {baoguanOpen && <BaoguanDialog shippingIds={shippingIds} declarationInfo={null}
                                             onSuccess={onBaoguanReturn}
                                             onCancel={onBaoguanReturn}></BaoguanDialog>}

          </Grid>
        </div>
      </CommonLayout>
    )
}
