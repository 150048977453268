import { makeStyles } from '@material-ui/core/styles';
import {Typography, Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '15% 20%',
    },
    hintsText: {
        color: theme.currentTheme.colors[3],
    },
    submitButton: {
        height: '40px !important',
        fontSize: '20px !important',
        marginTop: '30px'
    },
}));

export default function RegisterErrorPage(props){
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.container}>
            <Typography variant='h2' gutterBottom>
                Sorry! The link is invalid.
            </Typography>
            <Button variant="contained" color="primary"
                    className={classes.submitButton}
                    onClick={()=> history.push('/register')}>
                Sign up without invitation
            </Button>
        </div>
    )
}