import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {
  FormControl,
  FormHelperText,
  OutlinedInput,
} from '@material-ui/core';
import useToken from '../../hooks/useToken';
import userService from '../../services/userService';
import LoadingButton from "../../components/LoadingButton";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.currentTheme.backgrounds[10],
    border: theme.currentTheme.border[3],
    borderRadius: '8px'
  },
  box: {
    padding: '30px 30px 10px 30px',
    color: theme.currentTheme.colors[0],
  },
  box1: {
    padding: '30px',
  },
  text: {
    color: theme.currentTheme.colors[0],
  },
  text2: {
    color: theme.currentTheme.colors[5],
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '40px',
  },
  buttonProgress: {
    color: '#0e78f9',
    position: 'absolute',
    top: '50%',
    left: '0',
    marginTop: -12,
    marginLeft: 5,
  },
  errorMsg: {
    color: '#ff4d4f',
    marginTop: '20px',
  },
  msg: {
    color: '#0e78f9',
    marginTop: '20px',
  },
  input: {
    border: theme.currentTheme.border[3],
    backgroundColor: '#fafbfc',
    color: 'black',
    borderRadius: '4px',
  },
  inputErrMsg: {
    color: '#ff4d4f'
  },
  hrColor:{
    border: theme.currentTheme.border[3],
  }
}));

export default function PersonalProfile() {
  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const {token, setToken} = useToken();

  const [displayName, setDisplayName] = React.useState(token.user_info.username);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const handleChange = (prop) => (event) => {
    setDisplayName(event.target.value);
    validate({[prop]: event.target.value});
  };

  const validate = (fieldValues = displayName) => {
    let temp = {...errors};
    if ('displayName' in fieldValues) {
      temp.displayName = fieldValues.displayName ? '' : 'This field is required.';
    }
    setErrors({
      ...temp
    });
  }

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
      setMessage('');

      let temp = {...errors};
      temp.changeDisplayNameError = ''

      if (!displayName) {
        temp.displayName = 'This field is required.';
      }

      setErrors({
        ...temp
      });

      if (temp && temp.displayName) {
        setLoading(false);
        return;
      }

      userService.updateDisplayName(token.username, displayName)
        .then(result => {
          console.log('---> result: ', result);
          token.user_info.username = displayName;
          setToken(token);
          setLoading(false);
        }).catch(err => {
          console.log('---> err: ', err);
          setLoading(false);
      });
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant='body2' gutterBottom className={classes.box}>
        {t('myProfile.personalProfile')}
      </Typography>
      <hr className={classes.hrColor}/>
      <form className={classes.box1} noValidate autoComplete='off'>
        <div>
          <Typography variant='body2' gutterBottom className={classes.text}>
          {t('myProfile.Name')} *
          </Typography>

          <FormControl variant='outlined' size='small' fullWidth>
            <OutlinedInput
              id='outlined-display-name'
              // className={classes.input}
              value={displayName}
              onChange={handleChange('displayName')}
              onBlur={handleChange('displayName')}
              {...(errors['displayName'] && {error: true})}
            />
            {errors['displayName'] && (<FormHelperText><Typography className={classes.inputErrMsg} variant='caption'
                                                                   gutterBottom>{errors['displayName']}</Typography></FormHelperText>)}
          </FormControl>
        </div>
        <Typography variant='body2' gutterBottom className={classes.text2} style={{marginTop: '20px'}}>
        {t('myProfile.infoMsg')}
        </Typography>
        <div style={{marginTop: '20px'}}>
          <Typography variant='body2' gutterBottom className={classes.text}>
          {t('myProfile.Email')}
          </Typography>
          <Typography style={{marginTop: '10px'}} variant='body2' gutterBottom className={classes.text}>
            {token.username}
          </Typography>
        </div>
        {errors['changeDisplayNameError'] && (<Typography className={classes.errorMsg} variant='body2' gutterBottom>{errors['changeDisplayNameError']}</Typography>)}
        {message && (<Typography className={classes.msg} variant='body2' gutterBottom>{message}</Typography>)}
        <div className={classes.buttonDiv}>
          <LoadingButton
            variant='contained'
            isLoading={loading}
            onClick={handleButtonClick}>
            {t('myProfile.updateButton')}
          </LoadingButton>
        </div>
      </form>
    </div>
  );
}


