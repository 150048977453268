import {axiosCore as axios} from './axiosHelper';

const getPendingApprovals = function() {
    return new Promise(function (resolve, reject) {
        axios({
            url: '/auth/admin/pending-approval',
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "accept": "*/*"
            }
        }).then(function (result) {
            resolve(result.data)
        }).catch(error => {
            console.warn('getPendingApprovals error: ', error)
            resolve({code: "400", message: 'Verify company failed'})
        })
    })
}

const approve = function(email) {
    return new Promise(function (resolve, reject) {
        axios({
            url: '/auth/admin/approve',
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "accept": "*/*"
            },
            data: {
                email: email
            }
        }).then(function (result) {
            resolve(result.data)
        }).catch(error => {
            console.warn('approve error: ', error)
            resolve(null)
        })
    })
}
const reject = function(email) {
    return new Promise(function (resolve, reject) {
        axios({
            url: '/auth/admin/reject',
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "accept": "*/*"
            },
            data: {
                email: email
            }
        }).then(function (result) {
            resolve(result.data)
        }).catch(error => {
            console.warn('reject error: ', error)
            resolve(null)
        })
    })
}

const getUserList = function(searchContent) {
    return new Promise(function (resolve, reject) {
        axios({
            url: '/auth/admin/user-list',
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "accept": "*/*"
            },
            params:{
                "search_text": searchContent
            }
        }).then(function (result) {
            resolve(result.data)
        }).catch(error => {
            console.warn('getUserList error: ', error)
            resolve({code: "400", message: 'GetUserList failed'})
        })
    })
}

export default {
    getPendingApprovals,
    getUserList,
    approve,
    reject
}