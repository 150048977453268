import moment from 'moment';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import Button from '@material-ui/core/Button';
import { useState,useEffect,useRef} from 'react';
import {
    useParams,useHistory
} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import useToken from '../../hooks/useToken';
import meetingService from '../../services/meetingService';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MeeingEdit from './MeetingEdit';

const useStyles = makeStyles(theme=> ({
    meetingDetailPanel:{
        width:'100%',
    },
    detailMeeting:{
        minWidth:'500px',
        backgroundColor: theme.currentTheme.backgrounds[10],
        border: theme.currentTheme.border[3],
        borderRadius:'8px',
        padding: '20px 12px 15px 20px',
    },
    detailTitle:{
        color:"#818181"
    }
}))

export default function MeetingDetail(props){
    const [isOganizer, setIsOganizer] = useState(false);
    const { token } = useToken();
    const [ editDetail, setEditDetail ] = useState(false);

    const history = useHistory();
    const classes = useStyles();
    const ref = useRef();

    useEffect(()=>{
        if(props.meet && token && token.username === props.meet.organizer){
            setIsOganizer(true);
        }
        else{
            setIsOganizer(false);
        }
    },[props,token])

    // useOnClickOutside(ref, () => props.onClose());
    // function useOnClickOutside(ref, handler) {
    //     useEffect(
    //       () => {
    //         const listener = (event) => {
    //           // Do nothing if clicking ref's element or descendent elements
    //           if (!ref.current || ref.current.contains(event.target)) {
    //             return;
    //           }
    //           handler(event);
    //         };
    //         document.addEventListener("mousedown", listener);
    //         document.addEventListener("touchstart", listener);
    //         return () => {
    //           document.removeEventListener("mousedown", listener);
    //           document.removeEventListener("touchstart", listener);
    //         };
    //       },
    //       [ref, handler]
    //     );
    //   }

    function getMeetingUrl(){
        return process.env.REACT_APP_BOOTH_VIDEO_URL + props.meet.conferenceId;
    }
    function getMeetingTime(){
        const endTime = moment(props.meet.startTime).add(props.meet.duration,'minutes');
        return moment(props.meet.startTime).format("HH:mm") + ' ~ ' + endTime.format("HH:mm");
    }
    function jumpToBoothVideo(){
        if(props.meet && props.meet.requests && props.meet.requests.length > 0){
            history.push({pathname:'/requests/'+props.meet.id+"/"+props.meet.conferenceId, state: props.meet});
        }else{
            window.open(getMeetingUrl(),'_blank');
        }
    }
    function getLocalDate(){
        return moment(props.meet.startTime).format('YYYY-MM-DD');
    }


    function cancelMeeting(){
        meetingService.cancelMeeting(props.meet.id).then((result)=>{
            props.onRefresh();
            props.onClose();
        })
    }

    function getMeetingUrl(){
        return process.env.REACT_APP_BOOTH_VIDEO_URL + props.meet.conferenceId;
    }

    function hideDetail(){
        props.onClose();
    }
    function handleRefresh(){
        props.onRefresh();
    }
    function openEdit(){
        props.onClose();
        setEditDetail(true);
    }
    function editDetailCloseHandler(){
        setEditDetail(false);
    }
    return (
        <div>
        <MeeingEdit open={editDetail} method="update" meet={props.meet} onClose={editDetailCloseHandler} onRefresh={handleRefresh}></MeeingEdit>

        <Popover
                id="detail"
                open={props.showDetail}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                classes={{paper:classes.detailMeeting}}
            >
        <div className={classes.meetingDetailPanel} ref={ref}>
            <div className="hbox center title_medium font_blue" style={{width:"100%"}}>
                <div style={{width:"8%"}}>
                    <VideoCallIcon/> 
                </div>
                <div style={{width:"72%"}}><Typography variant="body1">{props.meet.title}</Typography></div>
                <div style={{width:"10%"}}>
                    {isOganizer && props.meet.status === 1 && <IconButton aria-label="close" onClick={openEdit}>
                        <EditIcon />
                    </IconButton>}
                </div>
                <div style={{width:"10%"}}>
                    <IconButton aria-label="close" onClick={hideDetail}>
                        <CloseIcon />
                    </IconButton>
                </div>

            </div>
            
            <div className="vbox">
                <Typography variant="body2" gutterBottom={true} className="text_large">{getLocalDate()} {getMeetingTime()}</Typography>
            </div>
            <div className="hbox">
                {   props.meet.status === 1 && <Button type="primary" variant="contained" color="primary"
                    onClick={jumpToBoothVideo}
                    style={{width:"160px",height:"47px",borderRadius:"10px",marginRight:'12px',marginTop:'20px',marginBottom:'20px'}}>Join the meeting</Button>
                }
                {
                    isOganizer && props.meet.status === 1 && 
                    <Button variant="outlined" color="secondary"
                    onClick={cancelMeeting}
                    style={{width:"160px",height:"47px",borderRadius:"10px",marginRight:'12px',marginTop:'20px',marginBottom:'20px'}}>Cancel the meeting</Button>
                }
            </div>
            <div className="vbox">
                <Typography gutterBottom={true} className={classes.detailTitle}>Agenda</Typography>
                {props.meet.agenda && props.meet.agenda.split(',').map((item,index)=>{
                    return (
                        <Typography gutterBottom={true} className="text_large" variant="body2" key={item}>{(index+1)+'. '+item}</Typography>
                    )
                })}
                
            </div>
            <div className="vbox">
                <Typography gutterBottom={true} className={classes.detailTitle}>Host</Typography>
                <Typography gutterBottom={true} variant="body2" className="text_large">{props.meet.host}</Typography>
            </div>
            <div className="vbox">
                <Typography gutterBottom={true} className={classes.detailTitle}>Participants</Typography>
                {
                    props.meet.participants && props.meet.participants.split(',').map((item)=>{
                        return (
                            <Typography gutterBottom={true} variant="body2" className="text_large" key={item}>{item}</Typography>
                        )
                    })
                }
            </div>
            {props.meet.status === 1 &&<div className="vbox">
                <Typography gutterBottom={true} className={classes.detailTitle}>Meeting link</Typography>
                <a href={getMeetingUrl()} target='_blank'>{getMeetingUrl()}</a>
            </div>}
        </div>
        
        </Popover>
        </div>
    )
}