import CommonLayout from "../../components/CommonLayout";
import React, {useContext} from 'react';
import {
  useHistory
} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  DialogTitle,
  Collapse
} from '@material-ui/core';

import {useEffect, useState} from 'react';
import useToken from '../../hooks/useToken';
import IconButton from '@material-ui/core/IconButton';
import {ApplicationContext} from "../../context/applicationContext";
import OrgRoleReader from "../../utils/orgRoleReader";
import orgRoleReader from "../../utils/orgRoleReader";
import openbookDescriptionPic1 from '../../assets/img/openbook_d_1.png';
import openbookDescriptionPic2 from '../../assets/img/openbook_d_2.png';
import openbookDescriptionPic3 from '../../assets/img/openbook_d_3.png';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';
import Pagination from '@material-ui/lab/Pagination';
import accountingService from "../../services/accountingService";
import SearchIcon from '@material-ui/icons/Search';
import OrganizationSelect from "../../components/OrganizationSelect";
import moment from 'moment';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import {useTranslation} from 'react-i18next';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import {AlertTitle} from "@material-ui/lab";
import organizationService from "../../services/organizationService";
import {LinkOff} from "@material-ui/icons";
import CustomerSelector from "../../components/CustomerSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '50px'
  },
  box: {
    padding: '30px',
    '@media (max-width:960px)': {
      padding: '16px',
    },
  },
  dialogRoot: {
    minHeight: '500px',
    marginTop: '10px',
  },
  dialogHr: {
    border: '1px',
    dashed: '#000',
    marginTop: '20px',
    marginBottom: '20px'
  },
  required: {
    color: '#981414',
  },
  outlinedPadding: {
    padding: '11px 14px',
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  newBook: {
    border: '2px dashed red',
    borderRadius: '4px',
    maxWidth: '200px',
    minHeight: '201px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  bookItem: {
    border: theme.currentTheme.border[3],
    maxWidth: '200px',
    minHeight: '201px',
    borderRadius: '4px',
    backgroundColor: theme.currentTheme.backgrounds[10],
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: theme.currentTheme.backgrounds[11]
    }
  },

  bookItemTitle: {
    paddingLeft: '8px',
    marginTop: '12px',
    "& p": {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '90%'
    }
  },

  bookItemPo: {
    paddingLeft: '8px',
    marginTop: '5px',

  },

  bookItemBalance: {
    padding: '4px',
  },
  detailBook: {
    minWidth: '200px',
    backgroundColor: 'none',
    border: theme.currentTheme.border[3],
    borderRadius: '8px',
    padding: '20px 12px 15px 20px',
    lineHeight: '70px'
  },
  popover: {
    pointerEvents: 'none',
  },
  balanceTable: {
    tableLayout: 'fixed',
    "& th": {
      backgroundColor: theme.currentTheme.theme2backgrounds[1],
      "& p": {fontFamily: 'IBMPlexSans-Bold'},
      "& a": {color: theme.currentTheme.text}
    },
    "& tr:nth-child(even)": {
      backgroundColor: theme.currentTheme.theme2backgrounds[1],
      height: '30px'
    },
    "& tr:nth-child(odd)": {
      backgroundColor: theme.currentTheme.theme2backgrounds[2],
      height: '30px'

    },
    "& p": {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  },
  selectField: {
    padding: "10px 10px",
  },
  input: {
    fontFamily: "Lato",
    fontSize: "15px",
    width: '300px',
  },
}));

export default function Main() {
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const {token} = useToken();
  const history = useHistory();
  const [initing, setIniting] = React.useState(true);
  const [orgExpired, setOrgExpired] = React.useState(false);
  const [openCreateOrg, setOpenCreateOrg] = React.useState(false);
  const [allBooks, setAllBooks] = useState([]);
  const [books, setBooks] = useState([]);
  const [searchKeyWordsInput, setSearchKeyWordsInput] = useState("");
  const [searchKeyWords, setSearchKeyWords] = useState("");
  const [currentBook, setCurrentBook] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLayout, setCurrentLayout] = useState("line");
  const [bookStatus, setBookStatus] = useState("opened");
  const [pages, setPages] = useState(0);

  const [shareOpen, setShareOpen] = useState(false);
  const [unShareOpen, setUnShareOpen] = useState(false);
  const [shareType, setShareType] = useState('supplier');
  const [shareUsername, setShareUsername] = useState('');
  const [shareBookId, setShareBookId] = useState('');
  const [shareOrgId, setShareOrgId] = useState('');
  const [unShareBook, setUnShareBook] = useState({});

  const [noUserOpen, setNoUserOpen] = useState(false);
  const [noTransactionOrgOpen, setNoTransactionOrgOpen] = useState(false);
  const [transactionOrgOpen, setTransactionOrgOpen] = useState(false);
  const [companyOrgName, setCompanyOrgName] = useState('');
  const [currentCustomer,setCurrentCustomer] = useState('all');

  let booksPerPage = 11;
  useEffect(() => {
    booksPerPage = orgRoleReader.IsOrgMember(currentOrg, application) ? 12 : 11;
    if (window.localStorage.getItem("layout")) {
      setCurrentLayout(window.localStorage.getItem("layout"));
    }

  }, [])

  const {application, refreshApplication} = useContext(ApplicationContext);

  const [currentOrg, setCurrentOrg] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState('normal');
  useEffect(() => {
    if (currentOrg && application) {
      if (new Date(OrgRoleReader.getOrgExpiredDateById(currentOrg, application)) < new Date()) {
        setOrgExpired(true)
        // setOrgExpired(false);
      } else {
        accountingService.getBooks(9999, 0, currentOrg, currentStatus).then((result) => {
          if (result.data && result.data.datas.length >= 0) {
            setAllBooks(result.data.datas);
            let filterdBookByPage = []
            filterdBookByPage = result.data.datas;
            setPages(Math.ceil(filterdBookByPage.length / booksPerPage))
            filterdBookByPage = filterdBookByPage.slice(booksPerPage * (currentPage - 1), booksPerPage * (currentPage))
            setBooks(filterdBookByPage);
          }
        });

      }
    }
  }, [currentOrg, application, currentStatus]);

  useEffect(() => {
    let filteredBooks = [];
    if (searchKeyWords !== "" || currentCustomer !== 'all') {
      allBooks.forEach((item, index) => {
        let condition1 = searchKeyWords === ""? true:
            item.poDatas.length > 0 && item.poDatas[0].poNumber && item.poDatas[0].poNumber.toLowerCase().indexOf(searchKeyWords.toLowerCase()) !== -1
        let condition2 = currentCustomer === "all"? true:
            item.purchaserName && item.purchaserName === currentCustomer || item.supplierName && item.supplierName === currentCustomer

        if (condition1 && condition2) {
          filteredBooks.push(item);
        }
      })
    } else {
      filteredBooks = allBooks;
    }

    let filterdBookByPage = [];
    setPages(Math.ceil(filteredBooks.length / booksPerPage))
    filterdBookByPage = filteredBooks.slice(booksPerPage * (currentPage - 1), booksPerPage * (currentPage))
    setBooks(filterdBookByPage);
  }, [currentPage, searchKeyWords, currentCustomer]);

  const onMouseEnterHandler = (event, item) => {
    setCurrentBook(item);
    setAnchorEl(event.currentTarget);
  }

  const onMouseLeaveHandler = (event, item) => {
    setCurrentBook(null);
    setAnchorEl(null);
  }

  const customerChangeHandler = (value) =>{
    setCurrentPage(1);
    setSearchKeyWords("");
    setCurrentCustomer(value);
  }

  const openBookDetail = (item) => {
    history.push({
      pathname: "/PO Transactions/detail/" + item,
      state: {"orgId": currentOrg},
    });
  }

  const bookStatusChangeHandler = (event) => {
    setBookStatus(event.target.value);
    setCurrentPage(1);
    setSearchKeyWords("");
    if (event.target.value === "opened") {
      setCurrentStatus('normal');
    } else {
      setCurrentStatus('closed')
    }

  }
  const newBookHandler = () => {
    history.push({
      pathname: "/PO Transactions/newbook/",
      state: {"orgId": currentOrg},
    });
  }

  const pageChangeHandler = (event, value) => {
    setCurrentPage(value);
  }

  const handleSearch = () => {
    if (searchKeyWordsInput.length > 2 || searchKeyWordsInput.length === 0) {
      setSearchKeyWords(searchKeyWordsInput);
    }
  }

  const hanlerEnterSearch = (event) => {
    // console.log(event);
    if (event.key == "Enter") {
      handleSearch();
    }

  }

  const handleSwithLayout = () => {
    if (currentLayout === "block") {
      setCurrentLayout("line");
      window.localStorage.setItem("layout", "line");

    }
    if (currentLayout === "line") {
      setCurrentLayout("block");
      window.localStorage.setItem("layout", "block");
    }
  }

  const duplicateHandler = (bookItem) => {
    history.push({
      pathname: "/PO Transactions/newbook/",
      state: {
        orgId: currentOrg,
        bookId: bookItem.bookId,
        duplicated: true
      },
    });
  }

  const handleShareBook = (bookItem) => {
    setShareBookId(bookItem.bookId);
    setShareOpen(true);
  }

  const handleUnShareBook = (bookItem) => {

    organizationService.getTransactionsOrgByEmail(bookItem.shareTo).then((transaction) => {

      console.log('transaction.data: ', transaction.data);

      setUnShareBook({
        bookId: bookItem.bookId,
        shareId: bookItem.shareId,
        shareTo: bookItem.shareTo,
        transactionOrg: transaction.data.orgs[0].display_name,
      });

      setUnShareOpen(true);

    });
  }

  const handleShareClose = () => {
    setShareOpen(false);
    setNoUserOpen(false);
    setNoTransactionOrgOpen(false);
    setCompanyOrgName('');
    setShareOrgId('');
    setTransactionOrgOpen(false);
  }

  const handleUnShareClose = () => {
    setUnShareOpen(false);
  }

  const shareBookEmailConfirm = () => {

    if (!shareUsername || !checkEmail(shareUsername)) {
      setNoUserOpen(true);
      setNoTransactionOrgOpen(false);
      setTransactionOrgOpen(false);
      return;
    }

    organizationService.getUsersBySearch(shareUsername).then((user) => {

      console.log('result: ', user);

      if (user.status === 200 && user.data && user.data.length > 0) {
        setNoUserOpen(false);
        organizationService.getTransactionsOrgByEmail(shareUsername).then((transaction) => {

          if (transaction.status === 200 && transaction.data && transaction.data.orgs && transaction.data.orgs.length > 0) {
            console.log('share org: ', transaction.data.orgs[0]);
            setNoTransactionOrgOpen(false);
            setCompanyOrgName(transaction.data.orgs[0].display_name);
            setShareOrgId(transaction.data.orgs[0].id);
            setTransactionOrgOpen(true);

          } else {
            setNoTransactionOrgOpen(true);
            setTransactionOrgOpen(false);
          }

          console.log('transaction: ', transaction.data.orgs);
        });
      } else {
        setNoUserOpen(true);
        setNoTransactionOrgOpen(false);
        setTransactionOrgOpen(false);
      }

    }).catch((_) => {
      setNoUserOpen(true);
      setNoTransactionOrgOpen(false);
      setTransactionOrgOpen(false);
    });

  }

  const shareBookConfirm = () => {

    let requestData = {
      shareType: shareType,
      toUser: shareUsername,
      toOrgId: shareOrgId,
    };

    accountingService.shareBook(shareBookId, requestData).then((result) => {

      console.log('share book result: ', result);

      if (result && result.status === 200 && result.data) {
        for (let i = 0; i < allBooks.length; i++) {
          if (allBooks[i].bookId === shareBookId) {
            allBooks[i].isShared = true;
            allBooks[i].shareId = result.data.shareId;
            allBooks[i].shareTo = shareUsername;
          }
        }

        for (let i = 0; i < books; i++) {
          if (books[i].bookId === shareBookId) {
            books[i].isShared = true;
            books[i].shareId = result.data.shareId;
            books[i].shareTo = shareUsername;
          }
        }
        handleShareClose();

      } else {
        // TODO, 分享失败
      }


      console.log('result: ', result);
    });
  }

  const unShareBookConfirm = () => {
    console.log('unShareBook: ', unShareBook);

    accountingService.unShareBook(unShareBook.shareId).then((result) => {
      console.log('un-share book result: ', result);
      if (result && result.status && result.status === 200) {
        for (let i = 0; i < allBooks.length; i++) {
          if (allBooks[i].bookId === unShareBook.bookId) {
            allBooks[i].isShared = false;
            allBooks[i].shareId = null;
            allBooks[i].shareTo = null;
          }
        }

        for (let i = 0; i < books; i++) {
          if (books[i].bookId === unShareBook.bookId) {
            books[i].isShared = false;
            books[i].shareId = null;
            books[i].shareTo = null;
          }
        }

        handleUnShareClose();

      } else {
        // TODO, 解除分享失败

      }
    });
  }

  const handleChangeShareType = (e) => {
    setShareType(e.target.value);
  }

  const changeShareUsername = (username) => {
    setShareUsername(username);
    setTransactionOrgOpen(false);
  }

  const checkEmail = (values) => {
    let reg = /([\w._-])+@([\w_-])+(\.([\w_-])+){1,2}/;
    return !(values && !reg.test(values));
  }

  return (
    <CommonLayout>
      <div className={classes.box}>
        <OrganizationSelect onSetIniting={setIniting} onSetOpenCreateOrg={setOpenCreateOrg}
                            onSetCurrentOrg={setCurrentOrg} onSetOrgExpired={setOrgExpired} initing={initing}
                            openCreateOrg={openCreateOrg} currentOrg={currentOrg}
                            orgExpired={orgExpired}></OrganizationSelect>
        {
          (!initing && (openCreateOrg || !currentOrg)) &&
          <Grid>
            <div style={{padding: '20px'}}>
              <Typography variant="body1" style={{marginBottom: "20px"}}>
                {t('poManagementHomepage.createOrgTips')}
              </Typography>
              <Grid container spacing={4}>
                <Grid item md={8} xs={12}>
                  <img src={openbookDescriptionPic1}
                       srcSet={`${openbookDescriptionPic1} 300w, ${openbookDescriptionPic2} 768w, ${openbookDescriptionPic3} 1280w`}
                       style={{width: "100%", objectFit: "contain"}}></img>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography variant="body1" style={{color: "#818181"}}>
                    <b
                      style={{fontFamily: "Lato-Black"}}>{t('poManagementHomepage.description1')}</b> {t('poManagementHomepage.description2')}
                  </Typography>
                </Grid>
              </Grid>
            </div>

          </Grid>
        }
        {(!initing && !openCreateOrg && currentOrg) &&
          <Grid container spacing={4} justifyContent="center" direction="row" alignItems="flex-start">
            <Grid xs={12} item className={classes.root}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid xs={12} container spacing={1} alignItems="center">
                  <Grid xs={6} sm={4} md={3} xl={2} item>
                    <Select
                      value={bookStatus}
                      variant="outlined"
                      color="primary"
                      classes={{select: classes.outlinedPadding}}
                      onChange={bookStatusChangeHandler}
                      style={{verticalAlign: 'middle', width: '100%'}}
                    >
                      <MenuItem key={1} value={"opened"}>{t('poManagementHomepage.outstandingBook')}</MenuItem>
                      <MenuItem key={2} value={"closed"}>{t('poManagementHomepage.closedBook')}</MenuItem>
                    </Select>
                  </Grid>
                  <Grid xs={6} sm={4} md={3} xl={2} item>
                    <CustomerSelector org={currentOrg} customer={currentCustomer}
                                      onValueChange={customerChangeHandler} />
                  </Grid>
                  <Grid xs={12} sm={4} md={4} xl={3} item>
                    <TextField id="outlined-basic" variant="outlined" size="small"
                               placeholder={t('poManagementHomepage.searchByPONumber')}
                               style={{verticalAlign: 'middle', width: 'calc(100% - 48px)'}}
                               onChange={e => setSearchKeyWordsInput(e.target.value)}
                               error={(searchKeyWordsInput.length < 3 && searchKeyWordsInput.length > 0)}
                               onKeyPress={hanlerEnterSearch}/>

                    <IconButton onClick={handleSearch} aria-label="search"><SearchIcon></SearchIcon></IconButton>
                  </Grid>

                </Grid>

                <Grid xs={12} container alignItems="center">
                  <IconButton onClick={handleSwithLayout} aria-label="switch layout">{currentLayout === "line" &&
                    <ViewModuleIcon></ViewModuleIcon>}{currentLayout === "block" &&
                    <ViewHeadlineIcon></ViewHeadlineIcon>}</IconButton>

                </Grid>

                <Grid xs={12} item>
                  {currentLayout === "block" && <Grid container spacing={2}>
                    {!orgRoleReader.IsOrgMember(currentOrg, application) && <Grid xs={6} md={3} lg={2} item>
                      <div className={classes.newBook} onClick={newBookHandler}>
                        <AddIcon fontSize="large" color="secondary"></AddIcon>
                      </div>
                    </Grid>}
                    {books && books.length > 0 && books.map(item => {
                      return (
                        <Grid xs={6} md={3} lg={2} item key={item.bookId}>
                          <div className={classes.bookItem} onClick={() => openBookDetail(item.bookId)}
                               onMouseEnter={(e) => onMouseEnterHandler(e, item)}
                               onMouseLeave={(e) => onMouseLeaveHandler(e, item)}>
                            <div className={classes.bookItemTitle}><Typography
                              variant="body2">{item.bookTitle}</Typography></div>
                            <div className={classes.bookItemTitle} style={{color: '#127bb0'}}>PO:</div>
                            <div className={classes.bookItemPo}><Typography variant="body1"
                                                                            style={{color: '#127bb0'}}>{item.poDatas && item.poDatas.length > 0 ? item.poDatas[0].poNumber : ""}</Typography>
                            </div>
                            <div className={classes.bookItemTitle}
                                 style={{color: "grey"}}>{item.status === "closed" ? t('poManagementHomepage.poTotal') + ":" : t('poManagementHomepage.balance') + ":"}</div>
                            <div className={classes.bookItemPo}>{item.poDatas && item.poDatas.length > 0 && <Typography
                              variant="body2">{item.poDatas[0].currency} {accountingService.getCurrencyLabel(item.poDatas[0].currency)} {item.status === "closed" ? item.poTotal : item.poDatas[0].outstandingBalance}</Typography>}</div>
                            {item.status === "closed" && <div className={classes.bookItemTitle}
                                                              style={{color: "grey"}}>{t('poManagementHomepage.closedDate')}:</div>}
                            {item.status === "closed" && <div className={classes.bookItemPo}><Typography
                              variant="body2">{moment(item.updateTime).format("YYYY/MM/DD")}</Typography></div>}
                          </div>
                        </Grid>
                      )
                    })}
                  </Grid>}
                  {currentLayout === "line" && <Grid container>
                    {!orgRoleReader.IsOrgMember(currentOrg, application) && <Grid item xs={12}>
                      <Button variant="contained" color="primary" size="small"
                              onClick={newBookHandler}>{t('poManagementHomepage.addBook')}</Button>
                    </Grid>}
                    <Grid container style={{overflowX: 'auto'}}>
                      <table width="100%" className={classes.balanceTable} style={{minWidth: '600px'}}>
                        <thead>
                        <tr style={{textAlign: 'left', height: '30px'}}>
                          <th width="20%"><Typography variant="body1">{t('poManagementHomepage.bookTitle')}</Typography>
                          </th>
                          <th width="7%"><Typography variant="body1">PO</Typography></th>
                          <th width="10%"><Typography
                            variant="body1">{currentStatus === "closed" ? t('poManagementHomepage.poTotal') : t('poManagementHomepage.balance')}</Typography>
                          </th>
                          <th width="10%"><Typography
                            variant="body1">{currentStatus === "closed" ? t('poManagementHomepage.closedDate') : t('poManagementHomepage.poDate')}</Typography>
                          </th>
                          <th width="10%"><Typography
                            variant="body1">{currentStatus === "closed" ? t('common.actions') : t('common.actions')}</Typography>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {books && books.length > 0 && books.map((item, index) => {
                          return (
                            <tr key={index} className={classes.balanceRow}>
                              <td><Typography variant="body2" title={item.actions}
                                              onClick={() => openBookDetail(item.bookId)}
                                              onMouseEnter={(e) => onMouseEnterHandler(e, item)}
                                              onMouseLeave={(e) => onMouseLeaveHandler(e, item)}
                                              style={{cursor: 'pointer'}}>{item.isShared && (item.orgId !== item.originalOrgId || item.owner !== token.username) && '[分享账本] '}{item.bookTitle}</Typography></td>
                              <td><Typography variant="body2"
                                              title={item.poDatas && item.poDatas.length > 0 ? item.poDatas[0].poNumber : ""}>{item.poDatas && item.poDatas.length > 0 ? item.poDatas[0].poNumber : ""}</Typography>
                              </td>
                              <td>{item.poDatas && item.poDatas.length > 0 && <Typography variant="body2"
                                                                                          title={item.status === "closed" ? item.poTotal : item.poDatas[0].outstandingBalance}>{item.poDatas[0].currency} {accountingService.getCurrencyLabel(item.poDatas[0].currency)} {item.status === "closed" ? item.poTotal : item.poDatas[0].outstandingBalance}</Typography>}</td>
                              <td><Typography variant="body2"
                                              title={item.status === "closed" ? moment(item.updateTime).format("YYYY/MM/DD") : (item.poDatas.length > 0 ? moment(item.poDatas[0].poDate).format("YYYY/MM/DD") : "")}>{item.status === "closed" ? moment(item.updateTime).format("YYYY/MM/DD") : (item.poDatas.length > 0 ? moment(item.poDatas[0].poDate).format("YYYY/MM/DD") : "")}</Typography>
                              </td>
                              <td>
                                <Tooltip title={t('common.duplucate')}><IconButton onClick={e => duplicateHandler(item)}
                                                                                   disabled={orgRoleReader.IsOrgMember(currentOrg, application)}
                                                                                   style={{padding: '6px'}}><FileCopyIcon
                                  fontSize="small"/></IconButton></Tooltip>
                                {
                                  item.status !== "closed" && !item.isShared && item.orgId === item.originalOrgId && item.owner === token.username &&
                                  <Tooltip title={t('common.sharePO')}><IconButton onClick={e => handleShareBook(item)}
                                                                                   style={{padding: '6px'}}><ShareIcon
                                    fontSize="small"/></IconButton></Tooltip>
                                }

                                {
                                  item.status !== "closed" && item.isShared && item.orgId === item.originalOrgId && item.owner === token.username &&
                                  <Tooltip title={t('shareBook.unShare')}><IconButton onClick={e => handleUnShareBook(item)}
                                                                                   style={{padding: '6px'}}><LinkOff
                                    fontSize="small"/></IconButton></Tooltip>
                                }

                              </td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>}
                  {currentBook && <Popover
                    id="detail"
                    className={classes.popover}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    classes={{paper: classes.detailBook}}
                    disableRestoreFocus
                  >
                    <Typography variant="body2"
                                style={{color: "#818181"}}>{t('poManagementHomepage.purchaserName')}:</Typography>
                    <Typography variant="body2">{currentBook.purchaserName}</Typography>
                    <Typography variant="body2"
                                style={{color: "#818181"}}>{t('poManagementHomepage.purchaserMember')}:</Typography>
                    {currentBook.purchaserMembers && currentBook.purchaserMembers.length > 0 && currentBook.purchaserMembers.map((item, index) =>
                      <Typography variant="body2" key={index}>{item.memberName}</Typography>
                    )}
                    <Typography variant="body2"
                                style={{color: "#818181"}}>{t('poManagementHomepage.supplierName')}:</Typography>
                    <Typography variant="body2">{currentBook.supplierName}</Typography>
                    <Typography variant="body2"
                                style={{color: "#818181"}}>{t('poManagementHomepage.supplierMember')}:</Typography>
                    {currentBook.supplierMembers && currentBook.supplierMembers.length > 0 && currentBook.supplierMembers.map((item, index) =>
                      <Typography variant="body2" key={index}>{item.memberName}</Typography>
                    )}
                    <Typography variant="body2"
                                style={{color: "#818181"}}>{t('poManagementHomepage.bookOwner')}:</Typography>
                    <Typography variant="body2">{currentBook.owner}</Typography>
                  </Popover>}
                </Grid>
                <Grid xs={12} item>
                  <Pagination count={pages} shape="rounded" showFirstButton showLastButton
                              onChange={pageChangeHandler}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      </div>

      <Dialog open={shareOpen} onClose={handleShareClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography variant="body1">
            {t('common.bookDistribute')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div style={{width: '20%'}}>
                <Select
                  fullWidth
                  required={true}
                  variant="outlined"
                  value={shareType}
                  onChange={handleChangeShareType}
                  classes={{ select: classes.selectField }}>

                  <MenuItem key={0} value={'supplier'}>
                    {t('common.supplier')}
                  </MenuItem>
                  <MenuItem key={1} value={'purchaser'}>
                    {t('common.purchaser')}
                  </MenuItem>
                </Select>
              </div>
              <div style={{marginLeft: '20px', width: '70%'}}>
                <TextField className={classes.input}
                           size='small'
                           variant="outlined"
                           required={true}
                           placeholder={t('common.email')}
                           onChange={e => changeShareUsername(e.target.value)}
                />
              </div>

              <div style={{width: '160px', marginLeft: '20px'}}>
                <Button variant="contained" color="primary" onClick={shareBookEmailConfirm} >
                  <Typography variant="body2">
                    {t('shareBook.confirmEmail')}
                  </Typography>
                </Button>
              </div>

            </div>
            <div style={{height: '20px'}} />

            <Collapse in={noUserOpen} style={{width: '100%', }}>
              <Alert severity="warning" action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setNoUserOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>

              }>
                <AlertTitle>Warning</AlertTitle>
                {t('shareBook.noUser')}
              </Alert>
            </Collapse>

            <Collapse in={noTransactionOrgOpen} style={{width: '100%', }}>
              <Alert severity="warning" action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setNoTransactionOrgOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>

              }>
                <AlertTitle>Warning</AlertTitle>
                {t('shareBook.noTransactionOrg')}
              </Alert>
            </Collapse>

            <Collapse in={transactionOrgOpen} style={{width: '100%', }}>
              <Alert severity="info">
                {t('shareBook.companyOrgDesc')}:  <span style={{fontWeight: 'bold',}}>{companyOrgName}</span>
              </Alert>
            </Collapse>

            <div style={{height: '20px'}} />

          </div>

        </DialogContent>
        <DialogActions>
          {transactionOrgOpen &&
            <Button variant="contained" color="primary" onClick={shareBookConfirm}>
            <Typography variant="body2">
              {t('common.shareBook')}
            </Typography>
          </Button>
            }

          <Button variant="contained" onClick={handleShareClose}>
            <Typography variant="body2">
              {t('common.cancel')}
            </Typography>
          </Button>

        </DialogActions>
      </Dialog>

      <Dialog open={unShareOpen} onClose={handleUnShareClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            <Typography variant="body1">
              {t('shareBook.unShare')}
            </Typography>
            <Alert severity="warning" style={{marginTop: '20px'}}>
              {t('shareBook.unShareDesc')}
            </Alert>
        </DialogTitle>
        <DialogContent>
          <div>

            <Typography variant="body1">
              {t('shareBook.sharedToUser')}:  {unShareBook.shareTo}
            </Typography>
            <Typography variant="body1">
              {t('shareBook.sharedToCompanyOrg')}:  {unShareBook.transactionOrg}
            </Typography>

          </div>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={unShareBookConfirm}>
            <Typography variant="body2">
              {t('shareBook.unShare')}
            </Typography>
          </Button>

          <Button variant="contained" onClick={handleUnShareClose}>
            <Typography variant="body2">
              {t('common.cancel')}
            </Typography>
          </Button>

        </DialogActions>
      </Dialog>

    </CommonLayout>
  )
}
