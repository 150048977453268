import {axiosBooking as axios} from './axiosHelper';

const getMyMeetings = function(){
    const url = '/auth/meetings/today';
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}


const createMeeting = function(meeting){
    const url = '/auth/meetings';
    return new Promise((resolve, reject)=>{
        axios.post(url,meeting).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateMeeting = function(meetingId,meeting){
    const url = `/auth/meetings/${meetingId}`;
    return new Promise((resolve, reject)=>{
        axios.put(url,meeting).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const cancelMeeting = function(meetingId){
    const url = `/auth/meetings/${meetingId}`;
    return new Promise((resolve, reject)=>{
        axios.delete(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const submitRequests = function(meetingId,requests){
    const url = '/auth/meetings/' + meetingId + "/requests";
    return new Promise((resolve, reject)=>{
        axios.put(url,{'requests':requests}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getMyMeetingsByDate = function(date,orgId){
    const inputDate = new Date(date)
    const startTime = new Date(inputDate.getFullYear(),inputDate.getMonth(),inputDate.getDate(),0,0,0).toISOString();
    const endTime = new Date(inputDate.getFullYear(),inputDate.getMonth(),inputDate.getDate(),23,59,59).toISOString();
    const url = '/auth/meetings?startdate='+startTime+'&enddate='+ endTime+'&organizationId='+orgId;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getMyMeetingsByRange = function(start,end){
    const startTime = new Date(start).toISOString();
    const endTime = new Date(end).toISOString();
    const url = '/auth/meetings?startdate='+startTime+'&enddate='+ endTime;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

export default {
    getMyMeetings,
    createMeeting,
    submitRequests,
    getMyMeetingsByDate,
    cancelMeeting,
    getMyMeetingsByRange,
    updateMeeting
}