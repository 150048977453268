import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Paper, Typography, Button, IconButton, Box, Collapse, Tooltip } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import moment from 'moment';

import accountingService from "../../services/accountingService";
import noImage from '../../assets/img/invoice&payment.svg';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaymentIcon from '@material-ui/icons/Payment';
import downloadFileUtils from '../../utils/downloadFile';

const useStyles = makeStyles(theme => ({
  invoiceLinePanel: {
    marginBottom: "13px",
    borderBottom: "none ",
  },
  thTitle: {
    letterSpacing: "0.05px",
    color: "#5d5d5d",
  },
  thBorder: {
    borderBottom: "none ",
    fontSize: "14px",
    padding: "6px",
  },
  issuePoGrid: {
    width: '100%',
    textAlign: 'center',
  },
  issuePoImage: {
    maxWidth: '150px'
  },
  dashedLine: {
    margin: "23px 0 10px 0",
    borderTop: "1px solid #dbdbdb",
    width: "100%",
  },
  itemTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  itemContent: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap", 
  },
}))

export default function InvoiceLine(props) {
  const invoiceAddPage = "/PO Transactions/Invoice/add";

  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const history = useHistory();

  const params = useParams();

  const [currentBook, setCurrentBook] = useState(null);

  const [bookData, setBookData] = useState([]);
  useEffect(()=> {
    accountingService.viewBook(props.book.bookId).then(result => {
      setBookData(result.data);
      console.log("book data", result.data);
    })
  }, [])

  const [poData, setPoData] = useState([]);
  useEffect(()=> {
    accountingService.getPOByBookId(props.book.bookId).then(result => {
      setPoData(result.data[0]);
      console.log("po data", result.data[0]);
    })
  }, [])

  const [invoiceLineData, setInvoiceLineData] = useState([]);
  useEffect(() => {
    setCurrentBook(props.book);
    refreshList();
  }, [])

  const refreshList = ()=>{
    if(props.po){
      accountingService.getInvoiceByPOId(props.po).then(result => {
        setInvoiceLineData(result.data);
        console.log("inovices data", result.data)
      })
    }
  }

  const titleCase = (str) => {
    let newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
    if(newStr==='Billed'){
      newStr = t('invoicePage.billed');
    }
    if(newStr==='Checked'){
      newStr = t('invoicePage.checked');
    }
    if(newStr==='Paid'){
      newStr = t('invoicePage.paid');
    }
    return newStr;
  }

  const cancelStatement =(row)=>{
    accountingService.deleteInvoiceByPOId(row.invoiceId).then(result=>{
      if(result.status === 200){
        refreshList();
      }
    })
  }

  const invoiceAddHandler = (row) =>{
    history.push({
      pathname: invoiceAddPage,
      state: { 
        "invoice":  row,
        "bookId": currentBook.bookId
      },
    });    
  }

  const paymentAddHandler = (row) =>{
    history.push({
      pathname: '/PO Transactions/Payment/add',
      state: { 
        "invoice":  row,
        "bookId": currentBook.bookId
      },
    });  
  }

  const handleDownlodStatement=(rowData)=>{
    accountingService.downloadStatementById(rowData.statementNumber,window.sessionStorage.getItem("currentOrg")).then(result=>{
        let types = 'application/vnd.ms-excel';
        let fileName = `Statement ${rowData.statementNumber}.xlsx`;
        let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
        downloadFileUtils.downLoadFile(blob, fileName);   
    })
}

  const Row = (props) => {
    const { row } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow className={classes.thBorder}>
          <TableCell component="th" scope="row" className={classes.thBorder} style={{ color: "rgb(18, 123, 176)" }}><Link onClick={e=>handleDownlodStatement(row)} style={{ color: "rgb(18, 123, 176)" }}>{row.statementNumber}</Link></TableCell>
          <TableCell className={classes.thBorder}>{moment(row.statementDate).format('YYYY/MM/DD')}</TableCell>
          <TableCell className={classes.thBorder}>{row.invoiceNumber}</TableCell>
          <TableCell className={classes.thBorder}>{row.invoiceDate?moment(row.invoiceDate).format("YYYY/MM/DD"):''}</TableCell>
          <TableCell className={classes.thBorder}>{row.invoiceTotalAmount}</TableCell>
          <TableCell className={classes.thBorder}>{titleCase(row.status)}</TableCell>
          <TableCell className={classes.thBorder}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {/* <IconButton aria-label="invoice" size="small" onClick={() => invoiceAddHandler(row)} disabled={row.status!=='checked'||!accountingService.checkPermission("new_invoice_line", currentBook)}>
              <Tooltip title={t('invoicePage.invoiceButton')}>
                <ReceiptIcon />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="pay" size="small" onClick={() => paymentAddHandler(row)} disabled={row.status!=='billed'||!accountingService.checkPermission("add_payment",currentBook)}>
              <Tooltip title={t('poManagementHomepage.payment')}>
                <PaymentIcon />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="cancel" size="small" onClick={() => cancelStatement(row)} disabled={row.status!=='checked'}>
              <Tooltip title={t('common.cancel')}>
                <DeleteIcon />
              </Tooltip>
            </IconButton> */}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.thBorder}>{t('poFormPage.line')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.item')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.product')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.QTY')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.price')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.lineTotal')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('poFormPage.date')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.lines.map((lineRow) => (
                      <TableRow key={lineRow.invoiceLineId}>
                        <TableCell className={classes.thBorder} component="th" scope="row">
                          {lineRow.lineNumber}
                        </TableCell>
                        <TableCell className={classes.thBorder}>{lineRow.itemNumber}</TableCell>
                        <TableCell className={classes.thBorder}>{lineRow.productName}</TableCell>
                        <TableCell className={classes.thBorder}>{lineRow.qty}</TableCell>
                        <TableCell className={classes.thBorder}>{lineRow.price}</TableCell>
                        <TableCell className={classes.thBorder}>
                          {lineRow.lineTotal}
                        </TableCell>
                        <TableCell className={classes.thBorder}>{moment(lineRow.lineDate).format("YYYY/MM/DD")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handleToAddInvoice = () => {
    history.push({
      pathname: invoiceAddPage,
      state: { "bookId": params.bookId },
    });
  };

  Row.propTypes = {
    row: PropTypes.shape({
      invoiceNumber: PropTypes.string.isRequired,
      paymentTerm: PropTypes.string.isRequired,
      invoiceTotalAmount: PropTypes.string.isRequired,
      invoiceDate: PropTypes.string.isRequired,
      lines: PropTypes.arrayOf(
        PropTypes.shape({
          lineNumber: PropTypes.string.isRequired,
          itemNumber: PropTypes.string.isRequired,
          productName: PropTypes.string.isRequired,
          qty: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          lineTotal: PropTypes.string.isRequired,
          lineDate: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  };

  return (
    <div>
      <Grid container fullWidth>
        <Grid container item spacing={2} xs={12} style={{margin: "20px 0px"}}>
          <Grid item xs={6} sm={4} md={3}>
              <div>
                  <Typography variant="body2" className={classes.itemTitle}>
                      {t('invoicePage.purchaserName')}
                  </Typography>
              </div>
              <div>
                  <Typography variant="subtitle2" className={classes.itemContent}>
                      {bookData && bookData.purchaserName ? bookData.purchaserName : (<span>&nbsp;</span>)}
                  </Typography>
              </div>
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
              <div>
                  <Typography variant="body2" className={classes.itemTitle}>
                      {t('invoicePage.purchaserTINEINVAT')}
                  </Typography>
              </div>
              <div>
                  <Typography variant="subtitle2" className={classes.itemContent}>
                      {invoiceLineData[0] && invoiceLineData[0].purchaserTaxId ? invoiceLineData[0].purchaserTaxId : (<span>&nbsp;</span>)}
                  </Typography>
              </div>
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <Grid container>
              <Grid item xs={12} >
                  <Typography variant="body2" className={classes.itemTitle}>
                    {t('invoicePage.supplierName')}
                  </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.itemContent}>
                      {bookData && bookData.supplierName ? bookData.supplierName : (<span>&nbsp;</span>)}
                  </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <Grid container>
                <Grid item xs={12} >
                    <Typography variant="body2" className={classes.itemTitle}>
                      {t('invoicePage.supplierTINEINVAT')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.itemContent}>
                        {invoiceLineData[0] && invoiceLineData[0].supplierTaxId ? invoiceLineData[0].supplierTaxId : (<span>&nbsp;</span>)}
                    </Typography>
                </Grid>
            </Grid>
          </Grid>

            <Grid item xs={6} sm={4} md={3}>
                <div>
                    <Typography variant="body2" className={classes.itemTitle}>
                        {t('invoicePage.poNumber')}
                    </Typography>
                </div>
                <div>
                    <Typography variant="subtitle2" className={classes.itemContent}>
                        {poData.poNumber ? poData.poNumber : (<span>&nbsp;</span>)}
                    </Typography>
                </div>
            </Grid>

            <Grid item xs={6} sm={4} md={3}>
                <div>
                    <Typography variant="body2" className={classes.itemTitle}>
                        {t('invoicePage.paymentTerm')}
                    </Typography>
                </div>
                <div>
                    <Typography variant="subtitle2" className={classes.itemContent}>
                        {poData.paymentTerm ? poData.paymentTerm : (<span>&nbsp;</span>)}
                    </Typography>
                </div>
            </Grid>

            <Grid item xs={6} sm={4} md={3}>
                <div>
                    <Typography variant="body2" className={classes.itemTitle}>
                        {t('invoicePage.currency')}
                    </Typography>
                </div>
                <div>
                    <Typography variant="subtitle2" className={classes.itemContent}>
                        {poData.currency ? poData.currency : (<span>&nbsp;</span>)}
                    </Typography>
                </div>
            </Grid>
        </Grid>

          <Grid item xs={12} style={{ textAlign: "center"}}>
              {/* {accountingService.checkPermission("new_invoice_line", currentBook) &&
              <Button variant="contained" color="primary" size="large" onClick={handleToAddInvoice}>
                  <Typography variant="body2">
                      {t('invoicePage.addInvoice')}
                  </Typography>
              </Button>} */}
          </Grid>
        <Grid container className={classes.dashedLine}></Grid>

        <Grid item xs={12}>
          {invoiceLineData && invoiceLineData.length > 0 ? (
            <Grid key={invoiceLineData.invoiceId} >
              <TableContainer component={Paper} className={classes.invoiceLinePanel}>
                <Table aria-label="simple table"  style={{minWidth: '600px'}}>
                  <TableHead>
                    <TableRow className={classes.thTitle}>
                      <TableCell className={classes.thBorder}>{t('invoicePage.statementNumber')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('invoicePage.statementDate')}</TableCell>
                      <TableCell className={classes.thBorder}>{t('invoicePage.invoiceNumber')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('invoicePage.invoiceDate')}</TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('invoicePage.totalAmount')} </TableCell>
                      <TableCell className={classes.thBorder} align="left">{t('invoicePage.status')}</TableCell>
                      <TableCell className={classes.thBorder} align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceLineData.map((item) => (
                      <Row key={item.invoiceId} row={item} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )
            :
            (
              <>
                <Grid item xs={12} className={classes.issuePoGrid}>
                  <img src={noImage} className={classes.issuePoImage}></img>
                </Grid>
                <Grid item xs={12} className={classes.issuePoGrid}>
                  {t('invoicePage.noInvoiceLine')}
                </Grid>
              </>
            )
          }
        </Grid>
      </Grid>
    </div>
  )
}
