import CommonLayout from "../../components/CommonLayout";
import React,{useContext}  from 'react';
import {
    Link, useHistory
} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Select, MenuItem, TextField} from '@material-ui/core';

import {useEffect, useState} from 'react';
import useToken from '../../hooks/useToken';
import IconButton from '@material-ui/core/IconButton';
import { ApplicationContext } from "../../context/applicationContext";
import OrgRoleReader from "../../utils/orgRoleReader";
import openbookDescriptionPic1 from '../../assets/img/openbook_d_1.png';
import openbookDescriptionPic2 from '../../assets/img/openbook_d_2.png';
import openbookDescriptionPic3 from '../../assets/img/openbook_d_3.png';
import accountingService from "../../services/accountingService";
import OrganizationSelect from "../../components/OrganizationSelect";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';

import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import downloadFileUtils from '../../utils/downloadFile';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '50px'
    },
    box: {
        padding: '30px',
        '@media (max-width:960px)':  {
            padding: '16px',
        },
    },
    dialogRoot: {
        minHeight: '500px',
        marginTop: '10px',
    },
    dialogHr: {
        border: '1px',
        dashed: '#000',
        marginTop: '20px',
        marginBottom: '20px'
    },
    required: {
        color: '#981414',
    },
    outlinedPadding: {
        padding: '11px 14px',
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft:0,
        width: '100%'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    bookItemTitle:{
        paddingLeft:'8px',
        marginTop:'12px',
        "& p":{
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            width:'90%'
        }
    },
    bookItemPo:{
        paddingLeft:'8px',
        marginTop:'5px',

    },
    bookItemBalance:{
        padding:'4px',
    },
    detailBook:{
        minWidth:'200px',
        backgroundColor:'none',
        border: theme.currentTheme.border[3],
        borderRadius:'8px',
        padding: '20px 12px 15px 20px',
        lineHeight:'70px'
    },
    dataPicker: {
        width: 'calc(50% - 8px)',
    },
    filter2Line: {
        '@media (max-width:960px)':  {
            marginTop: theme.spacing(1),
        },
        '@media (min-width:960px)':  {
            paddingLeft: theme.spacing(2),
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    balanceTable:{
        tableLayout:'fixed',
        "& th" : {
            backgroundColor: theme.currentTheme.theme2backgrounds[1],
            "& p":{fontFamily:'IBMPlexSans-Bold'},
            "& a":{color:theme.currentTheme.text}
        },
        "& td" : {
            paddingTop:'4px',
            verticalAlign:'middle'
        },
        "& tr:nth-child(even)" : {
            backgroundColor: theme.currentTheme.theme2backgrounds[1],
            height:'35px',
            verticalAlign:'top'
        },
        "& tr:nth-child(odd)" : {
            backgroundColor: theme.currentTheme.theme2backgrounds[1],
            height:'35px',
            verticalAlign:'top'

        },
        "& p":{
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
        },
    },
    balanceRow:{
        color:'red'
    },
    // select1_4: {
    //     verticalAlign:'middle',
    //     width:'10%',
    //     '@media only screen and (max-width: 1200px) and (min-width: 601px)':  {
    //         marginLeft: 'unset',
    //         marginRight: 'unset',
    //         width: '62%',
    //         marginTop: '5px',
    //     },
    //     '@media only screen and (max-width: 600px)':  {
    //         marginLeft: 'unset',
    //         marginRight: 'unset',
    //         marginTop: '5px',
    //         width: '100%',
    //     },
    // },
}));

export default function Main() {
    const classes = useStyles();
    const { token } = useToken();
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const [initing, setIniting] = React.useState(true);
    const [orgExpired, setOrgExpired] = React.useState(false);
    const [openCreateOrg, setOpenCreateOrg] = React.useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const [status, setStatus] = useState('all');

    const {application,refreshApplication} = useContext(ApplicationContext);
    const invoiceAddPage = "/PO Transactions/Invoice/add";

    const [currentOrg, setCurrentOrg] = useState('');

    const [statements, setStatements] = useState([]);
    const [statementsOld, setStatementsOld] = useState([]);

    const [keyword, setKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentRow, setCurrentRow] = useState(null);
    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>
        ),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        Cancel: forwardRef((props, ref) => <CancelOutlinedIcon {...props} ref={ref} />)
    };

    useEffect(() => {
        if (currentOrg && application) {
            if (new Date(OrgRoleReader.getOrgExpiredDateById(currentOrg, application)) < new Date()) {
                setOrgExpired(true);
            } else {
                accountingService.getAllStatements(currentOrg, searchKeyword).then(result => {
                    if (result.status === 200) {
                        let temp = result.data.datas;
                        setStatementsOld(result.data.datas);
                        filter(temp);
                    }
                });
            }
        }
    }, [currentOrg, application]);

    const refreshList = (searchKeyword) => {
        accountingService.getAllStatements(currentOrg, searchKeyword).then(result => {
            if (result.status === 200) {
                let temp = result.data.datas;
                setStatementsOld(result.data.datas);
                filter(temp);
            }
        })
    }

    useEffect(() => {
        if (currentOrg && application) {
            if (new Date(OrgRoleReader.getOrgExpiredDateById(currentOrg, application)) < new Date()) {
                setOrgExpired(true)
            } else {
                console.log('filter');
                filter(statementsOld);
            }
        }
    }, [status]);

    const filter = (data) => {
        let statementsTemp = [];
        if (status) {

            if (status === 'all') {
                data.forEach(item => {
                    statementsTemp.push(item);
                });

                setStatements(statementsTemp);
            }

            if (status === 'notInvoiced') {
                data.forEach(item => {
                    if (item.status && item.status.toLowerCase() === 'checked') {
                        statementsTemp.push(item);
                    }
                });

                setStatements(statementsTemp);
            }

            if (status === 'unpaid') {
                data.forEach(item => {
                    if (item.status && item.status.toLowerCase() === 'billed') {
                        statementsTemp.push(item);
                    }
                });

                setStatements(statementsTemp);
            }

            if (status === 'paid') {
                data.forEach(item => {
                    if (item.status && item.status.toLowerCase() === 'paid') {
                        statementsTemp.push(item);
                    }
                });

                setStatements(statementsTemp);
            }
        }
    }

    const handlerwarningOpenClose = () =>{
        setWarningOpen(false);
    }

    const titleCase = (str) => {
        let newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
        if (newStr === 'Billed') {
            newStr = t('invoicePage.billed');
        }
        if (newStr === 'Checked') {
            newStr = t('invoicePage.checked');
        }
        if (newStr === 'Paid') {
            newStr = t('invoicePage.paid');
        }
        return newStr;
    }

    const formatDate = (date) => {
        if (date && date !== '') {
            return moment(date).format('YYYY/MM/DD');
        } else {
            return '';
        }
    }

    const handleDownloadStatement = (rowData) => {
        accountingService.downloadStatementById(rowData.statementNumber, window.sessionStorage.getItem("currentOrg")).then(result => {
            let types = 'application/vnd.ms-excel';
            let fileName = `Statement ${rowData.statementNumber}.xlsx`;
            let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
            downloadFileUtils.downLoadFile(blob, fileName);
        })
    }

    const cancelStatement =(row)=>{
        let ids = [];
        row.lines.map(item=>{
            if(!ids.includes(item.invoiceId)){
                ids.push(item.invoiceId);
            }
        })
        accountingService.deleteStatementByIds(ids.toString()).then(result=>{
          if(result.status === 200){
            setWarningOpen(false);
            refreshList();
          }
        })
    }

    const paymentAddHandler = (event, row) =>{
        let selectedLine = row;
        selectedLine.customer = selectedLine.purchaserName;
        let selectedLines = [];
        selectedLines.push(selectedLine);
        const issueShippURL = '/PO Transactions/Payment/batchAdd';
        history.push({
            pathname: issueShippURL,
            state: {
                selectedLines: row,
                type: "batch"
            },
        });
    }

    const invoiceAddHandler = (event, row) =>{
        const invoiceAddPage = '/PO Transactions/Invoice/batchAdd';
        history.push({
            pathname: invoiceAddPage,
            state: {
              invoice:  row,
              selectedLines: row.lines,
            },
        });
    }

    return (
        <CommonLayout>
            <div className={classes.box}>
                <OrganizationSelect onSetIniting={setIniting} onSetOpenCreateOrg={setOpenCreateOrg} onSetCurrentOrg={setCurrentOrg} onSetOrgExpired={setOrgExpired} initing={initing} openCreateOrg={openCreateOrg} currentOrg={currentOrg} orgExpired={orgExpired}></OrganizationSelect>
                {
                    (!initing && (openCreateOrg || !currentOrg)) &&
                    <Grid>
                        <div style={{padding:'20px'}}>
                            <Typography variant="body1" style={{marginBottom:"20px"}}>
                                {t('poManagementHomepage.createOrgTips')}
                            </Typography>
                            <Grid container spacing={4}>
                                <Grid item md={8} xs={12}>
                                    <img src={openbookDescriptionPic1} srcSet={`${openbookDescriptionPic1} 300w, ${openbookDescriptionPic2} 768w, ${openbookDescriptionPic3} 1280w`} style={{width:"100%",objectFit:"contain"}}></img>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography variant="body1" style={{color:"#818181"}}>
                                        <b style={{fontFamily:"Lato-Black"}}>{t('poManagementHomepage.description1')}</b> {t('poManagementHomepage.description2')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>

                    </Grid>
                }
                {(!initing && !openCreateOrg && currentOrg)&&<Grid container spacing={4} justifyContent="center" direction="row" alignItems="flex-start">
                    <Grid xs={12} item className={classes.root}>
                        <Grid container>
                            <Grid xs={12} sm={12} md={12} spacing={1} container alignItems="center" justifyContent="flex-start" direction="row">
                                <Grid xs={9} md={3} item>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        label={t('invoicePage.searchByStatementOrInvoice')}
                                        fullWidth
                                        onChange={e=>setSearchKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid xs={9} md={1} item>
                                    <Select
                                      style={{marginTop: '5px'}}
                                      value={status}
                                      variant="outlined"
                                      color="primary"
                                      fontSize="small"
                                      classes={{ select: classes.outlinedPadding }}
                                      onChange={e => setStatus(e.target.value)}
                                    >
                                        <MenuItem key={0} value={'all'}>{t('poManagementHomepage.all')}</MenuItem>
                                        <MenuItem key={1} value={'notInvoiced'}>{t('poManagementHomepage.notInvoiced')}</MenuItem>
                                        <MenuItem key={2} value={'unpaid'}>{t('poManagementHomepage.unpaid')}</MenuItem>
                                        <MenuItem key={2} value={'paid'}>{t('poManagementHomepage.paid')}</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={2} md={2} item>
                                    <IconButton
                                      aria-label="search"
                                      onClick={() => refreshList(searchKeyword)}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{overflowX:'auto',marginTop:'20px'}}>
                            <Grid item spacing={2} style={{minWidth:'1100px'}}>
                                <MaterialTable
                                    width="100%"
                                    classes={{MuiPaper:classes.noBox}}
                                    title=""
                                    columns={[
                                        { field: 'statementNumber',
                                            title: t('invoicePage.statementNumber'),
                                            sorting: false,
                                            width: '8%',
                                            cellStyle:{
                                                maxWidth:'120px',
                                                padding:'4px',
                                                fontSize:'14px',
                                                overflow:'hidden',
                                                textOverflow:'ellipsis',
                                                whiteSpace:'nowrap',
                                            },
                                            render: rowData => {
                                                return(
                                                    <Link onClick={e=>handleDownloadStatement(rowData)} style={{ color: "rgb(18, 123, 176)" }}>
                                                        {rowData.statementNumber}
                                                    </Link>
                                                )
                                            }
                                        },
                                        {
                                            field: 'statementDate',
                                            title: t('invoicePage.statementDate'),
                                            width: '8%',
                                            sorting: false,
                                            render: rowData => formatDate(rowData.statementDate)
                                        },
                                        {
                                            field: 'invoiceNumber',
                                            title: t('invoicePage.invoiceNumber'),
                                            width: '8%',
                                            sorting: false,
                                        },
                                        {
                                            field: 'invoiceDate',
                                            title: t('invoicePage.invoiceDate'),
                                            width: '8%',
                                            sorting: false,
                                            render: rowData => formatDate(rowData.invoiceDate)
                                        },
                                        {
                                            field: 'totalAmount',
                                            title: t('invoicePage.totalAmount'),
                                            width: '8%',
                                            sorting: false,
                                        },
                                        {
                                            field: 'status',
                                            title: t('invoicePage.status'),
                                            width: '10%',
                                            sorting: false,
                                            render: rowData => titleCase(rowData.status),
                                            cellStyle:{
                                                maxWidth:'120px',
                                                padding:'4px',
                                                fontSize:'14px',
                                                overflow:'hidden',
                                                textOverflow:'ellipsis',
                                                whiteSpace:'nowrap',
                                            },
                                        },
                                        ]}
                                    icons={tableIcons}
                                    data={statements}
                                    style={{width:'100%'}}
                                    options={{
                                        actionsColumnIndex: -1,
                                        search: false,
                                        toolbar:false,
                                        sorting: true,
                                        paging:true,
                                        pageSize:10,
                                        // tableLayout:"fixed",
                                        headerStyle: {
                                            backgroundColor:"transparent",
                                            fontFamily:'IBMPlexSans-Bold',
                                            padding:'4px'
                                        },
                                        cellStyle:{
                                            padding:'4px',
                                            fontSize:'14px',
                                            overflow:'hidden',
                                            textOverflow:'ellipsis',
                                            whiteSpace:'nowrap',
                                        },
                                    }}
                                    localization={{
                                        header: {
                                            actions: t("common.actions")
                                        },
                                        body:{
                                            deleteTooltip:t("common.delete"),
                                            editTooltip:t("common.edit"),
                                            editRow:{
                                                deleteText:t("customerManagement.deleteConfirm"),
                                                cancelTooltip:t("common.cancel"),
                                                saveTooltip:t("common.save"),
                                            },
                                            emptyDataSourceMessage:t("common.noRecord"),
                                        }
                                    }}
                                    actions={[
                                        rowData => ({
                                            icon: ()=> <ReceiptIcon></ReceiptIcon>,
                                            tooltip: t('invoicePage.invoiceButton'),
                                            disabled: rowData.status!=='checked' || !rowData.permissions || !rowData.permissions.includes("new_invoice_line"),
                                            onClick: (event, rowData) => invoiceAddHandler(event, rowData),
                                        }),
                                        rowData => ({
                                            icon: ()=> <PaymentIcon></PaymentIcon>,
                                            tooltip: t('poManagementHomepage.payment'),
                                            disabled: rowData.status!=='billed' || !rowData.permissions || !rowData.permissions.includes("add_payment"),
                                            onClick: (event, rowData) => paymentAddHandler(event, rowData),
                                        }),
                                        rowData => ({
                                            icon: ()=> <DeleteIcon></DeleteIcon>,
                                            tooltip: t('common.cancel'),
                                            disabled: rowData.status!=='checked' || !rowData.permissions || !rowData.permissions.includes("new_invoice_line"),
                                            onClick: (event, rowData) => {
                                                setCurrentRow(rowData);
                                                setWarningOpen(true);
                                            },
                                        }),
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Dialog open={warningOpen} onClose={handlerwarningOpenClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="body1">
                            {t('invoicePage.cancelStatementTips')}
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={e=>cancelStatement(currentRow)}>
                            <Typography variant="body2">
                                {t('common.ok')}
                            </Typography>
                        </Button>
                        <Button variant="contained" onClick={handlerwarningOpenClose}>
                        <Typography variant="body2">
                            {t('common.cancel')}
                        </Typography>
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </CommonLayout>
    )
}
