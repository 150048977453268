import axios from 'axios';
import iamService from '../services/iamService';


const requestHandler = request => {
    const tokenString = sessionStorage.getItem('token');
    const token = JSON.parse(tokenString);
    if (token) {
        if (request.headers.Authorization === undefined || request.headers.Authorization === '') {
            request.headers['Authorization'] = "opendias " + token.access_token
        }
    }
    if (request.method.toLowerCase() === 'get') {
        if (!request.headers['Cache-Control']) {
            request.headers['Cache-Control'] = "no-cache";
        }
        if (!request.headers['Pragma']) {
            request.headers['Pragma'] = "no-cache";
        }
    }
    return request;
};


const paymentRequestHandler = request => {
    const tokenString = sessionStorage.getItem('token');
    const token = JSON.parse(tokenString);
    if (token) {
        if (request.headers.Authorization === undefined || request.headers.Authorization === '') {
            request.headers['Authorization'] = "Bearer " + token.access_token
        }
    }
    if (request.method.toLowerCase() === 'get') {
        if (!request.headers['Cache-Control']) {
            request.headers['Cache-Control'] = "no-cache";
        }
        if (!request.headers['Pragma']) {
            request.headers['Pragma'] = "no-cache";
        }
    }
    return request;
};

const responseHandler = response => {
    return response;
};

const errorHandler = error => {
    console.error(error);
    if (error.response && error.response.status && error.response.status + "" === '403') {
      window.sessionStorage.clear();
      window.localStorage.setItem('access_url',window.location.href);
      const iamLoginURL = iamService.loginURL();
      window.location.href = iamLoginURL;
    }
    return Promise.reject(error);
};

const bookingInst = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});
bookingInst.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);
bookingInst.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);


const coreInst = axios.create({
    baseURL: process.env.REACT_APP_CORE_API_BASE
});
coreInst.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);
coreInst.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

const notificationInst = axios.create({
    baseURL: process.env.REACT_APP_NOTIFICATION_API_BASE
});
notificationInst.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);
notificationInst.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

const newBookInst = axios.create({
    baseURL: process.env.REACT_APP_NEWBOOK_API_BASE
});

newBookInst.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

newBookInst.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

const inventoryInst = axios.create({
    baseURL: process.env.REACT_APP_INVENTORY_API_BASE
});

inventoryInst.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

inventoryInst.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

const paymentInst = axios.create({
    baseURL: process.env.REACT_APP_PAYMENT_API_BASE
});

paymentInst.interceptors.request.use(
    (request) => paymentRequestHandler(request),
    (error) => errorHandler(error)
);

paymentInst.interceptors.response.use(
    (response) => paymentRequestHandler(response),
    (error) => errorHandler(error)
);

export const axiosBooking = bookingInst;
export const axiosCore = coreInst;
export const axiosNotification = notificationInst;
export const axiosAccounting = newBookInst;
export const axiosInventory = inventoryInst;
export const axiosPayment = paymentInst;

