import CommonLayout from "../../components/CommonLayout";
import React,{useContext}  from 'react';
import {
    Link, useParams, useHistory
} from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Select, MenuItem,FormControl,InputLabel} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { Component, useEffect, useState, useRef} from 'react';
import Autocomplete, { createFilterOptions }  from '@material-ui/lab/Autocomplete';
import useToken from '../../hooks/useToken';
import Avatar from '@material-ui/core/Avatar';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import meetingService from '../../services/meetingService';
import requestsMock from '../../mocks/requests.json'
import moment from 'moment';
import { ApplicationContext } from "../../context/applicationContext";
import organizationService from "../../services/organizationService";
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import CreateOrg from "../intro/create-org"
import OrgRoleReader from "../../utils/orgRoleReader";
import orgRoleReader from "../../utils/orgRoleReader";
import LoadingButton from "../../components/LoadingButton";
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '50px'
    },
    box: {
        padding: '30px',
        width: '100%',
    },
    dialogRoot: {
        minHeight: '500px',
        marginTop: '10px',
    },
    dialogHr: {
        border: '1px',
        dashed: '#000',
        marginTop: '20px',
        marginBottom: '20px'
    },
    required: {
        color: '#981414',
        fontSize:'14px'
    },
    outlinedPadding: {
        padding: '11px 14px',
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        margin: '4px'
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
        margin: '4px'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    selectedmargin: {
        borderRadius: '4px',
        marginTop: '8px',
        marginBottom: '4px'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    link: {
        textDecoration: "none",
    },
    errorShow: {
        display:'',
        color:'red',
    },
    errorNotShow: {
        display:'none'
    },
    orangeButton:{
        backgroundColor:'#ff7247',
        '&:hover': {
            backgroundColor: '#ff7247',
        },
    },
    dialogContainer:{
        backgroundColor: theme.currentTheme.backgrounds[10],
        border: theme.currentTheme.border[3],
        borderRadius:'8px',
    },
    editTitle:{
        fontSize:'14px',
    }
}));

export default function MeeingEdit(props) {
    const classes = useStyles();
    const { token } = useToken();
    const [friends, setFriends] = useState([]);
    const [host, setHost] = useState(null);
    const [duration, setDuration] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [title, setTitle] = useState("");
    const [meetingId, setMeetingId] = useState(null);

    const [participants, setParticipants] = useState([]);
    const [titleError, setTitleError] = useState(false);

    const [participantsError, setParticipantsError] = useState(false);
    const [otherError, setOtherError] = useState("");
    const [agendaItems, setAgendaItems] = useState([]);
    const [inquireItems, setInquireItems] = useState([]);
    const [guestCanJoin, setGuestCanJoin] = useState(false);

    const [loading, setLoading] = React.useState(false);
    const re_email_format = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const {application,refreshApplication} = useContext(ApplicationContext);
    let timer;

    const [currentOrg, setCurrentOrg] = useState(props.meet.organizationId);


    const filter = createFilterOptions();

    useEffect(()=>{
        if(currentOrg!==""){
            organizationService.getUsersByOrgId(currentOrg).then((result) => {
                const options = [];
                if(result.data && result.data.users){
                    const users = result.data.users;
                    for (let i = 0; i < users.length; i++) {
                        const item = users[i];
                        if (item.email !== token.username) {
                            options.push({ label: item.username + " (" + item.email + ")", value: item.email });
                        } else {
                            options.push({ label: "you (" + item.email + ")", value: item.email });
                        }
                    }
                }
                setFriends(options);
                // console.log(host)
            })
        }
        let copy_agendaItems = [];
        if(props.meet.agenda && props.meet.agenda.length>0){
            props.meet.agenda.split(",").map((item)=>{
                copy_agendaItems.push({value:item});
            })
        }
        if(copy_agendaItems&&copy_agendaItems.length===0){
            setAgendaItems([{ value: "" }]);
        }
        else{
            setAgendaItems(copy_agendaItems);
        }

        let copy_participants = [];
        if(props.meet.participants&&props.meet.participants.length>0){
            props.meet.participants.split(",").map((item)=>{
                copy_participants.push({label:item,value:item});
            })
        }
        setParticipants(copy_participants);

        setMeetingId(props.meet.id);
        setTitle(props.meet.title);
        setStartDate(moment(props.meet.startTime));
        setStartTime(moment(props.meet.startTime));
        if(props.meet.requests&&props.meet.requests.length===0){
            setInquireItems([{ name: "" ,displayName:"",requesters:[]}]);
        }
        else{
            setInquireItems(props.meet.requests);
        }
        if(token.username === props.meet.host){
            setHost({ label: "you (" + props.meet.host + ")", value: props.meet.host });
        }
        else{
            setHost({ label: props.meet.host, value: props.meet.host });
        }
        setDuration(props.meet.duration)
        setGuestCanJoin(props.meet.guestCanJoin);
    },[props])

    const [orgMax10Paticipants, setOrgMax10Paticipants] = useState(true);
    const [canInviteExtra, setCanInviteExtra] = useState(false);
    useEffect(()=>{
        if(currentOrg && application){
            if(OrgRoleReader.IsOrgAdmin(currentOrg,application) || OrgRoleReader.IsOrgManager(currentOrg,application))
            {
                setCanInviteExtra(true);
            }

            if(OrgRoleReader.getOrgServicePackById(currentOrg,application) === "max 10 meeting attendees"){
                setOrgMax10Paticipants(true)
            }
            else{
                setOrgMax10Paticipants(false)
            }
        }
    },[currentOrg,application]);

    const handleClose = () => {
        props.onClose();
    };

    const handleUpdateMeeting = () => {
        let valied = true;

        if (title === "") {
            setTitleError(true)
            valied = false;
        }
        if (participants.length === 0) {
            setParticipantsError(true)
            setOtherError('Please select 1 Participants at least')
            valied = false;
        }
        if (orgMax10Paticipants && participants.length > 10) {
            setParticipantsError(true)
            setOtherError('The maximum number of the meeting attendees is 10.')
            valied = false;
        }
        if (valied === true) {
            setLoading(true);
            let values = {}
            //console.log(startDate)
            const year = startDate.year();
            const month = startDate.month();
            const date = startDate.date();
            startTime.year(year);
            startTime.month(month);
            startTime.date(date);

            // console.log(startTime.utc().format());
            values.startTime = startTime.utc().format();
            values.agenda = agendaItems.map(item => item.value).join(',');
            let copy_inquireItems = [];
            if(inquireItems && inquireItems.length === 1 && inquireItems[0] && inquireItems[0].name === ''){
                values.requests = [];
            }else{
                inquireItems.map((item)=>{
                    if(item.name!==''){
                        copy_inquireItems.push(item);
                    }
                })
            }
            values.requests = copy_inquireItems;
            console.log(values.requests)
            values.duration = duration;
            values.host = host.value;
            values.startDate = values.startTime;
            values.participants = participants.map(item => item.value);
            values.title = title;
            values.servicePackage = orgRoleReader.getOrgServicePackById(currentOrg,application);
            values.guestCanJoin = guestCanJoin;
            values.organizationId = currentOrg;
            console.log('Success:', values);
            if (props.method==="update"){
                meetingService.updateMeeting(meetingId,values).then((result) => {
                    if (result.status === 200) {
                        props.onClose();
                        props.onRefresh();
                    }
                    setLoading(false);
                })
            }
            else{
                meetingService.createMeeting(values).then((result) => {
                    if (result.status === 200) {
                        props.onClose();
                        props.onRefresh();
                    }
                    setLoading(false);
                })
            }

        }
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
        if (title === "") {
            setTitleError(true)
        }
        else {
            setTitleError(false)
        }
    };

    const handleAddParticipant = (event, newValue) => {
        setParticipantsError(false);
        setOtherError('');
        if (newValue.length === 0) {
            setParticipantsError(true);
            setOtherError('Please select 1 Participants at least');
        }
        if( orgMax10Paticipants && newValue.length > 10){
            setOtherError('The maximum number of the meeting attendees is 10.');
        }
        const tmp = [];
        for(const p of newValue){
            let email = p.value;
            if(p && p.value && p.value.indexOf('$invite:') === 0){
                email = p.value.split(':')[1];
            }
            if(p.value && re_email_format.test(email)){
                tmp.push(p);
            }
        }
        setParticipants(tmp);

    };
    const handleParticipantInputChnage = (event,value)=>{
        if(OrgRoleReader.IsOrgAdmin(currentOrg,application)||OrgRoleReader.IsOrgManager(currentOrg,application)){
            let userOptions=[];
            console.log(timer);
            if(timer){
                clearTimeout(timer);
            }
            if(value!=""&&value.length>2&&value!="you ("+token.username+")"){

                timer = setTimeout(()=>{
                    organizationService.getUsersBySearch(value.toLowerCase()).then((result) =>{
                        if(result && result.data.length>0){
                            result.data.map((item)=>{
                                userOptions.push({
                                    value: item.email,
                                    label: item.username + " (" + item.email + ")"
                                });
                        })};
                        setFriends(userOptions);
                    })
                },500);
            }
            else{
                organizationService.getUsersByOrgId(currentOrg).then((result) => {
                    const options = [];
                    if(result.data && result.data.users){
                        const users = result.data.users;
                        for (let i = 0; i < users.length; i++) {
                            const item = users[i];
                            if (item.email !== token.username) {
                                options.push({ label: item.username + " (" + item.email + ")", value: item.email });
                            } else {
                                options.push({ label: "you (" + item.email + ")", value: item.email });
                            }
                        }
                    }
                    setFriends(options);
                })
            }
        }
    }

    const addAgendaItemHandler = (value) => {
        if (agendaItems.length < 12) {
            const copy_agendaItems = JSON.parse(JSON.stringify(agendaItems));
            copy_agendaItems.push({ value: "" });
            setAgendaItems(copy_agendaItems);
        }
    };
    const addInquireItemHandler = (value) => {
        if (inquireItems.length < 11) {
            const copy_inquireItems = JSON.parse(JSON.stringify(inquireItems));
            copy_inquireItems.push({ name: "" ,displayName:"",requesters:[]});
            setInquireItems(copy_inquireItems);
        }
    };
    const removeAgendaItemHandler = (index) => {
        const copy_agendaItems = JSON.parse(JSON.stringify(agendaItems));
        copy_agendaItems.splice(index, 1);
        console.log("copy_agendaItems", copy_agendaItems);
        setAgendaItems(copy_agendaItems);
    };

    const removeInquireItemHandler = (index) => {
        const copy_inquireItems = JSON.parse(JSON.stringify(inquireItems));
        copy_inquireItems.splice(index, 1);
        console.log(copy_inquireItems)
        setInquireItems(copy_inquireItems);
    };

    const updateAgendaContent = (item, e) => {
        item.value = e.currentTarget.value;
        const copy_agendaItems = JSON.parse(JSON.stringify(agendaItems));
        setAgendaItems(copy_agendaItems);
    };

    const updateInquireContent = (item, e) => {
        item.name = e.currentTarget.value;
        item.displayName = e.currentTarget.value;
        item.requesters = [];
        const copy_inquireItems = JSON.parse(JSON.stringify(inquireItems));
        setInquireItems(copy_inquireItems);
    };

    const getShortName = (item) => {
        const value = item.label? item.label:item.value;
        if(!value){
            return;
        }
        let username = ""
        if(value.indexOf('(')===-1){
            username = value;
        }
        else{
            if(value.substring(0,6)==="Invite"){
                username = value.substring(value.indexOf('(') + 2, value.indexOf(')') - 2);
            }
            else{
                username = value.substring(0, value.indexOf('(') - 1);
            }
        }

        const names = username.split(' ');
        let abbr = "";
        if (names.length <= 1) {
            abbr = username.charAt(0);
        } else {
            abbr = names[0].charAt(0) + names[1].charAt(0)
        }
        return abbr.toUpperCase()
    }

    const handleSwitchChange = (event) =>{
        setGuestCanJoin(event.target.checked);
    }

    return (
        <div className={classes.box}>
            <Dialog open={props.open} scroll="body" classes={{paper: classes.dialogContainer,}} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title"><Typography variant="body1">{props.method==="update"?"Update Meeting":"New Meeting"}</Typography></DialogTitle>
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                                <Divider variant="middle"></Divider>
                                <DialogContent classes={{ root: classes.dialogRoot, }}>
                                    <form noValidate autoComplete="off">
                                        <Grid container spacing={1} >
                                            <Grid item xs={12}>
                                                <Grid>
                                                    <span className={classes.editTitle}>Title</span><span className={classes.required}>*</span>
                                                </Grid>
                                                <Grid item>
                                                    <TextField fullWidth
                                                        id="title"
                                                        error={titleError}
                                                        variant="outlined"
                                                        value={title}
                                                        size="small"
                                                        margin="dense"
                                                        required
                                                        onChange={handleTitleChange}
                                                        placeholder="Meeting title"
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item md={5} xs={12}>
                                                <Grid item>
                                                    <span className={classes.editTitle}>Date</span> <span className={classes.required}>*</span>
                                                </Grid>
                                                <Grid item>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk
                                                            disableToolbar
                                                            id="date"
                                                            disablePast
                                                            variant="inline"
                                                            inputVariant="outlined"
                                                            size="small"
                                                            format="MM/DD/yyyy"
                                                            InputAdornmentProps
                                                            value={startDate}
                                                            margin="dense"
                                                            onChange={date => setStartDate(date)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={4} xs={12}>

                                                <Grid item>
                                                    <span className={classes.editTitle}>Time</span> <span className={classes.required}>*</span>
                                                </Grid>
                                                <Grid item>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <KeyboardTimePicker
                                                            id="time"
                                                            variant="inline"
                                                            inputVariant="outlined"
                                                            size="small"
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={startTime}
                                                            onChange={setStartTime}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </Grid>
                                            <Grid md={3} xs={12} item>
                                                <Grid item>
                                                    <span className={classes.editTitle}>Duration</span> <span className={classes.required}>*</span>
                                                </Grid>
                                                <Grid item>
                                                    <Select
                                                        labelId="duration"
                                                        id="duration"
                                                        variant="outlined"
                                                        defaultValue="30"
                                                        value={duration}
                                                        onChange={(event) => {
                                                            setDuration(event.target.value);
                                                        }}
                                                        classes={{ select: classes.outlinedPadding }} className={classes.selectedmargin}
                                                    >
                                                        <MenuItem key={15} value={15}>15 M</MenuItem>
                                                        <MenuItem key={30} value={30}>30 M</MenuItem>
                                                        <MenuItem key={45} value={45}>45 M</MenuItem>
                                                        <MenuItem key={60} value={60}>60 M</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item>
                                                    <span className={classes.editTitle}>Agenda</span>
                                                </Grid>
                                                <Grid item>
                                                    {agendaItems.map((item, index) => {
                                                        return (
                                                            <div className="agendaItem" key={index}>
                                                                <span>{index + 1}</span>
                                                                <TextField fullWidth value={item.value} onChange={(e) => updateAgendaContent(item, e)}
                                                                    variant="outlined"
                                                                    placeholder="Agenda item"
                                                                    margin="dense"
                                                                    size="small" />
                                                                <div className="agendaItemDelete" style={{ visibility: agendaItems.length > 1 ? "visible" : "hidden" }} onClick={() => removeAgendaItemHandler(index)}>
                                                                    <DeleteIcon size="small" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <AddCircleIcon className="agendaItemAdd" onClick={addAgendaItemHandler} style={{marginLeft:'20px'}}>
                                                    </AddCircleIcon>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item>
                                                    <span className={classes.editTitle}>Inquires</span>
                                                </Grid>
                                                <Grid item>
                                                    {inquireItems.map((item, index) => {
                                                        return (
                                                            <div className="agendaItem" key={index}>
                                                                <span>{index + 1}</span>
                                                                <TextField fullWidth value={item.name} onChange={(e) => updateInquireContent(item, e)}
                                                                    variant="outlined"
                                                                    placeholder="Inquire item"
                                                                    margin="dense"
                                                                    size="small" />
                                                                <div className="agendaItemDelete" style={{ visibility: inquireItems.length > 1 ? "visible" : "hidden" }} onClick={() => removeInquireItemHandler(index)}>
                                                                    <DeleteIcon size="small" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <AddCircleIcon className="agendaItemAdd" onClick={addInquireItemHandler} style={{marginLeft:'20px'}}>
                                                    </AddCircleIcon>
                                                </Grid>
                                            </Grid>
                                            <Divider classes={{ root: classes.dialogHr, }}></Divider>
                                            <Grid item xs={12}>
                                                <Grid item>
                                                    <span className={classes.editTitle}>Host</span> <span className={classes.required}>*</span>
                                                </Grid>
                                                <Grid item>
                                                    <Autocomplete
                                                        id="Host"
                                                        freeSolo
                                                        value={host}
                                                        options={friends}
                                                        getOptionLabel={(option) => option.label}
                                                        onChange={(event, newValue) => {
                                                            setHost(newValue);
                                                        }}
                                                        onInputChange={handleParticipantInputChnage}
                                                        renderOption={(option) => (
                                                            <React.Fragment>
                                                                {option.label}
                                                            </React.Fragment>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} margin="dense" onKeyDown={e=>{
                                                                if(e.keyCode === 13){
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                }
                                                            }} variant="outlined" size="small" />
                                                        )}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);
                                                            let keyword = params.inputValue.toLowerCase();

                                                            // Suggest the creation of a new value
                                                            if (canInviteExtra && re_email_format.test(keyword) && (keyword!==token.username)) {
                                                                filtered.push({
                                                                value: '$invite:'+keyword,
                                                                label: `Invite ("${keyword}") to join openuse.co`,
                                                              });
                                                            }

                                                            return filtered;
                                                          }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item>
                                                    <span className={classes.editTitle}>Participants</span> <span className={classes.required}>*</span>
                                                </Grid>
                                                <Grid item>
                                                    <Autocomplete
                                                        id="Participants"
                                                        multiple
                                                        freeSolo
                                                        value={participants}
                                                        options={friends}
                                                        filterSelectedOptions={true}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option) => option.label}
                                                        renderOption={(option, { selected }) => (
                                                            <React.Fragment>
                                                                {option.label}
                                                            </React.Fragment>
                                                        )}
                                                        getOptionSelected={(option, value) => option.value === value.value}
                                                        onChange={handleAddParticipant}
                                                        onInputChange={handleParticipantInputChnage}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);
                                                            let keyword = params.inputValue.toLowerCase();
                                                            // Suggest the creation of a new value

                                                            if (canInviteExtra) {
                                                                if(re_email_format.test(keyword)&&(keyword!==token.username)){
                                                                    filtered.push({
                                                                        value: '$invite:'+keyword,
                                                                        label: `Invite ("${keyword}") to join openuse.co`
                                                                    });
                                                                }
                                                            }
                                                            return filtered;
                                                          }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} onKeyDown={e=>{
                                                                if(e.keyCode === 13){
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                }
                                                            }} margin="dense" error={participantsError} variant="outlined" size="small" placeholder={orgMax10Paticipants?"The maximum number of the meeting attendees is 10.":"Please add meeting attendees"}/>
                                                        )}
                                                    />
                                                    <span className={otherError==='' ? classes.errorNotShow : classes.errorShow}>{otherError}</span>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                >
                                                    {participants.map((item, index) => {
                                                        return (
                                                            <Grid item key={index}>
                                                                <Avatar className={classes.orange}>{getShortName(item)}</Avatar>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                                {process.env.REACT_APP_ADMINS.indexOf(token.username) >= 0 && <Grid item>
                                                <span className={classes.editTitle}>Allow guest: </span><Switch
                                                        checked={guestCanJoin}
                                                        onChange={handleSwitchChange}
                                                        name="checkedB"
                                                        color="secondary"
                                                    />
                                                </Grid>}
                                            </Grid>
                                        </Grid>
                                    </form>
                                </DialogContent>
                                <DialogActions>
                                    <LoadingButton isLoading={loading} variant="contained" onClick={handleUpdateMeeting} fullWidth color="primary">
                                        {props.method==="update"?"Update Meeting":"New Meeting"}
                                    </LoadingButton>
                                </DialogActions>
                            </Dialog>
        </div>
    )
}
