import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useState } from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    box: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '180px',
    }
  }));
export default function ComingSoon(props) {
    const classes = useStyles();

    return (
        <div className={classes.box}>
            <Grid container justifyContent="center">
                <Typography variant="h3">
                    {props.title + ' is coming soon!'}
                </Typography>
            </Grid>
        </div>
        
    );
}