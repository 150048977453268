import {axiosCore as axios} from './axiosHelper';

const createOrg = function(org){
    const url = '/auth/organizations';
    return new Promise((resolve, reject)=>{
        axios.post(url,org).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateOrg = function(id,org){
    const url = '/auth/organizations/'+id+'/';
    return new Promise((resolve, reject)=>{
        axios.patch(url,org).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const deleteOrg = function(id){
    const url = `/auth/organizations/${id}`;
    return new Promise((resolve, reject)=>{
        axios.delete(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getOrgRoles = function(id){
    const url = `/auth/organizations/${id}/roles`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const delOrgMember = function(id, delUsers){
    const url = `/auth/organizations/${id}/users`;
    return new Promise((resolve, reject)=>{
        axios.delete(url,{data:delUsers}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateOrgMember = function(id, updateOrg){
    const url = `/auth/organizations/${id}/users`;
    return new Promise((resolve, reject)=>{
        axios.post(url,updateOrg).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getUsersByOrgId = function(orgId){
    const url = `/auth/organizations/${orgId}/users`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getUsersBySearch = function(keywords){
    const url = `/auth/users?searchText=${keywords}`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const extendOrgByOrgId = function(orgId){
    const url = `/auth/organizations/${orgId}`;
    return new Promise((resolve, reject)=>{
        const action ={"action":"extend"};
        axios.patch(url,action).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const enableOrgByOrgId = function(orgId){
    const url = `/auth/organizations/${orgId}/enable`;
    return new Promise((resolve, reject)=>{
        axios.patch(url,{}).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getServicePackages = function(appIds,packageName){
    const url = `/auth/payment/package?serviceIds=${appIds}&packageName=${packageName}`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const createOrgOrder = function(orgOrder){
    const url = `/auth/payment/orders`;
    return new Promise((resolve, reject)=>{
        axios.post(url,orgOrder).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const changeOrgService = function(orgId,orgOrder){
    const url = `/auth/payment/orders/${orgId}/change-package`;
    return new Promise((resolve, reject)=>{
        axios.patch(url,orgOrder).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateOrgOrderStatus = function(orderId,status){
    const url = `/auth/payment/orders/${orderId}/status`;
    return new Promise((resolve, reject)=>{
        axios.patch(url,status).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getPaymentOrders = function(){
    const url = `/auth/payment/orders`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getPaymentOrdersByOrgId = function(orgId){
    const url = `/auth/payment/${orgId}/orders`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const applyCoupon = function(data){
    const url = `/auth/payment/coupon`;
    return new Promise((resolve, reject)=>{
        axios.patch(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const updateOrgTimezone = function(orgId,data){
    const url = `/auth/organizations/${orgId}/timezone`;
    return new Promise((resolve, reject)=>{
        axios.patch(url,data).then(result=>{
            resolve(result);
        }).catch(e=>{
            reject(e);
        })
    })
}

const getTransactionsOrg = () => {
    const url = `/auth/application/organizations`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(result => {
            resolve(result);
        }).catch(e => {
            reject(e);
        })
    });
}

const getTransactionsOrgByEmail = (username) => {
    const url = `/auth/company-org/organizations?email=${username}`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(result => {
            resolve(result);
        }).catch(e => {
            reject(e);
        })
    });
}

const setTransactionsOrg = (orgId) => {
    const url = `/auth/organizations/${orgId}/company-org`;
    return new Promise((resolve, reject) => {
        axios.patch(url).then(result => {
            resolve(result);
        }).catch(e => {
            reject(e);
        })
    })
}

export default {
    createOrg,
    deleteOrg,
    getOrgRoles,
    delOrgMember,
    updateOrgMember,
    getUsersByOrgId,
    getUsersBySearch,
    extendOrgByOrgId,
    updateOrg,
    enableOrgByOrgId,
    getServicePackages,
    createOrgOrder,
    updateOrgOrderStatus,
    changeOrgService,
    getPaymentOrders,
    applyCoupon,
    getPaymentOrdersByOrgId,
    updateOrgTimezone,
    getTransactionsOrg,
    setTransactionsOrg,
    getTransactionsOrgByEmail,
}
