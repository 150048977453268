import React , {useContext, useEffect, useState} from "react";
import { Link,useParams,useHistory} from 'react-router-dom';
import {Button, Select, Typography, Grid, TextField, IconButton, MenuItem} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useToken from '../../hooks/useToken';

import UndoIcon from '@material-ui/icons/Undo';
import CloseIcon from '@material-ui/icons/Close';

import { ApplicationContext } from "../../context/applicationContext";
import organizationService from '../../services/organizationService';
import { useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Edit } from "@material-ui/icons";
import alipayIcon from '../../assets/img/alipay.svg';
import stripeIcon from '../../assets/img/stripe.svg';
import paymnetService from '../../services/paymentService';
import {loadStripe} from '@stripe/stripe-js';
import LoadingButton from "../../components/LoadingButton";
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme=> ({
    organizationBox: {
        width:"100%",
        maxWidth:"1200px",
    },
    organizationBorder: {
        margin:"2vw 4vw",
        borderRadius: "8px",
    },
    organizationCreateTitle: {
        padding: "21px 0 21px 27px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[3],
    },
    undoIcon: {
        marginRight: "23px",
        color:"#3f51b5",
    },
    organizationCreateContent: {
        padding: "22px 108px",
        '@media (max-width:600px)':  {
            padding: '22px 27px 0px 27px',
        },
    },
    required: {
        color: "#981414",
        display: "inline",
    },
    textField: {
        margin: "7px 0 11px 0",
    },
    muiInput: {
        padding: "9.5px 7px",
    },
    newOrgTriBtnOutBox: {
        margin: "0.78vw 0.78vw 0 0",
    },
    newOrgTriBtnBox: {
        width: "120px",
        borderRadius: "5px",
        marginRight: "10px",
        marginBottom:"20px"
    },
    orgContentTxt: {
        marginBottom: "23px",
    },
    orgCreateContentBox: {
        padding: "0 89px 0 89px",
        '@media (max-width:960px)':  {
            padding: '0px',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    link: {
        cursor: "pointer"
    },
    dialogBox: {
        backgroundColor: theme.currentTheme.backgrounds[12],
        border: theme.currentTheme.border[3],
    },
}))


export default function OrganizationCreate(){
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };
    const params = useParams();
    const { token, setToken} = useToken();

    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const {application, applications, refreshApplications} = useContext(ApplicationContext);

    const organizationPage = "/management/services/:service_name/organizations"

    const history = useHistory();
    const [loading, setLoading] = React.useState(false);    

    const [addOrgName, setAddOrgName] = useState('');
    const [submitError, setSubmitError] = useState("");
    const [addProduct, setAddProduct] = useState([{label:t('newOrg.Service1'),value:"Open Book"},{label:t('newOrg.Service2'),value:"Open Comm"},{label:t('newOrg.Service3'),value:"Open Office"}]);
    // const [addProduct, setAddProduct] = useState([{label:t('newOrg.Service1'),value:"Open Book"},{label:t('newOrg.Service2'),value:"Open Comm"}]);
    const [openBookPackages, setOpenBookPackages] = useState([
        {label:t('newOrg.openbookPackage1'),value:'month basic package'}, 
        {label:t('newOrg.openbookPackage2'),value:'month premium package'}, 
        // {label:t('newOrg.openbookPackage3'),value:'year basic package/ 5 users'}, 
        // {label:t('newOrg.openbookPackage4'),value:'year premium package/ 20 users'},
        {label:t('newOrg.servicePackvalueTrial'),value:'Trial subscription'}]);
    const [liveSourcingPackages, setLiveSourcingPackages] = useState([
        {label:t('newOrg.liveSourcingPackage1'),value:'month basic package/ 10 users'},  
        {label:t('newOrg.liveSourcingPackage2'),value:'month basic package/ 20 users'},
        {label:t('newOrg.liveSourcingPackage3'),value:'month basic package/ 30 users'}, 
        {label:t('newOrg.liveSourcingPackage4'),value:'month premium package/ 10 users'}, 
        {label:t('newOrg.liveSourcingPackage5'),value:'month premium package/ 20 users'}, 
        {label:t('newOrg.liveSourcingPackage6'),value:'month premium package/ 30 users'},
        {label:t('newOrg.servicePackvalueTrial'),value:'Trial subscription'}]);
    const [selProduct, setSelProduct] = useState("");

    const [allService, setAllService] = useState([]);

    const [addService, setAddService] = useState([]);
    useEffect(()=> {
        console.log(applications);
        if(selProduct){
            let arr = [];
            let allArr = [];
            applications.forEach(item=> {
                if(item.category === selProduct){
                    let arr2 = {app_id:item.id, app_display_name:item.display_name};
                    arr2.app_display_name_Lan=arr2.app_display_name;
                    if(item.display_name==='PO Transactions'){
                        arr2.app_display_name_Lan=t('newOrg.servicePackage1');
                        arr.push(arr2);
                    }
                    if(item.display_name==='Inventory'){
                        arr2.app_display_name_Lan=t('newOrg.servicePackage2');
                        arr.push(arr2);
                    }
                    if(item.display_name==='Open Comm'){
                        arr2.app_display_name_Lan=t('newOrg.Service2');
                        arr.push(arr2);
                    }
                    if(item.display_name==='Live Sourcing'){
                        arr2.app_display_name_Lan=t('newOrg.servicePackage3');
                        arr.push(arr2);
                    }
                }
                let allArr2 = {app_id:item.id, app_display_name:item.display_name};
                allArr.push(allArr2);
            })
            setAddService(arr);
            setAllService(allArr);
        }
    },[selProduct])


    useEffect(()=> {
        if(params.service_name&&params.service_name!==''){
            setSelProduct(params.service_name);
            setSelService([]);
            setSelServiceIds([]);
            setSelServiceName("");
        }
    },[])

    const [addServicePkgsTime, setAddServicePkgsTime] = useState("");

    const [selService, setSelService] = useState([]);
    useEffect(()=> {
        if(history.location.state && history.location.state.fromYourOrg){
            setSelProduct("");
            setSelService([]);
            setSelServiceIds([]);
            setSelServiceName("");
        }else if(history.location.state && history.location.state.fromPrice){
            setSelProduct(params.service_name);
            setSelService([]);
            setSelServiceIds([]);
            setSelServiceName("");
        }else if(application){
            setSelService([application.name]);
            setSelServiceIds([application.id]);
            setSelProduct(application.category);
            setSelServiceName(application.name);
            setServicePer(true);
        }
    },[application])

    const [selServiceIds, setSelServiceIds] = useState([]);
    // useEffect(()=> {
    //     let appIds=[];
    //     allService.map(item => {
    //         selService.map(newItem =>{
    //             if(item.app_display_name === newItem){
    //                 let arr = item.app_id
    //                 appIds.push(arr);
    //             }
    //         })
    //     })
    //     setSelServiceIds(appIds);
    // },[selService])

    const setAppIdsBySelServices = (services)=>{
        let appIds=[];
        allService.map(item => {
            services.map(newItem =>{
                if(item.app_display_name === newItem){
                    let arr = item.app_id
                    appIds.push(arr);
                }
            })
        })
        setSelServiceIds(appIds);
    }

    const [addServicePkgs, setAddServicePkgs] = useState([]);
    useEffect(()=> {
        if(selProduct){
            if(selProduct==="Open Comm"){
                setAddServicePkgs([{label:t('newOrg.servicePackComm1'),value:'max 10 meeting attendees'}, {label:t('newOrg.servicePackComm2'),value:'10+ meeting attendees'}]);
                setAddServicePkgsTime("30 days trial");
            }else if(selProduct==="Open Book"){
                setAddServicePkgs(openBookPackages);
                setAddServicePkgsTime("90 days trial");
            }else if(selProduct==="Open Office"){
                setAddServicePkgs(liveSourcingPackages);
                setAddServicePkgsTime("90 days trial");
            }
        }
    },[selProduct])

    const [servicePer, setServicePer] = useState(false);

    const [selServiceName, setSelServiceName] = useState('');

    const [selServicePkgs, setSelServicePkgs] = useState('');

    const [orgValidate, setOrgValidate] = useState(false);

    const [proValidate, setProValidate] = useState(false);

    const [serValidate, setSerValidate] = useState(false);

    const [pkgsValidate, setPkgsValidate] = useState(false);
    const [coupon, setCoupon] = useState('');
    const [payment, setPayment] = useState('stripe');
    const [currency, setCurrency] = useState('RMB');
    const [currencyList, setCurrencyList] = useState([]);

    
    const [showPayBtn, setShowPayBtn] = useState(false);
    const [showTrailBtn, setShowTrailBtn] = useState(false);
    const [showContactBtn, setShowContactBtn] = useState(false);

    const [open, setOpen] = useState(false);

    const inputChange = (event) => {
        setAddOrgName(event.target.value);
        setSubmitError("");
        if(event.target.value===""){
            setOrgValidate(true);
        }else {
            setOrgValidate(false);
        }    
    }

    const handlerProductChange = (e) => {
        setSelService([]);
        setSelServiceIds([]);
        setSelServicePkgs("");
        let temp = e.target.value;
        setSelProduct(temp);
        if(temp===""){
            setProValidate(true);
        }else {
            setProValidate(false);
        }
    };

    const handleServiceChange= (e) => {
        // setSelServicePkgs("");
        let temp = e.target.value;
        setSelService(temp);
        setAppIdsBySelServices(temp);
        if(temp === "" || !(temp && temp.length>0) ){
            setSelServicePkgs("");
            setSerValidate(true);
        }else {
            setSerValidate(false);
        }
    };

    const handleSerPkgsChange = (event) => {
        let temp = event.target.value;
        setSelServicePkgs(temp);
        if(temp===""){
            setPkgsValidate(true);
        }else {
            setPkgsValidate(false);
        }
        if(temp === 'max 10 meeting attendees' || temp === 'Trial subscription'){
            setShowPayBtn(false);
            setShowTrailBtn(true);
            setShowContactBtn(false);
        }else{
            console.log(openBookPackages);
            console.log(temp);
            if(openBookPackages.some(item => item.value === temp)||liveSourcingPackages.some(item => item.value === temp)){
                setShowPayBtn(true);
                setShowTrailBtn(false);
                setShowContactBtn(false);
            }
            else{
                setShowPayBtn(false);
                setShowTrailBtn(false);
                setShowContactBtn(true);
            }
        }
    };

    useEffect(()=> {
        if(selServiceIds!==""&&selServicePkgs!==""){
            organizationService.getServicePackages(selServiceIds,selServicePkgs).then(result=>{
                if(result.status===200){
                    setCurrencyList(result.data);
                }
            })
        }
    },[selServiceIds,selServicePkgs])

    const validate = () => {
        let temp  = false;
        if(!addOrgName){
            setOrgValidate(true);
            temp = false;
        }
        if(!selProduct){
            setProValidate(true);
            temp = false;
        }
        if(!(selService && selService.length > 0)){
            setSerValidate(true);
            temp = false;
        }
        if(!selServicePkgs){
            setPkgsValidate(true);
            temp = false;
        }
        if(addOrgName&&selProduct&&selService&&selServicePkgs){
            temp = true;
        }
        return temp;
    }

    const addOrgCmp = () => {
        setLoading(true);
        if(validate()){
            let org = {
                "name": addOrgName,
                "display_name": addOrgName,
                "description":addOrgName,
                "appIds": selServiceIds,
                "servicePackage": selServicePkgs,
                "product": selProduct,
            }
            // console.log("CREATE ORG ITEM", org);
            organizationService.createOrg(org).then((result)=> {
                if(result&&result.status===200&&result.data){
                    setLoading(false);
                    refreshApplications();
                    history.push({
                        pathname: organizationPage,
                        state: {refresh: true},
                    });
                }
            }).catch((e) => {
                setLoading(false);
                if(e.response.data.message.indexOf("The org name")>-1 && e.response.data.message.indexOf("is existed, please check")>-1){
                    setSubmitError(t('newOrg.orgExistError'));
                    setOrgValidate(true);
                }
            })
        }
        else{
            setLoading(false);
        }
    }

    const contactPopup = () => {
        // if(validate()){
        //     let org = {
        //         "name": addOrgName,
        //         "display_name": addOrgName,
        //         "description":addOrgName,
        //         "appIds": selServiceIds,
        //         "servicePackage": selServicePkgs,
        //         "product": selProduct,
        //     }
        //     // console.log("CONTACT ORG ITEM", org);
        //     organizationService.createOrg(org).then((result)=> {
        //         if(result.data){
        //             setOpen(true);
        //         }
        //     }) 

        // }
        setOpen(true);

    }

    const contactPopupClose = () => {
        setOpen(false);
    }

    const toHome = () => {
        window.location.href = "/";
    }

    const goBack = () => {
        history.go(-1);
    }

    const handlePaymentChange = (e)=>{
        setPayment(e.target.value);
    }

    const handleCurrencyChange =(e)=>{
        console.log(e.target.value);
        setCurrency(e.target.value);
    }

    const paymentHandler =()=>{
        if(validate()){
            setLoading(true);
            let org = {
                "name": addOrgName,
                "display_name": addOrgName,
                "description":addOrgName,
                "appIds": selServiceIds,
                "servicePackage": selServicePkgs,
                "product": selProduct,
                "currency": currency,
                "paymentType": payment,
                "successUrl": process.env.REACT_APP_PAYMENT_STRIPE_SUCCESS_URL,
                "failureUrl": process.env.REACT_APP_PAYMENT_STRIPE_FAIL_URL,
            }
            // console.log("CONTACT ORG ITEM", org);
            organizationService.createOrgOrder(org).then((result)=> {
                if(result.status===200){
                    console.log(result.data);
                    if(result.data){
                        if(payment==='stripe'){
                            let sessionId = result.data.stripeSessionId;
                            loadStripe(process.env.REACT_APP_PAYMENT_STRIPE_PK).then(stripe=>{
                                stripe.redirectToCheckout({ sessionId: sessionId })
                            });
                        }
                    }
                }
            }).catch((e) => {
                setLoading(false);
                if(e.response.data.message.indexOf("The org name")>-1 && e.response.data.message.indexOf("is existed, please check")>-1){
                    setSubmitError(t('newOrg.orgExistError'));
                    setOrgValidate(true);
                }
            })
        }
        else{
            setLoading(false);
        }
    }

    return (
        <Grid className={classes.organizationBox}>
            <Grid className={classes.organizationBorder}>     
                <Grid container justifyContent="flex-start" alignItems="center" className={classes.organizationCreateTitle}>
                    <div className={classes.link} onClick={goBack}>
                        <UndoIcon color="primary" className={classes.undoIcon} ></UndoIcon>
                    </div>
                    <Typography variant="body2" gutterBottom style={{"fontWeight":"bold"}}>
                        {t('newOrg.createOrg')}
                    </Typography>
                </Grid>
                <Grid className={classes.organizationCreateContent}>
                    <Grid>
                        <Typography variant="body2" className={classes.orgContentTxt}>{t('newOrg.createMsg')}</Typography>
                    </Grid>

                    <Grid className={classes.orgCreateContentBox}>
                        <Grid item>
                            <Grid container>
                                <Typography variant="body2" gutterBottom >
                                {t('newOrg.orgTitle')}
                                </Typography>
                                <Typography variant="body2" gutterBottom className={classes.required}>
                                    *
                                </Typography>
                            </Grid>
                            
                            <TextField
                                id="standard-full-width"
                                required={true}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                error={orgValidate}
                                onChange={inputChange}
                                variant="outlined"
                                className={classes.textField}
                            />
                        </Grid>

                        <Grid item>
                            <Grid container>
                                <Typography variant="body2" gutterBottom >
                                {t('newOrg.serviceCategory')}
                                </Typography>
                                <Typography variant="body2" gutterBottom className={classes.required}>
                                    *
                                </Typography>
                            </Grid>
                            <Select 
                                fullWidth
                                required={true}
                                variant="outlined"
                                size="small"
                                value={selProduct}
                                error={proValidate}
                                onChange={handlerProductChange}
                                className={classes.textField} 
                                disabled={servicePer}
                                classes={{select:classes.muiInput}}
                            >
                                {addProduct && addProduct.map( (item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        <Typography variant="body2">
                                            {item.label} 
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item>
                            <Grid container>
                                <Typography variant="body2" gutterBottom >
                                {t('newOrg.Service')}
                                </Typography>
                                <Typography variant="body2" gutterBottom className={classes.required}>
                                    *
                                </Typography>
                            </Grid>
                            <Select 
                                fullWidth
                                multiple
                                required={true}
                                variant="outlined"
                                size="small"
                                value={selService}
                                error={serValidate}
                                className={classes.textField} 
                                //disabled={!selProduct || servicePer}
                                disabled={!selProduct}
                                classes={{select:classes.muiInput}}
                                onChange={handleServiceChange}
                                renderValue={(selected) => selected.join(', ')} 
                                MenuProps={MenuProps}
                            >
                                {addService && addService.map(item => (
                                    <MenuItem key={item.app_id} value={item.app_display_name}>
                                        <Checkbox style={{color:"#ff5722"}} checked={selService.indexOf(item.app_display_name) > -1} />
                                        <ListItemText primary={item.app_display_name_Lan} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item>
                            <Grid container>
                                <Typography variant="body2" gutterBottom >
                                {t('newOrg.servicePackage')}
                                </Typography>
                                <Typography variant="body2" gutterBottom className={classes.required}>
                                    *
                                </Typography>
                            </Grid>
                            <Select 
                                fullWidth
                                required={true}
                                variant="outlined"
                                size="small"
                                value={selServicePkgs}
                                error={pkgsValidate}
                                defaultValue={addServicePkgs[0]}
                                onChange={handleSerPkgsChange}
                                className={classes.textField} 
                                disabled={!selProduct || !(selService && selService.length>0) }
                                classes={{select:classes.muiInput}}
                            >
                                {addServicePkgs && addServicePkgs.map(item => (
                                    <MenuItem key={item.label} value={item.value}>
                                        <Typography variant="body2">
                                            {item.label}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        {showPayBtn&&<Grid item>
                            <Grid container direction="row">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="gender" name="gender1" value={currency} onChange={handleCurrencyChange}>
                                        {currencyList&&currencyList.map((item,index)=>(
                                            <Grid item key={index}><FormControlLabel value={item.currency} control={<Radio />} label={item.currency+" "+item.totalPrice} /></Grid>
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>} 
                         {showPayBtn&&<Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="gender" name="gender1" value={payment} onChange={handlePaymentChange}>
                                    <Grid container alignItems="center">
                                        <Grid item><FormControlLabel value="stripe" control={<Radio />} label="" /></Grid>
                                        <Grid item><img width="80px" src={stripeIcon}></img></Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>} 
                        {submitError!=="" && <Grid container alignItems="center" justifyContent="center" direction="row" style={{marginTop:'8px'}}>
                            <Alert severity="error" style={{marginBottom:'6px'}}><Typography>{submitError}</Typography></Alert>
                        </Grid>}
                        {
                            (selServicePkgs) &&
                            <Grid item>
                                <Grid container justifyContent="flex-start" className={classes.newOrgTriBtnOutBox}>
                                    {showTrailBtn&&<LoadingButton isLoading={loading} variant="contained" color="primary" className={classes.newOrgTriBtnBox} onClick={addOrgCmp}>      
                                        <Typography variant="body2">
                                            {t('newOrg.trialButton1')}
                                        </Typography>
                                    </LoadingButton>}

                                    {showPayBtn&&<LoadingButton isLoading={loading} variant="contained" color="primary" className={classes.newOrgTriBtnBox} onClick={paymentHandler}>
                                        <Typography variant="body2">
                                        {t('newOrg.payButton')}
                                        </Typography>
                                    </LoadingButton>}
                                    {showContactBtn&&<Button variant="contained" color="primary" className={classes.newOrgTriBtnBox} onClick={contactPopup}>
                                        <Typography variant="body2">
                                        {t('newOrg.payButton')}
                                        </Typography>
                                    </Button>}
                                </Grid>
                            </Grid>  
                        }
                    </Grid>
                </Grid>
                
            </Grid>

            <Dialog open={open} onClose={contactPopupClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title" className={classes.dialogBox}>
                <DialogTitle id="form-dialog-title">{t('newOrg.contactTitle')}</DialogTitle>
                <IconButton aria-label="close" className={classes.closeButton} onClick={contactPopupClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Typography variant="body2" gutterBottom>
                    {t('newOrg.contactTips')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={contactPopupClose} color="primary">
                        <Typography variant="body2" gutterBottom>
                        {t('common.ok')}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}