import React, {useEffect, useState, useRef, useContext} from 'react';
import {useParams, useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, IconButton, TextField, Select, MenuItem, Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  lineDateInput: {
    '& > *': {
      '& > *': {
        textAlign: "center"
      },
    },
  },
  buttonCol: {
    color: theme.currentTheme.colors[0],
  },
}))

export default function NotesEditor(props) {
  const {t} = useTranslation();
  const [noteOpen, setNoteOpen] = useState(false);
  const [value, setValue] = useState(props.value)

  const handleNoteClose = () => {
    setValue(props.value);
    setNoteOpen(false);
  }
  const handleNotesChange = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);
  }

  const saveNotes = () => {
    props.onChange(value);
    setNoteOpen(false);
  }

  return (
    <>
      <TextField value={value} onClick={e => setNoteOpen(true)}/>
      <Dialog open={noteOpen} onClose={handleNoteClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
        <DialogContent>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                multiline
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                rows={6}
                variant="outlined"
                value={value}
                onChange={handleNotesChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={saveNotes}>
            <Typography variant="body2">
              {t('common.confirm')}
            </Typography>
          </Button>
          <Button variant="contained" onClick={handleNoteClose}>
            <Typography variant="body2">
              {t('common.cancel')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

