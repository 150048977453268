import React,{useContext}  from 'react';
import { Component, useEffect, useState, useRef} from 'react';
import { Link, useParams, useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { Grid, Button, Select, MenuItem, FormControl, InputLabel, TextField, Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import useToken from '../../hooks/useToken';
import { ApplicationContext } from "../../context/applicationContext";
import userService from "../../services/userService";
import UndoIcon from '@material-ui/icons/Undo';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import MaterialTable from 'material-table'
import moment from 'moment';
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import inventoryService from '../../services/inventoryService';

import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import organizationService from "../../services/organizationService";
import downloadFileUtils from '../../utils/downloadFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import ChukuIcon from '../../assets/img/chuku.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
        display: 'flex',
        flexDirection: "row",
        maxWidth: '1200px',
        width: '100%',
    },
    box: {
        paddingLeft:'30px',
        paddingTop:'40px',
        width:'100%',
        [theme.breakpoints.down('md')]: {
            paddingLeft:'0',
            paddingTop:'4px',
        }
    },
    undoIcon: {
        marginRight: "23px",
        color:"#3f51b5",
        cursor:'pointer'
    },
    managementTitle: {
        padding: "21px 0 21px 27px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[3],
    },
    managementContent:{
        padding: "21px 0 21px 27px",
        [theme.breakpoints.down('md')]: {
            paddingLeft:'8px',
            paddingTop:'4px',
        }
    },
    editTitle:{
        // marginBottom:'4px',
        // marginTop:'4px',
        fontSize:'14px'
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    dialogHr: {
        border: '1px',
        borderStyle: 'dashed none none none',
        borderColor: theme.currentTheme.colors[6],
        marginTop: '10px',
        marginBottom: '10px',
        width:'100%',
    },
    dialogRoot:{
        marginBottom:'20px',
        color:theme.currentTheme.text,
        fontSize:'14px'
    },
    selectField: {
        padding: "11px 14px",
    },
    required:{
        color:'red'
    },
    customTable:{
        marginTop:'30px',
        [theme.breakpoints.down('md')]: {
            overflow:'scroll'
        }
    }
}));

export default function ReservationManagement() {
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const { token } = useToken();
    const theme = useTheme();

    const tableIcons = {
        Add: forwardRef((props, ref) =>
            <AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>
        ),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        Cancel: forwardRef((props, ref) => <CancelOutlinedIcon {...props} ref={ref} />)
    };

    const history = useHistory();
    const [isReserved, setIsReserved] = useState(true);
    const [searchGoodsKeyWordsInput, setSearchGoodsKeyWordsInput] = useState("");
    const [searchLotKeyWordsInput, setSearchLotKeyWordsInput] = useState("");
    const [searchGoodsKeyWords, setSearchGoodsKeyWords] = useState("");
    const [searchLotKeyWords, setSearchLotKeyWords] = useState("");
    const [checkInOutData, setCheckInOutData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [startDate, setStartDate] = useState(moment().subtract(3, 'months'));
    const [endDate, setEndDate] = useState(moment());
    const [type, setType] = useState("out");
    const [friends,setFriends] = useState([]);

    useEffect(() => {
        userService.getUserDetailsByEmail(token.username).then((result)=>{
            if(result&&result.status===200){
                console.log(result)
            }
        });
        organizationService.getUsersByOrgId(history.location.state.orgId).then((result) => {
            if (result.data && result.data.users) {
              setFriends(result.data.users);
            }
        })
    },[])
    
    useEffect(() => {
        getList();
    },[type,searchGoodsKeyWords,searchLotKeyWords,startDate])

    const getList = ()=>{
        if(type==="in"){
            inventoryService.searchCheckIn(history.location.state.orgId,!startDate?'':moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),!endDate?'':moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),searchGoodsKeyWords,searchLotKeyWords).then(result=>{
                if(result.status===200){
                    let tempCheckIn = [];
                    result.data.datas.map(item=>{
                        item.checkDate = item.checkInDate;
                        item.billNumber = item.inBillNumber;
                        item.refBillNumber = item.refInBillNumber;

                        tempCheckIn.push(item)
                    })
                    setCheckInOutData(tempCheckIn);
                    console.log(tempCheckIn);
                }
            })
        }
        else{
            inventoryService.searchCheckOut(history.location.state.orgId,!startDate?'':moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),!endDate?'':moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),isReserved,searchGoodsKeyWords,searchLotKeyWords).then(result=>{
                if(result.status===200){
                    let tempCheckIn = [];
                    result.data.datas.map(item=>{
                        item.checkDate = item.checkOutDate;
                        item.billNumber = item.outBillNumber;
                        item.refBillNumber = item.refOutBillNumber;
                        tempCheckIn.push(item)
                    })
                    setCheckInOutData(result.data.datas);
                    console.log(result.data.datas);
                }
            })
        }
    }

    const goBackHandler = ()=>{
        history.push("/Inventory")
    }

    const handleSearch = () => {
        setSearchLotKeyWords(searchLotKeyWordsInput);
        setSearchGoodsKeyWords(searchGoodsKeyWordsInput);
    }

    const hanlerEnterSearch =(event)=>{
        console.log(event);
        if (event.key == "Enter"){
            handleSearch();
        }

    }

    const formatDate=(value)=> {
        return value?moment(value).format("YYYY/MM/DD HH:mm"):"";
    }

    const addToolTip=(value)=> {
        return (<div title={value} className={classes.toolTipDiv}>{value}</div>);
    }

    const openDialogHandler =()=>{
        setOpenDialog(true);
    }
    const showLotNumer =(lotNumber)=>{
        return(lotNumber.map(item=>
            <Typography variant="body2">{item}</Typography>
        ))
    }
    const checkLine =(event, rowData)=>{
        if(type==="in"){
            inventoryService.verifyCheckIn(rowData.checkInId).then(result=>{
                if(result.status===200){
                    getList();
                }
            })
        }
        else{
            inventoryService.verifyCheckOut(rowData.checkOutId).then(result=>{
                if(result.status===200){
                    getList();
                }
            })
        }
        
    }

    const downloadLine =(event, rowData)=>{
        if(type==="in"){
            inventoryService.downloadCheckInById(rowData.checkInId,-(((new Date().getTimezoneOffset())/60))).then(result=>{
                if(result.status===200){
                    let types = 'application/vnd.ms-excel';
                    let fileName = `CheckIn ${rowData.billNumber}.xlsx`;
                    let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
                    downloadFileUtils.downLoadFile(blob, fileName); 
                }
            })
        }
        else{
            inventoryService.downloadCheckOutById(rowData.checkOutId,-(((new Date().getTimezoneOffset())/60))).then(result=>{
                if(result.status===200){
                    let types = 'application/vnd.ms-excel';
                    let fileName = `CheckOut ${rowData.billNumber}.xlsx`;
                    let blob = new Blob([result.data], {type: types}); // 将服务端返回的文件流excel文件
                    downloadFileUtils.downLoadFile(blob, fileName);
                }
            })
        }
    }

    const showStaff =(staff)=>{
        let temp = friends.find(item=>{
            return (item.email===staff)
        })
        if(temp){
            return temp.username;
        }
        else{
            return staff;
        }
    }

    return (
        <div className={classes.root}> 
            <div className={classes.box}>
                <Grid container justifyContent="flex-start" alignItems="center" className={classes.managementTitle}>
                    <div onClick={goBackHandler}>
                        <UndoIcon color="primary" className={classes.undoIcon} ></UndoIcon>
                    </div>
                    <Typography variant="h3" gutterBottom style={{"fontWeight":"bold"}}>
                        {t('homePage.resvationMgt')}
                    </Typography>
                </Grid>
                <Grid container justifyContent="flex-start" className={classes.managementContent}>
                    <Grid md={12}>
                        <Grid container justifyContent="flex-start">
                            <TextField id="outlined-basic" variant="outlined" size="small" placeholder={t("checkInOutManagement.searchHint")} style={{width:'200px',verticalAlign:'middle',marginRight:'4px',marginBottom:'8px'}} onChange={e => setSearchGoodsKeyWordsInput(e.target.value)} onKeyPress={hanlerEnterSearch}/>
                            <TextField id="outlined-basic" variant="outlined" size="small" placeholder={t("checkInOutManagement.searchHint1")} style={{width:'200px',verticalAlign:'middle',marginRight:'4px',marginBottom:'8px'}} onChange={e => setSearchLotKeyWordsInput(e.target.value)} onKeyPress={hanlerEnterSearch}/>
                            <IconButton onClick={handleSearch} aria-label="search"><SearchIcon></SearchIcon></IconButton>
                        </Grid>
                    </Grid>
                    <Grid md={12} className={classes.customTable}>
                    <MaterialTable
                            classes={{MuiPaper:classes.noBox}}
                            title=""
                            columns={[
                                {
                                    field: 'warehouse',
                                    title: t('checkInOutManagement.warehouse'),
                                    width: '8%',
                                    sorting: true,
                                },
                                {
                                    field: 'goods',
                                    title: t('checkInOutManagement.goodsIdName'),
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'createTime',
                                    title: t('checkInOutManagement.createTime'),
                                    width: '8%',
                                    sorting: false,
                                    render: rowData => formatDate(rowData.createTime),
                                },
                                {
                                    field: 'reservedDays',
                                    title: t('reserve.reserveDate'),
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'lotNumber',
                                    title: t('checkInOutManagement.lotNum'),
                                    width: '8%',
                                    sorting: false,
                                    render: rowData => showLotNumer(rowData.lotNumber),
                                },
                                {
                                    field: 'totalOutStorage',
                                    title: t('reserve.number'),
                                    width: '8%',
                                    sorting: false,
                                },
                                {
                                    field: 'createBy',
                                    title: t('checkInOutManagement.requestor'),
                                    width: '10%',
                                    sorting: false,
                                    render: rowData => showStaff(rowData.createBy),

                                },
                                {
                                    field: 'refRFQNumber',
                                    title: t('checkInOutManagement.refBillNumber'),
                                    width: '10%',
                                    sorting: false,
                                },
                                {
                                    field: 'notes',
                                    title: t('checkOut.notes'),
                                    width: '10%',
                                    sorting: false,
                                },
                                // {
                                //     field: 'supplier.name',
                                //     title: t('checkInOutManagement.supplier'),
                                //     width: '10%',
                                //     sorting: false,
                                // },
                                // {
                                //     field: 'customer.name',
                                //     title: t('checkInOutManagement.customer'),
                                //     width: '10%',
                                //     sorting: false,
                                // }
                                ]}
                            icons={tableIcons}
                            data={checkInOutData}
                            style={{width:'100%'}} 
                            options={{      
                                actionsColumnIndex: -1,                                      
                                search: false,
                                // toolbar:false,
                                sorting: true,
                                paging:true,
                                pageSize:20,
                                exportButton:{ csv: true, pdf: false },
                                exportAllData:true,
                                exportFileName: type==='in'?'Check in Management':'Check out Management',
                                // tableLayout:"fixed",
                                headerStyle: {
                                    backgroundColor:"transparent",
                                    fontFamily:'IBMPlexSans-Bold',
                                    padding:'4px'
                                },
                                cellStyle:{
                                    maxWidth:'160px',
                                    padding:'4px',
                                    fontSize:'14px',
                                    whiteSpace:'normal',
                                    overflowWrap:'break-word'
                                    },
                                rowStyle: (rowData, index ) => ({
                                    backgroundColor:
                                    index % 2 === 0 ?theme.currentTheme.theme2backgrounds[1]:theme.currentTheme.theme2backgrounds[2]                                                ,
                                    }),
                            }}
                            editable={{
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        // handleRowDelete(oldData);
                                        if(type==="in"){
                                            inventoryService.deleteCheckIn(oldData.checkInId).then(result=>{
                                                if(result.status===200){
                                                    getList();
                                                }
                                            })
                                            resolve();
                                        }
                                        else{
                                            inventoryService.deleteCheckOut(oldData.checkOutId).then(result=>{
                                                if(result.status===200){
                                                    getList();
                                                }
                                            })
                                            resolve();
                                        }
                                        
                                    }),
                            }}
                            localization={{
                                header: {
                                    actions: t("common.actions")
                                },
                                body:{
                                    deleteTooltip:t("reserve.cancel")
                                },
                                toolbar:{
                                    exportTitle:t("common.export"),
                                    exportAriaLabel:t("common.export"),
                                    exportCSVName:t("common.exportAsCsv"),
                                },
                                emptyDataSourceMessage:t("common.noRecord"),
                            }}
                            actions={[
                                rowData => ({
                                    icon: ()=> <img src={ChukuIcon} width='20px'></img>,
                                    tooltip: t('reserve.checkOut'),
                                    onClick: (event, rowData) => {
                                        let settingURL = '/Inventory/CheckOutPage';
                                        if(type==="in"){
                                            settingURL = '/Inventory/CheckInPage';
                                        }
                                        let warehouseItem = {};
                                        warehouseItem.warehouseId = rowData.warehouseId;
                                        warehouseItem.orgId = rowData.orgId;
                                        warehouseItem.name = rowData.warehouse;
                                        console.log(warehouseItem);
                                        history.push({
                                            pathname: settingURL,
                                            state: { 
                                                "checkInId": rowData.checkInId,
                                                "checkOutId": rowData.checkOutId,
                                                "warehouseData": warehouseItem,
                                                "operationType": 'new',
                                                "type": 'checkout',
                                                "from": 'reserve'
                                            }
                                        });
                                    }
                                })
                            ]}
                        />
                    </Grid>
                </Grid> 
            </div>
            
        </div>
    )
}