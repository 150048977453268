import {axiosCore as axios} from './axiosHelper';

const getInvitationDetail = function(code) {
    return new Promise(function (resolve, reject) {
        axios({
            url: '/public/invitation?code=' + code,
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "accept": "*/*"
            }
        }).then(function (result) {
            resolve(result.data)
        }).catch(error => {
            console.warn('getInvitationDetail error: ', error)
            resolve(null)
        })
    })
}

const getInvitationList = function(){
    const url = `/auth/users/org/invitations`;
    return new Promise((resolve, reject)=>{
        axios.get(url).then(result=>{
            resolve(result.data);
        }).catch(e=>{
            reject(e);
        })
    })
}

const checkEmailExist = (email) => {
    const url = '/public/userinfo/' + email;
    return new Promise((resolve, reject) => {
        axios.get(url).then(result => {
            resolve(result.data);
        }).catch(e => {
            reject(e);
        })
    })
}

const finishInvitation = (code, invitee, orgId) => {
    const url = '/public/invitation/';
    return new Promise((resolve, reject) => {
        axios.patch(url, {code: code, invitee: invitee, orgId: orgId}).then(result => {
            resolve(result.data);
        }).catch(e => {
            reject(e);
        })
    })
}
export default {
    getInvitationDetail,
    getInvitationList,
    checkEmailExist,
    finishInvitation
}