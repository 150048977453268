import React ,{ useContext, useState, useEffect } from 'react';
import { useHistory,useParams } from "react-router-dom";
import { Button, Grid, Typography, Tooltip, TextField,TextareaAutosize, Select, MenuItem, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import CommonLayout from "../../components/CommonLayout";
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingButton from "../../components/LoadingButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import UndoIcon from '@material-ui/icons/Undo';
import accountingService from "../../services/accountingService";
import moment from 'moment';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme=> ({
    root: {
        borderBottom: "none ",
        fontSize:"medium",
        backgroundColor: theme.currentTheme.backgrounds[11],
    },
    thBorder: {
        borderBottom: "none ",
        fontSize: "medium",
    },
    tableCell:{
        whiteSpace:'nowrap',
        overflow:'hidden',
        textOverflow:'ellipsis',
    },
    poFormBox: {
        '@media (min-width:600px)':  {
            padding: "4vw",
        },
    },
    poFormTitle: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "28px 0 20px 23px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[2],
        //marginTop:"30px",
    },
    poFormContent: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "0 40px 0 40px",
        '@media (max-width:600px)':  {
            padding: "0 24px 0 24px",
        },
    },
    poFormContentItem: {
        padding: "20px 0 0 0",
    },
    poFormContentBottom: {
        marginBottom:"9px"
    },
    poFormContentItemBottom: {
        padding: "20px 0 30px 0",
    },
    maxField: {
        maxWidth: '360px',
    },
    selectField: {
        padding: "11px 14px",
    },
    selectTopItem: {
        marginBottom:"12px",
    },
    itemTitle: {
        overflow:"hidden",
        textOverflow:"ellipsis",
        whiteSpace:"nowrap",
        marginBottom:"10px"
    },
    itemContent: {
        overflow:"hidden",
        textOverflow:"ellipsis",
        whiteSpace:"nowrap",
    },
    required: {
        color: "#fd4747",
        display: "inline",
    },
    dashedLine: {
        margin:"23px 0 0 0",
        borderTop:"1px solid #dbdbdb",
        width:"100%",
    },
    largeInput: {
        height:"5.1876em",
    },
    buttonSpacing: {
        width: "150px",
        '@media (max-width:600px)':  {
            width: "120px",
        },
    },
    buttonBottom: {
        marginBottom: "20px",
    },
    itemPOFormContent: {
        marginTop: "6px",
    },
    itemPOFormItem: {
        margin: "0",
    },
    link: {
        cursor: "pointer"
    },
    undoIcon: {
        marginRight: "23px",
        color:"#3f51b5",
    },
}))

export default function PayMentAddPage(props){
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const history = useHistory();

    const [paymentActionData, setPaymentActionData] = useState([]);
    const [paymentAction, setPaymentAction] = useState("Deposit");
    const [paymentFormData, setPaymentFormData] = useState([]);
    const [paymentActionError, setPaymentActionError] = useState(false);
    const [invoiceSelectError, setInvoiceSelectError] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [selLinesError, setSelLinesError] = useState(false);
    const [invoiceData, setInvoiceActionData] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState('');
    const [poFormData, setPoFormData]= useState([]);
    const [selLine, setSelLine] = useState([]);
    const [closeBookConfirmWindow,setCloseBookConfirmWindow] = useState(false);
    const [invoiceSelected, setInvoiceSelected] = useState('');
    const [notes, setNotes] = useState("");
    const [invoiceTotal, setInvoiceTotal] = useState(0);
    const [invoiceTotalWarning, setInvoiceTotalWarning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=> {
        if (history.location.state && history.location.state.selectedLines) {
            setPoFormData(history.location.state.selectedLines);
            let data = ["Deposit", "Batch"];
            setPaymentActionData(data);
            if (history.location.state.type === 'batch') {
                setPoFormData(history.location.state.selectedLines.lines);
                setSelLine(history.location.state.selectedLines.lines)
                setPaymentAction('Batch');
                let arr = [];
                arr.push(history.location.state.selectedLines);
                setInvoiceActionData(arr);
                setInvoiceSelected(history.location.state.selectedLines);
            }
        }

        let sum = 0;
        poFormData.forEach(item => {
            sum += parseFloat(item.lineTotal);
        });
        if (sum !== 0) {
            setPaymentAmount(sum);
        }
    },[poFormData])


    const handlePaymentActionChange =(e)=> {
        setPaymentAction(e.target.value);
        setSelLinesError(false);
        setInvoiceTotal(0);
        setInvoiceTotalWarning(false);
        if(e.target.value===""){
            setPaymentActionError(true);
        }else{
            if(e.target.value==='Deposit'){
                setPoFormData([]);
                setInvoiceSelectError(false);
                accountingService.getPOLineByPOId(paymentFormData.poId).then(result=>{
                    if(result.status===200){
                        let tableData = [];
                        if(result.data.length>0){
                            result.data.map((item,index)=>{
                                if(item.status==='normal'){
                                    let itemData = item;
                                    itemData.tableData = { checked: true };
                                    tableData.push(itemData);
                                }
                            })
                        }
                        setPoFormData(tableData);
                        setSelLine(tableData);
                    }
                });
                setInvoiceSelected("");
            }
            else{
                setPoFormData([]);
                setInvoiceSelected("");
                setSelLine([]);
            }
            setPaymentActionError(false);
        }

    };

    const handleInvoiceChange =(e)=> {
        if(e.target.value&&e.target.value.invoiceId!==""){
            setInvoiceSelectError(false);
        }
        else{
            setInvoiceSelectError(true);
        }
        setInvoiceSelected(e.target.value);
        invoiceData.map((item,index)=>{
            if(item.invoiceId === e.target.value.invoiceId ){
                setPoFormData(item.lines);
                setSelLine(item.lines);
                let total = 0;
                item.lines.map((item)=>{
                    total = total + parseFloat(item.lineTotal);
                })
                setInvoiceTotal(total);
                console.log(total);
            }
        })
    };

    const handleSubmitPOForm =()=> {
        let validated = true;
        if(paymentAction===""){
            console.log("paymentAction error")
            setPaymentActionError(true);
            validated = false;
        }
        if(paymentAction!==""&&paymentAction!=="Deposit"&&invoiceSelected===""){
            console.log("invoiceSelected error")
            setInvoiceSelectError(true);
            validated = false;
        }
        if(paymentAmount===""||!checkNumber(paymentAmount)){
            console.log("paymentAmount error")
            setAmountError(true);
            validated = false;
        }
        if(paymentAction!==""&&invoiceSelected!==""&&selLine.length===0){
            console.log("selLine error")
            setSelLinesError(true);
            validated = false;
        }
        // if(paymentAction==="Deposit"&&selLine.length===0){
        //     // console.log(" Deposit selLine error")
        //     setSelLinesError(true);
        //     validated = false;
        // }
        if(!validated){
            return;
        }
        let paymentDatas = [];

        let values={};
        values.paymentAction=paymentAction==='Deposit'?'deposit':paymentAction;
        values.paymentDate=new Date();
        values.invoiceId=invoiceSelected?invoiceSelected.invoiceId:"";
        values.invoiceNumber=invoiceSelected?invoiceSelected.invoiceNumber:"";
        values.paymentAmount = paymentAmount;
        values.notes = notes;
        if(paymentAction==='Deposit'){
            poFormData.map(item => {
                let foundIndex = paymentDatas.findIndex(item1 => item1.poId===item.poId);
                if(foundIndex>=0){
                    paymentDatas[foundIndex].lineIds.push(item.lineId);
                }
                else{
                    let newPoRow ={};
                    newPoRow.poId = item.poId;
                    newPoRow.lineIds = [];
                    newPoRow.lineIds.push(item.lineId);
                    paymentDatas.push(newPoRow);
                }
            })
            values.paymentDatas = paymentDatas;
            console.log(values)

            accountingService.addBatchPayment(values).then(result=>{
                if(result.status===200){
                    history.go(-1);
                }
            })
        }
        else if(paymentAction==='Batch'){
            poFormData.map(item => {
                let foundIndex = paymentDatas.findIndex(item1 => item1.invoiceId===item.invoiceId);
                if(foundIndex>=0){
                    paymentDatas[foundIndex].lineIds.push(item.invoiceLineId);
                }
                else{
                    let newPoRow ={};
                    newPoRow.invoiceId = item.invoiceId;
                    newPoRow.lineIds = [];
                    newPoRow.lineIds.push(item.invoiceLineId);
                    paymentDatas.push(newPoRow);
                }
            })
            values.paymentDatas = paymentDatas;
            console.log(values);
            accountingService.batchPaymentByInvoice(values).then(result=>{
                if(result.status===200){
                    history.go(-1);
                }
            })
        }
    };

    const checkNumber = (values) =>{
        let reg =/^\d*(\.\d{1,4})?$/;
        if (values && !reg.test(values)) {
            return false;
        }
        return true;
    }

    const goBack = () => {
        history.go(-1);
    }

    const handleCloseBookWindowClose =()=>{
        setCloseBookConfirmWindow(false);
        history.push("/PO Transactions/detail/" + history.location.state.bookId);
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    return(
      <CommonLayout>
        <div className={classes.poFormBox}>

            <Grid container justifyContent="flex-start" alignItems="center" className={classes.poFormTitle}>
                <Typography variant="h6" gutterBottom >
                    {t('paymentPage.addPayment')}
                </Typography>
            </Grid>

            <Grid className={classes.poFormContent}>

                <Grid container spacing={2} alignItems="center" justifyContent="flex-start"  direction="row" className={classes.poFormContentItem}>
                    <Grid item xs={12} sm={4} className={classes.maxField}>
                        <div>
                            <Typography variant="body1"  className={classes.itemTitle}>
                                {t('paymentPage.paymentActions')} <span className={classes.required}>*</span>
                            </Typography>
                        </div>
                        <div>
                            <Select
                                fullWidth
                                required={true}
                                error={paymentActionError}
                                variant="outlined"
                                disabled
                                value={paymentAction}
                                onChange={handlePaymentActionChange}
                                classes={{ select: classes.selectField }}
                            >
                                {paymentActionData && paymentActionData.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item==='Deposit'?t('paymentPage.deposit'):t('paymentPage.batch')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Grid>
                    {paymentAction !== 'Deposit' && <Grid item xs={12} sm={4} className={classes.maxField}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                            {t('paymentPage.invoice')} <span className={classes.required}>*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                fullWidth
                                disabled
                                required={true}
                                error={invoiceSelectError}
                                variant="outlined"
                                value={invoiceSelected}
                                onChange={handleInvoiceChange}
                                classes={{ select: classes.selectField }}
                            >
                                {invoiceData && invoiceData.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item.invoiceNumber}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>}
                    <Grid item xs={12} sm={4} className={classes.maxField}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                            {t('paymentPage.paymentAmount')} <span className={classes.required}>*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="invoice_total_amount_text"
                                required
                                fullWidth
                                margin="none"
                                error={amountError}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={paymentAmount}
                                onChange={(event) => {
                                    setPaymentAmount(event.target.value);
                                    setAmountError(!checkNumber(event.target.value));
                                    setInvoiceTotalWarning(invoiceTotal > 0 && invoiceTotal !== parseFloat(event.target.value));
                                }}
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems="center" justifyContent="flex-start"  direction="row" className={classes.poFormContentItem}>
                    <Grid item container xs={12}>
                        { poFormData && <div style={{width:'100%', overflow: 'auto'}}>
                            <MaterialTable
                                title=""
                                icons={tableIcons}
                                columns={
                                    [
                                        { title: t('poFormPage.poNumber'), field: 'poNumber', },
                                        { title: t('poFormPage.line'), field: 'lineNumber', },
                                        { title: t('poFormPage.item'), field: 'itemNumber'},
                                        { title: t('poFormPage.product'), field: 'productName'},
                                        { title: t('poFormPage.QTY'), field: 'qty'},
                                        { title: t('poFormPage.price'), field: 'price',},
                                        { title: t('poFormPage.lineTotal'), field: 'lineTotal'},
                                        { title: t('poFormPage.currency'), field: 'currency'},
                                        { title: t('poFormPage.date'), field: 'lineDate', type: 'date'}
                                    ]
                                }
                                data={poFormData}
                                options={{
                                    actionsColumnIndex: -1,
                                    search: false,
                                }}
                                style={{width:'100%', minWidth: '1080px'}}
                                options={{
                                    toolbar:false,
                                    paging:false,
                                    headerStyle: {
                                        backgroundColor:"transparent"
                                    },
                                }}

                                onSelectionChange={(rows) => {
                                    setSelLine(rows);
                                    setSelLinesError(rows.length === 0);
                                }}
                            />
                            { selLinesError===true && <Alert severity="error" style={{marginTop:'6px'}}><Typography>{t('paymentPage.errorTips3')}</Typography></Alert>}
                            { invoiceTotalWarning===true &&<Alert severity="warning" style={{marginTop:'6px'}}><Typography>{t('paymentPage.errorTips1')}</Typography></Alert>}
                            </div>
                        }
                    </Grid>
                </Grid>

                <Grid container className={classes.dashedLine}>

                </Grid>

                <Grid container alignItems="center" justifyContent="flex-start"  direction="row" className={classes.poFormContentItem}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1"  className={classes.itemTitle}>
                                {t('paymentPage.notes')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                rows={4}
                                variant="outlined"
                                onChange = {(event)=>{setNotes(event.target.value)}}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={4} alignItems="center" justifyContent="center" direction="row" className={classes.poFormContentItemBottom}>
                    <Grid container xs={6} direction="row-reverse">
                        <LoadingButton isLoading={isLoading} className={classes.buttonSpacing} onClick={handleSubmitPOForm}>
                            <Typography variant="subtitle2">
                                {t('common.confirm')}
                            </Typography>
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" className={classes.buttonSpacing} onClick={goBack}>
                            <Typography variant="subtitle2">
                                {t('common.cancel')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Dialog open={closeBookConfirmWindow} onClose={handleCloseBookWindowClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {t('paymentPage.errorTips2')}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleCloseBookWindowClose}>
                        <Typography variant="body2">
                            {t('common.ok')}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            </Grid>
        </div>
    </CommonLayout>
    )
}
