import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { Button, Grid, Typography, TextField, Select, MenuItem, IconButton} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Alert from '@material-ui/lab/Alert';

import CommonLayout from "../../components/CommonLayout";
import LoadingButton from "../../components/LoadingButton";
import accountingService from "../../services/accountingService";
import { set } from 'date-fns/esm';
import { useTranslation } from 'react-i18next';
import Big from 'big.js'
import randomIcon from '../../assets/img/random.svg';

const useStyles = makeStyles(theme => ({
    root: {
        borderBottom: "none ",
        fontSize: "medium",
        backgroundColor: theme.currentTheme.backgrounds[11],
    },
    buttonCol: {
        color: theme.currentTheme.colors[0],
    },
    lineDateInput: {
        '& > *': {
            '& > *': {
                textAlign: "center"
            },
        },
    },
    poFormBox: {
        '@media (min-width:600px)':  {
            padding: "4vw",
        },
    },
    poFormTitle: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "28px 0 20px 23px",
        borderRadius: "8px 8px 0 0",
        borderBottom: theme.currentTheme.border[2],
    },
    poFormContent: {
        backgroundColor: theme.currentTheme.backgrounds[10],
        padding: "0 40px 0 40px",
        '@media (max-width:600px)':  {
            padding: "0 24px 0 24px",
        },
    },
    poFormContentItem: {
        padding: "20px 0 0 0",
    },
    poFormContentItemBottom: {
        padding: "20px 0 30px 0",
    },
    textField: {
        margin: "4px 0 0 0",
        width:'100%',
    },
    fieldOverflow: {
        '& > *': {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
    selectField: {
        padding: "11px 14px",
    },
    selectTopItem: {
        marginTop: "12px",
    },
    itemTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    itemContent: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginTop: "12px"
    },
    required: {
        color: "#fd4747",
        display: "inline",
    },
    dashedLine: {
        margin: "23px 0 0 0",
        borderTop: "1px solid #dbdbdb",
        width: "100%",
    },
    buttonSpacing: {
        width: "150px",
        marginLeft: "10px",
    }
}))

export default function InvoiceAddPage(props) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const tableIcons = {
        Add: forwardRef((props, ref) =><AddCircleIcon className={classes.buttonCol} {...props} ref={ref}></AddCircleIcon>),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const [delBtnDisabled, setDelBtnDisabled] = useState(false);

    const [invoiceFormData, setInvoiceFormData] = useState([]);
 
    const [purchaserName, setPurchaserName] = useState();

    const [supplierName, setSupplierName] = useState();

    const [foreverLineData, setForeverLineData] = useState([]);

    const [invoiceLineData, setInvoiceLineData] = useState([]);

    const [poFormData, setPoFormData] = React.useState([]);

    const [purchaserTin, setPurchaserTin] = useState("");

    const [purchaserTinDisabled, setPurchaserTinDisabled] = useState(false);

    const [supplierTin, setSupplierTin] = useState("");

    const [supplierTinDisabled, setSupplierTinDisabled] = useState(false);
    const [opertionType, setOpertionType] = useState('statement');
    const [statementNumber, setStatementNumber] = useState("");

    const [statementDate, setStatementDate] = useState(moment().format('YYYY/MM/DD'));
    const [statementNumberError, setStatementNumberError] = useState(false);
    const [invoiceIds, setInvoiceIds] = useState([]);
    const [lineData, setLineData] = React.useState([]);
    const [submitError, setSubmitError] = useState('');
    useEffect(() => {
        if (history.location.state && history.location.state.selectedLines){
            setDelBtnDisabled(true);
            setPurchaserName(history.location.state.selectedLines[0].customer);
            setSupplierName(history.location.state.selectedLines[0].supplierName);
            history.location.state.selectedLines.map(item=>{
                if(item.supplierTaxId){
                    setSupplierTin(item.supplierTaxId);
                    setSupplierTinDisabled(true);

                }
                if(item.purchaserTaxId){
                    setPurchaserTin(item.purchaserTaxId);
                    setPurchaserTinDisabled(true);
                }
                // item.lineOutstanding = item.lineTotal;
            })
            
            setPoFormData(history.location.state.selectedLines);
        }
        if(history.location.state && history.location.state.invoice){
            let invoice = history.location.state.invoice;
            setOpertionType('invoice');
            setStatementNumber(invoice.statementNumber);
            setStatementDate(invoice.statementDate);
            invoice.lines.map(item=>{
                item.lineOutstanding = item.lineTotal;
            })
            setPoFormData(invoice.lines);
            // setInvoiceId(invoice.invoiceId);
            setNotes(invoice.notes);
            let item;
            for (item in invoice.lines){
                accountingService.getInvoiceByPOId(invoice.lines[item].poId).then(result => {
                    if(result.data[0]&&result.data[0].purchaserTaxId){
                        if(result.data[0].purchaserTaxId &&result.data[0].purchaserTaxId.length>0) {
                            setPurchaserTin(result.data[0].purchaserTaxId);
                            setPurchaserTinDisabled(true);
                        }
                        if(result.data[0].supplierTaxId &&result.data[0].supplierTaxId.length>0) {
                            setSupplierTin(result.data[0].supplierTaxId);
                            setSupplierTinDisabled(true);
                        }
                        setPurchaserName(result.data[0].purchaserName);
                        setSupplierName(result.data[0].supplierName);
                        return;
                    }
                })
            }
        }
    }, []);

    const [selLine, setSelLine] = React.useState("");

    const [lineSelectStatus, setLineSelectStatus] = React.useState(false);
    useEffect(() => {
        if (lineData && lineData.length > 0) {
            setLineSelectStatus(false);
        } else {
            setLineSelectStatus(true);
        }
    }, [lineData]);

    const [invoiceNumber, setInvoiceNumber] = useState("");

    const [invoiceDate, setInvoiceDate] = useState("");
    useEffect(() => {
        setInvoiceDate(moment().format("YYYY/MM/DD"));
    }, []);

    const [invoiceTotalAmount, setInvoiceTotalAmount] = useState("");
    useEffect(() => {
        if (poFormData) {
            let data = 0;
            poFormData.map(item => {
                if(item.rowStatus){
                    data += Math.round((item.lineTotal)*10000)/10000;
                }else{
                    data += Math.round((item.lineOutstanding)*10000)/10000;
                }
                
            })
            setInvoiceTotalAmount(data);
        }
    }, [poFormData]);

    const [customerId, setCustomerId] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [notes, setNotes] = useState("");

    const [invoiceNumberError, setInvoiceNumberError] = useState(false);
    const [invoiceDateError, setInvoiceDateError] = useState(false);
    const [invoiceTotalAmountError, setInvoiceTotalAmountError] = useState(false);
    const [selectError, setSelectedError] = useState(false);
    const [purchaserTinWarning, setPurchaserTinWarning] = useState(false);
    const [invoiceHelp, setInvoiceHelp] = useState('');

    const [lineTotalBalOpen, setLineTotalBalOpen] = useState(false);

    const handlerlineTotalBalClose = (e) => {
        setLineTotalBalOpen(false);
    };

    const handleStatementNumberChange = (e) => {
        setStatementNumber(e.target.value);
        if (e.target.value == "") {
            setStatementNumberError(true);
        }
        else{
            setStatementNumberError(false);
        }
        
    };

    const handleStatementDateChange = (date) => {
        const momentDate = moment(date).format("YYYY/MM/DD");
        setStatementDate(momentDate);
    };

    const handleInvoiceNumberChange = (e) => {
        setInvoiceNumber(e.target.value);

        console.log("Invoice Line", invoiceLineData);
        console.log("Is invoice number duplicated", checkDuplication(e.target.value));

        if (e.target.value == "") {
            setInvoiceNumberError(true);
            setInvoiceHelp(false);
        } else if (checkDuplication(e.target.value)) {
            setInvoiceNumberError(true);
            setInvoiceHelp(true);
        } else {
            setInvoiceNumberError(false);
            setInvoiceHelp(false);
        }
    };

    const checkDuplication = (value) => {
        for (var i = 0; i < invoiceLineData.length; i++) {
            if (invoiceLineData[i]["invoiceNumber"] === value) {
                return true;
            }
        }
        return false;
    };

    const handleInvoiceDateChange = (date) => {
        const momentDate = moment(date).format("YYYY/MM/DD");
        setInvoiceDate(momentDate);
        if (date === "") {
            setInvoiceDateError(true);
        } else {
            setInvoiceDateError(false);
        }
    };

    const purchaserTinChangeHandler = (e) => {
        if(e.target.value === ""){
            setPurchaserTinWarning(false);
        }else {
            setPurchaserTinWarning(true);
        }
        setPurchaserTin(e.target.value);
    }

    const purchaserIdChangeHandler = (e) => {
        setCustomerId(e.target.value);
    };
    const supplierTinChangeHandler = (e) => {
        if(e.target.value === ""){
            setPurchaserTinWarning(false);
        }else {
            setPurchaserTinWarning(true);
        }
        setSupplierTin(e.target.value);
    }
    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    };
    const handleLineChange = (e) => {
        setSelLine(e.target.value);
    };

    const handleAddInvoiceFormData = () => {
        if (selLine) {
            setSelectedError(false);
        } else {
            setSelectedError(true);
        }
        let copy_poFormData = [...poFormData];
        let copy_lineData = [...lineData];

        copy_lineData.map((item, index) => {
            if (item.lineNumber === selLine) {
                copy_poFormData.push(item);
                setPoFormData(copy_poFormData);

                copy_lineData.splice(index, 1);
                setLineData(copy_lineData);
            }
        })

        setSelLine("");
    };

    const handleRowDelete = (oldData) => {
        let copy_poFormData = [...poFormData];
        let copy_lineData = [...lineData];
        let oldDataIndex = oldData.tableData.id;

        foreverLineData.map(item => {
            if (item.lineId === oldData.lineId) {
                copy_lineData.push(item);
                setLineData(copy_lineData);

                copy_poFormData.splice(oldDataIndex, 1);
                setPoFormData(copy_poFormData);
            }
        })
    };

    const handleRowUpdate = (newData, oldData) => {
        const dataUpdate = [...poFormData];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setPoFormData([...dataUpdate]);
        if(newData.rowStatus && newData.lineTotal !== oldData.lineOutstanding ){
            setLineTotalBalOpen(true);
        }
        
    };

    const validate = () => {
        let temp = false;
        console.log(invoiceTotalAmount);
        if(opertionType==='invoice'){
            if (invoiceNumber === "" || invoiceNumber === undefined) {
                setInvoiceNumberError(true);
                temp = false;
            }
            if (invoiceDate === "" || invoiceDate === undefined) {
                setInvoiceDateError(true)
                temp = false;
            }
            if (invoiceTotalAmount === "" || invoiceTotalAmount === undefined) {
                setInvoiceTotalAmountError(true)
                temp = false;
            }
            if (invoiceNumber && invoiceDate && invoiceTotalAmount) {
                temp = true;
            }
        }
        else{
            if (statementNumber === "" || statementNumber === undefined) {
                setStatementNumberError(true);
                temp = false;
            }
            if (statementDate === "" || statementDate === undefined) {
                temp = false;
            }
            if (statementNumber && statementDate && invoiceTotalAmount) {
                temp = true;
            }
        }
        if (poFormData && poFormData.length > 0) {
            setSelectedError(false);
        } else {
            setSelectedError(true);
        }
        return temp;
    };

    const handleSubmitInvoiceForm = () => {
        let invoices = [];
        if(invoiceIds.length>0){
            invoices = invoiceIds;
        }
        else{
            poFormData.map(item=>{
                if(!invoices.includes(item.invoiceId)){
                    invoices.push(item.invoiceId);
                }
            })
        }
        let data = {
            invoiceNumber: invoiceNumber,
            invoiceDate: invoiceDate,
            invoiceTotalAmount: invoiceTotalAmount,
            purchaserTaxId: purchaserTin && purchaserTin !== undefined ? purchaserTin : "",
            customerId: customerId && customerId !== undefined ? customerId : "",
            supplierTaxId: supplierTin && supplierTin !== undefined ? supplierTin : "",
            invoiceIds:invoices,
        }
        if (validate()) {
            console.log("confirm", data);
            setIsLoading(true);
            accountingService.batchUpdateInvoice(data).then(
                result => {
                    if (result.data) {
                        console.log("result confirm", result.data);
                        history.go(-1);
                    }
                    setIsLoading(false);
                }
            ).catch((e) => {
                setIsLoading(false);
                if(e.response.data.message.indexOf("already exists")>-1){
                    setSubmitError(t('invoicePage.invoiceNumberExistTips'));
                }
                else{
                    setSubmitError(e.response.data.message);
                }
            })
        }
    };

    const handleSubmitStatementForm = ()=>{
        let newPoLines = [];
        let temp = {}
        let invoiceDatas = [];

        poFormData.map(item => {
            let foundIndex = invoiceDatas.findIndex(item1 => item1.poId===item.poId);
            temp = {
                lineId: item.lineId,
                lineNumber: item.lineNumber,
                itemNumber: item.itemNumber,
                itemRev: item.itemRev && item.itemRev !== undefined ? item.itemRev : "",
                productName: item.productName,
                qty: item.qty,
                unit: item.unit && item.unit !== undefined ? item.unit : "",
                price: item.price,
                tax: item.tax && item.tax !== undefined ? item.tax : "",
                discount: item.discount && item.discount !== undefined ? item.discount : "",
                lineTotal: item.rowStatus ? item.lineTotal: item.lineOutstanding,
                lineDate: item.lineDate && item.lineDate !== undefined ? item.lineDate : moment().format("YYYY/MM/DD"),
                notes: notes,
            }
            if(foundIndex>=0){
                invoiceDatas[foundIndex].lines.push(temp);
            }
            else{
                let newPoRow ={};
                newPoRow.poId = item.poId;
                newPoRow.lines = [];
                newPoRow.lines.push(temp);
                invoiceDatas.push(newPoRow);
            }
        })
        let data = {
            // invoiceNumber: invoiceNumber,
            // invoiceDate: invoiceDate,
            invoiceTotalAmount: invoiceTotalAmount,
            statementNumber: statementNumber,
            statementDate: statementDate,
            // purchaserTaxId: purchaserTin && purchaserTin !== undefined ? purchaserTin : "",
            // customerId: customerId && customerId !== undefined ? customerId : "",
            // supplierTaxId: supplierTin && supplierTin !== undefined ? supplierTin : "",
            notes: notes,
            invoiceDatas: invoiceDatas,
        }
        if (validate()) {
            console.log("confirm", data);
            setIsLoading(true);
            accountingService.addBatchPInvoice(data).then(
                result => {
                    if (result.data) {
                        console.log("result confirm", result.data);
                        // history.go(-1);
                        let ids = [];
                        result.data.map(item=>{
                            if(!ids.includes(item.invoiceId)){
                                ids.push(item.invoiceId);
                            }
                        })
                        console.log(ids);
                        setInvoiceIds(ids);
                        setOpertionType('invoice');
                    }
                    setIsLoading(false);
                }
            )
        }
    }

    const goBack = () => {
        history.go(-1);
    };

    const formatData = (value) => {
        let data = parseFloat(value).toFixed(4).replace(/d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
        return data;
    };

    const checkNumber = (values) => {
        let reg = /^\d*(\.\d{1,4})?$/;
        if (values && !reg.test(values)) {
            return false;
        }
        return true;
    };

    const genrateStatementNumber = ()=>{
        accountingService.getStatementNumberByOrgId(window.sessionStorage.getItem("currentOrg")).then(result=>{
            if(result.status===200){
                setStatementNumber(result.data.statementNumber);
            }
        })
    }

    return (
        <CommonLayout>
            { (history.location.state && history.location.state.selectedLines) &&
                <div className={classes.poFormBox}>

                    <Grid container justifyContent="flex-start" alignItems="center" className={classes.poFormTitle}>
                        <Typography variant="h6" gutterBottom >
                            {t('invoicePage.addInvoice')}
                        </Typography>
                    </Grid>

                    <Grid container className={classes.poFormContent}>
                        <Grid container spacing={2} justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" className={classes.itemTitle}>
                                            {t('invoicePage.statementNumber')} <span className={classes.required}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10} md={11}>
                                        <TextField
                                            id="invoice_number_text"
                                            value={statementNumber}
                                            error={statementNumberError}
                                            required={true}
                                            fullWidth
                                            disabled
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            className={classes.textField}
                                            onChange={handleStatementNumberChange}
                                            helperText={invoiceHelp ? t('invoicePage.existedInvoiceNumber') : ""}
                                        />
                                        <span
                                            style={{ color: '#ff4d4f' }}
                                        >
                                            {invoiceHelp}
                                        </span>
                                    </Grid>
                                    <Grid md={1} xs={2}>
                                        <Tooltip title={t('invoicePage.generateNumber')}>
                                            <IconButton onClick={genrateStatementNumber} disabled = {opertionType==='invoice'}>
                                                <img src={randomIcon} className={classes.randomImage}></img>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('invoicePage.statementDate')} <span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            disabled = {opertionType==='invoice'}
                                            id="date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="YYYY/MM/DD"
                                            value={statementDate}
                                            size="small"
                                            style={{ marginTop: "4px" }}
                                            onChange={date => handleStatementDateChange(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('invoicePage.statementAmount')} <span className={classes.required}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "18px" }}>
                                    {invoiceTotalAmount ? formatData(invoiceTotalAmount) : ""}
                                </Grid>
                            </Grid>
                        </Grid>

                        {opertionType==='invoice'&&<Grid container spacing={2} justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t('invoicePage.invoiceNumber')} <span className={classes.required}>*</span>
                                    </Typography>
                                </div>
                                <div>
                                    <TextField
                                        id="invoice_number_text"
                                        value={invoiceNumber}
                                        error={invoiceNumberError}
                                        required={true}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={[classes.textField, classes.fieldOverflow].join(' ')}
                                        onChange={handleInvoiceNumberChange}
                                        helperText={invoiceHelp ? t('invoicePage.existedInvoiceNumber') : ""}
                                    />
                                    <span
                                        style={{ color: '#ff4d4f' }}
                                    >
                                            {invoiceHelp}
                                        </span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t('invoicePage.invoiceDate')} <span className={classes.required}>*</span>
                                    </Typography>
                                </div>
                                <div>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            id="date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="YYYY/MM/DD"
                                            value={invoiceDate}
                                            size="small"
                                            className={classes.textField}
                                            onChange={date => handleInvoiceDateChange(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('invoicePage.invoiceTotalAmount')} <span className={classes.required}>*</span>
                                    </Typography>
                                </div>
                                <div className={classes.itemContent}>
                                    {invoiceTotalAmount ? formatData(invoiceTotalAmount) : ""}
                                </div>
                            </Grid>
                        </Grid>}

                        {opertionType==='invoice'&&<Grid container spacing={2} alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                            <Grid item xs={12} sm={6} md={4} className={classes.itemSpacing}>
                                <div>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('invoicePage.purchaserName')}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="body1" className={classes.itemContent}>
                                        {purchaserName}
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('invoicePage.purchaserTINEINVAT')}
                                    </Typography>
                                </div>
                                <div>
                                    <TextField
                                        id="purchaser_tin_text"
                                        value={purchaserTin}
                                        disabled={purchaserTinDisabled}
                                        required={false}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={[classes.textField, classes.fieldOverflow].join(' ')}
                                        onChange={purchaserTinChangeHandler}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('invoicePage.purchaserID')}
                                    </Typography>
                                </div>
                                <div>
                                    <TextField
                                        id="customer_id_text"
                                        value={customerId}
                                        required={true}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={[classes.textField, classes.fieldOverflow].join(' ')}
                                        onChange={purchaserIdChangeHandler}
                                    />
                                </div>
                            </Grid>

                        </Grid>}

                        {opertionType==='invoice'&&<Grid container spacing={2} alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('invoicePage.supplierName')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.itemContent}>
                                        {supplierName}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                    {t('invoicePage.supplierTINEINVAT')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="supplier_tin_text"
                                        value={supplierTin}
                                        disabled={supplierTinDisabled}
                                        required={true}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={classes.textField}
                                        onChange={supplierTinChangeHandler}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>}

                        { purchaserTinWarning && 
                            <Alert severity="info" style={{marginTop:'13px'}}>
                                <Typography>{t('invoicePage.TINTips')}</Typography>
                            </Alert>
                        }

                        <Grid container className={classes.dashedLine}>

                        </Grid>

                        <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem} style={{ overflowX: "auto", width: "100%" }}>
                            <MaterialTable
                                icons={tableIcons}
                                style={{ minWidth: "1080px" }}
                                columns={
                                    [
                                        {
                                            title: t('poFormPage.poNumber')+'*', field: 'poNumber', editable: 'onAdd',
                                            validate: rowData => rowData.lineNumber !== '' && rowData.lineNumber !== undefined,
                                            cellStyle: {
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                        },
                                        {
                                            title: t('poFormPage.line')+'*', field: 'lineNumber', editable: 'onAdd',
                                            validate: rowData => rowData.lineNumber !== '' && rowData.lineNumber !== undefined,
                                            cellStyle: {
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                        },
                                        {
                                            title: t('poFormPage.item')+'*', field: 'itemNumber', editable: 'onAdd',
                                            validate: rowData => rowData.itemNumber !== '' && rowData.itemNumber !== undefined,
                                            cellStyle: {
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                        },
                                        {
                                            title: t('poFormPage.rev'), field: 'itemRev', editable: 'onAdd',
                                            cellStyle: {
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                        },
                                        {
                                            title: t('poFormPage.product')+'*', field: 'productName', editable: 'onAdd',
                                            validate: rowData => rowData.productName !== '' && rowData.productName !== undefined,
                                            cellStyle: {
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                        },
                                        {
                                            title: t('poFormPage.QTY')+'*', field: 'qty',
                                            validate: rowData => rowData.qty !== '' && rowData.qty !== undefined && checkNumber(rowData.qty),
                                            render: rowData => formatData(rowData.qty),
                                            cellStyle: {
                                                width: '10%',
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                            editComponent: props => (
                                                <TextField
                                                    id="qty_text"
                                                    value={props.rowData.qty}
                                                    error={!props.rowData.qty || props.rowData.qty === undefined || props.rowData.qty === ""||!checkNumber(props.rowData.qty)||!checkNumber(props.rowData.qty)}
                                                    required={true}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    size="small"
                                                    onChange={e => {
                                                        props.onRowDataChange({
                                                            ...props.rowData,
                                                            lineTotal: new Big(e.target.value).times(props.rowData.price).toFixed(4),
                                                            qty: e.target.value,
                                                            rowStatus: true,
                                                        })
                                                    }}
                                                >
                                                </TextField>
                                            ),
                                        },
                                        {
                                            title: t('poFormPage.unit'), field: 'unit', editable: 'onAdd',
                                            cellStyle: {
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                        },
                                        {
                                            title: t('poFormPage.price')+'*', field: 'price', editable: 'onAdd',
                                            validate: rowData => rowData.price !== '' && rowData.price !== undefined && checkNumber(rowData.price),
                                            render: rowData => formatData(rowData.price),
                                            cellStyle: {
                                                width: '10%',
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                            editComponent: props => (
                                                <TextField
                                                    id="price_text"
                                                    value={props.rowData.price}
                                                    error={!props.rowData.price || props.rowData.price === undefined || props.rowData.price === ""||!checkNumber(props.rowData.price)}
                                                    required={true}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    size="small"
                                                    onChange={e => {
                                                        props.onRowDataChange({
                                                            ...props.rowData,
                                                            lineTotal: new Big(e.target.value).times(props.rowData.qty).toFixed(4),
                                                            price: e.target.value,
                                                        })
                                                    }}
                                                >
                                                </TextField>
                                            ),
                                        },
                                        // {
                                        //     title: t('poFormPage.tax'), field: 'tax', editable: 'onAdd',
                                        //     cellStyle: {
                                        //         textAlign: "center",
                                        //         padding: "4px"
                                        //     },
                                        // },
                                        // {
                                        //     title: t('poFormPage.discount'), field: 'discount', editable: 'onAdd',
                                        //     cellStyle: {
                                        //         textAlign: "center",
                                        //         padding: "4px"
                                        //     },
                                        // },
                                        {
                                            title: t('poFormPage.lineTotal')+'*', field: 'lineTotal',
                                            validate: rowData => rowData.lineTotal !== '' && rowData.lineTotal !== undefined && checkNumber(rowData.lineTotal),
                                            render: rowData => {
                                                if(rowData.rowStatus===true){
                                                    console.log('xxxxx');
                                                    return formatData(rowData.lineTotal);
                                                }
                                                console.log(rowData.lineOutstanding)
                                                return formatData(rowData.lineOutstanding);
                                            },
                                            cellStyle: {
                                                width: '10%',
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                            editComponent: (props) => {
                                                return (
                                                    <TextField
                                                        id="linetotal_text"
                                                        value={
                                                            props.rowData.rowStatus ? ( props.rowData.lineTotal ? props.rowData.lineTotal : "0") : props.rowData.lineOutstanding
                                                        }
                                                        error={!props.rowData.lineTotal || props.rowData.lineTotal === undefined || props.rowData.lineTotal === ""||!checkNumber(props.rowData.lineTotal)}
                                                        required={true}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        size="small"
                                                        onChange={e => {
                                                            props.onRowDataChange({
                                                                ...props.rowData,
                                                                lineTotal: e.target.value,
                                                                rowStatus: true,
                                                            })
                                                        }}
                                                    >
                                                    </TextField>
                                                )
                                            }
                                        },
                                        {
                                            title: t('poFormPage.currency'), field: 'currency', editable: 'onAdd',
                                            cellStyle: {
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                        },
                                        {
                                            title: t('poFormPage.date')+'*', field: 'lineDate', type: 'date', emptyValue: moment().format("YYYY/MM/DD"),
                                            editComponent: props => (
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        disableToolbar
                                                        id="date"
                                                        variant="inline"
                                                        size="small"
                                                        format="YYYY/MM/DD"
                                                        value={props.value}
                                                        className={classes.lineDateInput}
                                                        onChange={date => props.onChange(moment(date).format("YYYY/MM/DD"))}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            ),
                                            render: rowData => moment(rowData.lineDate).format("YYYY/MM/DD"),
                                            cellStyle: {
                                                width: '15%',
                                                textAlign: "center",
                                                padding: "4px"
                                            },
                                        }
                                    ]
                                }
                                data={poFormData}
                                localization={{
                                    header:{
                                        actions: t('poFormPage.actions')
                                    },
                                    body: {
                                        deleteTooltip: t("common.delete"),
                                        editTooltip: t("common.edit"),
                                        emptyDataSourceMessage:t("common.noRecord"),
                                    },
                                }}
                                options={{
                                    actionsColumnIndex: -1,
                                    search: false,
                                    toolbar: false,
                                    paging: false,
                                    sorting: false,
                                    cellStyle: {
                                        padding: "4px",
                                        textAlign: "center",
                                    },
                                    headerStyle: {
                                        padding: "4px",
                                        textAlign: "center",
                                        backgroundColor: "transparent"
                                    },
                                }}
                                editable={{
                                    isEditable: rowData => opertionType==='statement',
                                    isDeletable: rowData => !history.location.state.poLine && opertionType==='statement',
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowUpdate(newData, oldData);
                                            resolve();
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            handleRowDelete(oldData);
                                            resolve();
                                        }),
                                    
                                }}
                            />
                        </Grid>

                        <Grid container className={classes.dashedLine}>

                        </Grid>

                        <Grid container alignItems="center" justifyContent="flex-start" direction="row" className={classes.poFormContentItem}>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.itemTitle}>
                                        {t('invoicePage.notes')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        value={notes}
                                        multiline
                                        fullWidth
                                        disabled = {opertionType==='invoice'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleNotesChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            { submitError!='' && <Alert severity="error" style={{marginTop:'6px'}}><Typography>{submitError}</Typography></Alert>}
                        </Grid>
                        <Grid container alignItems="center" justifyContent="center" direction="row" className={classes.poFormContentItemBottom}>
                            {opertionType==='invoice'&&<Grid>
                                <LoadingButton isLoading={isLoading} color="primary" className={classes.buttonSpacing} classes={{ button: classes.buttonSpacing }} onClick={handleSubmitInvoiceForm}>
                                    <Typography variant="subtitle2">
                                        {t('invoicePage.invoiceButton')}
                                    </Typography>
                                </LoadingButton>
                            </Grid>}
                            {opertionType==='statement'&&<Grid>
                                <LoadingButton isLoading={isLoading} color="primary" className={classes.buttonSpacing} classes={{ button: classes.buttonSpacing }} onClick={handleSubmitStatementForm}>
                                    <Typography variant="subtitle2">
                                    {t('invoicePage.statementButton')}
                                    </Typography>
                                </LoadingButton>
                            </Grid>}
                            <Grid>
                                <Button variant="contained" className={classes.buttonSpacing} onClick={goBack}>
                                    <Typography variant="subtitle2">
                                    {t('common.cancel')}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>

                        <Dialog open={lineTotalBalOpen} onClose={handlerlineTotalBalClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">
                                <Typography variant="body1">
                                    {t('invoicePage.errorTips1')}
                                </Typography>
                            </DialogTitle>
                            <DialogActions>
                                <Button variant="contained" color="primary" onClick={handlerlineTotalBalClose}>
                                    <Typography variant="body2">
                                        {t('common.ok')}
                                    </Typography>
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </div>
            }
        </CommonLayout>
    )
}
