import CommonLayout from "../../components/CommonLayout";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import React, { useState } from 'react';
import {
  FormControl,
  Box,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import useToken from '../../hooks/useToken';
import LoadingButton from "../../components/LoadingButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import newBookService from '../../services/accountingService';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from "react-router-dom";
import organizationService from "../../services/organizationService";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "1200px",
    backgroundColor: theme.currentTheme.backgrounds[7],
    border: theme.currentTheme.border[2],
    borderRadius: '8px'
  },
  box: {
    padding: '30px 30px 10px 30px',
  },
  box1: {
    padding: '10px 30px 30px 30px',
  },
  box2: {
    width: "90%",
    marginLeft: "1%",
    marginTop: "2%",
    display: "inline-flex",
    flexFlow: "column",
  },
  text: {
    color: theme.currentTheme.colors[0],
  },
  text2: {
  },
  width1: {
    width: '60%',
  },
  buttonDiv: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '40px',
  },
  field_name_red: {
    color: "#C00000",
  },
  input: {
    height: "32px !important",
    fontFamily: "Lato",
    fontSize: "15px",
    marginTop: "1%",
  },
  box_left: {
    width: "30%",
    marginTop: "2%",
    marginLeft: "0%",
    display: "inline-flex",
    flexFlow: "column",
  },
  box_right: {
    width: "30%",
    marginTop: "2%",
    marginLeft: "22%",
    display: "inline-flex",
    flexFlow: "column"
  },
  box_add: {
    marginTop: "20px",
    display: "inline-flex",
    marginBottom: "20px",
  },
  buttonProgress: {
    color: '#0e78f9',
    position: 'absolute',
    top: '50%',
    left: '0',
    marginTop: -12,
    marginLeft: 5,
  },
  errorMsg: {
    color: '#ff4d4f',
    marginTop: '20px',
  },
  msg: {
    color: '#0e78f9',
    marginTop: '20px',
  },
  disable_box: {
    display: "none",
    marginLeft: "1%",
    marginTop: "2%",
  },
  select: {
    height: "32px !important",
    width: "500px !important",
    fontFamily: "Lato",
    fontSize: "15px",
    marginTop: "1%",
  },
  inputErrMsg: {
    color: '#ff4d4f'
  },
  hrColor: {
    border: theme.currentTheme.border[2],
  },
  section1: {
    margin: theme.spacing(0, 1),
  },
  section2: {
    margin: theme.spacing(1),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  errorShow: {
    display: '',
    color: 'red',
  },
  errorNotShow: {
    display: 'none'
  },
  buttonSpacing: {
    width: "200px",
    marginLeft: "10px",
  },
  buttonSpacing1: {
    marginLeft: "10px",
  },
}));

export default function NewBook(props) {


  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const purchaserNameDefaultHolder = t('newBookPage.purchaserTips');
  const purchaserMemberDefaultHolder = t('newBookPage.searchTips');
  const purchaserTitleDefaultHolder = t('newBookPage.titleTips');
  const supplierTitleDefaultHolder = t('newBookPage.titleTips');
  const supplierNameDefaultHolder = t('newBookPage.supplierTips');
  const { token, setToken } = useToken();
  const [newbookConstants, setNewBookConstants] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [friends, setFriends] = useState([]);
  const [friendsValue, setFriendsValue] = useState([]);
  const filter = createFilterOptions();
  const [purMemberData, setPurMemberData] = useState([]);
  const [supMemberData, setSupMemberData] = useState([]);
  const [otherPurMemberError, setOtherPurMemberError] = useState([]);
  const [otherSupMemberError, setOtherSupMemberError] = useState([]);
  const [otherPurMemberError1, setOtherPurMemberError1] = useState([]);
  const [otherSupMemberError1, setOtherSupMemberError1] = useState([]);
  const [emailSelected, setEmailSelected] = useState([])

  //判断error
  const [purMembersTitle, setPurMembersTitle] = useState([false]);
  const [purMembersName, setPurMembersName] = useState([false]);
  const [supMembersTitle, setSupMembersTitle] = useState([false]);
  const [supMembersName, setSupMembersName] = useState([false]);
  const [purMembersTitle1, setPurMembersTitle1] = useState([false]);
  const [purMembersName1, setPurMembersName1] = useState([false]);
  const [supMembersTitle1, setSupMembersTitle1] = useState([false]);
  const [supMembersName1, setSupMembersName1] = useState([false]);

  const history = useHistory();

  const [removePermisson, setRemovePermisson] = useState(false);
  const [assignPermisson, setAssignPermisson] = useState(false);

  const [purMemberData1, setPurMemberData1] = useState([]);
  const [supMemberData1, setSupMemberData1] = useState([]);
  const [duplicateError, setDuplicateError] = useState("");
  const issuePOformPage = '/PO Transactions/PoForm/issue';

  const [addName, setAddName] = useState([]);

  useEffect(()=>{
    let temp={
      title: [
          "",
          t('newBookPage.sales'),
          t('newBookPage.accounting'),
          t('newBookPage.buyer'),
          t('newBookPage.warehouse'),
      ],
    }
    setNewBookConstants(temp);
  },[])

  React.useEffect(() => {
    console.log("--------------------------------------")
    if (history.location.state && history.location.state.bookId) {

      console.log(purMemberData);

      newBookService.editBookByBookId(history.location.state.bookId).then(result => {

        if (result.data.permissions.includes("assign_book_members")) {
          setAssignPermisson(true);
        };
        if (result.data.permissions.includes("remove_book_members")) {
          setRemovePermisson(true);
        }

        if (result.data && result.data.bookId) {

          const aaa = JSON.parse(JSON.stringify(result.data.purchaserMembers).replace(/memberName/g, 'name').replace(/memberTitle/g, 'title'));
          if (aaa) {
            console.log(aaa);
            setPurMemberData(aaa);
            for (let i in aaa) {
              emailSelected.push(aaa[i].name)
            }
          }
          else {
            setPurMemberData([{ name: "", title: "" }]);
          }

          const bbb = JSON.parse(JSON.stringify(result.data.supplierMembers).replace(/memberName/g, 'name').replace(/memberTitle/g, 'title'));
          if (bbb) {
            setSupMemberData(bbb);
            for (let i in bbb) {
              emailSelected.push(bbb[i].name)
            }
          }
          else {
            setSupMemberData([{ name: "", title: "" }]);
          }

          setNewSupName(result.data.supplierName)
          setNewPurName(result.data.purchaserName)

        }
      })

    }
    else {
      let copy_purMemberData = [];
      let copy_supMemberData = [];
      setRemovePermisson(true);
      setAssignPermisson(true);
      if (copy_purMemberData && copy_purMemberData.length === 0) {
        setPurMemberData([{ name: "", title: "" }]);
      }
      else {
        setPurMemberData(copy_purMemberData);
      }
      if (copy_supMemberData && copy_supMemberData.length === 0) {
        setSupMemberData([{ name: "", title: "" }]);
      }
      else {
        setSupMemberData(copy_supMemberData);
      }
    }

    newBookService.addName(history.location.state.orgId).then((result) => {
      const addName1 = []
      result.data.map((item) => {

        addName1.push(
          item.counterpartyName
        );
      })
      setAddName(addName1)
      console.log("add+" + addName)
    })

    organizationService.getUsersByOrgId(history.location.state.orgId).then((result) => {
      const options = [];
      const optionsValue = [];


      if (result.data && result.data.users) {
        const users = result.data.users;
        for (let i = 0; i < users.length; i++) {
          const item = users[i];

          optionsValue.push(item.email);
          if (item.email !== token.username) {
            options.push({ label: item.username + " (" + item.email + ")", value: item.email });

          } else {
            options.push({ label: "you (" + item.email + ")", value: item.email });
          }
        }
      }
      setFriends(options);

      setFriendsValue(optionsValue);
      console.log("xuanze" + optionsValue);
    })

  }, [newbookConstants])

  const checkedPurMember = (value) => {
    for (let i = 0; i < purMemberData.length; i++) {
      if (purMemberData[i]["name"] === value) {
        return false;
      }
    }
    return true;
  };
  const checkedSupMember = (value) => {
    for (var i = 0; i < supMemberData.length; i++) {
      if (supMemberData[i]["name"] === value) {
        return false;
      }
    }
    return true;
  };
  const checkedPurMember1 = (value) => {
    for (var i = 0; i < purMemberData1.length; i++) {
      if (purMemberData1[i]["name"] === value) {
        return false;
      }
    }
    return true;
  };
  const checkedSupMember1 = (value) => {
    for (var i = 0; i < supMemberData1.length; i++) {
      if (supMemberData1[i]["name"] === value) {
        return false;
      }
    }
    return true;
  };


  const addPurMemberDataHandler = (value) => {
    const copy_purMemberData = JSON.parse(JSON.stringify(purMemberData));
    copy_purMemberData.push({ name: "", title: "" });
    values.fields.purchaserMembers.value.push({ name: "", title: "" });
    setPurMemberData(copy_purMemberData);
  }
  const addSupMemberDataHandler = (value) => {

    const copy_supMemberData = JSON.parse(JSON.stringify(supMemberData));
    copy_supMemberData.push({ name: "", title: "" });
    values.fields.supplierMembers.value.push({ name: "", title: "" });
    setSupMemberData(copy_supMemberData);
  }

  const removePurMemberDataHandler = (index) => {
    setPurMembersTitle([false])
    setPurMembersName([false])
    setSupMembersName([false]);


    const copy_otherPurMemberError = otherPurMemberError
    copy_otherPurMemberError.splice(index, 1)
    setOtherPurMemberError(copy_otherPurMemberError)


    const copy_purMemberData = JSON.parse(JSON.stringify(purMemberData));
    copy_purMemberData.splice(index, 1);
    setPurMemberData(copy_purMemberData);
    values.fields.purchaserMembers.value.splice(index, 1);
    if (!(history.location.state && history.location.state.bookId)) {
      setPurMemberData(values.fields.purchaserMembers.value);
    }

  };

  const removeSupMemberDataHandler = (index) => {
    setSupMembersTitle([false]);
    setSupMembersName([false]);
    // setPurMembersTitle([false]);
    setPurMembersName([false]);

    const copy_otherSupMemberError = otherSupMemberError
    copy_otherSupMemberError.splice(index, 1)
    setOtherSupMemberError(copy_otherSupMemberError)

    const copy_supMemberData = JSON.parse(JSON.stringify(supMemberData));
    copy_supMemberData.splice(index, 1);
    setSupMemberData(copy_supMemberData);
    values.fields.supplierMembers.value.splice(index, 1);
    console.log("values.fields.supplierMembers.value：：：：：：：：：" + values.fields.supplierMembers.value)
    if (!(history.location.state.bookId && history.location.state)) {
      setSupMemberData(values.fields.supplierMembers.value);
    }


  };


  const addPurMemberDataHandler1 = (value) => {
    const copy_purMemberData = JSON.parse(JSON.stringify(purMemberData1));
    copy_purMemberData.push({ name: "", title: "" });
    values.fields1.purchaserMembers1.value.push({ name: "", title: "" });
    setPurMemberData1(copy_purMemberData);
  }
  const addSupMemberDataHandler1 = (value) => {
    const copy_supMemberData = JSON.parse(JSON.stringify(supMemberData1));
    copy_supMemberData.push({ name: "", title: "" });
    values.fields1.supplierMembers1.value.push({ name: "", title: "" });
    setSupMemberData1(copy_supMemberData);
  }

  const removePurMemberDataHandler1 = (index) => {
    setPurMembersTitle1([false]);
    setPurMembersName1([false]);
    const copy_purMemberData1 = JSON.parse(JSON.stringify(purMemberData1));
    copy_purMemberData1.splice(index, 1);
    setPurMemberData1(copy_purMemberData1);
  };

  const removeSupMemberDataHandler1 = (index) => {
    setSupMembersTitle1([false]);
    setSupMembersName1([false]);
    const copy_supMemberData = JSON.parse(JSON.stringify(supMemberData1));
    copy_supMemberData.splice(index, 1);
    setSupMemberData1(copy_supMemberData);

    console.log("index" + index)
    console.log(supMemberData1.length)


  };






  const initField = function (fieldName, defaultHolder, isRequired) {
    return {
      value: "",
      fieldName: fieldName,
      holder: defaultHolder,
      defaultHolder: defaultHolder,
      isRequired: isRequired,
      isInvalid: false,
    };
  };
  const initObjField = function (fieldName, defaultHolder, isRequired) {
    return {
      value: [{ name: "", title: "" }],
      fieldName: fieldName,
      holder: defaultHolder,
      defaultHolder: defaultHolder,
      isRequired: isRequired,
      isInvalid: false,
    };
  };
  const defaultValue = {
    fields: {
      orgId: initField('orgId', "", true),
      // bookTitle: initField('title', bookTitleDefaultHolder, true),
      purchaserName: initField('purchaserName', purchaserNameDefaultHolder, true),
      purchaserMembers: initObjField('purchaserMembers', purchaserMemberDefaultHolder, true),
      supplierName: initField('supplierName', supplierNameDefaultHolder, true),
      supplierMembers: initObjField('supplierMembers', supplierTitleDefaultHolder, true),

    },
    fields1: {
      purchaserMembers1: initObjField('purchaserMembers1', purchaserMemberDefaultHolder, true),
      supplierMembers1: initObjField('supplierMembers1', supplierTitleDefaultHolder, true),

    },
    inputHolder: {},
    openDialog: false,
    specialReasonStyle: classes.disable_box,
  };

  const [values, setValues] = React.useState(defaultValue);
  const [newSupName, setNewSupName] = useState("");
  const [newPurName, setNewPurName] = useState("");
  const [counterpart, setCounterpart] = useState([]);
  const [supCounterpart, setSupCounterpart] = useState([]);
  let timer;


  const CancelBook = function () {
    history.push("/PO Transactions");
  }
  const CancelBook1 = function () {
    if(history.location.state.duplicated){
      history.push("/PO Transactions");
    }
    else{
      history.push("/PO Transactions/detail/" + history.location.state.bookId);
    }

  }


  const handleChange = (fieldName) => (event,newValue) => {
    let userOptions = [];
    if (timer) {
      clearTimeout(timer);
    }
    console.log(event);
    if (event) {
      if (fieldName == "purchaserName") {
        setNewPurName(newValue);
        console.log("tset" + event.target.value)

        if (event.target.value != "" && event.target.value.length > 2) {

          timer = setTimeout(() => {
            addName.map((item) => {
              if (!item.toLowerCase().indexOf(event.target.value.toLowerCase())) {
                userOptions.push({
                  name: item
                });
              }
              if (event.target.value === ""||event.target.value.length <3) {
                userOptions = []
              }
            }

            )
            setCounterpart(userOptions);


          }, 500);
        }
        else {

          setCounterpart([]);
        }

      } else if (fieldName === "supplierName") {
        setNewSupName(newValue);
        console.log("tset2" + event.target.toString())
        if (event.target.value !== "" && event.target.value.length > 2) {

          timer = setTimeout(() => {
            addName.map((item) => {
              if (!item.toLowerCase().indexOf(event.target.value.toLowerCase())) {
                userOptions.push({
                  name: item
                });
              }
              if (event.target.value == ""||event.target.value.length <3) {
                userOptions = []
              }
            })
            setSupCounterpart(userOptions);

          }, 500);
        }
        else {

          setSupCounterpart([]);

        }
      }



      let curValue = newValue;
      console.log(event.target.innerText+"event.targetvalue")
      let fieldValues = values.fields;

      fieldValues[fieldName].value = curValue;
      fieldValues[fieldName].holder = curValue ? "" : fieldValues.defaultHolder;
      setValues({ ...values, fields: fieldValues });

      if (event.target.value=== "") {
        fieldValues[fieldName].isInvalid = true;
      } else {
        fieldValues[fieldName].isInvalid = false;
      }
    }


  };


  const checkAllValue = () => {
    return checkStringValues(newPurName) && checkStringValues(newSupName) && checkAllObjectValue(supMemberData) && checkAllObjectValue(purMemberData) && checkAllAddObjectValue(supMemberData1) && checkAllAddObjectValue(purMemberData1);
  }

  const checkAllObjectValue = (submitValues) => {

    if (submitValues && submitValues.length > 0) {
      for (let i in submitValues) {
        if (submitValues[i].name && submitValues[i].title) {
          console.log("submitValues[i].name" + submitValues[i].name)
        } else {
          return false;
        }
      }
      return true
    } else {
      return true
    }
  }

  const checkAllAddObjectValue = (submitValues) => {

    if (submitValues && submitValues.length > 0) {
      for (let i in submitValues) {
        if (submitValues[i].name && submitValues[i].title) {
          console.log("submitValues[i].name" + submitValues[i].name)
        } else {
          return false;
        }
      }
      return true

    } else {
      return true
    }

  }
  const [updatesubmit, setUpdatesubmit] = useState([])
  //更新提交值
  const updateSubmitValues = function () {
    let updateSubmitValues = { purchaserName: "", purchaserMembers: [], supplierName: "", supplierMembers: [] };
    updateSubmitValues.purchaserName = newPurName;
    updateSubmitValues.supplierName = newSupName;
    checkUpdateSupMember(supMemberData)
    updateSubmitValues.purchaserMembers = purMemberData
    checkUpdatePurMember(purMemberData)
    if (purMemberData1.length > 0) {
      checkAddPurMember(purMemberData1)
      updateSubmitValues.purchaserMembers = updateSubmitValues.purchaserMembers.concat(purMemberData1)
    }
    updateSubmitValues.supplierMembers = supMemberData;
    if (supMemberData1.length > 0) {
      checkAddSupMember(supMemberData1)
      updateSubmitValues.supplierMembers = updateSubmitValues.supplierMembers.concat(supMemberData1)
    }
    checkMembers(updateSubmitValues)

    return updateSubmitValues;
  }


  //更新book
  const updateBook = function () {
    const updateSubmitValues1 = updateSubmitValues()
    if(history.location.state.duplicated){
      updateSubmitValues1.orgId=history.location.state.orgId;
      if (checkAllValue() && checkEmailIsFriends(otherPurMemberError) && checkEmailIsFriends(otherSupMemberError) && checkEmailIsFriends(otherPurMemberError1) && checkEmailIsFriends(otherSupMemberError1)) {
          newBookService.addBook(updateSubmitValues1).then((result) => {
            history.push({
              pathname: issuePOformPage,
              state: { bookId: result.data.id },
            });
          })
      }
    } else {
      if (checkAllValue() && checkEmailIsFriends(otherPurMemberError) && checkEmailIsFriends(otherSupMemberError) && checkEmailIsFriends(otherPurMemberError1) && checkEmailIsFriends(otherSupMemberError1)) {
        newBookService.updateBookByBookId(history.location.state.bookId, updateSubmitValues1).then((result) => {
          history.push({
            pathname: "/PO Transactions/detail/" + history.location.state.bookId,
            state: { bookId: history.location.state.bookId },
          });
        })
      }
    }
  }


  //新建book
  const onFinish = function () {
    let [isInvalid, fields, submitValues] = checkValues();
    checkMembers(submitValues);

    if (checkPurSubmit(submitValues) && checkSupSubmit(submitValues) && checkEmailIsFriends(otherPurMemberError) && checkEmailIsFriends(otherSupMemberError)) {
      newBookService.addBook(submitValues).then((result) => {
        history.push({
          pathname: issuePOformPage,
          state: { bookId: result.data.id },
        });
      })
      setValues({ ...values, fields: fields });
      if (isInvalid) {
        return;
      }
    }
  };

  const checkEmailIsFriends = (submitMemberValues) => {
    for (let i in submitMemberValues) {
      if (submitMemberValues[i] !== "null") {
        return false
      }
    }
    return true
  }




  const [beforeChangedPurSelectedValue, setBeforeChangedPurSelectedValue] = useState([])
  const updatePurMemberContent = (item, e, index) => {
    let reg = /([\w._-])+@([\w_-])+(\.([\w_-])+){1,2}/;
    item.name = e.currentTarget.value.toLowerCase();
    console.log(friends);
    if (friendsValue.includes(e.currentTarget.value.toLowerCase())) {
      if (beforeChangedPurSelectedValue[index] === e.currentTarget.value.toLowerCase()) {
        otherPurMemberError[index] = "null"
      } else {
        clearError(beforeChangedPurSelectedValue[index])
        if (emailSelected && emailSelected.length > 0) {
          console.log(emailSelected)
          console.log(
            "beforeChangedPurSelectedValue[index]:" + beforeChangedPurSelectedValue[index])
          if (emailSelected.includes(e.currentTarget.value.toLowerCase())) {
            otherPurMemberError[index] = "null"
            // otherPurMemberError[index]="This account has already been set as purchaser or supplier"
            beforeChangedPurSelectedValue[index] = e.currentTarget.value.toLowerCase()
          } else {
            beforeChangedPurSelectedValue[index] = e.currentTarget.value.toLowerCase()
            otherPurMemberError[index] = "null"

          }
        } else {
          beforeChangedPurSelectedValue[index] = e.currentTarget.value.toLowerCase()
          otherPurMemberError[index] = "null"
        }
      }


    } else {
      otherPurMemberError[index] = t('newBookPage.orgMemberError');
      beforeChangedPurSelectedValue[index] = ""
    }


    purMembersName[index] = !e.currentTarget.value.toLowerCase();
    const copy_purMemberData = JSON.parse(JSON.stringify(purMemberData));
    setPurMemberData(copy_purMemberData);
    if (history.location.state && history.location.state.bookId) {
    } else {
      values.fields.purchaserMembers.value[index].name = item.name;
    }
    if (friendsValue.includes(e.currentTarget.value.toLowerCase())) {
      updateEmailSelected()
    }

  };

  const submitAllMember = () => {
    const purchaserMember = []
    const supplierMember = []
    const purchaserMember1 = []
    const supplierMember1 = []
    let onFinishSubmit = []
    let updateSubmit = []
    for (let i in purMemberData) {
      purchaserMember.push(purMemberData[i].name)
    }

    for (let i in supMemberData) {
      supplierMember.push(supMemberData[i].name)
    }
    if (history.location.state && history.location.state.bookId) {

      for (let i in purMemberData1) {
        purchaserMember1.push(purMemberData1[i].name)
      }

      for (let i in supMemberData1) {
        supplierMember1.push(supMemberData1[i].name)
      }
    }

    if (history.location.state && history.location.state.bookId) {
      updateSubmit = purchaserMember.concat(supplierMember).concat(purchaserMember1).concat(supplierMember1)
      return updateSubmit
    } else {
      onFinishSubmit = purchaserMember.concat(supplierMember)
      return onFinishSubmit
    }

  }

  const clearError = (value) => {
    let count = 0
    let j
    const allValues = submitAllMember()
    for (let i in allValues) {
      if (allValues[i] === value) {
        count++
        j = i
      }
    }
    if (count === 1) {
      for (let k in purMemberData) {
        if (purMemberData[k].name === allValues[j]) {
          purMembersName[k] = false;
          break;
        }
      }

      for (let k in supMemberData) {
        if (supMemberData[k].name === allValues[j]) {
          supMembersName[k] = false;
          break;
        }
      }
      if (history.location.state && history.location.state.bookId) {

        for (let k in purMemberData1) {
          if (purMemberData1[k].name === allValues[j]) {
            purMembersName1[k] = false
          }
        }

        for (let k in supMemberData1) {
          if (supMemberData1[k].name === allValues[j]) {
            supMembersName1[k] = false
          }
        }
      }

    }


  }

  const [beforeChangedSupSelectedValue, setBeforeChangedSupSelectedValue] = useState([])
  //  const [beforeChangedValue,setBeforeChangedValue] =useState([])
  const updateSupMemberContent = (item, e, index) => {

    let reg = /([\w._-])+@([\w_-])+(\.([\w_-])+){1,2}/;
    item.name = e.currentTarget.value;

    if (friendsValue.includes(e.currentTarget.value.toLowerCase())) {
      if (beforeChangedSupSelectedValue[index] === e.currentTarget.value.toLowerCase() && beforeChangedSupSelectedValue) {
        otherSupMemberError[index] = "null"
      } else {
        clearError(beforeChangedSupSelectedValue[index])
        if (emailSelected && emailSelected.length > 0) {
          if (emailSelected.includes(e.currentTarget.value.toLowerCase())) {
            otherSupMemberError[index] = "null"
            // otherSupMemberError[index]="This account has already been set as purchaser or supplier"
            beforeChangedSupSelectedValue[index] = e.currentTarget.value.toLowerCase()
          } else {
            beforeChangedSupSelectedValue[index] = e.currentTarget.value.toLowerCase()
            otherSupMemberError[index] = "null"
            console.log(111)
          }
        } else {
          beforeChangedSupSelectedValue[index] = e.currentTarget.value.toLowerCase()
          otherSupMemberError[index] = "null"
        }
      }

    } else {
      otherSupMemberError[index] = t('newBookPage.orgMemberError');
    }

    supMembersName[index] = !e.currentTarget.value;

    const copy_supMemberData = JSON.parse(JSON.stringify(supMemberData));
    setSupMemberData(copy_supMemberData);
    if (history.location.state && history.location.state.bookId) {
    } else {
      values.fields.supplierMembers.value[index].name = item.name;
    }

    if (friendsValue.includes(e.currentTarget.value)) {
      updateEmailSelected()
    }

  }

  const updateEmailSelected = () => {
    const MemberData = beforeChangedPurSelectedValue.concat(beforeChangedSupSelectedValue).concat(beforeChangedPurSelectedValue1).concat(beforeChangedSupSelectedValue1)
    setEmailSelected(MemberData)
  }

  const updatePurMemberTitle = (item, e, index) => {

    item.title = e.currentTarget.textContent;
    purMembersTitle[index] = !e.currentTarget.textContent;
    const copy_purMemberData = JSON.parse(JSON.stringify(purMemberData));
    setPurMemberData(copy_purMemberData);
  }

  const updateSupMemberTitle = (item, e, index) => {
    item.title = e.currentTarget.textContent;
    supMembersTitle[index] = !e.currentTarget.textContent;
    const copy_supMemberData = JSON.parse(JSON.stringify(supMemberData));
    setSupMemberData(copy_supMemberData);
  }

  const updatePurMemberTitle1 = (item, e, index) => {

    item.title = e.currentTarget.textContent;
    purMembersTitle1[index] = !e.currentTarget.textContent;
    const copy_purMemberData1 = JSON.parse(JSON.stringify(purMemberData1));
    setPurMemberData1(copy_purMemberData1);
  }

  const updateSupMemberTitle1 = (item, e, index) => {
    item.title = e.currentTarget.textContent;
    supMembersTitle1[index] = !e.currentTarget.textContent;
    const copy_supMemberData1 = JSON.parse(JSON.stringify(supMemberData1));
    setSupMemberData1(copy_supMemberData1);
  }

  const [beforeChangedPurSelectedValue1, setBeforeChangedPurSelectedValue1] = useState([])
  const updatePurMemberContent1 = (item, e, index) => {
    item.name = e.currentTarget.value;
    purMembersName1[index] = !e.currentTarget.value;

    if (friendsValue.includes(e.currentTarget.value.toLowerCase())) {
      if (beforeChangedPurSelectedValue1[index] === e.currentTarget.value.toLowerCase()) {
        otherPurMemberError1[index] = "null"
      } else {
        clearError(beforeChangedPurSelectedValue1[index])
        if (emailSelected && emailSelected.length > 0) {
          console.log(emailSelected)
          console.log(
            " beforeChangedPurSelectedValue1[index]:" + beforeChangedPurSelectedValue1[index])
          if (emailSelected.includes(e.currentTarget.value.toLowerCase())) {
            otherPurMemberError1[index] = "null"
            // otherPurMemberError1[index]="This account has already been set as purchaser or supplier"
            beforeChangedPurSelectedValue1[index] = e.currentTarget.value.toLowerCase()
          } else {


            beforeChangedPurSelectedValue1[index] = e.currentTarget.value.toLowerCase()


            otherPurMemberError1[index] = "null"
            console.log(111)



          }
        } else {
          beforeChangedPurSelectedValue1[index] = e.currentTarget.value.toLowerCase()
          otherPurMemberError1[index] = "null"
        }
      }
    } else {
      otherPurMemberError1[index] = t('newBookPage.orgMemberError');
    }

    const copy_purMemberData1 = JSON.parse(JSON.stringify(purMemberData1));
    setPurMemberData1(copy_purMemberData1);

  };


  const [beforeChangedSupSelectedValue1, setBeforeChangedSupSelectedValue1] = useState([])
  const updateSupMemberContent1 = (item, e, index) => {
    item.name = e.currentTarget.value;

    if (friendsValue.includes(e.currentTarget.value.toLowerCase())) {
      if (beforeChangedSupSelectedValue1[index] == e.currentTarget.value.toLowerCase()) {
        console.log(999999)
        otherSupMemberError1[index] = "null"
      } else {
        clearError(beforeChangedSupSelectedValue1[index])
        if (emailSelected && emailSelected.length > 0) {
          console.log(emailSelected)
          console.log(
            " beforeChangedSupSelectedValue1[index]:" + beforeChangedSupSelectedValue1[index])
          if (emailSelected.includes(e.currentTarget.value.toLowerCase())) {
            // otherPurMemberError1[index]="This account has already been set as purchaser or supplier"
            otherSupMemberError1[index] = "null"
            beforeChangedSupSelectedValue1[index] = e.currentTarget.value.toLowerCase()
          } else {


            beforeChangedSupSelectedValue1[index] = e.currentTarget.value.toLowerCase()



            otherSupMemberError1[index] = "null"
            console.log(111)



          }
        } else {
          beforeChangedSupSelectedValue1[index] = e.currentTarget.value.toLowerCase()
          otherSupMemberError1[index] = "null"
        }
      }


    } else {
      otherSupMemberError1[index] = t('newBookPage.orgMemberError');
    }





    if (e.currentTarget.value) {
      supMembersName1[index] = false;
    } else {
      supMembersName1[index] = true;
    }

    const copy_supMemberData1 = JSON.parse(JSON.stringify(supMemberData1));
    setSupMemberData1(copy_supMemberData1);

  };





  const handleMembersInputChange = (fieldName, fieldName2, index) => (event, newValue) => {
    let curValue = newValue;
    let fieldValues = values.fields;
    console.log(fieldValues);
    console.log(index)
    if (history.location.state.bookId && history.location.state) {

      if (curValue) {
        if (fieldName == 'purchaserMembers' && fieldName2 == 'member') {

          purMemberData[index].name = curValue;
        } else if (fieldName == 'purchaserMembers' && fieldName2 == 'title') {
          purMembersTitle[index] = false
          purMemberData[index].title = curValue;
        } else if (fieldName == 'supplierMembers' && fieldName2 == 'member') {

          supMemberData[index].name = curValue;
        } else if (fieldName == 'supplierMembers' && fieldName2 == 'title') {
          supMembersTitle[index] = false
          supMemberData[index].title = curValue;
        }



        else if (fieldName == 'purchaserMembers1' && fieldName2 == 'member') {

          purMemberData1[index].name = curValue;
        } else if (fieldName == 'purchaserMembers1' && fieldName2 == 'title') {

          purMemberData1[index].title = curValue;
        } else if (fieldName == 'supplierMembers1' && fieldName2 == 'member') {

          supMemberData1[index].name = curValue;
        } else if (fieldName == 'supplierMembers1' && fieldName2 == 'title') {

          supMemberData1[index].title = curValue;
        }

      } else {

        if (fieldName == 'purchaserMembers' && fieldName2 == 'title') {
          purMembersTitle[index] = true
          purMemberData[index].title = curValue;
        } else if (fieldName == 'supplierMembers' && fieldName2 == 'title') {
          supMembersTitle[index] = true
          supMemberData[index].title = curValue;
        }
      }
    }

    else {
      if (curValue != null) {
        console.log(fieldValues[fieldName].value[index]);
        if (fieldName == 'purchaserMembers' && fieldName2 == 'member') {
          fieldValues[fieldName].value[index].name = curValue;

          purMembersName[index] = false;
        } else if (fieldName == 'purchaserMembers' && fieldName2 == 'title') {

          fieldValues[fieldName].value[index].title = curValue;
          purMembersTitle[index] = false;

        } else if (fieldName == 'supplierMembers' && fieldName2 == 'member') {
          fieldValues[fieldName].value[index].name = curValue;
          supMembersName[index] = false;


        } else if (fieldName == 'supplierMembers' && fieldName2 == 'title') {
          fieldValues[fieldName].value[index].title = curValue;
          supMembersTitle[index] = false;
        }

      } else {
        if (fieldName == 'purchaserMembers' && fieldName2 == 'member') {
          fieldValues[fieldName].value[index].name = "";
          purMembersName[index] = true;

        } else if (fieldName == 'purchaserMembers' && fieldName2 == 'title') {
          fieldValues[fieldName].value[index].title = "";
          purMembersTitle[index] = true;

        } else if (fieldName == 'supplierMembers' && fieldName2 == 'member') {
          fieldValues[fieldName].value[index].name = "";
          supMembersName[index] = true;

        } else if (fieldName == 'supplierMembers' && fieldName2 == 'title') {
          fieldValues[fieldName].value[index].title = "";
          supMembersTitle[index] = true;
        }
      }
    }



  }

  const checkStringValues = function (submitValues) {
    if (submitValues !== undefined && submitValues !== "") {

      return true;
    } else {
      return false;
    }
  }


  const checkUpdatePurMember = function (submitValues) {

    const purUpdateMemberTitle1 = [];
    const purUpdateMemberName1 = [];
    const copyOtherError = [];


    if (submitValues !== undefined) {
      for (let memberTitle1 in submitValues) {
        if (submitValues[memberTitle1].title !== "") {
          purUpdateMemberTitle1.push(false)
        } else {
          purUpdateMemberTitle1.push(true)

        }
        if (submitValues[memberTitle1].name !== "") {
          updatesubmit.push(submitValues[memberTitle1].name)
          purUpdateMemberName1.push(false)
          if (friendsValue.includes(submitValues[memberTitle1].name)) {
            copyOtherError.push("null")
          } else {
            copyOtherError.push(t('newBookPage.orgMemberError'))

          }
        } else {
          copyOtherError.push("null")
          purUpdateMemberName1.push(true)
        }
      }

      setOtherPurMemberError(copyOtherError)
      setPurMembersTitle(purUpdateMemberTitle1)
      setPurMembersName(purUpdateMemberName1)
    }
  }

  const checkUpdateSupMember = function (submitValues) {
    const supUpdateMemberTitle1 = [];
    const supUpdateMemberName1 = [];
    const copyOtherSupMemberErrorUpdate = [];

    if (submitValues !== undefined) {

      for (let memberTitle in submitValues) {
        console.log("checkUpdateSupMembermemberTitle:" + memberTitle)
        if (submitValues[memberTitle].title !== "") {
          supUpdateMemberTitle1.push(false)

        } else {
          supUpdateMemberTitle1.push(true)

        }
        if (submitValues[memberTitle].name !== "") {
          updatesubmit.push(submitValues[memberTitle].name)
          supUpdateMemberName1.push(false)
          if (friendsValue.includes(submitValues[memberTitle].name)) {

            copyOtherSupMemberErrorUpdate.push("null")
          }
          else {
            console.log("friendsValue" + friendsValue)
            copyOtherSupMemberErrorUpdate.push(t('newBookPage.orgMemberError'))

          }
        } else {
          copyOtherSupMemberErrorUpdate.push("null")
          supUpdateMemberName1.push(true)


        }
      }
      setSupMembersTitle(supUpdateMemberTitle1)
      setSupMembersName(supUpdateMemberName1)
      setOtherSupMemberError(copyOtherSupMemberErrorUpdate)
    }
  }

  const checkAddPurMember = function (submitValues) {

    const purAddMemberTitle1 = [];
    const purAddMemberName1 = [];
    const copyOtherError1 = [];


    if (submitValues !== undefined) {
      for (let memberTitle1 in submitValues) {
        if (submitValues[memberTitle1].title !== "") {
          purAddMemberTitle1.push(false)
        } else {
          purAddMemberTitle1.push(true)

        }
        if (submitValues[memberTitle1].name !== "") {
          updatesubmit.push(submitValues[memberTitle1].name)
          purAddMemberName1.push(false)
          if (friendsValue.includes(submitValues[memberTitle1].name)) {
            copyOtherError1.push("null")
          } else {
            copyOtherError1.push(t('newBookPage.orgMemberError'))

          }
        } else {
          copyOtherError1.push("null")
          purAddMemberName1.push(true)

        }
      }

      setOtherPurMemberError1(copyOtherError1)
      setPurMembersTitle1(purAddMemberTitle1)
      setPurMembersName1(purAddMemberName1)
    }
  }

  const checkAddSupMember = function (submitValues) {

    const supAddMemberTitle1 = [];
    const supAddMemberName1 = [];
    const copyOtherSupMemberError1 = [];
    if (submitValues !== undefined) {

      for (let memberTitle in submitValues) {
        console.log(memberTitle + memberTitle)
        if (submitValues[memberTitle].title !== "") {
          supAddMemberTitle1.push(false)

        } else {
          supAddMemberTitle1.push(true)

        }

        if (submitValues[memberTitle].name !== "") {
          updatesubmit.push(submitValues[memberTitle].name)
          supAddMemberName1.push(false)
          if (friendsValue.includes(submitValues[memberTitle].name)) {
            copyOtherSupMemberError1.push("null")
          }
          else {
            copyOtherSupMemberError1.push(t('newBookPage.orgMemberError'))

          }
        } else {
          copyOtherSupMemberError1.push("null")
          supAddMemberName1.push(true)

        }
      }
      setSupMembersTitle1(supAddMemberTitle1)
      setSupMembersName1(supAddMemberName1)
      setOtherSupMemberError1(copyOtherSupMemberError1)
    }


  }




  const checkSupSubmit = (submitValues) => {

    if (submitValues !== undefined) {

      if (submitValues.supplierMembers !== undefined) {
        for (let memberTitle in submitValues.supplierMembers) {
          if (!((submitValues.supplierMembers[memberTitle].title) && (submitValues.supplierMembers[memberTitle].name))) {
            return false
          } else {
            return true
          }
        }
      } else {
        return false
      }

    } else {
      return false
    }



  }
  const checkPurSubmit = (submitValues) => {

    if (submitValues !== undefined) {

      if (submitValues.purchaserMembers !== undefined) {
        for (let memberTitle1 in submitValues.purchaserMembers) {
          if (!((submitValues.purchaserMembers[memberTitle1].title) && (submitValues.purchaserMembers[memberTitle1].name))) {
            return false
          } else { return true }
        }
      } else {
        return false
      }
    } else {
      return false
    }


  }

  const checkMembers = function (submitValues) {
    const supMemberTitle1 = [];
    const supMemberName1 = [];
    const purMemberTitle1 = [];
    const purMemberName1 = [];
    const supMemberSubmit = [];
    const purMemberSubmit = [];


    if (submitValues.supplierMembers !== undefined) {
      for (let memberTitle in submitValues.supplierMembers) {

        console.log(memberTitle + memberTitle)
        if (submitValues.supplierMembers[memberTitle].title) {
          supMemberTitle1.push(false)

        } else {
          supMemberTitle1.push(true)
          console.log()
        }

        if (submitValues.supplierMembers[memberTitle].name) {
          supMemberName1.push(false)
          supMemberSubmit.push(submitValues.supplierMembers[memberTitle].name)

          // console.log("submitValues"+submitValues )
          // console.log("submitValues.supplierMembers[memberTitle].name :"+submitValues.supplierMembers[memberTitle].name )

        } else {
          supMemberName1.push(true)

        }
      }

      setSupMembersTitle(supMemberTitle1)
      setSupMembersName(supMemberName1)
    }
    if (submitValues.purchaserMembers !== undefined) {
      for (let memberTitle1 in submitValues.purchaserMembers) {

        if (submitValues.purchaserMembers[memberTitle1].title) {
          purMemberTitle1.push(false)
        } else {
          purMemberTitle1.push(true)

        }
        if (submitValues.purchaserMembers[memberTitle1].name) {
          purMemberName1.push(false)
          purMemberSubmit.push(submitValues.purchaserMembers[memberTitle1].name)
        } else {
          purMemberName1.push(true)
        }
      }

      setPurMembersTitle(purMemberTitle1)
      setPurMembersName(purMemberName1)
    }
    const duplicatedCheckMember = supMemberSubmit.concat(purMemberSubmit)
    duplicatedCheck(duplicatedCheckMember)
  }


  const duplicatedCheck = (value) => {
    const duplicated = []
    for (var i = 0; i < value.length; i++) {
      for (var j = i + 1; j < value.length; j++) {
        if (value[i] == value[j] && (!duplicated.includes(value[i]))) {
          duplicated.push(value[i])
        }
      }
    }
    if (duplicated && duplicated.length > 0) {
      const copy_duplicated = JSON.stringify(duplicated)
      for (let i in supMemberData) {
        if (duplicated.includes(supMemberData[i].name)) {
          supMembersName[i] = true
          setSupMembersName(supMembersName)

        }
      }
      for (let i in purMemberData) {
        if (duplicated.includes(purMemberData[i].name)) {
          purMembersName[i] = true
          setPurMembersName(purMembersName)

        }
      }
      for (let i in purMemberData1) {
        if (duplicated.includes(purMemberData1[i].name)) {
          purMembersName1[i] = true
          setPurMembersName1(purMembersName1)

        }
      }
      for (let i in supMemberData1) {
        if (duplicated.includes(supMemberData1[i].name)) {
          supMembersName1[i] = true
          setSupMembersName1(supMembersName1)

        }
      }
      setDuplicateError("The account has already been set as purchaser or supplier, please set another one.")
    }

  }






  const checkValues = function () {
    values.fields.orgId.value = history.location.state.orgId;
    let isInvalid = false;
    let fields = values.fields;
    let submitValues = {};
    let reg = /([\w._-])+@([\w_-])+(\.([\w_-])+){1,2}/;
    for (let fieldName in fields) {
      let fieldInfo = fields[fieldName];
      let submitName = fieldInfo.fieldName;
      if (!submitName) {
        continue;
      }
      let fieldValue = fieldInfo.value;
      let fieldValid = (fieldName === 'emailAddress') ? reg.test(fieldValue) : true;
      if (fieldInfo.isRequired && (!fieldValue || !fieldValid)) {
        fieldInfo.isInvalid = true;
        isInvalid = true;
      } else {
        fieldInfo.isInvalid = false;
        submitValues[submitName] = fieldValue;
      }
    }

    return [isInvalid, fields, submitValues];
  };

  return (
    <CommonLayout>{(!(history.location.state && history.location.state.bookId)) ?
      <div className={classes.root}>
        <Typography variant='h6' gutterBottom className={classes.box}>
        {t('newBookPage.newBook')}
        </Typography>
        <hr className={classes.hrColor} />
        <form className={classes.box1} noValidate autoComplete='off'>
          <div className={classes.section1}>
            <Typography variant='body2' gutterBottom className={classes.text2}>
            {t('newBookPage.purchaserName')}<span className={classes.field_name_red}>*</span>
            </Typography>
            <FormControl variant='outlined' size='small' className={classes.width1}>
              <Autocomplete
                value={newPurName}
                freeSolo
                fullWidth
                selectOnFocus
                size='small'
                className={classes.input}
                options={counterpart.map((option) => option.name)}
                // getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    {option}
                  </React.Fragment>
                )}
                // onChange={handleChange('purchaserName')}
                onInputChange={handleChange('purchaserName')}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  let keyword = params.inputValue;
                  // if (keyword == "") {
                  //   filtered.splice(0)
                  // }
                  return filtered;
                }
                }
                renderInput={(params) => <TextField {...params} placeholder={values.fields.purchaserName.holder} variant="outlined" error={values.fields.purchaserName.isInvalid} />}
              />
            </FormControl>
          </div>
          <div>
            <Grid item>
              {purMemberData && purMemberData.length > 0 && purMemberData.map((item, index) => {
                return (
                  <div className={classes.section2} key={index}>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={5}>
                        <div >
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                            <Box display={{ xs: 'block', sm: 'block', md: 'none' }}>Purchaser</Box>
                            <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>{t('newBookPage.purchaserMember')}<span className={classes.field_name_red}>*</span></Box>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div>
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                          {t('newBookPage.title')}<span className={classes.field_name_red}>*</span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={5}>

                        <FormControl variant='outlined' size='small' fullWidth >
                          <TextField className={classes.input}
                            value={item.name}
                            size='small'
                            variant="outlined"
                            required={true}
                            placeholder={purchaserMemberDefaultHolder}
                            error={purMembersName[index]}
                            onChange={(e) => updatePurMemberContent(item, e, index)}
                          />
                          <span className={otherPurMemberError[index] === "null" ? classes.errorNotShow : classes.errorShow}>{otherPurMemberError[index]}</span>

                        </FormControl>
                      </Grid>
                      <Grid item xs={5}>
                        <div>
                          <FormControl variant='outlined' size='small' fullWidth>
                            <Autocomplete
                              // value={item.title}
                              value={purMemberData[index].title}

                              freeSolo
                              fullWidth
                              selectOnFocus
                              size='small'
                              className={classes.input}
                              options={newbookConstants.title.map((option) => option)}
                              // getOptionLabel={(option) => option.label}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  {option}
                                </React.Fragment>
                              )}
                              onChange={handleMembersInputChange('purchaserMembers', 'title', index)}
                              onInputChange={handleMembersInputChange('purchaserMembers', 'title', index)}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                let keyword = params.inputValue.toLowerCase();
                                // if (keyword != "") {
                                //   filtered.push(keyword)
                                // }
                                return filtered;
                              }}
                              renderInput={(params) => <TextField {...params} label={purchaserTitleDefaultHolder} variant="outlined" error={purMembersTitle[index]} />}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      {removePermisson ?

                        <Grid item xs={1} >
                          <div style={{ visibility: (purMemberData.length + purMemberData1.length) > 1 ? "visible" : "hidden" }} onClick={() => removePurMemberDataHandler(index)}>
                            <DeleteIcon className="agendaItemDelete" size="small" />
                          </div>
                        </Grid>
                        : ""}

                    </Grid>
                  </div>
                )
              })}
            </Grid>
            <Grid item>
              <Grid item xs={1}>
                <div className={classes.box_add} style={{ display: "flex", flexDirection: "column-reverse" }} onClick={addPurMemberDataHandler}>

                  <AddCircleIcon className="agendaItemAdd" size="small"></AddCircleIcon>

                </div>
              </Grid>
            </Grid>
          </div>
          <Divider variant="middle" />
          <div className={classes.section1}>
            <Typography variant='body2' gutterBottom className={classes.text2}>
            {t('newBookPage.supplierName')}<span className={classes.field_name_red}>*</span>
            </Typography>
            <FormControl variant='outlined' size='small' className={classes.width1}>
              <Autocomplete
                value={newSupName}
                freeSolo
                fullWidth
                selectOnFocus
                size='small'
                className={classes.input}
                options={supCounterpart.map((option) => option.name)}
                // getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    {option}
                  </React.Fragment>
                )}

                onInputChange={handleChange('supplierName')}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  let keyword = params.inputValue.toLowerCase();
                  // if (keyword != "") {
                  //   filtered.push(keyword)
                  // }
                  return filtered;
                }}
                renderInput={(params) => <TextField {...params} placeholder={values.fields.supplierName.holder} variant="outlined" error={values.fields.supplierName.isInvalid} />}
              />
            </FormControl>
          </div>
          <div>
            <Grid item>
              {supMemberData && supMemberData.length > 0 && supMemberData.map((item, index) => {
                return (
                  <div className={classes.section2} key={index}>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={5}>
                        <div >
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                            <Box display={{ xs: 'block', sm: 'block', md: 'none' }}>Supplier</Box>
                            <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>{t('newBookPage.supplierMember')}<span className={classes.field_name_red}>*</span></Box>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div>
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                          {t('newBookPage.title')}<span className={classes.field_name_red}>*</span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={5} >
                        <FormControl variant='outlined' size='small' fullWidth >
                          <TextField className={classes.input}
                            value={item.name}
                            size='small'
                            // defaultValue={item.name}
                            variant="outlined"
                            placeholder={purchaserMemberDefaultHolder}
                            error={supMembersName[index]}
                            // &&!checkedPurMember1(item.name)
                            onChange={(e) => updateSupMemberContent(item, e, index)}

                          />

                          <span className={otherSupMemberError[index] === 'null' ? classes.errorNotShow : classes.errorShow}>{otherSupMemberError[index]}</span>
                        </FormControl>
                      </Grid>
                      <Grid item xs={5}>
                        <FormControl variant='outlined' size='small' fullWidth>

                          <Autocomplete
                            value={supMemberData[index].title}
                            id="supMembersTitle"

                            freeSolo
                            fullWidth
                            selectOnFocus
                            size='small'
                            className={classes.input}
                            options={newbookConstants.title.map((option) => option)}
                            // getOptionLabel={(option) => option.label}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                {option}
                              </React.Fragment>
                            )}
                            // onChange={(event, newValue) => {
                            //   setHost(newValue);
                            // }}
                            // getOptionSelected={(option, value) => option.value === value.value}
                            onChange={handleMembersInputChange('supplierMembers', 'title', index)}
                            onInputChange={handleMembersInputChange('supplierMembers', 'title', index)}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              let keyword = params.inputValue.toLowerCase();
                              // if (keyword != "") {
                              //   filtered.push(keyword)
                              // }
                              return filtered;

                            }}
                            renderInput={(params) => <TextField {...params} onKeyDown={e => {
                              if (e.keyCode === 13) {
                                e.stopPropagation();
                                e.preventDefault();
                              }
                            }} label={supplierTitleDefaultHolder} variant="outlined" error={supMembersTitle[index]} />}
                          />
                        </FormControl>

                      </Grid>
                      {removePermisson ?
                        <Grid item xs={1}>
                          <div style={{ visibility: (supMemberData.length + supMemberData1.length) > 1 ? "visible" : "hidden" }} onClick={() => removeSupMemberDataHandler(index)}>
                            <DeleteIcon className="agendaItemDelete" size="small" />
                          </div>
                        </Grid> : ""}
                    </Grid>
                  </div>
                )
              })}
            </Grid>
            <Grid item>
              <Grid item xs={1}>
                <div className={classes.box_add} style={{ display: "flex", flexDirection: "column-reverse" }} onClick={addSupMemberDataHandler}>

                  <AddCircleIcon className="agendaItemAdd" size="small"></AddCircleIcon>

                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container alignItems="center" justifyContent="center" direction="row">
            <span className={duplicateError === 'null' ? classes.errorNotShow : classes.errorShow}>{duplicateError}</span>
          </Grid>
          <Grid container alignItems="center" justifyContent="center" direction="row">
            <Grid className={classes.buttonDiv} >
              <div>
                <LoadingButton
                  className={classes.buttonSpacing}
                  variant='contained'
                  // isLoading={loading}
                  onClick={onFinish}>
                  {t('newBookPage.confirmButton')}
                </LoadingButton></div>
              <div>
                <Button
                  className={classes.buttonSpacing1}
                  variant='contained'
                  // isLoading={loading}
                  onClick={CancelBook}>
                    {t('common.cancel')}
                </Button></div>
            </Grid>
          </Grid>

        </form>
      </div>

      :



      <div className={classes.root}>
        <Typography variant='h6' gutterBottom className={classes.box}>
        {history.location.state.duplicated?t('newBookPage.newBook'):t('newBookPage.editBook')}
        </Typography>
        <hr className={classes.hrColor} />
        <form className={classes.box1} noValidate autoComplete='off'>
          <div className={classes.section1}>
            <Typography variant='body2' gutterBottom className={classes.text2}>
            {t('newBookPage.purchaserName')}<span className={classes.field_name_red}>*</span>
            </Typography>
            <FormControl variant='outlined' size='small' className={classes.width1}>
              <Autocomplete
                value={newPurName}
                freeSolo
                fullWidth
                selectOnFocus
                size='small'
                className={classes.input}
                options={counterpart.map((option) => option.name)}
                // getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    {option}
                  </React.Fragment>
                )}
                // onChange={handleChange('purchaserName')}
                onInputChange={handleChange('purchaserName')}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  let keyword = params.inputValue.toLowerCase();
                  // if (keyword != "") {
                  //   filtered.push(keyword)
                  // }
                  return filtered;
                }}
                renderInput={(params) => <TextField {...params} placeholder={values.fields.purchaserName.holder} variant="outlined" error={values.fields.purchaserName.isInvalid} />}
              />

            </FormControl>
          </div>

          <div>
            <Grid item>
              {purMemberData && purMemberData.length > 0 && purMemberData.map((item, index) => {

                return (
                  <div className={classes.section2} key={index}>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={5}>
                        <div >
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                            <Box display={{ xs: 'block', sm: 'block', md: 'none' }}>Purchaser</Box>
                            <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>{t('newBookPage.purchaserMember')}<span className={classes.field_name_red}>*</span></Box>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div>
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                          {t('newBookPage.title')}<span className={classes.field_name_red}>*</span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={5}>

                        <FormControl variant='outlined' size='small' fullWidth >
                          <TextField className={classes.input}
                            value={item.name}
                            size='small'
                            variant="outlined"
                            required={true}
                            placeholder={purchaserMemberDefaultHolder}
                            error={purMembersName[index]}
                            onChange={(e) => updatePurMemberContent(item, e, index)}
                          />
                          <span className={otherPurMemberError[index] === "null" ? classes.errorNotShow : classes.errorShow}>{otherPurMemberError[index]}</span>

                        </FormControl>
                      </Grid>
                      <Grid item xs={5}>
                        <div>
                          <FormControl variant='outlined' size='small' fullWidth>
                            <Autocomplete
                              // value={item.title}
                              value={purMemberData[index].title}

                              freeSolo
                              fullWidth
                              selectOnFocus
                              size='small'
                              className={classes.input}
                              options={newbookConstants.title.map((option) => option)}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  {option}
                                </React.Fragment>
                              )}
                              onChange={handleMembersInputChange('purchaserMembers', 'title', index)}
                              onInputChange={handleMembersInputChange('purchaserMembers', 'title', index)}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                let keyword = params.inputValue.toLowerCase();
                                // if (keyword != "") {
                                //   filtered.push(keyword)
                                // }
                                return filtered;
                              }}
                              renderInput={(params) => <TextField {...params} label={purchaserTitleDefaultHolder} variant="outlined" error={purMembersTitle[index]} />}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                      {removePermisson ?

                        <Grid item xs={1}>
                          <div style={{ visibility: (purMemberData.length + purMemberData1.length) > 1 ? "visible" : "hidden" }} onClick={() => removePurMemberDataHandler(index)}>
                            <DeleteIcon className="agendaItemDelete" size="small" />
                          </div>
                        </Grid>
                        : ""}

                    </Grid>
                  </div>
                )
              })}
            </Grid>
          </div>
          {(assignPermisson) ?
            <div>
              <Grid item>
                {purMemberData1 && purMemberData1.length > 0 && purMemberData1.map((item, index) => {
                  return (
                    <div className={classes.section2} key={index}>
                      <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={5}>
                          <div>
                            <Typography variant='body2' gutterBottom className={classes.text2}>
                              <Box display={{ xs: 'block', sm: 'block', md: 'none' }}>Purchaser</Box>
                              <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>{t('newBookPage.purchaserMember')}<span className={classes.field_name_red}>*</span></Box>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={5}>
                          <div>
                            <Typography variant='body2' gutterBottom className={classes.text2}>
                            {t('newBookPage.title')}<span className={classes.field_name_red}>*</span>
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={5}>
                          <FormControl variant='outlined' size='small' fullWidth >
                            <TextField className={classes.input}
                              value={item.name}
                              size='small'
                              variant="outlined"
                              placeholder={purchaserMemberDefaultHolder}
                              error={purMembersName1[index]}
                              // onChange={handleMembersInputChange('purchaserMembers1', 'member', index)}
                              onChange={(e) => updatePurMemberContent1(item, e, index)}
                            />
                            <span className={otherPurMemberError1[index] === "null" ? classes.errorNotShow : classes.errorShow}>{otherPurMemberError1[index]}</span>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <div>
                            <FormControl variant='outlined' size='small' fullWidth>
                              <Autocomplete
                                value={item.titile}
                                freeSolo
                                fullWidth
                                selectOnFocus
                                size='small'
                                className={classes.input}
                                options={newbookConstants.title.map((option) => option)}
                                // getOptionLabel={(option) => option.label}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    {option}
                                  </React.Fragment>
                                )}

                                onChange={(e) => updatePurMemberTitle1(item, e, index)}
                                onInputChange={(e) => updatePurMemberTitle1(item, e, index)}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  let keyword = params.inputValue.toLowerCase();
                                  // if (keyword != "") {
                                  //   filtered.push(keyword)
                                  // }
                                  return filtered;
                                }}
                                renderInput={(params) => <TextField {...params} label={purchaserTitleDefaultHolder} variant="outlined" error={purMembersTitle1[index]} />}
                              />

                            </FormControl>
                          </div>
                        </Grid>
                        {removePermisson ?
                          <Grid item xs={1}>
                            <div style={{ visibility: (purMemberData.length + purMemberData1.length) > 1 ? "visible" : "hidden" }} onClick={() => removePurMemberDataHandler1(index)}>
                              <DeleteIcon className="agendaItemDelete" size="small" />
                            </div>
                          </Grid> : ""}

                      </Grid>
                    </div>
                  )
                })}
              </Grid>
              {assignPermisson ?
                <Grid item>
                  <Grid item xs={1}>
                    <div className={classes.box_add} style={{ display: "flex", flexDirection: "column-reverse" }} onClick={addPurMemberDataHandler1}>

                      <AddCircleIcon className="agendaItemAdd" size="small"></AddCircleIcon>

                    </div>
                  </Grid>
                </Grid> : ""}
            </div> : ""}









          <Divider variant="middle" />

          <div className={classes.section1}>
            <Typography variant='body2' gutterBottom className={classes.text2}>
            {t('newBookPage.supplierName')}<span className={classes.field_name_red}>*</span>
            </Typography>
            <FormControl variant='outlined' size='small' className={classes.width1}>

              <Autocomplete
                value={newSupName}
                freeSolo
                fullWidth
                selectOnFocus
                size='small'
                className={classes.input}
                options={supCounterpart.map((option) => option.name)}
                // getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    {option}
                  </React.Fragment>
                )}
                // onChange={handleChange('supplierName')}
                onInputChange={handleChange('supplierName')}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  let keyword = params.inputValue.toLowerCase();
                  // if (keyword != "") {
                  //   filtered.push(keyword)
                  // }
                  return filtered;
                }}
                renderInput={(params) => <TextField {...params} placeholder={values.fields.supplierName.holder} variant="outlined" error={values.fields.supplierName.isInvalid} />}
              />
            </FormControl>
          </div>
          <div>
            <Grid item>
              {supMemberData && supMemberData.length > 0 && supMemberData.map((item, index) => {
                return (
                  <div className={classes.section2} key={index}>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={5}>
                        <div >
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                            <Box display={{ xs: 'block', sm: 'block', md: 'none' }}>Supplier</Box>
                            <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>{t('newBookPage.supplierMember')}<span className={classes.field_name_red}>*</span></Box>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div>
                          <Typography variant='body2' gutterBottom className={classes.text2}>
                          {t('newBookPage.title')}<span className={classes.field_name_red}>*</span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={5} >
                        <FormControl variant='outlined' size='small' fullWidth >
                          <TextField className={classes.input}
                            value={item.name}
                            size='small'
                            // defaultValue={item.name}
                            variant="outlined"
                            placeholder={purchaserMemberDefaultHolder}
                            error={supMembersName[index]}
                            // &&!checkedPurMember1(item.name)
                            onChange={(e) => updateSupMemberContent(item, e, index)}

                          />

                          <span className={otherSupMemberError[index] === 'null' ? classes.errorNotShow : classes.errorShow}>{otherSupMemberError[index]}</span>
                        </FormControl>
                      </Grid>
                      <Grid item xs={5}>
                        <FormControl variant='outlined' size='small' fullWidth>

                          <Autocomplete
                            value={supMemberData[index].title}
                            id="supMembersTitle"

                            freeSolo
                            fullWidth
                            selectOnFocus
                            size='small'
                            className={classes.input}
                            options={newbookConstants.title.map((option) => option)}
                            // getOptionLabel={(option) => option.label}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                {option}
                              </React.Fragment>
                            )}
                            onChange={handleMembersInputChange('supplierMembers', 'title', index)}
                            onInputChange={handleMembersInputChange('supplierMembers', 'title', index)}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              let keyword = params.inputValue.toLowerCase();
                              // if (keyword != "") {
                              //   filtered.push(keyword)
                              // }
                              return filtered;

                            }}
                            renderInput={(params) => <TextField {...params} onKeyDown={e => {
                              if (e.keyCode === 13) {
                                e.stopPropagation();
                                e.preventDefault();
                              }
                            }} label={supplierTitleDefaultHolder} variant="outlined" error={supMembersTitle[index]} />}
                          />
                        </FormControl>

                      </Grid>
                      {removePermisson ?
                        <Grid item xs={1}>
                          <div style={{ visibility: (supMemberData.length + supMemberData1.length) > 1 ? "visible" : "hidden" }} onClick={() => removeSupMemberDataHandler(index)}>
                            <DeleteIcon className="agendaItemDelete" size="small" />
                          </div>
                        </Grid> : ""}
                    </Grid>
                  </div>
                )
              })}
            </Grid>
          </div>
          {(assignPermisson) ?
            <div>
              <Grid item>
                {supMemberData1 && supMemberData1.length > 0 && supMemberData1.map((item, index) => {
                  return (
                    <div className={classes.section2} key={index}>
                      <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={5}>
                          <div >
                            <Typography variant='body2' gutterBottom className={classes.text2}>
                              <Box display={{ xs: 'block', sm: 'block', md: 'none' }}>Supplier</Box>
                              <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>{t('newBookPage.supplierMember')}<span className={classes.field_name_red}>*</span></Box>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={5}>
                          <div>
                            <Typography variant='body2' gutterBottom className={classes.text2}>
                            {t('newBookPage.title')}<span className={classes.field_name_red}>*</span>
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={5} >
                          <FormControl variant='outlined' size='small' fullWidth >
                            <TextField className={classes.input}
                              value={item.name}
                              size='small'
                              variant="outlined"
                              placeholder={purchaserMemberDefaultHolder}
                              error={supMembersName1[index]}
                              // onChange={handleMembersInputChange('supplierMembers1', 'member', index)}
                              onChange={(e) => updateSupMemberContent1(item, e, index)}
                            />
                            <span className={otherSupMemberError1[index] === 'null' ? classes.errorNotShow : classes.errorShow}>{otherSupMemberError1[index]}</span>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl variant='outlined' size='small' fullWidth>

                            <Autocomplete

                              id="supMembersTitle"
                              value={item.titile}
                              freeSolo
                              fullWidth
                              selectOnFocus
                              size='small'
                              className={classes.input}
                              options={newbookConstants.title.map((option) => option)}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  {option}
                                </React.Fragment>
                              )}
                              // onChange={(event, newValue) => {
                              //   setHost(newValue);
                              // }}

                              onChange={(e) => updateSupMemberTitle1(item, e, index)}
                              onInputChange={(e) => updateSupMemberTitle1(item, e, index)}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                let keyword = params.inputValue.toLowerCase();
                                // if (keyword != "") {
                                //   filtered.push(keyword)
                                // }
                                return filtered;

                              }}
                              renderInput={(params) => <TextField {...params} onKeyDown={e => {
                                if (e.keyCode === 13) {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }
                              }} label={supplierTitleDefaultHolder} variant="outlined" error={supMembersTitle1[index]} />}
                            />
                          </FormControl>
                        </Grid>
                        {removePermisson ?
                          <Grid item xs={1}>
                            <div style={{ visibility: (supMemberData.length + supMemberData1.length) > 1 ? "visible" : "hidden" }} onClick={() => removeSupMemberDataHandler1(index)}>
                              <DeleteIcon className="agendaItemDelete" size="small" />
                            </div>
                          </Grid> : ""}
                      </Grid>
                    </div>
                  )
                })}
              </Grid>
              <Grid item>
                <Grid item xs={1}>
                  <div className={classes.box_add} style={{ display: "flex", flexDirection: "column-reverse" }} onClick={addSupMemberDataHandler1}>
                    <AddCircleIcon className="agendaItemAdd" size="small"></AddCircleIcon>
                    {/* style={{ color: "#127bb0" }} */}
                  </div>
                </Grid>
              </Grid>
            </div> : ""}

          <Grid container alignItems="center" justifyContent="center" direction="row">
            <span className={duplicateError === 'null' ? classes.errorNotShow : classes.errorShow}>{duplicateError}</span>
          </Grid>


          <Grid container alignItems="center" justifyContent="center" direction="row">
            <Grid className={classes.buttonDiv}>
              <LoadingButton
                className={classes.buttonSpacing}
                variant='contained'
                isLoading={loading}
                onClick={updateBook}>
                {history.location.state.duplicated?t('newBookPage.confirmButton'):t('common.edit')}
              </LoadingButton>
              <Button
                className={classes.buttonSpacing}
                variant='contained'
                isLoading={loading}
                onClick={CancelBook1}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>}
    </CommonLayout>
  );
}
