import { lightTheme, darkTheme} from './theme';
import { useDarkMode } from './hooks/useDarkMode';
import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import useToken from './hooks/useToken';
import iamService from './services/iamService';
import React,{useState,useEffect} from "react";
import axios from 'axios';
import Header from './components/Header';
import RedirectTo from './components/RedirectTo';
import IAMCallback from './components/IAMCallback';
import AppRouters from "./AppRouters";
import { ApplicationContext } from './context/applicationContext';
import applicationService from './services/applicationService';
import {BrowserRouter as Router, Redirect, Route, Switch,useHistory} from "react-router-dom";
import HeaderBar from './components/Header2';

function App() {
  const { token , setToken} = useToken();
  const [theme, toggleTheme, componentMounted] = useDarkMode();
  const history = useHistory();
  const [applications, setApplications] = useState([]);
  useEffect(() => {
      if(token){
          applicationService.getApplications().then((result) => {
              if (result.data && result.data.length > 0) {
                  setApplications(result.data);
                  //props.applicationChange(result.data[0]);
                  return result.data[0].id;
              }
          });
      }
  }, [])

  const [currentApp, setCurrentApp] = useState();
  if (!componentMounted) {
    return <div />
  };

  function themeChangeHandler(){
    toggleTheme();
    console.log('theme', theme);
  }
  function applicationChange(app){
    console.log(app);
    setCurrentApp(app);
  }

  function refreshApplicationHandler(){
    applicationService.getApplicationById(currentApp.id).then((result)=>{
        const currentApp = result.data;
        setCurrentApp(currentApp);
    })
  }

  function refreshApplicationsHandler(){
    if(token){
      applicationService.getApplications().then((result) => {
          if (result.data && result.data.length > 0) {
              setApplications(result.data);
              return result.data[0].id;
          }
      });
  }
  }

  const path = window.location.pathname;
  const baseURL = process.env.REACT_APP_BASE_URL;
  if (path === baseURL + '/iamCallback') {
    return (
      <IAMCallback></IAMCallback>
    )
  }

  const publicRouterPool = new Set([
    baseURL + '/register',
    baseURL + '/register-success',
    baseURL + '/register-pending',
    baseURL + '/register-error',
    baseURL + '/',
    baseURL + '/terms-privacy',
  ]);


  if (!publicRouterPool.has(path) && !token) {
    window.localStorage.setItem('access_url',window.location.href);
    const iamLoginURL = iamService.loginURL();
    return (
        <RedirectTo url={iamLoginURL} />
    )
  } else if(token && token.user_info.status !== 1) {
    return (<RedirectTo url={baseURL + '/register-pending'} />)
  }
    // else if(publicRouterPool.has(path) && token && path === baseURL + '/'){
    //   return (<RedirectTo url={baseURL + '/Open Comm'} />)
    // }
    else {
    return (
        <ThemeProvider theme={theme === 'light' ? createTheme(lightTheme) : createTheme(darkTheme) }>
          <ApplicationContext.Provider value={{application:currentApp,refreshApplication:refreshApplicationHandler,switchApplication:applicationChange,applications:applications,refreshApplications:refreshApplicationsHandler}} >
            <Router basename={process.env.REACT_APP_BASE_URL}>
              <HeaderBar applications={applications} themeChanged={themeChangeHandler} applicationChange={applicationChange}></HeaderBar>
              <AppRouters apps={applications}></AppRouters>
            </Router>
          </ApplicationContext.Provider>
        </ThemeProvider>
    );
  }
}

export default App;
