const commonTheme = {
    typography: {
        fontFamily: [
            'IBMPlexSans-Regular',
            'Roboto',
            'Helvetica',
            'Arial', 
            'sans-serif'
        ].join(','),
        h1:{
            fontSize:'3rem',
            fontFamily: 'IBMPlexSans-Bold'
        },
        h2:{
            fontSize:'2.25rem',
            fontFamily: 'IBMPlexSans-Bold'
        },
        h3:{
            fontSize:'1.125rem',
            fontFamily: 'IBMPlexSans-Bold'
        },
        h4:{
            fontSize:'0.875rem',
            fontFamily: 'IBMPlexSans-Bold'
        },
        body1:{
            fontSize:'1rem',
            fontFamily: 'IBMPlexSans',
            '@media (max-width:600px)':  {
                fontSize: '0.8rem',
            },
        },
        body2:{
            fontSize:'0.875rem',
            fontFamily: 'IBMPlexSans-Regular',
            '@media (max-width:600px)':  {
                fontSize: '0.7rem',
            },
        },
        subtitle1:{
            fontSize:'1.125rem',
            fontFamily: 'IBMPlexSans-Regular',
            '@media (max-width:600px)':  {
                fontSize: '1rem',
            },
        },
        subtitle2:{
            fontSize:'1rem',
            fontFamily: 'IBMPlexSans-Regular',
            '@media (max-width:600px)':  {
                fontSize: '0.8rem',
            },
        }
    },
    overrides: {
        // Style sheet name ⚛️
        MuiButton: {
            root: {
                borderRadius: '4px',
                textTransform: 'none',
                height: '40px',
                
            },
            contained: {
                text:{
                    color: '#FFFFFF'
                },
                backgroundColor: '#FFFFFF',
                '&:hover': { // changes colors for hover state
                    backgroundColor: '#FFFFFF',
                },
            },
            containedPrimary: {
                backgroundColor: '#2DA44E',
                '&:hover': { // changes colors for hover state
                    backgroundColor: 'rgb(44,151,75)',
                },
            }
        },
        MuiFilledInput: {
            root: {
                // backgroundColor: '#ffffff',
                '&:hover': {
                    // backgroundColor: '#ffffff',
                },
                '.Mui-focused': {
                    // backgroundColor: '#ffffff',
                },
            },
            underline: {
                '&:before': {
                    border: 'none !important',
                },
                '&:after': {
                    border: 'none !important',
                }
            }
        },
        MuiFormLabel: {
            root: {
                // color: "#000000",
                '& .MuiForm-Focused': {
                    // color: 'rgba(0, 0, 0, 0.87)'
                }
            }
        },
        MuiOutlinedInput:{
            root:{
                fontSize:'14px'
            }
        },
        MuiMenuItem:{
            root:{
                fontSize:'14px'
            }
        }
        // MuiDialog: {
        //     paper: {
        //         border: 'solid 4px #656565'
        //     }
        // },
    },
}

export default commonTheme;