import React , {useEffect ,useState, useRef, useContext}from 'react';
import { useHistory } from "react-router-dom";
import { Grid, Paper, Typography, Button, IconButton} from '@material-ui/core';
import { Table, TableBody, TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import LoadingButton from "../../components/LoadingButton";
import { ApplicationContext } from "../../context/applicationContext";
import inviteService from '../../services/inviteService';
import useToken from '../../hooks/useToken';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme=> ({
    orgListItemDetailPanel: {
        marginBottom:"13px",
        border: theme.currentTheme.border[3],
    },
    thTitle: {
        letterSpacing: "0.05px",
        color: "#5d5d5d",
    },
    root: {
        borderBottom: "none ",
        backgroundColor: theme.currentTheme.backgrounds[10],
    },
}))

export default function OrganizationListsItem(props){
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const history = useHistory();

    const {token, setToken} = useToken();

    const ref = useRef();

    const [showDetail,setShowDetail] = useState(false);

    const { refreshApplications} = useContext(ApplicationContext);

    const [isLoading, setIsLoading] = React.useState(false);

    const [inviationList,setInviationList] = useState([]);
    useEffect(()=>{
        inviteService.getInvitationList().then((result)=> {
            result.map( item => {
                item["isLoading"] = false;
            })
            setInviationList(result);
        }) 
    },[])
    
    const delInvitationItem = (index)=> {
        let data = inviationList[index];
        inviationList[index].isLoading = true;
        setInviationList([...inviationList]);
        inviteService.finishInvitation(
            data.invitationCode,
            data.invitee,
            data.orgId
        ).then((result)=> {
            inviteService.getInvitationList().then((result)=> {
                result.map( item => {
                    item["isLoading"] = false;
                })
                setInviationList(result);
            }) 
            setIsLoading(false);
            refreshApplications();
        })
    }

    const transferName = (beforeName) => {
        if(beforeName==="Org_Admin"){
            return t('myOrg.roleValueadmin');
        }else if (beforeName==="Org_Manager"){
            return t('myOrg.roleValuemanager');
        }else if (beforeName==="Org_Member"){
            return t('myOrg.roleValuemember');
        }
    } 

    return(
        <div>
            { inviationList && inviationList.length>0 ?  (
                    <Grid key={inviationList.id} >
                        <TableContainer component={Paper} className={classes.orgListItemDetailPanel}>
                            <Table aria-label="simple table" size="small" classes={{ root: classes.root}} >
                                <TableHead >
                                    <TableRow className={classes.thTitle}>
                                        <TableCell className={ classes.root}>{t('myInvite.createTime')}</TableCell>
                                        <TableCell className={ classes.root}>{t('myInvite.orgName')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('myInvite.serviceName')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('myInvite.servicePackage')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('myInvite.Inviter')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('myInvite.Title')}</TableCell>
                                        <TableCell className={ classes.root} align="left">{t('myInvite.Role')}</TableCell>
                                        <TableCell className={ classes.root} align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                {inviationList.map((item ,index)=>(
                                    <TableBody key={index}>
                                        <TableRow >
                                            <TableCell component="th" scope="item" className={ classes.root}>{new moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.orgName}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.serviceName}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.servicePackage}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.inviter}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.title}</TableCell>
                                            <TableCell className={ classes.root} align="left">{item.role[0] ? transferName(item.role[0]) : ''}</TableCell>
                                            <TableCell className={ classes.root} align="left">
                                                <LoadingButton  isLoading={item.isLoading} variant="contained" color="primary"  onClick={()=>delInvitationItem(index)}>
                                                    <Typography variant="body2"  >
                                                    {t('myInvite.joinButton')}
                                                    </Typography>
                                                </LoadingButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                            </Table>
                        </TableContainer>
                    </Grid>
                    ) 
                :t('myInvite.noInviteMsg')
            }
        </div>
    )
}
