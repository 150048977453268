const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
};

function getOrganizationById(orgId,application){
    if(application && application.organizations){
        for (let i = 0; i < application.organizations.length; i++) {
            const org = application.organizations[i];
            if(org.id === orgId){
                return org;
            }
        }
    }
    return null;
}
function getRolesByOrgId(orgId,application){
    const token = getToken();
    const org = getOrganizationById(orgId,application);
    if(token && org){
        for (let i = 0; i < org.users.length; i++) {
            const user = org.users[i];
            if(user.email === token.username){
                return user.user_role;
            }
        }
    }
    return null;
}

function hasRole(orgId,application,roleName){
    const roles = getRolesByOrgId(orgId, application);
    if(roles){
        for (let i = 0; i < roles.length; i++) {
            const role = roles[i];
            if(role === roleName){
                return true;
            }
        }
    }
    return false;
}

function getOrgServicePackById(orgId,application){
    const token = getToken();
    const org = getOrganizationById(orgId,application);
    let returnValue="";
    if(org){
        returnValue = org.service_package
    }
    return returnValue;
}

function getOrgExpiredDateById(orgId,application){
    const token = getToken();
    let returnValue="";
    const org = getOrganizationById(orgId,application);
    if(org){
        returnValue=org.expire_date
    }
    return returnValue;
}

function IsOrgAdmin(orgId,application){
    return hasRole(orgId, application, 'Org_Admin')
}

function IsOrgManager(orgId,application){
    return hasRole(orgId, application, 'Org_Manager')
}

function IsOrgMember(orgId,application){
    return hasRole(orgId, application, 'Org_Member')
}

export default {
    IsOrgAdmin,
    IsOrgManager,
    IsOrgMember,
    getOrgServicePackById,
    getOrgExpiredDateById
}
