import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {TextField} from "@material-ui/core";
import React, {useState} from "react";

export default function PasswordInput(props) {
    const {title, value, valid, errorMsg, onChange} = props
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField fullWidth
                   placeholder={title}
                   variant='outlined'
                   autoComplete='none'
                   value={value}
                   size="small"
                   type={showPassword ? 'text' : 'password'}
                   onChange={onChange}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                               <IconButton
                                   aria-label="toggle password visibility"
                                   onClick={()=> setShowPassword(!showPassword)}
                               >
                                   {showPassword ? <Visibility/> : <VisibilityOff/>}
                               </IconButton>
                           </InputAdornment>
                       ),
                   }}
                   error={!valid}
                   helperText={errorMsg}
        >
        </TextField>
    )
}