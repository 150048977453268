import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    container:{
        padding: '50px 20%',
        display: 'flex',
        flexDirection: 'column',
    },
    hintsText: {
        color: theme.currentTheme.colors[3],
    },
    errorText: {
        color: '#ff0000',
    },
    submitButton: {
        height: '40px !important',
        marginTop: '30px'
    },
    form: {
        '& >div': {
            marginTop: theme.spacing(3)
        }
    }
}));

// .right-scroll-div {
//     height: max-content;
//     width: 85%;
//     margin: auto 0px;
// }
// .form-container {
//     padding-right: 50px;
//     height: max-content;
// }
//
// @media only screen and (max-width: 768px) {
//     .left-part {
//         display: none;
//     }
// }

export default styles;