const langCN = {
  lang: 'English',
  changeLang: "切换到 English",
  common: {
    ok: '确定',
    cancel: '取消',
    save: '保存',
    confirm:'确认',
    actions:'操作',
    delete: '删除',
    edit: '修改',
    noRecord: '没有数据显示',
    download:'下载',
    check:'检查',
    add:'添加',
    home:'首页',
    export:'导出',
    exportAsCsv:'保存成CSV文件',
    duplucate:'复制',
    sharePO:'分享账本',
    qrCode:'生成二维码',
    print:'打印',
    settings: '设置',
    all_selected: '全选',
    all_not_selected: '全取消',
    add_new_note: '$username 添加了新的备注。',
    review_notifications: '查看通知',
    no_notifications: '没有新的通知',
    mark_read: '确认已读',
    attach: '上传附件',
    downloadAttachment: '下载附件',
    noAttach: '没有附件下载',
    shareBook: '分享账本',
    bookDistribute: '账本分享给',
    supplier: '供应商',
    purchaser: '采购商',
    email: '邮件地址',
  },
  shareBook: {
    noUser: '您填入的邮箱地址不是openuse平台的注册用户，无法分享账本。',
    noTransactionOrg: '该邮箱用户没有公司群组，无法分享账本。请告知对方在"您的群组"中设定公司群组。',
    companyOrgDesc: '分享到的公司组群',
    confirmEmail: '确认邮件地址',
    unShare: '解除分享',
    unShareDesc: '解除分享后，您的分享数据将无法恢复！',
    sharedToUser: '已分享的用户',
    sharedToCompanyOrg: '已分享的组群',
  },
  header:{
    openOffice:'开元',
    liveOfficiating:'现场办公',
    liveSourcing:'现场采购',
    liveAudit:'现场审计',
    liveParing:'现场配对',
    openBook:'开卷',
    poTransactions:'PO 追记',
    inventory:'库存管理',
    openTrace:'开明',
    openComm:'开会'
  },
  login: {
    signIn: '登录',
    signUp: '注册'
  },
  select: {
    selectOrg: '选择组织'
  },
  platformHomepage:{
    content1:'供应链企业的开放共享服务平台',
    content2:'我们为供应链中的企业创建了这个平台，以便使用软件即服务 (SaaS) 进行共享。 如今，供应链中的企业在通过软件与采购商和供应商进行协作时遇到了困难。 openuse.io 使企业能够将采购商和供应商无缝连接起来。 采购商和供应商通过平台托管的Open Book、Open Office、Open Trace、Open Comm等SaaS进行互动。 例如，Open Book 记录采购商和供应商之间的活动，以便上游和下游都清楚地记录未完成的采购订单、账户余额、库存和运输。 该平台不断添加更多由 openuse.io 和第三方开发的 SaaS，以提供最佳的客户体验。',
    content2_1: '我们为供应链中的企业创建了这个平台，以便使用软件即服务 (SaaS) 进行共享。 openuse.io 使企业能够将采购商和供应商无缝连接起来。 采购商和供应商通过平台托管的',
    content2_2: 'Open Book、',
    content2_3: 'Open Office、',
    content2_4: 'Open Trace、',
    content2_5: '',
    content2_6: 'Open Comm',
    content2_7: '等SaaS进行互动。',
    content3:'交易会取消？旅行受到限制？',
    content4:'你能接触哪些品牌？',
    content5:'新冠病毒对我们有什么影响？',
    content6:'你可以使用哪些品牌？',
    content7:'哪里可以找到制造商？',
    content8Header:'Live Sourcing(实时采选)',
    content8:'实时采选是提供给采购商和供应商的解决方案。买方希望对供应商进行审查，供应商希望展示其能力。实时采选服务让采购商和供应商在24/7开放的虚拟房间（如接待室、工厂、签约室和会议室）中协同工作，免去差旅。',
    content9Header:'OPEN BOOK(开卷)',
    po_transactions: 'PO 追记',
    inventory_control: '库存管理',
    live_sourcing: '现场采选',
    live_office: '现场办公',
    content9:'Open Book 让您创建库存控制，详细了解库存订单、库存趋势和库存预留。 您可以使用库存创建组织(群)并邀请任何利益相关者加入，例如，销售团队可以通过库存控制实时生成销售订单等。',
    content9_1:'Open Book 让您创建记录采购订单交易的账簿。 这个账簿为采购商和供应商提供未结订单、帐户余额和货运状态的实时可见性。',
    content9_2:'Open Book 让您可以创建库存控制，详细了解库存订单、库存趋势和库存预留。 您可以创建群组并邀请任何利益相关者加入使用库存控制。',
    content10Header:'OPEN OFFICE(开元)',
    content10_1:'实时采选是提供给采购商和供应商的解决方案。买方希望对供应商进行审查，供应商希望展示其能力。实时采选服务让采购商和供应商在24/7开放的虚拟房间（如接待室、工厂、签约室和样品室）中协同工作，免去差旅。',
    content10_2:'Live Office 为远程工作人员提供了一个元办公室，可以在个人办公室、会议室、咖啡室和娱乐室等虚拟房间中一起工作。 远程工作人员通过他们在元办公室创建的“活动”自我管理。 生产力和灵感都显著增加。',
    button:'申请演示',
    button1:'注册 openuse.io',
    button2:'了解更多',
    terms:'服务条款',
    privacy:'隐私声明'
  },
  homePageDemo: {
    title: '我对 openuse.io 的演示很感兴趣。请联系我',
    firstName: '您的名字',
    lastName: '您的姓氏',
  },
  homePage: {
    selOrg: '选择群组',
    newOrg: '创建群组',
    warehouse: '库存列表',
    management: '管理',
    checkInOutMgt: '出入库审核管理',
    resvationMgt: '预留管理',
    goodsMgt: '成品目录管理',
    materialGoodsMgt: '材料目录管理',
    supplierMgt: '供应商管理',
    customerMgt: '客户管理',
    finishedGoods: '成品库',
    material: '材料库',
    transfer: '中转库',
    scraped: '废料库',
    id: '仓库编号',
    manager: '经理',
    phone: '电话',
    address: '地址',
    notes: '备注',
    checkIn:'入库登记',
    checkOut:'出库登记',
    reserve:'预留登记',
    description1:'库存',
    description2:'是一项管理成品、半成品、材料和废料库存的服务。它实时跟踪物品的转移、存储和状态。它加快销售团队填发销售合同，实时处理应急合同。它提供了一个易于使用的出入库管理审核。它解决了仓管使用Excel表格的痛点，避免了和销售、运输、会计等部门沟通上的差错。',
    createOrgTips:'现在就创建属于您的库存管理服务组织吧！',
    addWarehouse:'新建仓库'
  },
  poLearnMore: {
    title: 'PO追记',
    benefitsTitle: '用户收益',
    benefits1: '您是否需要电子账本采购订单而不是在电子邮件中搜索采购订单附件？',
    benefits2: '您需要了解未结订单和账户余额吗？',
    benefits3: '您需要供应商或客户对账吗？',
    benefits4: '您是否担心 PO交易记录丢失引起的纠纷？',
    experienceTitle: '用户体验',
    experience1: '在 openuse•io 平台上注册',
    experience2: '在主页上选择“开卷”，然后选择PO追记',
    experience3: '创建群组',
    experience4: '邀请利益相关者加入您的群组',
    experience5: '愉快地与利益相关者一起工作',
    price: '价目',
    demo: '申请演示',
    video: 'https://player.bilibili.com/player.html?aid=684179755&bvid=BV1fU4y1m7Jh&cid=776521032&page=1'
  },
  inventoryControlLearnMore: {
    title: '库存管理',
    benefitsTitle: '用户收益',
    benefits1: '您是否希望您的销售团队及时锁定库以应对询价？',
    benefits2: '您想要您的库存记录近在手边吗, 并可分享给利益相关者？',
    benefits3: '您是否担心用Excel表格管理库存造成信息滞后？',
    experienceTitle: '用户体验',
    experience1: '在 openuse•io 平台上注册',
    experience2: '在主页上选择“开卷”，然后选择“库存管理”',
    experience3: '创建群组',
    experience4: '邀请利益相关者加入您的群组',
    experience5: '愉快地与利益相关者一起工作',
    price: '价目',
    demo: '申请演示',
    video: 'https://player.bilibili.com/player.html?aid=726244537&bvid=BV1AS4y1h7Mp&cid=711086801&page=1'
  },
  liveSourcingLearnMore: {
    title: '现场采选',
    benefitsTitle: '用户收益',
    benefits1: '作为采购商，您在寻找最佳供应商吗？',
    benefits2: '作为供应商，您想添加更多采购商或品牌吗？',
    benefits3: '您是否被限制国际旅行或者您想减少商务旅行？',
    benefits4: '你渴望参观工厂吗？',
    benefits5: '你期待看到和触摸样品吗？',
    benefits6: '您希望实时签署 RFQ 和其他协议吗？',
    benefits7: '您喜欢 24/7 全天候与供应商或采购商接触吗？',
    experienceTitle: '用户体验',
    experience1: '在 openuse•io 平台上注册',
    experience2: '在主页上选择“开元”，然后选择现场采选',
    experience3: '创建群组',
    experience4: '邀请利益相关者加入您的群组',
    experience5: '愉快地与利益相关者一起互动',
    price: '价目',
    demo: '申请演示',
    video: 'https://player.bilibili.com/player.html?aid=898614935&bvid=BV1MN4y1M7Dv&cid=776609588&page=1'
  },
  liveOfficeLearnMore: {
    title: '现场办公',
    benefitsTitle: '用户收益',
    benefits1: '作为一名经理，您是否希望您的远程工作人员具有协作性、有生产力和有启发性？',
    benefits2: '作为经理，您是否希望您的远程工作人员保持透明？',
    benefits3: '作为经理，您是否希望您的远程工作人员保持透明？',
    benefits4: '作为一名员工，您是否对视频会议感到疲倦，需要一个灵活的工作场所？',
    benefits5: '作为一名员工，您是否希望远程工作时您的努力得到奖励？',
    experienceTitle: '用户体验',
    experience1: '在 openuse•io 平台上注册',
    experience2: '在主页上选择“开元”，然后选择现场办公',
    experience3: '创建群组',
    experience4: '邀请利益相关者加入您的群组',
    experience5: '愉快地和你的同事一起工作',
    price: '价目',
    demo: '申请演示',
    video: 'https://player.bilibili.com/player.html?aid=898614935&bvid=BV1MN4y1M7Dv&cid=776609588&page=1'
  },
  itemTotalStock: {
    title: '单品总存量查询',
    inputHint: '请输入单品编号或名称',
    goodsId: '单品编号',
    name: '名称',
    description: '说明',
    spec: '规格',
    currency: '货币',
    rev: '版本号',
    unitforStorage: '存储计量单位',
    unitforCost: '计价计量单位',
    totalQty: '单品总库存',
    totalCost: '单品总成本',
    quantity: '批次总库存',
    lotCost: '批次总成本',
    lotNum: '批次号',
    checkInDate: '入库日期',
    location: '存储位置',
    notes: '备注',
  },
  itemBatchStock: {
    title: '批次存量查询',
    inputHint: '请输入批次号',
    goodsId: '单品编号',
    name: '名称',
    description: '说明',
    spec: '规格',
    currency: '货币',
    lotNum: '批次号',
    checkInDate: '入库日期',
    location: '存储位置',
    totalLotQty: '批次总库存',
    totalLotCost: '批次总成本',
  },
  warehouse: {
    addHouse: '添加仓库',
    name: '仓库名称',
    id: '编号',
    manager: '经理',
    phone: '电话',
    address: '地址',
    notes: '备注',
    editHouse:'修改仓库信息',
    delHouse: '删除仓库',
    staff: '员工',
    deleteConfirmation: '确定删除该仓库吗？',
    deleteTitle: '仓库删除确认',
    noRecord: '当前没有数据',
    viewHouse:'展示仓库信息',
    isMaterial:'材料仓库?'
  },
  goodsManagement:{
    title:'成品目录管理',
    searchHint:'请根据编号或者名称查询',
    addFormTitle:'添加单品',
    editFormTitle:'修改单品',
    goodsId:'单品编号',
    goodsName:'单品名称',
    category:'类型',
    description:'说明',
    rev:'版本号',
    spec:'规格',
    eSpec:'品种/类型/牌号/规格',
    standard:'生产标准',
    unitforStorage:'存储计量单位',
    unitforCost:'计价计量单位',
    currency:'货币',
    notes:'备注',
    deleteConfirm:'确定删除这条数据吗？',
    addButton:'添加新品',
    uploadButton:'批量上传',
    batchUploadResultTitle:'批量上传结果',
    unSupportCsv:'噢! 不支持扫描该CSV的格式. 请重新选择再试.',
    errorTips1:'目前库存不为0，不可更改！'

  },
  mGoodsManagement:{
    title:'材料目录管理',
    goodsType:'品种',
    brand:'牌号',
    shape:'类型',
    dimension:'规格'
  },
  materialGoods:{
    goodsType:'品种',
    goodsGrade:'牌号',
    goodsShape:'类型',
    goodsDimension1:'规格尺寸1',
    goodsDimension2:'规格尺寸2',
    goodsDimension3:'规格尺寸3',
    dimDiameter:'直径',
    dimStickness:'厚',
    dimWall:'壁厚',
    dimLength:'长',
    dimWidth:'宽',
    dimSize1:'大小',
    dimSize2:'粗细',
    dimWantonly:'任意',
    typelistSteel:'钢',
    typelistStainless:'不锈钢',
    typelistAl:'铝',
    typelistTi:'钛',
    typelistNi:'镍',
    typelistLi:'锂',
    typelistZr:'锆',
    typelistCu:'铜',
    shapelistBar:'棒',
    shapelistSeamless:'无缝管',
    shapelistWelding:'焊接管',
    shapelistSheet:'板',
    shapelistStrip:'条-块-方棒',
    shapelsitWire:'丝',
    shapelistGrain:'颗粒',
    shapelistPowder:'粉',
    shapelistSpecial:'异形',
    shapelistOthers:'其它',
  },

  customerManagement:{
    title:'客户管理',
    searchHint:'请根据编号或者名称查询',
    addFormTitle:'添加客户',
    id:'编号',
    name:'名称',
    contact:'联系人',
    phone:'电话',
    address:'地址',
    notes:'备注',
    deleteConfirm:'确定删除这条数据吗？'
  },
  supplierManagement:{
    title:'供应商管理',
    searchHint:'请根据编号或者名称查询',
    addFormTitle:'添加供应商',
    id:'编号',
    name:'名称',
    contact:'联系人',
    phone:'电话',
    address:'地址',
    notes:'备注',
    deleteConfirm:'确定删除这条数据吗？'
  },
  checkInOutManagement:{
    title:'出库/入库管理',
    searchHint:'请根据编号或者名称查询',
    searchHint1:'请根据批次号查询',
    type:'单品编号',
    warehouse:'仓库',
    goodsIdName:'单品编号/名称',
    checkDate:'出入库日期',
    lotNum:'批次号',
    warehouseStaff:'库管员',
    requestor:'经办人',
    billNumber:'出入库单号',
    status:'状态',
    supplier:'供应商',
    customer:'客户',
    createTime:'创建日期',
    optionCheckIn:'入库',
    optionCheckOut:'出库',
    refBillNumber: '关联单号',
    deleteButton:'取消出入库'
  },
  checkIn: {
    title: '入库登记',
    billNo: '入库单号',
    refBillNo: '关联入库单号（例如：运单号）',
    goodsIdOrName: '单品编号/名称',
    category: '类型',
    description: '说明',
    rev: '版本号',
    spec: '规格',
    standard: '生产标准',
    unitforCost: '存储计量单位',
    unitforStorage: '计价计量单位',
    currency: '货币',
    lotNo: '批次号',
    refLotNo: '关联批次号',
    checkInCost: '入库单品总成本',
    qtyForCost: '计价数量',
    qtyForStorage: '存储数量',
    certification: '证书号/证书文件名',
    location: '存储位置',
    refNo: '关联号（例如：订单号）',
    supplier: '供应商',
    customer: '客户',
    inspector: '检验员',
    inspectionDate: '检验日期',
    requestor: '经办人',
    warehouseStaff: '库管员',
    notes: '备注',
    generate: '生成入库单',
    billOrder: '入库单',
    generated: '已经生成',
    checkInBillNumerExist:'入库单号已存在，请重新生成一个单号',
    LotNumberAlreadyExists:'批次号已存在，请选择正确的单品',
    calculatedQTYforCostInconsistent:'数量计算(计价数量:存储数量)与以往批次数据不符，请重新输入',
    calculatedUnitpriceInconsistent:'单价计算与以往批次数据不符，请重新输入',
    po:'PO 订货合同',
    so:'SO 销售合同',
    wo:'WO 内部生产合同',
    sncr:'SNCR 不合格品报告',
    rma:'RMA 退货授权',
    other:'其他'
  },
  checkOut: {
    title: '出库登记',
    billNo: '出库单号',
    refBillNo: '关联出库单号（例如：运单号）',
    goodsIdOrName: '单品编号/名称',
    category: '类型',
    description: '说明',
    rev: '版本号',
    spec: '规格',
    standard: '生产标准',
    unitforCost: '存储计量单位',
    unitforStorage: '计价计量单位',
    currency: '货币',
    lotNo: '批次号',
    checkOutQtyForStorage: '出库总数量',
    checkOutTotalCost: '出库总成本',
    checkOutCost: '出库成本',
    qtyForCost: '计价数量',
    qtyForStorage: '存储数量',
    certification: '证书号/证书文件名',
    location: '存储位置',
    refNo: '关联号（例如：订单号）',
    supplier: '供应商',
    customer: '客户',
    inspector: '检验员',
    inspectionDate: '检验日期',
    requestor: '经办人',
    warehouseStaff: '库管员',
    notes: '备注',
    generate: '生成出库单',
    billOrder: '出库单',
    generated: '已经生成',
    checkOutQtyExceeds: '出库数量超过了单品或批次库存',
    avaliableQty:'可用库存',
    checkOutBillNumerExist:'出库单号已存在，请重新生成一个单号'
  },
  reserve: {
    title: '预留登记',
    refBillNo:'关联号',
    reserveDate:'预留天数',
    requestor:'申请人',
    confirm:'预留',
    number:'数量',
    cancel:'取消预留',
    checkOutQtyForStorage: '预留总数量',
    checkOutTotalCost: '预留总成本',
    checkOutCost: '预留成本',
    checkOut: '出库',
  },
  poManagementHomepage:{
    outstandingBook:'未完成账本',
    closedBook:'已关闭账本',
    searchByPONumber:'根据PO号查询',
    balance:'余额',
    addBook:'添加账本',
    bookTitle:'账本',
    poDate:'PO日期',
    purchaserName:'采购商名称',
    purchaserMember:'采购商人员',
    supplierName:'供应商名称',
    supplierMember:'供应商人员',
    bookOwner:'账本所有方',
    closedDate:'关闭日期',
    poTotal:'PO 总额',
    outstandingPO:'未完成PO',
    invoice:'对账/发票',
    payment:'支付',
    delivery:'收发',
    issuePO:'上传采购订单',
    closeBook:'关闭账本',
    deleteBook:'删除账本',
    checkInventory:'检查库存',
    addAlarm:'预警管理',
    addLineRef:'关联管理',
    closeLine:'关闭行',
    description1:'PO 追记',
    description2:'是一本对买卖双方公开、透明的会计式帐本, 它记录下单、修改、确认、发单等所有双方的活动，提供买卖双方清晰的对账和结账记录。它解决了订单、修改、确认、发票等重要信息分散在邮件、附件、短信或电话交谈中，并由此可能产生的信息丢失问题。PO追记采用Event Sourcing的最新技术，使得每条记录不能被篡改，为可追查数据提供真实性保障。',
    createOrgTips:'现在就创建属于您的PO追记服务组织吧！',
    downloadPo:'下载采购单',
    bookMenu:'独立账本',
    totalBookMenu:'总账本',
    invoiceCheck:'对账/开票',
    bookingDate:'预订日期',
    bookingDateWidth: '80px',
    invoiceDate: '开票日期',
    requestDate: '报关日期',
    invoiceDateWidth: '80px',
    all: '全部',
    notInvoiced: '未开票',
    unpaid: '未支付',
    paid: '已付款',
    qtyAlarm: '修改的QTY小于本行已经发货的数量',
  },
  newBookPage:{
    newBook:'新建账本',
    purchaserName:'采购商名称',
    purchaserMember:'采购商人员',
    supplierName:'供应商名称',
    supplierMember:'供应商人员',
    title:'职务',
    sales:'销售',
    accounting:'财务',
    buyer:'采购',
    warehouse:'库管',
    orgMemberError:'只能添加本组织成员',
    purchaserTips:'这里通常指的是采购商公司的名称',
    supplierTips:'这里通常指的是供应商公司的名称',
    searchTips:'根据电子邮件地址查询',
    confirmButton:'确定并上传采购订单',
    titleTips:'请选择职务',
    editBook:'修改账本',
  },
  viewBookPage:{
    viewBook:'查看账本',
    creator:'创建者',
    createTime:'创建时间',
    lastUpdate:'最后更新时间',
    status:'状态'
  },
  closeBookPage:{
    closeBook:'关闭账本',
    closeBookContent:'您确定要关闭此账本吗？',
  },
  deleteBookPage:{
    deleteBook:'删除账本',
    deleteBookContent:'您确定要删除此账本吗？',
  },
  poFormPage:{
    importExcel: '导入Excel',
    poForm:'PO 表单',
    poNumber:'PO号(采购单号)',
    customer:'客户',
    refNumber:'关联号',
    rev:'版本',
    deliveryTerm:'交货方式',
    paymentTerm:'付款方式',
    poDate:'PO日期',
    supplierCode:'供应商号',
    supplierPhoneNumber:'供应商电话',
    currency:'货币',
    supplierAddress:'供应商地址',
    shippingAddress:'收货地址',
    line:'行号',
    item:'货号',
    product:'产品名称',
    QTY:'计价数量',
    unit:'计价单位',
    storageUnit:'记存',
    price:'单价',
    tax:'税',
    discount:'折扣',
    lineTotal:'行总价',
    date:'日期',
    alarmDate:'预警日期',
    alarmNotes:'预警内容',
    alarmOn:'已预警',
    alarmTrue:'是',
    alarmFalse:'否',
    lineRefNO:'关联号',
    actions:'操作',
    notes:'备注',
    scan:'扫描',
    scanTitle:'上传文件扫描并自动填写表单',
    warningTitle: '导入Excel提示',
    warningTips1: '您的文件中有必填项未完成, 重复的行, 日期格式不正确, 这些行不予导入！',
    warningTips2: '您的文件中有必填项未完成, 重复的行, 这些行不予导入！',
    warningTips3: '您的文件中有必填项未完成, 日期格式不正确, 这些行不予导入！',
    warningTips4: '您的文件中有重复的行, 日期格式不正确, 这些行不予导入！',
    warningTips5: '您的文件中有必填项未完成, 这些行不予导入！',
    warningTips6: '您的文件中有日期格式不正确, 这些行不予导入！',
    warningTips7: '您的文件中有重复的行, 这些行不予导入！',
    fileOverwrite:'文件覆盖',
    fileOverwriteTips:'你确定想要将文件内容覆盖已存在的数据吗？',
    poNumberExistTips:'此PO号已经存在',
    outstandingPO:'未完成PO',
    checkInventory:'查看库存',
    unSupportPDFTitle:'文件扫描',
    unSupportPDF:'噢! 不支持扫描该PDF的格式. 请重新选择再试.',
    supplierName:'供应商名称',
    purchaserName:'采购商名称',
    refLines:'已关联',
    noLines:'没有已关联的数据',
    addRefLine:'添加关联',
    refAmount:'关联数量',
    display_column: '选择要显示的列',
    po_note: 'PO行备注',
  },
  invoicePage:{
    addInvoice:'添加 对账/发票',
    poNumber:'PO号（采购单号）',
    paymentTerm:'付款方式',
    currency:'货币',
    invoiceNumber:'发票号',
    invoiceDate:'发票日期',
    invoiceTotalAmount:'发票总额',
    purchaserName:'采购商名称',
    purchaserTINEINVAT:'采购商纳税人识别号',
    purchaserID:'采购商编号',
    supplierName:'供应商名称',
    supplierTINEINVAT:'供应商纳税人识别号',
    TINTips:'您填写的TIN/EIN/VAT将用作此PO所有后续发票的参考值，请仔细检查。',
    errorTips1:'请注意，发票行总额与PO行总额不一致。',
    errorTips2:'请选择至少一个行号',
    notes:'备注',
    status:'状态',
    checked:'已对账',
    billed:'已开票',
    paid:'已付款',
    line:'行号',
    noInvoiceLine: '当前没有发票',
    existedInvoiceNumber:'发票号已存在',
    statementNumber:'对账单号',
    statementDate:'对账日期',
    statementAmount:'对账总额',
    statementButton:'对账',
    invoiceButton:'开票',
    totalAmount:'总额',
    searchByStatementOrInvoice:'对账单号或发票号',
    cancelStatementTips:'确定删除对账单？',
    generateNumber:'生成对账单号',
    invoiceNumberExistTips:'发票号已存在，请输入其他的发票号',
  },
  paymentPage:{
    addPayment:'添加支付',
    poNumber:'PO号（采购单号）',
    poDate:'PO日期',
    currency:'货币',
    paymentActions:'支付类型',
    deposit:'押金',
    invoice:'发票',
    paymentAmount:'支付总额',
    errorTips1:'请注意，付款金额与发票总额不一致。',
    errorTips2:'订单余额结清。单击右上角按钮可关闭账本。',
    errorTips3:'请选择至少一个选项',
    notes:'备注',
    user:'用户',
    invoiceNumber:'发票号',
    noPaymentLine: '当前没有支付记录',
    batch:'发票支付',
  },
  shippingPage:{
    addShipping:'发货',
    getShipping:'收货',
    noShippingLine:'当前没有收发记录',
    invoiceNumber:'发票号',
    shippingNumber:'运单号',
    shippingDate :'发货日期',
    shippingQTY:'总数量',
    shippingAddressFrom:'发货地址',
    shippingAddressTo:'收货地址',
    errorTips2:'请选择至少一个行号',
    errorTips1:'请注意，发货数量不能超过行可发货总数。',
    status:'状态',
    shipped:'已发货',
    received:'已收货',
    exportContractNo:'出口合同号',
    baoguanNo:'报关单号',
    baoguanDate:'报关日期',
    waimaoxinxi:'外贸信息',
    btnShipping: '发货',
    btnShippingAndDeclare: '发货并报关',
    btnConfirmRequest: '确认',
    btnCancelRequest: '取消',
  },
  outstandingBalancePage:{
    outstandingBalance:'未清余额',
    outstandingLineBalance:'未清行余额',
    actions:'操作类型',
    line:'行号',
    receivable:'应收',
    payable:'应付',
    received:'已收',
    paid:'已付',
    balance:'余额',
    notes:'备注',
    timestamp:'操作时间',
    ClosePOLineBySystem:'系统关闭PO行',
    action1:'新建PO行',
    action2:'取消PO行',
    action3:'更新PO行数量',
    action4:'更新PO行日期',
    action5:'关闭PO行',
    action6:'新建发票行',
    action7:'支付发票',
    action8:'支付押金'
  },
  poLineManagement:{
    poLineManagement:'PO行管理',
    activeLine:'未完成行',
    closedLine:'已关闭行',
    issueInvoice:'对账/开发票',
    issuePayment:'押金支付',
    taxReconciliation:'申报报表',
    issueShipping:'发货',
    date:'预定日期',
    shipDate:'交付日期',
    actualDate:'开票日期',
    shipped:'已交付',
    invoiced:'已开票',
    notShipped:'未交付',
    notInvoiced:'未开票',
    all:'全部',
    balance:'余额',
    expand: '更多',
    collapse: '收起',
    errorTipsNoLineSelected:'请先选择您要操作的行号！',
    errorTipsAlreadyShpped:'您选择的行号里包含已发货的行，请重新选择后再试。',
    errorTipsAlreadyInvoiced:'您选择的行号里包含已开票的行，请重新选择后再试。',
    errorTipsNoPermission:'您选择的行号里包含无权限操作的行，请重新选择后再试。',
    errorTipsAlreadyClosed:'您选择的行号里包含已关闭的行，请重新选择后再试。',
    errorTipsNotSameCustomer:'您选择的行号里包含不同的采购商名称，请重新选择后再试。',
    errorTipsNotSameCurrency:'您选择的行号里包含不同的货币，请重新选择后再试。',
    errorTipsNotSameSupplier:'您选择的行号里包含不同的供应商名称，请重新选择后再试。',
    errorTipsNotSameSupplierTax:'您选择的行号里包含不同的供应商税号，请重新选择后再试。',
    errorTipsNotSamePurchaserTax:'您选择的行号里包含不同的采购商税号，请重新选择后再试。',
    errorTipsAlreadyChecked:'您选择的行号里包含已完全对账/开票的行，请重新选择后再试'
  },
  myLogoMenu:{
    yourProfile:'您的信息',
    yourOrg:'您的群组',
    technicalSupport: '技术支持',
    signOut:'退出',
  },
  profileMenu:{
    Profile:'个人信息',
    accountSecurity:'账号安全',
    Signature:'签名',
    Avatar:'头像',
  },
  myProfile:{
    personalProfile:'个人信息',
    Name:'名字',
    Email:'邮箱',
    updateButton:'更新',
    infoMsg:'您的名字可能会出现在openuse.io的您作出贡献或您被提及的地方。您可以随时更改它。',
  },
  accountSecurity:{
    changePsd:'修改密码',
    oldPsd:'老密码',
    newPsd:'新密码',
    infoMsg1:'强密码或密码短语对于安全性很重要。您的密码必须使用：',
    infoMsg2:'最少8个字符',
    infoMsg3:'1 个字母',
    infoMsg4:'1 个数字',
    confirmPsd:'确认新密码',
    updatePsdButton:'更新',
    errorMsg1:'此字段为必填字段。',
    errorMsg2:'很抱歉，密码不符合格式！',
    errorMsg3:'很抱歉，您输入的两个密码不匹配！',
    errorMsg4:'密码修改失败。',
    errorMsg5:'原密码不正确。',
    errorMsg6:'新密码不正确。',
    errorMsg7:'老密码不正确。',
  },
  mySignature:{
    Signature:'签名',
    yourSignature:'您的签名',
    updateSigButton:'更新',
  },
  myAvatar:{
    Avatar:'头像',
    changeAvaButton:'更新头像',
    avaMsg:'接受的文件类型：JPG、JPEG、PNG，最大2MB，如果过大则会被拒绝。',
    Zoom:'缩放',
    cancelButton:'取消',
    previewButton:'预览',
    updateButton:'更新',
  },
  myOrgtab: {
    Org: '群组',
    orgInvitation: '邀请',
    newOrg: '创建群组',
    orgPayment: '支付',
    companyOrg: '公司群组',
  },
  myOrg:{
    editOrgHint:'编辑',
    deleteOrgHint:'删除',
    searchEmail:'按名字或者邮箱查询',
    inviteMsg:'邀请 (“{email}”) 加入openuse.io',
    Title:'职务',
    Role:'角色',
    Email:'邮箱',
    Status:'状态',
    roleValueadmin:'群组管理员',
    roleValuemanager:'群组经理',
    roleValuemember:'群组成员',
    addMemberHint:'添加',
    deleteMemberHint:'删除',
    status1:'已邀请',
    status2:'已加入',
    expireDate:'有效期至',
    paySuccessTitle:'支付成功',
    paySuccessContent:'支付成功',
    payConfirmTitle:'支付更改确认',
    payConfirmContent1:'我们将通过您已签约月付服务尾数为',
    payConfirmContent2:'的信用卡进行服务升级操作，升级付费对于当月将进行差额支付，付费成功后新服务立即生效。',
    payConfirmContent3:'即将跳转到第三方支付',
  },
  myInvite:{
    noInviteMsg:'您没有新的邀请！',
    createTime:'创建时间',
    orgName:'群组名称',
    serviceName:'服务名称',
    servicePackage:'服务包',
    Inviter:'邀请人',
    Title:'职务',
    Role:'角色',
    joinButton:'加入',
  },
  newOrg:{
    createOrg:'创建您的群组',
    createMsg:'创建您的群组，您会发现您立即拥有管理员使用该服务的权利。请记住在右上角的“您的群组”中管理。',
    orgTitle:'群组名称',
    serviceCategory:'服务类型',
    Service:'服务',
    Service1:'开卷（Open book）',
    Service2:'开会（Open comm）',
    Service3:'开元（Open office）',
    servicePackage:'服务包',
    servicePackage1:'PO追记（PO Transactions）',
    servicePackage2:'库存管理（Inventory）',
    servicePackage3:'现场采购（Live sourcing）',
    servicePackvalueTrial:'30天试用',
    servicePackvalueMonth:'月租',
    servicePackComm1:'最多十人与会',
    servicePackComm2:'十人以上与会',
    trialButton:'90天试用',
    trialButton1:'30天试用',
    payButton:'支付',
    coupon:'输入优惠券号',
    payment:'付款方式',
    successPageTips:'支付成功，3秒后自动跳转',
    failPageTips:'支付失败，3秒后自动跳转',
    contactTitle:'联系销售',
    contactTips:'请联系销售部product@openuse.io。',
    orgExpired:'群组过期',
    orgExpired1:'试用到期, 请联系群组管理员延长使用时间',
    orgExpired2:'试用到期, 想要延长试用30天吗?',
    orgExistError:'您输入的群组名称重复，请重新输入',
    openbookPackage1:'月租 基础包（包含5个群组用户）',
    openbookPackage2:'月租 精品包（包含20个群组用户）',
    openbookPackage3:'年租 基础包（包含5个群组用户）',
    openbookPackage4:'年租 精品包（包含20个群组用户）',
    liveSourcingPackage1:'月租 基础包（包含10个群组用户）',
    liveSourcingPackage2:'月租 基础包（包含20个群组用户）',
    liveSourcingPackage3:'月租 基础包（包含30个群组用户）',
    liveSourcingPackage4:'月租 精品包（包含10个群组用户）',
    liveSourcingPackage5:'月租 精品包（包含20个群组用户）',
    liveSourcingPackage6:'月租 精品包（包含30个群组用户）',
  },
  userReg:{
    signUp:'注册',
    startWork:'开始您的旅程吧！',
    Email:'邮箱*',
    emailMsg1:'请输入邮箱',
    emailMsg2:'请输入有效的邮箱',
    emailError:'用户邮箱已经存在',
    newPsd:'密码*',
    infoMsg1:'强密码或密码短语对于安全性很重要。您的密码必须使用：',
    infoMsg2:'最少8个字符',
    infoMsg3:'1 个字母',
    infoMsg4:'1 个数字',
    passMsg1:'请输入密码',
    passMsg2:'密码不符合要求',
    rePsd:'确认密码*',
    repsdMsg:'请确认您的密码',
    errorMsg3:'很抱歉，您输入的两个密码不匹配！',
    company:'所属公司',
    name:'名字*',
    nameMsg: '请输入名字',
    location:'所在地*',
    locationMsg: '请选择您的所在地',
    userExist: '用户名已存在',
    pendingTitle:'欢迎！',
    pendingContent:'您的新建帐户正在等待批准，请等待发送到您电子邮件的通知。',
    successContent1:'很高兴有您的加入。',
    successContent2:'你现在可以登录后使用。',
    successContent3:'回到登录页倒数',
    termsPrivacy:'当您创建帐户时，您就同意了服务条款。有关openuse.io的隐私条款，请参阅openuso.io的隐私声明。我们会向您发送与帐户相关的电子邮件。'
  },
  toolbar:{
    switchDark:'切换到暗主题',
    switchLight:'切换到亮主题',
    notification:'通知',
    schedule:'日程',
    price:'价目'
  },
  delorgMember:{
    delMember:'删除成员',
    infoMsg:'您确定要删除成员',
    button1:'确认',
    button2:'取消',
  },
  editOrganization:{
    editOrg:'编辑群组',
    orgTitle:'群组名称',
    serCat:'服务类型',
    service:'服务',
    button1:'确认',
    button2:'取消',
    coupon:'使用优惠券',
  },
  delOrganization:{
    delOrg:'删除群组',
    infoMsg:'您确定要删除群组',
    infoMsg1:'删除的群组将同时解除您的服务包的付费绑定。',
    button1:'确认',
    button2:'取消',
  },
  paymentList:{
    createTime:'创建时间',
    orgName:'群组名称',
    serviceName:'服务名称',
    servicePackage:'服务包',
    paymentType:'支付方式',
    price:'价格',
    status:'支付状态',
    enableStatus:'服务状态',
    retry:'重试',
    noPaymentLine:'您没有支付记录！',
    enableTrue:'生效中',
    enableFalse:'未生效',
  },
  pricePage:{
    title:'Openuse.io一个让供应链上下游企业共享服务的电子平台',
    openCommTitle:'Open Comm（开会）',
    openCommContent:'一个提供会议日程、任务、与会人签名、会议纪要等的多功能视频会议。',
    openCommFree:'免费',
    openCommButton:'免费使用',
    OpenBookTitle:'Open Book（开卷）',
    OpenBookContent:'一个为供应链企业提供从PO到PO的全链协作的服务，特别关注库存管理的配合。',
    OpenBookService1:'PO追记',
    OpenBookService1Price1:'基础包 ¥650.00/月',
    OpenBookService1Price2:'精品包 ¥1300.00/月',
    OpenBookService2:'库存管理',
    OpenBookService2Price1:'基础包 ¥325.00/月',
    OpenBookService2Price2:'精品包 ¥650.00/月',
    OpenOfficeTitle:'Open Office（开元）',
    OpenOfficeContent:'一个为供应链企业提供实时采选、现场办公、在线审计、撮合配对的服务。',
    OpenOfficeService1:'实时采选',
    OpenOfficeService1Price1:'基础包  $50.00/10/用户/月',
    OpenOfficeService1Price2:'       $90.00/20/用户/月',
    OpenOfficeService1Price3:'       $120.00/30/用户/月',
    OpenOfficeService1Price4:'精品包 $100.00/10/用户/月',
    OpenOfficeService1Price5:'       $180.00/20/用户/月',
    OpenOfficeService1Price6:'       $240.00/30/用户/月',
    tailButton:'试用',
    payButton:'立即购买',
  },
  liveSourcingMain:{
    createOrgTips:'现在就创建属于您的实时采选服务组织吧！',
    description:'采选是一个审查、选择和管理能够提供企业日常运行所需投入的供应商的过程。 Live Sourcing 促进了虚拟办公室，使采购商和供应商之间能够在 24/7 基础上进行通信。 在这个虚拟办公室，采购商可以“面对面”与供应商会面，制定并执行战略； 采购商可以参观工厂并定义质量和数量的指标。 采购商或供应商可以召开会议讨论如何满足供应商的标准； 采购商也可以与供应商“现场”签订协议或合同。 所有的活动都可以在采购商和供应商作为办公室伙伴的办公室内完成。'
  },
  countries: {
    cn: "中国",
    us: "美国",
    other: "其它"
  }
}
export default langCN;
